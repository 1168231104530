<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the highest average maximum drop in percent to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n>Max. avg. max. drop (%)</mat-label>
          <input cdkFocusInitial #maxAverageMaxDropInput type="number" matInput name="maxAverageMaxDrop" formControlName="maxAverageMaxDrop" placeholder="20" id="maxAverageMaxDrop"
                 [min]="0" [max]="100" (change)="saveMaxAverageMaxDrop(maxAverageMaxDropInput.value)">
          <mat-icon (click)="clearMaxAverageMaxDrop()" *ngIf="maxAverageMaxDropInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>
