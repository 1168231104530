import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import Locale from '../../../../shared/services/locale';
import {Currency} from '../../../../shared/models/currency.interface';
import {CurrencyService} from '../../../../shared/services/currency.service';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {takeUntil} from 'rxjs/operators';
import Util from '../../../../shared/util';
import {Subject} from 'rxjs';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-statistical-trade-properties-form',
  templateUrl: './statistical-trade-properties-form.component.html',
  styleUrls: ['./statistical-trade-properties-form.component.scss'],
})
export class StatisticalTradePropertiesFormComponent extends BaseComponent implements OnInit {

  @Input() formGroup?: FormGroup;
  @Input() currencyId?: string;
  currenciesByIdCache = new Map<string, Currency>();
  @Output() onSave = new EventEmitter<any>();
  @Output() onBought = new EventEmitter<boolean>();
  @Output() onSold = new EventEmitter<boolean>();
  @Input() calculateDerivedValuesTrigger?: Subject<void>;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private currencyService: CurrencyService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currencyService.currencies$.pipe(takeUntil(this.destroy$)).subscribe(currenies => {
      currenies.forEach(it => this.currenciesByIdCache.set(it.identifier, it));
    });
    this.calcDerivedValues();
    this.calculateDerivedValuesTrigger?.pipe(takeUntil(this.destroy$)).subscribe(it => this.calcDerivedValues());
  }


  getCurrencySuffixOrPrefix() {
    return Locale.currencySuffixOrPrefix();
  }

  calcDerivedValues() {
    this.checkAndEmitBoughtEvent();
    this.checkAndEmitSoldEvent();

    let buyPrice = this.formGroup?.value.buyPrice;
    let sellPrice = this.formGroup?.value.sellPrice;
    if (!buyPrice || !sellPrice) {
      this.formGroup?.patchValue({profitAbsoluteNet: 0, profitRelativeNet: 0, profitAbsoluteGross: 0, profitRelativeGross: 0});
      return;
    }
    let orderFeeAbsolute = this.formGroup?.value.orderFeeAbsolute ? this.formGroup?.value.orderFeeAbsolute : 0;
    let taxAbsolute = this.formGroup?.value.taxAbsolute ? this.formGroup?.value.taxAbsolute : 0;

    const profitAbsoluteGross = sellPrice - buyPrice;
    const profitRelativeGross = Util.toPercentage(profitAbsoluteGross / buyPrice);

    const profitAbsoluteNet = profitAbsoluteGross - taxAbsolute - orderFeeAbsolute;
    const profitRelativeNet = Util.toPercentage(profitAbsoluteNet / buyPrice);
    this.formGroup?.patchValue({profitAbsoluteNet, profitRelativeNet, profitAbsoluteGross, profitRelativeGross});
  }

  save() {
    this.onSave.emit();
  }

  onBuyDateChanged(matDatepickerInputEvent: MatDatepickerInputEvent<any, any>) {
    this.checkAndEmitBoughtEvent();
  }

  private checkAndEmitBoughtEvent() {
    let bought = this.isSet(this.formGroup?.value.buyPrice) && this.isSet(this.formGroup?.value.buyDate);
    this.onBought.emit(bought);
  }

  onSellDateChanged(matDatepickerInputEvent: MatDatepickerInputEvent<any, any>) {
    this.checkAndEmitSoldEvent();
  }

  private checkAndEmitSoldEvent() {
    let sold = this.isSet(this.formGroup?.value.sellPrice) && this.isSet(this.formGroup?.value.sellDate);
    this.onSold.emit(sold);
  }

  private isSet(value: any) {
    if (value === undefined)
      return false;
    if (value === null)
      return false;
    if (value === '')
      return false;
    return true;
  }
}
