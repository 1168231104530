import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appFocusAndSelect]',
})
export class FocusAndSelectDirective {

  constructor(private el: ElementRef) {
    if (!el.nativeElement['focus']) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngOnInit(): void {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    if (input !== undefined && input instanceof HTMLInputElement)
      setTimeout(() => {
        input.focus();
        input.select();
      }, 0);
  }
}
