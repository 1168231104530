import {AppState} from './app.state';
import {createSelector} from '@ngrx/store';

export const selectPlanner = (state: AppState) => state.planner;


// Cached statisticalTradingPlans

export const selectCachedStatisticalTradingPlans = createSelector(
    selectPlanner,
    (stockState) => stockState.statisticalTradingPlans,
);
