import {Component, Input, OnInit} from '@angular/core';
import {Symbol} from '../../models/symbol.interface';

@Component({
    selector: 'app-symbol-quick-info',
    templateUrl: './symbol-quick-info.component.html',
    styleUrls: ['./symbol-quick-info.component.scss'],
})
export class SymbolQuickInfoComponent implements OnInit {

    @Input() symbol?: Symbol;

    constructor() {
    }

    ngOnInit(): void {
    }

}
