<div class="container-fluid bg-container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="schedules headline">Schedules editor</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="schedules headline">Schedules editor</h1>
    <p class="jumbotron-lead" i18n="schedules lead text">View and manage schedules.</p>
  </div>

  <div class="container-fluid p-1">

    <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

    <app-please-wait-loading [active]="showLoadingSpinner" [messagesByKey]="loadingSpinnerMessagesByKey"
                             [messagesByKey]="loadingSpinnerMessagesByKey"></app-please-wait-loading>


    <div *ngIf="!showLoadingSpinner">
      <div class="row">
        <div class="col-3 mb-3 virtual-scroll-y-container">
          <app-mat-icon-button [routerLink]="['new']" color="primary" cssClass="mb-3 mr-2" i18n-label icon="campaign"
                               label="Create new schedule"></app-mat-icon-button>
          <app-mat-icon-button [callback]="onImportExport.bind(this)" [params]="[]" color="accent" cssClass="mr-1 mb-3" i18n-label="button label import export"
                               icon="import_export" label="Import/Export"></app-mat-icon-button>
          <app-schedules [editMode]="true" [inputSchedules]="schedules" [selectedScheduleUid]="selectedScheduleUid"></app-schedules>

          <button #showMoreButton (click)="onClickShowMore()" *ngIf="thereIsMore" class="btn btn-primary mt-0" i18n="show more" id="show-more-button" type="button">Show more</button>

        </div>
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>

    </div>
  </div>
</div>
