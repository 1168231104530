import {StatisticalTradingPlanTypeValue} from './statisticalTradingPlan.interface';

export interface StatisticalTradingPlanType {
  value: StatisticalTradingPlanTypeValue;
  label: string;
}

export const planTypes: StatisticalTradingPlanType [] = [
  {value: 'manual', label: $localize`Manual plan`},
  {value: 'automatic', label: $localize`Automatic plan`},
];
