import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Schedule} from '../../../shared/models/schedule.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import firebase from 'firebase/app';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {CANNOT_BE_UNDONE} from '../../../shared/constants/strings';
import {UtilService} from '../../../shared/util.service';
import {AdminService} from '../../services/admin.service';
import {AnalysisType} from '../../../shared/enums/analysisType.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-schedules-editor-form',
  templateUrl: './schedules-editor-form.component.html',
  styleUrls: ['./schedules-editor-form.component.scss'],
})
export class SchedulesEditorFormComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  uidMaxLength = 100;
  nameMaxLength = 100;
  descriptionMaxLength = 1000;
  analysisIntervalInDaysMaxLength = 4;
  form: FormGroup = this.createForm();
  uid?: string;
  new = false;
  private readonly spinnerKeyDeleteLastComprehensiveSymbolCheck = 'deleteLastComprehensiveSymbolCheck';
  private readonly spinnerKeyDeleteSchedule = 'deleteSchedule';
  private readonly spinnerKeyInsertSchedule = 'insertSchedule';
  private readonly spinnerKeyUpdateSchedule = 'updateSchedule';

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    private adminService: AdminService,
    private router: Router) {
    super(authService, userService, store);
  }

  _schedule?: Schedule;

  symbolCodes: Symbol[] = [];

  get schedule(): Schedule | undefined {
    return this._schedule;
  }

  public get analysisType(): typeof AnalysisType {
    return AnalysisType;
  }

  @Input() set schedule(schedule: Schedule | undefined) {
    this._schedule = schedule ? {...schedule} : undefined;
    this.reloadForm();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
      (params) => {
        this.clearAlerts();

        this.uid = params.uid;
        if (this.uid)
          // Fetch schedule
          this.adminService.fetchSchedule(this.uid).then(wrapper => {
            if (wrapper.data)
              this.schedule = wrapper.data;
            if (wrapper.errorMessage)
              this.addError(wrapper.errorMessage);
          });
        this.new = this.uid === undefined;
        this.adminService.onScheduleSelected$.next(this.uid);
      });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  save(): void {
    const now = Timestamp.now();
    const originalUid = this.schedule?.uid;

    this.clearAlerts();

    if (this.form.value.uid === 'new') {
      this.addError($localize`The UID <i>new</i> is reserved. Please use another UID.`);
      return;
    }

    let symbolCodes: string[] = this.schedule?.symbolCodes ? this.schedule?.symbolCodes : [];
    let exchangeShortNames: string[] = this.schedule?.exchangeShortNames ? this.schedule?.exchangeShortNames : [];

    const schedule: Schedule = {
      lastEditDate: now,
      uid: this.form.value.uid,
      creationDate: this.schedule?.creationDate ? this.schedule.creationDate : now,
      symbolCodes,
      exchangeShortNames,
      name: this.form.value.name,
      description: this.form.value.description,
      enabled: this.form.value.enabled ? this.form.value.enabled : false,
      type: this.form.value.type,
      analysisIntervalInDays: Number(this.form.value.analysisIntervalInDays),
      lastComprehensiveSymbolCheck: this.schedule?.lastComprehensiveSymbolCheck ? this.schedule?.lastComprehensiveSymbolCheck : null,
    };

    // Differentiate between (new schedule), (updated schedule with same UID) and (updated schedule with changed UID)
    if (!originalUid || originalUid === schedule.uid)
      return this.updateSchedule(schedule);
    // UID was changed
    return this.updateAndRenameSchedule(originalUid, schedule);
  }

  onDelete(schedule: Schedule) {
    this.clearAlerts();
    if (!schedule?.uid) {
      this.addError($localize`This schedule has not yet been saved. It cannot be deleted.`);
      return;
    }
    const title = schedule?.name ? $localize`Are you sure you want to delete the schedule <i>${schedule?.name}</i>?` :
      $localize`Are you sure you want to delete this unnamed schedule?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deleteSchedule.bind(this), [schedule.uid!, true], undefined, undefined, undefined, 'no');
  }

  onDeleteLastComprehensiveSymbolCheck(schedule: Schedule) {
    this.clearAlerts();

    const title = schedule?.name ? $localize`Are you sure you want to trigger a new comprehensive symbol check for <i>${schedule?.name}</i>?` :
      $localize`Are you sure you want to trigger a new comprehensive symbol check for this unnamed schedule?`;
    const description = $localize`This means that the next time the backend runs this schedule, it will check every single symbol within it, instead of just processing the outdated ones. This results in more server load and firestore traffic.`;
    this.utilService.showConfirmDialog(title, description, this.deleteLastComprehensiveSymbolCheck.bind(this), [schedule.uid!, true], undefined, undefined, undefined, 'no');
  }

  getCreateOrSaveLabel() {
    if (this.new)
      return $localize`Create`;
    return $localize`Save`;
  }

  private createForm(): FormGroup {

    return this.formBuilder.group({
      uid: ['', [Validators.required, Validators.maxLength(this.uidMaxLength)]],
      enabled: [true],
      name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
      description: ['', [Validators.required, Validators.maxLength(this.descriptionMaxLength)]],
      type: [AnalysisType.STATISTICAL_RETURN, [Validators.required]],
      analysisIntervalInDays: ['180', [Validators.required, Validators.maxLength(this.analysisIntervalInDaysMaxLength)]],
    });
  }

  private reloadForm() {
    this.form.reset();
    if (this.schedule) {
      this.form.patchValue(
        {
          uid: this.schedule.uid,
          name: this.schedule.name,
          description: this.schedule.description,
          enabled: this.schedule.enabled,
          type: this.schedule.type,
          analysisIntervalInDays: this.schedule.analysisIntervalInDays,
        });
    }
  }

  private insertSchedule(schedule: Schedule) {
    this.addLoadingSpinnerMessage(this.spinnerKeyInsertSchedule, $localize`Inserting schedule...`);
    this.adminService.insertSchedule(schedule, savedSchedule => {
        this.schedule = schedule;
        this.adminService.onScheduleSaved$.next(schedule);
        this.removeLoadingSpinnerMessage(this.spinnerKeyInsertSchedule);
      },
      error => {
        this.addError($localize`Error inserting the schedule\: ${error}`);
        this.removeLoadingSpinnerMessage(this.spinnerKeyInsertSchedule);
      });
  }

  private updateSchedule(schedule: Schedule) {
    this.addLoadingSpinnerMessage(this.spinnerKeyUpdateSchedule, $localize`Updating schedule...`);
    this.adminService.updateSchedule(schedule.uid!, schedule, schedule, false, () => {
        this.adminService.onScheduleSaved$.next(schedule);
        this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateSchedule);
        this.router.navigate(['admin', 'schedules', schedule.uid]);
        this.addSuccess($localize`Successfully saved schedule <i>${schedule.uid}</i>.`);
        this.adminService.onScheduleSelected$.next(schedule.uid);
        this.schedule = schedule;
      },
      error => {
        this.addError($localize`Error updating the schedule\: ${error}`);
        this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateSchedule);
      });
  }

  private updateAndRenameSchedule(originalUid: string, schedule: Schedule) {
    this.deleteSchedule(originalUid, false);
    this.updateSchedule(schedule);
  }

  private deleteSchedule(uid: string, forward = true) {
    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteSchedule, $localize`Deleting schedule ${uid}...`);
    this.adminService.deleteSchedule(uid).then(() => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteSchedule);
      this.adminService.onScheduleDeleted$.next(uid);
      this.addSuccess($localize`Successfully deleted schedule <i>${uid}</i>.`);
      if (forward)
        this.router.navigate(['admin', 'schedules']);
    });
  }

  private deleteLastComprehensiveSymbolCheck(uid: string, forward = true) {
    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteLastComprehensiveSymbolCheck, $localize`Deleting schedule ${uid}...`);
    if (!this.schedule) {
      this.addError($localize`Schedule not found.`);
      return;
    }
    const schedule = {lastComprehensiveSymbolCheck: null};
    const fullSchedule: Schedule = {...this.schedule, lastComprehensiveSymbolCheck: null};
    this.adminService.updateSchedule(uid, schedule, fullSchedule, true, () => {
        this.addSuccess($localize`Successfully triggered new comprehensive symbol check.`);
        this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteLastComprehensiveSymbolCheck);
        this.schedule = fullSchedule;
      },
      error => {
        this.addError($localize`Error updating the schedule\: ${error}`);
        this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteLastComprehensiveSymbolCheck);
      });
  }

  onSymbolsSelectionChanged(symbolCodes: string[]) {
    if (this.schedule)
      this.schedule.symbolCodes = symbolCodes;
  }

  onExchangesSelectionChanged(exchangeShortNames: string[]) {
    if (this.schedule)
      this.schedule.exchangeShortNames = exchangeShortNames;
  }
}
