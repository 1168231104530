import {Injectable} from '@angular/core';
import {Mutex} from 'async-mutex';
import {UserService} from './user.service';
import {UserSettings} from '../models/userSettings.interface';
import {Language} from '../models/language.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {selectUpdateUserActionResult, selectUser} from '../../auth/store/auth.selectors';
import {resetUpdateUserState, updateUserMerge} from '../../auth/store/auth.actions';
import {take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ColumnList, TableId} from '../models/columnSettings.interface';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  settingsMutex = new Mutex();

  user$ = this.store.select(selectUser);
  updateUserActionResult$ = this.store.select(selectUpdateUserActionResult);

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private matSnackBar: MatSnackBar) {
  }

  setStringValue(key: string, value: string) {
    this.user$.pipe(take(1)).subscribe(user => {
      if (!user)
        return;

      let settings: UserSettings = user.settings ? {...user.settings} : {};
      // Check, if rewriting the setting is necessary
      if (settings[key] === value) {
        this.matSnackBar.open($localize`Setting saved.`, undefined, {duration: 2000});
        // Setting does already have the wanted value. We're done.
        return;
      }
      settings[key] = value;
      user = {...user, settings};
      const userUpdate = {uid: user.uid, settings};
      this.store.dispatch(updateUserMerge({userUpdate}));
      this.listenForSettingsSaveResult();
    });
  }

  setTableColumnSetting(columnList: ColumnList, table: TableId) {
    this.user$.pipe(take(1)).subscribe(user => {
      if (!user)
        return;

      let settings: UserSettings = user.settings ? {...user.settings} : {};
      // Check, if rewriting the setting is necessary
      const userColumns = this.getUserColumnList(settings, table);
      if (JSON.stringify(userColumns) === JSON.stringify(columnList)) {
        // Setting does already have the wanted columnList. We're done.
        this.matSnackBar.open($localize`Setting saved.`, undefined, {duration: 2000});
        return;
      }
      let columnSettings = settings.columnSettings ? {...settings.columnSettings} : {};
      columnSettings[table] = columnList;
      settings = {...settings, columnSettings};
      user = {...user, settings};
      const userUpdate = {uid: user.uid, settings};
      this.store.dispatch(updateUserMerge({userUpdate}));
      this.listenForSettingsSaveResult();
    });
  }

  private listenForSettingsSaveResult() {
    this.updateUserActionResult$.pipe(take(1)).subscribe(result => {
      this.store.dispatch(resetUpdateUserState());
      if (result.success)
        this.matSnackBar.open($localize`Setting saved.`, undefined, {duration: 2000});
      if (result.errorMessage)
        this.matSnackBar.open($localize`Setting could not be saved. ` + result.errorMessage, undefined, {duration: 10000});
    });
  }

  /**
   * Sets the given language in the logged in user's account.
   * @param lang language
   */
  setLanguage(lang: Language) {
    this.setStringValue('lang', lang.code);
  }

  private getUserColumnList(settings: UserSettings, table: TableId): ColumnList | undefined {
    if (settings.columnSettings === undefined)
      return undefined;
    let userColumns: ColumnList | undefined = undefined;
    if (table === 'statistical-returns')
      userColumns = settings.columnSettings['statistical-returns'];
    return userColumns;
  }
}
