import {Component, Input, OnInit} from '@angular/core';
import {planTypes} from '../../../../shared/models/statisticalTradingPlanType.interface';
import {StatisticalTradingPlanTypeValue} from '../../../../shared/models/statisticalTradingPlan.interface';

@Component({
  selector: 'app-plan-type-view',
  templateUrl: './plan-type-view.component.html',
  styleUrls: ['./plan-type-view.component.scss'],
})
export class PlanTypeViewComponent implements OnInit {

  @Input() type?: StatisticalTradingPlanTypeValue;

  planTypes = planTypes;

  constructor() {
  }

  ngOnInit(): void {
  }

}
