<ng-container *ngIf="column !== undefined; else noColumn">

  <ng-container [ngSwitch]="column?.id">

    <ng-container *ngSwitchCase="'PERIODS_ALL_ASCENDING'">
      <td *ngFor="let period of periods">
        <app-period-data [statRet]="statRet" [period]="period"></app-period-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'PERIODS_ALL_DESCENDING'">
      <td *ngFor="let period of getReversePeriods()">
        <app-period-data [statRet]="statRet" [period]="period"></app-period-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'PERIODS_WIN_RATIOS_ALL_ASCENDING'">
      <td *ngFor="let period of periods">
        <app-period-win-ratio-data [statRet]="statRet" [period]="period"></app-period-win-ratio-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'PERIODS_WIN_RATIOS_ALL_DESCENDING'">
      <td *ngFor="let period of getReversePeriods()">
        <app-period-win-ratio-data [statRet]="statRet" [period]="period"></app-period-win-ratio-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_ALL_ASCENDING'">
      <td *ngFor="let year of years">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_ALL_DESCENDING'">
      <td *ngFor="let year of getReverseYears()">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_0_TO_5_ASCENDING'">
      <td *ngFor="let year of getXToYYears(0,5,false)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_0_TO_5_DESCENDING'">
      <td *ngFor="let year of getXToYYears(0,5,true)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_6_TO_10_ASCENDING'">
      <td *ngFor="let year of getXToYYears(6,10,false)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_6_TO_10_DESCENDING'">
      <td *ngFor="let year of getXToYYears(6,10,true)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_11_TO_15_ASCENDING'">
      <td *ngFor="let year of getXToYYears(11,15,false)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_11_TO_15_DESCENDING'">
      <td *ngFor="let year of getXToYYears(11,15,true)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_16_TO_20_ASCENDING'">
      <td *ngFor="let year of getXToYYears(16,20,false)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_16_TO_20_DESCENDING'">
      <td *ngFor="let year of getXToYYears(16,20,true)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_21_TO_25_ASCENDING'">
      <td *ngFor="let year of getXToYYears(21,25,false)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_21_TO_25_DESCENDING'">
      <td *ngFor="let year of getXToYYears(21,25,true)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_26_TO_30_ASCENDING'">
      <td *ngFor="let year of getXToYYears(26,30,false)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_26_TO_30_DESCENDING'">
      <td *ngFor="let year of getXToYYears(26,30,true)">
        <app-year-data [statRet]="statRet" [year]="year"></app-year-data>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="'DEVELOPMENT_SINCE_BUY_DAY'">
      <ng-container *ngIf="showDevelopmentSinceBuyDay">
        <td *ngIf="development; else developmentNotReadyYet">
          <span [matTooltip]="(development.buyDayString ? (utilService.getDate(development.buyDayString) | moment:'LL') : '') + ' (' + getTradingPhaseString(development.tradingPhase) +')'">
            <app-money [value]="development.priceOnBuyDay" [currencyId]="statRet?.currency"></app-money>
          </span>
        </td>
        <td *ngIf="symbol && symbol.price; else developmentNotReadyYet">
          <span [matTooltip]="symbol.priceDate ? (utilService.getDate(symbol.priceDate) | moment:'LLLL') : ''">
            <app-money [value]="symbol.price" [currencyId]="statRet?.currency"></app-money>
          </span>
        </td>
        <td *ngIf="development; else developmentNotReadyYet" [class]="development.change | valueClassPipe">
          <ng-container *ngIf="development.change; else noValue">
            <app-percentage-view [value]="development.change"></app-percentage-view>
          </ng-container>
        </td>
        <td *ngIf="development; else developmentNotReadyYet">
          <ng-container *ngIf="targetSellPrice; else noValue">
            <app-money [value]="targetSellPrice" [currencyId]="statRet?.currency"></app-money>
          </ng-container>
        </td>
        <td *ngIf="development; else developmentNotReadyYet" [class]="getExpectedProfitCssClass()">

          <ng-container *ngIf="expectedProfit; else noValue">
            <app-percentage-view [value]="expectedProfit"></app-percentage-view>
          </ng-container>
        </td>
        <ng-template #developmentNotReadyYet>
          <td *ngIf="noPriceFound" i18n>Unknown</td>
          <td *ngIf="!noPriceFound">
            <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
          </td>
        </ng-template>
        <ng-template #noValue>
          <span>-</span>
        </ng-template>
      </ng-container>

    </ng-container>
  </ng-container>

</ng-container>

<ng-template #noColumn>
  <td>&nbsp;</td>
</ng-template>
