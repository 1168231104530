<form (ngSubmit)="search()" [formGroup]="form">
  <div class="row mx-0 mb-1">
    <!-- Year -->
    <div class="col-auto p-0 pr-1">
      <mat-label i18n i18n-matTooltip matTooltip="Year of the simulation">Year</mat-label>
      <br>
      <mat-form-field class="input-xs">
        <mat-label i18n="Year">Year</mat-label>
        <input #yearInput type="number" matInput name="year" min="2000" [max]="getCurrentYear()" formControlName="year" placeholder="Year" id="year" i18n-placeholder (change)="saveYear(yearInput.value)">
        <mat-icon (click)="clearYear()" *ngIf="yearInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Min. ROI -->
    <div class="col-auto px-1">
      <mat-label i18n i18n-matTooltip matTooltip="Min. return on investment">Min. ROI (%)</mat-label>
      <br>
      <mat-form-field class="input-xs">
        <mat-label i18n="ROI">ROI</mat-label>
        <input #minRoiInput type="number" matInput name="roi" formControlName="minRoi" placeholder="50" id="minRoi" i18n-placeholder (change)="saveMinRoi(minRoiInput.value)">
        <mat-icon (click)="clearMinRoi()" *ngIf="minRoiInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Min. profit -->
    <div class="col-auto px-1">
      <mat-label i18n="Min. avg profit" i18n-matTooltip matTooltip="The minimum average relative annual profit per trade necessary to buy a stock in the simulation.">Min. avg. profit (%)</mat-label>
      <br>
      <mat-form-field class="input-xxs">
        <mat-label i18n="From">From</mat-label>
        <input #minAverageRelativeProfitMinInput type="number" matInput name="minAverageRelativeProfitMin" formControlName="minAverageRelativeProfitMin" placeholder="50"
               id="minAverageRelativeProfitMin" i18n-placeholder (change)="saveMinAverageRelativeProfitMin(minAverageRelativeProfitMinInput.value)">
        <mat-icon (click)="clearMinAverageRelativeProfitMin()" *ngIf="minAverageRelativeProfitMinInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
      <mat-form-field class="input-xxs">
        <mat-label i18n="To">To</mat-label>
        <input #minAverageRelativeProfitMaxInput type="number" matInput name="minAverageRelativeProfitMax" formControlName="minAverageRelativeProfitMax" placeholder="50"
               id="minAverageRelativeProfitMax" i18n-placeholder (change)="saveMinAverageRelativeProfitMax(minAverageRelativeProfitMaxInput.value)">
        <mat-icon (click)="clearMinAverageRelativeProfitMax()" *ngIf="minAverageRelativeProfitMaxInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Max. holding duration -->
    <div class="col-auto px-1">
      <mat-label i18n="Max. duration" i18n-matTooltip matTooltip="The maximum duration in days between buy and sell date.">Max. duration</mat-label>
      <br>
      <mat-form-field class="input-xxs">
        <mat-label i18n="From">From</mat-label>
        <input #maxHoldingDurationMinInput type="number" matInput name="maxHoldingDurationMin" formControlName="maxHoldingDurationMin" placeholder="50"
               id="maxHoldingDurationMin" i18n-placeholder (change)="saveMaxHoldingDurationMin(maxHoldingDurationMinInput.value)">
        <mat-icon (click)="clearMaxHoldingDurationMin()" *ngIf="maxHoldingDurationMinInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
      <mat-form-field class="input-xxs">
        <mat-label i18n="To">To</mat-label>
        <input #maxHoldingDurationMaxInput type="number" matInput name="maxHoldingDurationMax" formControlName="maxHoldingDurationMax" placeholder="50"
               id="maxHoldingDurationMax" i18n-placeholder (change)="saveMaxHoldingDurationMax(maxHoldingDurationMaxInput.value)">
        <mat-icon (click)="clearMaxHoldingDurationMax()" *ngIf="maxHoldingDurationMaxInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Seed capital -->
    <div class="col-auto px-1">
      <mat-label i18n="Seed capital" i18n-matTooltip matTooltip="The capital before the start of the simulation">Seed capital</mat-label>
      <br>
      <mat-form-field class="input-xs">
        <mat-label i18n="From">From</mat-label>
        <input #seedCapitalMinInput type="number" matInput name="seedCapitalMin" formControlName="seedCapitalMin" placeholder="50"
               id="seedCapitalMin" i18n-placeholder (change)="saveSeedCapitalMin(seedCapitalMinInput.value)">
        <mat-icon (click)="clearSeedCapitalMin()" *ngIf="seedCapitalMinInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
      <mat-form-field class="input-xs">
        <mat-label i18n="To">To</mat-label>
        <input #seedCapitalMaxInput type="number" matInput name="seedCapitalMax" formControlName="seedCapitalMax" placeholder="50"
               id="seedCapitalMax" i18n-placeholder (change)="saveSeedCapitalMax(seedCapitalMaxInput.value)">
        <mat-icon (click)="clearSeedCapitalMax()" *ngIf="seedCapitalMaxInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Max. capital per trade -->
    <div class="col-auto px-1">
      <mat-label i18n="Max. capital per Trade" i18n-matTooltip matTooltip="The maximum capital used for a single trade relative to the depot value">Max. capital per trade</mat-label>
      <br>
      <mat-form-field class="input-xs">
        <mat-label i18n="From">From</mat-label>
        <input #maxCapitalPerTradeMinInput type="number" matInput name="maxCapitalPerTradeMin" formControlName="maxCapitalPerTradeMin" placeholder="50"
               id="maxCapitalPerTradeMin" i18n-placeholder (change)="saveMaxCapitalPerTradeMin(maxCapitalPerTradeMinInput.value)">
        <mat-icon (click)="clearMaxCapitalPerTradeMin()" *ngIf="maxCapitalPerTradeMinInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
      <mat-form-field class="input-xs">
        <mat-label i18n="To">To</mat-label>
        <input #maxCapitalPerTradeMaxInput type="number" matInput name="maxCapitalPerTradeMax" formControlName="maxCapitalPerTradeMax" placeholder="50"
               id="maxCapitalPerTradeMax" i18n-placeholder (change)="saveMaxCapitalPerTradeMax(maxCapitalPerTradeMaxInput.value)">
        <mat-icon (click)="clearMaxCapitalPerTradeMax()" *ngIf="maxCapitalPerTradeMaxInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Search button -->
    <div class="col-auto pl-1">
      <mat-label i18n>Search</mat-label>
      <br>
      <app-mat-fab-search-button type="submit"></app-mat-fab-search-button>
    </div>

    <!-- Exchanges -->
    <div class="col-auto px-1">

      <app-symbols-exchange-selector i18n-matTooltip matTooltip="Select exchanges, which should be included in the simulation." [selectionBelow]="true"
                                     (onSelectionChanged)="onExchangesSelectionChanged($event)"
                                     [bigButton]="true" buttonColor="default" [showQuickSelectExchangeButtons]="true"
                                     [selectedElements]="selectedExchangeShortNames$ | async"
                                     elementType="exchanges" [maxSelectionCount]="6"></app-symbols-exchange-selector>
    </div>

    <!-- Clear all filters -->
    <div class="col-auto px-1">
      <a (click)="clearAllFilters()" class="hover-pointer" i18n>Clear all filters</a>
    </div>

  </div>
</form>
