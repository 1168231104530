<app-info-text page="symbol-view" part="introduction"></app-info-text>


<ng-container *ngIf="statisticalReturns !== undefined && statisticalReturns.length > 0; else noStatisticalReturns">

  <app-statistical-returns-table [statisticalReturns]="statisticalReturns"></app-statistical-returns-table>

  <p class="mx-0 my-3" i18n="message shown statisticalReturns count">Showing {{statisticalReturns.length}} statistical returns.</p>

  <button #showMoreButton (click)="onClickShowMore()" *ngIf="thereIsMore" class="btn btn-primary mb-3" i18n="show more" id="show-more-button" type="button">Show more</button>

  <app-info-text page="symbol-view" part="description"></app-info-text>

</ng-container>

<ng-template #noStatisticalReturns>
  <ng-container *ngIf="!searching">
    <div class="alert alert-info" i18n>We don't know of any statistical trading opportunities for this symbol.</div>
  </ng-container>
</ng-template>

<div class="mb-2">
  <app-admin-options (onStatisticalReturnsLoaded)="onStatisticalReturnsLoaded($event)" *ngIf="user?.admin" [symbol]="symbol"></app-admin-options>
</div>
