import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-statistical-trading-menu',
  templateUrl: './statistical-trading-menu.component.html',
  styleUrls: ['./statistical-trading-menu.component.scss'],
})
export class StatisticalTradingMenuComponent extends BaseComponent implements OnInit {

  collapsed = true;


  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
  }


  collapseNavbar() {
    this.collapsed = true;
  }

}
