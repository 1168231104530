import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../shared/components/base/base.component';
import {AuthService} from '../auth/auth.service';
import {UserService} from '../shared/services/user.service';
import {TitleService} from '../shared/services/title.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';

@Component({
  selector: 'app-statistical-trading',
  templateUrl: './statistical-trading.component.html',
  styleUrls: ['./statistical-trading.component.scss'],
})
export class StatisticalTradingComponent extends BaseComponent implements OnInit {

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Statistical Trading`);
  }


}
