import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StatisticalTradeStep} from '../../../../shared/models/statisticalTradeStep.interface';

@Component({
  selector: 'app-depot-contents-modal',
  templateUrl: './depot-contents-modal.component.html',
  styleUrls: ['./depot-contents-modal.component.scss'],
})
export class DepotContentsModalComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() step?: StatisticalTradeStep;
  @Input() currencyId?: string;


  constructor(public activeModal: NgbActiveModal,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }

}
