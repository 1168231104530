import {Component, ElementRef, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../shared/services/user.service';
import {AuthService} from '../../../auth/auth.service';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {takeUntil} from 'rxjs/operators';
import {Notification} from '../../../shared/models/notification.interface';
import {AccountService} from '../../../account/account.service';
import {NotificationsDialogComponent, NotificationsDialogModel} from '../../../account/notifications-dialog/notifications-dialog.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';


@Component({
  selector: 'app-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.css'],
})
export class NotificationsButtonComponent extends BaseComponent implements OnInit, OnDestroy {

  unreadNotificationsCount = 0;
  notifications: Notification[] = [];
  error?: string;
  closeEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  private notificationsStreamUnsubscribe?: () => void;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public dialog: MatDialog,
      private accountService: AccountService,
  ) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadNotifications();

    this.closeEventEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialog.closeAll();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.notificationsStreamUnsubscribe)
      this.notificationsStreamUnsubscribe();
  }

  showDialog(event: MouseEvent) {
    const target = new ElementRef(event.currentTarget);
    const dialogData = new NotificationsDialogModel(this.notifications, target, this.error, this.closeEventEmitter);
    const dialogRef = this.dialog.open(NotificationsDialogComponent, {
      maxWidth: '350px',
      data: dialogData,
      backdropClass: 'backdropBackground',
      panelClass: 'conversations-dialog-container',
    });
  }

  private loadNotifications() {
    this.user$.subscribe(user => {
      if (user?.uid)
        this.streamNotifications(user.uid);
      else {
        if (this.notificationsStreamUnsubscribe)
          this.notificationsStreamUnsubscribe();
        this.notifications = [];
      }
    });
  }

  private streamNotifications(userUid: string) {

    this.notificationsStreamUnsubscribe = this.accountService.streamNotifications(userUid,
        (notifications => {
          this.notifications = notifications;
          this.countUnreadNotifications(userUid);
        }),
        (error => {
          this.error = error;
        }),
    );
  }


  private countUnreadNotifications(userUid: string) {
    this.unreadNotificationsCount = 0;
    this.notifications.forEach(notification => {
      if (!notification.read)
        this.unreadNotificationsCount++;
    });
  }
}
