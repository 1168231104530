import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {TitleService} from '../../../shared/services/title.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-name-email',
  templateUrl: './name-email.component.html',
  styleUrls: ['./name-email.component.css'],
})
export class NameEmailComponent extends BaseComponent implements OnInit {

  @Input() embedded = false;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.embedded)
      this.titleService.setTitle($localize`My name and email`);
  }


}
