<div class="container-fluid bg-container p-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="faqs headline">FAQs editor</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="faqs headline">FAQs editor</h1>
    <p class="jumbotron-lead" i18n="faqs lead text">View and manage FAQs.</p>
  </div>


  <div class="container-fluid p-1">

    <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>


    <div class="row">
      <div class="col mb-3 virtual-scroll-y-container">
        <app-mat-icon-button [routerLink]="['new']" color="primary" cssClass="mb-3 mr-2" i18n-label icon="campaign"
                             label="Create new FAQ"></app-mat-icon-button>
        <app-mat-icon-button [callback]="onImportExport.bind(this)" [params]="[]" color="accent" cssClass="mr-1 mb-3" i18n-label="button label import export"
                             icon="import_export" label="Import/Export"></app-mat-icon-button>
        <app-faqs [editMode]="true" [inputFaqs]="faqs" [selectedFaqUid]="selectedFaqUid"></app-faqs>
      </div>
      <div class="col">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</div>
