<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please select the exchanges to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">


        <div class="col-auto px-1 pb-2">
          <app-symbols-exchange-selector i18n-matTooltip matTooltip="Select exchanges, which should be included in the search." [selectionBelow]="true"
                                         (onSelectionChanged)="onExchangesSelectionChanged($event)"
                                         [bigButton]="true" buttonColor="default" [showQuickSelectExchangeButtons]="true"
                                         [selectedElements]="selectedExchangeShortNames"
                                         elementType="exchanges" [maxSelectionCount]="10"></app-symbols-exchange-selector>
        </div>

      </div>
    </div>
  </form>

</ng-template>
