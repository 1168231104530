import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../shared/components/base-dialog/base-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {selectBestStatisticalReturnSearchExchangeShortNames, selectUpcomingStatisticalReturnSearchExchangeShortNames} from '../../../../store/stock.selectors';
import {takeUntil} from 'rxjs/operators';
import {
  MaxAverageMaxDropEditDialogComponent,
} from '../../max-average-max-drop-filter/max-average-max-drop-edit-dialog/max-average-max-drop-edit-dialog.component';
import {StatisticalTradingService} from '../../../statistical-trading.service';

@Component({
  selector: 'app-exchanges-edit-dialog',
  templateUrl: './exchanges-edit-dialog.component.html',
  styleUrls: ['./exchanges-edit-dialog.component.scss'],
})
export class ExchangesEditDialogComponent extends BaseDialogComponent implements OnInit {

  form: FormGroup = this.createForm();

  selectedExchangeShortNames$?: Observable<string[] | undefined>;
  selectedExchangeShortNames: string[] = [];

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    dialogRef: MatDialogRef<MaxAverageMaxDropEditDialogComponent>,
    private statisticalTradingService: StatisticalTradingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) {
    super(authService, userService, store, dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.viewType === 'upcoming') {
      this.selectedExchangeShortNames$ = this.store.select(selectUpcomingStatisticalReturnSearchExchangeShortNames);
    }
    if (this.data.viewType === 'best') {
      this.selectedExchangeShortNames$ = this.store.select(selectBestStatisticalReturnSearchExchangeShortNames);
    }

    this.selectedExchangeShortNames$?.pipe(takeUntil(this.destroy$)).subscribe(selectedExchangeShortNames => {
      if (selectedExchangeShortNames)
        this.selectedExchangeShortNames = [...selectedExchangeShortNames];
    });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      maxAverageMaxDrop: [''],
    });
  }

  onExchangesSelectionChanged(selectedExchanges: string[]) {
    if (this.data.viewType === 'upcoming') {
      this.statisticalTradingService.setUpcomingStatisticalReturnsExchangesFilter(selectedExchanges);
      this.statisticalTradingService.fetchUpcomingStatisticalReturns();
    }
    if (this.data.viewType === 'best') {
      this.statisticalTradingService.setBestStatisticalReturnsExchangesFilter(selectedExchanges);
      this.statisticalTradingService.fetchBestStatisticalReturns();
    }
  }

}

