import {Currency} from '../../models/currency.interface';
import {Component, Input, OnInit} from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import Locale from '../../services/locale';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.css'],
})
export class MoneyComponent implements OnInit {

  @Input() currency: Currency | undefined;
  locale = Locale.numberFormatLocale();
  allCurrencies?: Currency[];
  bigMoneyFormat = '';
  valueBigMoney?: number;
  /**
   * Determines, if the value should be shown exactly or in millions, billions etc.
   */
  @Input() bigMoney = false;
  @Input() useMetricPrefixes = true;

  constructor(public currencyService: CurrencyService) {
  }

  _value?: number;

  get value(): number | undefined {
    return this._value;
  }

  @Input() set value(value: number | undefined) {
    this._value = value;
    if (this.useMetricPrefixes)
      this.bigMoneyConverterMetricPrefixes();
    else
      this.bigMoneyConverter();
  }

  private bigMoneyConverter() {
    if (this.value === undefined)
      return;
    if (this.value >= 1e12) {
      this.bigMoneyFormat = $localize`:trillions indicator:T` + ' ';
      this.valueBigMoney = this.value / 1e12;
    } else if (this.value >= 1e9) {
      this.bigMoneyFormat = $localize`:billions indicator:B` + ' ';
      this.valueBigMoney = this.value / 1e9;
    } else if (this.value >= 1e6) {
      this.bigMoneyFormat = $localize`:millions indicator:M` + ' ';
      this.valueBigMoney = this.value / 1e6;
    } else {
      this.valueBigMoney = this.value;
    }
  }

  private bigMoneyConverterMetricPrefixes() {
    if (this.value === undefined)
      return;
    if (this.value >= 1e18) {
      this.bigMoneyFormat = 'E';
      this.valueBigMoney = this.value / 1e18;
    } else if (this.value >= 1e15) {
      this.bigMoneyFormat = 'P';
      this.valueBigMoney = this.value / 1e15;
    } else if (this.value >= 1e12) {
      this.bigMoneyFormat = 'T';
      this.valueBigMoney = this.value / 1e12;
    } else if (this.value >= 1e9) {
      this.bigMoneyFormat = 'G';
      this.valueBigMoney = this.value / 1e9;
    } else if (this.value >= 1e6) {
      this.bigMoneyFormat = 'M';
      this.valueBigMoney = this.value / 1e6;
    } else if (this.value >= 1e3) {
      this.bigMoneyFormat = 'k';
      this.valueBigMoney = this.value / 1e3;
    } else {
      this.bigMoneyFormat = '';
      this.valueBigMoney = this.value;
    }
  }

  _currencyId?: string;

  get currencyId(): string | undefined {
    return this._currencyId;
  }

  @Input() set currencyId(currencyId: string | undefined) {
    this._currencyId = currencyId?.toUpperCase();
    this.setCurrency();
  }

  ngOnInit(): void {
    this.currencyService.getCurrencies().then(wrapper => {
      if (wrapper.data) {
        this.allCurrencies = wrapper.data;
        this.setCurrency();
      }
    });

  }

  private setCurrency() {
    if (this.allCurrencies && this.currencyId) {
      const foundCurrency = this.allCurrencies.find(currency => currency.id === this.currencyId);
      if (foundCurrency)
        this.currency = foundCurrency;
    }
  }
}
