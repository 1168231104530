import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {MatAccordion} from '@angular/material/expansion';
import {Schedule} from '../../../shared/models/schedule.interface';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {LayoutService} from '../../../layout/layout.service';
import {Router} from '@angular/router';
import Util from '../../../shared/util';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() editMode = false;

  @ViewChild('accordion') matAccordion?: MatAccordion;
  /**
   * All schedules
   */
  allSchedules: Schedule[] = [];

  /**
   * Filtered schedules
   */
  schedules: Schedule[] = [];
  step = -1;
  filterTerm?: string;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private cdRef: ChangeDetectorRef,
      public layoutService: LayoutService,
      private router: Router) {
    super(authService, userService, store);
  }

  /**
   * If schedules are given as an input, no schedules will be fetched on init. Instead, allSchedules will be set to inputSchedules.
   */
  _inputSchedules?: Schedule[];

  get inputSchedules(): Schedule[] | undefined {
    return this._inputSchedules;
  }

  @Input() set inputSchedules(inputSchedules: Schedule[] | undefined) {
    this._inputSchedules = inputSchedules;
    try {
      if (inputSchedules) {
        this.allSchedules = [...inputSchedules];
        this.filter();
        this.selectSchedule(this.selectedScheduleUid);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * If schedules are given as an input, no schedules will be fetched on init. Instead, allSchedules will be set to inputSchedules.
   */
  _selectedScheduleUid?: string;

  get selectedScheduleUid(): string | undefined {
    return this._selectedScheduleUid;
  }

  @Input() set selectedScheduleUid(selectedScheduleUid: string | undefined) {
    this._selectedScheduleUid = selectedScheduleUid;
    this.selectSchedule(this.selectedScheduleUid);
  }

  setStep(index: number) {
    this.step = index;
    if (this.editMode) {
      if (this.step > -1)
        this.router.navigate(['admin', 'schedules', this.schedules[index].uid]);
      else
        this.router.navigate(['admin', 'schedules']);
    }
  }

  closed() {
    if (this.editMode) {
      this.router.navigate(['admin', 'schedules']);
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public filter(filterTerm?: string) {
    this.filterTerm = filterTerm;
    if (filterTerm && this.allSchedules)
      this.schedules = this.allSchedules.filter(schedule =>
          this.scheduleNameContainsFilterTerm(schedule, filterTerm) ||
          this.scheduleDescriptionContainsFilterTerm(schedule, filterTerm) ||
          this.scheduleSymbolCodesContainFilterTerm(schedule, filterTerm),
      );
    else
      this.schedules = this.allSchedules;
    this.schedules = this.schedules.sort((a, b) => {
      if (!a.name && !b.name)
        return 0;
      if (!a.name)
        return -1;
      if (!b.name)
        return 1;
      return Util.compare(a.name, b.name, true);
    });
  }

  private scheduleDescriptionContainsFilterTerm(schedule: Schedule, filterTerm: string) {
    if (!schedule?.description)
      return false;
    return schedule.description.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
  }

  private scheduleNameContainsFilterTerm(schedule: Schedule, filterTerm: string) {
    if (!schedule?.name)
      return false;
    return schedule.name.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
  }

  private scheduleSymbolCodesContainFilterTerm(schedule: Schedule, filterTerm: string) {
    if (!schedule?.symbolCodes)
      return false;
    for (let symbolCode of schedule.symbolCodes) {
      if (symbolCode.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1)
        return true;
    }
    return false;
  }

  private selectSchedule(selectedScheduleUid: string | undefined) {
    if (selectedScheduleUid) {
      const foundSelectedSchedule = this.schedules.find((schedule: Schedule) => schedule.uid === selectedScheduleUid);
      if (!foundSelectedSchedule)
        return;
      const index = this.schedules.indexOf(foundSelectedSchedule);
      this.setStep(index);
      // } else {
      //   this.matAccordion?.closeAll();
    }
  }

}
