import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {environment} from '../../environments/environment';
import {from, Observable, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {FacebookEventName, FacebookService} from '../shared/services/facebook.service';
import {AnalyticsEventName, AnalyticsService} from '../shared/services/analytics.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {StatisticalTradingService} from '../statistical-trading/statistical-trading.service';
import {
  bestStatisticalReturnsSearchFailed,
  fetchBestStatisticalReturnsSearchResults,
  fetchMoreBestStatisticalReturnsSearchResults,
  fetchMoreSymbolsSearchResults,
  fetchMoreUpcomingStatisticalReturnsSearchResults,
  fetchSymbolsSearchResults,
  fetchUpcomingStatisticalReturnsSearchResults,
  setBestStatisticalReturnsSearchResults,
  setMoreSymbolsSearchResults,
  setSymbolsSearchResults,
  setUpcomingStatisticalReturnsBuyDayNumber,
  setUpcomingStatisticalReturnsSearchResults,
  symbolsSearchFailed,
  upcomingStatisticalReturnsSearchFailed,
} from './stock.actions';
import {PayloadResultJo} from '../shared/models/results/payloadResultJo.interface';
import {PagedList} from '../shared/models/pagedList.interface';
import {BackendService} from '../shared/services/backend.service';
import {Symbol} from '../shared/models/symbol.interface';
import {SymbolsSearchParams} from '../shared/models/symbolsSearchParams.interface';
import {SymbolsSearchResult} from '../shared/models/symbolsSearchResult.interface';
import {selectStock} from './stock.selectors';
import {Store} from '@ngrx/store';
import {StatisticalReturnsSearchParams, StatisticalReturnsSortOrder} from '../shared/models/statisticalReturnsSearchParams.interface';
import {StockState} from './stock.state';
import {firestore} from '../app.module';
import {statisticalReturnConverter} from '../shared/services/stock.service';
import {StatisticalReturn} from '../shared/models/statisticalReturn.interface';
import {StatisticalReturnsSearchResult} from '../shared/models/statisticalReturnsSearchResult.interface';
import firebase from 'firebase';
import Util from '../shared/util';
import {StatisticalReturnViewType} from '../shared/types/statisticalReturnViewType.type';
import {AppState} from './app.state';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import QuerySnapshot = firebase.firestore.QuerySnapshot;

@Injectable()
export class StockEffects {

  fetchSymbolSearchResultsFromBackend = createEffect(() => this.actions.pipe(
      ofType(fetchSymbolsSearchResults, fetchMoreSymbolsSearchResults),
      withLatestFrom(this.store.select(selectStock)),
      switchMap(action => this.handleSymbolsSearch(action[1])),
  ));

  /**
   * Handles the backend search request for symbols and maps the response to handleSymbolSearchResponse.
   * @param stockState current stock state
   * @return Observable of backend request
   */
  private handleSymbolsSearch(stockState: StockState): Observable<any> {
    let httpParams = this.backendService.getApiPublicKeyParam();
    if (!httpParams)
      throw Error('Backend-Api-Secret-Key is missing.');

    const searchParams = stockState.symbolsSearch.searchParams;
    const start = searchParams.start ? searchParams.start : 0;
    const limit = searchParams.limit ? searchParams.limit : environment.defaultSymbolSearchCount;
    httpParams = httpParams.set('start', start);
    httpParams = httpParams.set('limit', limit);
    if (searchParams.searchTerm)
      httpParams = httpParams.set('searchTerm', searchParams.searchTerm);
    if (searchParams.exchangeShortName)
      httpParams = httpParams.set('exchangeShortName', searchParams.exchangeShortName);

    this.handleSymbolsSearchAnalytics(searchParams);
    try {
      return from(this.httpClient.post<PayloadResultJo<PagedList<Symbol>>>(environment.backend_api_path + environment.backend_api_request_symbols_find,
          null, {params: httpParams})
          .pipe(
              map(response => this.handleSymbolSearchResponse(response, searchParams, limit)),
              catchError((error) => this.handleSymbolsSearchError(error)),
          ),
      );
    } catch (error) {
      return this.handleSymbolsSearchError(error);
    }
  }

  private handleSymbolsSearchAnalytics(searchParams: SymbolsSearchParams) {
    let eventParams = {
      searchTerm: searchParams.searchTerm,
      exchangeShortName: searchParams.exchangeShortName,
    };
    this.analyticsService.logEvent(AnalyticsEventName.SEARCH, eventParams);
    if (searchParams.searchTerm)
      this.facebookService.logEvent(FacebookEventName.Search, {
        search_string: searchParams.searchTerm,
        content_category: searchParams.exchangeShortName,
      });
  }

  handleSymbolSearchResponse = (response: PayloadResultJo<PagedList<Symbol>>, searchParams: SymbolsSearchParams, limit: number) => {
    const pagedList: PagedList<Symbol> = response.payload;
    const symbols: Symbol[] = pagedList.list;
    let now = new Date();
    symbols.forEach(it => it.cacheDate = now);
    const start = searchParams.start ? searchParams.start : 0;
    const searchResult: SymbolsSearchResult = {
      symbols,
      thereIsMore: pagedList.allElementCount > pagedList.indexLast + 1,
      totalCount: pagedList.allElementCount,
    };
    if (start === 0)
      return setSymbolsSearchResults({searchResult});
    return setMoreSymbolsSearchResults({searchResult});
  };

  private handleSymbolsSearchError(error: any): Observable<any> {
    return of(symbolsSearchFailed({errorMessage: Util.createErrorString(error, $localize`Fetching symbols failed.`)}));
  }

  fetchBestStatisticalReturnSearchResultsFromFirestore = createEffect(() => this.actions.pipe(
      ofType(fetchBestStatisticalReturnsSearchResults, fetchMoreBestStatisticalReturnsSearchResults),
      withLatestFrom(this.store.select(selectStock)),
      switchMap(action => this.handleBestStatisticalReturnsSearch(action[1])),
  ));

  fetchUpcomingStatisticalReturnSearchResultsFromFirestore = createEffect(() => this.actions.pipe(
      ofType(fetchUpcomingStatisticalReturnsSearchResults, fetchMoreUpcomingStatisticalReturnsSearchResults),
      withLatestFrom(this.store.select(selectStock)),
      switchMap(action => this.handleUpcomingStatisticalReturnsSearch(action[1])),
  ));

  setUpcomingStatisticalReturnsBuyDayNumber = createEffect(() => this.actions.pipe(
      ofType(setUpcomingStatisticalReturnsBuyDayNumber),
      switchMap(action => of(fetchUpcomingStatisticalReturnsSearchResults())),
  ));

  constructor(private actions: Actions,
              private httpClient: HttpClient,
              private router: Router,
              private facebookService: FacebookService,
              private analyticsService: AnalyticsService,
              private statisticalTradingService: StatisticalTradingService,
              private backendService: BackendService,
              private store: Store<AppState>) {
  }

  /**
   * Handles the firestore search request for best statistical returns and maps the response to handleBestStatisticalReturnsSearchResponse.
   * @param stockState current stock state
   * @return Observable of search request
   */
  private handleBestStatisticalReturnsSearch(stockState: StockState): Observable<any> {

    const searchParams: StatisticalReturnsSearchParams = stockState.bestStatisticalReturnsSearch.searchParams;
    const limit = searchParams.limit ? searchParams.limit : environment.defaultLoadStatisticalReturnsCount;

    let query = firestore.collectionGroup(environment.firestoreCollectionStatisticalReturns)
        .withConverter(statisticalReturnConverter)
        .limit(limit);
    query = this.addWhereAndOrderByToStatisticalReturnSearchQuery(query, searchParams, 'best');

    try {
      return from(query.get()).pipe(
          map(response => this.handleBestStatisticalReturnsSearchResponse(response, searchParams, limit)),
          catchError((error) => this.handleBestStatisticalReturnsSearchError(error)),
      );
    } catch (error) {
      return this.handleBestStatisticalReturnsSearchError(error);
    }
  }

  handleBestStatisticalReturnsSearchResponse = (statisticalReturnQuerySnapshot: QuerySnapshot<StatisticalReturn>, searchParams: StatisticalReturnsSearchParams, limit: number) => {
    const searchResult = this.handleStatisticalReturnsSearchResponseHelper(statisticalReturnQuerySnapshot, limit);
    return setBestStatisticalReturnsSearchResults({searchResult});
  };

  private handleStatisticalReturnsSearchResponseHelper(statisticalReturnQuerySnapshot: firebase.firestore.QuerySnapshot<StatisticalReturn>, limit: number) {
    const statisticalReturns: StatisticalReturn[] = [];
    let now = new Date();
    statisticalReturnQuerySnapshot.forEach((statisticalReturnDocSnapshot: DocumentSnapshot<StatisticalReturn>) => {
      const statisticalReturn: StatisticalReturn | undefined = statisticalReturnDocSnapshot.data();
      if (statisticalReturn) {
        const statisticalReturnWithIdAndDate = {
          ...statisticalReturn,
          uid: statisticalReturnDocSnapshot.id,
          cacheDate: now,
        };
        statisticalReturns.push(statisticalReturnWithIdAndDate);
      }
    });

    const lastVisible = Object.freeze(statisticalReturnQuerySnapshot.docs[statisticalReturnQuerySnapshot.docs.length - 1]);

    const thereIsMore = statisticalReturnQuerySnapshot.size !== 0 && (limit !== undefined && statisticalReturnQuerySnapshot.size >= limit);
    const searchResult: StatisticalReturnsSearchResult = {
      statisticalReturns,
      lastVisible,
      thereIsMore,
      allFilteredOut: false,
    };
    return searchResult;
  }

  private handleBestStatisticalReturnsSearchError(error: any): Observable<any> {
    return of(bestStatisticalReturnsSearchFailed({errorMessage: Util.createErrorString(error, $localize`Fetching the best statistical returns failed.`)}));
  }

  /**
   * Handles the firestore search request for upcoming statistical returns and maps the response to handleUpcomingStatisticalReturnsSearchResponse.
   * @param stockState current stock state
   * @return Observable of search request
   */
  private handleUpcomingStatisticalReturnsSearch(stockState: StockState): Observable<any> {

    const searchParams: StatisticalReturnsSearchParams = stockState.upcomingStatisticalReturnsSearch.searchParams;
    const limit = searchParams.limit ? searchParams.limit : environment.defaultLoadUpcomingStatisticalReturnsCount;

    let query = firestore.collectionGroup(environment.firestoreCollectionStatisticalReturns)
        .withConverter(statisticalReturnConverter)
        .limit(limit);
    query = this.addWhereAndOrderByToStatisticalReturnSearchQuery(query, searchParams, 'upcoming');

    try {
      return from(query.get()).pipe(
          map(response => this.handleUpcomingStatisticalReturnsSearchResponse(response, searchParams, limit)),
          catchError((error) => this.handleUpcomingStatisticalReturnsSearchError(error)),
      );
    } catch (error) {
      return this.handleUpcomingStatisticalReturnsSearchError(error);
    }
  }

  private addWhereAndOrderByToStatisticalReturnSearchQuery(query: firebase.firestore.Query<StatisticalReturn>, searchParams: StatisticalReturnsSearchParams, viewType: StatisticalReturnViewType) {

    if (searchParams.buyDayNumber !== undefined)
      query = query.where('buyDayNumber', '==', searchParams.buyDayNumber);
    if (searchParams.exchangeShortNames && searchParams.exchangeShortNames.length > 0)
      query = query.where('exchangeShortName', 'in', searchParams.exchangeShortNames);

    let sortOrder: StatisticalReturnsSortOrder = searchParams.sortOrder ? searchParams.sortOrder : StatisticalReturnsSortOrder.AverageRelativeProfitDesc;
    if (viewType === 'upcoming') {
      // If sortOrder is buyDateAsc or buyDateDesc change it, because: Order by clause cannot contain a field with an equality filter buyDayNumber
      sortOrder = (sortOrder === StatisticalReturnsSortOrder.BuyDateDesc || sortOrder === StatisticalReturnsSortOrder.BuyDateAsc) ? StatisticalReturnsSortOrder.AverageRelativeProfitDesc : sortOrder;
    }

    let inequalityFilterSet = false;

    if (searchParams.minAverageRelativeProfit !== undefined) {
      query = query.where('averageRelativeProfit', '>=', searchParams.minAverageRelativeProfit / 100.0);
      query = query.orderBy('averageRelativeProfit', 'desc');
      inequalityFilterSet = true;
    }
    if (searchParams.maxDuration !== undefined && !inequalityFilterSet) {
      query = query.where('duration', '<=', searchParams.maxDuration);
      query = query.orderBy('duration', 'asc');
      inequalityFilterSet = true;
    }
    if (searchParams.minLowestYearlyProfit !== undefined && !inequalityFilterSet) {
      query = query.where('lowestYearlyProfit', '>=', searchParams.minLowestYearlyProfit / 100.0);
      query = query.orderBy('lowestYearlyProfit', 'desc');
      inequalityFilterSet = true;
    }
    if (searchParams.minWinRatio !== undefined && !inequalityFilterSet) {
      query = query.where('winRatio', '>=', searchParams.minWinRatio / 100.0);
      query = query.orderBy('winRatio', 'desc');
      inequalityFilterSet = true;
    }
    if (searchParams.minAverageMaxRise !== undefined && !inequalityFilterSet) {
      query = query.where('averageMaxRise', '>=', searchParams.minAverageMaxRise / 100.0);
      query = query.orderBy('averageMaxRise', 'desc');
      inequalityFilterSet = true;
    }
    if (searchParams.maxAverageMaxDrop !== undefined && !inequalityFilterSet) {
      query = query.where('averageMaxDrop', '<=', searchParams.maxAverageMaxDrop / 100.0);
      query = query.orderBy('averageMaxDrop', 'asc');
      inequalityFilterSet = true;
    }
    if (searchParams.minHighestRise !== undefined && !inequalityFilterSet) {
      query = query.where('highestMaxRise', '>=', searchParams.minHighestRise / 100.0);
      query = query.orderBy('highestMaxRise', 'desc');
      inequalityFilterSet = true;
    }
    if (searchParams.maxHighestDrop !== undefined && !inequalityFilterSet) {
      query = query.where('highestMaxDrop', '<=', searchParams.maxHighestDrop / 100.0);
      query = query.orderBy('highestMaxDrop', 'asc');
      inequalityFilterSet = true;
    }

    if (!inequalityFilterSet) {
      query = this.addStatisticalReturnsSortOrderTerm(sortOrder, query);
    }

    if (searchParams.startAfter)
      query = query.startAfter(searchParams.startAfter);

    return query;
  }

  private addStatisticalReturnsSortOrderTerm(sortOrder: StatisticalReturnsSortOrder, query: firebase.firestore.Query<StatisticalReturn>) {
    switch (sortOrder) {
      case StatisticalReturnsSortOrder.BuyDateAsc:
        return query.orderBy('buyDayNumber', 'asc');
      case StatisticalReturnsSortOrder.BuyDateDesc:
        return query.orderBy('buyDayNumber', 'desc');
      case StatisticalReturnsSortOrder.DurationAsc:
        return query.orderBy('duration', 'asc');
      case StatisticalReturnsSortOrder.DurationDesc:
        return query.orderBy('duration', 'desc');
      case StatisticalReturnsSortOrder.ExchangeShortNameAsc:
        return query.orderBy('exchangeShortName', 'asc');
      case StatisticalReturnsSortOrder.ExchangeShortNameDesc:
        return query.orderBy('exchangeShortName', 'desc');
      case StatisticalReturnsSortOrder.WorstYearAsc:
        return query.orderBy('lowestYearlyProfit', 'asc');
      case StatisticalReturnsSortOrder.WorstYearDesc:
        return query.orderBy('lowestYearlyProfit', 'desc');
      case StatisticalReturnsSortOrder.AvgMaxRiseAsc:
        return query.orderBy('averageMaxRise', 'asc');
      case StatisticalReturnsSortOrder.AvgMaxRiseDesc:
        return query.orderBy('averageMaxRise', 'desc');
      case StatisticalReturnsSortOrder.HighestMaxRiseAsc:
        return query.orderBy('highestMaxRise', 'asc');
      case StatisticalReturnsSortOrder.HighestMaxRiseDesc:
        return query.orderBy('highestMaxRise', 'desc');
      case StatisticalReturnsSortOrder.AvgMaxDropAsc:
        return query.orderBy('averageMaxDrop', 'asc');
      case StatisticalReturnsSortOrder.AvgMaxDropDesc:
        return query.orderBy('averageMaxDrop', 'desc');
      case StatisticalReturnsSortOrder.HighestMaxDropAsc:
        return query.orderBy('highestMaxDrop', 'asc');
      case StatisticalReturnsSortOrder.HighestMaxDropDesc:
        return query.orderBy('highestMaxDrop', 'desc');
      case StatisticalReturnsSortOrder.SymbolCodeAsc:
        return query.orderBy('symbolCode', 'asc');
      case StatisticalReturnsSortOrder.SymbolCodeDesc:
        return query.orderBy('symbolCode', 'desc');
      case StatisticalReturnsSortOrder.WinRatioAsc:
        return query.orderBy('winRatio', 'asc');
      case StatisticalReturnsSortOrder.WinRatioDesc:
        return query.orderBy('winRatio', 'desc');
      default:
        return query.orderBy('averageRelativeProfit', 'desc');
    }
  }

  handleUpcomingStatisticalReturnsSearchResponse = (statisticalReturnQuerySnapshot: QuerySnapshot<StatisticalReturn>, searchParams: StatisticalReturnsSearchParams, limit: number) => {
    const searchResult = this.handleStatisticalReturnsSearchResponseHelper(statisticalReturnQuerySnapshot, limit);
    return setUpcomingStatisticalReturnsSearchResults({searchResult});
  };

  private handleUpcomingStatisticalReturnsSearchError(error: any): Observable<any> {
    return of(upcomingStatisticalReturnsSearchFailed({errorMessage: Util.createErrorString(error, $localize`Fetching upcoming statistical returns failed.`)}));
  }
}

