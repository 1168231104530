import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {Exchange} from '../../models/exchange.interface';
import {ExchangeService} from '../../services/exchange.service';
import Util from '../../util';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-exchange-selector',
  templateUrl: './exchange-selector.component.html',
  styleUrls: ['./exchange-selector.component.scss'],
})
export class ExchangeSelectorComponent extends BaseComponent implements OnInit {

  @Input() narrowButton? = false;
  @Input() bigButton? = false;
  @Input() showAllExchanges? = false;
  @Input() buttonColor? = 'primary';
  @Output() onExchangeSelected = new EventEmitter<Exchange | undefined>();
  @ViewChild('exchangeSelectorDropdown') public exchangeSelectorDropdown?: NgbDropdown;
  @ViewChild('search') public search?: ElementRef;

  allUnhiddenExchanges?: Exchange[];
  allExchanges?: Exchange[];
  filteredExchanges?: Exchange[];
  selectedExchange?: Exchange;

  constructor(authService: AuthService,
              userService: UserService,
              store: Store<AppState>,
              private exchangeService: ExchangeService) {
    super(authService, userService, store);

  }

  _selectedExchangeShortName?: string | null;

  get selectedExchangeShortName(): string | undefined | null {
    return this._selectedExchangeShortName;
  }

  @Input() set selectedExchangeShortName(selectedExchangeShortName: string | undefined | null) {
    this._selectedExchangeShortName = selectedExchangeShortName;
    this.determineSelectedExchange();
    this.filter();
  }

  _hiddenExchangeShortNames?: string[];

  get hiddenExchangeShortNames(): string[] | undefined {
    return this._hiddenExchangeShortNames;
  }

  @Input() set hiddenExchangeShortNames(hiddenExchangeShortNames: string[] | undefined | null) {
    this._hiddenExchangeShortNames = hiddenExchangeShortNames ? hiddenExchangeShortNames : [];
    this.hideExchanges();
    this.filter();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.enableLoadingSpinner($localize`Loading exchanges`);
    this.exchangeService.getCachedExchangesOrFetch().then(wrapper => {
      this.disableLoadingSpinner();
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
      if (wrapper.data) {
        this.allExchanges = [...wrapper.data];
        this.determineSelectedExchange();
        this.hideExchanges();
        this.filter();
      }
    });
  }


  public filter(filterTerm?: string) {
    if (filterTerm && this.allUnhiddenExchanges)
      this.filteredExchanges = this.filterExchanges(this.allUnhiddenExchanges, filterTerm);
    else
      this.filteredExchanges = this.allUnhiddenExchanges;
  }


  onSelectExchange(exchange?: Exchange) {
    if (this.search?.nativeElement)
      this.search.nativeElement.value = '';
    this.exchangeSelectorDropdown?.close();
    this.selectedExchangeShortName = exchange?.shortName;
    this.onExchangeSelected.emit(exchange);
  }

  private filterExchanges(exchanges: Exchange[], filterTerm: string): Exchange[] {
    return exchanges.filter(exchange => this.exchangeContainsFilterTerm(exchange, filterTerm));
  }

  private exchangeContainsFilterTerm(exchange: Exchange, filterText: string): boolean {
    if (exchange.name && this.stringContains(exchange.name, filterText))
      return true;
    if (exchange.shortName && this.stringContains(exchange.shortName, filterText))
      return true;
    return false;
  }

  private stringContains(fullString: string, searchedTerm: string): boolean {
    return fullString.toLocaleLowerCase().indexOf(searchedTerm.toLocaleLowerCase()) > -1;
  }

  determineSelectedExchange() {
    this.selectedExchange = this.allExchanges?.find(it => it.shortName === this.selectedExchangeShortName);
  }

  private hideExchanges() {
    if (!this.hiddenExchangeShortNames) {
      this.allUnhiddenExchanges = this.allExchanges;
      return;
    }
    this.allUnhiddenExchanges = this.allExchanges?.filter(it => !Util.arrayContains(this.hiddenExchangeShortNames!, it.shortName));
    // Deselect the selected exchange, if it is hidden
    if (Util.arrayContains(this.hiddenExchangeShortNames, this.selectedExchangeShortName)) {
      this.selectedExchangeShortName = undefined;
      this.selectedExchange = undefined;
    }
  }

  onDropDownOpen() {
    setTimeout(() => {
      this.search?.nativeElement.focus();
    }, 100);
  }

  onEnterKeyPressed() {
    if (this.filteredExchanges && this.filteredExchanges.length > 0)
      this.onSelectExchange(this.filteredExchanges[0]);
  }
}
