import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {addStatisticalTradingPlansToCache, addStatisticalTradingPlanToCache, clearStatisticalTradingPlanCache} from './planner.actions';
import {environment} from '../../environments/environment';
import {StatisticalTradingPlan} from '../shared/models/statisticalTradingPlan.interface';
import {initialState, PlannerState} from './planner.state';


function logDebugMessages(actionName: string, state: PlannerState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}


const reducer: ActionReducer<PlannerState, Action> = createReducer(
    initialState,

    // Caches

    on(addStatisticalTradingPlanToCache, (state, {statisticalTradingPlan}) => {
      const statisticalTradingPlans = mergeStatisticalTradingPlansRemovingOutdated(state.statisticalTradingPlans, [statisticalTradingPlan]);
      const newState = {...state, statisticalTradingPlans};
      logDebugMessages('addStatisticalTradingPlanToCache', state, newState);
      return newState;
    }),
    on(addStatisticalTradingPlansToCache, (state, {statisticalTradingPlans}) => {
      statisticalTradingPlans = mergeStatisticalTradingPlansRemovingOutdated(state.statisticalTradingPlans, statisticalTradingPlans);
      const newState = {...state, statisticalTradingPlans};
      logDebugMessages('addStatisticalTradingPlansToCache', state, newState);
      return newState;
    }),
    on(clearStatisticalTradingPlanCache, (state) => {
      const newState = {...state, statisticalTradingPlans: []};
      logDebugMessages('clearStatisticalTradingPlanCache', state, newState);
      return newState;
    }),
);

export function plannerReducer(state: PlannerState | undefined, action: Action): PlannerState {
  return reducer(state, action);
}

/**
 * Merges two arrays of statisticalTradingPlans removing all duplicates and outdated entries and only keeping the newer ones.
 * @param list1 first list. The order of the parameters does not matter.
 * @param list2 second list
 * @return merged list
 */
function mergeStatisticalTradingPlansRemovingOutdated(list1: StatisticalTradingPlan[], list2: StatisticalTradingPlan[]) {
  // Remove outdated elements from both lists
  const filteredList1 = list1.filter(it => !isOutdated(it, environment.defaultStatisticalTradingPlanCacheAgeInSec));
  const filteredList2 = list2.filter(it => !isOutdated(it, environment.defaultStatisticalTradingPlanCacheAgeInSec));

  const statisticalTradingPlansByCode = new Map<string, StatisticalTradingPlan>();
  filteredList1.forEach(it => addStatisticalTradingPlanToMap(it, statisticalTradingPlansByCode));
  filteredList2.forEach(it => addStatisticalTradingPlanToMap(it, statisticalTradingPlansByCode));

  return [...statisticalTradingPlansByCode.values()];
}


function isOutdated(cachedElement: any, cacheAgeInSec: number): boolean {
  const nowUtc = new Date().getTime();
  return !cachedElement.cacheDate || (nowUtc - cachedElement.cacheDate.getTime() > cacheAgeInSec * 1000);
}

/**
 * Adds the given statisticalTradingPlan to the given map, but only, if the map doesn't contain a newer item
 * @param statisticalTradingPlan
 * @param statisticalTradingPlansByCode
 */
function addStatisticalTradingPlanToMap(statisticalTradingPlan: StatisticalTradingPlan, statisticalTradingPlansByCode: Map<string, StatisticalTradingPlan>) {
  if (!statisticalTradingPlan.uid)
    return;
  const statisticalTradingPlanFromMap = statisticalTradingPlansByCode.get(statisticalTradingPlan.uid);
  if (!statisticalTradingPlanFromMap)
    statisticalTradingPlansByCode.set(statisticalTradingPlan.uid, statisticalTradingPlan);
  else
    statisticalTradingPlansByCode.set(statisticalTradingPlan.uid, getNewerCacheItem(statisticalTradingPlan, statisticalTradingPlanFromMap));
}


function getNewerCacheItem<T>(cachedElement1: any, cachedElement2: any): T {
  if (cachedElement1.cacheDate >= cachedElement2.cacheDate)
    return cachedElement1;
  return cachedElement2;
}


