import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {UtilService} from '../../../shared/util.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../shared/services/title.service';
import {takeUntil} from 'rxjs/operators';
import {StatisticalTradingPlan} from '../../../shared/models/statisticalTradingPlan.interface';
import {PlannerService} from '../planner.service';
import {BreadcrumbPage} from '../../../shared/components/breadcrumb/breadcrumb.component';
import {environment} from '../../../../environments/environment';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DataType, IncludedExcludedModalComponent} from '../helper-components/included-excluded-modal/included-excluded-modal.component';
import {SymbolAndStatisticalReturnUid} from '../../../shared/models/symbolAndStatisticalReturnUid.interface';
import {StatisticalTradingService} from '../../statistical-trading.service';
import Util from '../../../shared/util';
import {PlanOptimizationStartData} from '../helper-components/statistical-trading-plan-actions-menu/statistical-trading-plan-actions-menu.component';

@Component({
  selector: 'app-statistical-trading-plan-view',
  templateUrl: './statistical-trading-plan-view.component.html',
  styleUrls: ['./statistical-trading-plan-view.component.scss'],
})
export class StatisticalTradingPlanViewComponent extends BaseComponent implements OnInit {
  public baseCurrency = environment.baseCurrency;
  planUid?: string;
  plan?: StatisticalTradingPlan;

  private readonly spinnerKeyTradingPlan = 'fetchTradingPlan';
  private readonly spinnerKeyOptimizePlan = 'optimizePlan';

  breadcrumbPages: BreadcrumbPage[] = [
    {routerLink: '../..', title: $localize`Statistical trading planner`},
    {routerLink: '..', title: $localize`My plans`},
  ];
  private modalRef?: NgbModalRef;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    public utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private plannerService: PlannerService,
    private statisticalTradingService: StatisticalTradingService,
    private router: Router,
    private modalService: NgbModal,
    private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Trading plan`);

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
      (params) => {
        this.clearAlerts();
        if (this.planUid !== params.planUid)
          this.resetComponent();
        this.planUid = params.planUid;
        if (this.planUid) {
          this.titleService.setTitle($localize`Trading plan`);
          this.fetchTradingPlan(this.planUid);
        }
      });
  }

  private resetComponent() {
    this.plan = undefined;
  }

  private fetchTradingPlan(planUid: string) {
    this.user$.subscribe(user => {
      if (!user?.uid)
        return;

      this.addLoadingSpinnerMessage(this.spinnerKeyTradingPlan, $localize`Fetching trading plan ${this.planUid}`);
      this.plannerService.fetchPlan(user.uid, planUid).then(wrapper => {
        this.removeLoadingSpinnerMessage(this.spinnerKeyTradingPlan);
        if (wrapper.data) {
          this.plan = wrapper.data;
          this.titleService.setTitle($localize`Trading plan ${this.plan.name}`);
        }
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
      });

    });
  }

  showDataInModal(dataType: DataType) {

    this.modalRef = this.modalService.open(IncludedExcludedModalComponent, {
      centered: true, size: 'xl',
    });
    this.modalRef.componentInstance.dataType = dataType;
    switch (dataType) {
      case 'excludedTrades':
        this.modalRef.componentInstance.itemUids = this.plan?.excludedTrades;
        this.modalRef.componentInstance.onDeleteStatisticalReturnClicked.pipe(takeUntil(this.destroy$)).subscribe((symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid) => {
          this.deleteStatisticalReturnFromIncludedOrExcludedTrades(symbolAndStatisticalReturnUid, dataType);
        });
        break;
      case 'includedTrades':
        this.modalRef.componentInstance.itemUids = this.plan?.includedTrades;
        this.modalRef.componentInstance.onDeleteStatisticalReturnClicked.pipe(takeUntil(this.destroy$)).subscribe((symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid) => {
          this.deleteStatisticalReturnFromIncludedOrExcludedTrades(symbolAndStatisticalReturnUid, dataType);
        });
        break;
      case 'excludedSymbolCodes':
        this.modalRef.componentInstance.itemUids = this.plan?.excludedSymbolCodes;
        this.modalRef.componentInstance.onDeleteSymbolClicked.pipe(takeUntil(this.destroy$)).subscribe((symbolCode: string) => {
          this.deleteSymbolFromExcludedSymbols(symbolCode);
        });
        break;
    }

  }

  planDeleted(planUid: string) {
    this.router.navigate(['/statistical-trading', 'planner', 'plans']);
  }

  planDeletionFailed(errorMessage: string) {
    this.addError(errorMessage);
  }

  onPlanEdited(editedPlan: StatisticalTradingPlan) {
    this.plan = editedPlan;
  }

  deleteStatisticalReturnFromIncludedOrExcludedTrades(symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid, dataType: DataType) {
    if (!this.plan || !this.user?.uid)
      return;

    let trades: string[] = [];
    switch (dataType) {
      case 'excludedTrades':
        trades = this.plan?.excludedTrades ? [...this.plan.excludedTrades] : [];
        trades = this.removeSymbolAndStatisticalReturnUidFromTrades(symbolAndStatisticalReturnUid, trades);
        this.plan = {...this.plan, excludedTrades: trades};
        if (this.modalRef)
          this.modalRef.componentInstance.itemUids = this.plan?.excludedTrades;

        break;
      case 'includedTrades':
        trades = this.plan?.includedTrades ? [...this.plan.includedTrades] : [];
        trades = this.removeSymbolAndStatisticalReturnUidFromTrades(symbolAndStatisticalReturnUid, trades);
        this.plan = {...this.plan, includedTrades: trades};
        if (this.modalRef)
          this.modalRef.componentInstance.itemUids = this.plan?.includedTrades;

        break;
    }
    this.plannerService.savePlan(this.plan, this.user.uid, this.planUid);
  }

  private removeSymbolAndStatisticalReturnUidFromTrades(symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid, trades: string[]): string[] {
    let statisticalReturnCacheId = StatisticalTradingService.determineStatisticalReturnCacheId(symbolAndStatisticalReturnUid.symbolCode, symbolAndStatisticalReturnUid.statisticalReturnUid);
    if (!statisticalReturnCacheId)
      return trades;
    return Util.removeFromArray(trades, statisticalReturnCacheId);
  }

  private deleteSymbolFromExcludedSymbols(symbolCode: string) {
    if (!this.plan || !this.user?.uid)
      return;
    let excludedSymbolCodes = this.plan?.excludedSymbolCodes ? [...this.plan.excludedSymbolCodes] : [];
    excludedSymbolCodes = this.removeSymbolCodeFromExcludedSymbolCodes(symbolCode, excludedSymbolCodes);
    this.plan = {...this.plan, excludedSymbolCodes: excludedSymbolCodes};
    if (this.modalRef)
      this.modalRef.componentInstance.itemUids = this.plan?.excludedSymbolCodes;

    this.plannerService.savePlan(this.plan, this.user.uid, this.planUid);
  }

  private removeSymbolCodeFromExcludedSymbolCodes(symbolCode: string, excludedSymbolCodes: string[]) {
    if (!symbolCode)
      return excludedSymbolCodes;
    return Util.removeFromArray(excludedSymbolCodes, symbolCode);
  }


  optimizationStarted(startData: PlanOptimizationStartData) {
    this.clearAlerts();
    this.addLoadingSpinnerMessage(this.spinnerKeyOptimizePlan, $localize`Optimizing your plan...`);
    startData.optimizedPlanPromise.then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyOptimizePlan);
      if (wrapper.success && wrapper.data) {
        this.plan = wrapper.data;
        this.addSuccess($localize`Plan '${startData.planName}' was optimized successfully.`);
      }

      if (wrapper.errorMessage && !wrapper.success)
        this.addError($localize`Optimizing plan '${startData.planName}' failed: ` + wrapper.errorMessage);
    });
  }
}
