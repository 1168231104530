import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BackendService} from '../../shared/services/backend.service';
import {Router} from '@angular/router';
import {UserService} from '../../shared/services/user.service';
import {UtilService} from '../../shared/util.service';
import {StatisticalTradingPlan, StatisticalTradingPlanTypeValue} from '../../shared/models/statisticalTradingPlan.interface';
import {firestore} from '../../app.module';
import {environment} from '../../../environments/environment';
import {FormGroup} from '@angular/forms';
import {Wrapper} from '../../shared/models/wrapper.model';
import {convertToStatisticalTradingPlan} from '../../shared/converters/modelConverters';
import {statisticalTradingPlanConverter} from '../../shared/services/stock.service';
import {Mutex} from 'async-mutex';
import {takeUntil} from 'rxjs/operators';
import {addStatisticalTradingPlansToCache, addStatisticalTradingPlanToCache} from '../../store/planner.actions';
import {selectCachedStatisticalTradingPlans} from '../../store/planner.selectors';
import Util from '../../shared/util';
import {StatisticalTrade, StatisticalTradeProperties} from '../../shared/models/statisticalTrade.interface';
import {StatisticalTradeStep, SymbolWithPrice} from '../../shared/models/statisticalTradeStep.interface';
import {TradeAction} from '../../shared/enums/tradeAction.enum';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {StatisticalTradeDeletionResult} from '../../shared/models/statisticalTradeDeletionResult.interface';
import {StatisticalTradingService} from '../statistical-trading.service';
import {MemoryCache} from '../../shared/models/memoryCache.interface';
import {PayloadResultJo} from '../../shared/models/results/payloadResultJo.interface';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import firebase from 'firebase';
import {AppState} from '../../store/app.state';
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class PlannerService {

  destroy$: Subject<null> = new Subject();

  statisticalTradingPlanMutex = new Mutex();
  statisticalTradingPlans: StatisticalTradingPlan[] = [];
  statisticalTradingPlansById: Map<string, StatisticalTradingPlan> = new Map<string, StatisticalTradingPlan>();
  userPlansCacheByUserUid: Map<string, MemoryCache<StatisticalTradingPlan[]>> = new Map();

  optimizationFinishedSnackBarRef ?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              private backendService: BackendService,
              private matSnackBar: MatSnackBar,
              private router: Router,
              private userService: UserService,
              private utilService: UtilService) {
    this.init();
  }

  init(): void {
    this.store.select(selectCachedStatisticalTradingPlans).pipe(takeUntil(this.destroy$)).subscribe(statisticalTradingPlans => {
      if (statisticalTradingPlans) {
        this.statisticalTradingPlansById = new Map<string, StatisticalTradingPlan>();
        statisticalTradingPlans.forEach(statisticalTradingPlan => {
          if (statisticalTradingPlan.uid)
            this.statisticalTradingPlansById.set(statisticalTradingPlan.uid, statisticalTradingPlan);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  createPlanObject(name: string, typeValue: StatisticalTradingPlanTypeValue, automaticPlannerSettingsFormGroup?: FormGroup, exchangeShortNames?: string[],
                   excludedSymbolCodes?: string[], excludedTrades?: string[], includedTrades?: string[], originalPlan?: StatisticalTradingPlan) {
    let plan: StatisticalTradingPlan = {
      name: name,
      typeValue: typeValue,
      seedCapital: automaticPlannerSettingsFormGroup?.value?.seedCapital,
      maxCapitalPerTrade: Util.fromPercentageNonUndefined(automaticPlannerSettingsFormGroup?.value?.maxCapitalPerTrade),
      currency: automaticPlannerSettingsFormGroup?.value?.currency,
    };
    if (automaticPlannerSettingsFormGroup?.value?.minAverageRelativeProfit)
      plan = {...plan, minAverageRelativeProfit: Util.fromPercentage(automaticPlannerSettingsFormGroup.value.minAverageRelativeProfit)};
    if (automaticPlannerSettingsFormGroup?.value?.minAverageRelativeProfitLast5Years)
      plan = {...plan, minAverageRelativeProfitLast5Years: Util.fromPercentage(automaticPlannerSettingsFormGroup?.value?.minAverageRelativeProfitLast5Years)};
    if (automaticPlannerSettingsFormGroup?.value?.minAverageRelativeProfitLast10Years)
      plan = {...plan, minAverageRelativeProfitLast10Years: Util.fromPercentage(automaticPlannerSettingsFormGroup?.value?.minAverageRelativeProfitLast10Years)};
    if (automaticPlannerSettingsFormGroup?.value?.minWinRatio)
      plan = {...plan, minWinRatio: Util.fromPercentage(automaticPlannerSettingsFormGroup?.value?.minWinRatio)};
    if (automaticPlannerSettingsFormGroup?.value?.minPricePerStock)
      plan = {...plan, minPricePerStock: automaticPlannerSettingsFormGroup?.value?.minPricePerStock};
    if (automaticPlannerSettingsFormGroup?.value?.minAverageVolumeBaseCurrency)
      plan = {...plan, minAverageVolumeBaseCurrency: automaticPlannerSettingsFormGroup?.value?.minAverageVolumeBaseCurrency * 1000};
    if (automaticPlannerSettingsFormGroup?.value?.minMarketCap)
      plan = {...plan, minMarketCap: automaticPlannerSettingsFormGroup?.value?.minMarketCap * 1000000};
    if (automaticPlannerSettingsFormGroup?.value?.maxAverageMaxDrop)
      plan = {...plan, maxAverageMaxDrop: Util.fromPercentage(automaticPlannerSettingsFormGroup?.value?.maxAverageMaxDrop)};
    if (automaticPlannerSettingsFormGroup?.value?.minDataYears)
      plan = {...plan, minDataYears: automaticPlannerSettingsFormGroup?.value?.minDataYears};
    if (automaticPlannerSettingsFormGroup?.value?.maxDuration)
      plan = {...plan, maxDuration: automaticPlannerSettingsFormGroup?.value?.maxDuration};
    if (automaticPlannerSettingsFormGroup?.value?.taxRate)
      plan = {...plan, taxRate: Util.fromPercentage(automaticPlannerSettingsFormGroup?.value?.taxRate)};
    if (automaticPlannerSettingsFormGroup?.value?.orderFeeFixed)
      plan = {...plan, orderFeeFixed: automaticPlannerSettingsFormGroup?.value?.orderFeeFixed};
    if (automaticPlannerSettingsFormGroup?.value?.orderFeeRelative)
      plan = {...plan, orderFeeRelative: Util.fromPercentage(automaticPlannerSettingsFormGroup?.value?.orderFeeRelative)};
    if (exchangeShortNames)
      plan = {...plan, exchangeShortNames: exchangeShortNames};
    if (excludedSymbolCodes)
      plan = {...plan, excludedSymbolCodes: excludedSymbolCodes};
    if (excludedTrades)
      plan = {...plan, excludedSymbolCodes: excludedTrades};
    if (includedTrades)
      plan = {...plan, excludedSymbolCodes: includedTrades};
    plan = {...plan, trades: originalPlan?.trades ? originalPlan.trades : []};
    if (originalPlan?.uid)
      plan = {...plan, uid: originalPlan.uid};
    if (originalPlan?.lastOptimizationDate)
      plan = {...plan, lastOptimizationDate: originalPlan.lastOptimizationDate};
    if (originalPlan?.lastEditDate)
      plan = {...plan, lastEditDate: originalPlan.lastEditDate};
    if (originalPlan?.creationDate)
      plan = {...plan, creationDate: originalPlan.creationDate};
    return plan;
  }

  async savePlan(plan: StatisticalTradingPlan, userUid: string, planUid?: string): Promise<Wrapper<StatisticalTradingPlan>> {
    const now = Timestamp.now();

    try {
      delete plan.cacheDate;
      delete plan.cacheUid;
      if (planUid) {
        // Update
        plan = {...plan, lastEditDate: now};
        await firestore.collection(environment.firestoreCollectionUsers).doc(userUid).collection(environment.firestoreCollectionStatisticalTradingPlans).doc(planUid).set(plan);
      } else {
        // Create
        plan = {...plan, creationDate: now};
        let documentReference = await firestore.collection(environment.firestoreCollectionUsers).doc(userUid).collection(environment.firestoreCollectionStatisticalTradingPlans).add(plan);
        plan = {...plan, uid: documentReference.id};
      }
      this.addPlanToCache(plan, userUid);
      return new Wrapper<StatisticalTradingPlan>(plan, undefined, undefined, true);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<StatisticalTradingPlan>(undefined, $localize`You are not allowed to save this statistical trading plan.`, undefined, false);
      return new Wrapper<StatisticalTradingPlan>(undefined, e.message, undefined, false);
    }
  }

  async fetchUserPlans(userUid: string, maxAgeInSec: number = environment.defaultStatisticalTradingPlanCacheAgeInSec): Promise<Wrapper<StatisticalTradingPlan[]>> {
    try {
      // Load from Cache
      const userPlansFromCache = this.getUserPlansFromMemoryCacheIfUpToDate(userUid, maxAgeInSec);
      if (userPlansFromCache)
        return new Wrapper<StatisticalTradingPlan[]>(userPlansFromCache, undefined, undefined, true);

      // Nothing or nothing up-to-date found in cache
      let querySnapshot = await firestore.collection(environment.firestoreCollectionUsers).doc(userUid)
        .collection(environment.firestoreCollectionStatisticalTradingPlans)
        .orderBy('creationDate', 'desc')
        .withConverter(statisticalTradingPlanConverter).get();

      const statisticalTradingPlans: StatisticalTradingPlan[] = [];
      querySnapshot.forEach(docSnapshot => {
        const statisticalTradingPlan: StatisticalTradingPlan = convertToStatisticalTradingPlan(docSnapshot.data(), docSnapshot.id);
        statisticalTradingPlans.push(statisticalTradingPlan);
      });
      this.addPlansToCache(statisticalTradingPlans);
      this.addUserPlansToMemoryCache(statisticalTradingPlans, userUid);
      return new Wrapper<StatisticalTradingPlan[]>(statisticalTradingPlans, undefined, undefined, true);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<StatisticalTradingPlan[]>(undefined, $localize`You are not allowed to view these statistical trading plans.`, undefined, false);
      return new Wrapper<StatisticalTradingPlan[]>(undefined, e.message, undefined, false);
    }
  }

  /**
   * Fetches the statistical trading plan with the given planUid from the firestore database.
   */
  async fetchPlan(userUid: string, planUid: string, maxAgeInSec: number = environment.defaultStatisticalTradingPlanCacheAgeInSec): Promise<Wrapper<StatisticalTradingPlan>> {
    return this.statisticalTradingPlanMutex.runExclusive(async () => {
      let statisticalTradingPlanFromCache = this.statisticalTradingPlansById.get(planUid);
      if (statisticalTradingPlanFromCache !== undefined && this.isPlanUpToDate(statisticalTradingPlanFromCache, maxAgeInSec)) {
        statisticalTradingPlanFromCache = PlannerService.fixTimestamps(statisticalTradingPlanFromCache);
        return new Wrapper<StatisticalTradingPlan>(statisticalTradingPlanFromCache);
      }

      try {
        let statisticalTradingPlanDocSnapshot = await firestore.collection(environment.firestoreCollectionUsers).doc(userUid)
          .collection(environment.firestoreCollectionStatisticalTradingPlans).doc(planUid).withConverter(statisticalTradingPlanConverter).get();
        const statisticalTradingPlan = statisticalTradingPlanDocSnapshot.data();
        if (statisticalTradingPlan) {
          const statisticalTradingPlanWithIdAndCacheDate: StatisticalTradingPlan = {
            ...statisticalTradingPlan, uid: statisticalTradingPlanDocSnapshot.id, cacheDate: new Date(),
          };
          this.addPlanToCache(statisticalTradingPlanWithIdAndCacheDate, userUid);
          return new Wrapper<StatisticalTradingPlan>(statisticalTradingPlanWithIdAndCacheDate, undefined, undefined, true);
        }
      } catch (e: any) {
        return new Wrapper<StatisticalTradingPlan>(undefined, e.message);
      }

      // If no statisticalTradingPlan was found
      return new Wrapper<StatisticalTradingPlan>(undefined);
    });
  }

  /**
   * Checks, if the given statisticalTradingPlan is newer then the given max age.
   * @param statisticalTradingPlan statisticalTradingPlan  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isPlanUpToDate(statisticalTradingPlan: StatisticalTradingPlan, maxAgeInSec: any): boolean {
    if (!statisticalTradingPlan.cacheDate)
      return false;
    const cacheTime = statisticalTradingPlan.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  addPlanToCache(statisticalTradingPlan?: StatisticalTradingPlan, userUid?: string) {
    if (!statisticalTradingPlan)
      return;
    const now = new Date();
    statisticalTradingPlan = {...statisticalTradingPlan, cacheDate: now};
    if (statisticalTradingPlan.uid)
      this.statisticalTradingPlansById.set(statisticalTradingPlan.uid, statisticalTradingPlan);
    this.store.dispatch(addStatisticalTradingPlanToCache({statisticalTradingPlan}));
    if (userUid)
      this.updatePlanInMemoryCache(statisticalTradingPlan, userUid);
  }

  private addPlansToCache(statisticalTradingPlans: StatisticalTradingPlan[]) {
    const now = new Date();
    statisticalTradingPlans.forEach(statisticalTradingPlan => {
      statisticalTradingPlan.cacheDate = now;
      if (statisticalTradingPlan?.uid)
        this.statisticalTradingPlansById.set(statisticalTradingPlan.uid, statisticalTradingPlan);
    });
    this.store.dispatch(addStatisticalTradingPlansToCache({statisticalTradingPlans}));
  }

  private addUserPlansToMemoryCache(plans: StatisticalTradingPlan[], userUid: string) {
    const memoryCache: MemoryCache<StatisticalTradingPlan[]> = {cacheDate: new Date(), data: plans};
    this.userPlansCacheByUserUid.set(userUid, memoryCache);
  }

  private getUserPlansFromMemoryCacheIfUpToDate(userUid: string, maxAgeInSec: number): StatisticalTradingPlan[] | undefined {
    let memoryCache = this.userPlansCacheByUserUid.get(userUid);
    if (!memoryCache)
      return undefined;
    const now = new Date();
    if (!memoryCache.cacheDate || ((now.getTime() - memoryCache.cacheDate.getTime()) / 1000 > maxAgeInSec))
      return undefined;
    return memoryCache.data ? memoryCache.data : undefined;
  }

  private updatePlanInMemoryCache(plan: StatisticalTradingPlan, userUid: string) {
    let memoryCache = this.userPlansCacheByUserUid.get(userUid);
    if (!memoryCache)
      return;
    const oldPlan = memoryCache.data?.find(it => it.uid === plan.uid);
    if (!oldPlan)
      return;
    const plans = Util.removeFromArray([...memoryCache.data!], oldPlan);
    plans.push(plan);
    memoryCache = {cacheDate: new Date(), data: plans};
    this.userPlansCacheByUserUid.set(userUid, memoryCache);
  }

  async deletePlan(userUid: string, planUid: string): Promise<Wrapper<void>> {
    try {
      await firestore.collection(environment.firestoreCollectionUsers).doc(userUid)
        .collection(environment.firestoreCollectionStatisticalTradingPlans).doc(planUid).delete();
      return new Wrapper<void>(undefined, undefined, undefined, true);

    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<void>(undefined, $localize`You are not allowed to delete this statistical trading plan.`, undefined, false);
      return new Wrapper<void>(undefined, e.message, undefined, false);
    }
  }

  determineSteps(trades: StatisticalTrade[], seedCapital: number): StatisticalTradeStep[] {
    const tradesByUid = this.createTradesByUidMap(trades);
    let steps: StatisticalTradeStep[] = [];
    // 1. Put all trades into the steps array
    trades.forEach(it => steps.push(...this.createStepsForTrade(it)));

    // 2. Sort steps by date
    steps = steps.sort((a, b) => a.date.getTime() - b.date.getTime());

    // 3. Iterate all steps and calculate the depotValue, available cash and depot contents for every step
    const depotContents: StatisticalTrade[] = [];
    let availableCash = seedCapital;
    steps.forEach(step => {
      const trade = tradesByUid.get(this.getSymbolAndStatRetUid(step.symbolCode, step.statisticalReturnUid));
      step.walletBeforeAction = availableCash;
      if (step.action === TradeAction.Buy) {
        availableCash -= step.priceGross;
        depotContents.push(trade!);
      }
      if (step.action === TradeAction.Sell) {
        availableCash += step.priceGross - step.taxAbsolute - step.orderFeeAbsolute;
        Util.removeFromArray(depotContents, trade!);
      }
      step.walletAfterAction = availableCash;
      step.depotContentsAfterAction = this.determineDepotContents(depotContents, step.date);
      step.depotValueAfterAction = this.determineTotalStockValue(step.depotContentsAfterAction);
      step.depotAndWalletValue = availableCash + this.determineTotalStockValue(step.depotContentsAfterAction);
      step.growthAbsoluteSinceStart = step.depotAndWalletValue - seedCapital;
      step.growthRelativeSinceStart = step.growthAbsoluteSinceStart / seedCapital;
    });
    return steps;
  }

  private createStepsForTrade(trade: StatisticalTrade): StatisticalTradeStep[] {
    const buyAndSelLStep: StatisticalTradeStep[] = [];

    const buyStep: StatisticalTradeStep = {
      symbolCode: trade.symbolCode,
      statisticalReturnUid: trade.statisticalReturnUid,
      action: TradeAction.Buy,
      realized: trade.bought,
      date: Util.getDate(trade.actual?.buyDate ? trade.actual.buyDate : trade.target.buyDate),
      priceGross: trade.actual?.buyPrice ? trade.actual.buyPrice : trade.target.buyPrice!,
      priceNet: 0, // Will be added in a later step
      orderFeeAbsolute: 0, // Order fee is deducted when selling
      taxAbsolute: 0,  // Tax is deducted when selling
      profitRelativeGross: 0, // Profit is made when selling
      profitRelativeNet: 0, // Profit is made when selling
      profitAbsoluteGross: 0, // Profit is made when selling
      profitAbsoluteNet: 0, // Profit is made when selling
      depotValueAfterAction: 0, // Will be added in a later step
      depotAndWalletValue: 0, // Will be added in a later step
      depotContentsAfterAction: [], // Will be added in a later step
      walletBeforeAction: 0, // Will be added in a later step
      walletAfterAction: 0, // Will be added in a later step
      growthAbsoluteSinceStart: 0, // Will be added in a later step
      growthRelativeSinceStart: 0, // Will be added in a later step
    };
    buyStep.priceNet = buyStep.priceGross - buyStep.orderFeeAbsolute - buyStep.taxAbsolute;

    const sellStep: StatisticalTradeStep = {
      symbolCode: trade.symbolCode,
      statisticalReturnUid: trade.statisticalReturnUid,
      action: TradeAction.Sell,
      realized: trade.sold,
      date: Util.getDate(trade?.actual?.sellDate ? trade.actual.sellDate : trade.target.sellDate),
      priceGross: trade?.actual?.sellPrice ? trade.actual.sellPrice : trade.target.sellPrice!,
      priceNet: 0, // Will be added in a later step
      orderFeeAbsolute: trade?.actual?.orderFeeAbsolute ? trade.actual.orderFeeAbsolute : trade.target.orderFeeAbsolute!,
      taxAbsolute: trade?.actual?.taxAbsolute ? trade.actual.taxAbsolute : trade.target.taxAbsolute!,
      profitRelativeGross: 0, // Will be calculated below
      profitRelativeNet: 0, // Will be calculated below
      profitAbsoluteGross: 0, // Will be calculated below
      profitAbsoluteNet: 0, // Will be calculated below
      depotValueAfterAction: 0, // Will be added in a later step
      depotAndWalletValue: 0, // Will be added in a later step
      depotContentsAfterAction: [], // Will be added in a later step
      walletBeforeAction: 0, // Will be added in a later step
      walletAfterAction: 0, // Will be added in a later step
      growthAbsoluteSinceStart: 0, // Will be added in a later step
      growthRelativeSinceStart: 0, // Will be added in a later step
    };

    sellStep.profitAbsoluteGross = sellStep.priceGross - buyStep.priceGross;
    sellStep.profitAbsoluteNet = sellStep.profitAbsoluteGross - sellStep.orderFeeAbsolute - sellStep.taxAbsolute;
    sellStep.profitRelativeGross = sellStep.profitAbsoluteGross / buyStep.priceGross;
    sellStep.profitRelativeNet = sellStep.profitAbsoluteNet / buyStep.priceGross;
    sellStep.priceNet = sellStep.priceGross - sellStep.orderFeeAbsolute - sellStep.taxAbsolute;

    buyAndSelLStep.push(buyStep, sellStep);
    return buyAndSelLStep;
  }

  private createTradesByUidMap(trades: StatisticalTrade[]): Map<string, StatisticalTrade> {
    const tradesByUid = new Map<string, StatisticalTrade>();
    trades.forEach(trade => tradesByUid.set(this.getSymbolAndStatRetUid(trade.symbolCode, trade.statisticalReturnUid), trade));
    return tradesByUid;
  }

  private getSymbolAndStatRetUid(symbolCode: string, statisticalReturnUid: string) {
    return symbolCode + '_' + statisticalReturnUid;
  }

  private determineDepotContents(depotContents: StatisticalTrade[], date: Date): SymbolWithPrice[] {
    return depotContents.map(trade => {
      const priceOnDate = this.calcPriceOnDate(trade, date);
      const symbolWithPrice: SymbolWithPrice = {
        symbolCode: trade.symbolCode,
        statisticalReturnUid: trade.statisticalReturnUid,
        price: priceOnDate,
      };
      return symbolWithPrice;
    });
  }

  private determineTotalStockValue(depotContents: SymbolWithPrice[]) {
    let totalStockValue = 0;
    depotContents.forEach(it => totalStockValue += it.price);
    return totalStockValue;
  }

  private calcPriceOnDate(trade: StatisticalTrade, date: Date) {
    const {buyDate, sellDate} = this.getBuyDateAndSellDate(trade);

    // Trade starts in the past and ends in the future
    const profitAbsoluteNet = trade?.actual?.profitAbsoluteNet ? trade.actual.profitAbsoluteNet : trade.target.profitAbsoluteNet!;
    const buyPrice = trade?.actual?.buyPrice ? trade.actual.buyPrice : trade.target.buyPrice!;
    const progressedMillis = date.getTime() - buyDate.getTime();
    const durationMillis = sellDate.getTime() - buyDate.getTime();
    const progressedPercentage = progressedMillis / durationMillis;
    return buyPrice + progressedPercentage * profitAbsoluteNet;
  }


  getBuyDateAndSellDate(trade: StatisticalTrade) {
    const buyDate = Util.getDate(trade?.actual?.buyDate ? trade.actual.buyDate : trade.target.buyDate);
    const sellDate = Util.getDate(trade?.actual?.sellDate ? trade.actual.sellDate : trade.target.sellDate);
    return {buyDate, sellDate};
  }

  async addStatisticalReturnToPlan(statisticalReturn: StatisticalReturn, planUid: string, userUid: string): Promise<Wrapper<StatisticalTradingPlan>> {
    const planWrapper = await this.fetchPlan(userUid, planUid, 0);
    if (planWrapper.errorMessage)
      return new Wrapper<StatisticalTradingPlan>(undefined, $localize`Error adding trade to statistical trading plan: ` + planWrapper.errorMessage, undefined, false);
    if (!planWrapper.success || !planWrapper.data)
      return new Wrapper<StatisticalTradingPlan>(undefined, $localize`Error adding trade to statistical trading plan: ` + $localize`Unknown error.`, undefined, false);
    let plan = planWrapper.data;
    const trade = this.convertStatisticalReturnToTrade(statisticalReturn, plan);
    if (trade === undefined)
      return new Wrapper<StatisticalTradingPlan>(undefined, $localize`Error adding trade to statistical trading plan: ` + $localize`The statistical return could not be converted to a statistical trade.`, undefined, false);
    let trades: StatisticalTrade[] = plan.trades ? [...plan.trades] : [];
    if (trades.find(it => it.statisticalReturnUid === statisticalReturn.uid))
      return new Wrapper<StatisticalTradingPlan>(undefined, $localize`The trade is already contained in the statistical trading plan ${plan.name}.`, undefined, false);
    trades.push(trade);
    trades = trades.sort(PlannerService.tradesSortByDateComparator());
    let includedTrades = this.addToIncludedTrades(plan, statisticalReturn.symbolCode, statisticalReturn.uid);
    let excludedTrades = this.removeFromExcludedTrades(plan, statisticalReturn.symbolCode, statisticalReturn.uid);
    plan = {...plan, trades, includedTrades, excludedTrades};
    const saveWrapper = await this.savePlan(plan, userUid, planUid);
    if (saveWrapper.success)
      return new Wrapper<StatisticalTradingPlan>(saveWrapper.data, undefined, undefined, true);
    if (saveWrapper.errorMessage)
      return new Wrapper<StatisticalTradingPlan>(plan, $localize`Error saving statistical trading plan after adding the new trade: ` + saveWrapper.errorMessage, undefined, false);
    // If we made it here, saving was not successful, but there is no error message
    return new Wrapper<StatisticalTradingPlan>(plan, $localize`Error saving statistical trading plan after adding the new trade: ` + $localize`Unknown error.`, undefined, false);
  }

  private addToIncludedTrades(plan: StatisticalTradingPlan, symbolCode: string, statisticalReturnUid: string | undefined) {
    let includedTrades = plan.includedTrades ? [...plan.includedTrades] : [];
    let includedTradeUid = StatisticalTradingService.determineStatisticalReturnCacheId(symbolCode, statisticalReturnUid);
    if (includedTradeUid && !Util.arrayContains(includedTrades, includedTradeUid))
      includedTrades.push(includedTradeUid);
    return includedTrades;
  }

  private addToExcludedTrades(plan: StatisticalTradingPlan, symbolCode: string, statisticalReturnUid: string) {
    let excludedTrades = plan.excludedTrades ? [...plan.excludedTrades] : [];
    let excludedTradeUid = StatisticalTradingService.determineStatisticalReturnCacheId(symbolCode, statisticalReturnUid);
    if (excludedTradeUid && !Util.arrayContains(excludedTrades, excludedTradeUid))
      excludedTrades.push(excludedTradeUid);
    return excludedTrades;
  }

  private removeFromIncludedTrades(plan: StatisticalTradingPlan, symbolCode: string, statisticalReturnUid: string | undefined) {
    let includedTrades = plan.includedTrades ? [...plan.includedTrades] : [];
    let includedTradeUid = StatisticalTradingService.determineStatisticalReturnCacheId(symbolCode, statisticalReturnUid);
    if (includedTradeUid)
      Util.removeFromArray(includedTrades, includedTradeUid);
    return includedTrades;
  }

  private removeFromExcludedTrades(plan: StatisticalTradingPlan, symbolCode: string, statisticalReturnUid: string | undefined) {
    let excludedTrades = plan.excludedTrades ? [...plan.excludedTrades] : [];
    let excludedTradeUid = StatisticalTradingService.determineStatisticalReturnCacheId(symbolCode, statisticalReturnUid);
    if (excludedTradeUid)
      Util.removeFromArray(excludedTrades, excludedTradeUid);
    return excludedTrades;
  }

  public static tradesSortByDateComparator() {
    return (a: StatisticalTrade, b: StatisticalTrade) => Util.getDate(a.target.buyDate).getTime() - Util.getDate(b.target.buyDate).getTime();
  }

  private convertStatisticalReturnToTrade(statRet: StatisticalReturn, plan: StatisticalTradingPlan): StatisticalTrade | undefined {
    if (!statRet.uid || !statRet.buyDayNumber || statRet.sellDay === undefined || statRet.sellMonth === undefined || statRet.averageRelativeProfit === undefined)
      return undefined;
    let currentYear = Util.getCurrentYear();
    let buyDate = Util.getDateFromDayNumber(statRet.buyDayNumber, currentYear);
    const buyPrice = this.calcBuyPriceOnDate(plan, buyDate);
    let targetTradeProperties: StatisticalTradeProperties;
    if (!buyPrice)
      targetTradeProperties = {
        buyDate: Timestamp.fromDate(buyDate),
        sellDate: Timestamp.fromDate(Util.getDateFromDayMonthYear(statRet.sellDay, statRet.sellMonth, currentYear)),
        buyPrice: 0, sellPrice: 0, orderFeeAbsolute: 0, taxAbsolute: 0, profitAbsoluteNet: 0,
        profitRelativeNet: 0, profitAbsoluteGross: 0, profitRelativeGross: 0,
      };
    else {
      const sellPrice = buyPrice * (1 + statRet.averageRelativeProfit);
      const orderFeeAbsolute = (plan.orderFeeFixed ? plan.orderFeeFixed : 0) + (plan.orderFeeRelative ? plan.orderFeeRelative * sellPrice : 0);
      const taxAbsolute = plan.taxRate ? (sellPrice - orderFeeAbsolute - buyPrice) * plan.taxRate : 0;
      const profitAbsoluteGross = sellPrice - buyPrice;
      const profitAbsoluteNet = sellPrice - buyPrice - orderFeeAbsolute - taxAbsolute;
      const profitRelativeGross = profitAbsoluteGross / buyPrice;
      const profitRelativeNet = profitAbsoluteNet / buyPrice;
      let sellYear = currentYear;
      // Check, if buy date is after sell date, e.g. buy in December, sell in January
      if (StatisticalTradingService.isBuyDayAfterSellDay(statRet))
        sellYear++;
      targetTradeProperties = {
        buyDate: Timestamp.fromDate(buyDate), sellDate: Timestamp.fromDate(Util.getDateFromDayMonthYear(statRet.sellDay, statRet.sellMonth, sellYear)),
        buyPrice, sellPrice, orderFeeAbsolute, taxAbsolute, profitAbsoluteNet, profitRelativeNet, profitAbsoluteGross, profitRelativeGross,
      };
    }

    const trade: StatisticalTrade = {
      statisticalReturnUid: statRet.uid, bought: false, sold: false, symbolCode: statRet.symbolCode,
      actual: {}, target: targetTradeProperties,
    };
    return trade;
  }

  private calcBuyPriceOnDate(plan: StatisticalTradingPlan, buyDate: Date): number {
    if (!plan.trades || plan.trades.length === 0)
      return plan.seedCapital * plan.maxCapitalPerTrade;

    let steps = this.determineSteps(plan.trades, plan.seedCapital);
    const stepsBeforeBuyDate = steps.filter(it => it.date <= buyDate);
    // If there are no steps before the buy date
    if (stepsBeforeBuyDate.length === 0)
      return plan.seedCapital * plan.maxCapitalPerTrade;

    let lastStepBeforeTrade = stepsBeforeBuyDate[stepsBeforeBuyDate.length - 1];
    let targetBuyPrice = lastStepBeforeTrade.depotAndWalletValue * plan.maxCapitalPerTrade;
    return Math.max(Math.min(targetBuyPrice, lastStepBeforeTrade.walletAfterAction), 0);
  }

  async deleteStatisticalReturnFromPlan(statisticalReturnUid: string, planUid: string, userUid: string): Promise<Wrapper<StatisticalTradeDeletionResult>> {
    const planWrapper = await this.fetchPlan(userUid, planUid, 0);
    if (planWrapper.errorMessage)
      return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error removing trade from statistical trading plan: ` + planWrapper.errorMessage, undefined, false);
    if (!planWrapper.success || !planWrapper.data)
      return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error removing trade from statistical trading plan: ` + $localize`Unknown error.`, undefined, false);
    let plan = planWrapper.data;
    if (plan === undefined)
      return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error removing trade from statistical trading plan: ` + $localize`The trading plan was not found.`, undefined, false);
    if (!plan.trades || plan.trades.length === 0)
      return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error removing trade from statistical trading plan: ` + $localize`The trading plan does not contain any trades.`, undefined, false);
    const tradeToDelete = [...plan.trades].find(it => it.statisticalReturnUid === statisticalReturnUid);
    if (!tradeToDelete)
      return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error removing trade from statistical trading plan: ` + $localize`The trading plan does not contain the trade you want to delete.`, undefined, false);
    let trades: StatisticalTrade[] = Util.removeFromArray([...plan.trades], tradeToDelete);
    let excludedTrades = this.addToExcludedTrades(plan, tradeToDelete.symbolCode, statisticalReturnUid);
    let includedTrades = this.removeFromIncludedTrades(plan, tradeToDelete.symbolCode, statisticalReturnUid);
    plan = {...plan, trades, excludedTrades, includedTrades};
    const saveWrapper = await this.savePlan(plan, userUid, planUid);
    if (saveWrapper.success && saveWrapper.data) {
      const deletionResult: StatisticalTradeDeletionResult = {resultingPlan: saveWrapper.data, deleteTrade: tradeToDelete};
      return new Wrapper<StatisticalTradeDeletionResult>(deletionResult, undefined, undefined, true);
    }
    if (saveWrapper.errorMessage)
      return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error saving statistical trading plan after removing the trade: ` + saveWrapper.errorMessage, undefined, false);
    // If we made it here, saving was not successful, but there is no error message
    return new Wrapper<StatisticalTradeDeletionResult>(undefined, $localize`Error saving statistical trading plan after removing the trade: ` + $localize`Unknown error.`, undefined, false);
  }


  async undoDeletingStatisticalReturnFromTradingPlan(deletionResult: StatisticalTradeDeletionResult, userUid: string): Promise<Wrapper<StatisticalTradingPlan>> {
    let trades = deletionResult.resultingPlan.trades ? [...deletionResult.resultingPlan.trades] : [];
    trades.push(deletionResult.deleteTrade);
    let includedTrades = this.addToIncludedTrades(deletionResult.resultingPlan, deletionResult.deleteTrade.symbolCode, deletionResult.deleteTrade.statisticalReturnUid);
    let excludedTrades = this.removeFromExcludedTrades(deletionResult.resultingPlan, deletionResult.deleteTrade.symbolCode, deletionResult.deleteTrade.statisticalReturnUid);
    trades = trades.sort(PlannerService.tradesSortByDateComparator());
    const plan: StatisticalTradingPlan = {...deletionResult.resultingPlan, trades, includedTrades, excludedTrades};
    return await this.savePlan(plan, userUid, plan.uid);
  }

  async optimizePlan(userUid: string, planUid: string): Promise<Wrapper<StatisticalTradingPlan>> {
    try {
      const response = await this.httpClient.post<PayloadResultJo<StatisticalTradingPlan>>(environment.backend_api_path + environment.backend_api_request_optimize_trading_plan + '/' + userUid + '/' + planUid,
        null, {params: this.backendService.apiPublicKeyParam}).toPromise();
      let statisticalTradingPlan = PlannerService.fixTimestamps(response.payload);
      if (response.isSuccess && response.payload) {
        this.addPlanToCache(statisticalTradingPlan);
        return new Wrapper(statisticalTradingPlan, response.message, undefined, response.isSuccess);
      }
      return new Wrapper(statisticalTradingPlan, response.message, undefined, response.isSuccess);
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        return new Wrapper<StatisticalTradingPlan>(undefined, error.message);
      return new Wrapper<StatisticalTradingPlan>(undefined, error.message);
    }
  }

  public static fixTimestamps(plan: StatisticalTradingPlan) {
    if (!plan)
      return;
    if (plan.creationDate)
      plan = {...plan, creationDate: Timestamp.fromDate(Util.getDate(plan.creationDate))};
    if (plan.lastEditDate)
      plan = {...plan, lastEditDate: Timestamp.fromDate(Util.getDate(plan.lastEditDate))};
    if (plan.lastOptimizationDate)
      plan = {...plan, lastOptimizationDate: Timestamp.fromDate(Util.getDate(plan.lastOptimizationDate))};
    let trades = plan.trades ? [...plan.trades] : [];
    let fixedTrades: StatisticalTrade[] = [];
    trades.forEach(trade => {
      let actual: StatisticalTradeProperties = {...trade.actual};
      if (trade.actual?.buyDate)
        actual.buyDate = Timestamp.fromDate(Util.getDate(trade.actual.buyDate));
      if (trade.actual?.sellDate)
        actual.sellDate = Timestamp.fromDate(Util.getDate(trade.actual.sellDate));
      let fixedTrade = {...trade, actual};
      let target: StatisticalTradeProperties = {...trade.target};
      if (trade.target?.buyDate)
        target.buyDate = Timestamp.fromDate(Util.getDate(trade.target.buyDate));
      if (trade.target?.sellDate)
        target.sellDate = Timestamp.fromDate(Util.getDate(trade.target.sellDate));
      fixedTrade = {...fixedTrade, target};
      fixedTrades.push(fixedTrade);
    });
    plan = {...plan, trades: fixedTrades};
    return plan;
  }

  showOptimizationFinishedSnackbar(planName: string, planUid: string) {
    this.optimizationFinishedSnackBarRef = this.matSnackBar.open($localize`Optimization finished successfully for plan ${planName}.`, $localize`Go to plan`, {
      duration: 10000,
    });
    let route: string[] = ['/statistical-trading', 'planner', 'plans', planUid];
    this.optimizationFinishedSnackBarRef.onAction().subscribe(() => {
      this.router.navigate(route);
    });
  }

}
