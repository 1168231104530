import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {FormGroup} from '@angular/forms';
import {StatisticalTradingPlanTypeValue} from '../../../../shared/models/statisticalTradingPlan.interface';

@Component({
  selector: 'app-statistical-trading-plan-settings-form',
  templateUrl: './statistical-trading-plan-settings-form.component.html',
  styleUrls: ['./statistical-trading-plan-settings-form.component.scss'],
})
export class StatisticalTradingPlanSettingsFormComponent extends BaseComponent implements OnInit {

  @Input() formGroup?: FormGroup;
  @Input() planType?: StatisticalTradingPlanTypeValue;
  @Input() selectedExchangeShortNames?: string[] = [];
  @Input() excludedSymbolCodes?: string[] = [];
  @Output() onExchangeSelectionChanged = new EventEmitter<string[]>();
  @Output() onExcludedSymbolSelectionChanged = new EventEmitter<string[]>();

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>) {
    super(authService, userService, store);
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  exchangeSelectionChanged(exchangeShortNames: string[]) {
    this.onExchangeSelectionChanged.emit(exchangeShortNames);
  }

  excludedSymbolSelectionChanged(symbolCodes: string[]) {
    this.onExcludedSymbolSelectionChanged.emit(symbolCodes);
  }

  clearInputValue(formControlName: string) {
    this.formGroup?.get(formControlName)?.setValue('');
  }
}
