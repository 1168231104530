import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {SymbolAndStatisticalReturnUid} from '../../shared/models/symbolAndStatisticalReturnUid.interface';
import {StatisticalTradingService} from '../../statistical-trading/statistical-trading.service';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {Subject} from 'rxjs';
import {TitleService} from '../../shared/services/title.service';
import {SortOrder} from '../../shared/enums/sortOrder.enum';
import {User} from '../../shared/models/user.interface';
import {SettingsService} from '../../shared/services/settings.service';
import Util from '../../shared/util';
import {StatisticalSimulationResult} from '../../shared/models/statisticalSimulationResult.interface';
import {StockService} from '../../shared/services/stock.service';

@Component({
  selector: 'app-watch-list',
  templateUrl: './watch-list.component.html',
  styleUrls: ['./watch-list.component.scss'],
})
export class WatchListComponent extends BaseComponent implements OnInit {

  // Tab management
  selectedIndex = 0;
  private tabTypes = ['symbols', 'statistical-returns', 'statistical-simulations'];

  statisticalReturns: StatisticalReturn[] = [];
  statisticalReturnsUnsorted: StatisticalReturn[] = [];
  symbolCodes: string[] = [];
  symbolCodesUnsorted: string[] = [];
  statisticalSimulationResults: StatisticalSimulationResult[] = [];
  statisticalSimulationResultsUnsorted: StatisticalSimulationResult[] = [];
  stopUserListener$: Subject<null> = new Subject();
  sortOrderStatisticalReturns = SortOrder.LAST_ADDED_TO_WATCHLIST;
  sortOrderSymbols = SortOrder.LAST_ADDED_TO_WATCHLIST;
  sortOrderStatisticalSimulations = SortOrder.LAST_ADDED_TO_WATCHLIST;

  public get sortOrder(): typeof SortOrder {
    return SortOrder;
  }

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private titleService: TitleService,
      private stockService: StockService,
      private statisticalTradingService: StatisticalTradingService,
      private settingsService: SettingsService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Watch list`);

    // Retrieve type from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();

          const type = params.type;
          if (type)
            this.switchToTab(type);
          else {
            this.switchToTab(this.tabTypes[0]);
            this.router.navigate(['account', 'watch-list', this.tabTypes[0]]);
          }
        });
  }

  onTabSwitchByClick(matTabEvent: MatTabChangeEvent) {
    let tabIndex = matTabEvent.index;
    this.router.navigate(['account', 'watch-list', this.tabTypes[tabIndex]]);
  }

  private switchToTab(type: string) {
    let selectedIndex = this.tabTypes.indexOf(type);
    this.selectedIndex = selectedIndex;
    this.clearAlerts();
    // On switch to statistical returns or statistical simulations
    if (selectedIndex === 1 || selectedIndex === 2)
      this.onSwitchToStatisticalReturns();
    else
      this.stopUserListener$.next();
  }


  onSwitchToStatisticalReturns() {
    this.user$.pipe(takeUntil(this.stopUserListener$)).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
  }


  private onUserLoaded(user?: User) {
    if (user?.settings?.sortOrderStatisticalReturns)
      this.sortOrderStatisticalReturns = user.settings.sortOrderStatisticalReturns;
    if (user?.settings?.sortOrderSymbols)
      this.sortOrderSymbols = user.settings.sortOrderSymbols;
    if (user?.settings?.sortOrderStatisticalSimulations)
      this.sortOrderStatisticalSimulations = user.settings.sortOrderStatisticalSimulations;

    this.symbolCodesUnsorted = user?.watchedSymbols ? [...user?.watchedSymbols] : [];
    this.sortSymbols();
    if (this.tabTypes[this.selectedIndex] === 'statistical-returns')
      this.loadStatisticalReturns(user?.watchedStatisticalReturns);
    if (this.tabTypes[this.selectedIndex] === 'statistical-simulations')
      this.loadStatisticalSimulations(user?.watchedStatisticalSimulation);
  }

  private loadStatisticalReturns(watchedStatisticalReturns?: SymbolAndStatisticalReturnUid[]) {
    if (!watchedStatisticalReturns)
      return;
    const statisticalReturns: StatisticalReturn[] = [];
    watchedStatisticalReturns.forEach(it =>
        this.statisticalTradingService.fetchStatisticalReturn(it.symbolCode, it.statisticalReturnUid).then(wrapper => {
          if (wrapper.data) {
            statisticalReturns.push(wrapper.data);
            this.sortStatisticalReturns();
          }
          if (wrapper.errorMessage)
            this.addError($localize`Error loading statistical trade ${it.symbolCode}\: ${it.statisticalReturnUid}\: ${wrapper.errorMessage}`);
        }),
    );
    this.statisticalReturnsUnsorted = statisticalReturns;
  }

  private loadStatisticalSimulations(watchedStatisticalSimulation?: string[]) {
    if (!watchedStatisticalSimulation)
      return;
    const simulationResults: StatisticalSimulationResult[] = [];
    watchedStatisticalSimulation.forEach(it =>
        this.stockService.fetchStatisticalSimulationResult(it).then(wrapper => {
          if (wrapper.data) {
            simulationResults.push(wrapper.data);
            this.sortStatisticalSimulations();
          }
          if (wrapper.errorMessage)
            this.addError($localize`Error loading statistical simulation result ${it}\: ${wrapper.errorMessage}`);
        }),
    );
    this.statisticalSimulationResultsUnsorted = simulationResults;
  }

  onSortOrderChange(list: 'symbols' | 'statistical-returns' | 'statistical-simulations', sortOrder: SortOrder) {
    switch (list) {
      case 'statistical-returns': {
        if (this.user?.settings && this.user.settings.sortOrderStatisticalReturns !== sortOrder)
          this.settingsService.setStringValue('sortOrderStatisticalReturns', sortOrder);
        this.sortStatisticalReturns(sortOrder);
        break;
      }
      case 'symbols': {
        if (this.user?.settings && this.user.settings.sortOrderSymbols !== sortOrder) {
          this.settingsService.setStringValue('sortOrderSymbols', sortOrder);
          this.sortSymbols(sortOrder);
        }
        break;
      }
      case 'statistical-simulations': {
        if (this.user?.settings && this.user.settings.sortOrderStatisticalSimulations !== sortOrder)
          this.settingsService.setStringValue('sortOrderStatisticalSimulations', sortOrder);
        this.sortStatisticalSimulations(sortOrder);
        break;
      }
    }
  }

  private sortStatisticalReturns(sortOrder: SortOrder = this.sortOrderStatisticalReturns) {
    this.statisticalReturns = [...this.statisticalReturnsUnsorted].sort(this.getCompareFnStatisticalReturns(sortOrder));
  }

  private getCompareFnStatisticalReturns(sortOrder: SortOrder = this.sortOrderStatisticalReturns): (a: StatisticalReturn, b: StatisticalReturn) => number {
    switch (sortOrder) {
      case SortOrder.AVERAGE_PROFIT:
        return (a, b) => Util.compareWithUndefined(a.averageRelativeProfit, b.averageRelativeProfit, false);
      case SortOrder.WORST_YEAR:
        return (a, b) => Util.compareWithUndefined(a.lowestYearlyProfit, b.lowestYearlyProfit, false);
      case SortOrder.BUY_DATE:
        return (a, b) => Util.compareWithUndefined(a.buyDayNumber, b.buyDayNumber);
      case SortOrder.SELL_DATE:
        return (a, b) => Util.compareWithUndefined(
            (a.sellMonth && a.sellDay ? Util.calculateDayNumber(a.sellMonth, a.sellDay) : 0),
            (b.sellMonth && b.sellDay ? Util.calculateDayNumber(b.sellMonth, b.sellDay) : 0),
        );
      case SortOrder.DURATION:
        return (a, b) => Util.compareWithUndefined(a.duration, b.duration);
      case SortOrder.SYMBOL_CODE:
        return (a, b) => Util.compareWithUndefined(a.symbolCode, b.symbolCode);
      case SortOrder.EXCHANGE_SHORT_NAME:
        return (a, b) => Util.compareWithUndefined(a.exchangeShortName, b.exchangeShortName);
    }
    return (a, b) => 0;
  }

  private sortStatisticalSimulations(sortOrder: SortOrder = this.sortOrderStatisticalSimulations) {
    this.statisticalSimulationResults = [...this.statisticalSimulationResultsUnsorted].sort(this.getCompareFnStatisticalSimulations(sortOrder));
  }

  private getCompareFnStatisticalSimulations(sortOrder: SortOrder = this.sortOrderStatisticalSimulations): (a: StatisticalSimulationResult, b: StatisticalSimulationResult) => number {
    switch (sortOrder) {
      case SortOrder.MIN_AVERAGE_PROFIT:
        return (a, b) => Util.compareWithUndefined(a.minAverageRelativeProfit, b.minAverageRelativeProfit);
      case SortOrder.MAX_DURATION:
        return (a, b) => Util.compareWithUndefined(a.maxHoldingDuration, b.maxHoldingDuration);
      case SortOrder.MIN_DATA_YEARS:
        return (a, b) => Util.compareWithUndefined(a.minDataYears, b.minDataYears);
      case SortOrder.MAX_CAPITAL_PER_TRADE:
        return (a, b) => Util.compareWithUndefined(a.maxCapitalPerTrade, b.maxCapitalPerTrade);
      case SortOrder.ROI:
        return (a, b) => Util.compareWithUndefined(a.roi, b.roi, false);
      case SortOrder.SEED_CAPITAL:
        return (a, b) => Util.compareWithUndefined(a.seedCapital, b.seedCapital);
      case SortOrder.FINAL_CAPITAL:
        return (a, b) => Util.compareWithUndefined(a.finalCapital, b.finalCapital);
      case SortOrder.YEAR:
        return (a, b) => Util.compareWithUndefined(a.year, b.year);
      case SortOrder.NUMBER_OF_TRANSACTIONS:
        return (a, b) => Util.compareWithUndefined(a.transactionList.length, b.transactionList.length);
    }
    return (a, b) => 0;
  }

  private sortSymbols(sortOrder: SortOrder = this.sortOrderSymbols) {
    this.symbolCodes = [...this.symbolCodesUnsorted].sort(this.getCompareFnSymbolCodes(sortOrder));
  }

  private getCompareFnSymbolCodes(sortOrder: SortOrder = this.sortOrderSymbols): (a: string, b: string) => number {
    switch (sortOrder) {
      case SortOrder.SYMBOL_CODE:
        return (a, b) => Util.compare(a, b);
    }
    return (a, b) => 0;
  }
}
