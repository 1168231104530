import {Component, Input, OnInit} from '@angular/core';
import {TradeAction} from '../../../../shared/enums/tradeAction.enum';

@Component({
  selector: 'app-trade-action-view',
  templateUrl: './trade-action-view.component.html',
  styleUrls: ['./trade-action-view.component.scss'],
})
export class TradeActionViewComponent implements OnInit {
  TradeAction = TradeAction;

  @Input() action?: TradeAction;

  constructor() {
  }

  ngOnInit(): void {
  }

}
