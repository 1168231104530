import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Util from '../../util';
import {StockService} from '../../services/stock.service';
import {StatisticalTradingService} from '../../../statistical-trading/statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {Symbol} from '../../models/symbol.interface';
import {removeSymbolFromSearchResults} from '../../../store/stock.actions';
import {Exchange} from '../../models/exchange.interface';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-symbols-exchange-selector',
  templateUrl: './symbols-exchange-selector.component.html',
  styleUrls: ['./symbols-exchange-selector.component.scss'],
})
export class SymbolsExchangeSelectorComponent extends BaseComponent implements OnInit {

  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private stockService: StockService,
      private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  @Input() selectionBelow? = false;
  @Input() narrowButton? = false;
  @Input() bigButton? = false;
  @Input() buttonColor? = 'primary';
  @Input() maxSelectionCount?: number;
  @Input() elementType: 'symbols' | 'exchanges' = 'symbols';
  @Input() showQuickSelectExchangeButtons? = false;

  @Output() onSelectionChanged = new EventEmitter<string[]>();

  _selectedElements: string[] = [];

  get selectedElements(): string[] {
    return this._selectedElements;
  }

  @Input() set selectedElements(selectedElements: string[] | undefined | null) {
    this._selectedElements = selectedElements ? [...selectedElements] : [];
  }

  ngOnInit(): void {
  }

  remove(selectedElement: string) {
    if (!this.selectedElements)
      return;
    this.selectedElements = [...this.selectedElements];
    Util.removeFromArray(this.selectedElements, selectedElement);
    this.onSelectionChanged.next(this.selectedElements);
  }

  selectExchange(exchangeShortName: string) {
    this.statisticalTradingService.updateExchangeShortName(exchangeShortName);
    this.statisticalTradingService.fetchSearchResults();
  }

  /**
   * Called, when the Show more button is clicked. Loads more trades.
   */
  onClickShowMore(): void {
    this.statisticalTradingService.fetchMoreSearchResults();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

  addSymbol(symbol: Symbol) {
    this.store.dispatch(removeSymbolFromSearchResults({symbol}));
    this.selectedElements.push(symbol.code);
    this.onSelectionChanged.next(this.selectedElements);
  }

  addExchange(exchange?: Exchange) {
    if (exchange?.shortName != null) {
      this.selectedElements = [...this.selectedElements];
      this.selectedElements.push(exchange.shortName);
      this.onSelectionChanged.next(this.selectedElements);
    }
  }

  addUsExchanges(): void {
    this.selectedElements = [...this.selectedElements];
    Util.addUniquely(environment.usExchanges, this.selectedElements);
    if (this.maxSelectionCount)
      this.selectedElements = this.selectedElements.slice(0, this.maxSelectionCount);
    this.onSelectionChanged.next(this.selectedElements);
  }

  addDeExchanges(): void {
    this.selectedElements = [...this.selectedElements];
    Util.addUniquely(environment.deExchanges, this.selectedElements);
    if (this.maxSelectionCount)
      this.selectedElements = this.selectedElements.slice(0, this.maxSelectionCount);
    this.onSelectionChanged.next(this.selectedElements);
  }

  shouldShowAddUsExchangesButton(): boolean {
    return !Util.arrayContainsAll(this.selectedElements, environment.usExchanges);
  }

  shouldShowAddDeExchangesButton(): boolean {
    return !Util.arrayContainsAll(this.selectedElements, environment.deExchanges);
  }
}
