import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../shared/components/base-dialog/base-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {selectBestStatisticalReturnSearchMinMarketCap, selectUpcomingStatisticalReturnSearchMinMarketCap} from '../../../../store/stock.selectors';
import {takeUntil} from 'rxjs/operators';
import {StatisticalReturnsSearchParams} from '../../../../shared/models/statisticalReturnsSearchParams.interface';
import {mergeUpdateBestStatisticalReturnSearchParams, mergeUpdateUpcomingStatisticalReturnSearchParams} from '../../../../store/stock.actions';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-min-market-cap-edit-dialog',
  templateUrl: './min-market-cap-edit-dialog.component.html',
  styleUrls: ['./min-market-cap-edit-dialog.component.scss'],
})
export class MinMarketCapEditDialogComponent extends BaseDialogComponent implements OnInit {

  form: FormGroup = this.createForm();

  baseCurrency = environment.baseCurrency;
  minMarketCap$?: Observable<number | undefined>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    dialogRef: MatDialogRef<MinMarketCapEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) {
    super(authService, userService, store, dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.viewType === 'upcoming') {
      this.minMarketCap$ = this.store.select(selectUpcomingStatisticalReturnSearchMinMarketCap);
    }
    if (this.data.viewType === 'best') {
      this.minMarketCap$ = this.store.select(selectBestStatisticalReturnSearchMinMarketCap);
    }

    this.minMarketCap$?.pipe(takeUntil(this.destroy$)).subscribe(minMarketCap => {
      this.form.patchValue({minMarketCap});
    });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      minMarketCap: [''],
    });
  }

  saveMinMarketCap(minMarketCapString: string) {
    if (!minMarketCapString)
      return this.clearMinMarketCap();
    const minMarketCap = parseInt(minMarketCapString);
    if (isNaN(minMarketCap))
      return;
    let searchParams: StatisticalReturnsSearchParams = {minMarketCap};
    this.mergeUpdateSearchParams(searchParams);
  }

  clearMinMarketCap() {
    let searchParams: StatisticalReturnsSearchParams = {minMarketCap: undefined};
    this.mergeUpdateSearchParams(searchParams);
  }

  private mergeUpdateSearchParams(searchParams: StatisticalReturnsSearchParams) {
    if (this.data.viewType === 'upcoming')
      this.store.dispatch(mergeUpdateUpcomingStatisticalReturnSearchParams({searchParams}));
    if (this.data.viewType === 'best')
      this.store.dispatch(mergeUpdateBestStatisticalReturnSearchParams({searchParams}));
  }
}
