import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {UtilService} from '../../../shared/util.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StockService} from '../../../shared/services/stock.service';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {TitleService} from '../../../shared/services/title.service';
import {takeUntil} from 'rxjs/operators';
import {StatisticalSimulationResult} from '../../../shared/models/statisticalSimulationResult.interface';
import {selectSimulationSearchSimulationResults} from '../../../store/simulation.selectors';
import {SimulationTransaction} from '../../../shared/models/simulationTransaction.interface';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-simulation-view',
  templateUrl: './simulation-view.component.html',
  styleUrls: ['./simulation-view.component.scss'],
})
export class SimulationViewComponent extends BaseComponent implements OnInit {

  simulationUid?: string;
  simulationResult?: StatisticalSimulationResult;

  simulationSearchSimulationResults$ = this.store.select(selectSimulationSearchSimulationResults);
  relativeProfitTruncatePercentage = environment.relativeProfitTruncateThreshold * 100;
  roiTruncatedTooltip = $localize`The return on investment of this trade. Profits over ${this.relativeProfitTruncatePercentage} % are truncated at ${this.relativeProfitTruncatePercentage} %. Losses are not truncated.`;
  private readonly spinnerKeySimulationResult = 'fetchSimulationResult';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public utilService: UtilService,
      private activatedRoute: ActivatedRoute,
      private stockService: StockService,
      private statisticalTradingService: StatisticalTradingService,
      private router: Router,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Statistical return`);

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();
          if (this.simulationUid !== params.simulationUid)
            this.resetComponent();
          this.simulationUid = params.simulationUid;
          if (this.simulationUid) {
            this.titleService.setTitle($localize`Simulation result`);
            this.fetchSimulation(this.simulationUid);
          }
        });
  }

  private fetchSimulation(simulationUid: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeySimulationResult, $localize`Fetching simulation result ${this.simulationUid}`);
    this.stockService.fetchStatisticalSimulationResult(simulationUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeySimulationResult);
      if (wrapper.data) {
        this.simulationResult = wrapper.data;
        this.titleService.setTitle($localize`Simulation result ${wrapper.data.year}`);
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  private resetComponent() {
    this.simulationResult = undefined;
  }

  /**
   Calculates the duration in days between transaction.buyDate and transaction.sellDate and returns it
   */
  /**
   * Calculates the duration in days between transaction.buyDate and transaction.sellDate and returns it.
   * Assumes that buyDate is before or equal to sellDate.
   */
  public getDuration(transaction: SimulationTransaction): number | undefined {
    if (!transaction.sellDate || !transaction.buyDate)
      return undefined;
    const oneDayMs = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const diffMs = this.utilService.getDate(transaction.sellDate!).getTime() - this.utilService.getDate(transaction.buyDate!).getTime();
    return Math.round(diffMs / oneDayMs);
  }

}
