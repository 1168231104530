import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {
  selectBestStatisticalReturnSearchParams,
  selectBestStatisticalReturnSearchSortOrder,
  selectUpcomingStatisticalReturnBuyDayNumber,
  selectUpcomingStatisticalReturnSearchParams,
  selectUpcomingStatisticalReturnSearchSortOrder,
} from '../../store/stock.selectors';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {StatisticalTradingService} from '../statistical-trading.service';
import {
  setBestStatisticalReturnsSearchParams,
  setBestStatisticalReturnsSortOrder,
  setUpcomingStatisticalReturnsBuyDayNumber,
  setUpcomingStatisticalReturnsSearchParams,
  setUpcomingStatisticalReturnsSortOrder,
} from '../../store/stock.actions';
import Util from '../../shared/util';
import {PeriodWinRatio, StatisticalReturnsSearchParams, StatisticalReturnsSortOrder} from '../../shared/models/statisticalReturnsSearchParams.interface';
import {takeUntil} from 'rxjs/operators';
import {StatisticalReturnViewType} from '../../shared/types/statisticalReturnViewType.type';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-statistical-return-search-panel',
  templateUrl: './statistical-return-search-panel.component.html',
  styleUrls: ['./statistical-return-search-panel.component.scss'],
})
export class StatisticalReturnSearchPanelComponent extends BaseComponent implements OnInit {

  @Input() viewType?: StatisticalReturnViewType;

  buyDayNumber$: Observable<number | undefined> = this.store.select(selectUpcomingStatisticalReturnBuyDayNumber);
  sortOrder$: Observable<StatisticalReturnsSortOrder | undefined> | undefined;
  sortOrder?: StatisticalReturnsSortOrder;

  maxBuyDate = this.getMaxBuyDate();
  minBuyDate = this.getMinBuyDate();

  form: FormGroup = this.createForm();
  minPeriodWinRatios?: PeriodWinRatio[];

  searchParams?: StatisticalReturnsSearchParams;
  searchParams$: Observable<StatisticalReturnsSearchParams | undefined> | undefined;

  public get statisticalReturnsSortOrder(): typeof StatisticalReturnsSortOrder {
    return StatisticalReturnsSortOrder;
  }

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private formBuilder: FormBuilder,
    private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);

  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.viewType === 'best') {
      this.searchParams$ = this.store.select(selectBestStatisticalReturnSearchParams);
      this.sortOrder$ = this.store.select(selectBestStatisticalReturnSearchSortOrder);
    } else {
      this.searchParams$ = this.store.select(selectUpcomingStatisticalReturnSearchParams);
      this.sortOrder$ = this.store.select(selectUpcomingStatisticalReturnSearchSortOrder);

      this.buyDayNumber$.pipe(takeUntil(this.destroy$)).subscribe(buyDayNumber => {
        let buyDate = new Date();
        if (buyDayNumber)
          buyDate = Util.getDateFromDayNumber(buyDayNumber);
        this.form.patchValue({buyDate: buyDate});
      });
    }

    this.sortOrder$.pipe(takeUntil(this.destroy$)).subscribe(sortOrder => {
      this.sortOrder = sortOrder;
    });

    this.searchParams$.pipe(takeUntil(this.destroy$)).subscribe(searchParams => {
      this.searchParams = searchParams;
    });
  }

  today() {
    const buyDayNumber = Util.getCurrentDayNumber();
    this.setBuyDayNumber(buyDayNumber);
  }

  previousDay(currentBuyDayNumber: number) {
    let buyDayNumber = currentBuyDayNumber - 1;
    if (buyDayNumber === 0)
      buyDayNumber = 365;
    this.setBuyDayNumber(buyDayNumber);
  }

  nextDay(currentBuyDayNumber: number) {
    let buyDayNumber = currentBuyDayNumber + 1;
    if (buyDayNumber === 366)
      buyDayNumber = 1;
    this.setBuyDayNumber(buyDayNumber);
  }

  saveBuyDate() {
    const buyDayNumber = this.getBuyDayNumber();
    if (!buyDayNumber || isNaN(buyDayNumber))
      return;
    this.setBuyDayNumber(buyDayNumber);
  }

  private setBuyDayNumber(buyDayNumber: number) {
    if (this.viewType === 'upcoming')
      this.store.dispatch(setUpcomingStatisticalReturnsBuyDayNumber({buyDayNumber}));
  }

  private getMaxBuyDate() {
    return new Date(Util.getCurrentYear(), 11, 31);
  }

  private getMinBuyDate() {
    return new Date(Util.getCurrentYear(), 0, 1);
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      buyDate: [''],
    });
  }

  search() {
    let searchParams: StatisticalReturnsSearchParams = {
      ...this.searchParams,
      buyDayNumber: this.viewType === 'upcoming' ? this.getBuyDayNumber() : undefined,
      sortOrder: this.sortOrder,
    };
    if (this.viewType === 'upcoming') {
      this.store.dispatch(setUpcomingStatisticalReturnsSearchParams({searchParams}));
      this.statisticalTradingService.fetchUpcomingStatisticalReturns();
    }
    if (this.viewType === 'best') {
      this.store.dispatch(setBestStatisticalReturnsSearchParams({searchParams}));
      this.statisticalTradingService.fetchBestStatisticalReturns();
    }
  }

  private getBuyDayNumber() {
    if (!this.form.value.buyDate)
      return undefined;
    const buyDate = Util.getDate(this.form.value.buyDate);
    return Util.calculateDayNumberFromDate(buyDate);
  }

  clearAllFilters() {
    const searchParams: StatisticalReturnsSearchParams = {
      exchangeShortNames: [],
      sortOrder: StatisticalReturnsSortOrder.AverageRelativeProfitDesc,
      minAverageRelativeProfit: undefined,
      minAverageVolume: undefined,
      minAverageVolumeBaseCurrency: undefined,
      minDataYears: undefined,
      maxDuration: undefined,
      minLowestYearlyProfit: undefined,
      minWinRatio: undefined,
      minPeriodWinRatios: undefined,
      minAverageMaxRise: undefined,
      maxAverageMaxDrop: undefined,
      minHighestRise: undefined,
      maxHighestDrop: undefined,
      minMarketCap: undefined,
    };
    if (this.viewType === 'upcoming')
      this.store.dispatch(setUpcomingStatisticalReturnsSearchParams({searchParams}));
    if (this.viewType === 'best')
      this.store.dispatch(setBestStatisticalReturnsSearchParams({searchParams}));
    this.search();
  }

  onSortOrderChange(sortOrder: StatisticalReturnsSortOrder) {
    if (this.viewType === 'upcoming')
      this.store.dispatch(setUpcomingStatisticalReturnsSortOrder({sortOrder}));
    if (this.viewType === 'best')
      this.store.dispatch(setBestStatisticalReturnsSortOrder({sortOrder}));
  }
}
