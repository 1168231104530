<!-- Breadcrumb-->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <a class="breadcrumb-item" i18n="Account" [routerLink]="['/account']">Account</a>
    <li class="breadcrumb-item">
      <span i18n>Watch list</span>
    </li>
  </ol>
</nav>

<div class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="Watch list headline">Watch list</h1>
  <p class="jumbotron-lead" i18n="watch list lead text">See what stock symbols and statistical trades you're watching.</p>
</div>

<div class="container-fluid p-1">

  <mat-tab-group (selectedTabChange)="onTabSwitchByClick($event)" mat-align-tabs="start" [selectedIndex]="selectedIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">business</mat-icon>
        <span i18n>Stock symbols</span>
      </ng-template>

      <h1 class="paragraph-headline mt-2" i18n>Watched stock symbols</h1>
      <mat-form-field class="mat-form-input">
        <mat-label i18n="sort by">Sort by</mat-label>
        <mat-select #sortOrderSymbolsSelect id="sortOrderSymbols" panelClass="mat-select-panel-500px"
                    [value]="sortOrderSymbols" (selectionChange)="onSortOrderChange('symbols', sortOrderSymbolsSelect.value)">
          <mat-option [value]="sortOrder.LAST_ADDED_TO_WATCHLIST" i18n>Last added</mat-option>
          <mat-option [value]="sortOrder.SYMBOL_CODE" i18n>Symbol code</mat-option>
        </mat-select>
      </mat-form-field>

      <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

      <ng-container *ngIf="symbolCodes.length > 0; else noWatchedSymbols">
        <div class="table-responsive">
          <table class="table table-striped table-sm w-100">
            <tbody>
            <tr *ngFor="let symbolCode of symbolCodes">
              <td>
                <app-watch-list-star [symbolCode]="symbolCode"></app-watch-list-star>
              </td>
              <td>
                <a [routerLink]="['/statistical-trading','symbols',symbolCode]">
                  <app-symbol-descriptor [symbolCode]="symbolCode"></app-symbol-descriptor>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-template #noWatchedSymbols>
        <div class="alert alert-info">
          <span i18n>You don't have any watched stock symbols yet. When looking at a stock symbol, click the yellow star icon to add it to your watch list.</span>
        </div>
      </ng-template>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">analytics</mat-icon>
        <span i18n>Statistical trades</span>
      </ng-template>

      <h1 class="paragraph-headline mt-2" i18n>Watched statistical trades</h1>
      <mat-form-field class="mat-form-input">
        <mat-label i18n="sort by">Sort by</mat-label>
        <mat-select #sortOrderStatisticalReturnsSelect id="sortOrderStatisticalReturns" panelClass="mat-select-panel-500px"
                    [value]="sortOrderStatisticalReturns" (selectionChange)="onSortOrderChange('statistical-returns', sortOrderStatisticalReturnsSelect.value)">
          <mat-option [value]="sortOrder.LAST_ADDED_TO_WATCHLIST" i18n>Last added</mat-option>
          <mat-option [value]="sortOrder.AVERAGE_PROFIT" i18n>Average profit</mat-option>
          <mat-option [value]="sortOrder.BUY_DATE" i18n>Buy date</mat-option>
          <mat-option [value]="sortOrder.SELL_DATE" i18n>Sell date</mat-option>
          <mat-option [value]="sortOrder.DURATION" i18n>Duration</mat-option>
          <mat-option [value]="sortOrder.SYMBOL_CODE" i18n>Symbol code</mat-option>
          <mat-option [value]="sortOrder.EXCHANGE_SHORT_NAME" i18n>Exchange short name</mat-option>
          <mat-option [value]="sortOrder.WORST_YEAR" i18n>Worst year</mat-option>
        </mat-select>
      </mat-form-field>

      <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

      <ng-container *ngIf="user && user.watchedStatisticalReturns && user.watchedStatisticalReturns.length > 0; else noWatchedStatisticalReturns">
        <app-statistical-returns-table [statisticalReturns]="statisticalReturns" [showName]="true" [showExchange]="true"></app-statistical-returns-table>
      </ng-container>

      <ng-template #noWatchedStatisticalReturns>
        <div class="alert alert-info">
          <span i18n>You don't have any watched statistical returns yet. When looking at a statistical return, click the yellow star icon to add it to your watch list.</span>
        </div>
      </ng-template>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">speed</mat-icon>
        <span i18n>Statistical simulations</span>
      </ng-template>

      <h1 class="paragraph-headline mt-2" i18n>Watched statistical simulations</h1>
      <mat-form-field class="mat-form-input">
        <mat-label i18n="sort by">Sort by</mat-label>
        <mat-select #sortOrderStatisticalSimulationsSelect id="sortOrderStatisticalSimulations" panelClass="mat-select-panel-500px"
                    [value]="sortOrderStatisticalSimulations" (selectionChange)="onSortOrderChange('statistical-simulations', sortOrderStatisticalSimulationsSelect.value)">
          <mat-option [value]="sortOrder.LAST_ADDED_TO_WATCHLIST" i18n>Last added</mat-option>
          <mat-option [value]="sortOrder.MAX_DURATION" i18n>Max. holding duration</mat-option>
          <mat-option [value]="sortOrder.MIN_AVERAGE_PROFIT" i18n>Min. average profit</mat-option>
          <mat-option [value]="sortOrder.MIN_DATA_YEARS" i18n>Min. data years</mat-option>
          <mat-option [value]="sortOrder.ROI" i18n>Return on investment</mat-option>
          <mat-option [value]="sortOrder.SEED_CAPITAL" i18n>Seed capital</mat-option>
          <mat-option [value]="sortOrder.FINAL_CAPITAL" i18n>Final capital</mat-option>
          <mat-option [value]="sortOrder.YEAR" i18n>Year</mat-option>
          <mat-option [value]="sortOrder.NUMBER_OF_TRANSACTIONS" i18n>Number of transactions</mat-option>
          <mat-option [value]="sortOrder.MAX_CAPITAL_PER_TRADE" i18n>Max. capital per trade</mat-option>
        </mat-select>
      </mat-form-field>

      <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

      <ng-container *ngIf="user && user.watchedStatisticalSimulation && user.watchedStatisticalSimulation.length > 0; else noWatchedStatisticalSimulations">
        <app-simulation-results-table [simulationResults]="statisticalSimulationResults" [sortable]="false"></app-simulation-results-table>
      </ng-container>

      <ng-template #noWatchedStatisticalSimulations>
        <div class="alert alert-info">
          <span i18n>You don't have any watched statistical simulations yet. When looking at a statistical simulation, click the yellow star icon to add it to your watch list.</span>
        </div>
      </ng-template>

    </mat-tab>
  </mat-tab-group>
</div>
