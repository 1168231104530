import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-please-wait-loading',
  templateUrl: './please-wait-loading.component.html',
  styleUrls: ['./please-wait-loading.component.scss']
})
export class PleaseWaitLoadingComponent implements OnInit {

  @Input() active = false
  @Input() message  ?: string;
  @Input() messagesByKey ?: Map<string, string>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
