<footer class="py-4 bg-dark text-white-50" id="sticky-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col footer-link-container">
        <small>
          <span i18n i18n-matTooltip matTooltip="Version {{version}}">Copyright &copy; {{year}} </span>&nbsp;<a routerLink="/">{{siteTitle}}</a> -
          <a [routerLink]="['/privacy-policy']" i18n>Privacy policy</a> -
          <a [routerLink]="['/imprint']" i18n>Imprint</a> -
          <a [routerLink]="['/support']" i18n>Support</a>
        </small>
      </div>
    </div>
  </div>
</footer>
