<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the minimum average profit in percent to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n-matTooltip matTooltip="Min. average profit in percent" i18n>Min. profit (%)</mat-label>
          <input cdkFocusInitial #minAverageRelativeProfitInput type="number" matInput name="minAverageRelativeProfit" formControlName="minAverageRelativeProfit" placeholder="5" id="minAverageRelativeProfit"
                 [min]="-10" [max]="10" (change)="saveMinAverageRelativeProfit(minAverageRelativeProfitInput.value)">
          <mat-icon (click)="clearMinAverageRelativeProfit()" *ngIf="minAverageRelativeProfitInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>
