import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import firebase from 'firebase';
import EventParams = firebase.analytics.EventParams;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {


  constructor(private analytics: AngularFireAnalytics) {
  }

  logEvent(eventName: AnalyticsEventName, eventParams?: {
             coupon?: EventParams['coupon'];
             currency?: EventParams['currency'];
             items?: EventParams['items'];
             payment_type?: EventParams['payment_type'];
             value?: EventParams['value'];
             [key: string]: any;
           },
  ): void {
    this.analytics.logEvent(eventName, eventParams);
  }
}

export enum AnalyticsEventName {
  SEARCH = 'search',
  LISTING_VIEW = 'listing_view',
  TRANSACTION_VIEW = 'transaction_view',
  LATEST_LISTINGS_VIEW = 'latest_listings_view',
  CATEGORY_VIEW = 'category_view',
  ALL_CATEGORIES_VIEW = 'all_categories_view',
  BOOK_VIEW = 'book_view',
  USER_VIEW = 'user_view',
  BOOKING_SUCCESSFUL = 'booking_successful',
  BOOKING_CANCELLED = 'booking_cancelled',
  BOOKING_DENIED = 'booking_denied',
  BOOKING_ACCEPTED = 'booking_accepted',
  BOOKING_CONFIRMED_ITEM_PICKUP = 'booking_confirmed_item_pickup',
  BOOKING_CONFIRMED_ITEM_RETURN = 'booking_confirmed_item_return',
  ITEM_LISTED = 'item_listed',
  ACCOUNT_CREATED = 'account_created',
  SIGN_IN = 'sign_in',
  SIGN_OUT = 'sign_out',
}
