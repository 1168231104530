<ng-container *ngIf="action; else none">
  <ng-container [ngSwitch]="action">
    <span *ngSwitchCase="TradeAction.Buy" i18n class="buy-color">Buy</span>
    <span *ngSwitchCase="TradeAction.Sell" i18n class="sell-color">Sell</span>
  </ng-container>
</ng-container>

<ng-template #none>
  -
</ng-template>
