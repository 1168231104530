import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {StartComponent} from './layout/start/start.component';
import {NotFoundComponent} from './layout/not-found/not-found.component';

const appRoutes: Routes = [
  {path: '', component: StartComponent},
  {path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)},
  {path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
  {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  {
    path: 'statistical-trading',
    loadChildren: () => import('./statistical-trading/statistical-trading.module').then(m => m.StatisticalTradingModule),
  }, {
    path: 'dividends',
    loadChildren: () => import('./dividends/dividends.module').then(m => m.DividendsModule),
  },
  {path: '**', component: NotFoundComponent},

  // Redirection = Bad user experience. The url will be lost
  // {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
