import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import Util from '../../shared/util';
import {MetadataService} from '../../shared/services/metadata.service';
import {environment} from '../../../environments/environment';
import {MANGOPAY_TOS_LINK} from '../../shared/constants/files';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent extends BaseComponent implements OnInit {
  @ViewChild('imageCarousel') imageCarousel: NgbCarousel | undefined;

  imgWidths = [350, 500, 750, 1000, 1132];
  imgPath = environment.publicImgPath + 'start-page/';
  imgNames = [
    'growth',
  ];
  imgExtension = '.webp';

  titleImageActiveIndex: number = 0;

  mangopayTosLink = MANGOPAY_TOS_LINK;


  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private metadataService: MetadataService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setRawTitle(environment.defaultTitle);
    Util.shuffle(this.imgNames);
    this.metadataService.updateTags(environment.defaultTitle,
        $localize`Borrow all the things you ever wanted. Rent out, what you have, but rarely use. Stock Forecast is a sharing marketplace, on which anybody can offer items for rent.`,
        $localize`Stock Forecast, sharing community, private rentals, borrow almost everything, rent out all the things`,
    );
  }

  isImageActive(i: number) {
    return Util.isSlideActive(i, this.titleImageActiveIndex, this.imgNames.length);
  }
}
