import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UtilService} from '../../../../shared/util.service';
import {TitleService} from '../../../../shared/services/title.service';
import {BreadcrumbPage} from '../../../../shared/components/breadcrumb/breadcrumb.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import {planTypes} from '../../../../shared/models/statisticalTradingPlanType.interface';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {PlannerService} from '../../planner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {StatisticalTradingPlan} from '../../../../shared/models/statisticalTradingPlan.interface';
import Util from '../../../../shared/util';
import {addStatisticalTradingPlanToCache} from '../../../../store/planner.actions';

interface StatisticalTradingPlanType {
  value: string;
  label: string;
}

@Component({
  selector: 'app-statistical-trading-plan-edit',
  templateUrl: './statistical-trading-plan-edit.component.html',
  styleUrls: ['./statistical-trading-plan-edit.component.scss'],
})
export class StatisticalTradingPlanEditComponent extends BaseComponent implements OnInit {

  private readonly spinnerKeySavePlan = 'savingPlan';
  private readonly spinnerKeyOptimizePlan = 'optimizePlan';


  stepLabels = ['nameAndType', 'automaticPlannerSettings', 'manualPlannerSettings', 'saving'];

  exchangeShortNames?: string[];
  excludedSymbolCodes?: string[];
  excludedTrades?: string[];
  includedTrades?: string[];

  planTypes = planTypes;
  isAutomaticPlanSelected = false;
  planUid?: string;
  savingSuccessful: boolean = false;
  optimizing: boolean = false;
  optimizingFinished: boolean = false;

  private readonly spinnerKeyTradingPlan = 'fetchTradingPlan';
  plan?: StatisticalTradingPlan;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private utilService: UtilService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private plannerService: PlannerService) {
    super(authService, userService, store);
  }

  nameAndTypeFormGroup ?: FormGroup;
  statisticalTradingPlanSettingsFormGroup?: FormGroup;

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Create plan`);
    this.createNameAndTypeForm();
    this.createAutomaticPlannerSettingsForm();

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
      (params) => {
        this.clearAlerts();
        if (this.planUid !== params.planUid)
          this.planUid = params.planUid;
        if (this.planUid) {
          this.titleService.setTitle($localize`Edit plan`);
          this.fetchTradingPlan(this.planUid);
        }
      });
  }

  private fetchTradingPlan(planUid: string) {
    this.user$.subscribe(user => {
      if (!user?.uid)
        return;

      this.addLoadingSpinnerMessage(this.spinnerKeyTradingPlan, $localize`Fetching trading plan ${this.planUid}`);
      this.plannerService.fetchPlan(user.uid, planUid, 0).then(wrapper => {
        this.removeLoadingSpinnerMessage(this.spinnerKeyTradingPlan);
        if (wrapper.data) {
          this.plan = wrapper.data;
          this.titleService.setTitle($localize`Edit trading plan ${this.plan.name}`);
          this.updateForm(this.plan);
          this.exchangeShortNames = this.plan?.exchangeShortNames;
          this.excludedSymbolCodes = this.plan?.excludedSymbolCodes;
          this.excludedTrades = this.plan?.excludedTrades;
          this.includedTrades = this.plan?.includedTrades;
        }
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
      });

    });
  }

  private createNameAndTypeForm() {
    this.nameAndTypeFormGroup = this.formBuilder.group({
      name: [this.getDefaultPlanName(), Validators.required],
      type: [this.planTypes[0].value, Validators.required],
    });
  }

  private createAutomaticPlannerSettingsForm(): void {
    if (this.statisticalTradingPlanSettingsFormGroup !== undefined)
      return;

    this.statisticalTradingPlanSettingsFormGroup = this.formBuilder.group({
      seedCapital: ['', [Validators.required]],
      maxCapitalPerTrade: ['10', [Validators.required]],
      currency: ['', [Validators.required]],
      minAverageRelativeProfit: ['3'],
      minAverageRelativeProfitLast5Years: [''],
      minAverageRelativeProfitLast10Years: [''],
      minWinRatio: [''],
      minPricePerStock: [''],
      minAverageVolumeBaseCurrency: [''],
      minMarketCap: [''],
      maxAverageMaxDrop: [''],
      minDataYears: [''],
      maxDuration: [''],
      taxRate: [''],
      orderFeeFixed: [''],
      orderFeeRelative: [''],
    });
  }

  updateForm(plan: StatisticalTradingPlan) {
    this.nameAndTypeFormGroup?.patchValue({
      name: plan.name,
      type: plan.typeValue,
    });
    this.statisticalTradingPlanSettingsFormGroup?.patchValue({
      seedCapital: plan.seedCapital,
      maxCapitalPerTrade: Util.toPercentage(plan.maxCapitalPerTrade),
      currency: plan.currency,
      minAverageRelativeProfit: Util.toPercentage(plan.minAverageRelativeProfit),
      minAverageRelativeProfitLast5Years: Util.toPercentage(plan.minAverageRelativeProfitLast5Years),
      minAverageRelativeProfitLast10Years: Util.toPercentage(plan.minAverageRelativeProfitLast10Years),
      minWinRatio: Util.toPercentage(plan.minWinRatio),
      minPricePerStock: plan.minPricePerStock,
      minAverageVolumeBaseCurrency: plan.minAverageVolumeBaseCurrency ? plan.minAverageVolumeBaseCurrency / 1000 : undefined,
      minMarketCap: plan.minMarketCap ? plan.minMarketCap / 1000000 : undefined,
      maxAverageMaxDrop: Util.toPercentage(plan.maxAverageMaxDrop),
      minDataYears: plan.minDataYears,
      maxDuration: plan.maxDuration,
      taxRate: Util.toPercentage(plan.taxRate),
      orderFeeFixed: plan.orderFeeFixed,
      orderFeeRelative: Util.toPercentage(plan.orderFeeRelative),
    });
    this.isAutomaticPlanSelected = plan.typeValue === 'automatic';
  }

  private getDefaultPlanName(): string {
    const now = new Date();
    return this.utilService.formatDateDefault(now);
  }

  onTypeChanged($event: MatRadioChange, type: StatisticalTradingPlanType): void {
    this.isAutomaticPlanSelected = type === planTypes[1];
  }

  onStepSelectionChange(event: StepperSelectionEvent): void {
    this.clearAlerts();
    const lastStepLabel = this.stepLabels[this.stepLabels.length - 1];
    if (event.selectedStep.label === lastStepLabel && event.previouslySelectedStep.label !== lastStepLabel)
      this.savingStepOpened();
  }

  savingStepOpened(): void {
    if (!this.nameAndTypeFormGroup?.valid) {
      this.addError($localize`There is an error in step 1.`);
      return;
    }
    if (!this.statisticalTradingPlanSettingsFormGroup?.valid) {
      this.addError($localize`There is an error in step 2.`);
      return;
    }
    this.save();
  }

  save(): void {
    this.clearAlerts();

    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }

    this.addLoadingSpinnerMessage(this.spinnerKeySavePlan, $localize`Saving plan...`);
    this.savingSuccessful = false;

    let name = this.nameAndTypeFormGroup?.value.name;
    let typeValue = this.nameAndTypeFormGroup?.value.type;
    this.plan = this.plannerService.createPlanObject(name, typeValue, this.statisticalTradingPlanSettingsFormGroup, this.exchangeShortNames,
      this.excludedSymbolCodes, this.excludedTrades, this.includedTrades, this.plan);

    this.plannerService.savePlan(this.plan, this.user?.uid, this.planUid).then(wrapper => {
        this.removeLoadingSpinnerMessage(this.spinnerKeySavePlan);
        if (wrapper.success) {
          this.planUid = wrapper.data?.uid;
          this.plan = wrapper.data;
          this.savingSuccessful = true;
          this.addSuccess($localize`Your plan was saved successfully.`);
          if (this.plan?.typeValue === 'automatic')
            this.optimizePlan(this.user!.uid!, this.planUid!, this.plan!);
        } else {
          this.addError($localize`Your plan could not be saved: ` + wrapper.errorMessage);
          this.savingSuccessful = false;
        }
      },
      errorMessage => {
        this.removeLoadingSpinnerMessage(this.spinnerKeySavePlan);
        this.savingSuccessful = false;
        this.addError($localize`Your plan could not be saved: ` + errorMessage);
      });
  }


  exchangeSelectionChanged(exchangeShortNames: string[]) {
    this.exchangeShortNames = exchangeShortNames;
  }

  excludedSymbolSelectionChanged(excludedSymbolCodes: string[]) {
    this.excludedSymbolCodes = excludedSymbolCodes;
  }

  getPageTitle(): string {
    if (this.planUid)
      return $localize`Edit plan`;
    return $localize`Create plan`;
  }

  getBreadcrumbPages(): BreadcrumbPage[] {
    if (this.planUid)
      return [
        {routerLink: '../../..', title: $localize`Statistical trading planner`},
        {routerLink: '../..', title: $localize`My plans`},
        {routerLink: '..', title: this.nameAndTypeFormGroup?.value.name},
      ];
    return [
      {routerLink: '../..', title: $localize`Statistical trading planner`},
      {routerLink: '..', title: $localize`My plans`},
    ];
  }

  private optimizePlan(userUid: string, planUid: string, plan: StatisticalTradingPlan) {
    this.addLoadingSpinnerMessage(this.spinnerKeyOptimizePlan, $localize`Optimizing your plan...`);
    this.optimizing = true;
    this.optimizingFinished = false;
    this.plannerService.optimizePlan(userUid, planUid).then(wrapper => {
      this.optimizing = false;
      this.removeLoadingSpinnerMessage(this.spinnerKeyOptimizePlan);
      if (wrapper.success && wrapper.data) {
        this.optimizingFinished = true;
        this.plannerService.showOptimizationFinishedSnackbar(plan.name, plan.uid!);
        this.store.dispatch(addStatisticalTradingPlanToCache({statisticalTradingPlan: wrapper.data}));
      }
      if (wrapper.errorMessage && !wrapper.success)
        this.addError($localize`Optimizing plan '${plan.name}' failed: ` + wrapper.errorMessage);
    });
  }
}
