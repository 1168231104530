import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss'],
})
export class InfoTextComponent implements OnInit {
  @Input() page?: 'best-statistical-trades' | 'symbol-view' | 'upcoming-statistical-trades';
  minRelativeReturnAnyPeriodPercentage = environment.minRelativeReturnAnyPeriod * 100;
  minRelativeReturnAllTimePercentage = environment.minRelativeReturnAllTime * 100;
  relativeProfitTruncatePercentage = environment.relativeProfitTruncateThreshold * 100;
 
  @Input() part: 'introduction' | 'description' | 'all' = 'all';

  constructor() {
  }

  ngOnInit(): void {
  }

}
