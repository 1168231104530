import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-sort-header',
  templateUrl: './sort-header.component.html',
  styleUrls: ['./sort-header.component.scss'],
})
export class SortHeaderComponent implements OnInit {

  @Output() onSortClicked = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input() sortable = true;
  @Input() currentSortOrder?: string | undefined | null;
  @Input() orderAsc?: string;
  @Input() orderDesc?: string;

  sort() {
    if (this.currentSortOrder === this.orderAsc)
      return this.onSortClicked.next(this.orderDesc);
    return this.onSortClicked.next(this.orderAsc);
  }
}
