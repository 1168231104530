import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {Schedule} from '../../shared/models/schedule.interface';
import Util from '../../shared/util';
import {takeUntil} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImportExportModalContentComponent} from '../../shared/components/import-export-modal-content/import-export-modal-content.component';
import {AdminService} from '../services/admin.service';
import {QueryDocumentSnapshot} from '@angular/fire/firestore';
import {environment} from '../../../environments/environment';
import firebase from 'firebase';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-schedules-editor',
  templateUrl: './schedules-editor.component.html',
  styleUrls: ['./schedules-editor.component.scss'],
})
export class SchedulesEditorComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  selectedScheduleUid?: string;
  schedules: Schedule[] = [];
  private lastVisible?: QueryDocumentSnapshot<Schedule>;
  thereIsMore = false;
  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  private readonly spinnerKeyLoadSchedules = 'loadSchedules';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private cdRef: ChangeDetectorRef,
      private modalService: NgbModal,
      private adminService: AdminService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Schedules editor`);
    this.fetchSchedules();

    this.adminService.onScheduleDeleted$.pipe(takeUntil(this.destroy$)).subscribe(uid => {
      this.removeFromSchedulesList(uid);
    });
    this.adminService.onScheduleSaved$.pipe(takeUntil(this.destroy$)).subscribe(schedule => {
      this.reflectUpdateInSchedulesList(schedule);
    });
    this.adminService.onScheduleSelected$.pipe(takeUntil(this.destroy$)).subscribe(uid => {
      this.selectSchedule(uid);
    });
  }

  private fetchSchedules() {
    this.addLoadingSpinnerMessage(this.spinnerKeyLoadSchedules, $localize`Loading schedules...`);
    this.adminService.fetchSchedules(100, this.lastVisible).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyLoadSchedules);
      if (wrapper.data) {
        this.schedules = wrapper.data;
        this.lastVisible = wrapper.lastVisible;
        this.thereIsMore = wrapper.data.length === environment.defaultLoadNotificationsCount;
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  onImportExport(): void {
    this.clearAlerts();
    const modalRef = this.modalService.open(ImportExportModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.exportJson = JSON.stringify(this.schedules);
    modalRef.componentInstance.filename = 'stockforecast_schedules';

    modalRef.componentInstance.importCallback = (schedulesJson: string) => {
      if (!schedulesJson) {
        this.addError($localize`Nothing was imported. Aborted.`);
        return;
      }
      const importedSchedules: Schedule[] = this.parseSchedulesImportJsonString(schedulesJson);
      if (!importedSchedules) {
        this.addError($localize`The import did not contain any schedules. Aborted.`);
        return;
      }
      this.schedules = importedSchedules;
      this.adminService.deleteAllSchedules().then(() => {
            this.addSuccess($localize`Successfully deleted all existing schedules.`);

            importedSchedules.forEach(schedule => {
              if (!schedule?.uid) {
                this.addError($localize`Error importing schedule <i>${schedule.name}</i>\: schedule is undefined or has no uid`);
                return;
              }
              this.adminService.updateSchedule(schedule.uid, schedule, schedule, false, () => {
                    this.addSuccess($localize`Successfully imported schedule <i>${schedule.name}</i>.`);
                  },
                  (error) => {
                    this.addError($localize`Error importing schedule <i>${schedule.name}</i>\: ${error}`);
                  });
            });
          },
          reason => {
            this.addError($localize`Error deleting all existing schedules\: ${reason}`);
          });
      console.log(importedSchedules);
    };
  }

  private parseSchedulesImportJsonString(schedulesJson: string) {
    let schedules = JSON.parse(schedulesJson);
    let fixedSchedules: Schedule[] = [];
    for (let schedule of schedules) {
      const fixedSchedule = {
        ...schedule,
        creationDate: Timestamp.fromDate(Util.getDate(schedule.creationDate)),
        lastEditDate: Timestamp.fromDate(Util.getDate(schedule.lastEditDate)),
        lastComprehensiveSymbolCheck: Timestamp.fromDate(Util.getDate(schedule.lastComprehensiveSymbolCheck)),
      };
      fixedSchedules.push(fixedSchedule);
    }
    return fixedSchedules;
  }

  /**
   * Removes the schedule with the given UID from the schedules list
   * @param uid UID of the removed schedule
   */
  private removeFromSchedulesList(uid: string) {
    const removedSchedule = this.schedules.find((schedule: Schedule) => schedule.uid === uid);
    if (!removedSchedule)
      return;
    this.schedules = Util.removeFromArray([...this.schedules], removedSchedule);
    this.schedules = [...this.schedules];
  }

  /**
   * Updates the list with the newly saved schedule. This works both for insertions or updates.
   * @param schedule saved schedule
   */
  private reflectUpdateInSchedulesList(schedule: Schedule) {
    if (!this.schedules)
      this.schedules = [];
    this.schedules = [...this.schedules];
    const updatedScheduleIndex = this.schedules.findIndex(element => element.uid === schedule.uid);
    if (updatedScheduleIndex > -1)
      this.schedules[updatedScheduleIndex] = schedule;
    else
        // This was an insert
      this.schedules.push(schedule);

    this.schedules = [...this.schedules];
    this.selectedScheduleUid = schedule.uid;
  }

  private selectSchedule(uid?: string) {
    this.selectedScheduleUid = uid;
  }

  /**
   * Called, when the Show more button is clicked. Loads more schedules.
   */
  onClickShowMore(): void {
    this.fetchSchedules();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

}
