import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {StockService} from '../../services/stock.service';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {Address} from '../../models/address.interface';
import {UtilService} from '../../util.service';
import Locale from '../../services/locale';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Symbol} from '../../models/symbol.interface';
import {environment} from '../../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextModalComponent} from '../text-modal/text-modal.component';


@Component({
  selector: 'app-company-profile-view',
  templateUrl: './company-profile-view.component.html',
  styleUrls: ['./company-profile-view.component.scss'],
})
export class CompanyProfileViewComponent extends BaseComponent implements OnInit {

  public baseCurrency = environment.baseCurrency;
  locale = Locale.numberFormatLocale();
  spinnerKeyRefreshSymbol = 'refreshSymbol';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private modalService: NgbModal,
      private stockService: StockService,
      public utilService: UtilService,
  ) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refresh();
  }

  @ViewChild('companyImage') companyImageRef ?: ElementRef;

  @Input() symbol?: Symbol;
  @Output() symbolRefreshed = new EventEmitter<Symbol>();


  createAddress(symbol: Symbol): Address {
    return {
      name: symbol.name,
      street: symbol.address,
      zipCode: symbol.zip,
      city: symbol.city,
      state: symbol.state,
      countryCode: symbol.country,
    };
  }

  copyToClipboard(text: string) {
    const listener = (event: ClipboardEvent) => {
      event.clipboardData?.setData('text/plain', text);
      event.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  getRangeLowerEnd(rangeString: string): number {
    return this.extractNumbersFromRangeString(rangeString)[0];
  }

  getRangeUpperEnd(rangeString: string): number {
    return this.extractNumbersFromRangeString(rangeString)[1];
  }

  extractNumbersFromRangeString(rangeString: string): [number, number] {
    const rangeRegex = /^(\d+(\.\d+)?)-(\d+(\.\d+)?)$/;
    const matches = rangeString.match(rangeRegex);
    if (matches) {
      const [, startStr, , endStr] = matches;
      const start = parseFloat(startStr);
      const end = parseFloat(endStr);
      if (!isNaN(start) && !isNaN(end)) {
        return [start, end];
      }
    }
    throw new Error('Invalid range string');
  }

  hideImage() {
    if (this.companyImageRef)
      this.companyImageRef.nativeElement.style.display = 'none';
  }

  refresh() {
    if (!this.symbol?.code)
      return;

    this.addLoadingSpinnerMessage(this.spinnerKeyRefreshSymbol, $localize`Refreshing symbol data...`);
    this.stockService.refreshSymbol(this.symbol.code).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyRefreshSymbol);
      if (wrapper.data) {
        this.symbol = wrapper.data;
        this.symbolRefreshed.emit(this.symbol);
        if (this.companyImageRef)
          this.companyImageRef.nativeElement.style.display = 'block';
      }
      if (wrapper.errorMessage) {
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
      }
    });
  }

  isRefreshCooldownExpired() {
    if (!this.symbol?.priceDate)
      return true;
    const priceDate = this.utilService.getDate(this.symbol.priceDate).getTime();
    const now = new Date().getTime();
    return (now - priceDate) / 1000 > environment.symbolRefreshCooldownInSec;
  }


  showCompanyDescription() {
    const modalRef = this.modalService.open(TextModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.text = this.symbol?.description;
  }

}
