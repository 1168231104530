import {Component, Input} from '@angular/core';
import {StatisticalReturn} from '../../../shared/models/statisticalReturn.interface';
import {CollectiveTableHeaderComponent} from '../collective-table-header/collective-table-header.component';
import {StockService} from '../../../shared/services/stock.service';
import {Symbol} from '../../../shared/models/symbol.interface';
import {DevelopmentSinceBuyDay} from '../../../shared/models/developmentSinceBuyDay.interface';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {UtilService} from '../../../shared/util.service';
import {TradingPhase} from '../../../shared/enums/tradingPhase.enum';
import Util from '../../../shared/util';

@Component({
  selector: 'app-statistical-return-collective-data-view',
  templateUrl: './statistical-return-collective-data-view.component.html',
  styleUrls: ['./statistical-return-collective-data-view.component.scss'],
})
export class StatisticalReturnCollectiveDataViewComponent extends CollectiveTableHeaderComponent {

  @Input() showDevelopmentSinceBuyDay = false;

  _statRet?: StatisticalReturn;

  get statRet(): StatisticalReturn | undefined {
    return this._statRet;
  }

  @Input() set statRet(statRet: StatisticalReturn | undefined) {
    this._statRet = statRet;
    this.onInputChanged();
  }

  _symbol?: Symbol;

  get symbol(): Symbol | undefined {
    return this._symbol;
  }

  @Input() set symbol(symbol: Symbol | undefined) {
    this._symbol = symbol;
    this.onInputChanged();
  }


  _symbolPricesOnBuyDay?: DevelopmentSinceBuyDay;

  get symbolPricesOnBuyDay(): DevelopmentSinceBuyDay | undefined {
    return this._symbolPricesOnBuyDay;
  }

  @Input() set symbolPricesOnBuyDay(symbolPricesOnBuyDay: DevelopmentSinceBuyDay | undefined) {
    this._symbolPricesOnBuyDay = symbolPricesOnBuyDay;
    this.onInputChanged();
  }


  // Development since buy day
  development?: DevelopmentSinceBuyDay;
  targetSellPrice?: number;
  expectedProfit?: number;
  noPriceFound = false;

  constructor(private stockService: StockService,
              private statisticalTradingService: StatisticalTradingService,
              public utilService: UtilService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private onInputChanged() {

    if (this.showDevelopmentSinceBuyDay && this.symbolPricesOnBuyDay) {
      this.development = this.symbolPricesOnBuyDay;
      if (this.development.priceOnBuyDay)
        this.calcDevelopment();
    }

    // if (this.showDevelopmentSinceBuyDay && this.statRet && this.statRet.buyDayNumber && this.symbol) {
    //   this.statisticalTradingService.fetchSymbolPriceOnBuyDay(this.statRet.symbolCode, this.statRet.buyDayNumber).then(wrapper => {
    //     if (wrapper.data) {
    //       this.development = {...wrapper.data};
    //       this.calcDevelopment();
    //     }
    //     if (wrapper.errorMessage)
    //       console.error(wrapper.errorMessage);
    //     if (wrapper.data === undefined && wrapper.success)
    //       this.noPriceFound = true;
    //   });
    // }
  }

  private calcDevelopment() {
    this.development = {...this.development, currentPrice: this.symbol?.price};
    this.development = {...this.development, change: this.calcChange()};
    this.targetSellPrice = this.calcTargetSellPrice();
    this.expectedProfit = this.calcExpectedProfit();
  }

  calcChange() {
    if (this.development?.priceOnBuyDay === undefined || this.development?.currentPrice === undefined)
      return undefined;
    return this.development.currentPrice / this.development.priceOnBuyDay - 1;
  }

  calcTargetSellPrice() {
    if (this.development?.priceOnBuyDay === undefined || this.statRet?.averageRelativeProfit === undefined)
      return undefined;
    return this.development?.priceOnBuyDay * (1 + this.statRet?.averageRelativeProfit);
  }


  calcExpectedProfit() {
    if (this.development?.currentPrice === undefined || this.targetSellPrice === undefined)
      return undefined;
    return this.targetSellPrice! / this.development?.currentPrice - 1;
  }

  getExpectedProfitCssClass() {
    if (this.statRet?.averageRelativeProfit === undefined || this.expectedProfit === undefined)
      return '';
    if (this.expectedProfit! > this.statRet.averageRelativeProfit)
      return 'good-value';
    if (this.expectedProfit < this.statRet.averageRelativeProfit)
      return 'bad-value';
    return 'neutral-value';
  }

  getTradingPhaseString(tradingPhase: TradingPhase | undefined) {
    return Util.getTradingPhaseString(tradingPhase);
  }
}
