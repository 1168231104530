import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {addUserPublicToCache} from '../store/shared.actions';
import {User} from '../models/user.interface';
import {UserPublic} from '../models/userPublic.interface';
import {Mutex} from 'async-mutex';
// @ts-ignore
import firebase, {DocumentSnapshot} from 'firebase';
import {takeUntil} from 'rxjs/operators';
import {Wrapper} from '../models/wrapper.model';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {convertToUser, convertToUserPublic} from '../converters/modelConverters';
import {firestore} from '../../app.module';
import {UserSettings} from '../models/userSettings.interface';
import Locale from './locale';
import {fetchUser, updateUserMerge} from '../../auth/store/auth.actions';
import {selectUser} from '../../auth/store/auth.selectors';
import {selectUsersPublic} from '../store/shared.selectors';
import {AppState} from '../../store/app.state';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  userPublicMutex = new Mutex();
  destroy$: Subject<null> = new Subject();
  usersPublicById: Map<string, UserPublic> = new Map<string, UserPublic>();
  usersPublic$ = this.store.select(selectUsersPublic).pipe(takeUntil(this.destroy$));

  constructor(
      private store: Store<AppState>) {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {
    this.usersPublic$.subscribe(usersPublic => {
      if (usersPublic) {
        this.usersPublicById = new Map<string, UserPublic>();
        usersPublic.forEach(userPublic => {
          if (userPublic.uid)
            this.usersPublicById.set(userPublic.uid, userPublic);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  async fetchUserPublic(uid: string, maxAgeInSec: number = environment.defaultUserPublicCacheAgeInSec): Promise<Wrapper<UserPublic>> {
    return this.userPublicMutex.runExclusive(async () => {
      const userPublicFromCache = this.usersPublicById.get(uid);
      if (userPublicFromCache !== undefined && this.isUserUpToDate(userPublicFromCache, maxAgeInSec))
        return new Wrapper<UserPublic>(userPublicFromCache);

      try {
        const userPublicDocSnapshot = await firestore.collection(environment.firestoreCollectionUsersPublic).doc(uid).withConverter(userPublicConverter).get();
        const userPublic = userPublicDocSnapshot.data();
        if (userPublic) {
          const userPublicWithIdAndCacheDate: UserPublic = {...userPublic, uid: userPublicDocSnapshot.id, cacheDate: new Date()};
          this.addUserPublicToCache(userPublicWithIdAndCacheDate);
          return new Wrapper<UserPublic>(userPublicWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<UserPublic>(undefined, e.message);
      }

      // If no user was found
      return new Wrapper<UserPublic>(undefined);

    });
  }

  async fetchUsersPublic(startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadUserUserPublicCount): Promise<Wrapper<UserPublic[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionUsersPublic)
          .orderBy('creationDate', 'desc')
          .withConverter(userPublicConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const userPublicQuerySnapshot = await query.get();
      const usersPublic: UserPublic[] = [];
      userPublicQuerySnapshot.forEach(userPublicDocSnapshot => {
        const userPublic: UserPublic | undefined = userPublicDocSnapshot.data();
        if (userPublic) {
          const userPublicWithId = {...userPublic, uid: userPublicDocSnapshot.id};
          usersPublic.push(userPublicWithId);
        }
      });
      const lastVisible = userPublicQuerySnapshot.docs[userPublicQuerySnapshot.docs.length - 1];

      return new Wrapper<UserPublic[]>(usersPublic, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<UserPublic[]>(undefined, $localize`You are not allowed to view these users.`);
      return new Wrapper<UserPublic[]>(undefined, e.message);
    }
  }

  /**
   * Sends the given userPublic to the backend serPublicver. If it has an ID, an existing userPublic is updated, otherwise a new one is added.
   * @param uid ID of the userPublic to be sent
   * @param userPublic userPublic to be sent. Need to be provided, even if a fullUserPublic is given
   * @param fullUserPublic userPublic to be written to the cache. This userPublic is not sent to the firestore
   * @param merge if true, only the fields given in the userPublic object will be updated. Otherwise, the whole userPublic object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateUserPublic(userPublic: UserPublic, fullUserPublic: UserPublic | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionUsersPublic).doc(userPublic.uid).set(userPublic, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The userPublic could not be updated\: ${error}`),
    );
    if (merge && !fullUserPublic) {
      console.error('updateUserPublic called with a merge job without providing a fullUserPublic.');
      return;
    }
    this.addUserPublicToCache(merge && fullUserPublic ? fullUserPublic : userPublic);

  }


  /**
   * Checks, if the given user or userPublic is newer then the given max age.
   * @param userPublic user or userPublic to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isUserUpToDate(user: UserPublic | User, maxAgeInSec: any): boolean {
    if (!user.cacheDate)
      return false;
    const cacheTime = user.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addUserPublicToCache(userPublic: UserPublic) {
    this.usersPublicById.set(userPublic.uid, userPublic);
    this.store.dispatch(addUserPublicToCache({userPublic}));
  }

  /**
   * Sets default values for the given user, like. the default language.
   * @param userUid user, whose default values should be set
   */
  setDefaultValues(userUid: string) {
    const finished$: Subject<null> = new Subject();

    this.store.select(selectUser).pipe(takeUntil(finished$)).subscribe(user => {
      if (!user) {
        this.store.dispatch(fetchUser());
        setTimeout(() => {
          this.setDefaultValues(userUid);
        }, 5000);
        return;
      }

      let somethingWasChanged = false;

      const settings: UserSettings = user.settings ? {...user.settings} : {};

      if (!settings.lang) {
        settings.lang = Locale.languageLocale();
        console.log(`Setting default lang to ${settings.lang} based on the current URL or browser setting.`);
        somethingWasChanged = true;
      }
      if (!settings.timeZone) {
        settings.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (!settings.timeZone)
          settings.timeZone = Locale.defaultTimeZone();
        console.log(`Setting time zone to ${settings.timeZone}.`);
        somethingWasChanged = true;
      }

      user = {...user, settings};

      if (somethingWasChanged)
        this.store.dispatch(updateUserMerge({userUpdate: user}));

      finished$.next();
    });
  }
}


// Firestore data converter
export const userPublicConverter = {
  toFirestore(userPublic: UserPublic): UserPublic {
    return userPublic;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): UserPublic {
    return convertToUserPublic(snapshot.data(options));
  },
};

// Firestore data converter
export const userConverter = {
  toFirestore(user: User): User {
    return user;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): User {
    return convertToUser(snapshot.data(options));
  },
};

