import {Component, HostListener, Inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent extends BaseComponent implements OnInit {

  @Input() templateRef?: TemplateRef<any> | null;

  dialogTitle: string = '';
  showCloseButton: boolean = true;

  @ViewChild('dialogContent', {read: TemplateRef})
  dialogContentTemplateRef!: TemplateRef<any>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    protected dialogRef: MatDialogRef<BaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(authService, userService, store);
    if (data) {
      this.dialogTitle = data.dialogTitle;
      this.showCloseButton = data.showCloseButton;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
