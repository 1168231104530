import {Component, Input, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import {Alert} from '../../models/alert.interface';
import {AlertType} from '../../enums/alertType.enum';
import {UtilService} from '../../util.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
})
export class AlertsComponent implements OnInit, OnDestroy {

  destroy$: Subject<null> = new Subject();
  @Input() float = true;
  @Input() scrollToSubject = new Subject();

  constructor(private utilService: UtilService,
              private sanitizer: DomSanitizer) {
  }

  _alerts: Alert[] = [];

  get alerts(): Alert[] {
    return this._alerts;
  }

  @Input() set alerts(alerts: Alert[]) {
    this._alerts = alerts;
  }

  public get alertType(): typeof AlertType {
    return AlertType;
  }

  ngOnInit(): void {
    this.scrollToSubject.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.utilService.scrollToId('alerts-container');
    });
  }

  onClose(alert: Alert) {
    const index = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  sanitizeMessage(message?: string): SafeHtml | null {
    if (!message)
      return '';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const replacedMessage = message.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    return this.sanitizer.sanitize(SecurityContext.HTML, replacedMessage);
  }
}
