import {createAction, props} from '@ngrx/store';
import {Symbol} from '../shared/models/symbol.interface';
import {Exchange} from '../shared/models/exchange.interface';
import {SymbolsSearchParams} from '../shared/models/symbolsSearchParams.interface';
import {SymbolsSearchResult} from '../shared/models/symbolsSearchResult.interface';
import {StatisticalReturnsSearchParams, StatisticalReturnsSortOrder} from '../shared/models/statisticalReturnsSearchParams.interface';
import {StatisticalReturnsSearchResult} from '../shared/models/statisticalReturnsSearchResult.interface';
import {CompanyProfile} from '../shared/models/companyProfile.interface';
import {StatisticalReturn} from '../shared/models/statisticalReturn.interface';
import {DevelopmentSinceBuyDay} from '../shared/models/developmentSinceBuyDay.interface';

export const addSymbolToCache = createAction('[Stock] Adding symbol to cache', props<{ symbol: Symbol }>());
export const clearSymbolCache = createAction('[Stock] Clearing symbol cache');

export const addDevelopmentSinceBuyDayToCache = createAction('[Stock] Adding developmentSinceBuyDay to cache', props<{
  developmentSinceBuyDay: DevelopmentSinceBuyDay
}>());
export const clearDevelopmentSinceBuyDayCache = createAction('[Stock] Clearing developmentSinceBuyDay cache');

export const addSymbolPriceOnBuyDayToCache = createAction('[Stock] Adding symbolPriceOnBuyDay to cache', props<{
  developmentSinceBuyDay: DevelopmentSinceBuyDay
}>());
export const clearSymbolPriceOnBuyDayCache = createAction('[Stock] Clearing symbolPriceOnBuyDay cache');

export const addStatisticalReturnToCache = createAction('[Stock] Adding statisticalReturn to cache', props<{ statisticalReturn: StatisticalReturn }>());
export const clearStatisticalReturnCache = createAction('[Stock] Clearing statisticalReturn cache');

export const addCompanyProfileToCache = createAction('[Stock] Adding companyProfile to cache', props<{ companyProfile: CompanyProfile }>());
export const clearCompanyProfileCache = createAction('[Stock] Clearing companyProfile cache');
export const addExchangeToCache = createAction('[Stock] Adding exchange to cache', props<{ exchange: Exchange }>());
export const addExchangesToCache = createAction('[Stock] Adding exchanges to cache', props<{ exchanges: Exchange[] }>());
export const clearExchangeCache = createAction('[Stock] Clearing exchange cache');

// Symbol search

export const updateSymbolsSearchTerm = createAction('[Stock] Updating symbol search search term', props<{ searchTerm?: string }>());
export const updateSymbolSearchExchangeShortName = createAction('[Stock] Updating symbol search exchange short name', props<{ exchangeShortName?: string }>());
export const resetSymbolsSearch = createAction('[Stock] Resetting symbols search results');
export const setSymbolsSearchParams = createAction('[Stock] Setting symbols search params', props<{ searchParams: SymbolsSearchParams }>());
export const fetchSymbolsSearchResults = createAction('[Stock] Fetching symbols search results');
export const fetchMoreSymbolsSearchResults = createAction('[Stock] Fetching more symbols search results');
export const setSymbolsSearchResults = createAction('[Stock] Setting symbols search results', props<{ searchResult: SymbolsSearchResult }>());
export const setMoreSymbolsSearchResults = createAction('[Stock] Setting more symbols search results to be added to the result list',
  props<{ searchResult: SymbolsSearchResult }>());
export const symbolsSearchFailed = createAction('[Stock] Symbols search failed', props<{ errorMessage: string, code?: number }>());
export const removeSymbolFromSearchResults = createAction('[Stock] Removing symbol from symbols search', props<{ symbol: Symbol }>());

// Best statistical returns search

export const resetBestStatisticalReturnsSearch = createAction('[Stock] Resetting bestStatisticalReturns search results');
export const setBestStatisticalReturnsSearchParams = createAction('[Stock] Setting bestStatisticalReturns search params', props<{
  searchParams: StatisticalReturnsSearchParams
}>());

export const mergeUpdateBestStatisticalReturnSearchParams = createAction('[Stock] Merge updating bestStatisticalReturns search params', props<{
  searchParams: StatisticalReturnsSearchParams
}>());
export const fetchBestStatisticalReturnsSearchResults = createAction('[Stock] Fetching best statistical results');
export const fetchMoreBestStatisticalReturnsSearchResults = createAction('[Stock] Fetching more best statistical results');
export const setBestStatisticalReturnsSortOrder = createAction('[Stock] Setting bestStatisticalReturns sortOrder', props<{
  sortOrder?: StatisticalReturnsSortOrder
}>());
export const setBestStatisticalReturnsSearchResults = createAction('[Stock] Setting bestStatisticalReturns search results', props<{
  searchResult: StatisticalReturnsSearchResult
}>());
export const bestStatisticalReturnsSearchFailed = createAction('[Stock] BestStatisticalReturns search failed', props<{
  errorMessage: string,
  code?: number
}>());
export const setBestStatisticalReturnsExchangesFilter = createAction('[Stock] Setting best statistical results exchanges filter', props<{
  exchangeShortNames: string[]
}>());
export const filterBestStatisticalReturns = createAction('[Stock] Filtering best statistical results by the filter terms set in the store');

// Upcoming statistical returns search

export const resetUpcomingStatisticalReturnsSearch = createAction('[Stock] Resetting upcomingStatisticalReturns search results');
export const setUpcomingStatisticalReturnsSearchParams = createAction('[Stock] Setting upcomingStatisticalReturns search params', props<{
  searchParams: StatisticalReturnsSearchParams
}>());
export const mergeUpdateUpcomingStatisticalReturnSearchParams = createAction('[Stock] Merge updating upcomingStatisticalReturns search params', props<{
  searchParams: StatisticalReturnsSearchParams
}>());
export const setUpcomingStatisticalReturnsBuyDayNumber = createAction('[Stock] Setting upcomingStatisticalReturns buyDayNumber', props<{
  buyDayNumber: number
}>());
export const setUpcomingStatisticalReturnsSortOrder = createAction('[Stock] Setting upcomingStatisticalReturns sortOrder', props<{
  sortOrder?: StatisticalReturnsSortOrder
}>());
export const fetchUpcomingStatisticalReturnsSearchResults = createAction('[Stock] Fetching upcoming statistical results');
export const fetchMoreUpcomingStatisticalReturnsSearchResults = createAction('[Stock] Fetching more upcoming statistical results');
export const setUpcomingStatisticalReturnsSearchResults = createAction('[Stock] Setting upcomingStatisticalReturns search results', props<{
  searchResult: StatisticalReturnsSearchResult
}>());
export const upcomingStatisticalReturnsSearchFailed = createAction('[Stock] UpcomingStatisticalReturns search failed', props<{
  errorMessage: string,
  code?: number
}>());
export const setUpcomingStatisticalReturnsExchangesFilter = createAction('[Stock] Setting upcoming statistical results exchanges filter', props<{
  exchangeShortNames: string[]
}>());
export const filterUpcomingStatisticalReturns = createAction('[Stock] Filtering upcoming statistical results by the filter terms set in the store');











