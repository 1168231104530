<div class="modal-body">
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>

  <ng-container *ngIf="step; else noSteps">
    <h1 class="paragraph-headline" i18n>Depot content</h1>
    <p>
      <app-trade-action-view [action]="step.action"></app-trade-action-view>
      -
      <app-date-view [date]="step.date" mode="compact-date-only"></app-date-view>
    </p>


    <table class="table table-striped table-sm table-responsive-sm table-sticky-header" *ngIf="step.depotContentsAfterAction; else noContent">
      <thead>
      <tr>
        <th i18n>Stock</th>
        <th i18n>Current value</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let symbolWithPrice of step.depotContentsAfterAction">
        <td><a [routerLink]="['/statistical-trading','symbols',symbolWithPrice.symbolCode, symbolWithPrice.statisticalReturnUid]">
          <app-symbol-descriptor [symbolCode]="symbolWithPrice.symbolCode" [nameOnly]="true"></app-symbol-descriptor>
        </a></td>
        <td>
          <app-money [value]="symbolWithPrice.price" [currencyId]="currencyId"></app-money>
        </td>
      </tr>
      </tbody>
      <tfoot *ngIf="step.depotValueAfterAction">
      <tr>
        <th i18n>Sum</th>
        <th>
          <app-money [value]="step.depotValueAfterAction" [currencyId]="currencyId"></app-money>
        </th>
      </tr>
      </tfoot>
    </table>
  </ng-container>

  <ng-template #noContent>
    <div i18n>There are no stocks in the depot at this time.</div>
  </ng-template>
  <ng-template #noSteps>
    <div i18n>There is no trading step.</div>
  </ng-template>
</div>

