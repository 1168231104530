<div *ngIf="statRet">
  <table class="table table-striped table-sm table-responsive-md table-sticky-header">
    <thead>
    <tr>
      <th i18n>Period</th>
      <th><span i18n-matTooltip matTooltip="The average relative annual profit if the symbol is bought each year on the buy date and sold on the sell date within the period" i18n>Avg. profit</span>
      <th><span i18n-matTooltip matTooltip="The average max. rise within the buy and sell period relative to the buy price over all years within the period" i18n>Avg. max. rise</span></th>
      <th><span i18n-matTooltip matTooltip="The average max. drop within the buy and sell period relative to the buy price over all years within the period" i18n>Avg. max. drop</span></th>
      <th><span i18n-matTooltip matTooltip="The highest rise within the buy and sell period relative to the buy price of all years within the period" i18n>Highest rise</span></th>
      <th><span i18n-matTooltip matTooltip="The highest drop within the buy and sell period relative to the buy price of all years within the period" i18n>Highest drop</span></th>
      <th><span i18n-matTooltip matTooltip="The win ratio (number of profitable years / total years)" i18n>Win ratio</span></th>
    </tr>
    </thead>
    <tbody>

    <ng-container *ngIf="statRet.profitByPeriodMap">
      <tr *ngFor="let period of getKeys(statRet.profitByPeriodMap)">
        <td>{{period}}</td>
        <td *ngIf="statRet.profitByPeriodMap; else none">
          <span [class]="statRet.profitByPeriodMap![period]! | valueClassPipe">
            <app-percentage-view [value]="statRet.profitByPeriodMap[period]"></app-percentage-view>
          </span>
        </td>
        <td *ngIf="statRet.maxRiseByYearMap; else none">
          <app-percentage-view [value]="getAvgMaxRiseForPeriod(statRet,period)"></app-percentage-view>
        </td>
        <td *ngIf="statRet.maxDropByYearMap; else none">
          <app-percentage-view [value]="getAvgMaxDropForPeriod(statRet,period)"></app-percentage-view>
        </td>
        <td *ngIf="getHighestRiseForPeriod(statRet,period) !== null; else none">
          <app-percentage-view [value]="getHighestRiseForPeriod(statRet,period)!"></app-percentage-view>
        </td>
        <td *ngIf="getHighestDropForPeriod(statRet,period) !== null; else none">
          <app-percentage-view [value]="getHighestDropForPeriod(statRet,period)!"></app-percentage-view>
        </td>
        <td *ngIf="getWinRatio(statRet,period) !== null; else none">
          <app-percentage-view [value]="getWinRatio(statRet,period)!"></app-percentage-view>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

<ng-template #none>
  -
</ng-template>
