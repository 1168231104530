import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {StatisticalTradingService} from '../statistical-trading.service';
import {takeUntil} from 'rxjs/operators';
import Util from '../../shared/util';
import {
  selectSymbolSearchErrorMessage,
  selectSymbolSearchParams,
  selectSymbolSearchResultList,
  selectSymbolSearchSearching,
  selectSymbolSearchThereIsMore,
  selectSymbolSearchTotalCount,
} from '../../store/stock.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-stock-search',
  templateUrl: './stock-search.component.html',
  styleUrls: ['./stock-search.component.scss'],
})
export class StockSearchComponent extends BaseComponent implements OnInit {
  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  symbols$ = this.store.select(selectSymbolSearchResultList);
  thereIsMore$ = this.store.select(selectSymbolSearchThereIsMore);
  searchTotalCount$ = this.store.select(selectSymbolSearchTotalCount);
  searchErrorMessage$ = this.store.select(selectSymbolSearchErrorMessage);
  searchParams$ = this.store.select(selectSymbolSearchParams);
  selectSymbolSearchSearching$ = this.store.select(selectSymbolSearchSearching);

  private readonly spinnerKeySearchingSymbols = 'searchingSymbols';

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private statisticalTradingService: StatisticalTradingService,
    private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Stock search`);

    this.selectSymbolSearchSearching$.pipe(takeUntil(this.destroy$)).subscribe(searching => {
      if (searching)
        this.addLoadingSpinnerMessage(this.spinnerKeySearchingSymbols, $localize`Searching symbols...`);
      else
        this.removeLoadingSpinnerMessage(this.spinnerKeySearchingSymbols);
    });

    this.searchErrorMessage$.pipe(takeUntil(this.destroy$)).subscribe(errorMessage => {
        this.clearAlerts();
        if (errorMessage)
          this.addError(errorMessage);
      },
    );
  }

  /**
   * Called, when the Show more button is clicked. Loads more trades.
   */
  onClickShowMore(): void {
    this.statisticalTradingService.fetchMoreSearchResults();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

  selectExchange(exchangeShortName: string) {
    this.statisticalTradingService.updateExchangeShortName(exchangeShortName);
    this.statisticalTradingService.fetchSearchResults();
  }

}
