import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {Wrapper} from '../models/wrapper.model';
import {Mutex} from 'async-mutex';
import {takeUntil} from 'rxjs/operators';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Exchange} from '../models/exchange.interface';
import {convertToExchange} from '../converters/modelConverters';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BackendService} from './backend.service';
import {addExchangesToCache, addExchangeToCache} from '../../store/stock.actions';
import {PayloadResultJo} from '../models/results/payloadResultJo.interface';
import {PagedList} from '../models/pagedList.interface';
import {selectCachedExchanges} from '../../store/stock.selectors';
import {AppState} from '../../store/app.state';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {

  destroy$: Subject<null> = new Subject();
  stockMutex = new Mutex();
  exchangesByShortName: Map<string, Exchange> = new Map<string, Exchange>();
  exchanges$ = this.store.select(selectCachedExchanges).pipe(takeUntil(this.destroy$));

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              private backendService: BackendService) {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {
    this.exchanges$.subscribe(exchanges => {
      if (exchanges) {
        this.exchangesByShortName = new Map<string, Exchange>();
        exchanges.forEach(exchange => {
          if (exchange.shortName)
            this.exchangesByShortName.set(exchange.shortName, exchange);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  async getCachedExchangesOrFetch(): Promise<Wrapper<Exchange[]>> {
    if (this.exchangesByShortName && this.exchangesByShortName.size > 0) {
      let exchanges = [...this.exchangesByShortName.values()];
      const wrapper = new Wrapper<Exchange[]>(exchanges);
      return new Promise((resolve, reject) => {
        resolve(wrapper);
      });
    }
    return this.fetchAndCacheExchanges();
  }

  async fetchExchanges(): Promise<Wrapper<Exchange[]>> {
    try {
      const response = await this.httpClient.post<PayloadResultJo<PagedList<Exchange>>>(environment.backend_api_path + environment.backend_api_request_exchanges_all,
        null, {params: this.backendService.getApiPublicKeyParam()}).toPromise();
      return new Wrapper(response.payload.list, response.message, undefined, response.isSuccess);
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        return new Wrapper<Exchange[]>(undefined, error.error.message);
      return new Wrapper<Exchange[]>(undefined, error.message);
    }
  }

  async fetchAndCacheExchanges(): Promise<Wrapper<Exchange[]>> {
    const wrapper = await this.fetchExchanges();
    if (wrapper.data)
      this.addExchangesToCache(wrapper.data);
    if (!wrapper.success && wrapper.errorMessage)
      console.error(wrapper.errorMessage);
    return new Promise((resolve, reject) => {
      resolve(wrapper);
    });
  }

  private addExchangeToCache(exchange: Exchange) {
    exchange = {...exchange, cacheDate: new Date()};
    if (exchange.shortName)
      this.exchangesByShortName.set(exchange.shortName, exchange);
    this.store.dispatch(addExchangeToCache({exchange}));
  }

  private addExchangesToCache(exchanges: Exchange[]) {
    const exchangesWithCacheDate: Exchange[] = [];
    let now = new Date();
    exchanges.forEach(exchange => {
      exchangesWithCacheDate.push({...exchange, cacheDate: now});
      if (exchange.shortName)
        this.exchangesByShortName.set(exchange.shortName, exchange);
    });
    this.store.dispatch(addExchangesToCache({exchanges: exchangesWithCacheDate}));
  }

  /**
   * Retrieves an exchange by its short name.
   *
   * @param exchangeShortName - The short name of the exchange to retrieve.
   * @returns A Promise that resolves to the Exchange object if found, or undefined if not found.
   */
  async getExchange(exchangeShortName: string): Promise<Exchange | undefined> {
    // Check for the exchange in the cache
    let exchangeFromCache = this.exchangesByShortName.get(exchangeShortName);
    if (exchangeFromCache) {
      return exchangeFromCache;
    }

    // Fetch exchanges if not found in cache
    const wrapper = await this.fetchAndCacheExchanges();
    if (wrapper.success && wrapper.data) {
      return wrapper.data.find(it => it.shortName === exchangeShortName);
    }
    if (wrapper.errorMessage)
      console.error(`Error fetching exchanges: ${wrapper.errorMessage}`);

    // Return undefined if the exchange is not found
    return undefined;
  }

}

// Firestore data converter

export const
  exchangeConverter = {
    toFirestore(exchange: Exchange): Exchange {
      return exchange;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Exchange {
      return convertToExchange(snapshot.data(options));
    },
  };
