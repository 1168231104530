import {Injectable} from '@angular/core';
import firebase from 'firebase/app';
// for cloud firestore
import {environment} from '../../../environments/environment';
import Functions = firebase.functions.Functions;
import HttpsError = firebase.functions.HttpsError;
import HttpsCallableResult = firebase.functions.HttpsCallableResult;

// Required for side-effects
require('firebase/functions');

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {

  functions: Functions;

  constructor() {
    this.functions = firebase.app().functions(environment.firebaseConfig.regionName);
  }

  getSecretPayload(params: string, onSuccessCallback: (secret: string) => void,
                   onErrorCallback: (errorMessage: string) => void) {
    this.runFunction('auth-getSecretPayload', params, onSuccessCallback, onErrorCallback);
  }

  getSecretPayloadAsync(params: string): Promise<HttpsCallableResult> {
    return this.runFunctionAsync('auth-getSecretPayload', params);
  }

  private runFunction(name: string, params: any, onSuccessCallback: (result: any) => void, onErrorCallback: (errorMessage: string) => void) {
    const fun = this.functions.httpsCallable(name);
    fun(params)
      .then((result: any) => {
        onSuccessCallback(result.data);
      })
      .catch((error: HttpsError) => {
        onErrorCallback(error.message);
      });
  }

  private async runFunctionAsync(name: string, params: any): Promise<HttpsCallableResult> {
    const fun = this.functions.httpsCallable(name);
    return fun(params);
  }
}
