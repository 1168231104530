import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {StockService} from '../../shared/services/stock.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {StatisticalTradingService} from '../statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {TableColumn} from '../../shared/models/columnSettings.interface';
import {TableColumnService} from '../../account/table-column.service';
import {Symbol} from '../../shared/models/symbol.interface';
import {StatisticalReturnViewType} from '../../shared/types/statisticalReturnViewType.type';
import {
  selectBestStatisticalReturnSearchSortOrder,
  selectUpcomingStatisticalReturnBuyDayNumber,
  selectUpcomingStatisticalReturnSearchSortOrder,
} from '../../store/stock.selectors';
import {StatisticalReturnsSortOrder} from '../../shared/models/statisticalReturnsSearchParams.interface';
import {filterUpcomingStatisticalReturns, setBestStatisticalReturnsSortOrder, setUpcomingStatisticalReturnsSortOrder} from '../../store/stock.actions';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DevelopmentSinceBuyDay} from '../../shared/models/developmentSinceBuyDay.interface';
import Util from '../../shared/util';
import {SymbolAndStatisticalReturnUid} from '../../shared/models/symbolAndStatisticalReturnUid.interface';

@Component({
  selector: 'app-statistical-returns-table',
  templateUrl: './statistical-returns-table.component.html',
  styleUrls: ['./statistical-returns-table.component.scss'],
})
export class StatisticalReturnsTableComponent extends BaseComponent implements OnInit {

  sortOrder$: Observable<StatisticalReturnsSortOrder | undefined> | undefined;
  upcomingBuyDayNumber$ = this.store.select(selectUpcomingStatisticalReturnBuyDayNumber);
  periods: string[] = ['5', '10', '15', '20', '25', '30'];
  years: string[] = [];

  visibleColumns: TableColumn[] = [];

  symbolsById: Map<string, Symbol> = new Map<string, Symbol>();
  symbolPricesOnBuyDayBySymbolCode: Map<string, DevelopmentSinceBuyDay> = new Map<string, DevelopmentSinceBuyDay>();

  @Input() showName = false;
  @Input() showExchange = false;
  @Output() onExchangeClicked = new EventEmitter<string>();
  @Input() sortable = false;

  @Input() viewType: StatisticalReturnViewType = 'best';

  @Input() customIcon?: string;
  @Output() onCustomIconClicked = new EventEmitter<SymbolAndStatisticalReturnUid>();


  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private stockService: StockService,
    private statisticalTradingService: StatisticalTradingService,
    private tableColumnService: TableColumnService) {
    super(authService, userService, store);
  }

  _statisticalReturns?: StatisticalReturn[] = [];

  get statisticalReturns(): StatisticalReturn[] | undefined {
    return this._statisticalReturns;
  }

  showDevelopmentSinceBuyDay = false;

  @Input() set statisticalReturns(statisticalReturns: StatisticalReturn[] | undefined) {
    this._statisticalReturns = statisticalReturns;
    this.years = StatisticalTradingService.determineYears(statisticalReturns, false);
    const symbolCodes = statisticalReturns?.map(statRet => statRet.symbolCode);
    this.loadSymbols(symbolCodes);
    if (this.viewType === 'upcoming')
      this.loadPricesSinceBuyDay(symbolCodes);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initTableColumns();

    if (this.viewType === 'best') {
      this.sortOrder$ = this.store.select(selectBestStatisticalReturnSearchSortOrder);
    } else {
      this.sortOrder$ = this.store.select(selectUpcomingStatisticalReturnSearchSortOrder);
    }
  }

  onExchangeClick(exchangeShortName: string) {
    this.onExchangeClicked.emit(exchangeShortName);
  }

  private initTableColumns() {
    this.user$.subscribe(user => {
      this.visibleColumns = this.tableColumnService.getTableColumns(user, 'statistical-returns');
      this.filterOutNameCodeAndExchangeIfApplicable();
    });

  }

  private filterOutNameCodeAndExchangeIfApplicable() {
    if (!this.showName) {
      // Remove name and code columns
      this.visibleColumns = this.visibleColumns.filter(it => (it.id !== 'SYMBOL_NAME' && it.id !== 'SYMBOL_CODE'));
    }
    if (!this.showExchange) {
      // Remove exchange column
      this.visibleColumns = this.visibleColumns.filter(it => (it.id !== 'EXCHANGE'));
    }
  }

  private loadSymbols(symbolCodes: string[] | undefined) {
    this.stockService.refreshSymbols(symbolCodes).then(wrapper => {
      if (wrapper.data) {
        const symbolCountBeforeAddingNewOnes = this.symbolsById.size;
        wrapper.data.forEach(it => {
          this.symbolsById.set(it.code, it);
        });
        if (symbolCountBeforeAddingNewOnes < this.symbolsById.size) {
          // New symbols have been added to the map. Filter upcoming statistical returns again.
          this.store.dispatch(filterUpcomingStatisticalReturns());
          // Note: It's necessary to check, if new symbols might have been added to avoid creating an infinite loop. Filtering updates the statisticalReturns
          // in the store, which triggers another call to loadSymbols.
        }
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  shouldShowDevelopmentSinceBuyDay(buyDayNumberSelected: number | undefined | null) {
    if (!buyDayNumberSelected)
      return false;
    const now = new Date();
    let buyDayNumberToday = Util.calculateDayNumberFromDate(now);
    if (buyDayNumberSelected > buyDayNumberToday)
      buyDayNumberSelected -= 365;
    if (Math.abs(buyDayNumberSelected - buyDayNumberToday) > 30)
      return false;
    // Selected day is close to today
    return buyDayNumberSelected < buyDayNumberToday;
  }

  sort(sortOrderString: string) {
    const sortOrder = this.getStatisticalReturnsSortOrderFromSortOrder(sortOrderString);
    if (this.viewType === 'best')
      this.store.dispatch(setBestStatisticalReturnsSortOrder({sortOrder}));
    if (this.viewType === 'upcoming')
      this.store.dispatch(setUpcomingStatisticalReturnsSortOrder({sortOrder}));
  }

  getStatisticalReturnsSortOrderFromSortOrder(sortOrderString: string): StatisticalReturnsSortOrder | undefined {
    return Object.values(StatisticalReturnsSortOrder).includes(sortOrderString as StatisticalReturnsSortOrder)
      ? (sortOrderString as StatisticalReturnsSortOrder)
      : undefined;
  }

  private loadPricesSinceBuyDay(symbolCodes: string[] | undefined) {
    if (!symbolCodes)
      return;
    this.upcomingBuyDayNumber$.pipe(takeUntil(this.destroy$)).subscribe(buyDayNumber => {
        if (buyDayNumber && this.shouldShowDevelopmentSinceBuyDay(buyDayNumber)) {
          this.showDevelopmentSinceBuyDay = true;

          const batchSize = 5; // Number of symbol codes per batch
          const numberOfBatches = Math.ceil(symbolCodes.length / batchSize);


          const fetchBatch = async (batchIndex: number) => {
            const start = batchIndex * batchSize;
            const end = start + batchSize;
            const batchSymbolCodes = symbolCodes.slice(start, end);

            await this.statisticalTradingService.fetchSymbolPricesOnBuyDay(batchSymbolCodes, buyDayNumber)
              .then(wrapper => {
                if (wrapper.data) {
                  wrapper.data.forEach(it => {
                    if (it.symbolCode)
                      this.symbolPricesOnBuyDayBySymbolCode.set(it.symbolCode, it);
                  });
                }
                if (wrapper.errorMessage)
                  this.addError(wrapper.errorMessage);
              });
          };

          const fetchAllBatches = async () => {
            for (let i = 0; i < numberOfBatches; i++) {
              await fetchBatch(i);
            }
          };

          fetchAllBatches();

        }
      },
    );
  }

  customIconClicked(symbolCode: string, statisticalReturnUid: string | undefined) {
    if (symbolCode && statisticalReturnUid) {
      const symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid = {symbolCode, statisticalReturnUid};
      this.onCustomIconClicked.emit(symbolAndStatisticalReturnUid);
    }
  }
}
