import {Component, Input, OnInit} from '@angular/core';
import Locale from '../../services/locale';

@Component({
  selector: 'app-percentage-view',
  templateUrl: './percentage-view.component.html',
  styleUrls: ['./percentage-view.component.scss'],
})
export class PercentageViewComponent implements OnInit {

  @Input() value?: number;
  @Input() format = '1.0-2';
  numberFormatLocale = Locale.numberFormatLocale();

  constructor() {
  }

  ngOnInit(): void {
  }

}
