<ng-container *ngIf="!selectionBelow">
  <ng-container *ngTemplateOutlet="selection"></ng-container>
</ng-container>

<ng-container *ngIf="maxSelectionCount == undefined || maxSelectionCount > selectedElements.length">

  <ng-container *ngIf="elementType === 'symbols'">
    <app-stock-search-panel class="mx-2" layout="full"></app-stock-search-panel>
    <app-symbol-search-table (onSymbolClicked)="addSymbol($event)" [isClickable]="true"></app-symbol-search-table>
  </ng-container>

  <div *ngIf="elementType === 'exchanges'" [class]="selectionBelow ? 'mb-3' : 'mt-3'">
    <app-exchange-selector (onExchangeSelected)="addExchange($event)" [narrowButton]="narrowButton" [bigButton]="bigButton" [buttonColor]="buttonColor"
                           [hiddenExchangeShortNames]="selectedElements"></app-exchange-selector>

    <ng-container *ngIf="showQuickSelectExchangeButtons">
      <app-mat-icon-button *ngIf="shouldShowAddUsExchangesButton()" [callback]="addUsExchanges.bind(this)" color="secondary" [cssClass]="bigButton ? 'big-button mx-1' : 'mx-1'" i18n-label="US" icon="add" label="US" type="button"></app-mat-icon-button>
      <app-mat-icon-button *ngIf="shouldShowAddDeExchangesButton()" [callback]="addDeExchanges.bind(this)" color="secondary" [cssClass]="bigButton ? 'big-button mx-1' : 'mx-1'" i18n-label="DE" icon="add" label="DE" type="button"></app-mat-icon-button>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="selectionBelow">
  <ng-container *ngTemplateOutlet="selection"></ng-container>
</ng-container>

<ng-template #selection>
  <mat-chip-list aria-label="Symbol selection" *ngIf="selectedElements">
    <mat-chip (removed)="remove(selectedElement)" *ngFor="let selectedElement of selectedElements">
      <mat-icon matChipRemove class="ml-0 mr-1">cancel</mat-icon>
      <span class="mr-2">
      <ng-container *ngIf="elementType === 'symbols'">
        <app-symbol-descriptor [symbolCode]="selectedElement" [nameOnly]="true"></app-symbol-descriptor>
      </ng-container>
      <ng-container *ngIf="elementType === 'exchanges'">
        <app-exchange-descriptor [exchangeShortName]="selectedElement" mode="codeOnly"></app-exchange-descriptor>
      </ng-container>
    </span>
    </mat-chip>
  </mat-chip-list>
</ng-template>
