<!--<div class="alert alert-primary">-->
<h3 class="paragraph-headline-3" i18n>Admin functions</h3>
<app-mat-icon-button [callback]="analyze.bind(this)" [disabled]="analysing || analysisFinished" cssClass="mb-2 mr-2" i18n-label
                     icon="calculate" label="Analyse this symbol"></app-mat-icon-button>

<app-mat-icon-button *ngIf="symbol" [callback]="fetchSymbol.bind(this)" [params]="[symbol.code]" cssClass="mb-2" i18n-label
                     icon="refresh" label="Reload symbol"></app-mat-icon-button>

<table class="table table-striped table-sm table-responsive">
  <tr>
    <th i18n>Last analysis:</th>
    <td>
      <ng-container *ngIf="symbol && symbol?.statisticalReturnsAnalysisDate as date; else unknown">
        <app-date-view [date]="date"></app-date-view>
      </ng-container>
    </td>
  </tr>
  <tr>
    <th i18n>Analysis version:</th>
    <td>
      <ng-container *ngIf="symbol && symbol?.statisticalReturnsAnalysisVersion; else unknown">
        {{symbol.statisticalReturnsAnalysisVersion}}
      </ng-container>
    </td>
  </tr>
</table>
<ng-template #unknown>
  <span i18n>Unknown. </span> <span *ngIf="symbol" i18n>Please <a (click)="fetchSymbol(symbol.code)">reload</a>.</span>
</ng-template>
<div *ngIf="analysing" class="alert alert-info mt-2" i18n>The analysis has been started. This will take a couple of seconds. You can close this window at any time. That will not disrupt the analysis.</div>

<div class="mt-2">
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
</div>
<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [messagesByKey]="loadingSpinnerMessagesByKey"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
<!--</div>-->
