<ng-container *ngIf="sortable; else notSortable">
  <a (click)="sort()" [class]="currentSortOrder == orderDesc || currentSortOrder == orderAsc ? 'hover-pointer space-for-arrow' : 'hover-pointer'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <mat-icon *ngIf="currentSortOrder == orderDesc" class="small">arrow_downward</mat-icon>
    <mat-icon *ngIf="currentSortOrder == orderAsc" class="small">arrow_upward</mat-icon>
  </a>
</ng-container>

<ng-template #notSortable>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
