import {createSelector} from '@ngrx/store';
import {AppState} from './app.state';

export const selectStock = (state: AppState) => state.stock;

// Symbol search

export const selectSymbolSearch = createSelector(
  selectStock,
  (stockState) => stockState.symbolsSearch,
);
export const selectSymbolSearchSearching = createSelector(
  selectSymbolSearch,
  (symbolSearch) => symbolSearch.searching,
);
export const selectSymbolSearchParams = createSelector(
  selectSymbolSearch,
  (symbolSearch) => symbolSearch.searchParams,
);
export const selectSymbolSearchSearchTerm = createSelector(
  selectSymbolSearchParams,
  (symbolSearchParams) => symbolSearchParams.searchTerm,
);
export const selectSymbolSearchExchangeShortName = createSelector(
  selectSymbolSearchParams,
  (symbolSearchParams) => symbolSearchParams.exchangeShortName,
);
export const selectSymbolSearchResult = createSelector(
  selectSymbolSearch,
  (symbolSearch) => symbolSearch.searchResult,
);
export const selectSymbolSearchErrorMessage = createSelector(
  selectSymbolSearchResult,
  (symbolsSearchResult) => symbolsSearchResult.errorMessage,
);
export const selectSymbolSearchThereIsMore = createSelector(
  selectSymbolSearchResult,
  (symbolsSearchResult) => symbolsSearchResult.thereIsMore,
);
export const selectSymbolSearchTotalCount = createSelector(
  selectSymbolSearchResult,
  (symbolsSearchResult) => symbolsSearchResult.totalCount,
);
export const selectSymbolSearchResultList = createSelector(
  selectSymbolSearchResult,
  (symbolsSearchResult) => symbolsSearchResult.symbols,
);

// Best statistical returns search

export const selectBestStatisticalReturnSearch = createSelector(
  selectStock,
  (stockState) => stockState.bestStatisticalReturnsSearch,
);
export const selectBestStatisticalReturnSearchSearching = createSelector(
  selectBestStatisticalReturnSearch,
  (bestStatisticalReturnSearch) => bestStatisticalReturnSearch.searching,
);
export const selectBestStatisticalReturnSearchParams = createSelector(
  selectBestStatisticalReturnSearch,
  (bestStatisticalReturnSearch) => bestStatisticalReturnSearch.searchParams,
);
export const selectBestStatisticalReturnSearchSymbolCode = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.symbolCode,
);
export const selectBestStatisticalReturnSearchExchangeShortNames = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.exchangeShortNames,
);

export const selectBestStatisticalReturnSearchSortOrder = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.sortOrder,
);
export const selectBestStatisticalReturnSearchMinAverageRelativeProfit = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minAverageRelativeProfit,
);
export const selectBestStatisticalReturnSearchMinAverageVolume = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minAverageVolume,
);
export const selectBestStatisticalReturnSearchMinAverageVolumeBaseCurrency = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minAverageVolumeBaseCurrency,
);
export const selectBestStatisticalReturnSearchMinDataYears = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minDataYears,
);
export const selectBestStatisticalReturnSearchMaxDuration = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.maxDuration,
);
export const selectBestStatisticalReturnSearchMinLowestYearlyProfit = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minLowestYearlyProfit,
);
export const selectBestStatisticalReturnSearchMinWinRatio = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minWinRatio,
);
export const selectBestStatisticalReturnSearchMinPeriodWinRatios = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minPeriodWinRatios,
);
export const selectBestStatisticalReturnSearchMinAverageMaxRise = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minAverageMaxRise,
);
export const selectBestStatisticalReturnSearchMaxAverageMaxDrop = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.maxAverageMaxDrop,
);
export const selectBestStatisticalReturnSearchMinHighestRise = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minHighestRise,
);
export const selectBestStatisticalReturnSearchMaxHighestDrop = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.maxHighestDrop,
);
export const selectBestStatisticalReturnSearchMinMarketCap = createSelector(
  selectBestStatisticalReturnSearchParams,
  (bestStatisticalReturnSearchParams) => bestStatisticalReturnSearchParams.minMarketCap,
);
export const selectBestStatisticalReturnSearchResult = createSelector(
  selectBestStatisticalReturnSearch,
  (bestStatisticalReturnSearch) => bestStatisticalReturnSearch.searchResult,
);
export const selectBestStatisticalReturnSearchErrorMessage = createSelector(
  selectBestStatisticalReturnSearchResult,
  (bestStatisticalReturnsSearchResult) => bestStatisticalReturnsSearchResult.errorMessage,
);
export const selectBestStatisticalReturnSearchThereIsMore = createSelector(
  selectBestStatisticalReturnSearchResult,
  (bestStatisticalReturnsSearchResult) => bestStatisticalReturnsSearchResult.thereIsMore,
);
export const selectBestStatisticalReturnSearchAllFilteredOut = createSelector(
  selectBestStatisticalReturnSearchResult,
  (bestStatisticalReturnsSearchResult) => bestStatisticalReturnsSearchResult.allFilteredOut,
);
export const selectBestStatisticalReturnSearchResultList = createSelector(
  selectBestStatisticalReturnSearchResult,
  (bestStatisticalReturnsSearchResult) => bestStatisticalReturnsSearchResult.statisticalReturns,
);

// Upcoming statistical returns search

export const selectUpcomingStatisticalReturnSearch = createSelector(
  selectStock,
  (stockState) => stockState.upcomingStatisticalReturnsSearch,
);
export const selectUpcomingStatisticalReturnSearchSearching = createSelector(
  selectUpcomingStatisticalReturnSearch,
  (upcomingStatisticalReturnSearch) => upcomingStatisticalReturnSearch.searching,
);
export const selectUpcomingStatisticalReturnSearchParams = createSelector(
  selectUpcomingStatisticalReturnSearch,
  (upcomingStatisticalReturnSearch) => upcomingStatisticalReturnSearch.searchParams,
);
export const selectUpcomingStatisticalReturnSearchSymbolCode = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.symbolCode,
);
export const selectUpcomingStatisticalReturnBuyDayNumber = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.buyDayNumber,
);
export const selectUpcomingStatisticalReturnSearchExchangeShortNames = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.exchangeShortNames,
);
export const selectUpcomingStatisticalReturnSearchSortOrder = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.sortOrder,
);
export const selectUpcomingStatisticalReturnSearchMinAverageVolume = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minAverageVolume,
);
export const selectUpcomingStatisticalReturnSearchMinAverageVolumeBaseCurrency = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minAverageVolumeBaseCurrency,
);
export const selectUpcomingStatisticalReturnSearchMinDataYears = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minDataYears,
);
export const selectUpcomingStatisticalReturnSearchMaxDuration = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.maxDuration,
);
export const selectUpcomingStatisticalReturnSearchMinLowestYearlyProfit = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minLowestYearlyProfit,
);
export const selectUpcomingStatisticalReturnSearchMinWinRatio = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minWinRatio,
);
export const selectUpcomingStatisticalReturnSearchMinPeriodWinRatios = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minPeriodWinRatios,
);
export const selectUpcomingStatisticalReturnSearchMinAverageMaxRise = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minAverageMaxRise,
);
export const selectUpcomingStatisticalReturnSearchMaxAverageMaxDrop = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.maxAverageMaxDrop,
);
export const selectUpcomingStatisticalReturnSearchMinHighestRise = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minHighestRise,
);
export const selectUpcomingStatisticalReturnSearchMaxHighestDrop = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.maxHighestDrop,
);
export const selectUpcomingStatisticalReturnSearchMinMarketCap = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minMarketCap,
);
export const selectUpcomingStatisticalReturnSearchMinAverageRelativeProfit = createSelector(
  selectUpcomingStatisticalReturnSearchParams,
  (upcomingStatisticalReturnSearchParams) => upcomingStatisticalReturnSearchParams.minAverageRelativeProfit,
);
export const selectUpcomingStatisticalReturnSearchResult = createSelector(
  selectUpcomingStatisticalReturnSearch,
  (upcomingStatisticalReturnSearch) => upcomingStatisticalReturnSearch.searchResult,
);
export const selectUpcomingStatisticalReturnSearchErrorMessage = createSelector(
  selectUpcomingStatisticalReturnSearchResult,
  (upcomingStatisticalReturnsSearchResult) => upcomingStatisticalReturnsSearchResult.errorMessage,
);
export const selectUpcomingStatisticalReturnSearchThereIsMore = createSelector(
  selectUpcomingStatisticalReturnSearchResult,
  (upcomingStatisticalReturnsSearchResult) => upcomingStatisticalReturnsSearchResult.thereIsMore,
);
export const selectUpcomingStatisticalReturnSearchAllFilteredOut = createSelector(
  selectUpcomingStatisticalReturnSearchResult,
  (upcomingStatisticalReturnsSearchResult) => upcomingStatisticalReturnsSearchResult.allFilteredOut,
);
export const selectUpcomingStatisticalReturnSearchResultList = createSelector(
  selectUpcomingStatisticalReturnSearchResult,
  (upcomingStatisticalReturnsSearchResult) => upcomingStatisticalReturnsSearchResult.statisticalReturns,
);

// Cached symbols, exchanges, statisticalReturns, companyProfiles

export const selectCachedSymbols = createSelector(
  selectStock,
  (stockState) => stockState.symbols,
);
export const selectCachedExchanges = createSelector(
  selectStock,
  (stockState) => stockState.exchanges,
);
export const selectCachedStatisticalReturns = createSelector(
  selectStock,
  (stockState) => stockState.statisticalReturns,
);
export const selectCachedDevelopmentSinceBuyDays = createSelector(
  selectStock,
  (stockState) => stockState.developmentSinceBuyDays,
);
export const selectCachedSymbolPriceOnBuyDays = createSelector(
  selectStock,
  (stockState) => stockState.symbolPriceOnBuyDays,
);
export const selectCashedCompanyProfiles = createSelector(
  selectStock,
  (stockState) => stockState.companyProfiles,
);
