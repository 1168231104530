import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Language} from '../../../shared/models/language.interface';
import {LanguageService} from '../../../shared/services/language.service';
import {takeUntil} from 'rxjs/operators';
import {UtilService} from '../../../shared/util.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../../shared/services/settings.service';
import Util from '../../../shared/util';
import Locale from '../../../shared/services/locale';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent extends BaseComponent implements OnInit {

  langs: Language[] = [];
  iconSize = 32;
  currentLang ?: Language;


  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private router: Router,
      private route: ActivatedRoute,
      public languageService: LanguageService,
      private settingsService: SettingsService,
      private utilService: UtilService,
  ) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.langs = this.languageService.languages;

    // Current lang
    const currentLangCode = Locale.getCurrentLang();
    this.currentLang = this.langs.find(lang => lang.code === currentLangCode);

    // Load and set user's lang
    this.user$.subscribe(user => {
      if (!user)
        return;
      const userLangCode = user.settings?.lang;
      this.currentLang = this.langs.find(lang => lang.code === userLangCode);
      this.redirect(this.currentLang?.code);
    });

    this.languageService.changeAndSaveLanguage.pipe(takeUntil(this.destroy$)).subscribe(lang => {
      if (lang)
        this.selectLang(lang);
    });

    this.languageService.onLanguageChanged.pipe(takeUntil(this.destroy$)).subscribe(langCode => {
      if (!langCode)
        return;
      this.currentLang = this.langs.find(lang => lang.code === langCode);
      this.redirect(this.currentLang?.code);
    });
  }

  selectLang(lang: Language) {
    this.currentLang = lang;
    // If a user is logged in, first change their language setting, then redirect
    if (this.user?.uid)
      this.settingsService.setLanguage(lang);
    // If no user is logged in, just redirect
    else
      this.redirect(this.currentLang.code);
  }

  /**
   * Checks, if a redirection is necessary and possible. If so, redirects to the target language
   * @param targetLang code of the language to be redirected to, e.g. 'en'
   */
  private redirect(targetLang?: string) {
    if (!targetLang)
      return;

    const domain = Util.getDomain();
    const path = Util.getPath();
    const currentLang = Locale.getLangFromPath(path);

    if (!currentLang)
      return;

    if (currentLang === targetLang)
      return;

    const targetPath = path.replace(currentLang, targetLang);
    const targetUrl = domain + targetPath;
    this.utilService.redirectExternal(targetUrl);
  }

}
