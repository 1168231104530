<table class="table table-striped table-sm table-responsive-md table-sticky-header">
  <thead>
  <tr>
    <th i18n>Year</th>
    <th i18n i18n-matTooltip
        matTooltip="The relative profit made when the symbol was bought on the buy date and sold on the sell date.">Relative profit
    </th>
    <th i18n i18n-matTooltip matTooltip="For the average profit calculation, the relative profit is truncated at +/- 10 %. We do this to filter out years
        with extreme price gains or losses.">Truncated relative profit
    </th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let year of years">
    <tr *ngIf="statisticalReturn && statisticalReturn.profitByYearMap && statisticalReturn.profitByYearMap[year] as profit">
      <td *ngIf="isYearClickable"><a href="#" target="_blank" (click)="onYearClick(year);$event.preventDefault()">{{year}}</a></td>
      <td *ngIf="!isYearClickable">{{year}}</td>
      <td>
        <app-percentage-view [value]="profit"></app-percentage-view>
      </td>
      <td>
        <app-percentage-view [value]="getTruncatedProfit(profit)"></app-percentage-view>
      </td>
    </tr>
  </ng-container>
  </tbody>
  <tfoot *ngIf="period && statisticalReturn && statisticalReturn.profitByPeriodMap">
  <tr>
    <td i18n>Average</td>
    <td *ngIf="nonTruncatedProfit !== undefined; else emptyCell">
      <app-percentage-view [value]="nonTruncatedProfit"></app-percentage-view>
    </td>
    <ng-template #emptyCell>
      <td>&nbsp;</td>
    </ng-template>
    <td>
      <app-percentage-view [value]="getAveragePeriodProfit()"></app-percentage-view>
    </td>
  </tr>
  </tfoot>
</table>
