import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {StockService} from '../../../shared/services/stock.service';
import {Symbol} from '../../../shared/models/symbol.interface';
import {TitleService} from '../../../shared/services/title.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-symbol-view',
  templateUrl: './symbol-view.component.html',
  styleUrls: ['./symbol-view.component.scss'],
})
export class SymbolViewComponent extends BaseComponent implements OnInit {
  symbolCode?: string;
  symbol?: Symbol;
  private readonly spinnerKeyFetchingSymbol = 'refreshSymbol';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private activatedRoute: ActivatedRoute,
      private stockService: StockService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Stock view`);

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();
          if (this.symbolCode !== params.symbolCode)
            this.resetComponent();
          this.symbolCode = params.symbolCode;
          if (this.symbolCode) {
            this.titleService.setTitle(this.symbolCode);
            this.refreshSymbol(this.symbolCode);
          }
        });
  }

  private refreshSymbol(symbolCode: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingSymbol, $localize`Fetching symbol ${this.symbolCode}`);
    this.stockService.refreshSymbol(symbolCode).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingSymbol);
      if (wrapper.data) {
        this.symbol = wrapper.data;
        this.titleService.setTitle(this.symbol.name);
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  private resetComponent() {
    this.symbol = undefined;
  }

  onSymbolRefreshed(symbol: Symbol) {
    this.symbol = symbol;
  }
}
