import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {
  addDevelopmentSinceBuyDayToCache,
  addStatisticalReturnToCache,
  addSymbolPriceOnBuyDayToCache,
  fetchBestStatisticalReturnsSearchResults,
  fetchMoreBestStatisticalReturnsSearchResults,
  fetchMoreSymbolsSearchResults,
  fetchMoreUpcomingStatisticalReturnsSearchResults,
  fetchSymbolsSearchResults,
  fetchUpcomingStatisticalReturnsSearchResults,
  setBestStatisticalReturnsExchangesFilter,
  setSymbolsSearchParams,
  setSymbolsSearchResults,
  setUpcomingStatisticalReturnsExchangesFilter,
  updateSymbolSearchExchangeShortName,
  updateSymbolsSearchTerm,
} from '../store/stock.actions';
import {selectCachedDevelopmentSinceBuyDays, selectCachedStatisticalReturns, selectCachedSymbolPriceOnBuyDays} from '../store/stock.selectors';
import {takeUntil} from 'rxjs/operators';
import {SymbolsSearchParams} from '../shared/models/symbolsSearchParams.interface';
import {SymbolsSearchResult} from '../shared/models/symbolsSearchResult.interface';
import {environment} from '../../environments/environment';
import {Wrapper} from '../shared/models/wrapper.model';
import {NumberByYearMap, ProfitByPeriodMap, StatisticalReturn} from '../shared/models/statisticalReturn.interface';
import {firestore} from '../app.module';
import {statisticalReturnConverter} from '../shared/services/stock.service';
import firebase from 'firebase';
import {PayloadResultJo} from '../shared/models/results/payloadResultJo.interface';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BackendService} from '../shared/services/backend.service';
import {Mutex} from 'async-mutex';
import Util from '../shared/util';
import {User} from '../shared/models/user.interface';
import {SymbolAndStatisticalReturnUid} from '../shared/models/symbolAndStatisticalReturnUid.interface';
import {UserService} from '../shared/services/user.service';
import {updateUserMerge} from '../auth/store/auth.actions';
import {fetchMoreSimulationSearchResults, fetchSimulationSearchResults} from '../store/simulation.actions';
import {DevelopmentSinceBuyDay} from '../shared/models/developmentSinceBuyDay.interface';
import {UtilService} from '../shared/util.service';
import {AppState} from '../store/app.state';
import {MatDialog} from '@angular/material/dialog';
import {StatisticalReturnViewType} from '../shared/types/statisticalReturnViewType.type';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Injectable({
  providedIn: 'root',
})
export class StatisticalTradingService {

  statisticalReturnMutex = new Mutex();
  developmentSinceBuyDayMutex = new Mutex();
  statisticalReturnsById: Map<string, StatisticalReturn> = new Map<string, StatisticalReturn>();
  developmentSinceBuyDaysById: Map<string, DevelopmentSinceBuyDay> = new Map<string, DevelopmentSinceBuyDay>();
  symbolPriceOnBuyDaysById: Map<string, DevelopmentSinceBuyDay> = new Map<string, DevelopmentSinceBuyDay>();
  destroy$: Subject<null> = new Subject();

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              private backendService: BackendService,
              private router: Router,
              private userService: UserService,
              private utilService: UtilService,
              private matDialog: MatDialog) {
    this.init();
  }

  init(): void {

    this.store.select(selectCachedStatisticalReturns).pipe(takeUntil(this.destroy$)).subscribe(statisticalReturns => {
      this.statisticalReturnsById = new Map<string, StatisticalReturn>();
      statisticalReturns.forEach(statisticalReturn => {
        if (statisticalReturn.cacheId)
          this.statisticalReturnsById.set(statisticalReturn.cacheId, statisticalReturn);
      });
    });

    this.store.select(selectCachedDevelopmentSinceBuyDays).pipe(takeUntil(this.destroy$)).subscribe(developmentSinceBuyDays => {
      this.developmentSinceBuyDaysById = new Map<string, DevelopmentSinceBuyDay>();
      developmentSinceBuyDays.forEach(developmentSinceBuyDay => {
        if (developmentSinceBuyDay.cacheId)
          this.developmentSinceBuyDaysById.set(developmentSinceBuyDay.cacheId, developmentSinceBuyDay);
      });
    });

    this.store.select(selectCachedSymbolPriceOnBuyDays).pipe(takeUntil(this.destroy$)).subscribe(symbolPriceOnBuyDays => {
      this.symbolPriceOnBuyDaysById = new Map<string, DevelopmentSinceBuyDay>();
      symbolPriceOnBuyDays.forEach(symbolPriceOnBuyDay => {
        if (symbolPriceOnBuyDay.cacheId)
          this.symbolPriceOnBuyDaysById.set(symbolPriceOnBuyDay.cacheId, symbolPriceOnBuyDay);
      });
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  /**
   * Navigates to the symbol search page
   */
  navigateToSymbolSearch(): void {
    this.router.navigateByUrl('/statistical-trading/stock-search');
  }

  /**
   * Navigates to the Statistical Simulation search page
   */
  navigateToStatisticalSimulationSearch(): void {
    this.router.navigateByUrl('/statistical-trading/simulations/search');
  }

  /**
   * Updates the searchTerm in the store.
   * @param searchTerm searchTerm to be written into the store
   */
  updateSearchTerm(searchTerm?: string): void {
    this.store.dispatch(updateSymbolsSearchTerm({searchTerm}));
  }

  /**
   * Updates the searchTerm in the store.
   * @param exchangeShortName searchTerm to be written into the store
   */
  updateExchangeShortName(exchangeShortName?: string): void {
    this.store.dispatch(updateSymbolSearchExchangeShortName({exchangeShortName}));
  }

  setSearchParams(searchParams: SymbolsSearchParams): void {
    this.store.dispatch(setSymbolsSearchParams({searchParams}));
  }

  resetSearch(): void {
    const searchParams: SymbolsSearchParams = {searchTerm: '', exchangeShortName: '', start: 0};
    this.setSearchParams(searchParams);
    const searchResult: SymbolsSearchResult = {symbols: [], errorMessage: '', thereIsMore: false, totalCount: undefined};
    this.store.dispatch(setSymbolsSearchResults({searchResult}));
  }

  /**
   * Starts the search using the store.
   */
  fetchSearchResults(): void {
    this.store.dispatch(fetchSymbolsSearchResults());
  }

  /**
   * Fetches more search results for the last search
   */
  fetchMoreSearchResults(): void {
    this.store.dispatch(fetchMoreSymbolsSearchResults());
  }

  fetchBestStatisticalReturns() {
    this.store.dispatch(fetchBestStatisticalReturnsSearchResults());
  }

  fetchMoreBestStatisticalReturns(): void {
    this.store.dispatch(fetchMoreBestStatisticalReturnsSearchResults());
  }

  setBestStatisticalReturnsExchangesFilter(exchangeShortNames: string[]) {
    this.store.dispatch(setBestStatisticalReturnsExchangesFilter({exchangeShortNames}));
  }

  fetchUpcomingStatisticalReturns() {
    this.store.dispatch(fetchUpcomingStatisticalReturnsSearchResults());
  }

  fetchMoreUpcomingStatisticalReturns(): void {
    this.store.dispatch(fetchMoreUpcomingStatisticalReturnsSearchResults());
  }

  setUpcomingStatisticalReturnsExchangesFilter(exchangeShortNames: string[]) {
    this.store.dispatch(setUpcomingStatisticalReturnsExchangesFilter({exchangeShortNames}));
  }

  fetchStatisticalSimulationResults(): void {
    this.store.dispatch(fetchSimulationSearchResults());
  }

  fetchMoreStatisticalSimulationResults(): void {
    this.store.dispatch(fetchMoreSimulationSearchResults());
  }

  async fetchStatisticalReturns(symbolCode: string, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadStatisticalReturnsCount): Promise<Wrapper<StatisticalReturn[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionSymbols).doc(symbolCode).collection(environment.firestoreCollectionStatisticalReturns)
        .orderBy('averageRelativeProfit', 'desc')
        .withConverter(statisticalReturnConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const statisticalReturnQuerySnapshot = await query.get();
      const statisticalReturns: StatisticalReturn[] = [];
      statisticalReturnQuerySnapshot.forEach(statisticalReturnDocSnapshot => {
        const statisticalReturn: StatisticalReturn | undefined = statisticalReturnDocSnapshot.data();
        if (statisticalReturn) {
          const statisticalReturnWithId = {...statisticalReturn, uid: statisticalReturnDocSnapshot.id};
          statisticalReturns.push(statisticalReturnWithId);
        }
      });
      const lastVisible = statisticalReturnQuerySnapshot.docs[statisticalReturnQuerySnapshot.docs.length - 1];

      return new Wrapper<StatisticalReturn[]>(statisticalReturns, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<StatisticalReturn[]>(undefined, $localize`You are not allowed to view these statistical returns.`);
      return new Wrapper<StatisticalReturn[]>(undefined, e.message);
    }
  }

  async analyzeStatisticalReturns(symbolCode: string): Promise<Wrapper<StatisticalReturn[]>> {
    try {
      const response = await this.httpClient.post<PayloadResultJo<StatisticalReturn[]>>(environment.backend_api_path + environment.backend_api_request_analyze + '/' + symbolCode,
        null, {params: this.backendService.apiSecretKeyParam}).toPromise();
      return new Wrapper(response.payload, response.message, undefined, response.isSuccess);
    } catch (error) {
      if (error instanceof HttpErrorResponse)
        return new Wrapper<StatisticalReturn[]>(undefined, error.error.message);
      return new Wrapper<StatisticalReturn[]>(undefined, error.message);
    }
  }

  async fetchStatisticalReturn(symbolCode: string, uid: string, maxAgeInSec: number = environment.defaultStatisticalReturnCacheAgeInSec): Promise<Wrapper<StatisticalReturn>> {
    return this.statisticalReturnMutex.runExclusive(async () => {
      const cacheId = StatisticalTradingService.determineStatisticalReturnCacheId(symbolCode, uid);
      const statisticalReturnFromCache = cacheId ? this.statisticalReturnsById.get(cacheId) : undefined;
      if (statisticalReturnFromCache !== undefined && this.isStatisticalReturnUpToDate(statisticalReturnFromCache, maxAgeInSec))
        return new Wrapper<StatisticalReturn>(statisticalReturnFromCache, undefined, undefined, true);
      try {
        const statisticalReturnDocSnapshot = await firestore.collection(environment.firestoreCollectionSymbols).doc(symbolCode)
          .collection(environment.firestoreCollectionStatisticalReturns).doc(uid).withConverter(statisticalReturnConverter).get();
        const statisticalReturn = statisticalReturnDocSnapshot.data();
        if (statisticalReturn) {
          const statisticalReturnWithIdAndCacheDate: StatisticalReturn = {
            ...statisticalReturn, uid: statisticalReturnDocSnapshot.id,
            cacheDate: new Date(), cacheId,
          };
          this.addStatisticalReturnToCache(statisticalReturnWithIdAndCacheDate);
          return new Wrapper<StatisticalReturn>(statisticalReturnWithIdAndCacheDate, undefined, undefined, true);
        }
      } catch (e: any) {
        return new Wrapper<StatisticalReturn>(undefined, e.message, undefined, true);
      }

      // If no statisticalReturn was found
      return new Wrapper<StatisticalReturn>(undefined);
    });
  }

  private addStatisticalReturnToCache(statisticalReturn: StatisticalReturn) {
    this.statisticalReturnsById.set(statisticalReturn.cacheId!, statisticalReturn);
    this.store.dispatch(addStatisticalReturnToCache({statisticalReturn}));
  }

  static determineStatisticalReturnCacheId(symbolCode?: string, statisticalReturnUid?: string) {
    if (!symbolCode)
      return undefined;
    if (!statisticalReturnUid)
      return undefined;
    return `${symbolCode}_${statisticalReturnUid}`;
  }

  static determineSymbolAndStatisticalReturnUid(statisticalReturnCacheId?: string): SymbolAndStatisticalReturnUid | undefined {
    if (!statisticalReturnCacheId)
      return undefined;
    let parts = statisticalReturnCacheId.split('_');
    if (parts.length === 3)
      return {symbolCode: parts[0], statisticalReturnUid: parts[1] + '_' + parts[2]};
    return undefined;
  }

  /**
   * Checks, if the given statisticalReturn is newer then the given max age.
   * @param statisticalReturn statistical return
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isStatisticalReturnUpToDate(statisticalReturn: StatisticalReturn, maxAgeInSec: any): boolean {
    if (!statisticalReturn.cacheDate)
      return false;
    const cacheTime = statisticalReturn.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  async fetchSymbolPriceDevelopmentSinceBuyDay(symbolCode: string, buyDayNumber: number, maxAgeInSec: number = environment.defaultDevelopmentSinceBuyDayCacheAgeInSec): Promise<Wrapper<DevelopmentSinceBuyDay>> {
    return this.developmentSinceBuyDayMutex.runExclusive(async () => {
      const cacheId = StatisticalTradingService.determineDevelopmentSinceBuyDayCacheId(symbolCode, buyDayNumber);
      const developmentSinceBuyDayFromCache = cacheId ? this.developmentSinceBuyDaysById.get(cacheId) : undefined;
      if (developmentSinceBuyDayFromCache !== undefined && this.isDevelopmentSinceBuyDayUpToDate(developmentSinceBuyDayFromCache, maxAgeInSec))
        return new Wrapper<DevelopmentSinceBuyDay>(developmentSinceBuyDayFromCache);
      try {
        let requestUrl = environment.backend_api_request_symbol + '/' + symbolCode + '/' + environment.backend_api_request_development_since_buy_day;

        const year = Util.getLatestYearForDayNumber(buyDayNumber);
        const buyDate = Util.getDateFromDayNumber(buyDayNumber, year);
        let httpParams = this.backendService.getApiPublicKeyParam();
        if (!httpParams)
          throw Error('Backend-Api-Public-Key is missing.');
        httpParams = httpParams.set('buyDateUtc', buyDate.getTime());
        const payloadResult: PayloadResultJo<DevelopmentSinceBuyDay> = await this.httpClient.post<PayloadResultJo<DevelopmentSinceBuyDay>>(environment.backend_api_path + requestUrl,
          null, {params: httpParams}).toPromise();
        if (!payloadResult.isSuccess)
          return new Wrapper<DevelopmentSinceBuyDay>(undefined, payloadResult.message);
        let developmentSinceBuyDay = payloadResult.payload;
        if (developmentSinceBuyDay) {
          developmentSinceBuyDay = {...developmentSinceBuyDay, cacheId, cacheDate: new Date()};
          this.addDevelopmentSinceBuyDayToCache(developmentSinceBuyDay);
          return new Wrapper<DevelopmentSinceBuyDay>(developmentSinceBuyDay);
        }

      } catch (e: any) {
        return new Wrapper<DevelopmentSinceBuyDay>(undefined, e.message);
      }

      // If no developmentSinceBuyDay was found
      return new Wrapper<DevelopmentSinceBuyDay>(undefined);
    });
  }

  async fetchSymbolPriceOnBuyDay(symbolCode: string, buyDayNumber: number, maxAgeInSec: number = environment.defaultSymbolPriceOnBuyDayCacheAgeInSec): Promise<Wrapper<DevelopmentSinceBuyDay>> {
    const cacheId = StatisticalTradingService.determineDevelopmentSinceBuyDayCacheId(symbolCode, buyDayNumber);
    const symbolPriceOnBuyDayFromCache = cacheId ? this.symbolPriceOnBuyDaysById.get(cacheId) : undefined;
    if (symbolPriceOnBuyDayFromCache !== undefined && this.isDevelopmentSinceBuyDayUpToDate(symbolPriceOnBuyDayFromCache, maxAgeInSec))
      return new Wrapper<DevelopmentSinceBuyDay>(symbolPriceOnBuyDayFromCache, undefined, undefined, true);
    try {
      let requestUrl = environment.backend_api_request_symbol + '/' + symbolCode + '/' + environment.backend_api_request_price_on_day;

      const httpParams: HttpParams = this.getHttpParamsDevelopmentOrPriceBuyDay(buyDayNumber);
      const payloadResult: PayloadResultJo<DevelopmentSinceBuyDay> = await this.httpClient.post<PayloadResultJo<DevelopmentSinceBuyDay>>(environment.backend_api_path + requestUrl,
        null, {params: httpParams}).toPromise();
      if (!payloadResult.isSuccess)
        return new Wrapper<DevelopmentSinceBuyDay>(undefined, payloadResult.message, undefined, payloadResult.isSuccess);
      let symbolPriceOnBuyDay = payloadResult.payload;
      if (symbolPriceOnBuyDay) {
        const developmentSinceBuyDay: DevelopmentSinceBuyDay = {...symbolPriceOnBuyDay, cacheId, cacheDate: new Date()};
        this.addSymbolPriceOnBuyDayToCache(developmentSinceBuyDay);
        return new Wrapper<DevelopmentSinceBuyDay>(developmentSinceBuyDay, undefined, undefined, payloadResult.isSuccess);
      }
      // If no symbolPriceOnBuyDay was found
      return new Wrapper<DevelopmentSinceBuyDay>(undefined, undefined, undefined, true);
    } catch (e: any) {
      return new Wrapper<DevelopmentSinceBuyDay>(undefined, e.message);
    }
  }

  async fetchSymbolPricesOnBuyDay(symbolCodes: string[], buyDayNumber: number, maxAgeInSec: number = environment.defaultSymbolPriceOnBuyDayCacheAgeInSec): Promise<Wrapper<DevelopmentSinceBuyDay[]>> {
    return this.developmentSinceBuyDayMutex.runExclusive(async () => {
      // Check, which symbols prices are already in the cache
      let missingSymbolCodes: string[] = [];
      let symbolPricesOnBuyDayFromCache: DevelopmentSinceBuyDay[] = [];
      symbolCodes?.forEach(symbolCode => {
        const cacheId = StatisticalTradingService.determineDevelopmentSinceBuyDayCacheId(symbolCode, buyDayNumber);
        const symbolPriceOnBuyDayFromCache = this.symbolPriceOnBuyDaysById.get(cacheId);
        if (symbolPriceOnBuyDayFromCache !== undefined && this.isDevelopmentSinceBuyDayUpToDate(symbolPriceOnBuyDayFromCache, maxAgeInSec))
          symbolPricesOnBuyDayFromCache.push(symbolPriceOnBuyDayFromCache);
        else if (!Util.arrayContains(missingSymbolCodes, symbolCode))
          missingSymbolCodes.push(symbolCode);
      });
      // If all symbols are in the cache, return them
      if (missingSymbolCodes.length === 0)
        return new Wrapper<DevelopmentSinceBuyDay[]>(symbolPricesOnBuyDayFromCache);

      try {
        let requestUrl = environment.backend_api_request_prices_on_day + '/' + missingSymbolCodes;

        const httpParams: HttpParams = this.getHttpParamsDevelopmentOrPriceBuyDay(buyDayNumber);
        let loadedSymbolPricesOnBuyDay: DevelopmentSinceBuyDay[] = [];
        const payloadResult: PayloadResultJo<DevelopmentSinceBuyDay[]> = await this.httpClient.post<PayloadResultJo<DevelopmentSinceBuyDay[]>>(environment.backend_api_path + requestUrl,
          null, {params: httpParams}).toPromise();
        if (!payloadResult.isSuccess)
          return new Wrapper<DevelopmentSinceBuyDay[]>(undefined, payloadResult.message, undefined, payloadResult.isSuccess);
        let symbolPricesOnBuyDay = payloadResult.payload;
        if (symbolPricesOnBuyDay) {
          symbolPricesOnBuyDay.forEach(it => {
            if (it.symbolCode) {
              const cacheId = StatisticalTradingService.determineDevelopmentSinceBuyDayCacheId(it.symbolCode, buyDayNumber);
              const developmentSinceBuyDay: DevelopmentSinceBuyDay = {...it, cacheId, cacheDate: new Date()};
              this.addSymbolPriceOnBuyDayToCache(developmentSinceBuyDay);
              loadedSymbolPricesOnBuyDay.push(developmentSinceBuyDay);
            }
          });
          const allSymbolPrices = [...symbolPricesOnBuyDayFromCache, ...loadedSymbolPricesOnBuyDay];
          return new Wrapper<DevelopmentSinceBuyDay[]>(allSymbolPrices, undefined, undefined, payloadResult.isSuccess);
        }
        // If no symbolPricesOnBuyDay were found
        return new Wrapper<DevelopmentSinceBuyDay[]>(symbolPricesOnBuyDayFromCache, undefined, undefined, true);
      } catch (e: any) {
        return new Wrapper<DevelopmentSinceBuyDay[]>(symbolPricesOnBuyDayFromCache, e.message);
      }
    });
  }

  private getHttpParamsDevelopmentOrPriceBuyDay(buyDayNumber: number): HttpParams {
    const year = Util.getLatestYearForDayNumber(buyDayNumber);
    const buyDate = Util.getDateFromDayNumber(buyDayNumber, year);
    const buyDateString = this.utilService.formatDateDefault(buyDate);
    let httpParams = this.backendService.getApiPublicKeyParam();
    if (!httpParams)
      throw Error('Backend-Api-Public-Key is missing.');
    return httpParams.set('buyDate', buyDateString);
  }

  static determineDevelopmentSinceBuyDayCacheId(symbolCode: string, buyDayNumber: number) {
    return `${symbolCode}_${buyDayNumber}`;
  }

  /**
   * Checks, if the given developmentSinceBuyDay is newer then the given max age.
   * @param developmentSinceBuyDay statistical return
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isDevelopmentSinceBuyDayUpToDate(developmentSinceBuyDay: DevelopmentSinceBuyDay, maxAgeInSec: any): boolean {
    if (!developmentSinceBuyDay.cacheDate)
      return false;
    const cacheTime = developmentSinceBuyDay.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addDevelopmentSinceBuyDayToCache(developmentSinceBuyDay: DevelopmentSinceBuyDay) {
    if (developmentSinceBuyDay.cacheId)
      this.developmentSinceBuyDaysById.set(developmentSinceBuyDay.cacheId, developmentSinceBuyDay);
    this.store.dispatch(addDevelopmentSinceBuyDayToCache({developmentSinceBuyDay}));
  }

  private addSymbolPriceOnBuyDayToCache(developmentSinceBuyDay: DevelopmentSinceBuyDay) {
    if (developmentSinceBuyDay.cacheId)
      this.symbolPriceOnBuyDaysById.set(developmentSinceBuyDay.cacheId, developmentSinceBuyDay);
    this.store.dispatch(addSymbolPriceOnBuyDayToCache({developmentSinceBuyDay}));
  }

  public static determineYears(statisticalReturns: StatisticalReturn[] | undefined, reverse = false): string[] {
    const allYears: string[] = [];
    statisticalReturns?.forEach(statisticalReturn => {
      const years = statisticalReturn.profitByYearMap !== undefined ? this.getYears(statisticalReturn.profitByYearMap) : [];
      Util.addUniquely(years, allYears);
    });

    return Util.sortNumerically(allYears, reverse);
  }

  public static determineLatestYear(statisticalReturns: StatisticalReturn[] | undefined): number | undefined {
    const allYears = StatisticalTradingService.determineYears(statisticalReturns, true);
    if (!allYears || allYears.length === 0)
      return undefined;
    return Number(allYears[0]);
  }

  public static determinePeriods(statisticalReturns: StatisticalReturn[] | undefined): string[] {
    const allYears: string[] = [];
    statisticalReturns?.forEach(statisticalReturn => {
      const years = statisticalReturn.profitByPeriodMap !== undefined ? this.getPeriods(statisticalReturn.profitByPeriodMap) : [];
      Util.addUniquely(years, allYears);
    });

    return Util.sortNumerically(allYears);
  }

  public static getPeriods(profitByPeriodMap: ProfitByPeriodMap): string[] {
    return Object.keys(profitByPeriodMap);
  }

  public static getYears(profitByYearMap: NumberByYearMap): string[] {
    return Object.keys(profitByYearMap);
  }

  public static getFormattedDate(buyDay?: number, buyMonth?: number): string {
    if (buyDay && buyMonth)
      return Util.getFormattedDateDayMonth(buyDay, buyMonth);
    return $localize`Unknown date`;
  }

  public static calculateNonTruncatedProfit(statisticalReturn: StatisticalReturn, years: string[]): number | undefined {
    let sum = 0;
    let count = 0;
    for (let year of years) {
      if (statisticalReturn?.profitByYearMap && statisticalReturn?.profitByYearMap[year] !== undefined) {
        sum += statisticalReturn?.profitByYearMap[year];
        count++;
      }
    }
    if (count === 0)
      return undefined;
    else
      return sum / count;
  }

  doesUserWatchItem(user: User, symbolCode: string | undefined, statisticalReturnUid: string | undefined, statisticalSimulationUid: string | undefined): boolean {
    if (symbolCode && statisticalReturnUid)
      return this.doesUserWatchStatisticalReturn(user, symbolCode!, statisticalReturnUid!);
    if (symbolCode && !statisticalReturnUid)
      return this.doesUserWatchSymbol(user, symbolCode!);
    if (statisticalSimulationUid)
      return this.doesUserStatisticalSimulation(user, statisticalSimulationUid);
    return false;
  }

  private doesUserWatchStatisticalReturn(user: User, symbolCode: string, statisticalReturnUid: string): boolean {
    if (user.watchedStatisticalReturns === undefined)
      return false;
    const symbolAndStatisticalReturnUid = user.watchedStatisticalReturns.find(it => it.symbolCode == symbolCode && it.statisticalReturnUid == statisticalReturnUid);
    return symbolAndStatisticalReturnUid !== undefined;
  }

  private doesUserWatchSymbol(user: User, symbolCode: string) {
    if (user.watchedSymbols === undefined)
      return false;
    const foundSymbolCode = user.watchedSymbols.find(it => it === symbolCode);
    return foundSymbolCode !== undefined;
  }

  private doesUserStatisticalSimulation(user: User, simulationUid: string) {
    if (user.watchedStatisticalSimulation === undefined)
      return false;
    const foundSimulationUid = user.watchedStatisticalSimulation.find(it => it === simulationUid);
    return foundSimulationUid !== undefined;
  }

  /**
   * Adds the given item to the watch list of the given user.
   * @param user
   * @param symbolCode
   * @param statisticalReturnUid
   * @param statisticalSimulationUid
   * @return The new status, whether the item is now on the watch list or not. True, if it is, false if it isn't.
   */
  addToWatchList(user: User, symbolCode: string | undefined, statisticalReturnUid: string | undefined, statisticalSimulationUid: string | undefined): boolean {
    if (symbolCode && statisticalReturnUid)
      return this.addStatisticalReturnToWatchList(user, symbolCode!, statisticalReturnUid!);
    if (symbolCode && !statisticalReturnUid)
      return this.addSymbolToWatchList(user, symbolCode!);
    if (statisticalSimulationUid)
      return this.addStatisticalSimulationToWatchList(user, statisticalSimulationUid);
    return true;
  }

  private addStatisticalReturnToWatchList(user: User, symbolCode: string, statisticalReturnUid: string): boolean {
    user = {...user};
    user.watchedStatisticalReturns = !user.watchedStatisticalReturns ? [] : [...user.watchedStatisticalReturns];
    const symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid = {symbolCode, statisticalReturnUid};
    user.watchedStatisticalReturns.push(symbolAndStatisticalReturnUid);
    const userUpdate: User = {uid: user.uid, watchedStatisticalReturns: user.watchedStatisticalReturns};
    this.store.dispatch(updateUserMerge({userUpdate}));
    return true;
  }

  private addSymbolToWatchList(user: User, symbolCode: string): boolean {
    user = {...user};
    user.watchedSymbols = !user.watchedSymbols ? [] : [...user.watchedSymbols];
    user.watchedSymbols.push(symbolCode);
    const userUpdate: User = {uid: user.uid, watchedSymbols: user.watchedSymbols};
    this.store.dispatch(updateUserMerge({userUpdate}));
    return true;
  }

  private addStatisticalSimulationToWatchList(user: User, statisticalSimulationUid: string): boolean {
    user = {...user};
    user.watchedStatisticalSimulation = !user.watchedStatisticalSimulation ? [] : [...user.watchedStatisticalSimulation];
    user.watchedStatisticalSimulation.push(statisticalSimulationUid);
    const userUpdate: User = {uid: user.uid, watchedStatisticalSimulation: user.watchedStatisticalSimulation};
    this.store.dispatch(updateUserMerge({userUpdate}));
    return true;
  }

  /**
   * Removes the given item from the watch list of the given user.
   * @param user
   * @param symbolCode
   * @param statisticalReturnUid
   * @param statisticalSimulationUid
   * @return The new status, whether the item is now on the watch list or not. True, if it is, false if it isn't.
   */
  removeFromWatchList(user: User, symbolCode: string | undefined, statisticalReturnUid: string | undefined, statisticalSimulationUid: string | undefined): boolean {
    if (symbolCode && statisticalReturnUid)
      return this.removeStatisticalReturnFromWatchList(user, symbolCode!, statisticalReturnUid!);
    if (symbolCode && !statisticalReturnUid)
      return this.removeSymbolFromWatchList(user, symbolCode!);
    if (statisticalSimulationUid)
      return this.removeStatisticalSimulationFromWatchList(user, statisticalSimulationUid!);
    return true;
  }

  private removeStatisticalReturnFromWatchList(user: User, symbolCode: string, statisticalReturnUid: string): boolean {
    if (!user.watchedStatisticalReturns)
      return false;
    const symbolAndStatisticalReturnUid = user.watchedStatisticalReturns.find(it => it.symbolCode == symbolCode && it.statisticalReturnUid == statisticalReturnUid);
    let watchedStatisticalReturns = [...user.watchedStatisticalReturns];
    Util.removeFromArray(watchedStatisticalReturns, symbolAndStatisticalReturnUid);
    const userUpdate: User = {uid: user.uid, watchedStatisticalReturns};
    this.store.dispatch(updateUserMerge({userUpdate}));
    return false;
  }

  private removeSymbolFromWatchList(user: User, symbolCode: string): boolean {
    if (!user.watchedSymbols)
      return false;
    const foundSymbolCode = user.watchedSymbols.find(it => it == symbolCode);
    let watchedSymbols = [...user.watchedSymbols];
    Util.removeFromArray(watchedSymbols, foundSymbolCode);
    const userUpdate: User = {uid: user.uid, watchedSymbols};
    this.store.dispatch(updateUserMerge({userUpdate}));
    return false;
  }

  private removeStatisticalSimulationFromWatchList(user: User, statisticalSimulationUid: string): boolean {
    if (!user.watchedStatisticalSimulation)
      return false;
    const foundStatisticalSimulationUid = user.watchedStatisticalSimulation.find(it => it == statisticalSimulationUid);
    let watchedStatisticalSimulation = [...user.watchedStatisticalSimulation];
    Util.removeFromArray(watchedStatisticalSimulation, foundStatisticalSimulationUid);
    const userUpdate: User = {uid: user.uid, watchedStatisticalSimulation};
    this.store.dispatch(updateUserMerge({userUpdate}));
    return false;
  }

  static isBuyDayAfterSellDay(statRet: StatisticalReturn) {
    const buyDayNumber = Util.calculateDayNumber(statRet.buyMonth, statRet.buyDay);
    const sellDayNumber = Util.calculateDayNumber(statRet.sellMonth, statRet.sellDay);
    return buyDayNumber > sellDayNumber;
  }

  /**
   * Calculates the win ratio for a given period based on statistical data.
   *
   * @param {StatisticalReturn} statRet - The statistical data to analyze.
   * @param {string} period - The period for which to calculate the win ratio.
   * @returns {(number|null)} - The win ratio for the given period, or null if no data is available.
   *
   * @description
   * This method computes the win ratio, defined as the proportion of profitable outcomes
   * among all outcomes, for a specified period. It first identifies the relevant years
   * within the period and then calculates the ratio of winning profits (positive values)
   * to the total number of profits.
   */
  static getWinRatio(statRet: StatisticalReturn, period: string) {
    const years = this.getYearsForPeriod(statRet, period);
    const relevantRelativeProfits = this.getRelativeProfits(statRet, years);
    let profitsCountAll = relevantRelativeProfits.length;
    if (profitsCountAll === 0)
      return null;
    let profitsCountWinning = relevantRelativeProfits.filter(it => it > 0).length;
    return profitsCountWinning / profitsCountAll;
  }

  /**
   * Determines the years relevant to a given period from statistical data.
   *
   * @param {StatisticalReturn} statRet - The statistical data containing yearly profits.
   * @param {string} periodString - A string representing the period to analyze.
   * @returns {string[]} - An array of strings representing the years within the specified period.
   *
   * @description
   * This method extracts years from the statistical data relevant to the specified period.
   * It sorts the years and then slices the array to return only the years pertaining
   * to the given period. If the period is longer than the available data, all years are returned.
   */
  static getYearsForPeriod(statRet: StatisticalReturn, periodString: string): string[] {
    if (!statRet.profitByYearMap)
      return [];
    const allYears = StatisticalTradingService.getYears(statRet.profitByYearMap).sort();
    const period = Number(periodString);
    if (period > allYears.length)
      return allYears;
    return allYears.slice(allYears.length - period, allYears.length);
  }

  /**
   * Extracts the relative profits for specified years from statistical data.
   *
   * @param {StatisticalReturn} statRet - The statistical data containing profits by year.
   * @param {string[]} years - An array of years for which to calculate relative profits.
   * @returns {number[]} - An array of numbers representing the relative profits for the specified years.
   *
   * @description
   * This method calculates the relative profits for each year specified in the input array.
   * It iterates through the years, extracting the profit for each year from the statistical data.
   * The method returns an array of these profits.
   */
  static getRelativeProfits(statRet: StatisticalReturn, years: string[]) {
    const relativeProfits: number[] = [];
    if (!statRet.profitByYearMap)
      return relativeProfits;
    years.forEach(it => {
      if (statRet.profitByYearMap && statRet.profitByYearMap[it])
        relativeProfits.push(statRet.profitByYearMap!![it]);
    });
    return relativeProfits;
  }

  /**
   * Opens a dialog with the specified component and configuration.
   *
   * @param {any} component - The component to be loaded inside the dialog.
   * @param {Object} data - Data to be passed to the dialog component.
   * @param {number} [dialogWidth=400] - Width of the dialog in pixels.
   * @param {number} [dialogHeight=600] - Height of the dialog in pixels.
   * @param {MouseEvent} [mouseEvent] - The mouse event, if the dialog is to be opened at the mouse pointer.
   * @returns The reference to the opened dialog.
   */
  openDialog(component: any, data: {}, dialogWidth = 400, dialogHeight = 600, mouseEvent?: MouseEvent) {
    let dialogConfig;
    if (mouseEvent)
      dialogConfig = Util.getMatDialogConfigOpenAtPointer(dialogWidth, dialogHeight, mouseEvent);
    else
      dialogConfig = Util.getMatDialogConfigOpenCentered(dialogWidth, dialogHeight);
    dialogConfig.data = data;
    return this.matDialog.open(component, dialogConfig);
  }

  /**
   * Opens an edit filter dialog with the specified component, title, and optional parameters.
   *
   * @param {any} component - The component to be used in the dialog.
   * @param {string} dialogTitle - Title of the dialog.
   * @param {StatisticalReturnViewType} [viewType] - The type of statistical view to be used, if applicable.
   * @param {MouseEvent} [mouseEvent] - The mouse event, if the dialog is to be opened at the mouse pointer.
   * @param {number} [dialogHeight=600] - Height of the dialog in pixels.
   * @param {number} [dialogWidth=400] - Width of the dialog in pixels.
   * @returns The reference to the opened dialog.
   */
  openEditFilterDialog(component: any, dialogTitle: string, viewType?: StatisticalReturnViewType, mouseEvent?: MouseEvent, dialogHeight = 600, dialogWidth = 400) {
    const data = {
      dialogTitle: dialogTitle,  // Set the title
      showCloseButton: true,    // Set the visibility of the close button
      viewType: viewType,
    };
    return this.openDialog(component, data, dialogWidth, dialogHeight, mouseEvent);
  }
}
