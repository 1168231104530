import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Country} from '../../../shared/models/country.interface';
import {CountryService} from '../../../shared/services/country.service';
import {TitleService} from '../../../shared/services/title.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address} from '../../../shared/models/address.interface';
import {Coordinates} from '../../../shared/models/coordinates.interface';
import {take, takeUntil} from 'rxjs/operators';
import Util from '../../../shared/util';
import {GeoService} from '../../../shared/services/geo.service';
import {MapsAPILoader} from '@agm/core';
import {User} from '../../../shared/models/user.interface';
import {Gender} from '../../../shared/enums/gender.enum';
import {UtilService} from '../../../shared/util.service';
import {FunctionsService} from '../../../shared/services/functions.service';
import Locale from '../../../shared/services/locale';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {resetUpdateUserState, updateUserMerge} from '../../../auth/store/auth.actions';
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderStatus = google.maps.GeocoderStatus;

const HUNDRED_FIFTY_YEARS_IN_MS = 4733640000000;


@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.css'],
})
export class MasterDataComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() embedded = false;
  firstNameMaxLength = 50;
  lastNameMaxLength = 50;
  phoneNumberMaxLength = 20;
  streetMaxLength = 45;
  houseNumberMaxLength = 9;
  zipCodeMaxLength = 10;
  cityMaxLength = 85;
  countries: Country[] = [];
  form!: FormGroup;
  maxBirthday = this.getDate18YearsAgo();
  minBirthday = new Date(new Date().getTime() - HUNDRED_FIFTY_YEARS_IN_MS);
  @Input() saveSubject = new Subject();
  @Input() showSaveButton = true;
  // Map
  mapLat: number = 0;
  mapLng: number = 0;
  address: Address | undefined;
  /**
   * A hint shown under the address bar form field
   */
  addressHint?: string;
  private readonly spinnerKeyUpdateUserData = 'updatingUserData';
  @ViewChild('addressBar')
  private addressElementRef: ElementRef | undefined;
  private addressAutocomplete: google.maps.places.Autocomplete | undefined;


  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private geoService: GeoService,
      private mapsAPILoader: MapsAPILoader,
      private zone: NgZone,
      private titleService: TitleService,
      private formBuilder: FormBuilder,
      private functionsService: FunctionsService,
      private utilService: UtilService,
      private countryService: CountryService) {
    super(authService, userService, store);
  }

  public get gender(): typeof Gender {
    return Gender;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.saveSubject)
      this.saveSubject.pipe(takeUntil(this.destroy$)).subscribe(value => this.save());

    if (!this.embedded)
      this.titleService.setTitle($localize`My master data`);

    this.countries = this.countryService.countries;
    this.form = this.createForm();

  }

  ngAfterViewInit() {
    // Initialize map
    this.user$.subscribe(user => {
      if (!user)
        return;

      // We have a user and listing. That's all we need.
      this.initializeAddressBarAutoComplete();
      this.determineInitialMapCoordsAndAddress();

      this.fillCoreData(user);
    });
  }

  /**
   * Called, when a new location from the map is received
   * @param latLng new map location
   */
  onMapLocationChanged(coords: Coordinates) {
    this.mapLat = coords.lat;
    this.mapLng = coords.lng;
  }

  onMapGeocoderResultChanged(address: Address) {
    this.address = address;

    this.form.patchValue({
      address: address.formattedAddress,
    });
  }

  save() {
    this.clearAlerts();

    if (!this.user) {
      this.addError($localize`We could not find your user account. Please log in.`);
      return;
    }
    if (!this.form.valid) {
      this.addError($localize`Please fill all required fields.`);
      this.form.markAllAsTouched();
      return;
    }

    const firstName = this.form.value.firstName;
    const lastName = this.form.value.lastName;
    const birthdate = this.form.value.birthdate;
    const gender = this.form.value.gender;
    const telephoneNumber = this.form.value.telephoneNumber;
    const nationalityCountryCode = this.form.value.nationalityCountryCode;

    this.user = {
      ...this.user,
      uid: this.user.uid,
      address: this.address,
      firstName,
      lastName,
      birthdate,
      gender,
      telephoneNumber,
      nationalityCountryCode,
    };
    const userUpdate: User = {
      uid: this.user.uid,
      address: this.address,
      firstName,
      lastName,
      birthdate,
      gender,
      telephoneNumber,
      nationalityCountryCode,
    };

    this.addLoadingSpinnerMessage(this.spinnerKeyUpdateUserData, $localize`Saving your master data...`);

    this.store.dispatch(updateUserMerge({userUpdate}));

    this.updateUserActionResult$.pipe(take(1)).subscribe(result => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyUpdateUserData);
      this.store.dispatch(resetUpdateUserState());
      if (result.success) {
        console.log('Successfully saved the master data.');
        this.addSuccess($localize`Successfully saved your master data.`);
      }
      if (result.errorMessage)
        this.addError($localize`Error saving the master data: ${result.errorMessage}`);
    });

  }

  /**
   * Initializes the address bar auto complete. Note: This must not be done too early. At the time of calling this function, the address bar must be visible on
   * the DOM. This is not the case, if firebaseAuthUser is false or fetchListing is true.
   */
  private initializeAddressBarAutoComplete(): void {
    if (this.addressAutocomplete !== undefined)
        // Nothing to do here
      return;

    const addressElement = this.addressElementRef?.nativeElement;
    if (addressElement === undefined)
      return;

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      this.addressAutocomplete = new google.maps.places.Autocomplete(addressElement);
      this.addressAutocomplete.addListener('place_changed', () => {
        this.zone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult | undefined = this.addressAutocomplete?.getPlace();

          //verify result
          if (place?.geometry === undefined || place?.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          const coords = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          this.mapLat = coords.lat;
          this.mapLng = coords.lng;
          if (place.address_components) {
            this.address = {
              ...this.geoService.convertGeocoderAddressComponents(place.address_components),
              formattedAddress: place.formatted_address,
              coords,
            };

            this.form.patchValue({
              address: this.address.formattedAddress,
            });
          }

        });
      });
    });
  }

  /**
   * Determines initial map coordinates and address.
   * First, tries to use the coords and address saved in the listing (if available).
   * Then, tries to load the data from the user, who this listing belongs to.
   * If there are none, loads the user's current location.
   */
  private determineInitialMapCoordsAndAddress(): void {

    this.user$.subscribe(user => {
      if (!user)
        return;

      // Try to load the data from the user, who this listing belongs to.
      if (this.fillInAddressAndCoords(user.address))
          // If successful, we're done
        return;

      // Load the user's current location
      this.geoService.getCurrentLocation(
          (position) => {
            this.mapLat = position.coords.latitude;
            this.mapLng = position.coords.longitude;

            // Also determine the address based on those coordinates
            this.geoService.getAddress(this.mapLat, this.mapLng, (results: GeocoderResult[], status: GeocoderStatus) => {
              if (results && results[0]) {
                const address: Address = {
                  ...this.geoService.convertGeocoderAddressComponents(results[0].address_components),
                  formattedAddress: results[0].formatted_address, coords: {lat: position.coords.latitude, lng: position.coords.longitude},
                };
                this.fillInAddressAndCoords(address);
                this.addressHint = $localize`We tried to determine your address. Please check, if it is correct.`;
              }
            });
          },
          positionError => {
            if (positionError.code === 1)
              this.addressHint = $localize`We could not determine your current location, because you didn't give us permission to access it.`;
            else {
              this.addressHint = $localize`We could not determine your current location.`;
              console.log(`We could not determine your current location. ${positionError.message}`);
            }
            // Fill in default coords
            this.mapLat = environment.defaultLatitude;
            this.mapLng = environment.defaultLongitude;
          },
      );
    });

  }

  /**
   * Fills in the given address and coords, if they are complete (not counting the house number). Fill in means:
   * 1. Fill empty address fields in the form with the given data (omitting non-empty fields)
   * 2. Set this.mapLat and this.mapLng with the coords from this address
   * @param address address to be filled in
   * @return true, if successful, false otherwise. True means, we're done.
   */
  private fillInAddressAndCoords(address: Address | undefined): boolean {
    this.address = address;
    if (address?.coords) {
      const coords = address.coords;
      this.mapLat = coords?.lat ? coords?.lat : 0;
      this.mapLng = coords?.lng ? coords?.lng : 0;
    }

    if (!this.form)
      return false;
    // If address and coords are complete (not counting house number, since it's optional)
    if (address?.city && address?.countryCode && address?.zipCode && address?.street) {
      // Fill the address into the availability form (only empty fields)
      this.patchAddressForm(address);
      return true;
    }
    return false;
  }

  /**
   * Patches the values of the address form with the given newAddress, but only currently empty fields. If a field already contains a value, it is not changed.
   * @param newAddress new address, which should replace the contents of empty fields
   */
  private patchAddressForm(newAddress: Address) {
    // Read filled in values from the form
    const address = Util.valueOrNull(this.form?.value.address);
    const street = Util.valueOrNull(this.form?.value.street);
    const houseNumber = Util.valueOrNull(this.form?.value.houseNumber);
    const zipCode = Util.valueOrNull(this.form?.value.zipCode);
    const city = Util.valueOrNull(this.form?.value.city);
    const countryCode = Util.valueOrNull(this.form?.value.countryCode);

    // Replace empty fields with the contents of newAddress
    this.form.patchValue({
      address: !address && newAddress.formattedAddress ? newAddress.formattedAddress : address,
      houseNumber: !houseNumber && newAddress.houseNumber ? newAddress.houseNumber : houseNumber,
      street: !street && newAddress.street ? newAddress.street : street,
      zipCode: !zipCode && newAddress.zipCode ? newAddress.zipCode : zipCode,
      city: !city && newAddress.city ? newAddress.city : city,
      countryCode: !countryCode && newAddress.countryCode ? newAddress.countryCode : countryCode,
    });
  }

  private createForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      telephoneNumber: [null, [Validators.required,
        Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]],
      birthdate: [null, Validators.required],
      gender: [null],
      nationalityCountryCode: [Locale.defaultNationalityCountryCode(), Validators.required],
      address: [null, Validators.required],
      showPhoneNumberAfterBooking: [null],
      street: [null],
      houseNumber: [],
      zipCode: [null, Validators.maxLength(this.zipCodeMaxLength)],
      city: [null],
      countryCode: [null],

    });

  }

  private fillCoreData(user: User) {
    if (user.firstName)
      this.form.patchValue({firstName: user.firstName});
    if (user.lastName)
      this.form.patchValue({lastName: user.lastName});
    if (user.birthdate)
      this.form.patchValue({birthdate: this.utilService.getDate(user.birthdate)});
    if (user.gender)
      this.form.patchValue({gender: user.gender});
    if (user.telephoneNumber)
      this.form.patchValue({telephoneNumber: user.telephoneNumber});
    if (user.nationalityCountryCode)
      this.form.patchValue({nationalityCountryCode: user.nationalityCountryCode});
    if (user.settings?.showPhoneNumberAfterBooking)
      this.form.patchValue({showPhoneNumberAfterBooking: user.settings.showPhoneNumberAfterBooking});
  }

  private getDate18YearsAgo() {
    let date = new Date();
    return new Date(date.setFullYear(date.getFullYear() - 18));
  }
}
