import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {AccountComponent} from './account.component';
import {AccountRoutingModule} from './account-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AccountOverviewComponent} from './account-overview/account-overview.component';
import {SettingsComponent} from './settings/settings.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {NotificationComponent} from './notifications-dialog/notification/notification.component';
import {NotificationsDialogComponent} from './notifications-dialog/notifications-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {NameEmailComponent} from './personal-info/name-email/name-email.component';
import {NgxAuthFirebaseUIModule} from 'ngx-auth-firebaseui';
import {ImageComponent} from './personal-info/image/image.component';
import {StarRatingModule} from 'angular-star-rating';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MasterDataComponent} from './personal-info/master-data/master-data.component';
import {AngularIbanModule} from 'angular-iban';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import {LayoutModule} from '../layout/layout.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {WatchListComponent} from './watch-list/watch-list.component';
import {StatisticalTradingModule} from '../statistical-trading/statistical-trading.module';
import {TableColumnsComponent} from './table-columns/table-columns.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DragDropColumnsListComponent } from './table-columns/drag-drop-columns-list/drag-drop-columns-list.component';


@NgModule({
  declarations: [
    AccountComponent,
    AccountOverviewComponent,
    SettingsComponent,
    NotificationComponent,
    NotificationsDialogComponent,
    MasterDataComponent,
    NameEmailComponent,
    ImageComponent,
    PersonalInfoComponent,
    WatchListComponent,
    TableColumnsComponent,
    DragDropColumnsListComponent,
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule,
    MatTabsModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    NgxAuthFirebaseUIModule,
    StarRatingModule,
    MatCardModule,
    MatCheckboxModule,
    // https://www.npmjs.com/package/angular-iban
    AngularIbanModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatStepperModule,
    LayoutModule,
    MatTooltipModule,
    StatisticalTradingModule,
    DragDropModule,
  ],
  providers: [CurrencyPipe],
  exports: [],
})
export class AccountModule {
}
