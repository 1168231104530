import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {Wrapper} from '../models/wrapper.model';
import {environment} from '../../../environments/environment';
import {firestore} from '../../app.module';
import {Mutex} from 'async-mutex';
import {takeUntil} from 'rxjs/operators';
import {Symbol} from '../models/symbol.interface';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {
  convertToStatisticalReturn,
  convertToStatisticalSimulationResult,
  convertToStatisticalTradingPlan,
  convertToSymbol,
} from '../converters/modelConverters';
import {StatisticalReturn} from '../models/statisticalReturn.interface';
import {addCompanyProfileToCache, addSymbolToCache} from '../../store/stock.actions';
import {PayloadResultJo} from '../models/results/payloadResultJo.interface';
import {BackendService} from './backend.service';
import {HttpClient} from '@angular/common/http';
import Util, {memoize} from '../../shared/util';
import {CompanyProfile} from '../models/companyProfile.interface';
import moment from 'moment/moment';
import {selectCachedSymbols, selectCashedCompanyProfiles} from '../../store/stock.selectors';
import {StatisticalSimulationResult} from '../models/statisticalSimulationResult.interface';
import {selectCachedStatisticalSimulationResults} from '../../store/simulation.selectors';
import {addSimulationResultToCache} from '../../store/simulation.actions';
import {StatisticalTradingPlan} from '../models/statisticalTradingPlan.interface';
import {AppState} from '../../store/app.state';


@Injectable({
  providedIn: 'root',
})
export class StockService {

  destroy$: Subject<null> = new Subject();
  stockMutex = new Mutex();
  symbolsById: Map<string, Symbol> = new Map<string, Symbol>();
  statisticalSimulationResultsById: Map<string, StatisticalSimulationResult> = new Map<string, StatisticalSimulationResult>();
  companyProfilesById: Map<string, CompanyProfile> = new Map<string, CompanyProfile>();

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              private backendService: BackendService) {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {
    this.store.select(selectCachedSymbols).pipe(takeUntil(this.destroy$)).subscribe(symbols => {
      if (symbols) {
        this.symbolsById = new Map<string, Symbol>();
        symbols.forEach(symbol => {
          if (symbol.code)
            this.symbolsById.set(symbol.code, symbol);
        });
      }
    });
    this.store.select(selectCashedCompanyProfiles).pipe(takeUntil(this.destroy$)).subscribe(companyProfiles => {
      if (companyProfiles) {
        this.companyProfilesById = new Map<string, CompanyProfile>();
        companyProfiles.forEach(companyProfile => {
          if (companyProfile.symbol)
            this.companyProfilesById.set(companyProfile.symbol, companyProfile);
        });
      }
    });
    this.store.select(selectCachedStatisticalSimulationResults).pipe(takeUntil(this.destroy$)).subscribe(simulationResults => {
      if (simulationResults) {
        this.statisticalSimulationResultsById = new Map<string, StatisticalSimulationResult>();
        simulationResults.forEach(simulationResult => {
          if (simulationResult.uid)
            this.statisticalSimulationResultsById.set(simulationResult.uid, simulationResult);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  async fetchSymbolCodeList(): Promise<Wrapper<String[]>> {
    try {
      return new Wrapper<String[]>(undefined, 'Not yet implemented');
    } catch (e: any) {
      return new Wrapper<String[]>(undefined, e.message);
    }
  }


  /**
   * Fetches the symbol with the given symbolCode from the firestore database.
   */
  async fetchSymbolFromFirestore(symbolCode: string, maxAgeInSec: number = environment.defaultSymbolCacheAgeInSec): Promise<Wrapper<Symbol>> {
    return this.stockMutex.runExclusive(async () => {
      const symbolFromCache = this.symbolsById.get(symbolCode);
      if (symbolFromCache !== undefined && this.isSymbolUpToDate(symbolFromCache, maxAgeInSec))
        return new Wrapper<Symbol>(symbolFromCache);

      try {
        const symbolDocSnapshot = await firestore.collection(environment.firestoreCollectionSymbols).doc(symbolCode).withConverter(symbolConverter).get();
        let symbol = symbolDocSnapshot.data();
        if (symbol) {
          symbol = {...symbol, code: symbolDocSnapshot.id, cacheDate: new Date()};
          this.addSymbolToCache(symbol);
          return new Wrapper<Symbol>(symbol);
        }
      } catch (e: any) {
        return new Wrapper<Symbol>(undefined, e.message);
      }

      // If no symbol was found
      return new Wrapper<Symbol>(undefined);
    });
  }

  async refreshSymbol(symbolCode: string, maxAgeInSec: number = environment.defaultSymbolCacheAgeInSec): Promise<Wrapper<Symbol>> {
    let requestUrl = environment.backend_api_request_refresh_symbol + '/' + symbolCode;
    return this.loadSymbolFromBackendHelper(symbolCode, maxAgeInSec, requestUrl, true);
  }


  async fetchSymbolFromBackend(symbolCode: string, maxAgeInSec: number = environment.defaultSymbolCacheAgeInSec): Promise<Wrapper<Symbol>> {
    let requestUrl = environment.backend_api_request_symbol + '/' + symbolCode;
    return this.loadSymbolFromBackendHelper(symbolCode, maxAgeInSec, requestUrl, false);
  }

  private loadSymbolFromBackendHelper(symbolCode: string, maxAgeInSec: number, requestUrl: string, addToCache = true) {
    return this.stockMutex.runExclusive(async () => {
      const symbolFromCache = this.symbolsById.get(symbolCode);
      if (symbolFromCache !== undefined && this.isSymbolUpToDate(symbolFromCache, maxAgeInSec))
        return new Wrapper<Symbol>(symbolFromCache);

      let httpParams = this.backendService.getApiPublicKeyParam();
      if (!httpParams)
        throw Error('Backend-Api-Public-Key is missing.');

      try {
        const payloadResult: PayloadResultJo<Symbol> = await this.httpClient.post<PayloadResultJo<Symbol>>(environment.backend_api_path + requestUrl,
            null, {params: httpParams}).toPromise();
        if (!payloadResult.isSuccess)
          return new Wrapper<Symbol>(undefined, payloadResult.message);
        let symbol = payloadResult.payload;
        if (symbol) {
          symbol = {...symbol, code: symbolCode, cacheDate: new Date()};
          if (addToCache)
            this.addSymbolToCache(symbol);
          return new Wrapper<Symbol>(symbol);
        }
        // If no symbol was found
        return new Wrapper<Symbol>(undefined);
      } catch (e: any) {
        return new Wrapper<Symbol>(undefined, e.message);
      }
    });
  }

  refreshSymbols(symbolCodes: string[] | undefined, maxAgeInSec: number = environment.defaultSymbolCacheAgeInSec): Promise<Wrapper<Symbol[]>> {
    return this.stockMutex.runExclusive(async () => {
      // Check, which symbols are already in the cache
      let missingSymbolCodes: string[] = [];
      let symbolsFromCache: Symbol[] = [];
      symbolCodes?.forEach(symbolCode => {
        const symbolFromCache = this.symbolsById.get(symbolCode);
        if (symbolFromCache !== undefined && this.isSymbolUpToDate(symbolFromCache, maxAgeInSec))
          symbolsFromCache.push(symbolFromCache);
        else if (!Util.arrayContains(missingSymbolCodes, symbolCode))
          missingSymbolCodes.push(symbolCode);
      });
      // If all symbols are in the cache, return them
      if (missingSymbolCodes.length === 0)
        return new Wrapper<Symbol[]>(symbolsFromCache);

      // Load the missing symbols from the backend
      let httpParams = this.backendService.getApiPublicKeyParam();
      if (!httpParams)
        throw Error('Backend-Api-Public-Key is missing.');
      let loadedSymbols: Symbol[] = [];

      try {
        let requestUrl = environment.backend_api_request_refresh_symbols + '/' + missingSymbolCodes;
        const payloadResult: PayloadResultJo<Symbol[]> = await this.httpClient.post<PayloadResultJo<Symbol[]>>(environment.backend_api_path + requestUrl,
            null, {params: httpParams}).toPromise();
        if (!payloadResult.isSuccess)
          return new Wrapper<Symbol[]>(symbolsFromCache, payloadResult.message);
        let symbols: Symbol[] = payloadResult.payload;
        if (symbols) {
          symbols.forEach(it => {
            const symbol: Symbol = {...it, cacheDate: new Date()};
            this.addSymbolToCache(symbol);
            loadedSymbols.push(symbol);
          });
          return new Wrapper<Symbol[]>([...symbolsFromCache, ...loadedSymbols]);
        }
        // If no symbols were found
        return new Wrapper<Symbol[]>(symbolsFromCache);
      } catch (e: any) {
        return new Wrapper<Symbol[]>(symbolsFromCache, e.message);
      }
    });
  }

  async fetchCompanyProfileFromBackend(symbolCode: string, maxAgeInSec: number = environment.defaultSymbolCacheAgeInSec): Promise<Wrapper<CompanyProfile>> {
    return this.stockMutex.runExclusive(async () => {
      const companyProfileFromCache = this.companyProfilesById.get(symbolCode);
      if (companyProfileFromCache !== undefined && this.isCompanyProfileUpToDate(companyProfileFromCache, maxAgeInSec))
        return new Wrapper<CompanyProfile>(companyProfileFromCache);

      let httpParams = this.backendService.getApiPublicKeyParam();
      if (!httpParams)
        throw Error('Backend-Api-Public-Key is missing.');

      try {
        const payloadResult: PayloadResultJo<CompanyProfile> = await this.httpClient.post<PayloadResultJo<CompanyProfile>>(environment.backend_api_path + environment.backend_api_request_company_profile + '/' + symbolCode,
            null, {params: httpParams}).toPromise();
        if (!payloadResult.isSuccess)
          return new Wrapper<CompanyProfile>(undefined, payloadResult.message);
        let companyProfile = payloadResult.payload;
        if (companyProfile) {
          companyProfile = {...companyProfile, symbol: symbolCode, cacheDate: new Date()};
          this.addCompanyProfileToCache(companyProfile);
          return new Wrapper<CompanyProfile>(companyProfile);
        }
      } catch (e: any) {
        return new Wrapper<CompanyProfile>(undefined, e.message);
      }

      // If no companyProfile was found
      return new Wrapper<CompanyProfile>(undefined);
    });
  }

  @memoize()
  public async getSymbolName(symbolCode: string): Promise<string> {
    let cachedSymbol = this.symbolsById.get(symbolCode);
    if (cachedSymbol?.name)
      return new Promise((resolve) => {
        resolve(cachedSymbol!.name);
      });
    let wrapper = await this.fetchSymbolFromBackend(symbolCode);
    if (wrapper.data)
      return wrapper.data.name;
    return new Promise((resolve) => {
      resolve($localize`Unknown symbol`);
    });
  }

  /**
   * Checks, if the given symbol is newer then the given max age.
   * @param symbol symbol  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isSymbolUpToDate(symbol: Symbol, maxAgeInSec: any): boolean {
    if (!symbol.cacheDate)
      return false;
    const cacheTime = symbol.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  /**
   * Checks, if the given companyProfile is newer then the given max age.
   * @param companyProfile companyProfile  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isCompanyProfileUpToDate(companyProfile: CompanyProfile, maxAgeInSec: any): boolean {
    if (!companyProfile.cacheDate)
      return false;
    const cacheTime = companyProfile.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  /**
   * Checks, if the given simulationResult is newer then the given max age.
   * @param simulationResult simulationResult  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isSimulationResultUpToDate(simulationResult: StatisticalSimulationResult, maxAgeInSec: any): boolean {
    if (!simulationResult.cacheDate)
      return false;
    const cacheTime = simulationResult.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addSymbolToCache(symbol: Symbol) {
    if (symbol.code)
      this.symbolsById.set(symbol.code, symbol);
    this.store.dispatch(addSymbolToCache({symbol}));
  }

  private addStatisticalSimulationResultToCache(simulationResult: StatisticalSimulationResult) {
    if (simulationResult.uid)
      this.statisticalSimulationResultsById.set(simulationResult.uid, simulationResult);
    this.store.dispatch(addSimulationResultToCache({simulationResult}));
  }

  private addCompanyProfileToCache(companyProfile: CompanyProfile) {
    if (companyProfile.symbol)
      this.companyProfilesById.set(companyProfile.symbol, companyProfile);
    this.store.dispatch(addCompanyProfileToCache({companyProfile}));
  }

  /**
   * Fetches the StatisticalSimulationResult with the given simulationUid from the firestore database.
   */
  async fetchStatisticalSimulationResult(simulationUid: string, maxAgeInSec: number = environment.defaultSimulationResultCacheAgeInSec): Promise<Wrapper<StatisticalSimulationResult>> {
    return this.stockMutex.runExclusive(async () => {
      const simulationResultFromCache = this.statisticalSimulationResultsById.get(simulationUid);
      if (simulationResultFromCache !== undefined && this.isSimulationResultUpToDate(simulationResultFromCache, maxAgeInSec))
        return new Wrapper<StatisticalSimulationResult>(simulationResultFromCache);

      try {
        const simulationResultDocSnapshot = await firestore.collection(environment.firestoreCollectionStatisticalSimulationResults).doc(simulationUid).withConverter(simulationResultConverter).get();
        let simulationResult = simulationResultDocSnapshot.data();
        if (simulationResult) {
          simulationResult = {...simulationResult, uid: simulationResultDocSnapshot.id, cacheDate: new Date()};
          this.addStatisticalSimulationResultToCache(simulationResult);
          return new Wrapper<StatisticalSimulationResult>(simulationResult);
        }
      } catch (e: any) {
        return new Wrapper<StatisticalSimulationResult>(undefined, e.message);
      }

      // If no simulationResult was found
      return new Wrapper<StatisticalSimulationResult>(undefined);
    });
  }

}

export function determineBuyAndSellDates(year: string, buyMonth: number, sellMonth: number, buyDay: number, sellDay: number) {
  let buyYear = +year;
  let sellYear = buyYear;
  if (buyMonth > sellMonth)
    sellYear++;
  let buyDate = new Date(buyYear, buyMonth - 1, buyDay);
  let sellDate = new Date(sellYear, sellMonth - 1, sellDay);
  let buyMoment = moment(buyDate);
  let sellMoment = moment(sellDate);
  return {buyDate, sellDate, buyMoment, sellMoment};
}

// Firestore data converter

export const symbolConverter = {
  toFirestore(symbol: Symbol): Symbol {
    return symbol;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Symbol {
    return convertToSymbol(snapshot.data(options));
  },
};

export const statisticalReturnConverter = {
  toFirestore(statisticalReturn: StatisticalReturn): StatisticalReturn {
    return statisticalReturn;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): StatisticalReturn {
    return convertToStatisticalReturn(snapshot.data(options));
  },
};

export const simulationResultConverter = {
  toFirestore(simulationResult: StatisticalSimulationResult): StatisticalSimulationResult {
    return simulationResult;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): StatisticalSimulationResult {
    return convertToStatisticalSimulationResult(snapshot.data(options));
  },
};

export const statisticalTradingPlanConverter = {
  toFirestore(statisticalTradingPlan: StatisticalTradingPlan): StatisticalTradingPlan {
    return statisticalTradingPlan;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): StatisticalTradingPlan {
    return convertToStatisticalTradingPlan(snapshot.data(options), snapshot.id);
  },
};
