import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Subject} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../shared/services/title.service';
import {StockService} from '../../shared/services/stock.service';
import {SettingsService} from '../../shared/services/settings.service';
import {takeUntil} from 'rxjs/operators';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {User} from '../../shared/models/user.interface';
import {ColumnList, TableColumn, tableColumnsStatisticalReturn, TableId} from '../../shared/models/columnSettings.interface';
import {TableColumnService} from '../table-column.service';

@Component({
  selector: 'app-table-columns',
  templateUrl: './table-columns.component.html',
  styleUrls: ['./table-columns.component.scss'],
})
export class TableColumnsComponent extends BaseComponent implements OnInit {

  // Tab management
  selectedIndex = 0;
  private tabTypes: TableId[] = ['statistical-returns'];

  stopUserListener$: Subject<null> = new Subject();

  tableColumnsStatisticalReturns: TableColumn[] = [];

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private titleService: TitleService,
      private stockService: StockService,
      private settingsService: SettingsService,
      private tableColumnService: TableColumnService) {
    super(authService, userService, store);
  }

  public get tableColumnsStatisticalReturn(): typeof tableColumnsStatisticalReturn {
    return tableColumnsStatisticalReturn;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Table columns`);

    // Retrieve type from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();

          const type = params.type;
          if (type)
            this.switchToTab(type);
          else {
            this.switchToTab(this.tabTypes[0]);
            this.router.navigate(['account', 'table-columns', this.tabTypes[0]]);
          }
        });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
  }

  onTabSwitchByClick(matTabEvent: MatTabChangeEvent) {
    let tabIndex = matTabEvent.index;
    this.router.navigate(['account', 'table-columns', this.tabTypes[0]]);
  }

  private switchToTab(type: TableId) {
    let selectedIndex = this.tabTypes.indexOf(type);
    this.selectedIndex = selectedIndex;
    this.clearAlerts();
  }


  private onUserLoaded(user?: User) {
    this.tableColumnsStatisticalReturns = this.tableColumnService.getTableColumns(user, 'statistical-returns');
  }

  onSaveColumns(visibleColumns: TableColumn[], tableId: TableId) {
    const columnList = this.convertToColumnList(visibleColumns);
    this.settingsService.setTableColumnSetting(columnList, tableId);
  }

  private convertToColumnList(visibleColumns: TableColumn[]): ColumnList {
    const visibleColumnIds: string[] = visibleColumns.map(it => it.id);
    return {visibleColumnIds};
  }

}
