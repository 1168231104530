<a (click)="onNotificationClicked()" [routerLink]="notification?.actionUrl ? notification?.actionUrl : ''" class="no-underline">
  <div *ngIf="notification" [class]="notification.read ? 'read' : 'unread'">
    <div class="row m-0 p-1 notification-box">

      <div class="col mx-1 p-0">

        <p [title]="notification.creationDate.toDate() | moment:'LLLL'"
           class="date">{{utilService.createFromNowString(notification.creationDate.toDate())}} </p>
      </div>

    </div>
  </div>
</a>
