import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {StockService} from '../../shared/services/stock.service';
import {DateByYearMap, StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {UtilService} from '../../shared/util.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-statistical-return-detail-table',
  templateUrl: './statistical-return-detail-table.component.html',
  styleUrls: ['./statistical-return-detail-table.component.scss'],
})
export class StatisticalReturnDetailTableComponent extends BaseComponent implements OnInit {

  @Input() statRet?: StatisticalReturn;
  relativeProfitTruncatePercentage = environment.relativeProfitTruncateThreshold * 100;
  truncatedProfitTooltip = $localize`We truncate the annual profit at +/- ${this.relativeProfitTruncatePercentage} %. This means an annual profit of over ${this.relativeProfitTruncatePercentage} % is treated as ${this.relativeProfitTruncatePercentage} % for the average profit calculation, while an annual loss of more than ${this.relativeProfitTruncatePercentage} % is treated as ${this.relativeProfitTruncatePercentage} %. We do this to filter out years with extreme price gains or losses. Otherwise a single year with a very high profit could pull up the average, even though the stock performed poorly in all other years.`;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public utilService: UtilService,
      private stockService: StockService) {
    super(authService, userService, store);
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  getKeys(dateByYearMap: DateByYearMap) {
    return Object.keys(dateByYearMap).sort();
  }
}
