import {Component, Input, OnInit} from '@angular/core';
import firebase from 'firebase';
import {UtilService} from '../../util.service';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-date-view',
  templateUrl: './date-view.component.html',
  styleUrls: ['./date-view.component.scss'],
})
export class DateViewComponent implements OnInit {

  @Input() date?: Date | Timestamp;
  @Input() mode: 'full-date-and-time' | 'compact-date-and-time' | 'compact-date-only' | 'relative-only' = 'full-date-and-time';
  @Input() showRelativeDate = false;

  constructor(public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

}
