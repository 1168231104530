import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {StatisticalTradeStep} from '../../../../shared/models/statisticalTradeStep.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StatisticalTradingPlan} from '../../../../shared/models/statisticalTradingPlan.interface';

@Component({
  selector: 'app-statistical-trading-plan-trade-edit-modal',
  templateUrl: './statistical-trading-plan-trade-edit-modal.component.html',
  styleUrls: ['./statistical-trading-plan-trade-edit-modal.component.scss'],
})
export class StatisticalTradingPlanTradeEditModalComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  @Input() plan?: StatisticalTradingPlan;
  @Input() step?: StatisticalTradeStep;

  @Output() onSuccessfulEdit = new EventEmitter<StatisticalTradingPlan>();
  @Output() onEditFailed = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }

  editFailed(errorMessage: string) {
    this.onEditFailed.emit(errorMessage);
  }

  successfulEdit(resultingPlan: StatisticalTradingPlan) {
    this.onSuccessfulEdit.emit(resultingPlan);
    this.activeModal.dismiss('Saving successful');
  }
}
