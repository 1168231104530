import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UtilService} from '../../../../shared/util.service';
import {TitleService} from '../../../../shared/services/title.service';
import {PlannerService} from '../../planner.service';
import {StatisticalTradingPlan} from '../../../../shared/models/statisticalTradingPlan.interface';
import {StatisticalTradeDeletionResult} from '../../../../shared/models/statisticalTradeDeletionResult.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StatisticalTradingPlanTradeEditModalComponent} from '../statistical-trading-plan-trade-edit-modal/statistical-trading-plan-trade-edit-modal.component';
import {StatisticalTradeStep} from '../../../../shared/models/statisticalTradeStep.interface';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-statistical-trading-plan-trade-action-menu',
  templateUrl: './statistical-trading-plan-trade-action-menu.component.html',
  styleUrls: ['./statistical-trading-plan-trade-action-menu.component.scss'],
})
export class StatisticalTradingPlanTradeActionMenuComponent extends BaseComponent implements OnInit {

  @Input() plan?: StatisticalTradingPlan;
  @Input() step?: StatisticalTradeStep;
  @Output() onSuccessfulEdit = new EventEmitter<StatisticalTradingPlan>();
  @Output() onEditFailed = new EventEmitter<string>();
  @Output() onSuccessfulDeletion = new EventEmitter<StatisticalTradeDeletionResult>();
  @Output() onDeletionFailed = new EventEmitter<string>();
  @Input() floatTopRight = false;

  private readonly spinnerKeyDeleteTrade = 'deletePlan';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private utilService: UtilService,
      private titleService: TitleService,
      private modalService: NgbModal,
      private plannerService: PlannerService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  editTradeClicked(): void {
    this.clearAlerts();
    if (!this.plan?.uid) {
      this.addError($localize`Edit failed. The plan ID is unknown.`);
      return;
    }
    const modalRef = this.modalService.open(StatisticalTradingPlanTradeEditModalComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.step = this.step;
    modalRef.componentInstance.plan = this.plan;
    // Subscribe to the output event emitters
    modalRef.componentInstance.onSuccessfulEdit.pipe(takeUntil(this.destroy$)).subscribe((editedPlan: StatisticalTradingPlan) => {
      this.onSuccessfulEdit.emit(editedPlan);
    });

    modalRef.componentInstance.onEditFailed.pipe(takeUntil(this.destroy$)).subscribe((errorMessage: string) => {
      this.onEditFailed.emit(errorMessage);
    });
  }

  deleteTradeClicked(): void {
    this.clearAlerts();
    if (!this.plan?.uid) {
      this.addError($localize`Deletion failed. The plan ID is unknown.`);
      return;
    }
    const title = $localize`Are you sure you want to delete this trade from the plan '${this.plan.name}'?`;
    this.utilService.showConfirmDialog(title, $localize`You can add it again any time.`, this.deleteTrade.bind(this), [], undefined, undefined, undefined, 'yes');
  }

  deleteTrade() {
    if (!this.step?.statisticalReturnUid || !this.plan?.uid)
      return;
    this.clearAlerts();
    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteTrade, $localize`Deleting trade from plan...`);
    this.plannerService.deleteStatisticalReturnFromPlan(this.step.statisticalReturnUid, this.plan.uid, this.user.uid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteTrade);
      if (wrapper.success)
        this.onSuccessfulDeletion.emit(wrapper.data);
      if (wrapper.errorMessage)
        this.onDeletionFailed.emit(wrapper.errorMessage);
    });
  }
}
