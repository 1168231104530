import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {AuthService} from '../../auth/auth.service';
import {TitleService} from '../../shared/services/title.service';
import {UserService} from '../../shared/services/user.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {NotificationSettings, UserSettings, ViewMode} from '../../shared/models/userSettings.interface';
import {take} from 'rxjs/operators';
import {User} from '../../shared/models/user.interface';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {LanguageService} from '../../shared/services/language.service';
import {TimeZoneService} from '../../shared/services/time-zone.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {resetUpdateUserState, updateUserMerge} from '../../auth/store/auth.actions';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent extends BaseComponent implements OnInit {

  emailNotifications: NotificationSettings = {
    mangoPayDocumentTypeAddress: true,
    mangoPayDocumentTypeIdentity: true,
    message: true,
    paymentTransferred: true,
    paymentRefunded: true,
  };

  listingsViewMode: ViewMode = 'grid';
  langCode?: string;
  timeZone?: string;
  allNotificationsChecked: boolean = true;
  someNotificationsChecked: boolean = false;

  constructor(authService: AuthService,
              userService: UserService,
              store: Store<AppState>,
              public languageService: LanguageService,
              private sharedService: SharedService,
              private titleService: TitleService,
              private timeZoneService: TimeZoneService,
  ) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Settings`);

    this.user$.subscribe(user => {
      if (user)
        this.onUserLoaded(user);
    });

  }

  onSaveNotificationSettings(): void {
    this.clearAlerts();

    if (!this.user) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    this.enableLoadingSpinner($localize`Saving email notification settings...`);

    const settings: UserSettings = this.user?.settings ? {...this.user.settings} : {};
    settings.emailNotifications = this.emailNotifications;
    this.saveSettings(settings);
  }

  onSaveWebsiteSettings(): void {
    this.clearAlerts();

    if (!this.user) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    if (!this.langCode) {
      this.addError($localize`Please select a language.`);
      return;
    }
    if (!this.timeZone) {
      this.addError($localize`Please select a time zone.`);
      return;
    }
    this.enableLoadingSpinner($localize`Saving website settings...`);
    const settings: UserSettings = this.user?.settings ? {...this.user.settings} : {};
    settings.listingsViewMode = this.listingsViewMode;
    settings.lang = this.langCode;
    settings.timeZone = this.timeZone;
    this.saveSettings(settings);
  }

  onTabSwitch($event: MatTabChangeEvent) {
    this.clearAlerts();
  }

  /**
   * Enables or disables all notification sliders
   * @param checked true, to enable them all. false, to disable them all.
   */
  toggleAllNotifications(checked: boolean) {
    this.emailNotifications.mangoPayDocumentTypeAddress = checked;
    this.emailNotifications.mangoPayDocumentTypeIdentity = checked;
    this.emailNotifications.message = checked;
    this.emailNotifications.paymentTransferred = checked;
    this.emailNotifications.paymentRefunded = checked;
  }

  updateNotificationsMetaCheckbox(): void {
    const checkboxesCount = [...Object.values(this.emailNotifications)].length;
    const checkedCheckboxesCount = [...Object.values(this.emailNotifications)].filter(notificationValue => notificationValue === true).length;
    this.allNotificationsChecked = checkboxesCount === checkedCheckboxesCount;
    this.someNotificationsChecked = checkedCheckboxesCount > 0 && !this.allNotificationsChecked;
    console.log(`checkboxesCount: ${checkboxesCount}, checkedCheckboxesCount: ${checkedCheckboxesCount}, this.allNotificationsChecked: ${this.allNotificationsChecked}, this.someNotificationsChecked: ${this.someNotificationsChecked}`, this.emailNotifications);

  }

  public getTimeZones() {
    return this.timeZoneService.getOrderedTimeZones();
  }

  /**
   * Saves the given settings and updates the corresponding user object in the firestore.
   * @param settings settings to be written
   */
  private saveSettings(settings: UserSettings) {
    const userUpdate = {uid: this.user!.uid, settings};
    this.store.dispatch(updateUserMerge({userUpdate}));

    this.updateUserActionResult$.pipe(take(1)).subscribe(result => {
      this.disableLoadingSpinner();
      this.store.dispatch(resetUpdateUserState());
      if (result.success) {
        this.emailNotifications = {...this.user?.settings?.emailNotifications};
        this.addSuccess($localize`Saving successful.`);
        // Notify about a possibly changed language. (If it has not been changed, nothing will happen)
        if (this.user?.settings?.lang)
          this.languageService.onLanguageChanged.next(this.user.settings.lang);
      }
      if (result.errorMessage)
        this.addError($localize`Error saving settings.` + result.errorMessage);
    });

  }

  private onUserLoaded(user: User) {
    // Website settings
    this.langCode = user.settings?.lang;
    this.timeZone = user.settings?.timeZone;
    if (user.settings?.listingsViewMode !== undefined)
      this.listingsViewMode = user.settings.listingsViewMode;

    // Email notification settings
    if (user.settings?.emailNotifications?.mangoPayDocumentTypeAddress !== undefined)
      this.emailNotifications.mangoPayDocumentTypeAddress = user.settings.emailNotifications.mangoPayDocumentTypeAddress;
    if (user.settings?.emailNotifications?.mangoPayDocumentTypeIdentity !== undefined)
      this.emailNotifications.mangoPayDocumentTypeIdentity = user.settings.emailNotifications.mangoPayDocumentTypeIdentity;
    if (user.settings?.emailNotifications?.message !== undefined)
      this.emailNotifications.message = user.settings.emailNotifications.message;
    if (user.settings?.emailNotifications?.paymentTransferred !== undefined)
      this.emailNotifications.paymentTransferred = user.settings.emailNotifications.paymentTransferred;
    if (user.settings?.emailNotifications?.paymentRefunded !== undefined)
      this.emailNotifications.paymentRefunded = user.settings.emailNotifications.paymentRefunded;
    this.updateNotificationsMetaCheckbox();
  }
}
