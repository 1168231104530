<form (ngSubmit)="save()" *ngIf="schedule||new" [formGroup]="form">
  <h1 *ngIf="new" class="paragraph-headline" i18n="new schedule">New schedule</h1>
  <h1 *ngIf="!new && schedule" class="paragraph-headline ellipsis" i18n="edit schedule">Edit schedule <i>{{schedule?.name}}</i></h1>
  <div class="row mb-3">
    <div class="col">
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
    </div>
  </div>
  <div class="row">
    <div class="col col-lg-4">
      <mat-slide-toggle formControlName="enabled" i18n class="mb-3">
        Enabled
      </mat-slide-toggle>
      <br>

      <mat-form-field class="mat-form-input">
        <mat-label i18n="analysisType">Analysis type</mat-label>
        <mat-select formControlName="type" id="type" panelClass="mat-select-panel-500px">
          <mat-option [value]="null" i18n>Please choose the analysis type</mat-option>
          <div class="dropdown-divider"></div>
          <mat-option [value]="analysisType.STATISTICAL_RETURN" i18n>Statistical returns</mat-option>
          <mat-option [value]="analysisType.DIVIDEND" i18n>Dividends</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mat-form-input text-input-100">
        <mat-label i18n="uid">ID</mat-label>
        <input #uidRef formControlName="uid"
               i18n-placeholder matInput maxlength="{{uidMaxLength}}" placeholder="Schedule ID"
               required tabindex="0">
        <span class="character-counter">{{uidRef.value.length}}/{{uidMaxLength}}</span>
      </mat-form-field>
      <mat-form-field class="mat-form-input text-input-100">
        <mat-label i18n="name">Name</mat-label>
        <input #nameRef formControlName="name"
               i18n-placeholder matInput maxlength="{{nameMaxLength}}" placeholder="Schedule Name"
               required tabindex="0">
        <span class="character-counter">{{nameRef.value.length}}/{{nameMaxLength}}</span>
      </mat-form-field>

      <mat-form-field class="mat-form-textarea-w-100">
        <mat-label i18n="description">Description</mat-label>
        <textarea #descriptionRef
                  cdkAutosizeMaxRows="25" cdkAutosizeMinRows="2" cdkTextareaAutosize
                  formControlName="description" i18n-placeholder="schedule description input placeholder" matInput
                  maxlength="{{descriptionMaxLength}}"
                  placeholder="The description of the schedule." tabindex="3"></textarea>
        <span class="character-counter">{{descriptionRef.value.length}}/{{descriptionMaxLength}}</span>
      </mat-form-field>

      <mat-form-field class="mat-form-input">
        <mat-label i18n="analysisIntervalInDays">Analysis interval in days</mat-label>
        <input #analysisIntervalInDaysRef formControlName="analysisIntervalInDays" matInput maxlength="{{analysisIntervalInDaysMaxLength}}" type="number"
               placeholder="180" required>
        <mat-error *ngIf="!form.get('analysisIntervalInDays')?.valid && form.get('analysisIntervalInDays')?.touched" i18n="analysis interval in days invalid format">Please enter a valid analysis interval in days in the format: 123
        </mat-error>
        <span class="character-counter">{{analysisIntervalInDaysRef.value.length}}/{{analysisIntervalInDaysMaxLength}}</span>
      </mat-form-field>


      <table class="table table-striped table-responsive">
        <tbody>
        <tr>
          <th i18n>Creation date:</th>
          <td>
            <ng-container *ngIf="schedule && schedule?.creationDate as date; else unknown">
              <app-date-view [date]="date"></app-date-view>
            </ng-container>
          </td>
        </tr>
        <tr>
          <th i18n>Last edit date:</th>
          <td>
            <ng-container *ngIf="schedule && schedule?.lastEditDate as date; else unknown">
              <app-date-view [date]="date"></app-date-view>
            </ng-container>
          </td>
        </tr>
        <tr>
          <th i18n>Last comprehensive analysis:</th>
          <td>
            <ng-container *ngIf="schedule && schedule?.lastComprehensiveSymbolCheck as date; else none">
              <app-date-view [date]="date"></app-date-view>
            </ng-container>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col">
      <h2 class="paragraph-headline-2" i18n>Exchanges</h2>
      <p i18n>All symbols of the selected exchanges are included in the schedule.</p>
      <app-symbols-exchange-selector *ngIf="schedule"
                                     (onSelectionChanged)="onExchangesSelectionChanged($event)" [showQuickSelectExchangeButtons]="true"
                                     [selectedElements]="schedule.exchangeShortNames ? schedule.exchangeShortNames : []" elementType="exchanges"></app-symbols-exchange-selector>

      <h2 class="paragraph-headline-2 mt-3" i18n>Symbols</h2>
      <p i18n>All selected symbols are included in the schedule.</p>
      <app-symbols-exchange-selector *ngIf="schedule"
                                     (onSelectionChanged)="onSymbolsSelectionChanged($event)"
                                     [selectedElements]="schedule.symbolCodes ? schedule.symbolCodes : []" elementType="symbols"></app-symbols-exchange-selector>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-mat-icon-button [disabled]="!this.form.valid" [label]="getCreateOrSaveLabel()" cssClass="mb-3 mr-2" icon="save"
                           type="submit"></app-mat-icon-button>

      <app-mat-icon-button [callback]="onDelete.bind(this)" [disabled]="new" [params]="[schedule]" color="warn" cssClass="mb-3 mr-2"
                           i18n-label="delete" icon="delete" label="Delete" type="button"></app-mat-icon-button>

      <app-mat-icon-button [callback]="onDeleteLastComprehensiveSymbolCheck.bind(this)" [disabled]="new" [params]="[schedule]" color="accent" cssClass="mb-3" *ngIf="schedule?.lastComprehensiveSymbolCheck"
                           i18n-label="Trigger new comprehensive analysis" icon="delete" label="Trigger new comprehensive analysis" type="button"></app-mat-icon-button>
    </div>
  </div>
</form>

<ng-template #unknown>
  <span i18n>Unknown</span>
</ng-template>
<ng-template #none>
  <span i18n>None</span>
</ng-template>
