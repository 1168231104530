<h1 i18n class="paragraph-headline">Statistical trading planner (coming soon)</h1>

<app-mat-icon-button *ngIf="user" [routerLink]="['plans']" color="primary" cssClass="mb-3" i18n-label icon="event"
                     label="Go to my plans"></app-mat-icon-button>

<p i18n>The Statistical trading planner is a powerful feature designed to help users maximize their profits in the stock market. It allows users to create customized plans for buying and selling stocks throughout the year, based on statistical analysis. Here's an overview of what the feature offers:</p>

<h2 class="paragraph-headline-2" i18n>Creating a Plan:</h2>
<ul>
  <li i18n>You can choose between a blank plan or an automatically generated plan.</li>
  <li i18n>In a blank plan, you can manually add statistical trades you intend to make.</li>
  <li i18n>An automatically generated plan selects statistically favorable trades based on user-defined parameters, such as minimum average relative profit per trade, minimum win rate, trading volume, market capitalization, and more.</li>
</ul>

<h2 class="paragraph-headline-2" i18n>Plan Customization:</h2>
<ul>
  <li i18n>You can set parameters like starting capital, maximum drawdown, minimum data years, maximum trade holding period, exchanges, excluded stocks, included trades, and more.</li>
  <li i18n>The plan can be further tailored by specifying the start date and tax rate.</li>
  <li i18n>The plan can be recalculated at any time, retaining past transactions and excluded stocks while including the specified trades.</li>
  <li i18n>You can manually add trades from the best trades and upcoming trades tables using dedicated buttons.</li>
</ul>

<h2 class="paragraph-headline-2" i18n>Detailed Planning and Tracking:</h2>
<ul>
  <li i18n>The planner generates a comprehensive buying and selling plan for the next 12 months, reflecting real-time stock prices when available.</li>
  <li i18n>You can enter your actual purchase and sell prices, dates, and associated fees.</li>
  <li i18n>Expected and realized profits are displayed, taking into account the specified tax rate.</li>
  <li i18n>You can remove stocks you do not wish to purchase.</li>
  <li i18n>You view your cash balance as well as a detailed breakdown of the current stock holdings for each point of time in your plan.</li>
</ul>

<h2 class="paragraph-headline-2" i18n>E-Mail Reminders and Exports:</h2>
<ul>
  <li i18n>You can set up reminders for each trade.</li>
  <li i18n>Your plans can be exported as JSON or CSV for further analysis or record-keeping purposes.</li>
</ul>

<h2 class="paragraph-headline-2" i18n>Disclaimer:</h2>
<p i18n>The Statistical trading planner is based on statistical calculations and should not be considered as financial advice, as future market developments are inherently uncertain.</p>

<p i18n>With the Statistical trading planner, users can strategize their stock market investments with statistical insights, track their trades, and make informed decisions to potentially enhance their profitability.</p>
