<a (click)="openEditDialog($event)" class="hover-pointer" [ngClass]="{'filter-active': isFilterActive}">
  <span i18n>Exchanges</span>
  <ng-container *ngIf="exchangeShortNames$ | async as exchangeShortNames">
    <ng-container *ngIf="exchangeShortNames !== undefined && exchangeShortNames.length > 0">
      <span>:&nbsp;</span>
      <ul>
        <li *ngFor="let exchangeShortName of exchangeShortNames">
          <app-exchange-descriptor [exchangeShortName]="exchangeShortName" mode="codeOnly"></app-exchange-descriptor>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</a>
