import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {MetadataService} from '../../shared/services/metadata.service';
import * as moment from 'moment';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-statistical-trading-overview',
  templateUrl: './statistical-trading-overview.component.html',
  styleUrls: ['./statistical-trading-overview.component.scss'],
})
export class StatisticalTradingOverviewComponent extends BaseComponent implements OnInit {

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private titleService: TitleService,
      private metadataService: MetadataService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`About statistical trades`);

    const momentDate = moment(new Date());
    this.metadataService.updateTags($localize`Statistical trades`,
        $localize`using algorithms to identify trades based on historic market data and trends`,
        $localize`stock statistical trade`, undefined, undefined, undefined, undefined, momentDate,
    );
  }


}
