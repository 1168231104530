import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UtilService} from '../../../../shared/util.service';
import {TitleService} from '../../../../shared/services/title.service';
import {PlannerService} from '../../planner.service';
import {CANNOT_BE_UNDONE} from '../../../../shared/constants/strings';
import {Wrapper} from '../../../../shared/models/wrapper.model';
import {StatisticalTradingPlan, StatisticalTradingPlanTypeValue} from '../../../../shared/models/statisticalTradingPlan.interface';

@Component({
  selector: 'app-statistical-trading-plan-actions-menu',
  templateUrl: './statistical-trading-plan-actions-menu.component.html',
  styleUrls: ['./statistical-trading-plan-actions-menu.component.scss'],
})
export class StatisticalTradingPlanActionsMenuComponent extends BaseComponent implements OnInit {

  @Input() planUid?: string;
  @Input() planName?: string;
  @Input() automaticPlan?: StatisticalTradingPlanTypeValue = 'manual';
  @Output() onSuccessfulDeletion = new EventEmitter<string>();
  @Output() onDeletionFailed = new EventEmitter<string>();
  @Input() floatTopRight = false;
  @Output() onOptimizationStarted = new EventEmitter<PlanOptimizationStartData>();

  private readonly spinnerKeyDeletePlan = 'deletePlan';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private utilService: UtilService,
      private titleService: TitleService,
      private plannerService: PlannerService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  deletePlanClicked(planName?: string, planUid?: string): void {
    this.clearAlerts();
    if (!planUid) {
      this.addError($localize`Deletion failed. The plan ID is unknown.`);
      return;
    }
    const title = $localize`Are you sure you want to delete the plan '${planName}'?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deletePlan.bind(this), [planUid], undefined, undefined, undefined, 'no');
  }

  deletePlan(planUid: string) {
    this.clearAlerts();
    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    this.addLoadingSpinnerMessage(this.spinnerKeyDeletePlan, $localize`Deleting statistical trading plan...`);
    this.plannerService.deletePlan(this.user.uid, planUid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeletePlan);
      if (wrapper.success)
        this.onSuccessfulDeletion.emit(planUid);
      if (wrapper.errorMessage)
        this.onDeletionFailed.emit(wrapper.errorMessage);
    });
  }

  optimizePlanClicked(planName?: string, planUid?: string): void {
    this.clearAlerts();
    if (!planUid) {
      this.addError($localize`Optimization failed. The plan ID is unknown.`);
      return;
    }
    const title = $localize`Are you sure you want to optimize the plan '${planName}'?`;
    let message = $localize`Optimizing the plan means that we will choose a good selection of trades for you. We will keep all the trades you have already realized and try to add the ones you specified to be included in the plan. At the same time, we will exclude the trades and stocks that you have specified to be excluded. The optimization is done in the background and can take a few minutes. You don't need to stay on this page.`;
    this.utilService.showConfirmDialog(title, message, this.optimizePlan.bind(this), [planName, planUid], undefined, undefined, undefined, 'yes');
  }

  optimizePlan(planName: string, planUid: string) {
    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    let optimizedPlanPromise = this.plannerService.optimizePlan(this.user.uid, planUid);
    this.onOptimizationStarted.emit({planName, planUid, optimizedPlanPromise});
  }
}

export interface PlanOptimizationStartData {
  planName: string,
  planUid: string,
  optimizedPlanPromise: Promise<Wrapper<StatisticalTradingPlan>>
}
