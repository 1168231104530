import {Component, ElementRef, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Notification} from '../../shared/models/notification.interface';
import {environment} from '../../../environments/environment';
import {AccountService} from '../account.service';
import firebase from 'firebase/app';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import Timestamp = firebase.firestore.Timestamp;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

@Component({
  selector: 'notifications',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent extends BaseComponent implements OnInit {

  notifications: Notification[] = [];

  error?: string;
  thereIsMore = false;

  private readonly _matDialogRef: MatDialogRef<NotificationsDialogComponent>;
  private readonly target: ElementRef;
  private oldestNotificationTimestamp?: Timestamp;
  private lastVisible?: QueryDocumentSnapshot<Notification>;
  private closeEventEmitter?: EventEmitter<void>;

  constructor(authService: AuthService,
              userService: UserService,
              store: Store<AppState>,
              public dialogRef: MatDialogRef<NotificationsDialogComponent>,
              private accountService: AccountService,
              @Inject(MAT_DIALOG_DATA) public data: NotificationsDialogModel) {
    super(authService, userService, store);

    this._matDialogRef = dialogRef;
    this.target = data.target;
    this.error = data.error;
    this.closeEventEmitter = data.closeEventEmitter;

    // Update view with given values
    this.notifications = data.notifications;
    this.thereIsMore = this.notifications.length === environment.defaultLoadNotificationsCount;
    // Remember oldest notification
    if (this.notifications.length > 0)
      this.oldestNotificationTimestamp = this.notifications[this.notifications.length - 1].creationDate;
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.target.nativeElement.getBoundingClientRect();
    matDialogConfig.position = {left: `${rect.right >= 350 ? rect.right - 290 : rect.left}px`, top: `${rect.bottom}px`};
    this._matDialogRef.updatePosition(matDialogConfig.position);
  }

  loadMore() {
    this.enableLoadingSpinner($localize`Loading more notifications...`);
    const userUid = this.notifications[0].userUid;
    if (!userUid || !this.oldestNotificationTimestamp)
      return;
    this.accountService.fetchNotifications(userUid, this.oldestNotificationTimestamp, this.lastVisible).then(wrapper => {
      if (wrapper.data) {
        this.notifications.push(...wrapper.data);
        this.thereIsMore = wrapper.data.length === environment.defaultLoadNotificationsCount;
      }
      this.error = wrapper.errorMessage;
      this.lastVisible = wrapper.lastVisible;
      this.disableLoadingSpinner();
    });
  }

  closeDialog() {
    this.closeEventEmitter?.emit();
  }
}

/**
 * Class to represent messages dialog model.
 */
export class NotificationsDialogModel {

  constructor(public notifications: Notification[], public target: ElementRef, public error?: string, public closeEventEmitter?: EventEmitter<void>) {
  }
}
