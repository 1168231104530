<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the minimum market capitalization (in million {{baseCurrency}}) to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n>Min. market cap (M {{baseCurrency}})</mat-label>
          <input cdkFocusInitial #minMarketCapInput type="number" matInput name="minMarketCap" formControlName="minMarketCap" placeholder="1000" id="minMarketCap"
                 [min]="0" [max]="999999" (change)="saveMinMarketCap(minMarketCapInput.value)">
          <mat-icon (click)="clearMinMarketCap()" *ngIf="minMarketCapInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>
