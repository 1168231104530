import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../shared/components/base-dialog/base-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {selectBestStatisticalReturnSearchMinHighestRise, selectUpcomingStatisticalReturnSearchMinHighestRise} from '../../../../store/stock.selectors';
import {takeUntil} from 'rxjs/operators';
import {StatisticalReturnsSearchParams} from '../../../../shared/models/statisticalReturnsSearchParams.interface';
import {mergeUpdateBestStatisticalReturnSearchParams, mergeUpdateUpcomingStatisticalReturnSearchParams} from '../../../../store/stock.actions';

@Component({
  selector: 'app-min-highest-rise-edit-dialog',
  templateUrl: './min-highest-rise-edit-dialog.component.html',
  styleUrls: ['./min-highest-rise-edit-dialog.component.scss'],
})
export class MinHighestRiseEditDialogComponent extends BaseDialogComponent implements OnInit {

  form: FormGroup = this.createForm();

  minHighestRise$?: Observable<number | undefined>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    dialogRef: MatDialogRef<MinHighestRiseEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) {
    super(authService, userService, store, dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.viewType === 'upcoming') {
      this.minHighestRise$ = this.store.select(selectUpcomingStatisticalReturnSearchMinHighestRise);
    }
    if (this.data.viewType === 'best') {
      this.minHighestRise$ = this.store.select(selectBestStatisticalReturnSearchMinHighestRise);
    }

    this.minHighestRise$?.pipe(takeUntil(this.destroy$)).subscribe(minHighestRise => {
      this.form.patchValue({minHighestRise});
    });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      minHighestRise: [''],
    });
  }

  saveMinHighestRise(minHighestRiseString: string) {
    if (!minHighestRiseString)
      return this.clearMinHighestRise();
    const minHighestRise = parseInt(minHighestRiseString);
    if (isNaN(minHighestRise))
      return;
    let searchParams: StatisticalReturnsSearchParams = {minHighestRise};
    this.mergeUpdateSearchParams(searchParams);
  }

  clearMinHighestRise() {
    let searchParams: StatisticalReturnsSearchParams = {minHighestRise: undefined};
    this.mergeUpdateSearchParams(searchParams);
  }

  private mergeUpdateSearchParams(searchParams: StatisticalReturnsSearchParams) {
    if (this.data.viewType === 'upcoming')
      this.store.dispatch(mergeUpdateUpcomingStatisticalReturnSearchParams({searchParams}));
    if (this.data.viewType === 'best')
      this.store.dispatch(mergeUpdateBestStatisticalReturnSearchParams({searchParams}));
  }
}
