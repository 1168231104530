import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {
  selectBestStatisticalReturnSearchMinPeriodWinRatios,
  selectBestStatisticalReturnSearchMinWinRatio,
  selectUpcomingStatisticalReturnSearchMinPeriodWinRatios,
  selectUpcomingStatisticalReturnSearchMinWinRatio,
} from '../../../store/stock.selectors';
import {Observable} from 'rxjs';
import {StatisticalReturnViewType} from '../../../shared/types/statisticalReturnViewType.type';
import {PeriodWinRatio} from '../../../shared/models/statisticalReturnsSearchParams.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {MinWinRatiosEditDialogComponent} from './min-win-ratios-edit-dialog/min-win-ratios-edit-dialog.component';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-min-win-ratios-filter',
  templateUrl: './min-win-ratios-filter.component.html',
  styleUrls: ['./min-win-ratios-filter.component.scss'],
})
export class MinWinRatiosFilterComponent extends BaseComponent implements OnInit {

  @Input() viewType?: StatisticalReturnViewType;

  minWinRatio$?: Observable<number | undefined>;
  minPeriodWinRatios$?: Observable<PeriodWinRatio[] | undefined>;

  private editDialog?: MatDialogRef<any, any>;
  isFilterActive = false;
  private minWinRatio?: number;
  private minPeriodWinRatios?: PeriodWinRatio[];

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.viewType === 'upcoming') {
      this.minWinRatio$ = this.store.select(selectUpcomingStatisticalReturnSearchMinWinRatio);
      this.minPeriodWinRatios$ = this.store.select(selectUpcomingStatisticalReturnSearchMinPeriodWinRatios);
    }
    if (this.viewType === 'best') {
      this.minWinRatio$ = this.store.select(selectBestStatisticalReturnSearchMinWinRatio);
      this.minPeriodWinRatios$ = this.store.select(selectBestStatisticalReturnSearchMinPeriodWinRatios);
    }

    this.minWinRatio$?.pipe(takeUntil(this.destroy$)).subscribe(minWinRatio => {
      this.minWinRatio = minWinRatio;
      this.determineIfFilterIsActive();
    });
    this.minPeriodWinRatios$?.pipe(takeUntil(this.destroy$)).subscribe(minPeriodWinRatios => {
      this.minPeriodWinRatios = minPeriodWinRatios;
      this.determineIfFilterIsActive();
    });
  }

  openEditDialog(mouseEvent: MouseEvent): void {
    this.editDialog = this.statisticalTradingService.openEditFilterDialog(MinWinRatiosEditDialogComponent, $localize`Minimum win ratios`, this.viewType, undefined);
  }

  private determineIfFilterIsActive() {
    this.isFilterActive = false;
    if (this.minWinRatio) {
      this.isFilterActive = true;
      return;
    }
    if (this.minPeriodWinRatios) {
      let periodWinRatioWithVaue = this.minPeriodWinRatios.find(it => it.minWinRatio);
      if (periodWinRatioWithVaue)
        this.isFilterActive = true;
    }

  }
}
