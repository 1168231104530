<div class="modal-body">
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
  <ng-container *ngIf="dataType === 'includedTrades'">
    <h1 class="paragraph-headline" i18n>Included trades</h1>
    <ng-container *ngTemplateOutlet="trades"></ng-container>
  </ng-container>
  <ng-container *ngIf="dataType === 'excludedTrades'">
    <h1 class="paragraph-headline" i18n>Excluded trades</h1>
    <ng-container *ngTemplateOutlet="trades"></ng-container>
  </ng-container>
  <ng-container *ngIf="dataType === 'excludedSymbolCodes'">
    <h1 class="paragraph-headline" i18n>Excluded stock symbols</h1>

    <table class="table table-striped table-sm table-responsive" *ngIf="itemUids && itemUids.length > 0">
      <tr>
        <th i18n>Symbol</th>
        <th>&nbsp;</th>
      </tr>
      <tr *ngFor="let itemUid of itemUids">
        <td>
          <app-symbol-descriptor [symbolCode]="itemUid"></app-symbol-descriptor>
        </td>
        <td>
          <mat-icon class="hover-pointer" (click)="deleteSymbol(itemUid)">delete</mat-icon>
        </td>
      </tr>
    </table>
  </ng-container>
  <p *ngIf="!itemUids || itemUids.length === 0" i18n="No included or excluded trades|No included or excluded trades">None.</p>
  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>
</div>
<ng-template #trades>
  <app-statistical-returns-table *ngIf="itemUids && itemUids.length > 0" [showName]="true" [showExchange]="true" [statisticalReturns]="statisticalReturns" customIcon="delete" (onCustomIconClicked)="deleteTrade($event)"></app-statistical-returns-table>
</ng-template>
