<mat-toolbar color="primary">

  <button [matMenuTriggerFor]="menu" aria-label="Menu" class="d-block d-lg-none mr-2" i18n-aria-label="Menu"
          mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <a class="navbar-brand" routerLink="/" style="cursor: pointer;">
    <img alt="Stock Forecast Logo"
         class="align-top img-fluid app-icon d-block d-xs-none"
         i18n-alt="Stock Forecast icon alt text"
         src="./assets/images/icons/icon-w64.webp"
    >
    <picture>
      <source media="(max-width: 150px)" srcset="./assets/images/logos/logo-w150.webp">
      <source media="(max-width: 200px)" srcset="./assets/images/logos/logo-w200.webp">
      <source media="(max-width: 300px)" srcset="./assets/images/logos/logo-w300.webp">
      <img alt="Stock Forecast logo" class="align-top img-fluid app-logo d-none d-xs-block"
           i18n-alt="Stock Forecast logo"
           src="./assets/images/logos/logo-w500.webp">
    </picture>
  </a>

  <a class="d-none d-sm-block" routerLink="/statistical-trading/">
    <button color="accent" i18n="statistical trading button|statistical trading button" mat-button
            routerLinkActive="toolbar-active">Statistical trading
    </button>
  </a>

  <a class="d-block d-sm-none d-md-none d-lg-none d-xl-none" routerLink="/statistical-trading/">
    <button color="accent" i18n="statistical trading button short|statistical trading button short" mat-button
            routerLinkActive="toolbar-active" i18n-matTooltip matTooltip="Statistical trading">Stat
    </button>
  </a>

  <a class="d-none d-md-block" routerLink="/dividends/">
    <button color="accent" i18n="dividends button|dividends button" mat-button
            routerLinkActive="toolbar-active">Dividends
    </button>
  </a>

  <a class="d-block d-sm-none d-md-none d-lg-none d-xl-none" routerLink="/dividends/">
    <button color="accent" i18n="dividends button short|dividends button short" mat-button
            routerLinkActive="toolbar-active" i18n-matTooltip matTooltip="Dividends">Div
    </button>
  </a>

  <a class="d-none d-lg-block" routerLink="/account/">
    <button color="accent" i18n="my stock Forecast button|my stock Forecast button" mat-button
            routerLinkActive="toolbar-active">My Stock Forecast
    </button>
  </a>


  <mat-menu #menu="matMenu">
    <a class="btn btn-menu d-block d-sm-none text-left" i18n="statistical trading button|statistical trading button"
       mat-menu-item routerLink="/statistical-trading" routerLinkActive="menu-active">Statistical Trading</a>
    <a class="btn btn-menu d-block d-md-none text-left" i18n="dividends button|dividends button"
       mat-menu-item routerLink="/dividends" routerLinkActive="menu-active">Dividends</a>
    <a class="btn btn-menu d-block d-lg-none text-left" i18n="my stock forecast button|my stock forecast button"
       mat-menu-item routerLink="/account" routerLinkActive="menu-active">My Stock Forecast</a>
    <a [matMenuTriggerFor]="langMenu" aria-label="Language" class="btn btn-menu d-block d-lg-none text-left"
       i18n="language selection menu" i18n-aria-label="Language menu button" mat-menu-item>Language</a>

    <mat-menu #langMenu="matMenu">
      <button (click)="selectLang(lang)" *ngFor="let lang of langs"
              [class]="lang.code === currentLang?.code ? 'bg-primary text-white' : ''" mat-menu-item>
        <img class="mr-1" src="./assets/images/icons/flags/{{lang.iconCode}}-{{iconSize}}.webp">
        <span>{{languageService.getLanguageNameByCode(lang.code)}}</span>
      </button>
    </mat-menu>

  </mat-menu>

  <span class="spacer"></span>
  <!--  <button aria-label="Search" class="mr-2 d-block d-md-none" color="accent" i18n-aria-label="Search" mat-icon-button routerLink="/search">-->
  <!--    <mat-icon>search</mat-icon>-->
  <!--  </button>-->

  <app-lang-selector class="d-none d-sm-block mr-2"></app-lang-selector>
  <app-notifications-button class="mr-2"></app-notifications-button>
  <app-login-button></app-login-button>
</mat-toolbar>

<!--<app-account-setup-warning></app-account-setup-warning>-->
