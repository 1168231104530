<!-- Breadcrumb-->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <a class="breadcrumb-item" i18n="Account" routerLink="..">Account</a>
    <li class="breadcrumb-item">
      <span i18n="personal info headline">My personal information</span>
    </li>
  </ol>
</nav>

<div class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="personal info headline">My personal information</h1>
  <p class="jumbotron-lead" i18n="personal info lead text">Edit your first and last name, birthday, gender, nationality, phone number, home address,
    user name, email address and user image.</p>
  <p class="jumbotron-lead" i18n>Only your user name and user image will be shown publicly. All other information are only used for verification, payment
    handling and
    dispute settlement. Borrowers can see your name after you accepted a booking.</p>
</div>

<div class="container-fluid p-1">
  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <div class="mb-3">
    <mat-card>
      <app-master-data [embedded]="true"></app-master-data>
    </mat-card>
  </div>
  <div class="mb-3">
    <!-- Note: <app-name-email> comes with its own <mat-card> -->
    <app-name-email [embedded]="true"></app-name-email>
  </div>
  <div class="mb-3">
    <h1 class="paragraph-headline" i18n="user image headline">My image</h1>
    <mat-card>
      <app-image [embedded]="true"></app-image>
    </mat-card>
  </div>
</div>
