import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Price} from './models/price.interface';
import {Coordinates} from './models/coordinates.interface';
import * as moment from 'moment';
import {ElementRef} from '@angular/core';
import {ProfitByPeriodMap, StatisticalReturn} from './models/statisticalReturn.interface';
import firebase from 'firebase';
import {TradingPhase} from './enums/tradingPhase.enum';
import {MatDialogConfig} from '@angular/material/dialog';
import Timestamp = firebase.firestore.Timestamp;

export const memoizee = require('memoizee');

export const IMAGE_FILE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'bpg'];
export const DAYS_IN_MONTH = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export default class Util {

  /**
   * Removes the given element from the given array and returns the array. The original array is changed by this.
   * @param array array, from which the element should be removed. This array is changed by this
   * @param element element to be removed. If it is not contained, nothing happens
   * @return given array without the element
   */
  static removeFromArray<T>(array: T[], element: T): T[] {
    const index = array.indexOf(element);
    if (index > -1)
      array.splice(index, 1);
    return array;
  }

  /**
   * Checks, if the given element is contained in the given array.
   * @param array array to check
   * @param element element to be contained
   * @return true, if contained, false otherwise
   */
  static arrayContains<T>(array: T[], element: T): boolean {
    const index = array.indexOf(element);
    return index > -1;
  }

  /**
   * Checks, if the given elements are contained in the given array.
   * @param array array to check
   * @param elements elements to be contained
   * @return true, if contained, false otherwise
   */
  static arrayContainsAll(array: string[], elements: string[]): boolean {
    for (let i = 0; i < elements.length; i++) {
      if (!this.arrayContains(array, elements[i])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Converts the given argument to a map. The argument can be either a map or an object structured as a map
   * @param mapOrObject
   */
  static convertToMap(mapOrObject: any) {
    if (mapOrObject instanceof Map) {
      return mapOrObject;
    }

    return new Map(Object.entries(mapOrObject));
  }

  /**
   * Shuffles the given array.
   * @param array shuffled array
   */
  static shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  /**
   * Delivers the given date without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param date date, from which the time should be removed
   * @return date without time.
   */
  static getDateWithoutTime(date: Date): Date {
    return new Date(this.getTimestampWithoutTime(date));
  }

  /**
   * Converts the given parameter to a date, if it is something date-like.
   * @param date date parameter. Can be a firebase timestamp, a Date or have the fields seconds and nanoseconds
   * @return Date
   */
  static getDate(date: any): Date {
    if (date instanceof Timestamp)
      return date.toDate();
    if (date instanceof Date)
      return date;
    if (date.seconds !== undefined && date.nanoseconds !== undefined)
      return Timestamp.fromMillis(date.seconds * 1000 + date.nanoseconds / 1000000).toDate();
    if (date.seconds !== undefined && date.nanos !== undefined)
      return Timestamp.fromMillis(date.seconds * 1000 + date.nanos / 1000000).toDate();
    if (typeof date === 'string')
      return new Date(date);
    return date;
  }

  /**
   * Converts the given parameter to a firebase.firestore.Timestamp, if it is something date-like.
   * @param date date parameter. Can be a firebase timestamp, a Date or have the fields seconds and nanoseconds
   * @return Timestamp
   */
  static getTimestamp(date: any): firebase.firestore.Timestamp {
    if (date instanceof firebase.firestore.Timestamp) {
      return date;
    } else if (date instanceof Date) {
      const seconds = Math.floor(date.getTime() / 1000);
      const nanoseconds = (date.getTime() % 1000) * 1000000;
      return new firebase.firestore.Timestamp(seconds, nanoseconds);
    } else if (date && typeof date === 'object' && 'seconds' in date && 'nanoseconds' in date) {
      return new firebase.firestore.Timestamp(date.seconds, date.nanoseconds);
    } else {
      throw new Error('Invalid date-like parameter');
    }
  }

  /**
   * Delivers the given date as a timestamp without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param date date, from which the time should be removed
   * @return date as timestamp without time.
   */
  static getTimestampWithoutTime(date: Date): number {
    // Don't modify the original date
    const newDate = new Date(date.getTime());
    return newDate.setHours(0, 0, 0, 0);
  }

  /**
   * Delivers the given date as a timestamp without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param timestamp timestamp, from which the time should be removed
   * @return date as timestamp without time.
   */
  static removeTimeFromTimestamp(timestamp: number): number {
    return this.getTimestampWithoutTime(new Date(timestamp));
  }

  /**
   * Delivers the given firebase timestamp as a number timestamp without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param timestamp firebase timestamp, from which the time should be removed
   * @return date as number timestamp without time.
   */
  static removeTimeFromFirebaseTimestamp(timestamp: Timestamp): number {
    return this.removeTimeFromTimestamp(timestamp.seconds * 1000);
  }

  /**
   * Returns a formatted date string without the year for the given day and month.
   * @param {number} day - The day of the month (1-31).
   * @param {number} month - The month of the year (0-11).
   * @returns {string} A formatted date string without the year.
   */
  static getFormattedDateDayMonth(day: number, month: number): string {
    const date = new Date(2000, month - 1, day); // Year 2000 is used as a reference year for correct formatting
    return date.toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
    });
  }

  static auxObservable(action: Action): Observable<any> {
    return new Observable((observer) => {
      observer.next(action);
    });
  }

  /**
   * Scrolls to the top of the page smoothly. By default scrolling is done with about 60fps (every 16 ms),
   * 333 pixels per step. But this can be configured with the parameters
   * @param scrollDistancePerStep distance to be scrolled per step.
   * @param stepPeriod period of each step. If stepPeriod is 0, the animation is omitted and scrolling is done instantly.
   */
  static scrollToTop(stepPeriod: number = 16, scrollDistancePerStep: number = 333): void {
    if (stepPeriod === 0) {
      window.scroll(0, 0);
      return;
    }

    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - scrollDistancePerStep); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, stepPeriod);
  }

  /**
   * Returns the given value, if it's not undefined, or null, if it is
   * @param value any value
   * @return the given value, if it's not undefined, or null, if it is
   */
  static valueOrNull<T>(value: T | undefined): T | null {
    if (!value)
      return null;
    return value;
  }

  /**
   * Calculated the lowest and highest price per day from the given array of prices. By doing so, the given prices are sorted by pricePerDay descendingly
   * (highest price first).
   * @param prices array of prices
   * @return object containing the lowest and highest price per day
   */
  static calcLowestAndHighestPricePerDay(prices: Price[]): { lowestPricePerDay: number, highestPricePerDay: number } {
    // Sort prices by price per day
    prices.sort((a, b) => b.pricePerDay - a.pricePerDay);

    return {
      highestPricePerDay: prices[0].pricePerDay,
      lowestPricePerDay: prices[prices.length - 1].pricePerDay,
    };
  }

  /**
   * Calculates the book price.
   * @param prices list of prices for this listing
   * @param rentPeriod the book period in days
   * @return price for period or undefined, if calculation failed
   */
  public static findBestRentPrice(prices: Price[] | undefined, rentPeriod: number | undefined): Price | undefined {

    if (rentPeriod === undefined || prices === undefined)
      return undefined;

    // Clone the prices and convert them into an array
    let sortedPrices: Price[] = [].slice.call(prices);
    // Sort the list by pricePerDay ascending
    sortedPrices = sortedPrices?.sort((a, b) => a.pricePerDay - b.pricePerDay);
    // Iterate the sorted list to find the cheapest option and return it
    for (const price of sortedPrices) {
      if (rentPeriod >= price.minDays)
        return price;
    }

    return undefined;
  }

  /**
   * Calculates the distance between two given sets of coordinates
   * @param coords1
   * @param coords2
   */
  public static getDistanceInKm(coords1: Coordinates, coords2: Coordinates): number {
    const R = 6371e3; // metres
    const omega1 = coords1!.lat * Math.PI / 180; // φ, λ in radians
    const omega2 = coords2!.lat * Math.PI / 180;
    const phiOmega = (coords2!.lat - coords1!.lat) * Math.PI / 180;
    const phiLambda = (coords2!.lng - coords1!.lng) * Math.PI / 180;

    const a = Math.sin(phiOmega / 2) * Math.sin(phiOmega / 2) +
      Math.cos(omega1) * Math.cos(omega2) *
      Math.sin(phiLambda / 2) * Math.sin(phiLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d / 1000;
  }

  /**
   * Prints an array of strings as a comma separated list. After every comma, there is a space. After the last element, there is no comma
   * @param strings array of strings
   * @return pretty string
   */
  public static prettyPrintArray(strings: string[]): string {
    let result = '';
    for (let i = 0; i < strings.length; i++) {
      const str = strings[i];
      result += str;
      if (i < strings.length - 1)
        result += ', ';
    }
    return result;
  }

  /**
   * Delivers the file extension based on the given file name. The file itself is not validated.
   * @param filename name of the file
   * @return file extension as a string or undefined, if there is none or if it can't be determined
   */
  public static getFileExtension(filename: string | undefined): string | undefined {
    if (!filename)
      return undefined;
    const parts = filename.split('.');
    if (parts.length < 2)
      return undefined;
    return parts[parts.length - 1].toLowerCase();
  }

  /**
   * Validates, if the file is an image.
   * @param file file to be validated
   * @param onValid callback for valid image
   * @param onInvalid callback for invalid image
   * @return true, if the file is a valid image, false otherwise
   */
  public static validateImageFile(file: File, onValid: (file: File) => void, onInvalid: (error: string) => void): void {

    if (!this.validateFileType(file, IMAGE_FILE_TYPES))
      return onInvalid(file.name + ': ' + $localize`Invalid filetype. Allowed filetypes are\:` + ' ' + Util.prettyPrintArray(IMAGE_FILE_TYPES));

    const image = new Image();

    image.onload = () => {
      onValid(file);
    };
    image.onerror = () => {
      onInvalid(file.name + ': ' + $localize`File is not a valid image.`);
    };

    // The following line is necessary for image.onload or .onerror to get called. If nothing is done with the image, neither function gets called.
    image.src = URL.createObjectURL(file);
  }

  /**
   * Validates, if the file has one of the given file types.
   * @param file file to be validated
   * @param fileTypes accepted file types array. If undefined, all file types are valid. If empty, no file types are valid.
   * @return true, if the file is a valid image, false otherwise
   */
  public static validateFileType(file: File, fileTypes?: string[]): boolean {
    // If no file types are given, all are valid
    if (fileTypes === undefined)
      return true;
    const fileExtension = Util.getFileExtension(file.name);
    return !(!fileExtension || fileTypes?.indexOf(fileExtension) === -1);
  }

  /**
   * Rounds the given value to the given number of decimal places, if necessary.
   * Example Input:
   * 10
   * 1.7777777
   * 9.1
   * Output:
   * 10
   * 1.78
   * 9.1
   * @param value number to round
   * @param decimalPlaces max number of decimal places
   * @return rounded value
   */
  public static round(value: number, decimalPlaces: number) {
    const helper = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * helper) / helper;
  }

  /**
   * Ceils the given value to the given number of decimal places, if necessary.
   * Example Input:
   * 10
   * 1.7777777
   * 9.1
   * Output:
   * 10
   * 1.78
   * 9.1
   * @param value number to ceil
   * @param decimalPlaces max number of decimal places
   * @return ceiled value
   */
  public static ceil(value: number, decimalPlaces: number) {
    const helper = Math.pow(10, decimalPlaces);
    return Math.ceil((value + Number.EPSILON) * helper) / helper;
  }

  /**
   * Removes the params from the given url. Those are the part of the url beginning with something like '?param=value'.
   * @param url url with or without params
   * @return url without params. If the url does not contain a '?', it's returned as it is.
   */
  static getUrlWithoutParams(url: string): string {
    if (url.indexOf('?') === -1)
      return url;
    const parts = url.split('?');
    return parts[0];
  }

  /**
   * Removes everything from the url but the path within the storage. Example:
   * Input: https://firebasestorage.googleapis.com/v0/b/stockforecast.appspot
   *        .com/o/img%2Flistings%2FxBOUipA4qWyXvAez8gtH%2F2021-04-07_14-00-53.5353_20200303_131920.thumb.webp
   * Output: /img/listings/xBOUipA4qWyXvAez8gtH/2021-04-07_14-12-44.4444_20200308_174414.thumb.webp
   * @param url
   */
  static getRefFromUrl(url: string) {
    if (url.indexOf('/') === -1)
      return url;
    const parts = url.split('/');
    const splitString: string = parts[parts.length - 1];
    return '/' + splitString.replace(/%2F/g, '/');
  }

  /**
   * Creates a from now string from the given date. It will be formatted in the language set in environment.momentLocale
   * @param date date, for which the from now string should be created.
   * @return from now string
   */
  static createFromNowString(date: Date): string {
    return moment(date).fromNow();
  }

  /**
   * Compares the two given values to determine, which one should come first, when sorting
   * @param a value one
   * @param b value two
   * @param isAsc if true, the smaller value will come first, otherwise the bigger value
   * @return if isAsc: -1, if a is the smaller value, 1 otherwise. if! isAsc: -1, if a is the bigger value, 1 otherwise
   */
  static compare(a: number | string, b: number | string, isAsc: boolean = true) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  static compareWithUndefined(a: number | string | undefined, b: number | string | undefined, isAsc: boolean = true) {
    if (a === undefined && b === undefined)
      return 0;
    if (a === undefined)
      return -1 * (isAsc ? 1 : -1);
    if (b === undefined)
      return 1 * (isAsc ? 1 : -1);
    return this.compare(a, b, isAsc);
  }

  static upperCaseFirstLetter(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  static lowerCaseFirstLetter(input: string): string {
    return input.charAt(0).toLowerCase() + input.slice(1);
  }

  /**
   * Removes <em>...</em> tags from the given input. The content of the tag remains.
   * @param input some string with or without em tag
   * @return input string after removing em tags (and leaving tag content)
   */
  static removeEmTags(input?: string): string {
    if (!input)
      return '';
    return input.replace('<em>', '').replace('</em>', '');
  }

  static parseTime(timeString: string): { hours: number, minutes: number } {
    if (timeString.indexOf('AM') > -1 || timeString.indexOf('PM') > -1) {
      return this.getHoursAndMinutes(moment(timeString, ['h:mm A']));
    }
    return this.getHoursAndMinutes(moment(timeString, ['hh:mm']));
  }

  static getHoursAndMinutes(momentTime: moment.Moment): { hours: number, minutes: number } {
    const hours = momentTime.hours();
    const minutes = momentTime.minutes();
    return {hours, minutes};
  }

  /**
   * Returns true, if the two dates are the same day (not counting the time).
   * @param date1 date and time 1
   * @param date2 date and time 2
   * @return true, if same day, false otherwise.
   */
  static isSameDay(date1: Date, date2: Date) {
    return this.getTimestampWithoutTime(date1) === this.getTimestampWithoutTime(date2);
  }

  /**
   * Inserts the given data object into the firestore
   * @param data data to be written
   * @param db firestore instance
   * @param fireStoreCollectionPath the path of the collection, where the data should be written to
   * @param onSuccessCallback Callback to be called after successful writing. Passes the documentUid.
   * @param onErrorCallback Callback to be called, if something goes wrong.
   */
  static insertData(data: any, db: firebase.firestore.Firestore, fireStoreCollectionPath: string,
                    onSuccessCallback?: ((docUid: string) => void), onErrorCallback?: ((reason: string) => void)): void {
    db.collection(fireStoreCollectionPath).add(data)
      .then(docRef => {
        if (onSuccessCallback)
          onSuccessCallback(docRef.id);
      })
      .catch((errorResponse) => {
        if (onErrorCallback)
          onErrorCallback(errorResponse.message);
      });
  }

  /**
   * Checks, if the given testDate is between the given dateFrom and dateUntil. If so, returns true, otherwise false.
   * @param testDate date to be tested
   * @param dateFrom date from
   * @param dateUntil date until
   * @return true, if testDate is in between, false, if not
   */
  static isBetween(testDate: Date, dateFrom: Date, dateUntil: Date) {
    return testDate.getTime() > dateFrom.getTime() && testDate.getTime() < dateUntil.getTime();
  }

  /**
   * Checks, if the given testDate is between the given dateFrom and dateUntil or equals either date. If so, returns true, otherwise false.
   * @param testDate date to be tested
   * @param dateFrom date from
   * @param dateUntil date until
   * @return true, if testDate is in between or equals either date, false, if not
   */
  static isBetweenOrEquals(testDate: Date, dateFrom: Date, dateUntil: Date) {
    return testDate.getTime() >= dateFrom.getTime() && testDate.getTime() <= dateUntil.getTime();
  }

  static getFullDaysBetween(startDate: Date, endDate: Date): number[] {
    const fullDays: number[] = [];

    for (const d = this.getDateWithoutTime(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const nextDay: Date = new Date(d.getTime());
      nextDay.setDate(d.getDate() + 1);
      if (d.getTime() >= startDate.getTime() && nextDay.getTime() <= endDate.getTime())
        fullDays.push(d.getTime());
    }
    return fullDays;
  }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  static humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  /**
   * Converts a file to a base64 string.
   * @param file file to be converted
   * @param onSuccessCallback callback containing the base64 string after successful conversion
   * @param onErrorCallback callback, if something went wrong
   */
  static convertFiletoBase64(file: File, onSuccessCallback: (base64: any) => void,
                             onErrorCallback: (error: any) => void): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onSuccessCallback(reader.result);
    reader.onerror = error => onErrorCallback(error);
  };

  /**
   * Converts all given files to base64 strings
   * @param files all files to be converted
   * @param base64Files already converted base64 files. Used internally (for recursive calls). You can pass an empty error, when you call this method.
   * @param fileIndex index of the file to be converted next. Used internally (for recursive calls). You can pass 0, when you call this method.
   * @param onFinishedCallback callback containing all converted base64 strings after successful conversion
   * @param onErrorCallback callback, if something went wrong
   */
  static convertFilesToBase64(files: File[], base64Files: string[], fileIndex: number, binaryOnly: boolean, onFinishedCallback: (base64: string[]) => void, onErrorCallback: (error: any, incompleteBase64: string[]) => void): void {
    this.convertFiletoBase64(files[fileIndex], (base64 => {
        if (binaryOnly) {
          const parts = base64.split(';base64,');
          base64 = parts[1];
        }
        base64Files.push(base64);
        if (fileIndex < files.length - 1)
          this.convertFilesToBase64(files, base64Files, ++fileIndex, binaryOnly, onFinishedCallback, onErrorCallback);
        else
          onFinishedCallback(base64Files);
      }),
      (error => {
        onErrorCallback(error, base64Files);
      }));
  }

  /**
   * Delivers the domain part of the URL, e.g. https://stockForecast.de or http://localhost:4200
   */
  static getDomain(): string {
    return window.location.origin;
  }

  /**
   * Delivers the path part of the URL, e.g. /en/account/listings
   */
  static getPath(): string {
    return (location.pathname + location.search);
  }

  /**
   * Scrolls to the element with the given elementRef.
   * @param elementRef element reference of the target element. Use with @ViewChild('element') element?: ElementRef;
   */
  static scrollToElementRef(elementRef?: ElementRef) {
    if (!elementRef)
      return;
    // Use a timeout to give the target component some time to load (if necessary)
    setTimeout(args => {
      const targetElement = elementRef?.nativeElement;
      targetElement?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }, 50);
  }

  /**
   * Determines, if the slide (of a carousel) is active. Those slides are active:
   * - the previous one (or the last one, if the current one is the first)
   * - the current one
   * - the next one (or the first one, if the current one is the last)
   * This can be used for lazy loading of images. Only the current, previous and next images will be loaded and not all at once.
   * @param index index of the slide
   * @param activeIndex the currently shown slide
   * @param slideCount number of slides
   */
  public static isSlideActive(index: number, activeIndex: number, slideCount: number) {
    // Current slide
    if (index === activeIndex)
      return true;
    // Next slide
    if (index === activeIndex + 1)
      return true;
    // Previous slide
    if (index === activeIndex - 1)
      return true;
    // If this is the first slide, the last one
    if (activeIndex === 0 && index === slideCount - 1)
      return true;
    // If this is the last slide, the first one
    if (activeIndex === slideCount - 1 && index === 0)
      return true;
    // All other slides are inactive
    return false;
  }

  /**
   * Parses the given date string as a date and returns its year.
   * @param dateString a date in the format yyyy-MM-dd
   */
  public static getYear(dateString: string) {
    const date = new Date(dateString);
    return date.getFullYear();

  }

  /**
   * Calculates the day number of the given month and day.
   * @param {number} month The month as a number between 1 and 12.
   * @param {number} day The day as a number between 1 and 31.
   * @returns {number} The day number of the given month and day.
   */
  public static calculateDayNumber(month: number, day: number): number {
    let dayNumber = 0;
    for (let i = 1; i < month; i++) {
      dayNumber += DAYS_IN_MONTH[i];
    }
    dayNumber += day;
    return dayNumber;
  }

  static calculateDayNumberFromDate(date: Date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return this.calculateDayNumber(month, day);
  }

  /**
   * Calculates the date from the given day number and year.
   * @param {number} dayNumber The day number.
   * @param {number} year The year.
   * @returns {Date} The corresponding date.
   */
  public static getDateFromDayNumber(dayNumber: number, year: number = this.getCurrentYear()): Date {
    const daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      // Leap year, February has 29 days
      daysInMonth[2] = 29;
    }
    let month = 1;
    while (dayNumber > daysInMonth[month]) {
      dayNumber -= daysInMonth[month];
      month++;
    }
    return new Date(year, month - 1, dayNumber);
  }

  public static getCurrentDayNumber(): number {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return Util.calculateDayNumber(month, day);
  }

  /**
   * Returns a Date object from the given day, month, and year.
   * @param {number} day - The day of the month (1-31).
   * @param {number} month - The month of the year (1-12).
   * @param {number} year - The year.
   * @returns {Date | undefined} The Date object representing the given day, month, and year
   */
  static getDateFromDayMonthYear(day: number, month: number, year: number): Date {
    return new Date(year, month - 1, day);
  }

  /**
   * Returns the current year as a four-digit number.
   * @returns {number} The current year as a four-digit number.
   */
  public static getCurrentYear(): number {
    return new Date().getFullYear();
  }

  /**
   * If the given day number is after today's day number, the last year is returned. Otherwise, this year is returned
   * @param dayNumber given day number
   * @return this year or last year
   *
   */
  static getLatestYearForDayNumber(dayNumber: number) {
    const currentYear = this.getCurrentYear();
    if (this.getCurrentDayNumber() >= dayNumber)
      return currentYear;
    return currentYear - 1;
  }

  /**
   * Calculates the average of the given array of numbers
   * @param numbers numbers to be averaged
   * @return average number
   */
  static calcAverage(numbers: number[]) {
    let sum = 0;
    numbers.forEach(num => sum += num);
    let count = numbers.length;
    return sum / count;
  }

  /**
   * Adds the itemsToAdd to the completeArray, but only if they are not yet contained.
   * @param itemsToAdd items to be added to the complete array
   * @param completeArray array, to which the items to be added should be added
   */
  static addUniquely(itemsToAdd: any[], completeArray: any[]) {
    itemsToAdd.forEach(item => {
      if (completeArray.indexOf(item) === -1)
        completeArray.push(item);
    });
  }

  /**
   * Reloads the current page.
   */
  static reloadPage() {
    window.location.reload();
  }

  static getNumberOfDataYearsString(statRet?: StatisticalReturn): string {
    if (statRet === undefined || statRet.profitByPeriodMap === undefined)
      return '';
    return Util.getNumberOfDataYears(statRet.profitByYearMap).toString();
  }

  static getNumberOfDataYears(profitByPeriodMap?: ProfitByPeriodMap): number {
    if (profitByPeriodMap === undefined)
      return 0;
    let keys = Object.keys(profitByPeriodMap);
    let intKeys: number[] = keys.map(it => Number(it));
    let years = intKeys.sort((a, b) => a - b);
    return years.length;
  }

  /**
   * Sorts the string as if it were numbers (e.g. 5 comes before 10)
   * @param numericStrings
   * @private
   */
  public static sortNumerically(numericStrings: string[], reverse = false) {
    // Convert to numbers
    let numbers: number[] = [];
    numericStrings.forEach(numericString => numbers.push(parseInt(numericString)));
    numbers = numbers.sort((a, b) => a - b);
    const sortedStrings: string[] = [];
    numbers.forEach(num => sortedStrings.push(num.toString()));
    if (reverse)
      return sortedStrings.reverse();
    return sortedStrings;
  }

  /**
   * Joins an array of strings using a specified separator string.
   *
   * @param {string[]} stringArray - The array of strings to join.
   * @param {string} on - The separator string to use for joining the strings.
   * @returns {string} A new string that is the result of joining the input strings using the specified separator.
   */
  public static joinStringArray(stringArray: string[], on: string): string {
    return stringArray.join(on);
  }

  /**
   * Creates a user-friendly error message based on an error object.
   *
   * @param error - The error object to generate a message for.
   * @param introString - An optional introduction string to prefix the error message with. Should be a sentence with a period in the end. No space after that.
   * @returns The generated error message string.
   */
  static createErrorString(error: any, introString?: string): string {
    let errorMessage = '';
    if (error?.code === 'permission-denied')
      errorMessage = $localize`Not authorized.`;
    if (error?.status === 401)
      errorMessage = $localize`Not authenticated.`;
    if (error?.status === 0)
      errorMessage = $localize`Backend is currently not available. Please try again later.`;
    if (error?.error?.error !== undefined)
      errorMessage = error.error.error;
    if (error?.message)
      errorMessage = error?.message;
    else
      errorMessage = error.toString();

    if (introString !== undefined)
      return `${introString} ${errorMessage}`;
    return errorMessage;
  }

  static getTradingPhaseString(tradingPhase: TradingPhase | undefined) {
    if (!tradingPhase)
      return $localize`Unknown trading phase`;
    switch (tradingPhase) {
      case TradingPhase.Open:
        return $localize`Opening price`;
      case TradingPhase.Close:
        return $localize`Closing price`;
    }
  }

  /**
   * Multiplies the given number by 100, if a number is given, or returns undefined. E.g. 0.15 -> 15 (%)
   * @param value number to be multiplied by 100. Can be undefined.
   * @return multiplied number or undefined
   */
  static toPercentage(value?: number) {
    if (value === undefined)
      return undefined;
    return this.round(value * 100, 5);
  }

  /**
   * Divides the given number by 100, if a number is given, or returns undefined. E.g. 15 (%) -> 0.15
   * @param value number to be divided by 100. Can be undefined.
   * @return divided number or undefined
   */
  static fromPercentage(value?: number) {
    if (value === undefined)
      return undefined;
    return this.round(value / 100, 5);

  }

  /**
   * Divides the given number by 100. E.g. 15 (%) -> 0.15
   * @param value number to be divided by 100
   * @return divided number
   */
  static fromPercentageNonUndefined(value: number) {
    return this.round(value / 100, 5);
  }

  static getRandomNumber(lowerLimit: number, upperLimit: number): number {
    return Math.floor(Math.random() * (upperLimit - lowerLimit + 1)) + lowerLimit;
  }

  /**
   * Generates configuration for a MatDialog component based on mouse event coordinates and dialog dimensions.
   *
   * This function calculates the optimal position for a MatDialog based on the user's mouse click position,
   * ensuring that the dialog always fits within the visible window boundaries. It adjusts the position
   * of the dialog if it goes beyond the window's width or height.
   *
   * @param {number} dialogWidth - The width of the dialog to be displayed.
   * @param {number} dialogHeight - The height of the dialog to be displayed.
   * @param {MouseEvent} mouseEvent - The mouse event that triggers the dialog. Provides clientX and clientY for positioning.
   * @returns {MatDialogConfig} dialogConfig - The configuration object for the MatDialog, with adjusted top and left positions.
   */
  static getMatDialogConfigOpenAtPointer(dialogWidth: number, dialogHeight: number, mouseEvent: MouseEvent) {
    // Get window's width and height
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate position
    let positionX = mouseEvent.clientX;
    let positionY = mouseEvent.clientY;

    // Adjust X position if the dialog goes out of the window
    if (positionX + dialogWidth > windowWidth) {
      positionX = windowWidth - dialogWidth;
    }

    // Adjust Y position if the dialog goes out of the window
    if (positionY + dialogHeight > windowHeight) {
      positionY = windowHeight - dialogHeight;
    }

    // MatDialogConfig
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {top: `${positionY}px`, left: `${positionX}px`};
    dialogConfig.width = `${dialogWidth}px`;
    dialogConfig.height = `${dialogHeight}px`;
    return dialogConfig;
  }

  /**
   * Creates and returns a configuration object for a MatDialog instance.
   * This configuration will ensure that the dialog opens centered on the screen
   * with the specified width and height.
   *
   * @param {number} dialogWidth - The width of the dialog in pixels.
   * @param {number} dialogHeight - The height of the dialog in pixels.
   * @returns {MatDialogConfig} An instance of MatDialogConfig with the specified dimensions.
   */
  static getMatDialogConfigOpenCentered(dialogWidth: number, dialogHeight: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = `${dialogWidth}px`;
    dialogConfig.height = `${dialogHeight}px`;
    return dialogConfig;
  }
}

export function memoize() {
  return function(target: any, key: any, descriptor: any) {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction);
    descriptor.value = function() {
      return newFunction.apply(this, arguments);
    };
  };
};
