import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-trade-realized-view',
  templateUrl: './trade-realized-view.component.html',
  styleUrls: ['./trade-realized-view.component.scss'],
})
export class TradeRealizedViewComponent implements OnInit {

  @Input() realized = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
