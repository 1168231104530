import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {StatisticalReturnViewType} from '../../../shared/types/statisticalReturnViewType.type';
import {Observable} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {
  selectBestStatisticalReturnSearchMinAverageRelativeProfit,
  selectUpcomingStatisticalReturnSearchMinAverageRelativeProfit,
} from '../../../store/stock.selectors';
import {MinAverageRelativeProfitEditDialogComponent} from './min-average-relative-profit-edit-dialog/min-average-relative-profit-edit-dialog.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-min-average-relative-profit-filter',
  templateUrl: './min-average-relative-profit-filter.component.html',
  styleUrls: ['./min-average-relative-profit-filter.component.scss'],
})
export class MinAverageRelativeProfitFilterComponent extends BaseComponent implements OnInit {

  @Input() viewType?: StatisticalReturnViewType;

  isFilterActive = false;

  minAverageRelativeProfit$?: Observable<number | undefined>;

  private editDialog?: MatDialogRef<any, any>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.viewType === 'upcoming') {
      this.minAverageRelativeProfit$ = this.store.select(selectUpcomingStatisticalReturnSearchMinAverageRelativeProfit);
    }
    if (this.viewType === 'best') {
      this.minAverageRelativeProfit$ = this.store.select(selectBestStatisticalReturnSearchMinAverageRelativeProfit);
    }

    this.minAverageRelativeProfit$?.pipe(takeUntil(this.destroy$)).subscribe(minAverageRelativeProfit => {
      if (minAverageRelativeProfit)
        this.isFilterActive = true;
      else
        this.isFilterActive = false;
    });
  }

  openEditDialog(mouseEvent: MouseEvent): void {
    this.editDialog = this.statisticalTradingService.openEditFilterDialog(MinAverageRelativeProfitEditDialogComponent, $localize`Min. average profit in percent`, this.viewType, undefined, 400);
  }

}
