import {MangopayBankAccountType} from '../app/shared/enums/mangopayBankAccountType.enum';
import packageJson from '../../package.json';
import {PaymentProvider} from '../app/shared/enums/paymentProvider';

export const environment = {
  production: false,

  // -------------------------------------------------------------------------------------------------
  // Basic app settings
  // -------------------------------------------------------------------------------------------------

  applicationRootUrl: 'https://dev.stockforecast.de',
  version: packageJson.version,
  // The suffix to be added to the html title attribute, e.g. resulting in 'Weber Barbecue station | Stock Forecast'
  defaultTitleSuffix: ' | dev - Stock Forecast',
  defaultTitle: 'dev - Stock Forecast',

  // -------------------------------------------------------------------------------------------------
  // Backend API settings
  // -------------------------------------------------------------------------------------------------

  backend_api_path: 'https://backend-dev.stockforecast.de/rest/',
  backend_api_public_key: 'ijhPlTKFyAYsP1gSDCkgBC7yxCApDynhv91kE70WY587a0b5SpfMpFQPK1ZMGciX9odRNuacnM8GDeIpPCdoBxmHFh2jHQWHzNCvFB9cKKannlB20MLsexRZSGp3r7L2',
  backend_api_secret_key_secret_name: 'stock_forecast_backend_api_secret_key',
  backend_api_request_exchanges_all: 'exchanges/all',
  backend_api_request_symbol: 'symbol',
  backend_api_request_development_since_buy_day: 'development-since-buy-day',
  backend_api_request_price_on_day: 'price-on-day',
  backend_api_request_prices_on_day: 'prices-on-day',
  backend_api_request_refresh_symbol: 'refresh-symbol',
  backend_api_request_refresh_symbols: 'refresh-symbols',
  backend_api_request_company_profile: 'company-profile',
  backend_api_request_symbols_find: 'symbols/find',
  backend_api_request_analyze: 'analyze',
  backend_api_request_optimize_trading_plan: 'optimize-trading-plan',
  backend_api_request_delete_old_statistical_returns: 'delete-old-statistical-returns',

  // -------------------------------------------------------------------------------------------------
  // Default fetch counts (firestore, backend)
  // -------------------------------------------------------------------------------------------------

  // default number of statistical returns to be searched (loaded from the firestore)
  defaultLoadStatisticalReturnsCount: 10,
  // default number of statistical returns to be searched (loaded from the firestore)
  defaultLoadUpcomingStatisticalReturnsCount: 50,
  // default number of announcements to be loaded from the server
  defaultLoadAnnouncementsCount: 10,
  // default number of FAQs to be loaded from the server
  defaultLoadFaqsCount: 10,
  // default number of messages to be loaded from the server
  defaultLoadMessagesCount: 10,
  // default number of conversations to be loaded from the server
  defaultLoadConversationsCount: 10,
  // default number of notifications to be loaded from the server
  defaultLoadNotificationsCount: 10,
  // default number of reports to be loaded from the server
  defaultLoadReportsCount: 10,
  // default number of public user documents to be loaded from the server
  defaultLoadUserUserPublicCount: 5,
  // default number of symbols to be fetched from the backend
  defaultSymbolSearchCount: 10,
  // default number of schedules to be fetched from the firestore
  defaultLoadSchedulesCount: 10,
  // default number of statistical simulations to be fetched from the firestore
  defaultSimulationSearchCount: 15,

  // -------------------------------------------------------------------------------------------------
  // Default cache durations (time, after which a cached entry should be refetched)
  // -------------------------------------------------------------------------------------------------

  defaultAnnouncementCacheAgeInSec: 30,
  defaultFaqCacheAgeInSec: 30,
  defaultUserCacheAgeInSec: 5,
  defaultUserPublicCacheAgeInSec: 30,
  defaultListingCacheAgeInSec: 30,
  defaultListingPrivateCacheAgeInSec: 30,
  defaultCategoryCacheAgeInSec: 30,
  defaultCurrencyCacheAgeInSec: 30,
  defaultScheduleCacheAgeInSec: 600,
  defaultSymbolCacheAgeInSec: 864000, // 10 days
  defaultSimulationResultCacheAgeInSec: 2592000, // 30 days
  defaultCompanyProfileCacheAgeInSec: 600, // 10 minutes
  defaultExchangeCacheAgeInSec: 2592000, // 30 days
  defaultStatisticalReturnCacheAgeInSec: 2592000, // 30 days
  defaultStatisticalTradingPlanCacheAgeInSec: 43200, // 12 hours
  defaultDevelopmentSinceBuyDayCacheAgeInSec: 600, // 10 minutes
  defaultSymbolPriceOnBuyDayCacheAgeInSec: 2592000, // 30 days
  symbolRefreshCooldownInSec: 600, // 10 minutes

  // -------------------------------------------------------------------------------------------------
  // Firebase config
  // -------------------------------------------------------------------------------------------------

  firebaseConfig: {
    apiKey: 'AIzaSyABnWieM0KO5FHZpIhx-yzOCAgJmo7BF6A',
    authDomain: 'stockforecast-dev.firebaseapp.com',
    databaseURL: 'https://stockforecast-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'stockforecast-dev',
    storageBucket: 'stockforecast-dev.appspot.com',
    publicStorageBucket: 'stockforecast-dev-public',
    messagingSenderId: '73304065734',
    appId: '1:73304065734:web:2b2dc7c20ac1f4d70ef9c7',
    measurementId: 'G-LQEX83YRBR',
    regionName: 'europe-west3',
  },
  // fallback language for the firestore, if the selected firestoreLocale is not available (e.g. for categories and currencies).
  // defaultFirestoreLocale should always be 'en'. The interfaces require 'en'-entries, but don't require any other locales.
  defaultFirestoreLocale: 'en',

  // -------------------------------------------------------------------------------------------------
  // Firestore collection names
  // -------------------------------------------------------------------------------------------------

  firestoreCollectionCurrencies: 'currencies',
  firestoreCollectionFaqs: 'faqs',
  firestoreCollectionListings: 'listings',
  firestoreCollectionMails: 'mail',
  firestoreCollectionNotifications: 'notifications',
  firestoreCollectionSchedules: 'schedules',
  firestoreCollectionStatisticalReturns: 'statisticalReturns',
  firestoreCollectionStatisticalSimulationResults: 'statisticalSimulationResults',
  firestoreCollectionStatisticalTradingPlans: 'statisticalTradingPlans',
  firestoreCollectionSymbols: 'symbols',
  firestoreCollectionUsers: 'users',
  firestoreCollectionUsersPublic: 'usersPublic',

  // -------------------------------------------------------------------------------------------------
  // Email templates
  // -------------------------------------------------------------------------------------------------

  // Document name of the admin notify template (used, e.g. if an admin deletes a listing)
  mailTemplateAdminNotify: 'adminNotify',
  // Document name of the messages template
  mailTemplateMessage: 'message',
  // Document name of the notification template
  mailTemplateNotification: 'notification',
  // Document name of the reminder template
  mailTemplateReminder: 'reminder',
  // Document name of the validationResult template
  mailTemplateValidationResult: 'validationResult',
  // Document name of the report template
  mailTemplateReport: 'report',
  // Document name of the contentRemovedByAdmin template
  mailTemplateContentRemovedByAdmin: 'contentRemovedByAdmin',

  // User UIDs of all users, that should receive report emails
  reportReceiverUids: ['SiXmJBpn7ghJh6PiGX3DFRZPu922'],

  // -------------------------------------------------------------------------------------------------
  // Storage paths
  // -------------------------------------------------------------------------------------------------

  // Root directory for listing images in fire storage. Each listing gets its own subdirectory, whose name is the listingId
  listingImagesPath: 'img/listings',
  // Root directory for category images in fire storage.
  categoryImagesPath: 'img/categories',
  // Root directory for user images in fire storage.
  userImagesPath: 'img/users',
  // Root directory for user images in fire storage.

  publicImgPath: 'https://storage.googleapis.com/stockforecast-dev-public/img/',

  // -------------------------------------------------------------------------------------------------
  // Misc. API keys
  // -------------------------------------------------------------------------------------------------

  algoliaAppId: 'GG9L47ELVY',
  algoliaSearchKey: '9dfd81a9329a79f6ef2914982ebc6a4f',
  algoliaListingsIndex: 'dev_listings',

  geoCodingApiKey: 'AIzaSyAHLWvRUoU78ItWTICsAjNoqOx4ccVhreU',
  translationApiKey: 'AIzaSyDYF9Mb9Pe9CzwbOn1R90AT4h6WDjJFE6k',

  // -------------------------------------------------------------------------------------------------
  // Payment
  // -------------------------------------------------------------------------------------------------

  activePaymentProviders: [PaymentProvider.Mangopay],

  // -------------------------------------------------------------------------------------------------
  // Stripe
  // -------------------------------------------------------------------------------------------------

  stripeEnvironmentMode: 'test',
  stripeLinkAccountRefreshUrl: 'http://localhost:4200/account/payment-account?refresh=true',
  stripeLinkAccountRedirectUrl: 'http://localhost:4200/account/payment-account',

  // -------------------------------------------------------------------------------------------------
  // Mangopay
  // -------------------------------------------------------------------------------------------------

  mangopayEnvironmentMode: 'live',
  mangopayCurrenciesByPaymentMethod: {
    'CB_VISA_MASTERCARD': ['EUR', 'GBP', 'USD', 'CHF', 'PLN', 'NOK', 'SEK', 'DKK', 'CAD', 'AUD'],
    'DINERS': ['EUR'],
    'MAESTRO': ['EUR'],
    'P24': ['PLN'],
    'IDEAL': ['EUR'],
    'BCMC': ['EUR'],
    'PAYLIB': ['EUR'],
    'GIROPAY': ['EUR'],
    'SOFORT': ['EUR'],
    'BANK_WIRE': ['*'],
  },
  mangopayEscrowLegalUser: '111205288',
  mangopayEscrowWallets: {'EUR': '111205517', 'USD': '111205639', 'GBP': '111206114', 'PLN': '111206122'},
  defaultBankAccountType: MangopayBankAccountType.IBAN,
  mangopayFixedFees: {'EUR': 30, 'USD': 30, 'GBP': 30, 'PLN': 120}, // in cents, pennies etc.
  transferFeePercentage: 0.055,

  // -------------------------------------------------------------------------------------------------
  // Facebook
  // -------------------------------------------------------------------------------------------------

  facebookCatalogId: 265639308896209,

  // -------------------------------------------------------------------------------------------------
  // Misc
  // -------------------------------------------------------------------------------------------------

  baseCurrency: 'USD',
  vatPercentage: 0.19,
  // Determines, how big the rectangle on the map is, which is shown for a listing. 7 means:	≤ 153m	×	153m. See https://www.movable-type.co.uk/scripts/geohash.html
  geohashPrecision: 7,
  // The number of seconds before the target item pickup or return time, when the buttons 'mark as picked up' and 'mark as returned' become active
  itemPickupReturnToleranceSec: 21600,
  // Suffix to be put in the filename of generated thumbnails before uploading them
  thumbnailSuffix: 'thumb',
  // This is either the width or the height, whichever is smaller, in which thumbnails are created to be uploaded.
  // Thumbnails will have a minimum width and height of 300px.
  thumbnailSize: 300,
  maxImagesCount: 15,
  // Default latitude for users not sharing their location
  defaultLatitude: 48.1498353,
  // Default longitude for users not sharing their location
  defaultLongitude: 11.5914705,
  usExchanges: ['AMEX', 'ASE', 'NYSE', 'NASDAQ'],
  deExchanges: ['HAM', 'STU', 'XETRA'],

  // -------------------------------------------------------------------------------------------------
  // Trading and stock properties
  // -------------------------------------------------------------------------------------------------

  // The maximum number of days between buying and selling for a statistical trade
  maxNumberOfStockHoldingDays: 31,
  // The minimum average yearly relative return of a statistical trade for any period to save it (lower returns will be neglected)
  minRelativeReturnAnyPeriod: 0.06,
  // The minimum average yearly relative return of a statistical trade for any period to save it (lower returns will be neglected)
  minRelativeReturnAllTime: 0.03,
  // Observation periods for statistical trades in years
  periodsInYears: [5, 10, 15, 20, 25, 30],
  // The percentage at which annual relative profits are truncated
  relativeProfitTruncateThreshold: 0.10,

  // -------------------------------------------------------------------------------------------------
  // Debugging
  // -------------------------------------------------------------------------------------------------

  enableReducerLogging: false,

};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
