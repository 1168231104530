<ng-container
        *ngIf="year !== undefined && statRet !== undefined && statRet.profitByYearMap !== undefined && statRet.profitByYearMap[year] !== undefined; else none">
  <a (click)="showTradePrice(year,statRet)" [class]="statRet.profitByYearMap![year]! | valueClassPipe">
    <app-percentage-view [value]="statRet.profitByYearMap![year]!"></app-percentage-view>
  </a>
</ng-container>

<ng-template #none>
  -
</ng-template>
