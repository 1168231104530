<div *ngIf="layout == 'toolbar'">
  <input #searchTermInputToolbar (keyup)="searchInput.next(searchTermInputToolbar.value)" (paste)="searchInput.next(searchTermInputToolbar.value)"
         (search)="setSearchTermAndSearch(searchTermInputToolbar.value)" [value]="(searchTerm$ | async) ? (searchTerm$ | async) : ''"
         aria-label="Search" class="form-control mr-sm-2 search-input" i18n-aria-label="Search"
         i18n-placeholder="header search input field placeholder text|The placeholder text of the search field in the site header" id="searchTermHeader"
         placeholder="Search" type="search">
</div>

<div *ngIf="layout == 'full'" class="row">
  <div class="col-auto">
    <mat-form-field class="mat-form-input">
      <mat-label i18n="symbol name">Symbol name</mat-label>
      <input #searchTermInputFull (keyup)="searchInput.next(searchTermInputFull.value)" (paste)="searchInput.next(searchTermInputFull.value)"
             (search)="setSearchTermAndSearch(searchTermInputFull.value)"
             [value]="(searchTerm$ | async) ? (searchTerm$ | async) : ''" autocapitalize="off" autocomplete="off" autocorrect="off"
             i18n-placeholder="symbol name" id="searchTerm" matInput placeholder="Symbol name" required
             spellcheck="false" type="text">
      <mat-icon (click)="setSearchTermAndSearch('')" *ngIf="searchTermInputFull.value" aria-label="Clear" i18n-matTooltip
                matSuffix matTooltip="Clear" type="button">close
      </mat-icon>
    </mat-form-field>
  </div>
  <div class="col-auto">
    <app-exchange-selector (onExchangeSelected)="selectExchangeAndSearch($event?.shortName)"
                           [selectedExchangeShortName]="selectedExchange$ | async"
                           [bigButton]="true" buttonColor="default"
                           [showAllExchanges]="true"></app-exchange-selector>
  </div>
  <div class="col-auto">

    <app-mat-fab-search-button (click)="search()"></app-mat-fab-search-button>
  </div>
</div>
