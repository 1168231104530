import {Injectable} from '@angular/core';

@Injectable()
export class AppInitService {

  constructor() {
  }

  init() {

    return new Promise<void>((resolve, reject) => {
      // Do initialisation stuff here
      // Loading of the app will only continue when resolve() is called
      resolve();
    });
  }
}
