import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {StatisticalReturnViewType} from '../../../shared/types/statisticalReturnViewType.type';
import {Observable} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {selectBestStatisticalReturnSearchMinHighestRise, selectUpcomingStatisticalReturnSearchMinHighestRise} from '../../../store/stock.selectors';
import {MinHighestRiseEditDialogComponent} from './min-highest-rise-edit-dialog/min-highest-rise-edit-dialog.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-min-highest-rise-filter',
  templateUrl: './min-highest-rise-filter.component.html',
  styleUrls: ['./min-highest-rise-filter.component.scss'],
})
export class MinHighestRiseFilterComponent extends BaseComponent implements OnInit {

  @Input() viewType?: StatisticalReturnViewType;

  isFilterActive = false;

  minHighestRise$?: Observable<number | undefined>;

  private editDialog?: MatDialogRef<any, any>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.viewType === 'upcoming') {
      this.minHighestRise$ = this.store.select(selectUpcomingStatisticalReturnSearchMinHighestRise);
    }
    if (this.viewType === 'best') {
      this.minHighestRise$ = this.store.select(selectBestStatisticalReturnSearchMinHighestRise);
    }

    this.minHighestRise$?.pipe(takeUntil(this.destroy$)).subscribe(minHighestRise => {
      if (minHighestRise)
        this.isFilterActive = true;
      else
        this.isFilterActive = false;
    });
  }

  openEditDialog(mouseEvent: MouseEvent): void {
    this.editDialog = this.statisticalTradingService.openEditFilterDialog(MinHighestRiseEditDialogComponent, $localize`Min. highest rise (%)`, this.viewType, undefined, 350);
  }

}
