import {NgModule} from '@angular/core';
import {DropdownDirective} from './directives/dropdown.directive';
import {PlaceholderDirective} from './directives/placeholder.directive';
import {AlertComponent} from './components/alert/alert.component';
import {BsStarRatingComponent} from './components/bs-star-rating/bs-star-rating.component';
import {StarRatingModule} from 'angular-star-rating';
import {ShortenPipe} from './pipes/shorten.pipe';
import {ImageModalContentComponent} from './components/image-modal-content/image-modal-content.component';
import {ImageCarouselModalContentComponent} from './components/image-carousel-modal-content/image-carousel-modal-content.component';
import {NgbCarouselModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {MoneyComponent} from './components/money/money.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {MomentPipe} from './pipes/moment.pipe';
import {NotLoggedInComponent} from './components/not-logged-in/not-logged-in.component';
import {MatButtonModule} from '@angular/material/button';
import {ZeroToNullPipe} from './pipes/zeroToNull.pipe';
import {HttpClientModule} from '@angular/common/http';
import {LteDirective} from './directives/validation/lte.directive';
import {GteDirective} from './directives/validation/gte.directive';
import {RangeDirective} from './directives/validation/range.directive';
import {MatIconModule} from '@angular/material/icon';
import {InfoCardComponent} from './components/info-card/info-card.component';
import {MatIconButtonComponent} from './components/mat-icon-button/mat-icon-button.component';
import {MapComponent} from './components/map/map.component';
import {AgmCoreModule} from '@agm/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageUploaderComponent} from './components/image-uploader/image-uploader.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropzoneDirective} from './directives/dropzone.directive';
import {PreviewModalComponent} from './components/image-uploader/cropper/preview-modal/preview-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CropperComponent} from './components/image-uploader/cropper/cropper.component';
import {UploadTaskComponent} from './components/image-uploader/upload-task/upload-task.component';
import {FilesizePipe} from './pipes/filesize.pipe';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {BaseComponent} from './components/base/base.component';
import {FocusAndSelectDirective} from './directives/focus-and-select.directive';
import {ElementScrollPercentageDirective} from './directives/element-scroll-percentage.directive';
import {NotEmptyDirective} from './directives/validation/not-empty.directive';
import {UserThumbComponent} from './components/user-thumb/user-thumb.component';
import {RouterModule} from '@angular/router';
import {AlertsComponent} from './components/alerts/alerts.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {DefaultImageDirective} from './directives/default-image.directive';
import {PublicAddressComponent} from './components/public-address/public-address.component';
import {FileSelectorComponent} from './components/file-selector/file-selector.component';
import {MatCardModule} from '@angular/material/card';
import {MangopayRefusedReasonTypePipe} from './pipes/mangopay-refused-reason-type.pipe';
import {MangopayKycDocumentStatusPipe} from './pipes/mangopay-kyc-document-status.pipe';
import {MatTreeModule} from '@angular/material/tree';
import {MatChipsModule} from '@angular/material/chips';
import {HighlightSearchPipe} from './pipes/highlight-search.pipe';
import {YesNoComponent} from './components/yes-no/yes-no.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AddressTableComponent} from './components/address-table/address-table.component';
import {AddressBarMapErrorsComponent} from './components/address-bar-map-errors/address-bar-map-errors.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RoundPipe} from './pipes/round.pipe';
import {ThumbnailWidgetComponent} from './components/thumbnail-widget/thumbnail-widget.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ImportExportModalContentComponent} from './components/import-export-modal-content/import-export-modal-content.component';
import {SymbolSelectorComponent} from './components/symbol-selector/symbol-selector.component';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SymbolDescriptorComponent} from './components/symbol-descriptor/symbol-descriptor.component';
import {PleaseWaitLoadingComponent} from './components/please-wait-loading/please-wait-loading.component';
import {PageHeadlineComponent} from './components/page-headline/page-headline.component';
import {ExchangeDescriptorComponent} from './components/exchange-descriptor/exchange-descriptor.component';
import {DateViewComponent} from './components/date-view/date-view.component';
import {SymbolSearchTableComponent} from './components/symbol-search-table/symbol-search-table.component';
import {ExchangeSelectorComponent} from './components/exchange-selector/exchange-selector.component';
import {CompanyProfileViewComponent} from './components/company-profile-view/company-profile-view.component';
import {CompanyProfileViewAccordionComponent} from './components/company-profile-view-accordion/company-profile-view-accordion.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {SymbolsExchangeSelectorComponent} from './components/symbols-exchange-selector/symbols-exchange-selector.component';
import {SymbolQuickInfoComponent} from './components/symbol-quick-info/symbol-quick-info.component';
import {StockSearchPanelComponent} from './components/stock-search-panel/stock-search-panel.component';
import {PercentageViewComponent} from './components/percentage-view/percentage-view.component';
import {NumberViewComponent} from './components/number-view/number-view.component';
import {WatchListStarComponent} from './components/watch-list-star/watch-list-star.component';
import {SortHeaderComponent} from './components/sort-header/sort-header.component';
import {StockTypeComponent} from './components/company-profile-view/stock-type/stock-type.component';
import {TextModalComponent} from './components/text-modal/text-modal.component';
import {MatFabSearchButtonComponent} from './components/mat-fab-search-button/mat-fab-search-button.component';
import {ValueClassPipe} from './pipes/value-class-pipe.pipe';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {CurrencySelectorComponent} from './components/currency-selector/currency-selector.component';
import {MatSelectModule} from '@angular/material/select';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ValueClassWinRatioPipe} from './pipes/value-class-win-ratio-pipe.pipe';
import {BaseDialogComponent} from './components/base-dialog/base-dialog.component';
import {SmallEditButtonComponent} from './components/small-edit-button/small-edit-button.component';

@NgModule({
  declarations: [
    DropdownDirective,
    PlaceholderDirective,
    AlertComponent,
    BsStarRatingComponent,
    ShortenPipe,
    ZeroToNullPipe,
    MomentPipe,
    ImageModalContentComponent,
    ImageCarouselModalContentComponent,
    MoneyComponent,
    LoadingSpinnerComponent,
    NotLoggedInComponent,
    LteDirective,
    GteDirective,
    RangeDirective,
    InfoCardComponent,
    MatIconButtonComponent,
    MapComponent,
    ImageUploaderComponent,
    DropzoneDirective,
    UploadTaskComponent,
    CropperComponent,
    PreviewModalComponent,
    FilesizePipe,
    ConfirmDialogComponent,
    BaseComponent,
    FocusAndSelectDirective,
    ElementScrollPercentageDirective,
    ImportExportModalContentComponent,
    NotEmptyDirective,
    UserThumbComponent,
    AlertsComponent,
    DefaultImageDirective,
    PublicAddressComponent,
    FileSelectorComponent,
    MangopayRefusedReasonTypePipe,
    MangopayKycDocumentStatusPipe,
    HighlightSearchPipe,
    YesNoComponent,
    AddressTableComponent,
    AddressBarMapErrorsComponent,
    RoundPipe,
    ThumbnailWidgetComponent,
    SymbolSelectorComponent,
    SymbolDescriptorComponent,
    PleaseWaitLoadingComponent,
    PageHeadlineComponent,
    ExchangeDescriptorComponent,
    DateViewComponent,
    SymbolSearchTableComponent,
    ExchangeSelectorComponent,
    CompanyProfileViewComponent,
    CompanyProfileViewAccordionComponent,
    SymbolQuickInfoComponent,
    SymbolsExchangeSelectorComponent,
    StockSearchPanelComponent,
    PercentageViewComponent,
    NumberViewComponent,
    WatchListStarComponent,
    SortHeaderComponent,
    StockTypeComponent,
    TextModalComponent,
    MatFabSearchButtonComponent,
    ValueClassPipe,
    BreadcrumbComponent,
    CurrencySelectorComponent,
    ProgressBarComponent,
    ValueClassWinRatioPipe,
    BaseDialogComponent,
    SmallEditButtonComponent,
  ],
  imports: [
    HttpClientModule,
    StarRatingModule,
    NgbCarouselModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AgmCoreModule,
    MatDialogModule,
    ImageCropperModule,
    FormsModule,
    DragDropModule,
    RouterModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    NgbDropdownModule,
    MatTreeModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatListModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatSelectModule,
    MatProgressBarModule,
  ],
  exports: [DropdownDirective,
    PlaceholderDirective,
    AlertComponent,
    BsStarRatingComponent,
    ZeroToNullPipe,
    ShortenPipe,
    MomentPipe,
    ImageModalContentComponent,
    ImageCarouselModalContentComponent,
    MoneyComponent,
    LoadingSpinnerComponent,
    NotLoggedInComponent,
    LteDirective,
    GteDirective,
    RangeDirective,
    InfoCardComponent,
    MatIconButtonComponent,
    ImportExportModalContentComponent,
    MapComponent,
    ImageUploaderComponent,
    ImageCropperModule,
    FocusAndSelectDirective,
    NotEmptyDirective,
    UserThumbComponent,
    AlertsComponent,
    DefaultImageDirective,
    PublicAddressComponent,
    FileSelectorComponent,
    MangopayKycDocumentStatusPipe,
    MangopayRefusedReasonTypePipe,
    HighlightSearchPipe,
    YesNoComponent,
    AddressTableComponent,
    AddressBarMapErrorsComponent,
    RoundPipe,
    ThumbnailWidgetComponent,
    SymbolSelectorComponent,
    SymbolDescriptorComponent,
    PleaseWaitLoadingComponent,
    PageHeadlineComponent,
    ExchangeDescriptorComponent,
    DateViewComponent,
    SymbolSearchTableComponent,
    ExchangeSelectorComponent,
    CompanyProfileViewComponent,
    CompanyProfileViewAccordionComponent,
    SymbolQuickInfoComponent,
    SymbolsExchangeSelectorComponent,
    StockSearchPanelComponent,
    PercentageViewComponent,
    NumberViewComponent,
    WatchListStarComponent,
    SortHeaderComponent, MatFabSearchButtonComponent, ValueClassPipe, BreadcrumbComponent, CurrencySelectorComponent, ProgressBarComponent, ValueClassWinRatioPipe, BaseDialogComponent, SmallEditButtonComponent,
  ],
})
export class SharedModule {
}
