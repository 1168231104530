import {Component, Input, OnInit} from '@angular/core';

import {Symbol} from '../../models/symbol.interface';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {StockService} from '../../services/stock.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-symbol-descriptor',
  templateUrl: './symbol-descriptor.component.html',
  styleUrls: ['./symbol-descriptor.component.scss'],
})
export class SymbolDescriptorComponent extends BaseComponent implements OnInit {

  @Input() nameOnly = false;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public stockService: StockService) {
    super(authService, userService, store);
  }

  _symbol?: Symbol = undefined;

  get symbol(): Symbol | undefined {
    return this._symbol;
  }

  @Input() set symbol(symbol: Symbol | undefined) {
    this._symbol = symbol;
  }

  _symbolCode?: string = undefined;

  get symbolCode(): string | undefined {
    return this._symbolCode;
  }

  @Input() set symbolCode(symbolCode: string | undefined) {
    this._symbolCode = symbolCode;
    if (symbolCode && !this.symbol)
      this.fetchSymbol(symbolCode);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  fetchSymbol(symbolCode: string) {
    this.stockService.fetchSymbolFromBackend(symbolCode).then(wrapper => {
      if (wrapper.data)
        this.symbol = wrapper.data;
      if (wrapper.errorMessage) {
        this.stockService.fetchSymbolFromFirestore(symbolCode).then(wrapper => {
          if (wrapper.data)
            this.symbol = wrapper.data;
          if (wrapper.errorMessage)
            this.addError(wrapper.errorMessage);
        });
      }
    });
  }


}
