import {Component, Input, OnInit} from '@angular/core';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';
import {TradePriceModalComponent} from '../../../trade-price-modal/trade-price-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-year-data',
  templateUrl: './year-data.component.html',
  styleUrls: ['./year-data.component.scss'],
})
export class YearDataComponent implements OnInit {

  @Input() year?: string;
  @Input() statRet?: StatisticalReturn;

  constructor(
      private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  showTradePrice(year: string, statRet: StatisticalReturn) {
    const modalRef = this.modalService.open(TradePriceModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.statRet = statRet;
    modalRef.componentInstance.year = year;
  }

}
