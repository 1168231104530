<div #exchangeSelectorDropdown="ngbDropdown" class="d-inline-block always-on-top"
     container="body" ngbDropdown
     (openChange)="onDropDownOpen()">
  <button [ngClass]="{ 'narrow-button': narrowButton, 'big-button': bigButton}" color="{{buttonColor}}"
          id="exchangesDropdown" mat-raised-button ngbDropdownToggle appFocusAndSelect
          type="button">
    <span *ngIf="!selectedExchangeShortName && showAllExchanges" i18n>All exchanges</span>
    <span *ngIf="!selectedExchangeShortName && !showAllExchanges" i18n>Select exchange</span>
    <span *ngIf="selectedExchange">{{selectedExchange.shortName}}: {{selectedExchange.name}}</span>
  </button>
  <div aria-labelledby="exchangesDropdown" ngbDropdownMenu>
    <div class="dropdown-content">

      <!-- All exchanges button -->
      <div *ngIf="showAllExchanges" [ngClass]="{ 'selected': !selectedExchangeShortName }">
        <button (click)="onSelectExchange(undefined)" class="exchange-button all-exchanges-button">
          <span i18n>All exchanges</span>
        </button>
      </div>

      <div *ngIf="showAllExchanges" class="dropdown-divider"></div>

      <input #search (cancel)="filter(search.value)" (emptied)="filter(search.value)"
             (keyup)="filter(search.value)" (paste)="filter(search.value)"
             (reset)="filter(search.value)" (keydown.enter)="onEnterKeyPressed()"
             (search)="filter(search.value)"
             aria-label="Search"
             class="form-control mb-2 search-input"
             i18n-placeholder="exchange selector search input field placeholder text|The placeholder text of the search field in the exchange selector"
             id="searchTermExchange"
             placeholder="Search" type="search">


      <div *ngFor="let exchange of filteredExchanges">
        <button (click)="onSelectExchange(exchange)" class="exchange-button" type="button">
          {{exchange.shortName}}: {{exchange.name}}
        </button>
      </div>

    </div>
  </div>
</div>
