<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="row">
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="buyDate label">Buy date</mat-label>
        <input [matDatepicker]="pickerBuyDate" formControlName="buyDate" matInput (dateChange)="onBuyDateChanged($event)">
        <mat-datepicker-toggle [for]="pickerBuyDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #pickerBuyDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="sellDate label">Sell date</mat-label>
        <input [matDatepicker]="pickerSellDate" formControlName="sellDate" matInput (dateChange)="onSellDateChanged($event)">
        <mat-datepicker-toggle [for]="pickerSellDate" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #pickerSellDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="buy Price label">Buy price</mat-label>
        <input class="text-align-right" formControlName="buyPrice" matInput type="number" (keyup)="calcDerivedValues()" (change)="calcDerivedValues()">
        <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && currencyId" matSuffix
              style="position: relative; bottom: 4px;">&nbsp; {{currenciesByIdCache?.get(currencyId)?.symbol}}</span>
        <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && currencyId" matPrefix
              style="position: relative; bottom: 4px;">{{currenciesByIdCache?.get(currencyId)?.symbol}} &nbsp;</span>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="sell Price label">Sell price</mat-label>
        <input class="text-align-right" formControlName="sellPrice" matInput type="number" (keyup)="calcDerivedValues()" (change)="calcDerivedValues()">
        <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && currencyId" matSuffix
              style="position: relative; bottom: 4px;">&nbsp; {{currenciesByIdCache?.get(currencyId)?.symbol}}</span>
        <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && currencyId" matPrefix
              style="position: relative; bottom: 4px;">{{currenciesByIdCache?.get(currencyId)?.symbol}} &nbsp;</span>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="Profit gross label">Profit gross</mat-label>
        <input class="text-align-right" formControlName="profitAbsoluteGross" matInput type="number">
        <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && currencyId" matSuffix
              style="position: relative; bottom: 4px;">&nbsp; {{currenciesByIdCache?.get(currencyId)?.symbol}}</span>
        <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && currencyId" matPrefix
              style="position: relative; bottom: 4px;">{{currenciesByIdCache?.get(currencyId)?.symbol}} &nbsp;</span>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="Profit gross label">Profit gross</mat-label>
        <input class="text-align-right" formControlName="profitRelativeGross" matInput type="number">
        <span matSuffix style="position: relative; bottom: 4px;">&nbsp; %</span>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="order fee label">Order fee</mat-label>
        <input class="text-align-right" formControlName="orderFeeAbsolute" matInput type="number" (keyup)="calcDerivedValues()">
        <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && currencyId" matSuffix
              style="position: relative; bottom: 4px;">&nbsp; {{currenciesByIdCache?.get(currencyId)?.symbol}}</span>
        <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && currencyId" matPrefix
              style="position: relative; bottom: 4px;">{{currenciesByIdCache?.get(currencyId)?.symbol}} &nbsp;</span>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="tax label">Tax</mat-label>
        <input class="text-align-right" formControlName="taxAbsolute" matInput type="number" (keyup)="calcDerivedValues()">
        <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && currencyId" matSuffix
              style="position: relative; bottom: 4px;">&nbsp; {{currenciesByIdCache?.get(currencyId)?.symbol}}</span>
        <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && currencyId" matPrefix
              style="position: relative; bottom: 4px;">{{currenciesByIdCache?.get(currencyId)?.symbol}} &nbsp;</span>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="Profit net label">Profit net</mat-label>
        <input class="text-align-right" formControlName="profitAbsoluteNet" matInput type="number">
        <span *ngIf="getCurrencySuffixOrPrefix() === 'suffix' && currencyId" matSuffix
              style="position: relative; bottom: 4px;">&nbsp; {{currenciesByIdCache?.get(currencyId)?.symbol}}</span>
        <span *ngIf="getCurrencySuffixOrPrefix() === 'prefix' && currencyId" matPrefix
              style="position: relative; bottom: 4px;">{{currenciesByIdCache?.get(currencyId)?.symbol}} &nbsp;</span>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="Profit net label">Profit net</mat-label>
        <input class="text-align-right" formControlName="profitRelativeNet" matInput type="number">
        <span matSuffix style="position: relative; bottom: 4px;">&nbsp; %</span>
      </mat-form-field>
    </div>
  </div>

</form>
