import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-headline',
  templateUrl: './page-headline.component.html',
  styleUrls: ['./page-headline.component.scss']
})
export class PageHeadlineComponent implements OnInit {

  constructor() {
  }

  @Input() headline = ""

  ngOnInit(): void {
  }

}
