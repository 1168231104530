import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {Exchange} from '../shared/models/exchange.interface';
import {initialState, SimulationSearch, SimulationState} from './simulation.state';
import {
  addSimulationResultToCache,
  addSimulationSearchExchangeShortName,
  clearSimulationResultCache,
  fetchMoreSimulationSearchResults,
  fetchSimulationSearchResults,
  removeSimulationSearchExchangeShortName,
  resetSimulationSearch,
  setSimulationSearchExchangeShortNames,
  setSimulationSearchParams,
  setSimulationSearchResults,
  setSimulationSearchSortOrder,
  simulationSearchFailed,
  updateSimulationSearchMaxCapitalPerTradeMax,
  updateSimulationSearchMaxCapitalPerTradeMin,
  updateSimulationSearchMaxHoldingDurationMax,
  updateSimulationSearchMaxHoldingDurationMin,
  updateSimulationSearchMinAverageRelativeProfitMax,
  updateSimulationSearchMinAverageRelativeProfitMin,
  updateSimulationSearchMinDataYears,
  updateSimulationSearchMinRoi,
  updateSimulationSearchSeedCapitalMax,
  updateSimulationSearchSeedCapitalMin,
  updateSimulationSearchYear,
} from './simulation.actions';
import {StatisticalSimulationResult} from '../shared/models/statisticalSimulationResult.interface';
import {StatisticalSimulationSearchResult} from '../shared/models/statisticalSimulationSearchResult.interface';
import {StatisticalSimulationSearchParams, StatisticalSimulationSortOrder} from '../shared/models/statisticalSimulationSearchParams.interface';
import Util from '../shared/util';


function logDebugMessages(actionName: string, state: SimulationState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

function containsExchange(exchanges: Exchange[], exchangeToFind: Exchange) {
  return exchanges.find(newExchange => exchangeToFind.uid === newExchange.uid);
}


const reducer: ActionReducer<SimulationState, Action> = createReducer(
    initialState,

    // Caches

    on(addSimulationResultToCache, (state, {simulationResult}) => {
      const simulationResults = mergeSimulationResultsRemovingOutdated(state.simulationResults, [simulationResult]);
      const newState = {...state, simulationResults};
      logDebugMessages('addSimulationResultToCache', state, newState);
      return newState;
    }),
    on(clearSimulationResultCache, (state) => {
      const newState = {...state, simulationResults: []};
      logDebugMessages('clearSimulationResultCache', state, newState);
      return newState;
    }),

    // StatisticalReturn Search

    on(resetSimulationSearch, (state) => {
      const searchResult: StatisticalSimulationSearchResult = {
        ...state.simulationSearch.searchResult, simulationResults: [], thereIsMore: false, errorMessage: undefined,
      };
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchResult, searching: false};
      const newState = {...state, simulationSearch: simulationSearch};
      logDebugMessages('resetSimulationSearch', state, newState);
      return newState;
    }),
    on(updateSimulationSearchYear, (state, {year}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, year}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchYear', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMinRoi, (state, {minRoi}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, minRoi}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMinRoi', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMinDataYears, (state, {minDataYears}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, minDataYears}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMinDataYears', state, newState);
      return newState;
    }),
    on(updateSimulationSearchSeedCapitalMin, (state, {seedCapitalMin}) => {
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...state.simulationSearch.searchParams, seedCapitalMin},
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchSeedCapitalMin', state, newState);
      return newState;
    }),
    on(updateSimulationSearchSeedCapitalMax, (state, {seedCapitalMax}) => {
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...state.simulationSearch.searchParams, seedCapitalMax},
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchSeedCapitalMax', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMaxHoldingDurationMin, (state, {maxHoldingDurationMin}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, maxHoldingDurationMin}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMaxHoldingDurationMin', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMaxHoldingDurationMax, (state, {maxHoldingDurationMax}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, maxHoldingDurationMax}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMaxHoldingDurationMax', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMinAverageRelativeProfitMin, (state, {minAverageRelativeProfitMin}) => {
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...state.simulationSearch.searchParams, minAverageRelativeProfitMin},
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMinAverageRelativeProfitMin', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMinAverageRelativeProfitMax, (state, {minAverageRelativeProfitMax}) => {
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...state.simulationSearch.searchParams, minAverageRelativeProfitMax},
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMinAverageRelativeProfitMax', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMaxCapitalPerTradeMin, (state, {maxCapitalPerTradeMin}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, maxCapitalPerTradeMin}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMaxCapitalPerTradeMin', state, newState);
      return newState;
    }),
    on(updateSimulationSearchMaxCapitalPerTradeMax, (state, {maxCapitalPerTradeMax}) => {
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, maxCapitalPerTradeMax}};
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMaxCapitalPerTradeMax', state, newState);
      return newState;
    }),
    on(addSimulationSearchExchangeShortName, (state, {exchangeShortName}) => {
      let exchangeShortNames = state.simulationSearch.searchParams.exchangeShortNames ? [...state.simulationSearch.searchParams.exchangeShortNames] : [];
      exchangeShortNames.push(exchangeShortName);
      exchangeShortNames = exchangeShortNames.sort();
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, exchangeShortNames}};
      const newState = {...state, simulationSearch};
      logDebugMessages('addSimulationSearchExchangeShortName', state, newState);
      return newState;
    }),
    on(removeSimulationSearchExchangeShortName, (state, {exchangeShortName}) => {
      let exchangeShortNames = state.simulationSearch.searchParams.exchangeShortNames ? [...state.simulationSearch.searchParams.exchangeShortNames] : [];
      Util.removeFromArray(exchangeShortNames, exchangeShortName);
      exchangeShortNames = exchangeShortNames.sort();
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, exchangeShortNames}};
      const newState = {...state, simulationSearch};
      logDebugMessages('addSimulationSearchExchangeShortName', state, newState);
      return newState;
    }),
    on(setSimulationSearchExchangeShortNames, (state, {exchangeShortNames}) => {
      exchangeShortNames = exchangeShortNames ? [...exchangeShortNames] : [];
      exchangeShortNames = exchangeShortNames.sort();
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searchParams: {...state.simulationSearch.searchParams, exchangeShortNames}};
      const newState = {...state, simulationSearch};
      logDebugMessages('setSimulationSearchExchangeShortNames', state, newState);
      return newState;
    }),
    on(setSimulationSearchSortOrder, (state, {sortOrder}) => {
      const sortedSimulations = sortSimulationSearchResults(state.simulationSearch.searchResult.simulationResults, sortOrder);
      const searchResult = {...state.simulationSearch.searchResult, simulationResults: sortedSimulations};
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...state.simulationSearch.searchParams, sortOrder},
        searchResult: searchResult,
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('updateSimulationSearchMaxCapitalPerTradeMax', state, newState);
      return newState;
    }),
    on(setSimulationSearchParams, (state, {searchParams}) => {
      const simulationSearchResult: StatisticalSimulationSearchResult = {
        ...state.simulationSearch.searchResult,
        simulationResults: [],
        thereIsMore: false,
        errorMessage: undefined,
      };
      let limit = searchParams.limit ? searchParams.limit : environment.defaultSimulationSearchCount;
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...searchParams, limit},
        searchResult: simulationSearchResult,
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('setSimulationSearchParams', state, newState);
      return newState;
    }),
    on(fetchSimulationSearchResults, (state) => {
      const simulationSearchResult: StatisticalSimulationSearchResult = {
        ...state.simulationSearch.searchResult,
        simulationResults: [],
        thereIsMore: false,
        errorMessage: undefined,
        lastVisible: undefined,
      };
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...state.simulationSearch.searchParams, startAfter: undefined},
        searching: true,
        searchResult: simulationSearchResult,
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('fetchSimulationSearchResults', state, newState);
      return newState;
    }),
    on(fetchMoreSimulationSearchResults, (state) => {
      let searchParams = state.simulationSearch.searchParams;
      let searchResult = state.simulationSearch.searchResult;
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searchParams: {...searchParams, startAfter: searchResult.lastVisible},
        searching: true,
        searchResult: state.simulationSearch.searchResult,
      };
      const newState = {...state, simulationSearch};
      logDebugMessages('fetchMoreSimulationSearchResults', state, newState);
      return newState;
    }),
    on(setSimulationSearchResults, (state, {searchResult}) => {
      const simulationResults: StatisticalSimulationResult[] = [...state.simulationSearch.searchResult.simulationResults, ...searchResult.simulationResults];
      const filteredSimulationResults = filterSimulationResults(simulationResults, state.simulationSearch.searchParams);
      const sortedSimulations = sortSimulationSearchResults(filteredSimulationResults, state.simulationSearch.searchParams.sortOrder);
      const newSearchResult = {...searchResult, simulationResults: sortedSimulations};
      const simulationSearch: SimulationSearch = {
        ...state.simulationSearch,
        searching: false,
        searchResult: newSearchResult,
      };
      const newSimulationResults = addCacheDatesAndIds([...searchResult.simulationResults]);
      const simulationResultsCache = mergeSimulationResultsRemovingOutdated(state.simulationResults, newSimulationResults);
      const newState = {...state, simulationSearch, simulationResults: simulationResultsCache};
      logDebugMessages('setSimulationSearchResults', state, newState);
      return newState;
    }),
    on(simulationSearchFailed, (state, {errorMessage}) => {
      const searchResult: StatisticalSimulationSearchResult = {...state.simulationSearch.searchResult, errorMessage, thereIsMore: false};
      const simulationSearch: SimulationSearch = {...state.simulationSearch, searching: false, searchResult};
      const newState = {...state, simulationSearch};
      logDebugMessages('simulationSearchFailed', state, newState);
      return newState;
    }),
);

export function simulationReducer(state: SimulationState | undefined, action: Action): SimulationState {
  return reducer(state, action);
}

/**
 * Merges two arrays of simulationResults removing all duplicates and outdated entries and only keeping the newer ones.
 * @param list1 first list. The order of the parameters does not matter.
 * @param list2 second list
 * @return merged list
 */
function mergeSimulationResultsRemovingOutdated(list1: StatisticalSimulationResult[], list2: StatisticalSimulationResult[]) {
  // Remove outdated elements from both lists
  const filteredList1 = list1.filter(it => !isOutdated(it, environment.defaultSimulationResultCacheAgeInSec));
  const filteredList2 = list2.filter(it => !isOutdated(it, environment.defaultSimulationResultCacheAgeInSec));

  const simulationResultsByCode = new Map<string, StatisticalSimulationResult>();
  filteredList1.forEach(it => addSimulationResultToMap(it, simulationResultsByCode));
  filteredList2.forEach(it => addSimulationResultToMap(it, simulationResultsByCode));

  return [...simulationResultsByCode.values()];
}

function isOutdated(cachedElement: any, cacheAgeInSec: number): boolean {
  const nowUtc = new Date().getTime();
  return !cachedElement.cacheDate || (nowUtc - cachedElement.cacheDate.getTime() > cacheAgeInSec * 1000);
}

/**
 * Adds the given simulationResult to the given map, but only, if the map doesn't contain a newer item
 * @param simulationResult
 * @param simulationResultsByCode
 */
function addSimulationResultToMap(simulationResult: StatisticalSimulationResult, simulationResultsByCode: Map<string, StatisticalSimulationResult>) {
  const simulationResultFromMap = simulationResultsByCode.get(simulationResult.uid!);
  if (!simulationResultFromMap)
    simulationResultsByCode.set(simulationResult.uid!, simulationResult);
  else
    simulationResultsByCode.set(simulationResult.uid!, getNewerCacheItem(simulationResult, simulationResultFromMap));
}

function getNewerCacheItem<T>(cachedElement1: any, cachedElement2: any): T {
  if (cachedElement1.cacheDate >= cachedElement2.cacheDate)
    return cachedElement1;
  return cachedElement2;
}

function addCacheDatesAndIds(simulationResults: StatisticalSimulationResult[]): StatisticalSimulationResult[] {
  const newList: StatisticalSimulationResult[] = [];
  let now = new Date();
  simulationResults.forEach(it => {
    const simRes =
        {...it, cacheDate: now, cacheId: it.uid};
    newList.push(simRes);
  });
  return newList;
}

function filterSimulationResults(simulationResults: StatisticalSimulationResult[], searchParams: StatisticalSimulationSearchParams) {
  return simulationResults.filter(it => {
    if (it.seedCapital !== undefined && searchParams.seedCapitalMin !== undefined && it.seedCapital < searchParams.seedCapitalMin)
      return false;
    if (it.seedCapital !== undefined && searchParams.seedCapitalMax !== undefined && it.seedCapital > searchParams.seedCapitalMax)
      return false;
    if (it.maxCapitalPerTrade !== undefined && searchParams.maxCapitalPerTradeMin !== undefined && it.maxCapitalPerTrade < searchParams.maxCapitalPerTradeMin)
      return false;
    if (it.maxCapitalPerTrade !== undefined && searchParams.maxCapitalPerTradeMax !== undefined && it.maxCapitalPerTrade > searchParams.maxCapitalPerTradeMax)
      return false;
    if (it.maxHoldingDuration !== undefined && searchParams.maxHoldingDurationMin !== undefined && it.maxHoldingDuration < searchParams.maxHoldingDurationMin)
      return false;
    if (it.maxHoldingDuration !== undefined && searchParams.maxHoldingDurationMax !== undefined && it.maxHoldingDuration > searchParams.maxHoldingDurationMax)
      return false;
    if (it.minAverageRelativeProfit !== undefined && searchParams.minAverageRelativeProfitMin !== undefined && it.minAverageRelativeProfit * 100 < searchParams.minAverageRelativeProfitMin)
      return false;
    if (it.minAverageRelativeProfit !== undefined && searchParams.minAverageRelativeProfitMax !== undefined && it.minAverageRelativeProfit * 100 > searchParams.minAverageRelativeProfitMax)
      return false;
    if (it.roi !== undefined && searchParams.minRoi !== undefined && it.roi * 100 < searchParams.minRoi)
      return false;
    if (it.year !== undefined && searchParams.year !== undefined && it.year !== searchParams.year)
      return false;
    if (it.minDataYears !== undefined && searchParams.minDataYears !== undefined && it.minDataYears !== searchParams.minDataYears)
      return false;
    if (it.exchangeShortNames !== undefined && searchParams.exchangeShortNames !== undefined && !Util.arrayContainsAll(it.exchangeShortNames, searchParams.exchangeShortNames))
      return false;
    return true;
  });
}

function sortSimulationSearchResults(simulationResults: StatisticalSimulationResult[], sortOrder?: StatisticalSimulationSortOrder): StatisticalSimulationResult[] {
  if (sortOrder === undefined)
    return simulationResults;
  const sortedSimulationResults = [...simulationResults].sort((a: StatisticalSimulationResult, b: StatisticalSimulationResult) => {
    switch (sortOrder) {
      case StatisticalSimulationSortOrder.MaxCapitalPerTradeAsc:
        return Util.compareWithUndefined(a.maxCapitalPerTrade, b.maxCapitalPerTrade);
      case StatisticalSimulationSortOrder.MaxCapitalPerTradeDesc:
        return Util.compareWithUndefined(a.maxCapitalPerTrade, b.maxCapitalPerTrade, false);
      case StatisticalSimulationSortOrder.MinPricePerStockAsc:
        return Util.compareWithUndefined(a.minPricePerStock, b.minPricePerStock);
      case StatisticalSimulationSortOrder.MinPricePerStockDesc:
        return Util.compareWithUndefined(a.minPricePerStock, b.minPricePerStock, false);
      case StatisticalSimulationSortOrder.SeedCapitalAsc:
        return Util.compareWithUndefined(a.seedCapital, b.seedCapital);
      case StatisticalSimulationSortOrder.SeedCapitalDesc:
        return Util.compareWithUndefined(a.seedCapital, b.seedCapital, false);
      case StatisticalSimulationSortOrder.FinalCapitalAsc:
        return Util.compareWithUndefined(a.finalCapital, b.finalCapital);
      case StatisticalSimulationSortOrder.FinalCapitalDesc:
        return Util.compareWithUndefined(a.finalCapital, b.finalCapital, false);
      case StatisticalSimulationSortOrder.RoiAsc:
        return Util.compareWithUndefined(a.roi, b.roi);
      case StatisticalSimulationSortOrder.RoiDesc:
        return Util.compareWithUndefined(a.roi, b.roi, false);
      case StatisticalSimulationSortOrder.YearAsc:
        return Util.compareWithUndefined(a.year, b.year);
      case StatisticalSimulationSortOrder.YearDesc:
        return Util.compareWithUndefined(a.year, b.year, false);
      case StatisticalSimulationSortOrder.MaxHoldingDurationAsc:
        return Util.compareWithUndefined(a.maxHoldingDuration, b.maxHoldingDuration);
      case StatisticalSimulationSortOrder.MaxHoldingDurationDesc:
        return Util.compareWithUndefined(a.maxHoldingDuration, b.maxHoldingDuration, false);
      case StatisticalSimulationSortOrder.MinAverageRelativeProfitAsc:
        return Util.compareWithUndefined(a.minAverageRelativeProfit, b.minAverageRelativeProfit);
      case StatisticalSimulationSortOrder.MinAverageRelativeProfitDesc:
        return Util.compareWithUndefined(a.minAverageRelativeProfit, b.minAverageRelativeProfit, false);
      case StatisticalSimulationSortOrder.MinDataYearsAsc:
        return Util.compareWithUndefined(a.minDataYears, b.minDataYears);
      case StatisticalSimulationSortOrder.MinDataYearsDesc:
        return Util.compareWithUndefined(a.minDataYears, b.minDataYears, false);
      case StatisticalSimulationSortOrder.NumberOfTradesAsc:
        return Util.compareWithUndefined(a.transactionList?.length, b.transactionList?.length);
      case StatisticalSimulationSortOrder.NumberOfTradesDesc:
        return Util.compareWithUndefined(a.transactionList?.length, b.transactionList?.length, false);
      case StatisticalSimulationSortOrder.ExchangesAsc:
        return Util.compareWithUndefined(Util.joinStringArray(a.exchangeShortNames, ','), Util.joinStringArray(b.exchangeShortNames, ','));
      case StatisticalSimulationSortOrder.ExchangesDesc:
        return Util.compareWithUndefined(Util.joinStringArray(a.exchangeShortNames, ','), Util.joinStringArray(b.exchangeShortNames, ','), false);
      default:
        return Util.compareWithUndefined(a.roi, b.roi, false);
    }
  });
  return sortedSimulationResults;
}

