import {StatisticalReturnsSortOrder} from './statisticalReturnsSearchParams.interface';
import {environment} from '../../../environments/environment';

export type TableId = 'statistical-returns' | 'simulation-results'

export interface ColumnSettings {
  [id: string]: ColumnList;
}

export interface ColumnList {
  visibleColumnIds: string[];
}

export interface TableColumn {
  id: string;
  label: string;
  defaultVisible: boolean;
  tooltip: string;
  areCollectiveColumns: boolean;
  sortOrderAsc?: string;
  sortOrderDesc?: string;
  minWidth?: string;
}

export const tableColumnsStatisticalReturn: TableColumn[] = [
  {
    id: 'SYMBOL_NAME',
    label: $localize`Symbol name`,
    defaultVisible: true,
    tooltip: $localize`The name the symbol`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.SymbolNameAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.SymbolNameDesc,
    minWidth: '200px',
  },
  {
    id: 'SYMBOL_CODE', label: $localize`Symbol code`, defaultVisible: false, tooltip: $localize`The code of the symbol`, areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.SymbolCodeAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.SymbolCodeDesc,
  },
  {
    id: 'EXCHANGE',
    label: $localize`Exchange`,
    defaultVisible: true,
    tooltip: $localize`The exchange, on which the symbol is listed`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.ExchangeNameAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.ExchangeNameDesc,
  },
  {
    id: 'CURRENCY',
    label: $localize`Currency`,
    defaultVisible: true,
    tooltip: $localize`The currency in which the stock is traded`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.CurrencyAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.CurrencyDesc,
  },
  {
    id: 'BUY_DATE', label: $localize`Buy date`, defaultVisible: true, tooltip: $localize`The buy date of the symbol`, areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.BuyDateAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.BuyDateDesc,
  },
  {
    id: 'SELL_DATE', label: $localize`Sell date`, defaultVisible: true, tooltip: $localize`The sell date of the symbol`, areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.SellDateAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.SellDateDesc,
  },
  {
    id: 'PRICE', label: $localize`Price`, defaultVisible: true, tooltip: $localize`The current price of the symbol`, areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.PriceAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.PriceDesc,
  },
  {
    id: 'DURATION',
    label: $localize`Duration`,
    defaultVisible: true,
    tooltip: $localize`The duration between buy and sell date in days`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.DurationAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.DurationDesc,
  },
  {id: 'DEVELOPMENT_SINCE_BUY_DAY', label: $localize`Development since buy day`, defaultVisible: true, tooltip: '', areCollectiveColumns: true},
  {
    id: 'AVG_PROFIT',
    label: $localize`Avg. profit`,
    defaultVisible: true,
    tooltip: $localize`The average relative annual profit if the symbol is bought each year on the buy date and sold on the sell date`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.AverageRelativeProfitAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.AverageRelativeProfitDesc,
  },
  {
    id: 'WORST_YEAR',
    label: $localize`Worst year`,
    defaultVisible: true,
    tooltip: $localize`The worst annual profit in the review period`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.WorstYearAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.WorstYearDesc,
  },
  {
    id: 'DATA_YEARS',
    label: $localize`Data years`,
    defaultVisible: true,
    tooltip: $localize`The number of years for which we have data`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.DataYearsAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.DataYearsDesc,
  },
  {
    id: 'WIN_RATIO',
    label: $localize`Win ratio`,
    defaultVisible: true,
    tooltip: $localize`The win ratio (number of profitable years / total years)`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.WinRatioAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.WinRatioDesc,
  },
  {
    id: 'AVG_MAX_RISE',
    label: $localize`Avg. max. rise`,
    defaultVisible: true,
    tooltip: $localize`The average max. rise within the buy and sell period relative to the buy price over all years`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.AvgMaxRiseAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.AvgMaxRiseDesc,
  },
  {
    id: 'AVG_MAX_DROP',
    label: $localize`Avg. max. drop`,
    defaultVisible: true,
    tooltip: $localize`The average max. drop within the buy and sell period relative to the buy price over all years`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.AvgMaxDropAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.AvgMaxDropDesc,
  },
  {
    id: 'HIGHEST_RISE',
    label: $localize`Highest rise`,
    defaultVisible: true,
    tooltip: $localize`The highest rise within the buy and sell period relative to the buy price of all years`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.HighestMaxRiseAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.HighestMaxRiseDesc,
  },
  {
    id: 'HIGHEST_DROP',
    label: $localize`Highest drop`,
    defaultVisible: true,
    tooltip: $localize`The highest drop within the buy and sell period relative to the buy price of all years`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.HighestMaxDropAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.HighestMaxDropDesc,
  },
  {
    id: 'MARKET_CAP', label: $localize`Market cap.`, defaultVisible: true, tooltip: $localize`The market capitalization`, areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.MarketCapAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.MarketCapDesc,
  },
  {
    id: 'AVG_VOLUME_UNITS',
    label: $localize`Avg. volume in units`,
    defaultVisible: false,
    tooltip: $localize`The average daily trading volume of the stock in units`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.AvgVolumeAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.AvgVolumeDesc,
  },
  {
    id: 'AVG_VOLUME_PRICE',
    label: $localize`Avg. volume`,
    defaultVisible: false,
    tooltip: $localize`The average daily trading volume of the stock in the currency the stock is traded in`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.AvgVolumePriceAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.AvgVolumePriceDesc,
  },
  {
    id: 'AVG_VOLUME_BASE_CURRENCY',
    label: $localize`Avg. volume in ${environment.baseCurrency}`,
    defaultVisible: true,
    tooltip: $localize`The average daily trading volume of the stock in ${environment.baseCurrency}`,
    areCollectiveColumns: false,
    sortOrderAsc: StatisticalReturnsSortOrder.AvgVolumeBaseCurrencyeAsc,
    sortOrderDesc: StatisticalReturnsSortOrder.AvgVolumeBaseCurrencyDesc,
  },
  {id: 'PERIODS_ALL_ASCENDING', label: $localize`All periods ascending`, defaultVisible: true, tooltip: '', areCollectiveColumns: true},
  {id: 'PERIODS_ALL_DESCENDING', label: $localize`All periods descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'PERIODS_WIN_RATIOS_ALL_ASCENDING', label: $localize`All periods' win ratios ascending`, defaultVisible: true, tooltip: '', areCollectiveColumns: true},
  {
    id: 'PERIODS_WIN_RATIOS_ALL_DESCENDING', label: $localize`All periods' win ratios descending`,
    defaultVisible: false, tooltip: '', areCollectiveColumns: true,
  },
  {id: 'YEARS_ALL_ASCENDING', label: $localize`All years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_ALL_DESCENDING', label: $localize`All years descending`, defaultVisible: true, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_0_TO_5_ASCENDING', label: $localize`Last 0-5 years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_0_TO_5_DESCENDING', label: $localize`Last 0-5 years descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_6_TO_10_ASCENDING', label: $localize`Last 6-10 years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_6_TO_10_DESCENDING', label: $localize`Last 6-10 years descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_11_TO_15_ASCENDING', label: $localize`Last 11-15 years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_11_TO_15_DESCENDING', label: $localize`Last 11-15 years descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_16_TO_20_ASCENDING', label: $localize`Last 16-20 years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_16_TO_20_DESCENDING', label: $localize`Last 16-20 years descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_21_TO_25_ASCENDING', label: $localize`Last 21-25 years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_21_TO_25_DESCENDING', label: $localize`Last 21-25 years descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_26_TO_30_ASCENDING', label: $localize`Last 26-30 years ascending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
  {id: 'YEARS_26_TO_30_DESCENDING', label: $localize`Last 26-30 years descending`, defaultVisible: false, tooltip: '', areCollectiveColumns: true},
];

