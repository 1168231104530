import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {
  addCompanyProfileToCache,
  addDevelopmentSinceBuyDayToCache,
  addExchangesToCache,
  addExchangeToCache,
  addStatisticalReturnToCache,
  addSymbolPriceOnBuyDayToCache,
  addSymbolToCache,
  bestStatisticalReturnsSearchFailed,
  clearCompanyProfileCache,
  clearDevelopmentSinceBuyDayCache,
  clearExchangeCache,
  clearStatisticalReturnCache,
  clearSymbolCache,
  clearSymbolPriceOnBuyDayCache,
  fetchBestStatisticalReturnsSearchResults,
  fetchMoreBestStatisticalReturnsSearchResults,
  fetchMoreSymbolsSearchResults,
  fetchMoreUpcomingStatisticalReturnsSearchResults,
  fetchSymbolsSearchResults,
  fetchUpcomingStatisticalReturnsSearchResults,
  filterBestStatisticalReturns,
  filterUpcomingStatisticalReturns,
  mergeUpdateBestStatisticalReturnSearchParams,
  mergeUpdateUpcomingStatisticalReturnSearchParams,
  removeSymbolFromSearchResults,
  resetBestStatisticalReturnsSearch,
  resetSymbolsSearch,
  resetUpcomingStatisticalReturnsSearch,
  setBestStatisticalReturnsExchangesFilter,
  setBestStatisticalReturnsSearchParams,
  setBestStatisticalReturnsSearchResults,
  setBestStatisticalReturnsSortOrder,
  setMoreSymbolsSearchResults,
  setSymbolsSearchParams,
  setSymbolsSearchResults,
  setUpcomingStatisticalReturnsBuyDayNumber,
  setUpcomingStatisticalReturnsExchangesFilter,
  setUpcomingStatisticalReturnsSearchParams,
  setUpcomingStatisticalReturnsSearchResults,
  setUpcomingStatisticalReturnsSortOrder,
  symbolsSearchFailed,
  upcomingStatisticalReturnsSearchFailed,
  updateSymbolSearchExchangeShortName,
  updateSymbolsSearchTerm,
} from './stock.actions';
import {environment} from '../../environments/environment';
import {Symbol} from '../shared/models/symbol.interface';
import {Exchange} from '../shared/models/exchange.interface';
import {SymbolsSearchResult} from '../shared/models/symbolsSearchResult.interface';
import {BestStatisticalReturnsSearch, initialState, StockState, SymbolsSearch, UpcomingStatisticalReturnsSearch} from './stock.state';
import {NumberByYearMap, StatisticalReturn} from '../shared/models/statisticalReturn.interface';
import {StatisticalReturnsSearchResult} from '../shared/models/statisticalReturnsSearchResult.interface';
import Util from '../shared/util';
import {CompanyProfile} from '../shared/models/companyProfile.interface';
import {PeriodWinRatio, StatisticalReturnsSearchParams, StatisticalReturnsSortOrder} from '../shared/models/statisticalReturnsSearchParams.interface';
import {StatisticalTradingService} from '../statistical-trading/statistical-trading.service';
import {DevelopmentSinceBuyDay} from '../shared/models/developmentSinceBuyDay.interface';

const emptyStatisticalReturnsSearchResult = {
  statisticalReturns: [],
  thereIsMore: false,
  errorMessage: undefined,
  lastVisible: undefined,
};

const emptySymbolsSearchResult = {symbols: [], thereIsMore: false, errorMessage: undefined};

function logDebugMessages(actionName: string, state: StockState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

function containsExchange(exchanges: Exchange[], exchangeToFind: Exchange) {
  return exchanges.find(newExchange => exchangeToFind.uid === newExchange.uid);
}

/**
 * Merges two arrays of PeriodWinRatio objects. If both arrays contain an entry with the same period,
 * the entry from the newPeriodWinRatios array will overwrite the one from the oldPeriodWinRatios array.
 *
 * @param {PeriodWinRatio[]} oldPeriodWinRatios - The original array of PeriodWinRatio objects.
 * @param {PeriodWinRatio[]} newPeriodWinRatios - The new array of PeriodWinRatio objects to merge with the old array.
 * @return {PeriodWinRatio[]} A new array containing merged PeriodWinRatio objects.
 */
function mergePeriodWinRatios(oldPeriodWinRatios?: PeriodWinRatio[], newPeriodWinRatios?: PeriodWinRatio[]) {
  if (!oldPeriodWinRatios || oldPeriodWinRatios.length === 0)
    return newPeriodWinRatios ?? [];

  const merged: Record<string, PeriodWinRatio> = {};

  // Add all oldPeriodWinRatios to the merged record
  oldPeriodWinRatios.forEach(pwr => {
    merged[pwr.period] = pwr;
  });

  // Overwrite with newPeriodWinRatios
  newPeriodWinRatios?.forEach(pwr => {
    merged[pwr.period] = pwr;
  });

  // Convert the record back to an array
  return Object.values(merged);
}

const reducer: ActionReducer<StockState, Action> = createReducer(
  initialState,

  // Caches

  on(addSymbolToCache, (state, {symbol}) => {
    const symbols = mergeSymbolsRemovingOutdated(state.symbols, [symbol]);
    const newState = {...state, symbols};
    logDebugMessages('addSymbolToCache', state, newState);
    return newState;
  }),
  on(clearSymbolCache, (state) => {
    const newState = {...state, symbols: []};
    logDebugMessages('clearSymbolCache', state, newState);
    return newState;
  }),
  on(addDevelopmentSinceBuyDayToCache, (state, {developmentSinceBuyDay}) => {
    const developmentSinceBuyDays = mergeDevelopmentSinceBuyDaysRemovingOutdated(state.developmentSinceBuyDays, [developmentSinceBuyDay]);
    const newState = {...state, developmentSinceBuyDays};
    logDebugMessages('addDevelopmentSinceBuyDayToCache', state, newState);
    return newState;
  }),
  on(clearDevelopmentSinceBuyDayCache, (state) => {
    const newState = {...state, developmentSinceBuyDays: []};
    logDebugMessages('clearDevelopmentSinceBuyDayCache', state, newState);
    return newState;
  }),
  on(addSymbolPriceOnBuyDayToCache, (state, {developmentSinceBuyDay}) => {
    const symbolPriceOnBuyDays = mergeDevelopmentSinceBuyDaysRemovingOutdated(state.symbolPriceOnBuyDays, [developmentSinceBuyDay]);
    const newState = {...state, symbolPriceOnBuyDays};
    logDebugMessages('addSymbolPriceOnBuyDayToCache', state, newState);
    return newState;
  }),
  on(clearSymbolPriceOnBuyDayCache, (state) => {
    const newState = {...state, symbolPriceOnBuyDays: []};
    logDebugMessages('clearSymbolPriceOnBuyDayCache', state, newState);
    return newState;
  }),
  on(addStatisticalReturnToCache, (state, {statisticalReturn}) => {
    const statisticalReturns = mergeStatisticalReturnsRemovingOutdated(state.statisticalReturns, [statisticalReturn]);
    const newState = {...state, statisticalReturns};
    logDebugMessages('addStatisticalReturnToCache', state, newState);
    return newState;
  }),
  on(clearStatisticalReturnCache, (state) => {
    const newState = {...state, statisticalReturns: []};
    logDebugMessages('clearStatisticalReturnCache', state, newState);
    return newState;
  }),
  on(addCompanyProfileToCache, (state, {companyProfile}) => {
    const companyProfiles = mergeCompanyProfilesRemovingOutdated(state.companyProfiles, [companyProfile]);
    const newState = {...state, companyProfiles};
    logDebugMessages('addCompanyProfileToCache', state, newState);
    return newState;
  }),
  on(clearCompanyProfileCache, (state) => {
    const newState = {...state, companyProfiles: []};
    logDebugMessages('clearCompanyProfileCache', state, newState);
    return newState;
  }),
  on(addExchangeToCache, (state, {exchange}) => {
    const newExchanges: Exchange[] = [];
    const nowUtc = new Date().getTime();
    for (const exchangeFromState of state.exchanges) {
      // Skip the exchange with the ID of the given exchange, because we will add the given exchange object later
      if (exchange.shortName === exchangeFromState.shortName)
        continue;
      // Also skip, if the cached exchange is too old (or has no cache date)
      if (!exchangeFromState.cacheDate || (nowUtc - exchangeFromState.cacheDate.getTime() > environment.defaultExchangeCacheAgeInSec * 1000))
        continue;
      const exchangeCopy: Exchange = {...exchangeFromState};
      newExchanges.push(exchangeCopy);
    }
    newExchanges.push(exchange);
    const newState = {...state, exchanges: newExchanges};

    logDebugMessages('addExchangeToCache', state, newState);
    return newState;
  }),
  on(addExchangesToCache, (state, {exchanges}) => {
    let newExchanges: Exchange[] = state.exchanges.filter(exchangeFromState => isOutdated(exchangeFromState, environment.defaultExchangeCacheAgeInSec));
    newExchanges = newExchanges.filter(exchangeFromState => !containsExchange(exchanges, exchangeFromState));
    newExchanges = [...newExchanges, ...exchanges];
    const newState = {...state, exchanges: newExchanges};
    logDebugMessages('addExchangesToCache', state, newState);
    return newState;
  }),
  on(clearExchangeCache, (state) => {
    const newState = {...state, exchanges: []};
    logDebugMessages('clearExchangeCache', state, newState);
    return newState;
  }),

  // StatisticalReturn Search

  on(resetSymbolsSearch, (state) => {
    const searchResult: SymbolsSearchResult = {...state.symbolsSearch.searchResult, ...emptySymbolsSearchResult};
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searchResult, searching: false};
    const newState = {...state, symbolsSearch: symbolsSearch};
    logDebugMessages('resetSymbolsSearch', state, newState);
    return newState;
  }),
  on(updateSymbolsSearchTerm, (state, {searchTerm}) => {
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searchParams: {...state.symbolsSearch.searchParams, searchTerm}};
    const newState = {...state, symbolsSearch};
    logDebugMessages('updateSymbolsSearchTerm', state, newState);
    return newState;
  }),
  on(updateSymbolSearchExchangeShortName, (state, {exchangeShortName}) => {
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searchParams: {...state.symbolsSearch.searchParams, exchangeShortName}};
    const newState = {...state, symbolsSearch};
    logDebugMessages('updateSymbolSearchExchangeShortName', state, newState);
    return newState;
  }),
  on(setSymbolsSearchParams, (state, {searchParams}) => {
    const symbolSearchResult: SymbolsSearchResult = {...state.symbolsSearch.searchResult, ...emptySymbolsSearchResult};
    let limit = searchParams.limit ? searchParams.limit : environment.defaultSymbolSearchCount;
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searchParams: {...searchParams, start: 0, limit}, searchResult: symbolSearchResult};
    const newState = {...state, symbolsSearch};
    logDebugMessages('setSymbolsSearchParams', state, newState);
    return newState;
  }),
  on(fetchSymbolsSearchResults, (state) => {
    const symbolSearchResult: SymbolsSearchResult = {...state.symbolsSearch.searchResult, ...emptySymbolsSearchResult};
    const symbolsSearch: SymbolsSearch = {
      ...state.symbolsSearch,
      searchParams: {...state.symbolsSearch.searchParams, start: 0},
      searching: true,
      searchResult: symbolSearchResult,
    };
    const newState = {...state, symbolsSearch};
    logDebugMessages('fetchSymbolsSearchResults', state, newState);
    return newState;
  }),
  on(fetchMoreSymbolsSearchResults, (state) => {
    let searchParams = state.symbolsSearch.searchParams;
    let searchResult = state.symbolsSearch.searchResult;
    let start = searchResult.symbols.length;
    const symbolsSearch: SymbolsSearch = {
      ...state.symbolsSearch,
      searchParams: {...searchParams, start},
      searching: true,
      searchResult: state.symbolsSearch.searchResult,
    };
    const newState = {...state, symbolsSearch};
    logDebugMessages('fetchMoreSymbolsSearchResults', state, newState);
    return newState;
  }),
  on(setSymbolsSearchResults, (state, {searchResult}) => {
    // Do not add search results to the cache. The search results contain very little symbol data (just name, type and exchange)
    // const symbols = mergeSymbolsRemovingOutdated(searchResult.symbols, state.symbols);
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searching: false, searchResult};
    const newState = {...state, symbolsSearch};
    logDebugMessages('setSymbolsSearchResults', state, newState);
    return newState;
  }),
  on(setMoreSymbolsSearchResults, (state, {searchResult}) => {
    // Do not add search results to the cache. The search results contain very little symbol data (just name, type and exchange)
    // const symbols = mergeSymbolsRemovingOutdated(searchResult.symbols, state.symbols);
    const allSearchResults: Symbol[] = [...state.symbolsSearch.searchResult.symbols, ...searchResult.symbols];
    const newSearchResult = {...searchResult, symbols: allSearchResults};
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searching: false, searchResult: newSearchResult};
    const newState = {...state, symbolsSearch};
    logDebugMessages('setMoreSymbolsSearchResults', state, newState);
    return newState;
  }),
  on(symbolsSearchFailed, (state, {errorMessage}) => {
    const searchResult: SymbolsSearchResult = {...state.symbolsSearch.searchResult, errorMessage, thereIsMore: false};
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searching: false, searchResult};
    const newState = {...state, symbolsSearch};
    logDebugMessages('symbolsSearchFailed', state, newState);
    return newState;
  }),
  on(removeSymbolFromSearchResults, (state, {symbol}) => {
    const symbols = Util.removeFromArray([...state.symbolsSearch.searchResult.symbols], symbol);
    const searchResult: SymbolsSearchResult = {...state.symbolsSearch.searchResult, symbols};
    const symbolsSearch: SymbolsSearch = {...state.symbolsSearch, searchResult};
    const newState = {...state, symbolsSearch};
    logDebugMessages('removeSymbolFromSearchResults', state, newState);
    return newState;
  }),

  // Best statistical returns search

  on(resetBestStatisticalReturnsSearch, (state) => {
    const searchResult: StatisticalReturnsSearchResult = {
      ...state.bestStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult,
    };
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {...state.bestStatisticalReturnsSearch, searchResult, searching: false};
    const newState = {...state, bestStatisticalReturnsSearch: bestStatisticalReturnsSearch};
    logDebugMessages('resetBestStatisticalReturnsSearch', state, newState);
    return newState;
  }),
  on(setBestStatisticalReturnsSearchParams, (state, {searchParams}) => {
    const emptyResult: StatisticalReturnsSearchResult = {
      ...state.bestStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult,
    };
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {
      ...state.bestStatisticalReturnsSearch,
      searchParams: {...searchParams},
      searchResult: emptyResult,
    };
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('setBestStatisticalReturnsSearchParams', state, newState);
    return newState;
  }),
  on(mergeUpdateBestStatisticalReturnSearchParams, (state, {searchParams}) => {
    const minPeriodWinRatios: PeriodWinRatio[] = mergePeriodWinRatios(state.bestStatisticalReturnsSearch.searchParams.minPeriodWinRatios, searchParams.minPeriodWinRatios);
    const mergedSearchParams: StatisticalReturnsSearchParams = {...state.bestStatisticalReturnsSearch.searchParams, ...searchParams, minPeriodWinRatios};
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {
      ...state.bestStatisticalReturnsSearch, searchParams: {...mergedSearchParams},
    };
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('mergeUpdateBestStatisticalReturnSearchParams', state, newState);
    return newState;
  }),
  on(setBestStatisticalReturnsSortOrder, (state, {sortOrder}) => {
    const searchParams: StatisticalReturnsSearchParams = {...state.bestStatisticalReturnsSearch.searchParams, sortOrder};
    const sortedStatisticalReturns = sortStatisticalReturns(state.bestStatisticalReturnsSearch.searchResult.statisticalReturns, sortOrder, state.symbols, state.exchanges);
    const sortedSearchResults: StatisticalReturnsSearchResult = {
      ...state.bestStatisticalReturnsSearch.searchResult, statisticalReturns: sortedStatisticalReturns,
    };
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {
      ...state.bestStatisticalReturnsSearch,
      searchParams: {...searchParams},
      searchResult: sortedSearchResults,
    };
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('setBestStatisticalReturnsSortOrder', state, newState);
    return newState;
  }),
  on(fetchBestStatisticalReturnsSearchResults, (state) => {
    const emptyResult: StatisticalReturnsSearchResult = {
      ...state.bestStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult, allFilteredOut: false,
    };
    let searchParams = state.bestStatisticalReturnsSearch.searchParams;
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {
      ...state.bestStatisticalReturnsSearch,
      searchParams: {...searchParams, startAfter: undefined},
      searching: true,
      searchResult: emptyResult,
    };
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('fetchBestStatisticalReturnsSearchResults', state, newState);
    return newState;
  }),
  on(fetchMoreBestStatisticalReturnsSearchResults, (state) => {
    let searchParams = state.bestStatisticalReturnsSearch.searchParams;
    let searchResult = {...state.bestStatisticalReturnsSearch.searchResult, allFilteredOut: false};
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {
      ...state.bestStatisticalReturnsSearch,
      searchParams: {...searchParams, startAfter: searchResult.lastVisible},
      searching: true,
    };
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('fetchMoreBestStatisticalReturnsSearchResults', state, newState);
    return newState;
  }),
  on(setBestStatisticalReturnsSearchResults, (state, {searchResult}) => {
    let statisticalReturns: StatisticalReturn[] = [...state.bestStatisticalReturnsSearch.searchResult.statisticalReturns, ...searchResult.statisticalReturns];
    statisticalReturns = removeDuplicates(statisticalReturns);
    let searchParams = state.bestStatisticalReturnsSearch.searchParams;
    let sortOrder = searchParams.sortOrder;
    statisticalReturns = filterStatisticalReturns(statisticalReturns, searchParams, state.symbols, state.exchanges);
    statisticalReturns = sortStatisticalReturns(statisticalReturns, sortOrder, state.symbols, state.exchanges);
    const allFilteredOut = state.bestStatisticalReturnsSearch.searchResult.statisticalReturns.length >= statisticalReturns.length;
    const newSearchResult: StatisticalReturnsSearchResult = {...searchResult, statisticalReturns, allFilteredOut};
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {
      ...state.bestStatisticalReturnsSearch,
      searching: false,
      searchResult: newSearchResult,
    };
    const newStatisticalReturns = addCacheDatesAndIds([...searchResult.statisticalReturns]);
    const statisticalReturnsCache = mergeStatisticalReturnsRemovingOutdated(state.statisticalReturns, newStatisticalReturns);
    const newState = {...state, bestStatisticalReturnsSearch, statisticalReturns: statisticalReturnsCache};
    logDebugMessages('setBestStatisticalReturnsSearchResults', state, newState);
    return newState;
  }),
  on(bestStatisticalReturnsSearchFailed, (state, {errorMessage}) => {
    const searchResult: StatisticalReturnsSearchResult = {
      ...state.bestStatisticalReturnsSearch.searchResult,
      errorMessage,
      thereIsMore: false,
      allFilteredOut: false,
    };
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {...state.bestStatisticalReturnsSearch, searching: false, searchResult};
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('bestStatisticalReturnsSearchFailed', state, newState);
    return newState;
  }),
  on(setBestStatisticalReturnsExchangesFilter, (state, {exchangeShortNames}) => {
    const searchParams: StatisticalReturnsSearchParams = {...state.bestStatisticalReturnsSearch.searchParams, exchangeShortNames};
    const bestStatisticalReturnsSearch: BestStatisticalReturnsSearch = {...state.bestStatisticalReturnsSearch, searchParams};
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('setBestStatisticalReturnsExchangesFilter', state, newState);
    return newState;
  }),
  on(filterBestStatisticalReturns, (state) => {
    let searchParams = state.bestStatisticalReturnsSearch.searchParams;
    let searchResult = state.bestStatisticalReturnsSearch.searchResult;
    const statisticalReturns = filterStatisticalReturns(searchResult.statisticalReturns, searchParams, state.symbols, state.exchanges);
    searchResult = {...searchResult, statisticalReturns};
    const bestStatisticalReturnsSearch = {...state.bestStatisticalReturnsSearch, searchResult};
    const newState = {...state, bestStatisticalReturnsSearch};
    logDebugMessages('filterBestStatisticalReturns', state, newState);
    return newState;
  }),

  // Upcoming statistical returns search

  on(resetUpcomingStatisticalReturnsSearch, (state) => {
    const searchResult: StatisticalReturnsSearchResult = {
      ...state.upcomingStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult,
    };
    const searchParams = {...state.upcomingStatisticalReturnsSearch.searchParams, buyDayNumber: Util.getCurrentDayNumber()};
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch, searchParams, searchResult, searching: false,
    };
    const newState = {...state, upcomingStatisticalReturnsSearch: upcomingStatisticalReturnsSearch};
    logDebugMessages('resetUpcomingStatisticalReturnsSearch', state, newState);
    return newState;
  }),
  on(setUpcomingStatisticalReturnsSearchParams, (state, {searchParams}) => {
    const emptyResult: StatisticalReturnsSearchResult = {
      ...state.upcomingStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult,
    };
    if (searchParams.buyDayNumber === undefined)
      searchParams = {...searchParams, buyDayNumber: Util.getCurrentDayNumber()};
    if (searchParams.sortOrder === undefined)
      searchParams = {...searchParams, sortOrder: StatisticalReturnsSortOrder.AverageRelativeProfitDesc};
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch,
      searchParams: {...searchParams},
      searchResult: emptyResult,
    };
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('setUpcomingStatisticalReturnsSearchParams', state, newState);
    return newState;
  }),
  on(mergeUpdateUpcomingStatisticalReturnSearchParams, (state, {searchParams}) => {
    const minPeriodWinRatios: PeriodWinRatio[] = mergePeriodWinRatios(state.upcomingStatisticalReturnsSearch.searchParams.minPeriodWinRatios, searchParams.minPeriodWinRatios);
    const mergedSearchParams: StatisticalReturnsSearchParams = {...state.upcomingStatisticalReturnsSearch.searchParams, ...searchParams, minPeriodWinRatios};
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch, searchParams: {...mergedSearchParams},
    };
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('mergeUpdateUpcomingStatisticalReturnSearchParams', state, newState);
    return newState;
  }),
  on(setUpcomingStatisticalReturnsBuyDayNumber, (state, {buyDayNumber}) => {
    const searchParams: StatisticalReturnsSearchParams = {...state.upcomingStatisticalReturnsSearch.searchParams, buyDayNumber};
    const emptyResult: StatisticalReturnsSearchResult = {
      ...state.upcomingStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult,
    };
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch,
      searchParams: {...searchParams},
      searchResult: emptyResult,
    };
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('setUpcomingStatisticalReturnsBuyDayNumber', state, newState);
    return newState;
  }),
  on(setUpcomingStatisticalReturnsSortOrder, (state, {sortOrder}) => {
    const searchParams: StatisticalReturnsSearchParams = {...state.upcomingStatisticalReturnsSearch.searchParams, sortOrder};
    const sortedStatisticalReturns = sortStatisticalReturns(state.upcomingStatisticalReturnsSearch.searchResult.statisticalReturns, sortOrder, state.symbols, state.exchanges);
    const sortedSearchResults: StatisticalReturnsSearchResult = {
      ...state.upcomingStatisticalReturnsSearch.searchResult, statisticalReturns: sortedStatisticalReturns,
    };
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch,
      searchParams: {...searchParams},
      searchResult: sortedSearchResults,
    };
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('setUpcomingStatisticalReturnsSortOrder', state, newState);
    return newState;
  }),
  on(fetchUpcomingStatisticalReturnsSearchResults, (state) => {
    const emptyResult: StatisticalReturnsSearchResult = {
      ...state.upcomingStatisticalReturnsSearch.searchResult, ...emptyStatisticalReturnsSearchResult, allFilteredOut: false,
    };
    let searchParams = state.upcomingStatisticalReturnsSearch.searchParams;
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch,
      searchParams: {...searchParams, startAfter: undefined},
      searching: true,
      searchResult: emptyResult,
    };
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('fetchUpcomingStatisticalReturnsSearchResults', state, newState);
    return newState;
  }),
  on(fetchMoreUpcomingStatisticalReturnsSearchResults, (state) => {
    let searchParams = state.upcomingStatisticalReturnsSearch.searchParams;
    let searchResult = {...state.upcomingStatisticalReturnsSearch.searchResult, allFilteredOut: false};
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch,
      searchParams: {...searchParams, startAfter: searchResult.lastVisible},
      searching: true,
    };
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('fetchMoreUpcomingStatisticalReturnsSearchResults', state, newState);
    return newState;
  }),
  on(setUpcomingStatisticalReturnsSearchResults, (state, {searchResult}) => {
    let statisticalReturns: StatisticalReturn[] = [...state.upcomingStatisticalReturnsSearch.searchResult.statisticalReturns, ...searchResult.statisticalReturns];
    statisticalReturns = removeDuplicates(statisticalReturns);
    let searchParams = state.upcomingStatisticalReturnsSearch.searchParams;
    let sortOrder = searchParams.sortOrder;
    statisticalReturns = filterStatisticalReturns(statisticalReturns, searchParams, state.symbols, state.exchanges);
    statisticalReturns = sortStatisticalReturns(statisticalReturns, sortOrder, state.symbols, state.exchanges);
    const allFilteredOut = state.upcomingStatisticalReturnsSearch.searchResult.statisticalReturns.length >= statisticalReturns.length;
    const newSearchResult: StatisticalReturnsSearchResult = {...searchResult, statisticalReturns, allFilteredOut};
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {
      ...state.upcomingStatisticalReturnsSearch,
      searching: false,
      searchResult: newSearchResult,
    };
    const newStatisticalReturns = addCacheDatesAndIds([...searchResult.statisticalReturns]);
    const statisticalReturnsCache = mergeStatisticalReturnsRemovingOutdated(state.statisticalReturns, newStatisticalReturns);
    const newState = {...state, upcomingStatisticalReturnsSearch, statisticalReturns: statisticalReturnsCache};
    logDebugMessages('setUpcomingStatisticalReturnsSearchResults', state, newState);
    return newState;
  }),
  on(upcomingStatisticalReturnsSearchFailed, (state, {errorMessage}) => {
    const searchResult: StatisticalReturnsSearchResult = {
      ...state.upcomingStatisticalReturnsSearch.searchResult,
      errorMessage,
      thereIsMore: false,
      allFilteredOut: false,
    };
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {...state.upcomingStatisticalReturnsSearch, searching: false, searchResult};
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('upcomingStatisticalReturnsSearchFailed', state, newState);
    return newState;
  }),
  on(setUpcomingStatisticalReturnsExchangesFilter, (state, {exchangeShortNames}) => {
    const searchParams: StatisticalReturnsSearchParams = {...state.upcomingStatisticalReturnsSearch.searchParams, exchangeShortNames};
    const upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch = {...state.upcomingStatisticalReturnsSearch, searchParams};
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('setUpcomingStatisticalReturnsExchangesFilter', state, newState);
    return newState;
  }),
  on(filterUpcomingStatisticalReturns, (state) => {
    let searchParams = state.upcomingStatisticalReturnsSearch.searchParams;
    let searchResult = state.upcomingStatisticalReturnsSearch.searchResult;
    const statisticalReturns = filterStatisticalReturns(searchResult.statisticalReturns, searchParams, state.symbols, state.exchanges);
    searchResult = {...searchResult, statisticalReturns};
    const upcomingStatisticalReturnsSearch = {...state.upcomingStatisticalReturnsSearch, searchResult};
    const newState = {...state, upcomingStatisticalReturnsSearch};
    logDebugMessages('filterUpcomingStatisticalReturns', state, newState);
    return newState;
  }),
);

export function stockReducer(state: StockState | undefined, action: Action): StockState {
  return reducer(state, action);
}

/**
 * Merges two arrays of symbols removing all duplicates and outdated entries and only keeping the newer ones.
 * @param list1 first list. The order of the parameters does not matter.
 * @param list2 second list
 * @return merged list
 */
function mergeSymbolsRemovingOutdated(list1: Symbol[], list2: Symbol[]) {
  // Remove outdated elements from both lists
  const filteredList1 = list1.filter(it => !isOutdated(it, environment.defaultSymbolCacheAgeInSec));
  const filteredList2 = list2.filter(it => !isOutdated(it, environment.defaultSymbolCacheAgeInSec));

  const symbolsByCode = new Map<string, Symbol>();
  filteredList1.forEach(it => addSymbolToMap(it, symbolsByCode));
  filteredList2.forEach(it => addSymbolToMap(it, symbolsByCode));

  return [...symbolsByCode.values()];
}

/**
 * Merges two arrays of developmentSinceBuyDays removing all duplicates and outdated entries and only keeping the newer ones.
 * @param list1 first list. The order of the parameters does not matter.
 * @param list2 second list
 * @return merged list
 */
function mergeDevelopmentSinceBuyDaysRemovingOutdated(list1: DevelopmentSinceBuyDay[], list2: DevelopmentSinceBuyDay[]) {
  // Remove outdated elements from both lists
  const filteredList1 = list1.filter(it => !isOutdated(it, environment.defaultDevelopmentSinceBuyDayCacheAgeInSec));
  const filteredList2 = list2.filter(it => !isOutdated(it, environment.defaultDevelopmentSinceBuyDayCacheAgeInSec));

  const developmentSinceBuyDaysByCode = new Map<string, DevelopmentSinceBuyDay>();
  filteredList1.forEach(it => addDevelopmentSinceBuyDayToMap(it, developmentSinceBuyDaysByCode));
  filteredList2.forEach(it => addDevelopmentSinceBuyDayToMap(it, developmentSinceBuyDaysByCode));

  return [...developmentSinceBuyDaysByCode.values()];
}

/**
 * Merges two arrays of statisticalReturns removing all duplicates and outdated entries and only keeping the newer ones.
 * @param list1 first list. The order of the parameters does not matter.
 * @param list2 second list
 * @return merged list
 */
function mergeStatisticalReturnsRemovingOutdated(list1: StatisticalReturn[], list2: StatisticalReturn[]) {
  // Remove outdated elements from both lists
  const filteredList1 = list1.filter(it => !isOutdated(it, environment.defaultStatisticalReturnCacheAgeInSec));
  const filteredList2 = list2.filter(it => !isOutdated(it, environment.defaultStatisticalReturnCacheAgeInSec));

  const statisticalReturnsByCode = new Map<string, StatisticalReturn>();
  filteredList1.forEach(it => addStatisticalReturnToMap(it, statisticalReturnsByCode));
  filteredList2.forEach(it => addStatisticalReturnToMap(it, statisticalReturnsByCode));

  return [...statisticalReturnsByCode.values()];
}

/**
 * Merges two arrays of companyProfiles removing all duplicates and outdated entries and only keeping the newer ones.
 * @param list1 first list. The order of the parameters does not matter.
 * @param list2 second list
 * @return merged list
 */
function mergeCompanyProfilesRemovingOutdated(list1: CompanyProfile[], list2: CompanyProfile[]) {
  // Remove outdated elements from both lists
  const filteredList1 = list1.filter(it => !isOutdated(it, environment.defaultCompanyProfileCacheAgeInSec));
  const filteredList2 = list2.filter(it => !isOutdated(it, environment.defaultCompanyProfileCacheAgeInSec));

  const companyProfilesByCode = new Map<string, CompanyProfile>();
  filteredList1.forEach(it => addCompanyProfileToMap(it, companyProfilesByCode));
  filteredList2.forEach(it => addCompanyProfileToMap(it, companyProfilesByCode));

  return [...companyProfilesByCode.values()];
}

function isOutdated(cachedElement: any, cacheAgeInSec: number): boolean {
  const nowUtc = new Date().getTime();
  return !cachedElement.cacheDate || (nowUtc - cachedElement.cacheDate.getTime() > cacheAgeInSec * 1000);
}

/**
 * Adds the given symbol to the given map, but only, if the map doesn't contain a newer item
 * @param symbol
 * @param symbolsByCode
 */
function addSymbolToMap(symbol: Symbol, symbolsByCode: Map<string, Symbol>) {
  const symbolFromMap = symbolsByCode.get(symbol.code);
  if (!symbolFromMap)
    symbolsByCode.set(symbol.code, symbol);
  else
    symbolsByCode.set(symbol.code, getNewerCacheItem(symbol, symbolFromMap));
}

/**
 * Adds the given developmentSinceBuyDay to the given map, but only, if the map doesn't contain a newer item
 * @param developmentSinceBuyDay
 * @param developmentSinceBuyDaysByCode
 */
function addDevelopmentSinceBuyDayToMap(developmentSinceBuyDay: DevelopmentSinceBuyDay, developmentSinceBuyDaysByCode: Map<string, DevelopmentSinceBuyDay>) {
  const developmentSinceBuyDayFromMap = developmentSinceBuyDaysByCode.get(developmentSinceBuyDay.cacheId!);
  if (!developmentSinceBuyDayFromMap)
    developmentSinceBuyDaysByCode.set(developmentSinceBuyDay.cacheId!, developmentSinceBuyDay);
  else
    developmentSinceBuyDaysByCode.set(developmentSinceBuyDay.cacheId!, getNewerCacheItem(developmentSinceBuyDay, developmentSinceBuyDayFromMap));
}

/**
 * Adds the given statisticalReturn to the given map, but only, if the map doesn't contain a newer item
 * @param statisticalReturn
 * @param statisticalReturnsByCacheId
 */
function addStatisticalReturnToMap(statisticalReturn: StatisticalReturn, statisticalReturnsByCacheId: Map<string, StatisticalReturn>) {
  const statisticalReturnFromMap = statisticalReturnsByCacheId.get(statisticalReturn.cacheId!);
  if (!statisticalReturnFromMap)
    statisticalReturnsByCacheId.set(statisticalReturn.cacheId!, statisticalReturn);
  else
    statisticalReturnsByCacheId.set(statisticalReturn.cacheId!, getNewerCacheItem(statisticalReturn, statisticalReturnFromMap));
}

/**
 * Adds the given companyProfile to the given map, but only, if the map doesn't contain a newer item
 * @param companyProfile
 * @param companyProfilesByCode
 */
function addCompanyProfileToMap(companyProfile: CompanyProfile, companyProfilesByCode: Map<string, CompanyProfile>) {
  if (!companyProfile?.symbol)
    return;

  const companyProfileFromMap = companyProfilesByCode.get(companyProfile.symbol);
  if (!companyProfileFromMap)
    companyProfilesByCode.set(companyProfile.symbol, companyProfile);
  else
    companyProfilesByCode.set(companyProfile.symbol, getNewerCacheItem(companyProfile, companyProfileFromMap));
}

function getNewerCacheItem<T>(cachedElement1: any, cachedElement2: any): T {
  if (cachedElement1.cacheDate >= cachedElement2.cacheDate)
    return cachedElement1;
  return cachedElement2;
}

function addCacheDatesAndIds(statisticalReturns: StatisticalReturn[]): StatisticalReturn[] {
  const newList: StatisticalReturn[] = [];
  let now = new Date();
  statisticalReturns.forEach(it => {
    const statRet = {...it, cacheDate: now, cacheId: StatisticalTradingService.determineStatisticalReturnCacheId(it.symbolCode, it.uid)};
    newList.push(statRet);
  });
  return newList;
}

function removeDuplicates(statisticalReturns: StatisticalReturn[]) {
  let filteredList: StatisticalReturn[] = [];
  statisticalReturns.forEach(statRet => {
      if (filteredList.find(it => it.uid === statRet.uid && it.symbolCode === statRet.symbolCode) === undefined)
        filteredList.push(statRet);
      else
        console.error(`Duplicate StatisticalReturn ${statRet.symbolCode}/${statRet.uid} found in search results`);
    },
  );
  return filteredList;
}

function getDataYears(profitByYearMap: NumberByYearMap | undefined) {
  if (!profitByYearMap)
    return 0;
  return Object.keys(profitByYearMap).length;
}

function filterStatisticalReturns(statisticalReturns: StatisticalReturn[], searchParams?: StatisticalReturnsSearchParams, symbols: Symbol[] = [], exchanges: Exchange[] = []): StatisticalReturn[] {
  const filteredStatisticalReturns = [...statisticalReturns].filter(it => {
    if (!isMinValueSatisfied(it.averageRelativeProfit, searchParams?.minAverageRelativeProfit, true))
      return false;
    if (!isMinValueSatisfied(Util.getNumberOfDataYears(it.profitByYearMap), searchParams?.minDataYears, false))
      return false;
    if (!isMaxValueSatisfied(it.duration, searchParams?.maxDuration, false))
      return false;
    if (!isMinValueSatisfied(it.lowestYearlyProfit, searchParams?.minLowestYearlyProfit, true))
      return false;
    if (!isMinValueSatisfied(it.winRatio, searchParams?.minWinRatio, true))
      return false;
    if (!isMinValueSatisfied(it.averageMaxRise, searchParams?.minAverageMaxRise, true))
      return false;
    if (!isMinValueSatisfied(it.averageMaxDrop, searchParams?.maxAverageMaxDrop, true, false, true))
      return false;
    if (!isMinValueSatisfied(it.highestMaxRise, searchParams?.minHighestRise, true))
      return false;
    if (!isMinValueSatisfied(it.highestMaxDrop, searchParams?.maxHighestDrop, true, false, true))
      return false;
    if (!areMinPeriodWinRatiosSatisfied(it, searchParams?.minPeriodWinRatios))
      return false;
    let symbol = findSymbol(it.symbolCode, symbols);
    // At this time, some symbols might not be available. StatisticalReturnsTableComponent.loadSymbols gets called after filtering, when the Input of the table is updated.
    // If we don't know the symbol, we cannot filter by the remaining criteria. Keep the stat ret for now. We'll do another filtering after obtaining the symbol...
    if (!symbol)
      return true;
    if (!isMinValueSatisfied(symbol?.mktCapUsd, searchParams?.minMarketCap, false, true))
      return false;
    if (!isMinValueSatisfied(symbol?.volAvg, searchParams?.minAverageVolume, false))
      return false;
    if (!isMinValueSatisfied(symbol?.volAvgBaseCurrency, searchParams?.minAverageVolumeBaseCurrency, false))
      return false;
    return true;

  });
  return filteredStatisticalReturns;
}

/**
 *  Checks if the given value is greater than or equal to the minimum value.
 *  @param  givenValue - The value to check against the minimum value.
 *  @param  minValue - The minimum value to compare the given value against.
 *  @param  isMinValuePercentage - True, if the minValue is passed as a percentage, e.g. 5 for 0.05
 *  @param  isMinValueInMillions - True, if the minValue is passed in millions, e.g. 1 for 1.000.000
 *  @param shouldMinValueBeNegated - True, if the min. value should be negated
 *  @returns {boolean} - True if the given value is greater than or equal to the minimum value, false otherwise.
 */
function isMinValueSatisfied(givenValue: number | undefined, minValue: number | undefined, isMinValuePercentage = false, isMinValueInMillions = false, shouldMinValueBeNegated = false) {
  if (minValue === undefined)
    return true;
  if (givenValue === undefined)
    return false;
  let {adjustedThresholdValue, adjustedGivenValue} =
    adjustThresholdAndGivenValues(givenValue, minValue, isMinValuePercentage, isMinValueInMillions, shouldMinValueBeNegated);
  return adjustedGivenValue >= adjustedThresholdValue;
}

/**
 *  Checks if the given value is lower than or equal to the minimum value.
 *  @param  givenValue - The value to check against the maximum value.
 *  @param  maxValue - The maximum value to compare the given value against.
 *  @param  isMaxValuePercentage - True, if the maxValue is passed as a percentage, e.g. 5 for 0.05
 *  @param  isMaxValueInMillions - True, if the maxValue is passed in millions, e.g. 1 for 1.000.000
 *  @param shouldMaxValueBeNegated - True, if the max. value should be negated
 *  @returns {boolean} - True if the given value is greater than or equal to the maximum value, false otherwise.
 */
function isMaxValueSatisfied(givenValue: number | undefined, maxValue: number | undefined, isMaxValuePercentage = false, isMaxValueInMillions = false, shouldMaxValueBeNegated = false) {
  if (maxValue === undefined)
    return true;
  if (givenValue === undefined)
    return false;
  let {adjustedThresholdValue, adjustedGivenValue} =
    adjustThresholdAndGivenValues(givenValue, maxValue, isMaxValuePercentage, isMaxValueInMillions, shouldMaxValueBeNegated);
  return adjustedGivenValue <= adjustedThresholdValue;
}

/**
 *  Adjusts the threshold and given values based on the specified parameters.
 *  @param {number} givenValue - The given value to be adjusted.
 *  @param {number} thresholdValue - The maximum value to be used as a threshold.
 *  @param {boolean} isThresholdValuePercentage - Indicates whether the threshold value is a percentage.
 *  @param {boolean} isThresholdValueInMillions - Indicates whether the threshold value is in millions.
 *  @param shouldThresholdValueBeNegated - True, if the threshold value should be negated
 *  @returns {Object} An object containing the adjusted maximum value and the adjusted given value.
 */
function adjustThresholdAndGivenValues(givenValue: number, thresholdValue: number, isThresholdValuePercentage: boolean, isThresholdValueInMillions: boolean, shouldThresholdValueBeNegated: boolean) {
  let adjustedThresholdValue = thresholdValue;
  adjustedThresholdValue = isThresholdValuePercentage ? adjustedThresholdValue / 100.0 : adjustedThresholdValue;
  adjustedThresholdValue = isThresholdValueInMillions ? adjustedThresholdValue * 1000000 : adjustedThresholdValue;
  adjustedThresholdValue = shouldThresholdValueBeNegated ? -adjustedThresholdValue : adjustedThresholdValue;
  let adjustedGivenValue = givenValue;
  return {adjustedThresholdValue, adjustedGivenValue};
}

/**
 * Checks if the minimum period win ratios are satisfied based on the provided statistical return data.
 *
 * @param {StatisticalReturn} statRet - The statistical return data to evaluate.
 * @param {PeriodWinRatio[] | undefined} minPeriodWinRatios - An array of period win ratio objects or undefined.
 * @returns {boolean} - Returns `true` if all specified period win ratios are satisfied, `false` otherwise.
 *
 */
function areMinPeriodWinRatiosSatisfied(statRet: StatisticalReturn, minPeriodWinRatios: PeriodWinRatio[] | undefined) {
  if (!statRet)
    return false;
  if (!minPeriodWinRatios || minPeriodWinRatios.length === 0)
    return true;
  for (let {period, minWinRatio} of minPeriodWinRatios) {
    if (!minWinRatio || !period)
      continue;
    let winRatio = StatisticalTradingService.getWinRatio(statRet, period);
    if (!winRatio || winRatio < minWinRatio / 100)
      return false;
  }
  return true;
}

function sortStatisticalReturns(statisticalReturns: StatisticalReturn[], sortOrder?: StatisticalReturnsSortOrder, symbols: Symbol[] = [], exchanges: Exchange[] = []): StatisticalReturn[] {
  if (sortOrder === undefined)
    return statisticalReturns;
  const sortedStatisticalReturns = [...statisticalReturns].sort((a: StatisticalReturn, b: StatisticalReturn) => {
    switch (sortOrder) {
      case StatisticalReturnsSortOrder.SymbolCodeAsc:
        return Util.compareWithUndefined(a.symbolCode, b.symbolCode);
      case StatisticalReturnsSortOrder.SymbolCodeDesc:
        return Util.compareWithUndefined(a.symbolCode, b.symbolCode, false);
      case StatisticalReturnsSortOrder.SymbolNameAsc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.name, findSymbol(b.symbolCode, symbols)?.name);
      case StatisticalReturnsSortOrder.SymbolNameDesc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.name, findSymbol(b.symbolCode, symbols)?.name, false);
      case StatisticalReturnsSortOrder.ExchangeNameAsc:
        return Util.compareWithUndefined(findExchange(a.exchangeShortName, exchanges)?.name, findExchange(b.exchangeShortName, exchanges)?.name);
      case StatisticalReturnsSortOrder.ExchangeNameDesc:
        return Util.compareWithUndefined(findExchange(a.exchangeShortName, exchanges)?.name, findExchange(b.exchangeShortName, exchanges)?.name, false);
      case StatisticalReturnsSortOrder.AverageRelativeProfitAsc:
        return Util.compareWithUndefined(a.averageRelativeProfit, b.averageRelativeProfit);
      case StatisticalReturnsSortOrder.AverageRelativeProfitDesc:
        return Util.compareWithUndefined(a.averageRelativeProfit, b.averageRelativeProfit, false);
      case StatisticalReturnsSortOrder.AvgVolumeAsc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.volAvg, findSymbol(b.symbolCode, symbols)?.volAvg);
      case StatisticalReturnsSortOrder.AvgVolumeDesc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.volAvg, findSymbol(b.symbolCode, symbols)?.volAvg, false);
      case StatisticalReturnsSortOrder.BuyDateAsc:
        return Util.compareWithUndefined(a.buyDayNumber, b.buyDayNumber);
      case StatisticalReturnsSortOrder.BuyDateDesc:
        return Util.compareWithUndefined(a.buyDayNumber, b.buyDayNumber, false);
      case StatisticalReturnsSortOrder.SellDateAsc:
        return Util.compareWithUndefined(Util.calculateDayNumber(a.sellMonth, a.sellDay), Util.calculateDayNumber(b.sellMonth, b.sellDay));
      case StatisticalReturnsSortOrder.SellDateDesc:
        return Util.compareWithUndefined(Util.calculateDayNumber(a.sellMonth, a.sellDay), Util.calculateDayNumber(b.sellMonth, b.sellDay), false);
      case StatisticalReturnsSortOrder.PriceAsc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.price, findSymbol(b.symbolCode, symbols)?.price);
      case StatisticalReturnsSortOrder.PriceDesc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.price, findSymbol(b.symbolCode, symbols)?.price, false);
      case StatisticalReturnsSortOrder.DataYearsAsc:
        return Util.compareWithUndefined(getDataYears(a.profitByYearMap), getDataYears(b.profitByYearMap));
      case StatisticalReturnsSortOrder.DataYearsDesc:
        return Util.compareWithUndefined(getDataYears(a.profitByYearMap), getDataYears(b.profitByYearMap), false);
      case StatisticalReturnsSortOrder.DurationAsc:
        return Util.compareWithUndefined(a.duration, b.duration);
      case StatisticalReturnsSortOrder.DurationDesc:
        return Util.compareWithUndefined(a.duration, b.duration, false);
      case StatisticalReturnsSortOrder.MarketCapAsc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.mktCapUsd, findSymbol(b.symbolCode, symbols)?.mktCapUsd);
      case StatisticalReturnsSortOrder.MarketCapDesc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.mktCapUsd, findSymbol(b.symbolCode, symbols)?.mktCapUsd, false);
      case StatisticalReturnsSortOrder.CurrencyAsc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.currency, findSymbol(b.symbolCode, symbols)?.currency);
      case StatisticalReturnsSortOrder.CurrencyDesc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.currency, findSymbol(b.symbolCode, symbols)?.currency, false);
      case StatisticalReturnsSortOrder.WinRatioAsc:
        return Util.compareWithUndefined(a.winRatio, b.winRatio);
      case StatisticalReturnsSortOrder.WinRatioDesc:
        return Util.compareWithUndefined(a.winRatio, b.winRatio, false);
      case StatisticalReturnsSortOrder.WorstYearAsc:
        return Util.compareWithUndefined(a.lowestYearlyProfit, b.lowestYearlyProfit);
      case StatisticalReturnsSortOrder.WorstYearDesc:
        return Util.compareWithUndefined(a.lowestYearlyProfit, b.lowestYearlyProfit, false);
      case StatisticalReturnsSortOrder.HighestMaxRiseAsc:
        return Util.compareWithUndefined(a.highestMaxRise, b.highestMaxRise);
      case StatisticalReturnsSortOrder.HighestMaxRiseDesc:
        return Util.compareWithUndefined(a.highestMaxRise, b.highestMaxRise, false);
      case StatisticalReturnsSortOrder.HighestMaxDropAsc:
        return Util.compareWithUndefined(a.highestMaxDrop, b.highestMaxDrop);
      case StatisticalReturnsSortOrder.HighestMaxDropDesc:
        return Util.compareWithUndefined(a.highestMaxDrop, b.highestMaxDrop, false);
      case StatisticalReturnsSortOrder.AvgMaxRiseAsc:
        return Util.compareWithUndefined(a.averageMaxRise, b.averageMaxRise);
      case StatisticalReturnsSortOrder.AvgMaxRiseDesc:
        return Util.compareWithUndefined(a.averageMaxRise, b.averageMaxRise, false);
      case StatisticalReturnsSortOrder.AvgMaxDropAsc:
        return Util.compareWithUndefined(a.averageMaxDrop, b.averageMaxDrop);
      case StatisticalReturnsSortOrder.AvgMaxDropDesc:
        return Util.compareWithUndefined(a.averageMaxDrop, b.averageMaxDrop, false);
      case StatisticalReturnsSortOrder.AvgVolumePriceAsc:
        return Util.compareWithUndefined(calcAvgVolumePrice(findSymbol(a.symbolCode, symbols)), calcAvgVolumePrice(findSymbol(b.symbolCode, symbols)));
      case StatisticalReturnsSortOrder.AvgVolumePriceDesc:
        return Util.compareWithUndefined(calcAvgVolumePrice(findSymbol(a.symbolCode, symbols)), calcAvgVolumePrice(findSymbol(b.symbolCode, symbols)), false);
      case StatisticalReturnsSortOrder.AvgVolumeBaseCurrencyeAsc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.volAvgBaseCurrency, findSymbol(b.symbolCode, symbols)?.volAvgBaseCurrency);
      case StatisticalReturnsSortOrder.AvgVolumeBaseCurrencyDesc:
        return Util.compareWithUndefined(findSymbol(a.symbolCode, symbols)?.volAvgBaseCurrency, findSymbol(b.symbolCode, symbols)?.volAvgBaseCurrency, false);
      default:
        return Util.compareWithUndefined(a.averageRelativeProfit, b.averageRelativeProfit, false);
    }
  });
  return sortedStatisticalReturns;
}

function findSymbol(symbolCode: string, symbols: Symbol[]) {
  return symbols.find(it => it.code === symbolCode);
}

function findExchange(exchangeShortName: string | undefined, exchanges: Exchange[]) {
  return exchanges.find(it => it.shortName === exchangeShortName);
}

function calcAvgVolumePrice(symbol: Symbol | undefined) {
  if (!symbol || !symbol.price || !symbol.volAvg)
    return undefined;
  return symbol.price * symbol.volAvg;
}


