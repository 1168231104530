import firebase from 'firebase/app';

import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

export class Wrapper<T> {
  data?: T;
  errorMessage?: string;
  lastVisible?: QueryDocumentSnapshot<any>;
  success?: boolean;

  constructor(data?: T, errorMessage?: string, lastVisible?: QueryDocumentSnapshot<any>, success?: boolean) {
    this.data = data;
    this.errorMessage = errorMessage;
    this.lastVisible = lastVisible;
    this.success = success;
  }

}
