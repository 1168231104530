<ng-container
        *ngIf="period !== undefined && statRet !== undefined && statRet.profitByPeriodMap !== undefined && statRet.profitByPeriodMap[period] !== undefined; else none">
  <span [class]="statRet.profitByPeriodMap![period]! | valueClassPipe">
    <app-percentage-view [value]="statRet.profitByPeriodMap![period]!"></app-percentage-view>
  </span>
</ng-container>

<ng-template #none>
  -
</ng-template>
