import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {User as FirestoreUser} from '../../models/user.interface';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../../auth/auth.service';
import {Alert} from '../../models/alert.interface';
import {AlertType} from '../../enums/alertType.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {selectFetchUserActionResult, selectFirebaseUser, selectUpdateUserActionResult, selectUser} from '../../../auth/store/auth.selectors';
import {FirebaseUser} from '../../models/firebaseUser.interface';
import {UserActionResult} from '../../models/userActionResult.interface';

/**
 * Base component, which should be inherited by most other components, which need access to the logged in user. It will be provided in the fields:
 * - firebaseUser
 * - user
 */
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
})
export class BaseComponent implements OnInit, OnDestroy {
  destroy$: Subject<null> = new Subject();

  /**
   * Store selector for logged in firebase authentication user
   */
  firebaseUser$ = this.store.select(selectFirebaseUser).pipe(takeUntil(this.destroy$));
  /**
   * The logged in firebase authentication user
   */
  firebaseUser?: FirebaseUser; // from the firestore users collection

  /**
   * Store selector for logged in firestore user
   */
  user$ = this.store.select(selectUser).pipe(takeUntil(this.destroy$));
  /**
   * The logged in Firestore user
   */
  user?: FirestoreUser; // from the firestore users collection

  /**
   * Store selector for the result of fetching the Firestore user
   */
  fetchUserActionResult$ = this.store.select(selectFetchUserActionResult).pipe(takeUntil(this.destroy$));
  /**
   * The result of fetching the Firestore user
   */
  fetchUserActionResult?: UserActionResult;

  /**
   * Store selector for the result of updating the Firestore user
   */
  updateUserActionResult$ = this.store.select(selectUpdateUserActionResult).pipe(takeUntil(this.destroy$));
  /**
   * The result of updating the Firestore user
   */
  updateUserActionResult?: UserActionResult;

  /**
   * Alerts to be shown in the <app-alerts> component
   */
  alerts: Alert[] = [];
  /**
   * Flag, that states, if the loading spinner should be visible or not.
   */
  showLoadingSpinner = false;
  /**
   * A simple message to be shown below the loading spinner
   */
  loadingSpinnerMessage ?: string;
  /**
   * A map of key/message pairs for multiple message to be shown below the loading spinner
   */
  loadingSpinnerMessagesByKey = new Map<string, string>();


  /**
   * Subject used to scroll to alerts. Should be added to <app-alerts>
   */
  scrollToAlertsSubject = new Subject();

  constructor(
      protected authService: AuthService,
      protected userService: UserService,
      protected store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.user$.subscribe(user =>
        this.user = user);
    this.firebaseUser$.subscribe(firebaseUser =>
        this.firebaseUser = firebaseUser);
    this.fetchUserActionResult$.subscribe(fetchUserActionResult => {
      this.fetchUserActionResult = fetchUserActionResult;
    });
    this.updateUserActionResult$.subscribe(updateUserActionResult => {
      this.updateUserActionResult = updateUserActionResult;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  // *********************************************
  // Error handling
  // *********************************************

  /**
   * Deletes all alerts.
   */
  clearAlerts(): void {
    this.alerts.length = 0;
  }

  /**
   * Deletes all errors, while leaving all other alerts.
   */
  clearErrors(): void {
    const newAlerts: Alert[] = [];
    this.alerts.forEach(alert => {
      if (alert.type === AlertType.Error)
        return;
      newAlerts.push(alert);
    });
    this.alerts = newAlerts;
  }

  /**
   * Deletes all success alerts, while leaving all other alerts.
   */
  clearSuccessAlerts(): void {
    const newAlerts: Alert[] = [];
    this.alerts.forEach(alert => {
      if (alert.type === AlertType.Success)
        return;
      newAlerts.push(alert);
    });
    this.alerts = newAlerts;
  }

  /**
   * Adds an alert of type Error.
   * @param message alert message
   */
  addError(message: string): void {
    this.alerts.push({message, type: AlertType.Error, date: new Date()});
    this.scrollToAlertsSubject.next();
  }

  /**
   * Adds an alert of type Info.
   * @param message alert message
   */
  addInfo(message: string): void {
    this.alerts.push({message, type: AlertType.Info, date: new Date()});
    this.scrollToAlertsSubject.next();
  }

  /**
   * Adds an alert of type Warning.
   * @param message alert message
   */
  addWarning(message: string): void {
    this.alerts.push({message, type: AlertType.Warning, date: new Date()});
    this.scrollToAlertsSubject.next();
  }

  /**
   * Adds an alert of type Success.
   * @param message alert message
   */
  addSuccess(message: string): void {
    this.alerts.push({message, type: AlertType.Success, date: new Date()});
    this.scrollToAlertsSubject.next();
  }

  getErrors(): Alert[] {
    const errors: Alert[] = [];
    this.alerts.forEach(alert => {
      if (alert.type === AlertType.Error)
        errors.push(alert);
    });
    return errors;
  }

  /**
   * Shows the loading spinner and sets the given message (if there is any).
   * @param message message to be shown or undefined
   */
  enableLoadingSpinner(message?: string): void {
    this.showLoadingSpinner = true;
    this.loadingSpinnerMessage = message;
  }

  /**
   * Hides the loading spinner and deletes its message.
   */
  disableLoadingSpinner(): void {
    this.showLoadingSpinner = false;
    this.loadingSpinnerMessage = undefined;
  }

  /**
   * Adds a message with the given key to the loading spinner messages map and enables the loading spinner.
   * @param key key of the message, e.g. 'loadingListing'
   * @param message message to be shown below the loading spinner
   */
  addLoadingSpinnerMessage(key: string, message: string): void {
    this.showLoadingSpinner = true;
    this.loadingSpinnerMessagesByKey.set(key, message);
  }

  /**
   * Removes the message with the given key from the loading spinner. If it was the last one, hides the spinner.
   * @param key key of the message to be removed, e.g. 'loadingListing'
   */
  removeLoadingSpinnerMessage(key: string): void {
    this.loadingSpinnerMessagesByKey.delete(key);
    if (this.loadingSpinnerMessagesByKey.size === 0)
      this.showLoadingSpinner = false;
  }


}
