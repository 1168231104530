import * as moment from 'moment';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import Util from './util';
import {environment} from '../../environments/environment';
import {formatDate, ViewportScroller} from '@angular/common';
import {ConfirmButton, ConfirmDialogComponent, ConfirmDialogModel} from './components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {User} from './models/user.interface';
import {DATE_FORMAT_MONTH_DATE, DEFAULT_DATE_FORMAT} from './constants/strings';


@Injectable({
  providedIn: 'root',
})
export class UtilService {

  private imageFileTypes = [
    'image/apng',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ];

  constructor(@Inject(LOCALE_ID) private locale: string,
              private viewportScroller: ViewportScroller,
              private dialog: MatDialog) {
  }

  /**
   * Scrolls to the DOM ID with the given elementId.
   * @param elementId ID of a DOM element
   */
  scrollToId(elementId: string): void {
    // Use a timeout to give the target component some time to load (if necessary)
    setTimeout(args => {
      this.viewportScroller.scrollToAnchor(elementId);
    }, 50);
  }

  validateFile(file: File): boolean {
    // If there is no file, it can't be invalid, right?
    if (!file || file === null)
      return true;
    return !this.imageFileTypes.includes(file.type);
  }

  /**
   * Converts the given parameter to a date, if it is something date-like.
   * @param date date parameter. Can be a firebase timestamp, a Date or have the fields seconds and nanoseconds
   * @return Date
   */
  public getDate(date: any): Date {
    return Util.getDate(date);
  }

  /**
   * Creates a from now string from the given date. It will be formatted in the language set in environment.momentLocale
   * @param date date, for which the from now string should be created.
   * @return from now string
   */
  public createFromNowString(date: Date) {
    return Util.createFromNowString(date);
  };

  public isAccountSetupIncomplete(user?: User): boolean {
    if (!user)
      return false;
    if (!user?.firstName)
      return true;
    if (!user?.lastName)
      return true;
    if (!user?.address?.formattedAddress)
      return true;
    return false;
  }

  /**
   * Redirects to the given external URL.
   * @param url URL to redirect to.
   */
  redirectExternal(url: string) {
    if (!environment.production)
      console.log(`Redirecting to ${url}.`);
    window.location.href = url;
  }

  public humanFileSize(bytes: number, si = false, dp = 1) {
    return Util.humanFileSize(bytes, si, dp);
  }

  /**
   * Shows a confirmation dialog
   * @param title confirmation title
   * @param message confirmation message
   * @param yesCallback callback, if the user clicks yes
   * @param yesParams params for the yes callback, e.g ['a string', listing, 3]
   * @param noCallback callback, if the user clicks no
   * @param noParams params for the no callback, e.g ['a string', listing, 3]
   * @param imageUrl optional image to be shown in the confirmation
   * @param primaryButton if 'yes', the yes button will be blue, come first and be preselected; the no button will be white.
   *                      If 'no', the no button will be 'red', come first and be preselected; the yes button will be white. 'yes' is default.
   */
  public showConfirmDialog(title: string, message: string, yesCallback: ((args?: any, data?: any) => void), yesParams: any[] = [],
                           noCallback?: ((args?: any, data?: any) => void), noParams: any[] = [], imageUrl?: string, primaryButton: ConfirmButton = 'yes'): void {
    const dialogData = new ConfirmDialogModel(title, message, imageUrl, primaryButton);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {maxWidth: '400px', data: dialogData});
    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult)
        yesCallback(...yesParams);
      else if (noCallback)
        noCallback(...noParams);
    });
  }

  /**
   * Formats the given date with the given format string.
   * @param date date to be formatted
   * @param formatString format string, e.g. 'yyyy-MM-dd'
   * @return date string
   */
  public formatDate(date: Date, formatString: string): string {
    return formatDate(date, formatString, this.locale);
  }

  /**
   * Parses the given date string using the given format string.
   * @param dateString date string to be parsed
   * @param momentFormatString format string in moment.js notation, e.g. 'YYYY-MM-DD'
   */
  public parseDateMoment(dateString: string, momentFormatString: string): Date {
    return moment(dateString, momentFormatString).toDate();
  }

  /**
   * Formats the given date in the format 'yyyy-MM-dd'
   * @param date date to be formatted
   * @return date string
   */
  public formatDateDefault(date: Date): string {
    return this.formatDate(date, DEFAULT_DATE_FORMAT);
  }

  /**
   * Parses da date string in the default format 'yyyy-MM-dd'
   * @param dateString date string in format 'yyyy-MM-dd' to be parsed
   * @return date
   */
  public parseDateDefault(dateString: string): Date {
    return new Date(Date.parse(dateString));
  }

  public formatDateMonthDay(date: Date) {
    return this.formatDate(date, DATE_FORMAT_MONTH_DATE);
  }
}
