import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {AuthService} from '../../auth/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CurrencyService} from '../../shared/services/currency.service';
import {UtilService} from '../../shared/util.service';
import {CANNOT_BE_UNDONE} from '../../shared/constants/strings';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent extends BaseComponent implements OnInit {

  private readonly spinnerKeyDeleteOldStatisticalReturns = 'deleteOldStatisticalReturns';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private cdRef: ChangeDetectorRef,
      private modalService: NgbModal,
      private currencyService: CurrencyService,
      private utilService: UtilService,
      private titleService: TitleService,
      private adminService: AdminService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Admin tools`);
  }

  onDeleteOldStatisticalReturnsClicked(): void {
    const title = $localize`Are you sure you want to delete all old statistical returns?`;
    this.utilService.showConfirmDialog(title, CANNOT_BE_UNDONE, this.deleteOldStatisticalReturns.bind(this), undefined, undefined, undefined, undefined, 'no');
  }

  deleteOldStatisticalReturns(): void {
    this.clearAlerts();
    this.addLoadingSpinnerMessage(this.spinnerKeyDeleteOldStatisticalReturns, $localize`Deleting all old statistical returns`);
    this.adminService.deleteOldStatisticalReturns().then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyDeleteOldStatisticalReturns);
      if (wrapper.data !== undefined) {
        this.addInfo($localize`Successfully deleted all ${wrapper.data.statisticalReturnsDeletionCount} old statistical returns and ${wrapper.data.symbolDeletionCount} symbols.`);
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });

  }


}
