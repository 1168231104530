import {Injectable} from '@angular/core';
import {Address} from '../models/address.interface';
import {MapsAPILoader} from '@agm/core';
import Geocoder = google.maps.Geocoder;
import GeocoderStatus = google.maps.GeocoderStatus;
import GeocoderResult = google.maps.GeocoderResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

@Injectable({
  providedIn: 'root',
})
export class GeoService {

  geoCoder: Geocoder | undefined;

  constructor(
    private mapsAPILoader: MapsAPILoader) {

  }

  initializeGeoCoder(callAfterInitialization: any): void {
    this.mapsAPILoader.load().then(() => {

      if (!this.geoCoder)
        this.geoCoder = new google.maps.Geocoder;
      callAfterInitialization();
    });
  }

  getAddress(lat: number, lng: number, callback: (results: GeocoderResult[], status: GeocoderStatus) => void): void {
    if (!this.geoCoder)
      this.initializeGeoCoder(() => {
        this.geoCoder?.geocode({'location': {lat: lat, lng: lng}}, callback);
      });
    else
      this.geoCoder?.geocode({'location': {lat: lat, lng: lng}}, callback);
  }

  /**
   * Delivers the current location from the browser (if the user agrees on sharing their location with Stock Forecast).
   */
  getCurrentLocation(successCallback: PositionCallback, errorCallback?: PositionErrorCallback): void {
    if ('geolocation' in navigator) {
      if (!errorCallback)
        errorCallback = (positionError: GeolocationPositionError) => {
          console.log(positionError);
        };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
  }

  /**
   * Converts the given array of GeocoderAddressComponents to an Address object.
   * @param addressComponents GeocoderAddressComponents
   * @return Address object consisting of the elements of this GeocoderAddressComponents array
   */
  convertGeocoderAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]): Address {
    let houseNumber: string | undefined;
    let street: string | undefined;
    let district: string | undefined;
    let city: string | undefined;
    let zipCode: string | undefined;
    let state: string | undefined;
    let countryCode: string | undefined;
    // loop through to get address components
    for (const component of addressComponents) {
      houseNumber = this.findAddressValueForType(houseNumber, component, 'street_number');
      street = this.findAddressValueForType(street, component, 'route');
      district = this.findAddressValueForType(district, component, 'sublocality');
      city = this.findAddressValueForType(city, component, 'locality');
      if (!city)
        city = this.findAddressValueForType(city, component, 'political');
      zipCode = this.findAddressValueForType(zipCode, component, 'postal_code');
      state = this.findAddressValueForType(state, component, 'administrative_area_level_1');
      countryCode = this.findAddressValueForType(countryCode, component, 'country', true);
    }
    const address: Address = {};
    if (houseNumber)
      address.houseNumber = houseNumber;
    if (street)
      address.street = street;
    if (district)
      address.district = district;
    if (city)
      address.city = city;
    if (zipCode)
      address.zipCode = zipCode;
    if (state)
      address.state = state;
    if (countryCode)
      address.countryCode = countryCode;
    return address;
  }

  /**
   * Find the address value for the given type in the given component. If it can't be found, the previous value is returned.
   * @param component address component, in which the value is searched
   * @param type one of 'street_number', 'route', 'sublocality', 'locality', 'postal_code', 'administrative_area_level_1', 'country'
   *  @param shortName if true, the short name is returned. Otherwise (also, if the parameter is omitted), the long name is returned.
   * @return value or previous value
   */
  private findAddressValueForType(previousValue: string | undefined, component: GeocoderAddressComponent, type: string, shortName: boolean = false): string | undefined {
    if (component.types.indexOf(type) !== -1) {
      if (shortName)
        return component.short_name;
      else
        return component.long_name;
    }
    return previousValue;
  }

}
