import {Component, Input, OnInit} from '@angular/core';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';
import {StatisticalTradingService} from '../../../statistical-trading.service';
import Util from '../../../../shared/util';

@Component({
  selector: 'app-period-win-ratio-data',
  templateUrl: './period-win-ratio-data.component.html',
  styleUrls: ['./period-win-ratio-data.component.scss'],
})
export class PeriodWinRatioDataComponent implements OnInit {

  @Input() period?: string;
  @Input() statRet?: StatisticalReturn;

  periodWinRatio?: null | number;

  constructor() {
  }

  ngOnInit(): void {
    if (this.statRet && this.period && this.statRetHasEnoughDataYears(this.statRet, this.period))
      this.periodWinRatio = StatisticalTradingService.getWinRatio(this.statRet, this.period);
  }

  /**
   * Checks if the statistical data has enough years of data for the specified period.
   *
   * @param {StatisticalReturn} statRet - The statistical data to be checked.
   * @param {string} periodString - The period as a string to check data availability for.
   * @returns {boolean} - True if there are enough data years available, false otherwise.
   *
   * @description
   * This method determines if the provided statistical data contains a sufficient number of years
   * to cover the specified period. It converts the period to a number and then verifies
   * if the number of years from the earliest available year in the data to the current year
   * meets or exceeds the specified period. The method returns false if the data
   * is not sufficient or if any necessary information is missing.
   */
  private statRetHasEnoughDataYears(statRet: StatisticalReturn, periodString: string): boolean {
    const period = Number(periodString);
    const profitByYearMap = statRet?.profitByYearMap;

    if (!profitByYearMap) {
      return false;
    }

    const allYears = StatisticalTradingService.getYears(profitByYearMap).sort();
    if (!allYears || allYears.length === 0)
      return false;

    const earliestYear = Number(allYears[0]);
    const currentYear = Util.getCurrentYear();
    return currentYear - earliestYear >= period;
  }
}
