import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export enum StatisticalSimulationSortOrder {
  YearAsc = 'yearAsc',
  MinAverageRelativeProfitAsc = 'MinAverageRelativeProfitAsc',
  RoiAsc = 'RoiAsc',
  MaxHoldingDurationAsc = 'MaxHoldingDurationAsc',
  MinDataYearsAsc = 'MinDataYearsAsc',
  MaxCapitalPerTradeAsc = 'MaxCapitalPerTradeAsc',
  MinPricePerStockAsc = 'MinPricePerStockAsc',
  SeedCapitalAsc = 'SeedCapitalAsc',
  FinalCapitalAsc = 'FinalCapitalAsc',
  NumberOfTradesAsc = 'NumberOfTradesAsc',
  ExchangesAsc = 'ExchangesAsc',
  YearDesc = 'yearDesc',
  MinAverageRelativeProfitDesc = 'MinAverageRelativeProfitDesc',
  RoiDesc = 'RoiDesc',
  MaxHoldingDurationDesc = 'MaxHoldingDurationDesc',
  MinDataYearsDesc = 'MinDataYearsDesc',
  MaxCapitalPerTradeDesc = 'MaxCapitalPerTradeDesc',
  MinPricePerStockDesc = 'MinPricePerStockDesc',
  SeedCapitalDesc = 'SeedCapitalDesc',
  FinalCapitalDesc = 'FinalCapitalDesc',
  NumberOfTradesDesc = 'NumberOfTradesDesc',
  ExchangesDesc = 'ExchangesDesc',
}


export interface StatisticalSimulationSearchParams {
  year?: number;

  /**
   The minimum average relative profit. Lower search limit.
   */
  minAverageRelativeProfitMin?: number;

  /**
   The minimum average relative profit. Upper search limit.
   */
  minAverageRelativeProfitMax?: number;

  /**
   The minimum return on investment.
   */
  minRoi?: number;

  /**
   The maximum holding duration. Lower search limit.
   */
  maxHoldingDurationMin?: number;

  /**
   The maximum holding duration. Upper search limit.
   */
  maxHoldingDurationMax?: number;

  /**
   The minimum number of data years used in the simulation.
   */
  minDataYears?: number;

  /**
   The maximum amount of capital used per trade. Lower search limit.
   */
  maxCapitalPerTradeMin?: number;

  /**
   The maximum amount of capital used per trade. Upper search limit.
   */
  maxCapitalPerTradeMax?: number;

  /**
   The maximum amount of capital used per trade. Lower search limit.
   */
  seedCapitalMin?: number;

  /**
   The maximum amount of capital used per trade. Upper search limit.
   */
  seedCapitalMax?: number;

  /**
   * Exchange short names to be used exclusively in the simulation.
   */
  exchangeShortNames?: string[];

  sortOrder?: StatisticalSimulationSortOrder;

  limit?: number;
  startAfter?: DocumentSnapshot;
}
