import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Language} from '../../shared/models/language.interface';
import {LanguageService} from '../../shared/services/language.service';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  collapsed = true;

  searchForm: FormGroup | undefined;


  langs: Language[] = [];
  iconSize = 32;
  currentLang ?: Language;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public languageService: LanguageService,
      private router: Router) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.langs = this.languageService.languages;
    // Load and set user's lang
    this.user$.subscribe(user => {
      if (!user)
        return;
      const userLangCode = user.settings?.lang;
      this.currentLang = this.langs.find(lang => lang.code === userLangCode);
    });
  }

  ngOnDestroy(): void {
  }


  collapseNavbar() {
    this.collapsed = true;
  }


  selectLang(lang: Language) {
    this.currentLang = lang;
    if (lang)
      this.languageService.changeAndSaveLanguage.next(lang);
  }
}
