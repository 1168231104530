<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the minimum win ratio in percent to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <!-- Min win ratio over all time -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>All time</mat-label>
          <input cdkFocusInitial #minWinRatioInput type="number" matInput name="minWinRatio" formControlName="minWinRatio" placeholder="80" id="minWinRatio"
                 [min]="0" [max]="100" (change)="saveMinWinRatio(minWinRatioInput.value)">
          <mat-icon (click)="clearMinWinRatio()" *ngIf="minWinRatioInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

      <!-- Min win ratio over last 5 years -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>Last 5 years</mat-label>
          <input #minWinRatioInput5 type="number" matInput name="minWinRatio5" formControlName="minWinRatio5" placeholder="80" id="minWinRatio5"
                 [min]="0" [max]="100" (change)="saveMinWinRatioForPeriod(minWinRatioInput5.value,'5')">
          <mat-icon (click)="clearMinWinRatioForPeriod('5')" *ngIf="minWinRatioInput5.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

      <!-- Min win ratio over last 10 years -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>Last 10 years</mat-label>
          <input #minWinRatioInput10 type="number" matInput name="minWinRatio10" formControlName="minWinRatio10" placeholder="80" id="minWinRatio10"
                 [min]="0" [max]="100" (change)="saveMinWinRatioForPeriod(minWinRatioInput10.value,'10')">
          <mat-icon (click)="clearMinWinRatioForPeriod('10')" *ngIf="minWinRatioInput10.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

      <!-- Min win ratio over last 15 years -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>Last 15 years</mat-label>
          <input #minWinRatioInput15 type="number" matInput name="minWinRatio15" formControlName="minWinRatio15" placeholder="80" id="minWinRatio15"
                 [min]="0" [max]="100" (change)="saveMinWinRatioForPeriod(minWinRatioInput15.value,'15')">
          <mat-icon (click)="clearMinWinRatioForPeriod('15')" *ngIf="minWinRatioInput15.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

      <!-- Min win ratio over last 20 years -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>Last 20 years</mat-label>
          <input #minWinRatioInput20 type="number" matInput name="minWinRatio20" formControlName="minWinRatio20" placeholder="80" id="minWinRatio20"
                 [min]="0" [max]="100" (change)="saveMinWinRatioForPeriod(minWinRatioInput20.value,'20')">
          <mat-icon (click)="clearMinWinRatioForPeriod('20')" *ngIf="minWinRatioInput20.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

      <!-- Min win ratio over last 25 years -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>Last 25 years</mat-label>
          <input #minWinRatioInput25 type="number" matInput name="minWinRatio25" formControlName="minWinRatio25" placeholder="80" id="minWinRatio25"
                 [min]="0" [max]="100" (change)="saveMinWinRatioForPeriod(minWinRatioInput25.value,'25')">
          <mat-icon (click)="clearMinWinRatioForPeriod('25')" *ngIf="minWinRatioInput25.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

      <!-- Min win ratio over last 30 years -->
      <div class="col-auto px-1">
        <mat-form-field class="input-sm">
          <mat-label i18n>Last 30 years</mat-label>
          <input #minWinRatioInput30 type="number" matInput name="minWinRatio30" formControlName="minWinRatio30" placeholder="80" id="minWinRatio30"
                 [min]="0" [max]="100" (change)="saveMinWinRatioForPeriod(minWinRatioInput30.value,'30')">
          <mat-icon (click)="clearMinWinRatioForPeriod('30')" *ngIf="minWinRatioInput30.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>

    </div>
  </form>

</ng-template>
