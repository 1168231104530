import {Component, Input, OnInit} from '@angular/core';
import {StatisticalTrade} from '../../../../shared/models/statisticalTrade.interface';
import Util from '../../../../shared/util';

@Component({
  selector: 'app-plan-start-or-end-date',
  templateUrl: './plan-start-or-end-date.component.html',
  styleUrls: ['./plan-start-or-end-date.component.scss'],
})
export class PlanStartOrEndDateComponent implements OnInit {


  @Input() trades?: StatisticalTrade[];
  @Input() phase?: 'start' | 'end';
  date?: Date;

  constructor() {
  }

  ngOnInit(): void {
    this.determineDate();
  }

  private determineDate() {
    if (!this.trades || this.trades.length === 0)
      return;

    if (this.phase === 'start')
      this.date = Util.getDate(this.trades[0].target.buyDate);
    if (this.phase === 'end')
      this.date = Util.getDate(this.trades[this.trades.length - 1].target.sellDate);
  }
}
