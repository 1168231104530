<ng-container *ngIf="exchange, else unknownExchange">
  <ng-container *ngIf="mode === 'codeOnly'">
    <span matTooltip="{{exchange.name}}">{{exchange.shortName}}</span>
  </ng-container>
  <ng-container *ngIf="mode === 'nameOnly'">
    <span matTooltip="{{exchange.shortName}}">{{exchange.name}}</span>
  </ng-container>
  <ng-container *ngIf="mode === 'nameAndCode'">
    <span i18n="exchange descriptor|exchange descriptor">{{exchange.name}} ({{exchange.shortName}})</span>
  </ng-container>
</ng-container>
<ng-template #unknownExchange>
  <span>{{exchangeShortName}}</span>
</ng-template>

