import {Symbol} from '../shared/models/symbol.interface';
import {Exchange} from '../shared/models/exchange.interface';
import {SymbolsSearchParams} from '../shared/models/symbolsSearchParams.interface';
import {SymbolsSearchResult} from '../shared/models/symbolsSearchResult.interface';
import {StatisticalReturnsSearchParams, StatisticalReturnsSortOrder} from '../shared/models/statisticalReturnsSearchParams.interface';
import {StatisticalReturnsSearchResult} from '../shared/models/statisticalReturnsSearchResult.interface';
import {CompanyProfile} from '../shared/models/companyProfile.interface';
import {StatisticalReturn} from '../shared/models/statisticalReturn.interface';
import {DevelopmentSinceBuyDay} from '../shared/models/developmentSinceBuyDay.interface';

export interface StockState {
  symbols: Symbol[];
  statisticalReturns: StatisticalReturn[];
  developmentSinceBuyDays: DevelopmentSinceBuyDay[];
  symbolPriceOnBuyDays: DevelopmentSinceBuyDay[];
  companyProfiles: CompanyProfile[];
  exchanges: Exchange[];
  symbolsSearch: SymbolsSearch;
  bestStatisticalReturnsSearch: BestStatisticalReturnsSearch;
  upcomingStatisticalReturnsSearch: UpcomingStatisticalReturnsSearch;
}

export interface SymbolsSearch {
  searchParams: SymbolsSearchParams;
  searching: boolean;
  searchResult: SymbolsSearchResult;
}

export interface BestStatisticalReturnsSearch {
  searchParams: StatisticalReturnsSearchParams;
  searching: boolean;
  searchResult: StatisticalReturnsSearchResult;
}

export interface UpcomingStatisticalReturnsSearch {
  searchParams: StatisticalReturnsSearchParams;
  searching: boolean;
  searchResult: StatisticalReturnsSearchResult;
}

export const initialState: StockState = {
  symbols: [],
  statisticalReturns: [],
  developmentSinceBuyDays: [],
  symbolPriceOnBuyDays: [],
  companyProfiles: [],
  exchanges: [],
  symbolsSearch: {
    searching: false,
    searchParams: {},
    searchResult: {
      symbols: [],
      thereIsMore: false,
    },
  },
  bestStatisticalReturnsSearch: {
    searching: false,
    searchParams: {
      sortOrder: StatisticalReturnsSortOrder.AverageRelativeProfitDesc,
    },
    searchResult: {
      statisticalReturns: [],
      thereIsMore: false,
      allFilteredOut: false,
    },
  },
  upcomingStatisticalReturnsSearch: {
    searching: false,
    searchParams: {
      sortOrder: StatisticalReturnsSortOrder.AverageRelativeProfitDesc,
    },
    searchResult: {
      statisticalReturns: [],
      thereIsMore: false,
      allFilteredOut: false,
    },
  },
};
