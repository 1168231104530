import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {UserService} from '../../shared/services/user.service';
import {AuthService} from '../../auth/auth.service';
import {TitleService} from '../../shared/services/title.service';
import {MetadataService} from '../../shared/services/metadata.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css'],
})
export class ImprintComponent extends BaseComponent implements OnInit {

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private metadataService: MetadataService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Imprint`);

    this.metadataService.updateTags($localize`Imprint`,
        $localize`Find out, who is responsible for the Stock Forecast platform.`,
        $localize`imprint contact`,
    );
  }

}
