import {Component, Input, OnInit} from '@angular/core';
import {PeriodWinRatio} from '../../../../shared/models/statisticalReturnsSearchParams.interface';

@Component({
  selector: 'app-period-win-ratio-view',
  templateUrl: './period-win-ratio-view.component.html',
  styleUrls: ['./period-win-ratio-view.component.scss']
})
export class PeriodWinRatioViewComponent implements OnInit {

  @Input() periodWinRatio?: PeriodWinRatio;

  constructor() {
  }

  ngOnInit(): void {
  }

}
