import {StatisticalSimulationSearchParams} from '../shared/models/statisticalSimulationSearchParams.interface';
import {StatisticalSimulationSearchResult} from '../shared/models/statisticalSimulationSearchResult.interface';
import {StatisticalSimulationResult} from '../shared/models/statisticalSimulationResult.interface';


export interface SimulationState {
  simulationResults: StatisticalSimulationResult[];
  simulationSearch: SimulationSearch;
}

export interface SimulationSearch {
  searchParams: StatisticalSimulationSearchParams;
  searching: boolean;
  searchResult: StatisticalSimulationSearchResult;
}

export const initialState: SimulationState = {
  simulationResults: [],
  simulationSearch: {
    searching: false,
    searchParams: {},
    searchResult: {
      simulationResults: [],
      thereIsMore: false,
    },
  },
};
