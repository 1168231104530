import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {UtilService} from '../../../shared/util.service';
import {TitleService} from '../../../shared/services/title.service';
import {BreadcrumbPage} from '../../../shared/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent extends BaseComponent implements OnInit {

  breadcrumbPages: BreadcrumbPage[] = [{routerLink: '..', title: $localize`Statistical trading planner`}];

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private utilService: UtilService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`My plans`);
  }

}
