import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {UtilService} from '../../../shared/util.service';
import {Notification} from '../../../shared/models/notification.interface';
import {AccountService} from '../../account.service';
import {TransactionState} from '../../../shared/enums/transactionState.enum';
import {UserPublic} from '../../../shared/models/userPublic.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends BaseComponent implements OnInit {

  @Input() notification?: Notification;

  borrower?: UserPublic;
  lender?: UserPublic;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private accountService: AccountService,
      public utilService: UtilService) {
    super(authService, userService, store);
  }

  public get transactionState(): typeof TransactionState {
    return TransactionState;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  onNotificationClicked() {
    if (this.notification)
      this.accountService.markNotificationAsRead(this.notification, () => {
      }, (error) => {
        console.log(`Error marking notification ${this.notification!.uid} as read: ${error}`);
      });
  }

  getBorrower(): UserPublic | undefined {
    return this.borrower;
  }

  getLender() {
    return this.lender;
  }
}
