import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-schedule-shown',
  templateUrl: './no-schedule-shown-component.html',
  styleUrls: ['./no-schedule-shown-component.scss'],
})
export class NoScheduleShownComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
