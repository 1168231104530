import {Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../../shared/util.service';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';

@Component({
  selector: 'app-trade-price-view',
  templateUrl: './trade-price-view.component.html',
  styleUrls: ['./trade-price-view.component.scss'],
})
export class TradePriceViewComponent implements OnInit {

  @Input() statRet: StatisticalReturn | undefined;
  @Input() year: number | undefined;

  constructor(
      public utilService: UtilService) {
  }

  ngOnInit(): void {
  }

}
