<mat-toolbar class="m-0 p-0" color="primary">

  <div class="d-block d-md-none">
    <button [matMenuTriggerFor]="menu" aria-label="Menu" class="mx-2" i18n-aria-label="Menu" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <app-stock-search-panel class="mx-2" layout="toolbar" [navigateToSearch]="true"></app-stock-search-panel>

  <a class="d-none d-md-block" routerLink="/statistical-trading/overview">
    <button class="mx-0 px-2" color="accent" i18n="About button|About button" mat-button routerLinkActive="toolbar-active">About</button>
  </a>
  <a class="d-none d-md-block" routerLink="/statistical-trading/stock-search">
    <button class="mx-0 p-2" color="accent" i18n="stock search button|stock search button" mat-button routerLinkActive="toolbar-active">Stock search</button>
  </a>
  <a class="d-none d-xs-block" routerLink="/statistical-trading/best-trades">
    <button class="mx-0 px-2" color="accent" i18n="Best trades button|Best trades button" mat-button routerLinkActive="toolbar-active">Best trades</button>
  </a>
  <a class="d-none d-sm-block" routerLink="/statistical-trading/upcoming-trades">
    <button class="mx-0 px-2" color="accent" i18n="Upcoming trades button|Upcoming trades button" mat-button routerLinkActive="toolbar-active">Upcoming trades</button>
  </a>
  <a class="d-none d-md-block" routerLink="/statistical-trading/symbols">
    <button class="mx-0 px-2" color="accent" i18n="Symbols button|Symbols button" mat-button routerLinkActive="toolbar-active">Symbols</button>
  </a>
  <a class="d-none d-md-block" routerLink="/statistical-trading/simulations">
    <button class="mx-0 px-2" color="accent" i18n="Simulations button|Simulations button" mat-button routerLinkActive="toolbar-active">Simulations</button>
  </a>
  <a class="d-none d-md-block" routerLink="/statistical-trading/planner">
    <button class="mx-0 px-2" color="accent" i18n="Planner button|Planner button" mat-button routerLinkActive="toolbar-active">Planner</button>
  </a>

  <mat-menu #menu="matMenu">
    <a class="btn btn-menu d-block d-md-none text-left" i18n="stock search button|stock search button"
       mat-menu-item routerLink="/statistical-trading/stock-search" routerLinkActive="menu-active">Stock search</a>
    <a class="btn btn-menu d-block d-xs-none text-left" i18n="Best trades button|Best trades button"
       mat-menu-item routerLink="/statistical-trading/best-trades" routerLinkActive="menu-active">Best trades</a>
    <a class="btn btn-menu d-block d-sm-none text-left" i18n="Upcoming trades button|Upcoming trades button"
       mat-menu-item routerLink="/statistical-trading/upcoming-trades" routerLinkActive="menu-active">Upcoming trades</a>
    <a class="btn btn-menu d-block d-md-none text-left" i18n="About button|About button"
       mat-menu-item routerLink="/statistical-trading/overview" routerLinkActive="menu-active">About</a>
    <a class="btn btn-menu d-block d-md-none text-left" i18n="Symbols button|Symbols button"
       mat-menu-item routerLink="/statistical-trading/symbols" routerLinkActive="menu-active">Symbols</a>
    <a class="btn btn-menu d-block d-md-none text-left" i18n="Simulations button|Simulations button"
       mat-menu-item routerLink="/statistical-trading/simulations" routerLinkActive="menu-active">Simulations</a>
    <a class="btn btn-menu d-block d-md-none text-left" i18n="Planner button|Planner button"
       mat-menu-item routerLink="/statistical-trading/planner" routerLinkActive="menu-active">Planner</a>
  </mat-menu>

</mat-toolbar>
