<div *ngIf="alerts && alerts.length>0" [ngClass]="{'float': float}" id="alerts-container">
  <div *ngFor="let alert of alerts"
       [class]="alert.type === alertType.Error ? 'alert-danger' : (alert.type === alertType.Warning ? 'alert-warning' : (alert.type === alertType.Info ? 'alert-info' : (alert.type === alertType.Success ? 'alert-success': '')))"
       class="alert flex-left"><span [innerHTML]="sanitizeMessage(alert.message)"></span>

    <div class="alert-box-actions">
      <button (click)="onClose(alert)" aria-label="Close alert" i18n-aria-label="close alert" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
