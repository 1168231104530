import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatisticalTradingComponent} from './statistical-trading.component';
import {StatisticalTradingMenuComponent} from './statistical-trading-header/statistical-trading-menu/statistical-trading-menu.component';
import {StatisticalTradingOverviewComponent} from './statistical-trading-overview/statistical-trading-overview.component';
import {SymbolViewComponent} from './symbols/symbol-view/symbol-view.component';
import {NoSymbolSelectedComponent} from './symbols/no-symbol-selected/no-symbol-selected.component';
import {SymbolsComponent} from './symbols/symbols.component';
import {StatisticalReturnsTableComponent} from './statistical-returns-table/statistical-returns-table.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {StatisticalTradingRoutingModule} from './statistical-trading-routing.module';
import {TradePriceModalComponent} from './trade-price-modal/trade-price-modal.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {StatisticalTradingHeaderComponent} from './statistical-trading-header/statistical-trading-header.component';
import {BestTradesComponent} from './best-trades/best-trades.component';
import {UpcomingTradesComponent} from './upcoming-trades/upcoming-trades.component';
import {StockSearchComponent} from './stock-search/stock-search.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {StockSearchPanelComponent} from '../shared/components/stock-search-panel/stock-search-panel.component';
import {InfoTextComponent} from './info-text/info-text.component';
import {AdminOptionsComponent} from './symbols/symbol-view/admin-options/admin-options.component';
import {SymbolExchangeColumnsComponent} from './statistical-returns-table/symbol-exchange-columns/symbol-exchange-columns.component';
import {StatisticalReturnViewComponent} from './symbols/statistical-return-view/statistical-return-view.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {TradePriceViewComponent} from './trade-price-view/trade-price-view.component';
import {PeriodViewComponent} from './symbols/statistical-return-view/period-view/period-view.component';
import {StatisticalReturnsTableLoaderComponent} from './symbols/statistical-returns-table-loader/statistical-returns-table-loader.component';
import {SimulationsComponent} from './simulations/simulations.component';
import {SimulationSearchComponent} from './simulations/simulation-search/simulation-search.component';
import {SimulationViewComponent} from './simulations/simulation-view/simulation-view.component';
import {SimulationResultsTableComponent} from './simulations/simulation-results-table/simulation-results-table.component';
import {SimulationSearchPanelComponent} from './simulations/simulation-search/simulation-search-panel/simulation-search-panel.component';
import {ExchangesViewComponent} from './simulations/simulation-results-table/exchanges-view/exchanges-view.component';
import {MatSliderModule} from '@angular/material/slider';
import {StatisticalReturnDetailTableComponent} from './statistical-return-detail-table/statistical-return-detail-table.component';
import {StatisticalReturnPeriodsTableComponent} from './statistical-return-periods-table/statistical-return-periods-table.component';
import {CollectiveTableHeaderComponent} from './statistical-returns-table/collective-table-header/collective-table-header.component';
import {YearHeaderComponent} from './statistical-returns-table/collective-table-header/year-header/year-header.component';
import {PeriodHeaderComponent} from './statistical-returns-table/collective-table-header/period-header/period-header.component';
import {StatisticalReturnDataViewComponent} from './statistical-returns-table/statistical-return-data-view/statistical-return-data-view.component';
import {
  StatisticalReturnCollectiveDataViewComponent,
} from './statistical-returns-table/statistical-return-collective-data-view/statistical-return-collective-data-view.component';
import {YearDataComponent} from './statistical-returns-table/statistical-return-collective-data-view/year-data/year-data.component';
import {PeriodDataComponent} from './statistical-returns-table/statistical-return-collective-data-view/period-data/period-data.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {StatisticalReturnSearchPanelComponent} from './statistical-return-search-panel/statistical-return-search-panel.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PlannerComponent} from './planner/planner.component';
import {PlannerOverviewComponent} from './planner/planner-overview/planner-overview.component';
import {PlansComponent} from './planner/plans/plans.component';
import {StatisticalTradingPlansListComponent} from './planner/plans/statistical-trading-plans-list/statistical-trading-plans-list.component';
import {StatisticalTradingPlanEditComponent} from './planner/plans/statistical-trading-plan-edit/statistical-trading-plan-edit.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {
  StatisticalTradingPlanSettingsFormComponent,
} from './planner/plans/statistical-trading-plan-settings-form/statistical-trading-plan-settings-form.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PlanTypeViewComponent} from './planner/helper-components/plan-type-view/plan-type-view.component';
import {RealizedTradesCountComponent} from './planner/helper-components/realized-trades-count/realized-trades-count.component';
import {FutureTradesCountComponent} from './planner/helper-components/future-trades-count/future-trades-count.component';
import {PlanStartOrEndDateComponent} from './planner/helper-components/plan-start-or-end-date/plan-start-or-end-date.component';
import {PlanValueComponent} from './planner/helper-components/plan-value/plan-value.component';
import {StatisticalTradingPlanViewComponent} from './planner/statistical-trading-plan-view/statistical-trading-plan-view.component';
import {IncludedExcludedModalComponent} from './planner/helper-components/included-excluded-modal/included-excluded-modal.component';
import {
  StatisticalTradingPlanActionsMenuComponent,
} from './planner/helper-components/statistical-trading-plan-actions-menu/statistical-trading-plan-actions-menu.component';
import {
  StatisticalTradingPlanTradesViewComponent,
} from './planner/helper-components/statistical-trading-plan-trades-view/statistical-trading-plan-trades-view.component';
import {TradeActionViewComponent} from './planner/helper-components/trade-action-view/trade-action-view.component';
import {DepotContentsModalComponent} from './planner/helper-components/depot-contents-modal/depot-contents-modal.component';
import {MatDividerModule} from '@angular/material/divider';
import {
  StatisticalTradingPlanTradeActionMenuComponent,
} from './planner/helper-components/statistical-trading-plan-trade-action-menu/statistical-trading-plan-trade-action-menu.component';
import {
  StatisticalTradingPlanTradeEditModalComponent,
} from './planner/helper-components/statistical-trading-plan-trade-edit-modal/statistical-trading-plan-trade-edit-modal.component';
import {
  StatisticalTradingPlanTradeEditComponent,
} from './planner/helper-components/statistical-trading-plan-trade-edit/statistical-trading-plan-trade-edit.component';
import {
  StatisticalTradePropertiesFormComponent,
} from './planner/helper-components/statistical-trade-properties-form/statistical-trade-properties-form.component';
import {TradeRealizedViewComponent} from './planner/helper-components/trade-realized-view/trade-realized-view.component';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {PlanProfitComponent} from './planner/helper-components/plan-profit/plan-profit.component';
import {MatTabsModule} from '@angular/material/tabs';
import {
  PeriodWinRatioDataComponent,
} from './statistical-returns-table/statistical-return-collective-data-view/period-win-ratio-data/period-win-ratio-data.component';
import {PeriodWinRatioHeaderComponent} from './statistical-returns-table/collective-table-header/period-win-ratio-header/period-win-ratio-header.component';
import {MinWinRatiosFilterComponent} from './statistical-return-search-panel/min-win-ratios-filter/min-win-ratios-filter.component';
import {
  MinWinRatiosEditDialogComponent,
} from './statistical-return-search-panel/min-win-ratios-filter/min-win-ratios-edit-dialog/min-win-ratios-edit-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MinAverageRelativeProfitFilterComponent } from './statistical-return-search-panel/min-average-relative-profit-filter/min-average-relative-profit-filter.component';
import { MinAverageRelativeProfitEditDialogComponent } from './statistical-return-search-panel/min-average-relative-profit-filter/min-average-relative-profit-edit-dialog/min-average-relative-profit-edit-dialog.component';
import { MinLowestYearlyProfitFilterComponent } from './statistical-return-search-panel/min-lowest-yearly-profit-filter/min-lowest-yearly-profit-filter.component';
import { MinLowestYearlyProfitEditDialogComponent } from './statistical-return-search-panel/min-lowest-yearly-profit-filter/min-lowest-yearly-profit-edit-dialog/min-lowest-yearly-profit-edit-dialog.component';
import { MinAverageVolumeFilterComponent } from './statistical-return-search-panel/min-average-volume-filter/min-average-volume-filter.component';
import { MinAverageVolumeEditDialogComponent } from './statistical-return-search-panel/min-average-volume-filter/min-average-volume-edit-dialog/min-average-volume-edit-dialog.component';
import { MinAverageVolumeBaseCurrencyFilterComponent } from './statistical-return-search-panel/min-average-volume-base-currency-filter/min-average-volume-base-currency-filter.component';
import { MinAverageVolumeBaseCurrencyEditDialogComponent } from './statistical-return-search-panel/min-average-volume-base-currency-filter/min-average-volume-base-currency-edit-dialog/min-average-volume-base-currency-edit-dialog.component';
import { MinDataYearsFilterComponent } from './statistical-return-search-panel/min-data-years-filter/min-data-years-filter.component';
import { MinDataYearsEditDialogComponent } from './statistical-return-search-panel/min-data-years-filter/min-data-years-edit-dialog/min-data-years-edit-dialog.component';
import { MaxDurationFilterComponent } from './statistical-return-search-panel/max-duration-filter/max-duration-filter.component';
import { MaxDurationEditDialogComponent } from './statistical-return-search-panel/max-duration-filter/max-duration-edit-dialog/max-duration-edit-dialog.component';
import { MinAverageMaxRiseFilterComponent } from './statistical-return-search-panel/min-average-max-rise-filter/min-average-max-rise-filter.component';
import { MinAverageMaxRiseEditDialogComponent } from './statistical-return-search-panel/min-average-max-rise-filter/min-average-max-rise-edit-dialog/min-average-max-rise-edit-dialog.component';
import { MaxAverageMaxDropFilterComponent } from './statistical-return-search-panel/max-average-max-drop-filter/max-average-max-drop-filter.component';
import { MaxAverageMaxDropEditDialogComponent } from './statistical-return-search-panel/max-average-max-drop-filter/max-average-max-drop-edit-dialog/max-average-max-drop-edit-dialog.component';
import { MinHighestRiseFilterComponent } from './statistical-return-search-panel/min-highest-rise-filter/min-highest-rise-filter.component';
import { MinHighestRiseEditDialogComponent } from './statistical-return-search-panel/min-highest-rise-filter/min-highest-rise-edit-dialog/min-highest-rise-edit-dialog.component';
import { MaxHighestDropFilterComponent } from './statistical-return-search-panel/max-highest-drop-filter/max-highest-drop-filter.component';
import { MaxHighestDropEditDialogComponent } from './statistical-return-search-panel/max-highest-drop-filter/max-highest-drop-edit-dialog/max-highest-drop-edit-dialog.component';
import { MinMarketCapFilterComponent } from './statistical-return-search-panel/min-market-cap-filter/min-market-cap-filter.component';
import { MinMarketCapEditDialogComponent } from './statistical-return-search-panel/min-market-cap-filter/min-market-cap-edit-dialog/min-market-cap-edit-dialog.component';
import { FilterSpacerComponent } from './statistical-return-search-panel/filter-spacer/filter-spacer.component';
import { ExchangesFilterComponent } from './statistical-return-search-panel/exchanges-filter/exchanges-filter.component';
import { ExchangesEditDialogComponent } from './statistical-return-search-panel/exchanges-filter/exchanges-edit-dialog/exchanges-edit-dialog.component';
import { PeriodWinRatioViewComponent } from './statistical-return-search-panel/min-win-ratios-filter/period-win-ratio-view/period-win-ratio-view.component';

@NgModule({
  declarations: [
    StatisticalTradingComponent,
    StatisticalTradingMenuComponent,
    StatisticalTradingOverviewComponent,
    SymbolViewComponent,
    NoSymbolSelectedComponent,
    SymbolsComponent,
    StatisticalReturnsTableComponent,
    TradePriceModalComponent,
    StatisticalTradingMenuComponent,
    StatisticalTradingHeaderComponent,
    BestTradesComponent,
    UpcomingTradesComponent,
    StockSearchComponent,
    InfoTextComponent,
    AdminOptionsComponent,
    SymbolExchangeColumnsComponent,
    StatisticalReturnViewComponent,
    TradePriceViewComponent,
    PeriodViewComponent,
    StatisticalReturnsTableLoaderComponent,
    SimulationsComponent,
    SimulationSearchComponent,
    SimulationViewComponent,
    SimulationResultsTableComponent,
    SimulationSearchPanelComponent,
    ExchangesViewComponent,
    StatisticalReturnDetailTableComponent,
    StatisticalReturnPeriodsTableComponent,
    CollectiveTableHeaderComponent,
    YearHeaderComponent,
    PeriodHeaderComponent,
    StatisticalReturnDataViewComponent,
    StatisticalReturnCollectiveDataViewComponent,
    YearDataComponent,
    PeriodDataComponent,
    StatisticalReturnSearchPanelComponent,
    PlannerComponent,
    PlannerOverviewComponent,
    PlansComponent,
    StatisticalTradingPlansListComponent,
    StatisticalTradingPlanEditComponent,
    StatisticalTradingPlanSettingsFormComponent,
    PlanTypeViewComponent,
    RealizedTradesCountComponent,
    FutureTradesCountComponent,
    PlanStartOrEndDateComponent,
    PlanValueComponent,
    StatisticalTradingPlanViewComponent,
    IncludedExcludedModalComponent,
    StatisticalTradingPlanActionsMenuComponent,
    StatisticalTradingPlanTradesViewComponent,
    TradeActionViewComponent,
    DepotContentsModalComponent,
    StatisticalTradingPlanTradeActionMenuComponent,
    StatisticalTradingPlanTradeEditModalComponent,
    StatisticalTradingPlanTradeEditComponent,
    StatisticalTradePropertiesFormComponent,
    TradeRealizedViewComponent,
    PlanProfitComponent,
    PeriodWinRatioDataComponent,
    PeriodWinRatioHeaderComponent,
    MinWinRatiosFilterComponent,
    MinWinRatiosEditDialogComponent,
    MinAverageRelativeProfitFilterComponent,
    MinAverageRelativeProfitEditDialogComponent,
    MinLowestYearlyProfitFilterComponent,
    MinLowestYearlyProfitEditDialogComponent,
    MinAverageVolumeFilterComponent,
    MinAverageVolumeEditDialogComponent,
    MinAverageVolumeBaseCurrencyFilterComponent,
    MinAverageVolumeBaseCurrencyEditDialogComponent,
    MinDataYearsFilterComponent,
    MinDataYearsEditDialogComponent,
    MaxDurationFilterComponent,
    MaxDurationEditDialogComponent,
    MinAverageMaxRiseFilterComponent,
    MinAverageMaxRiseEditDialogComponent,
    MaxAverageMaxDropFilterComponent,
    MaxAverageMaxDropEditDialogComponent,
    MinHighestRiseFilterComponent,
    MinHighestRiseEditDialogComponent,
    MaxHighestDropFilterComponent,
    MaxHighestDropEditDialogComponent,
    MinMarketCapFilterComponent,
    MinMarketCapEditDialogComponent,
    FilterSpacerComponent,
    ExchangesFilterComponent,
    ExchangesEditDialogComponent,
    PeriodWinRatioViewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    StatisticalTradingRoutingModule,
    MatTooltipModule,
    MatButtonModule,
    NgbTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatExpansionModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatDialogModule,
  ],
  exports: [
    StatisticalTradingComponent,
    StatisticalReturnsTableComponent,
    SymbolViewComponent,
    StockSearchPanelComponent,
    SimulationResultsTableComponent,
    InfoTextComponent,
  ],
})
export class StatisticalTradingModule {
}
