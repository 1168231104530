import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Wrapper} from '../shared/models/wrapper.model';
import firebase from 'firebase/app';

import {Subject} from 'rxjs';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {convertToFaq} from '../shared/converters/modelConverters';
import {addFaqsToCache, addFaqToCache, clearFaqsCache, setFaqsLastFetch} from './store/layout.actions';
import {Faq, FaqLangStrings} from '../shared/models/faq.interface';

import {Mutex} from 'async-mutex';
import {firestore} from '../app.module';
import Locale from '../shared/services/locale';
import {selectFaqs, selectLayout} from './store/layout.selectors';
import {AppState} from '../store/app.state';
import Timestamp = firebase.firestore.Timestamp;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  announcementMutex = new Mutex();
  faqMutex = new Mutex();

  destroy$: Subject<null> = new Subject();
  faqsById: Map<string, Faq> = new Map<string, Faq>();
  onFaqSaved$ = new Subject<Faq>();
  onFaqDeleted$ = new Subject<string>();
  onFaqSelected$ = new Subject<string | undefined>();
  faqs$ = this.store.select(selectFaqs).pipe(takeUntil(this.destroy$));
  layout$ = this.store.select(selectLayout).pipe(takeUntil(this.destroy$));
  private announcementsLastFetch?: Date;
  private faqsLastFetch?: Date;

  constructor(private store: Store<AppState>) {
  }


  fetchCachedFaqs(onSuccessCallback: (faqs: Faq[]) => void, onErrorCallback: (error: string) => void): void {

    this.layout$.subscribe(async state => {
      if (state.faqs) {
        this.faqsById = new Map<string, Faq>();
        state.faqs.forEach(faq => {
          if (faq.uid)
            this.faqsById.set(faq.uid, faq);
        });
      }
      if (state.faqsLastFetch)
        this.faqsLastFetch = state.faqsLastFetch;

      if (!this.faqsLastFetch || (new Date().getTime() - this.faqsLastFetch.getTime() > environment.defaultFaqCacheAgeInSec * 1000)) {
        // Fetch live faqs
        const now = Timestamp.now();
        const wrapper = await this.fetchFaqs(100);
        if (wrapper.errorMessage)
          onErrorCallback(wrapper.errorMessage);
        if (wrapper.data)
          onSuccessCallback(wrapper.data);
        return;
      }
      // Return faqs from cache
      const faqs: Faq[] = [...this.faqsById.values()];
      onSuccessCallback(faqs);
    });


  }

  async fetchFaqs(limit: number = environment.defaultLoadFaqsCount, startAfter?: DocumentSnapshot): Promise<Wrapper<Faq[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionFaqs).orderBy('priority', 'desc');
      const faqQuerySnapshot = await query.get();
      const faqs: Faq[] = [];
      faqQuerySnapshot.forEach(faqDocSnapshot => {
        const faq: Faq = convertToFaq(faqDocSnapshot.data());
        if (faq) {
          const faqWithId = {...faq, uid: faqDocSnapshot.id};
          faqs.push(faqWithId);
        }
      });

      this.addFaqsToCache(faqs);
      const lastVisible = faqQuerySnapshot.docs[faqQuerySnapshot.docs.length - 1];

      return new Wrapper<Faq[]>(faqs, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Faq[]>(undefined, $localize`You are not allowed to view faqs.`);
      return new Wrapper<Faq[]>(undefined, e.message);
    }
  }

  deleteFaq(faqUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionFaqs).doc(faqUid).delete();
  }

  deleteAllFaqs(): Promise<void> {
    return this.fetchFaqs(1000).then(wrapper => {
      return new Promise<void>((resolve, reject) => {
        if (wrapper.errorMessage) {
          reject(wrapper.errorMessage);
        }
        const faqs = wrapper.data;
        if (faqs) {
          if (faqs.length === 0)
            resolve();

          resolve(this.deleteAllFaqsRecursively(faqs, 0));

        }


      });


    });
  }

  /**
   * Sends the given faq to the backend server. If it has an ID, an existing faq is updated, otherwise a new one is added.
   * @param faqId ID of the faq to be sent
   * @param faq faq to be sent. Need to be provided, even if a fullFaq is given
   * @param fullFaq faq to be written to the cache. This faq is not sent to the firestore
   * @param merge if true, only the fields given in the faq object will be updated. Otherwise, the whole faq object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateFaq(faqId: string, faq: Faq, fullFaq: Faq | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionFaqs).doc(faqId).set(faq, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The faq could not be updated\: ${error}`),
    );
    if (merge && !fullFaq) {
      console.error('updateFaq called with a merge job without providing a fullFaq.');
      return;
    }
    this.addFaqToCache(merge && fullFaq ? fullFaq : faq);
  }

  /**
   * Creates a new faq in the backend database.
   */
  insertFaq(faq: Faq, onSuccessCallback: (faq: Faq) => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionFaqs).add(faq).then(docRef => {
          faq.uid = docRef.id;
          onSuccessCallback(faq);
          this.addFaqToCache(faq);
        },
        (error) => onErrorCallback($localize`The faq could not be created\: ${error}`),
    );
  }

  /**
   * Fetches the faq with the given faqId from the firestore database.
   */
  async fetchFaq(uid: string, maxAgeInSec: number = environment.defaultFaqCacheAgeInSec): Promise<Wrapper<Faq>> {

    return this.faqMutex.runExclusive(async () => {
      const faqFromCache = this.faqsById.get(uid);
      if (faqFromCache !== undefined && this.isFaqUpToDate(faqFromCache, maxAgeInSec))
        return new Wrapper<Faq>(faqFromCache);

      try {
        const faqDocSnapshot = await firestore.collection(environment.firestoreCollectionFaqs).doc(uid).withConverter(faqConverter).get();
        const faq = faqDocSnapshot.data();
        if (faq) {
          const faqWithIdAndCacheDate: Faq = {...faq, uid: faqDocSnapshot.id, cacheDate: new Date()};
          this.addFaqToCache(faqWithIdAndCacheDate);
          return new Wrapper<Faq>(faqWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Faq>(undefined, e.message);
      }

// If no faq was found
      return new Wrapper<Faq>(undefined);
    });
  }

  resetFaqCache() {
    this.faqsById.clear();
    this.store.dispatch(clearFaqsCache());
  }

  getFaqQuestion(faq: Faq) {
    return this.getFaqLangStrings(faq).question;
  }

  getFaqAnswer(faq: Faq) {
    return this.getFaqLangStrings(faq).answer;
  }

  getFaqLangStrings(faq: Faq): FaqLangStrings {
    const strings = faq.strings[Locale.firestoreLocale()];
    return strings ? strings : faq.strings[environment.defaultFirestoreLocale];
  }


  /**
   * Checks, if the given faq is newer then the given max age.
   * @param faq faq  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isFaqUpToDate(faq: Faq, maxAgeInSec: any): boolean {
    if (!faq.cacheDate)
      return false;
    const cacheTime = faq.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addFaqToCache(faq: Faq) {
    if (faq.uid)
      this.faqsById.set(faq.uid, faq);
    this.store.dispatch(addFaqToCache({faq}));
  }

  private addFaqsToCache(faqs: Faq[]) {
    this.faqsById.clear();
    faqs.forEach(faq => {
      if (faq?.uid)
        this.faqsById.set(faq.uid, faq);
    });
    this.store.dispatch(addFaqsToCache({faqs}));
  }

  private setFaqsLastFetchDate(faqsLastFetch: Date) {
    this.store.dispatch(setFaqsLastFetch({faqsLastFetch: faqsLastFetch}));
  }

  /**
   * Deletes all the given FAQs starting with the given index. Does it by calling the function over and over again until they're all gone.
   * @param faqs list of FAQs
   * @param index starting index for the faqs array
   */
  private deleteAllFaqsRecursively(faqs: Faq[], index: number): Promise<void> {
    const faqUid = faqs[index].uid;
    if (!faqUid)
      throw new Error(`faq with index ${index} doesn't have a uid.`);
    return this.deleteFaq(faqUid).then(() => {
          console.log(`Successfully deleted FAQ with UID ${faqUid}.`);
          if (index < faqs.length - 1)
            return this.deleteAllFaqsRecursively(faqs, ++index);
          // We're done
          return new Promise<void>(resolve => resolve());
        },
        reason => {
          return new Promise<void>((resolve, reject) => reject(reason));
        });
  }
}


// Firestore data converter
export const faqConverter = {
  toFirestore(faq: Faq): Faq {
    return faq;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Faq {
    return convertToFaq(snapshot.data(options));
  },
};

