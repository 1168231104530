import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-simulations',
  templateUrl: './simulations.component.html',
  styleUrls: ['./simulations.component.scss'],
})
export class SimulationsComponent implements OnInit {

  constructor(
      private titleService: TitleService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Simulations`);
  }

}
