<app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<div *ngIf="simulationResults && simulationResults.length > 0">
  <table class="table table-striped table-sm table-responsive-lg table-sticky-header">
    <thead>
    <tr>
      <th>&nbsp;</th>
      <th *ngIf="user">&nbsp;</th>

      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.YearAsc" [orderDesc]="sortOrder.YearDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="yearHeader"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.SeedCapitalAsc" [orderDesc]="sortOrder.SeedCapitalDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="seedCapital"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.FinalCapitalAsc" [orderDesc]="sortOrder.FinalCapitalDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="finalCapital"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.RoiAsc" [orderDesc]="sortOrder.RoiDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="roi"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.MinAverageRelativeProfitAsc" [orderDesc]="sortOrder.MinAverageRelativeProfitDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="minAvgProfit"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.MaxHoldingDurationAsc" [orderDesc]="sortOrder.MaxHoldingDurationDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="maxDuration"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.MinPricePerStockAsc" [orderDesc]="sortOrder.MinPricePerStockDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="minPricePerStock"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.MaxCapitalPerTradeAsc" [orderDesc]="sortOrder.MaxCapitalPerTradeDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="maxCapitalPerTrade"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.NumberOfTradesAsc" [orderDesc]="sortOrder.NumberOfTradesDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="numberOfTrades"></ng-container>
        </app-sort-header>
      </th>
      <th>
        <app-sort-header [sortable]="sortable" [currentSortOrder]="currentSortOrder$ | async" [orderAsc]="sortOrder.ExchangesAsc" [orderDesc]="sortOrder.ExchangesDesc" (onSortClicked)="sort($event)">
          <ng-container *ngTemplateOutlet="exchanges"></ng-container>
        </app-sort-header>
      </th>

      <ng-template #yearHeader><span i18n-matTooltip matTooltip="Calendar year of the simulation" i18n>Year</span></ng-template>
      <ng-template #seedCapital><span i18n-matTooltip matTooltip="The capital before the start of the simulation" i18n>Seed capital</span></ng-template>
      <ng-template #finalCapital><span i18n-matTooltip matTooltip="The capital at the end of the simulation" i18n>Final capital</span></ng-template>
      <ng-template #roi><span i18n-matTooltip matTooltip="Return on investment" i18n>ROI</span></ng-template>
      <ng-template #minAvgProfit><span i18n-matTooltip matTooltip="The minimum average relative annual profit per trade necessary to buy a stock in the simulation." i18n>Min. avg. profit</span></ng-template>
      <ng-template #maxDuration><span i18n-matTooltip matTooltip="The maximum duration in days between buy and sell date." i18n>Max. duration</span></ng-template>
      <ng-template #minPricePerStock><span i18n-matTooltip matTooltip="The minimum buying price per stock necessary to buy the stock symbol in the simulation" i18n>Min. stock price</span></ng-template>
      <ng-template #maxCapitalPerTrade><span i18n-matTooltip matTooltip="The maximum capital used for a single trade relative to the depot value" i18n>Max. capital per trade</span></ng-template>
      <ng-template #numberOfTrades><span i18n-matTooltip matTooltip="Number of trades (each one represents buying and selling the stock)" i18n>Trades</span></ng-template>
      <ng-template #exchanges><span i18n-matTooltip matTooltip="Exchanges used for the simulation" i18n>Exchanges</span></ng-template>

    </thead>
    <tbody>
    <tr *ngFor="let simRes of simulationResults" class="no-row-break">
      <td>
        <a [routerLink]="['/statistical-trading/simulations', simRes.uid]">
          <mat-icon>double_arrow</mat-icon>
        </a>
      <td *ngIf="user">
        <app-watch-list-star [statisticalSimulationUid]="simRes.uid" [floatTopRight]="false"></app-watch-list-star>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.year !== undefined; else none">
          {{simRes.year}}
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.seedCapital !== undefined; else none">
          <app-money [value]="simRes.seedCapital"></app-money>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.finalCapital !== undefined; else none">
          <app-money [value]="simRes.finalCapital"></app-money>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.roi !== undefined; else none">
          <app-percentage-view [value]="simRes.roi"></app-percentage-view>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.minAverageRelativeProfit !== undefined; else none">
          <app-percentage-view [value]="simRes.minAverageRelativeProfit"></app-percentage-view>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.maxHoldingDuration !== undefined; else none">
          {{simRes.maxHoldingDuration}}
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.minPricePerStock !== undefined; else none">
          <app-money [value]="simRes.minPricePerStock"></app-money>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.maxCapitalPerTrade !== undefined; else none">
          <app-percentage-view [value]="simRes.maxCapitalPerTrade"></app-percentage-view>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.transactionList !== undefined; else none">
          {{simRes.transactionList.length}}
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="simRes !== undefined && simRes.exchangeShortNames !== undefined; else none">
          <app-exchanges-view [exchangeShortNames]="simRes.exchangeShortNames" (click)="onExchangesClicked(simRes.exchangeShortNames)"></app-exchanges-view>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #none>
  -
</ng-template>
