<form (ngSubmit)="search()" [formGroup]="form">

  <div class="row mx-0 mb-0">
    <!-- Buy day number -->
    <ng-container *ngIf="viewType==='upcoming'">
      <div class="col-auto px-1">
        <app-mat-icon-button icon="today" matTooltip="Today" i18n-matTooltip [callback]="today.bind(this)" type="button" cssClass="big-button" color="white"></app-mat-icon-button>
      </div>
      <div class="col-auto px-1">
        <app-mat-icon-button icon="navigate_before" [callback]="previousDay.bind(this)" [params]="[buyDayNumber$|async]" type="button" cssClass="big-button" color="white"></app-mat-icon-button>
      </div>
      <div class="col-auto px-1">
        <mat-form-field class="w-150px no-padding-bottom">
          <mat-label i18n="Buy date label">Buy date</mat-label>
          <input [matDatepicker]="picker" [max]="maxBuyDate" [min]="minBuyDate" formControlName="buyDate" matInput (dateChange)="saveBuyDate()">
          <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="form?.get('buyDate')?.hasError('matDatepickerMin')" i18n>The buy date lies too long in the past.</mat-error>
          <mat-error *ngIf="form?.get('buyDate')?.hasError('matDatepickerMax')" i18n>The buy date lies too long in the future.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-auto px-1">
        <app-mat-icon-button icon="navigate_next" [callback]="nextDay.bind(this)" [params]="[buyDayNumber$|async]" type="button" cssClass="big-button" color="white"></app-mat-icon-button>
      </div>
    </ng-container>

    <!-- Sort order -->
    <div class="col-auto px-1">
      <mat-form-field class="mat-form-input no-padding-bottom no-margin-outline">
        <mat-label i18n="sort by">Sort by</mat-label>
        <mat-select #sortOrderSelect id="sortOrderSelect" panelClass="mat-select-panel-500px"
                    [value]="sortOrder$ | async" (selectionChange)="onSortOrderChange(sortOrderSelect.value)">
          <mat-option *ngIf="viewType !== 'upcoming'" [value]="statisticalReturnsSortOrder.BuyDateAsc" i18n>Buy date ascending</mat-option>
          <mat-option *ngIf="viewType !== 'upcoming'" [value]="statisticalReturnsSortOrder.BuyDateDesc" i18n>Buy date descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.AverageRelativeProfitAsc" i18n>Avg. profit ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.AverageRelativeProfitDesc" i18n>Avg. profit descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.DurationAsc" i18n>Duration ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.DurationDesc" i18n>Duration descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.ExchangeShortNameAsc" i18n>Exchange ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.ExchangeShortNameDesc" i18n>Exchange descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.WorstYearAsc" i18n>Worst year ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.WorstYearDesc" i18n>Worst year descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.AvgMaxRiseAsc" i18n>Avg. max. rise ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.AvgMaxRiseDesc" i18n>Avg. max. rise descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.HighestMaxRiseAsc" i18n>Highest rise ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.HighestMaxRiseDesc" i18n>Highest rise descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.AvgMaxDropAsc" i18n>Avg. max. drop ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.AvgMaxDropDesc" i18n>Avg. max. drop descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.HighestMaxDropAsc" i18n>Highest drop ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.HighestMaxDropDesc" i18n>Highest drop descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.SymbolCodeAsc" i18n>Symbol code ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.SymbolCodeDesc" i18n>Symbol code descending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.WinRatioAsc" i18n>Win ratio ascending</mat-option>
          <mat-option [value]="statisticalReturnsSortOrder.WinRatioDesc" i18n>Win ratio descending</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Search button -->
    <div class="col-auto pl-1">
      <app-mat-fab-search-button type="submit"></app-mat-fab-search-button>
    </div>

  </div>

  <div class="row mx-0 mb-1">

    <!-- Min. average relative profit -->
    <div class="col-auto px-1">

      <!-- Exchanges -->
      <app-exchanges-filter [viewType]="viewType"></app-exchanges-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. average relative profit -->
    <div class="col-auto px-1">
      <app-min-average-relative-profit-filter [viewType]="viewType"></app-min-average-relative-profit-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. lowest yearly profit -->
    <div class="col-auto px-1">
      <app-min-lowest-yearly-profit-filter [viewType]="viewType"></app-min-lowest-yearly-profit-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. average volume -->
    <div class="col-auto px-1">
      <app-min-average-volume-filter [viewType]="viewType"></app-min-average-volume-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. average volume base currency -->
    <div class="col-auto px-1">
      <app-min-average-volume-base-currency-filter [viewType]="viewType"></app-min-average-volume-base-currency-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. data years -->
    <div class="col-auto px-1">
      <app-min-data-years-filter [viewType]="viewType"></app-min-data-years-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Max. duration -->
    <div class="col-auto px-1">
      <app-max-duration-filter [viewType]="viewType"></app-max-duration-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. avg. max. rise -->
    <div class="col-auto px-1">
      <app-min-average-max-rise-filter [viewType]="viewType"></app-min-average-max-rise-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Max. avg. max. drop -->
    <div class="col-auto px-1">
      <app-max-average-max-drop-filter [viewType]="viewType"></app-max-average-max-drop-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. highest rise -->
    <div class="col-auto px-1">
      <app-min-highest-rise-filter [viewType]="viewType"></app-min-highest-rise-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Max. highest drop -->
    <div class="col-auto px-1">
      <app-max-highest-drop-filter [viewType]="viewType"></app-max-highest-drop-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. market cap -->
    <div class="col-auto px-1">
      <app-min-market-cap-filter [viewType]="viewType"></app-min-market-cap-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>

    <!-- Min. win ratio (%) -->
    <div class="col-auto px-1">
      <app-min-win-ratios-filter [viewType]="viewType"></app-min-win-ratios-filter>
    </div>

    <div class="col-auto px-1">
      <app-filter-spacer></app-filter-spacer>
    </div>


    <!-- Clear all filters -->
    <div class="col-auto px-1">
      <a (click)="clearAllFilters()" class="hover-pointer" i18n>Clear all filters</a>
    </div>


  </div>


</form>
