import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StatisticalReturn} from '../../../shared/models/statisticalReturn.interface';
import {StockService} from '../../../shared/services/stock.service';
import {Symbol} from '../../../shared/models/symbol.interface';
import firebase from 'firebase';
import {environment} from '../../../../environments/environment';
import {StatisticalTradingService} from '../../statistical-trading.service';
import Util from '../../../shared/util';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Component({
  selector: 'app-statistical-returns-table-loader',
  templateUrl: './statistical-returns-table-loader.component.html',
  styleUrls: ['./statistical-returns-table-loader.component.scss'],
})
export class StatisticalReturnsTableLoaderComponent extends BaseComponent implements OnInit {

  _symbolCode?: string = undefined;

  get symbolCode(): string | undefined {
    return this._symbolCode;
  }

  @Input() set symbolCode(symbolCode: string | undefined) {
    this._symbolCode = symbolCode;
    this.resetComponent();
    this.fetchStatisticalReturns();
  }

  @Input() symbol?: Symbol;

  statisticalReturns: StatisticalReturn[] = [];
  thereIsMore = true;
  lastVisibleStatisticalReturn?: DocumentSnapshot<StatisticalReturn>;

  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;
  searching: boolean = false;
  private readonly spinnerKeyFetchingSymbol = 'fetchSymbol';
  private readonly spinnerKeyStatisticalReturns = 'fetchStatisticalReturns';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private activatedRoute: ActivatedRoute,
      private stockService: StockService,
      private statisticalTradingService: StatisticalTradingService,
      private router: Router) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public fetchStatisticalReturns() {
    if (!this.symbolCode) {
      this.addError($localize`Please select a symbol first.`);
      return;
    }
    this.searching = true;
    this.addLoadingSpinnerMessage(this.spinnerKeyStatisticalReturns, $localize`Fetching statistical returns`);
    this.statisticalTradingService.fetchStatisticalReturns(this.symbolCode!, this.lastVisibleStatisticalReturn).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyStatisticalReturns);
      this.searching = false;
      if (wrapper.data) {
        this.statisticalReturns.push(...wrapper.data);
        // Recreate the statisticalReturns array in order to trigger a fresh setStatisticalReturns in view component. This is necessary for the table to be initialized
        // this.statisticalReturns = [...this.statisticalReturns];
        this.thereIsMore = wrapper.data.length === environment.defaultLoadStatisticalReturnsCount;
        this.lastVisibleStatisticalReturn = wrapper.lastVisible;

      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });

  }

  /**
   * Called, when the Show more button is clicked. Loads more trades.
   */
  onClickShowMore(): void {
    this.fetchStatisticalReturns();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

  onStatisticalReturnsLoaded(statisticalReturns: StatisticalReturn[]) {
    this.statisticalReturns = statisticalReturns;
  }

  private resetComponent() {
    this.thereIsMore = true;
    this.statisticalReturns = [];
    this.lastVisibleStatisticalReturn = undefined;
  }
}
