import {createAction, props} from '@ngrx/store';
import {StatisticalTradingPlan} from '../shared/models/statisticalTradingPlan.interface';

export const addStatisticalTradingPlanToCache = createAction('[Stock] Adding statisticalTradingPlan to cache', props<{
  statisticalTradingPlan: StatisticalTradingPlan
}>());
export const addStatisticalTradingPlansToCache = createAction('[Stock] Adding statisticalTradingPlans to cache', props<{
  statisticalTradingPlans: StatisticalTradingPlan[]
}>());
export const clearStatisticalTradingPlanCache = createAction('[Stock] Clearing statisticalTradingPlan cache');












