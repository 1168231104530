<div *ngIf="statRet">
  <table class="table table-striped table-sm table-responsive-md table-sticky-header">
    <thead>
    <tr>
      <th i18n>Year</th>
      <th><span i18n i18n-matTooltip matTooltip="The buy date of the symbol">Buy date</span></th>
      <th><span i18n i18n-matTooltip matTooltip="The sell date of the symbol">Sell date</span></th>
      <th><span i18n i18n-matTooltip matTooltip="The buying price of the symbol">Buy price</span></th>
      <th><span i18n i18n-matTooltip matTooltip="The selling price of the symbol">Sell price</span></th>
      <th><span i18n>Profit</span></th>
      <th><span i18n i18n-matTooltip matTooltip="The relative profit made when the symbol was bought on the buy date and sold on the sell date.">Relative profit</span></th>
      <th><span i18n i18n-matTooltip [matTooltip]="truncatedProfitTooltip">Truncated relative profit</span></th>
      <th><span i18n>Dividends</span></th>
      <th><span i18n i18n-matTooltip matTooltip="The max. rise within the buy and sell period relative to the buy price over all years">Max. Rise</span></th>
      <th><span i18n i18n-matTooltip matTooltip="The max. rise within the buy and sell period relative to the buy price over all years">Max. drop</span></th>
    </tr>
    </thead>
    <tbody>

    <ng-container *ngIf="statRet.buyDateByYearMap">
      <tr *ngFor="let year of getKeys(statRet.buyDateByYearMap).reverse()">
        <td>{{year}}</td>
        <td>{{utilService.getDate(statRet.buyDateByYearMap[year]) | moment:'L'}}</td>
        <td *ngIf="statRet.sellDateByYearMap; else none">{{utilService.getDate(statRet.sellDateByYearMap[year]) | moment:'L'}}</td>
        <td *ngIf="statRet.buyPriceYearMap; else none">
          <app-money [value]="statRet.buyPriceYearMap[year]" [currencyId]="statRet.currency"></app-money>
        </td>
        <td *ngIf="statRet.sellPriceYearMap; else none">
          <app-money [value]="statRet.sellPriceYearMap[year]" [currencyId]="statRet.currency"></app-money>
        </td>
        <td *ngIf="statRet.profitAbsoluteYearMap; else none">
          <span [class]="statRet.profitAbsoluteYearMap![year]! | valueClassPipe">
            <app-money [value]="statRet.profitAbsoluteYearMap[year]" [currencyId]="statRet.currency"></app-money>
          </span>
        </td>
        <td *ngIf="statRet.profitByYearMap; else none">
          <span [class]="statRet.profitByYearMap![year]! | valueClassPipe">
            <app-percentage-view [value]="statRet.profitByYearMap[year]"></app-percentage-view>
          </span>
        </td>
        <td *ngIf="statRet.profitTruncatedByYearMap; else none">
          <span [class]="statRet.profitTruncatedByYearMap![year]! | valueClassPipe">
            <app-percentage-view [value]="statRet.profitTruncatedByYearMap[year]"></app-percentage-view>
          </span>
        </td>
        <td *ngIf="statRet.dividendPaymentsYearMap; else none">
          <app-money [value]="statRet.dividendPaymentsYearMap[year]" [currencyId]="statRet.currency"></app-money>
        </td>
        <td *ngIf="statRet.maxRiseByYearMap; else none">
          <app-percentage-view [value]="statRet.maxRiseByYearMap[year]"></app-percentage-view>
        </td>
        <td *ngIf="statRet.maxDropByYearMap; else none">
          <app-percentage-view [value]="statRet.maxDropByYearMap[year]"></app-percentage-view>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

<ng-template #none>
  -
</ng-template>
