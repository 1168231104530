import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';
import {environment} from '../../../../../environments/environment';
import {StatisticalTradingService} from '../../../statistical-trading.service';
import Util from '../../../../shared/util';

@Component({
  selector: 'app-period-view',
  templateUrl: './period-view.component.html',
  styleUrls: ['./period-view.component.scss'],
})
export class PeriodViewComponent implements OnInit {

  nonTruncatedProfit?: number;
  years: string[] = [];

  @Input() isYearClickable = false;
  @Output() onYearClicked = new EventEmitter<string>();

  _statisticalReturn?: StatisticalReturn;

  get statisticalReturn(): StatisticalReturn | undefined {
    return this._statisticalReturn;
  }

  @Input() set statisticalReturn(statisticalReturn: StatisticalReturn | undefined) {
    this._statisticalReturn = statisticalReturn;
    this.doCalculations();
  }

  _period?: String;

  get period(): String | undefined {
    return this._period;
  }

  @Input() set period(period: String | undefined) {
    this._period = period;
    this.doCalculations();
  }

  private doCalculations() {
    this.determineYears();
    this.calculateNonTruncatedProfit();
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  private determineYears() {
    if (!this.period || !this.statisticalReturn)
      return;

    const period = Number(this.period);
    let latestYear = StatisticalTradingService.determineLatestYear([this.statisticalReturn]);
    if (latestYear === undefined)
      latestYear = Util.getCurrentYear();
    let loopYear = latestYear;
    while (this.years.length < period) {
      this.years.push(loopYear.toString());
      loopYear--;
    }
  }

  getTruncatedProfit(profit: number) {
    if (profit > environment.relativeProfitTruncateThreshold)
      return environment.relativeProfitTruncateThreshold;
    if (profit < -environment.relativeProfitTruncateThreshold)
      return -environment.relativeProfitTruncateThreshold;
    return profit;
  }

  getAveragePeriodProfit() {
    if (!this.statisticalReturn?.profitByPeriodMap)
      return undefined;
    const period: string = this.period as string;
    return this.statisticalReturn?.profitByPeriodMap[period];
  }

  private calculateNonTruncatedProfit() {
    if (this.statisticalReturn)
      this.nonTruncatedProfit = StatisticalTradingService.calculateNonTruncatedProfit(this.statisticalReturn, this.years);
    else this.nonTruncatedProfit = undefined;
  }

  public onYearClick(year: string) {
    this.onYearClicked.emit(year);
  }
}
