<app-breadcrumb [currentPageTitle]="getPageTitle()" [pages]="getBreadcrumbPages()"></app-breadcrumb>

<h1 i18n class="paragraph-headline">{{getPageTitle()}}</h1>

<app-not-logged-in *ngIf="!firebaseUser" i18n-message="not-logged-in message account management"
                   message="You need to login, before creating or editing a trading plan."></app-not-logged-in>
<div *ngIf="firebaseUser">
  <mat-stepper orientation="vertical" [linear]="true" #stepper (selectionChange)="onStepSelectionChange($event)" *ngIf="nameAndTypeFormGroup">
    <mat-step [stepControl]="nameAndTypeFormGroup" [label]="stepLabels[0]">
      <form [formGroup]="nameAndTypeFormGroup">
        <ng-template matStepLabel i18n>Plan name and type</ng-template>
        <mat-form-field class="text-input-100">
          <mat-label i18n>Plan name</mat-label>
          <input matInput placeholder="The name of your plan" i18n-placeholder formControlName="name" required [maxlength]="100">
        </mat-form-field>

        <label id="example-radio-group-label" i18n>Do you want to create a manual plan or should we automatically select good trading opportunities for you?</label>
        <mat-radio-group
          aria-labelledby="example-radio-group-label" #type
          class="default-radio-group"
          formControlName="type">
          <mat-radio-button *ngFor="let type of planTypes" [value]="type.value" (change)="onTypeChanged($event,type)">{{type.label}}</mat-radio-button>
        </mat-radio-group>

        <div class="alert alert-warning" *ngIf="isAutomaticPlanSelected" i18n>The Statistical trading planner is based on statistical calculations and should not be considered as financial advice, as future market developments are inherently uncertain.</div>

        <div class="mt-3">
          <button i18n="Next" mat-raised-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="statisticalTradingPlanSettingsFormGroup" *ngIf="statisticalTradingPlanSettingsFormGroup && isAutomaticPlanSelected" [label]="stepLabels[1]">
      <ng-template matStepLabel i18n>Automatic planning settings</ng-template>
      <app-statistical-trading-plan-settings-form [formGroup]="statisticalTradingPlanSettingsFormGroup" planType="automatic"
                                                  (onExchangeSelectionChanged)="exchangeSelectionChanged($event)" [selectedExchangeShortNames]="exchangeShortNames"
                                                  (onExcludedSymbolSelectionChanged)="excludedSymbolSelectionChanged($event)" [excludedSymbolCodes]="excludedSymbolCodes"></app-statistical-trading-plan-settings-form>
      <div>
        <button class="mr-2" i18n="Back" mat-raised-button matStepperPrevious>Back</button>
        <button i18n="Save" mat-raised-button color="primary" matStepperNext>Save</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="statisticalTradingPlanSettingsFormGroup" *ngIf="statisticalTradingPlanSettingsFormGroup && !isAutomaticPlanSelected" [label]="stepLabels[1]">
      <ng-template matStepLabel i18n>Manual planning settings</ng-template>
      <app-statistical-trading-plan-settings-form [formGroup]="statisticalTradingPlanSettingsFormGroup" planType="manual"
                                                  (onExchangeSelectionChanged)="exchangeSelectionChanged($event)"
                                                  (onExcludedSymbolSelectionChanged)="excludedSymbolSelectionChanged($event)"></app-statistical-trading-plan-settings-form>
      <div>
        <button class="mr-2" i18n="Back" mat-raised-button matStepperPrevious>Back</button>
        <button i18n="Save" mat-raised-button color="primary" matStepperNext>Save</button>
      </div>
    </mat-step>
    <mat-step [label]="stepLabels[3]">
      <ng-template matStepLabel i18n>Finish</ng-template>

      <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

      <div class="my-3">
        <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
      </div>
      
      <h3 *ngIf="optimizing" i18n>We are optimizing your plan.</h3>
      <h3 *ngIf="optimizingFinished" i18n>We finished optimizing your plan.</h3>
      <p *ngIf="optimizing || optimizingFinished" i18n>Optimizing the plan means that we choose a good selection of trades for you.</p>
      <p *ngIf="optimizing" i18n>The optimization is done in the background and can take a few minutes. You don&apos;t need to stay on this page.</p>


      <div class="mt-3" *ngIf="savingSuccessful">
        <app-mat-icon-button *ngIf="user" [routerLink]="['/statistical-trading','planner','plans', planUid]" color="primary" cssClass="mb-3 mr-1" i18n-label icon="event"
                             label="View plan"></app-mat-icon-button>
        <app-mat-icon-button *ngIf="user" [routerLink]="['/statistical-trading','planner','plans']" color="primary" cssClass="mb-3" i18n-label icon="event"
                             label="Go to my plans"></app-mat-icon-button>
      </div>

    </mat-step>
  </mat-stepper>

</div>
