import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';

@Component({
  selector: 'app-trade-price-modal',
  templateUrl: './trade-price-modal.component.html',
  styleUrls: ['./trade-price-modal.component.scss'],
})
export class TradePriceModalComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  
  @Input() statRet: StatisticalReturn | undefined;
  @Input() year: number | undefined;

  constructor(public activeModal: NgbActiveModal,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }

}
