<div class="container bg-container p-1">
  <app-not-logged-in *ngIf="!firebaseUser" i18n-message="not-logged-in message account management"
                     message="You need to login, before editing your account."></app-not-logged-in>
  <div *ngIf="firebaseUser">
    <div class="row">
      <div class="col">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
