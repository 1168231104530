import {Component, Input, OnInit} from '@angular/core';
import {StatisticalTrade} from '../../../../shared/models/statisticalTrade.interface';

@Component({
  selector: 'app-future-trades-count',
  templateUrl: './future-trades-count.component.html',
  styleUrls: ['./future-trades-count.component.scss'],
})
export class FutureTradesCountComponent implements OnInit {

  @Input() trades?: StatisticalTrade[];

  constructor() {
  }

  ngOnInit(): void {
  }

  getFutureTradesCount(trades: StatisticalTrade[]) {
    let futureTrades = trades.filter(it => !it.bought || !it.sold);
    return futureTrades.length;
  }
}
