import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stock-type',
  templateUrl: './stock-type.component.html',
  styleUrls: ['./stock-type.component.scss'],
})
export class StockTypeComponent implements OnInit {

  @Input() type?: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
