<!-- Breadcrumb-->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <a class="breadcrumb-item" i18n="Account" [routerLink]="['/account']">Account</a>
    <li class="breadcrumb-item">
      <span i18n="Table columns headline">Table columns</span>
    </li>
  </ol>
</nav>

<div class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="Table columns headline">Table columns</h1>
  <p class="jumbotron-lead" i18n="settings lead text">Change what columns should be visible in Stock Forecast tables.</p>
</div>

<div class="container-fluid p-1">

  <mat-tab-group (selectedTabChange)="onTabSwitchByClick($event)" mat-align-tabs="start" [selectedIndex]="selectedIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-1" color="primary">trending_up</mat-icon>
        <span i18n>Statistical returns</span>
      </ng-template>

      <h1 class="paragraph-headline mt-2" i18n>Statistical returns</h1>

      <p i18n>Select the columns that should be shown in statistical returns tables.</p>

      <app-drag-drop-columns-list [visible]="tableColumnsStatisticalReturns" [all]="tableColumnsStatisticalReturn" (save)="onSaveColumns($event, 'statistical-returns')"></app-drag-drop-columns-list>

    </mat-tab>
  </mat-tab-group>
</div>
