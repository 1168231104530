<mat-tab-group (selectedTabChange)="onTabSwitch($event)" mat-align-tabs="start">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-1" color="primary">event</mat-icon>
      <span i18n="website">Steps</span>
    </ng-template>

    <section class="trades-table-container mat-elevation-z8" tabindex="0">

      <div class="responsiveTable">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="asc">

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef i18n matTooltip="Edit or delete the step" i18n-matTooltip>&nbsp;</th>
            <td mat-cell *matCellDef="let step">
              <app-statistical-trading-plan-trade-action-menu [plan]="plan" [step]="step" (onSuccessfulEdit)="tradeEdited($event)" (onEditFailed)="tradeEditFailed($event)" (onSuccessfulDeletion)="tradeDeleted($event)" (onDeletionFailed)="tradeDeletionFailed($event)"></app-statistical-trading-plan-trade-action-menu>
            </td>
          </ng-container>

          <ng-container matColumnDef="stock" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef i18n>Stock</th>
            <td mat-cell *matCellDef="let step">
              <a *ngIf="step.symbolCode && step.statisticalReturnUid; else none" [routerLink]="['/statistical-trading','symbols',step.symbolCode, step.statisticalReturnUid]">
                <app-symbol-descriptor [symbolCode]="step.symbolCode" [nameOnly]="true"></app-symbol-descriptor>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Date</th>
            <td mat-cell *matCellDef="let step">
              <app-date-view [date]="step.date" mode="compact-date-only"></app-date-view>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Action</th>
            <td mat-cell *matCellDef="let step">
              <app-trade-action-view [action]="step.action"></app-trade-action-view>
            </td>
          </ng-container>

          <ng-container matColumnDef="realized">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Realized</th>
            <td mat-cell *matCellDef="let step">
              <app-trade-realized-view [realized]="step.realized"></app-trade-realized-view>
            </td>
          </ng-container>

          <ng-container matColumnDef="priceGross">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Price gross</th>
            <td mat-cell *matCellDef="let step">
              <app-money *ngIf="step.priceGross !== undefined; else none" [value]="step.priceGross" [currencyId]="plan?.currency"></app-money>
            </td>
          </ng-container>

          <ng-container matColumnDef="orderFeeAbsolute">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Order fee</th>
            <td mat-cell *matCellDef="let step">
              <app-money *ngIf="step.orderFeeAbsolute !== undefined; else none" [value]="step.orderFeeAbsolute" [currencyId]="plan?.currency"></app-money>
            </td>
          </ng-container>

          <ng-container matColumnDef="taxAbsolute">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tax</th>
            <td mat-cell *matCellDef="let step">
              <app-money *ngIf="step.taxAbsolute !== undefined; else none" [value]="step.taxAbsolute" [currencyId]="plan?.currency"></app-money>
            </td>

          </ng-container>

          <ng-container matColumnDef="priceNet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Price net</th>
            <td mat-cell *matCellDef="let step">
              <app-money *ngIf="step.priceNet !== undefined; else none" [value]="step.priceNet" [currencyId]="plan?.currency"></app-money>
            </td>
          </ng-container>

          <ng-container matColumnDef="profitAbsoluteGross">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Profit Gross</th>
            <td mat-cell *matCellDef="let step">
              <ng-container *ngIf="step.profitAbsoluteGross !== undefined && step.profitRelativeGross !== undefined; else none">
                <app-money *ngIf="step.profitAbsoluteGross !== undefined" [value]="step.profitAbsoluteGross" [currencyId]="plan?.currency">
                </app-money>
                <span *ngIf="step.profitRelativeGross">(<app-percentage-view [value]="step.profitRelativeGross"></app-percentage-view>)</span>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="profitAbsoluteNet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Profit Net</th>
            <td mat-cell *matCellDef="let step">
              <ng-container *ngIf="step.profitAbsoluteNet !== undefined && step.profitRelativeNet !== undefined; else none">
                <app-money *ngIf="step.profitAbsoluteNet !== undefined" [value]="step.profitAbsoluteNet" [currencyId]="plan?.currency">
                </app-money>
                <span *ngIf="step.profitRelativeNet">(<app-percentage-view [value]="step.profitRelativeNet"></app-percentage-view>)</span>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="walletBeforeAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Wallet before action</th>
            <td mat-cell *matCellDef="let step">
           <span class="icon-with-text" *ngIf="step.walletBeforeAction !== undefined; else none">
          <app-money [value]="step.walletBeforeAction" [currencyId]="plan?.currency"></app-money>
          <mat-icon *ngIf="step.walletBeforeAction<=-0.01" color="warn" class="ml-1" i18n-matTooltip matTooltip="Your wallet balance is negative.">warning</mat-icon>
        </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="walletAfterAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Wallet after action</th>
            <td mat-cell *matCellDef="let step">
<span class="icon-with-text" *ngIf="step.walletAfterAction !== undefined; else none">
          <app-money [value]="step.walletAfterAction" [currencyId]="plan?.currency"></app-money>
          <mat-icon *ngIf="step.walletAfterAction<=-0.01" color="warn" class="ml-1" i18n-matTooltip matTooltip="Your wallet balance is negative.">warning</mat-icon>
        </span></td>
            <td>

            </td>
          </ng-container>

          <ng-container matColumnDef="depotValueAfterAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Depot</th>
            <td mat-cell *matCellDef="let step">
              <app-money *ngIf="step.depotValueAfterAction !== undefined; else none" [value]="step.depotValueAfterAction" [currencyId]="plan?.currency"></app-money>
            </td>
          </ng-container>

          <ng-container matColumnDef="depotAndWalletValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Depot + wallet</th>
            <td mat-cell *matCellDef="let step">
              <app-money *ngIf="step.depotAndWalletValue !== undefined; else none" [value]="step.depotAndWalletValue" [currencyId]="plan?.currency"></app-money>
            </td>
          </ng-container>

          <ng-container matColumnDef="depotContentsAfterAction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Depot contents</th>
            <td mat-cell *matCellDef="let step">
              <a *ngIf="step.depotContentsAfterAction !== undefined; else none" (click)="showDepotContentsInModal(step);$event.preventDefault()" href="#" target="_blank" class="hover-pointer">{{step.depotContentsAfterAction.length}}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="growthAbsoluteSinceStart">
            <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Growth</th>
            <td mat-cell *matCellDef="let step">
              <ng-container *ngIf="step.growthAbsoluteSinceStart !== undefined || step.growthRelativeSinceStart !== undefined; else none">
                <app-money *ngIf="step.growthAbsoluteSinceStart !== undefined" [value]="step.growthAbsoluteSinceStart" [currencyId]="plan?.currency">
                </app-money>
                <span *ngIf="step.growthRelativeSinceStart">(<app-percentage-view [value]="step.growthRelativeSinceStart"></app-percentage-view>)</span>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="addStyleClass(row)"></tr>
        </table>

      </div>

      <p *ngIf="!dataSource || !dataSource?.filteredData?.length" i18n class="m-3">There are no steps for the specified filter.</p>

    </section>

    <ng-template #none>
      <span>-</span>
    </ng-template>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="mr-1" color="primary">inventory</mat-icon>
      <span i18n="website">Trades</span>
    </ng-template>

    <app-progress-bar *ngIf="loadingCount" label="Loading trades..." i18n-label></app-progress-bar>

    <div class="my-3">
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
    </div>

    <ng-container *ngIf="statisticalReturns">

      <ng-container *ngIf="statisticalReturns.length > 0; else noStatisticalReturns">
        <app-statistical-returns-table [showName]="true" [statisticalReturns]="statisticalReturns" [sortable]="true" viewType="best"
                                       [showExchange]="true"></app-statistical-returns-table>
      </ng-container>

      <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [messagesByKey]="loadingSpinnerMessagesByKey"
                           [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

      <ng-template #noStatisticalReturns>
        <ng-container *ngIf="!loadingCount">
          <div class="alert alert-info" i18n>This plan does not contain any trades or we're having trouble finding them.</div>
        </ng-container>
      </ng-template>
    </ng-container>

  </mat-tab>
</mat-tab-group>
