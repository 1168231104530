<ng-container *ngIf="column !== undefined && statRet !== undefined; else noColumn">

  <ng-container [ngSwitch]="column?.id">

    <ng-container *ngSwitchCase="'SYMBOL_NAME'">
      <ng-container *ngIf="statRet?.symbolCode as symbolCode">
        <a [routerLink]="['/statistical-trading', 'symbols', symbolCode]">
          <ng-container *ngIf="symbol">
            <!-- Name without shortening-->
            <span *ngIf="nameMaxLength == undefined || nameMaxLength >= symbol.name.length"
                  matTooltip="Code: {{symbol.code}}&#13;Exchange: {{symbol.exchangeShortName}}" i18n-matTooltip>{{symbol.name}}</span>
            <!-- Name with shortening-->
            <span *ngIf="nameMaxLength && nameMaxLength < symbol.name.length"
                  matTooltip="{{symbol.name}}&#13;Code: {{symbol.code}}&#13;Exchange: {{symbol.exchangeShortName}}" i18n-matTooltip>{{symbol.name | shorten:nameMaxLength:true}}</span>
          </ng-container>
          <!--Symbol code -->
          <span *ngIf="!symbol && symbolCode" matTooltip="Code: {{symbolCode}}" i18n-matTooltip>{{symbolCode}}</span>
        </a>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'SYMBOL_CODE'">
      <ng-container *ngIf="statRet?.symbolCode as symbolCode">
        <a [routerLink]="['/statistical-trading', 'symbols', symbolCode]">{{symbolCode}}</a>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'EXCHANGE'">
      <ng-container *ngIf="symbol && symbol.exchangeShortName">
        <a (click)="onExchangeClick(symbol.exchangeShortName)" class="hover-pointer">
          <app-exchange-descriptor mode="codeOnly" [exchangeShortName]="symbol?.exchangeShortName"></app-exchange-descriptor>
        </a>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'CURRENCY'">
      {{statRet.currency}}
    </ng-container>

    <ng-container *ngSwitchCase="'BUY_DATE'">
      {{getFormattedBuyDate(statRet)}}
    </ng-container>

    <ng-container *ngSwitchCase="'SELL_DATE'">
      {{getFormattedSellDate(statRet)}}
    </ng-container>

    <ng-container *ngSwitchCase="'PRICE'">
      <app-money *ngIf="symbol && symbol.price" [value]="symbol.price" [currencyId]="symbol.currency" [bigMoney]="false"></app-money>
    </ng-container>
    
    <ng-container *ngSwitchCase="'DURATION'">
      {{statRet.duration}}
    </ng-container>

    <ng-container *ngSwitchCase="'AVG_PROFIT'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.averageRelativeProfit !== undefined; else none">
        <app-percentage-view [value]="statRet.averageRelativeProfit"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'WORST_YEAR'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.lowestYearlyProfit !== undefined; else none">
        <app-percentage-view [value]="statRet.lowestYearlyProfit"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'DATA_YEARS'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.profitByPeriodMap !== undefined; else none">
        {{getNumberOfDataYears(statRet)}}
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'WIN_RATIO'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.winRatio !== undefined; else none">
        <app-percentage-view [value]="statRet.winRatio"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'AVG_MAX_RISE'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.averageMaxRise !== undefined; else none">
        <app-percentage-view [value]="statRet.averageMaxRise"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'AVG_MAX_DROP'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.averageMaxDrop !== undefined; else none">
        <app-percentage-view [value]="statRet.averageMaxDrop"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'HIGHEST_RISE'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.highestMaxRise !== undefined; else none">
        <app-percentage-view [value]="statRet.highestMaxRise"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'HIGHEST_DROP'">
      <ng-container
              *ngIf="statRet !== undefined && statRet.highestMaxDrop !== undefined; else none">
        <app-percentage-view [value]="statRet.highestMaxDrop"></app-percentage-view>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'MARKET_CAP'">
      <app-money *ngIf="symbol && symbol.mktCapUsd" [value]="symbol.mktCapUsd" currencyId="USD" [bigMoney]="true"></app-money>
    </ng-container>

    <ng-container *ngSwitchCase="'AVG_VOLUME_UNITS'">
      <ng-container *ngIf="symbol && symbol.volAvg && symbol.currency">
        {{symbol.volAvg | number:'1.0-0':locale}}</ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'AVG_VOLUME_PRICE'">
      <app-money *ngIf="symbol && symbol.volAvg && symbol.price && symbol.currency" [value]="symbol.volAvg * symbol.price" [currencyId]="symbol.currency" [bigMoney]="true"></app-money>
    </ng-container>

    <ng-container *ngSwitchCase="'AVG_VOLUME_BASE_CURRENCY'">
      <app-money *ngIf="symbol && symbol.volAvgBaseCurrency" [value]="symbol.volAvgBaseCurrency" [currencyId]="baseCurrency" [bigMoney]="true"></app-money>
    </ng-container>

  </ng-container>
</ng-container>

<ng-template #noColumn>
  <span>&nbsp;</span>
</ng-template>

<ng-template #none>
  -
</ng-template>
