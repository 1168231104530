<ng-container *ngIf="currencyControl">
  <mat-form-field>
    <mat-label i18n="currency">Currency</mat-label>
    <mat-select [formControl]="currencyControl" [value]="selectedCurrencyId" id="currencyId" panelClass="mat-select-panel-500px" required (selectionChange)="currencyChanged($event)" [disabled]="disabled"
                [errorStateMatcher]="errorStateMatcher">
      <mat-option [value]="undefined" i18n>Please select a currency</mat-option>
      <mat-option *ngFor="let currency of currencies" [value]="currency.id">{{currencyService.getCurrencyName1(currency)}}</mat-option>
    </mat-select>
    <mat-error *ngIf="currencyControl.invalid && currencyControl.touched" i18n>Please select a currency.</mat-error>

  </mat-form-field>
</ng-container>
