import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {StatisticalTradeStep} from '../../../../shared/models/statisticalTradeStep.interface';
import {PlannerService} from '../../planner.service';
import {StatisticalTradingPlan} from '../../../../shared/models/statisticalTradingPlan.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DepotContentsModalComponent} from '../depot-contents-modal/depot-contents-modal.component';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UtilService} from '../../../../shared/util.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StockService} from '../../../../shared/services/stock.service';
import {StatisticalTradingService} from '../../../statistical-trading.service';
import {TitleService} from '../../../../shared/services/title.service';
import {StatisticalTradeDeletionResult} from '../../../../shared/models/statisticalTradeDeletionResult.interface';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';
import {StatisticalReturnDescriptor} from '../../../../shared/models/statisticalReturnDescriptor.interface';
import {StatisticalTrade} from '../../../../shared/models/statisticalTrade.interface';

@Component({
  selector: 'app-statistical-trading-plan-trades-view',
  templateUrl: './statistical-trading-plan-trades-view.component.html',
  styleUrls: ['./statistical-trading-plan-trades-view.component.scss'],
})
export class StatisticalTradingPlanTradesViewComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatTable) table?: MatTable<StatisticalTradeStep>;
  dataSource = new MatTableDataSource<StatisticalTradeStep>();
  displayedColumns = ['actions', 'stock', 'date', 'action', 'realized', 'priceGross', 'orderFeeAbsolute',
    'taxAbsolute', 'priceNet', 'profitAbsoluteGross', 'profitAbsoluteNet', 'walletBeforeAction', 'walletAfterAction',
    'depotValueAfterAction', 'depotAndWalletValue', 'depotContentsAfterAction', 'growthAbsoluteSinceStart'];

  selection = new SelectionModel<StatisticalTradeStep>(true, []);

  planDeletedSnackbar ?: MatSnackBarRef<TextOnlySnackBar>;
  undoPlanDeletedSnackbar ?: MatSnackBarRef<TextOnlySnackBar>;


  @Output() planEdited = new EventEmitter<StatisticalTradingPlan>();

  _plan?: StatisticalTradingPlan;

  get plan(): StatisticalTradingPlan | undefined {
    return this._plan;
  }

  @Input() set plan(plan: StatisticalTradingPlan | undefined) {
    this._plan = plan;
    this.determineSteps();
    this.loadStatisticalReturns();
  }

  statisticalReturns?: StatisticalReturn[];
  loadingCount = 0;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    public utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private stockService: StockService,
    private statisticalTradingService: StatisticalTradingService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private plannerService: PlannerService,
    private titleService: TitleService,
    private modalService: NgbModal) {
    super(authService, userService, store);

  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private determineSteps(): void {
    if (this.plan?.trades)
      this.dataSource.data = this.plannerService.determineSteps(this.plan?.trades, this.plan?.seedCapital);
    else
      this.dataSource.data = [];
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort ? this.sort : null;
  }

  showDepotContentsInModal(step: StatisticalTradeStep): void {
    const modalRef = this.modalService.open(DepotContentsModalComponent, {centered: true});
    modalRef.componentInstance.step = step;
    modalRef.componentInstance.currencyId = this.plan?.currency;
  }

  tradeEdited(resultingPlan: StatisticalTradingPlan): void {
    this.plan = resultingPlan;
    this.planEdited.emit(this.plan);
    this.planDeletedSnackbar = this.matSnackBar.open($localize`The trade was successfully edited.`, undefined, {
      duration: 10000,
    });
  }

  tradeEditFailed(errorMessage: string): void {
    this.addError(errorMessage);
  }

  tradeDeleted(deletionResult: StatisticalTradeDeletionResult): void {
    this.plan = deletionResult.resultingPlan;
    this.planEdited.emit(this.plan);
    this.planDeletedSnackbar = this.matSnackBar.open($localize`The trade was successfully deleted from your plan ${this.plan.name}.`, $localize`Undo`, {
      duration: 60000,
    });
    this.planDeletedSnackbar.onAction().subscribe(() => {
      if (!this.user?.uid) {
        this.addError($localize`You are not logged in.`);
        return;
      }
      this.plannerService.undoDeletingStatisticalReturnFromTradingPlan(deletionResult, this.user.uid).then(wrapper => {
        if (wrapper.success && wrapper.data) {
          this.plan = wrapper.data;
          this.planEdited.emit(this.plan);
          this.undoPlanDeletedSnackbar = this.matSnackBar.open($localize`The trade was restored successfully.`, undefined, {
            duration: 10000,
          });
        }
        if (wrapper.errorMessage)
          this.addError($localize`The deletion could not be undone: ` + wrapper.errorMessage);
      });
    });
  }

  tradeDeletionFailed(errorMessage: string): void {
    this.addError(errorMessage);
  }


  addStyleClass(row: any) {
    const styleClass = {
      defaultRow: !row.action,
      buyRow: row.action === 'buy',
      sellRow: row.action === 'sell',
    };
    return styleClass;
  }


  onTabSwitch($event: MatTabChangeEvent): void {
    this.clearAlerts();
  }

  private loadStatisticalReturns() {
    if (!this.plan?.trades || this.plan.trades.length === 0)
      return;

    const statRetDescriptors: StatisticalReturnDescriptor[] = this.getUniqueStatRetDescriptors(this.plan.trades);
    this.statisticalReturns = [];

    statRetDescriptors.forEach(it => {
      this.loadingCount++;
      this.statisticalTradingService.fetchStatisticalReturn(it.symbolCode, it.statisticalReturnUid).then(wrapper => {
        this.loadingCount--;
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
        if (wrapper.success && wrapper.data)
          this.statisticalReturns?.push(wrapper.data);
      });
    });
  }

  private getUniqueStatRetDescriptors(trades: StatisticalTrade[]) {
    return trades.reduce((uniqueDescriptors: StatisticalReturnDescriptor[], trade) => {
      const existingDescriptor = uniqueDescriptors.find(descriptor => descriptor.symbolCode === trade.symbolCode && descriptor.statisticalReturnUid === trade.statisticalReturnUid);
      if (!existingDescriptor) {
        uniqueDescriptors.push({symbolCode: trade.symbolCode, statisticalReturnUid: trade.statisticalReturnUid});
      }
      return uniqueDescriptors;
    }, []);
  }
}
