import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StatisticalTradingComponent} from './statistical-trading.component';
import {StatisticalTradingOverviewComponent} from './statistical-trading-overview/statistical-trading-overview.component';
import {SymbolViewComponent} from './symbols/symbol-view/symbol-view.component';
import {NoSymbolSelectedComponent} from './symbols/no-symbol-selected/no-symbol-selected.component';
import {SymbolsComponent} from './symbols/symbols.component';
import {UpcomingTradesComponent} from './upcoming-trades/upcoming-trades.component';
import {BestTradesComponent} from './best-trades/best-trades.component';
import {StockSearchComponent} from './stock-search/stock-search.component';
import {StatisticalReturnViewComponent} from './symbols/statistical-return-view/statistical-return-view.component';
import {SimulationSearchComponent} from './simulations/simulation-search/simulation-search.component';
import {SimulationViewComponent} from './simulations/simulation-view/simulation-view.component';
import {SimulationsComponent} from './simulations/simulations.component';
import {PlannerComponent} from './planner/planner.component';
import {PlannerOverviewComponent} from './planner/planner-overview/planner-overview.component';
import {PlansComponent} from './planner/plans/plans.component';
import {StatisticalTradingPlanEditComponent} from './planner/plans/statistical-trading-plan-edit/statistical-trading-plan-edit.component';
import {StatisticalTradingPlanViewComponent} from './planner/statistical-trading-plan-view/statistical-trading-plan-view.component';

const statisticalTradingRoutes: Routes = [
  {
    path: '',
    component: StatisticalTradingComponent,
    children: [
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
      {path: 'overview', component: StatisticalTradingOverviewComponent},
      {path: 'stock-search', component: StockSearchComponent},
      {path: 'upcoming-trades', component: UpcomingTradesComponent},
      {path: 'best-trades', component: BestTradesComponent},
      {
        path: 'symbols', component: SymbolsComponent, children: [
          {path: '', component: NoSymbolSelectedComponent},
          {path: ':symbolCode', component: SymbolViewComponent},
          {path: ':symbolCode/:statisticalReturnUid', component: StatisticalReturnViewComponent},
        ],
      },
      {
        path: 'simulations', component: SimulationsComponent, children: [
          {path: '', component: SimulationSearchComponent},
          {path: 'search', component: SimulationSearchComponent},
          {path: ':simulationUid', component: SimulationViewComponent},
        ],
      },
      {
        path: 'planner', component: PlannerComponent, children: [
          {path: '', component: PlannerOverviewComponent},
          {
            path: 'plans', component: PlannerComponent, children: [
              {path: '', component: PlansComponent},
              {path: 'create', component: StatisticalTradingPlanEditComponent},
              {path: ':planUid', component: StatisticalTradingPlanViewComponent},
              {path: ':planUid/edit', component: StatisticalTradingPlanEditComponent},
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(statisticalTradingRoutes)],
  exports: [RouterModule],
})
export class StatisticalTradingRoutingModule {
}
