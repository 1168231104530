import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CompanyProfile} from '../../models/companyProfile.interface';
import {Symbol} from '../../models/symbol.interface';
import Util from '../../util';

@Component({
  selector: 'app-company-profile-view-accordion',
  templateUrl: './company-profile-view-accordion.component.html',
  styleUrls: ['./company-profile-view-accordion.component.scss'],
})
export class CompanyProfileViewAccordionComponent implements OnInit {

  @ViewChild('companyProfileView') public companyProfileViewElementRef?: ElementRef;

  @Input() companyProfile?: CompanyProfile;
  @Input() symbolCode?: string;
  @Input() symbol?: Symbol;
  @Input() companyProfileIsShown = false;
  @Output() symbolRefreshed = new EventEmitter<Symbol>();

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleShowCompanyProfile() {
    this.companyProfileIsShown = !this.companyProfileIsShown;
    if (this.companyProfileIsShown)
      Util.scrollToElementRef(this.companyProfileViewElementRef);
  }

  onSymbolRefreshed(symbol: Symbol) {
    this.symbol = symbol;
    this.symbolRefreshed.emit(symbol);
  }
}
