<form *ngIf="formGroup" [formGroup]="formGroup" class="pb-3">
  <div class="row">
    <div class="col">
      <h3 class="paragraph-headline-3" i18n>General conditions</h3>
    </div>
  </div>
  <div class="row">
    <!-- Seed capital -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="seedCapital">Seed capital</mat-label>
        <input #seedCapitalInput type="number" matInput name="seedCapital" formControlName="seedCapital"
               placeholder="5000" id="seedCapital" i18n-matTooltip
               matTooltip="The amount of money you start your plan with">
        <mat-icon (click)="clearInputValue('seedCapital')" *ngIf="seedCapitalInput.value" aria-label="Clear"
                  i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Max. capital per trade -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="maxCapitalPerTrade (%)">Max. capital per trade (%)</mat-label>
        <input #maxCapitalPerTradeInput type="number" matInput name="maxCapitalPerTrade"
               formControlName="maxCapitalPerTrade" [min]="0" [max]="100" i18n-matTooltip
               matTooltip="How much of your total depot value should be spent on a single trade"
               placeholder="10" id="maxCapitalPerTrade">
        <mat-icon (click)="clearInputValue('maxCapitalPerTrade')" *ngIf="maxCapitalPerTradeInput.value" aria-label="Clear"
                  i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Currency -->
    <div class="col-auto">
      <app-currency-selector formControlName="currency" [preselectDefaultCurrency]="true"></app-currency-selector>
    </div>
  </div>


  <div class="row" *ngIf="planType === 'automatic'">
    <div class="col">
      <h3 class="paragraph-headline-3" i18n>Criteria for statistical trades</h3>
      <app-info-card i18n>Only statistical trades that meet all of the following criteria will be selected:
      </app-info-card>
    </div>
  </div>

  <div class="row" *ngIf="planType === 'automatic'">
    <!-- Min. win ratio (%) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="minWinRatio">Min. win ratio (%)</mat-label>
        <input #minWinRatioInput type="number" matInput name="minWinRatio" formControlName="minWinRatio"
               placeholder="85" id="minWinRatio" i18n-matTooltip [min]="0" [max]="100"
               matTooltip="The minimum win-loss-ratio of the stock symbol. The higher the value, the lower the risk. 100% means, the statistical trade was successful every single year.">
        <mat-icon (click)="clearInputValue('minWinRatio')" *ngIf="minWinRatioInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <div class="row" *ngIf="planType === 'automatic'">
      <!-- Min. price per stock (%) -->
      <div class="col-auto">
        <mat-form-field class="input-md">
          <mat-label i18n="minPricePerStock">Min. price per stock</mat-label>
          <input #minPricePerStockInput type="number" matInput name="minPricePerStock" formControlName="minPricePerStock"
                 placeholder="5" id="minPricePerStock" i18n-matTooltip [min]="0"
                 matTooltip="The minimum buying price per stock necessary to buy the stock symbol in the automatic optimization">
          <mat-icon (click)="clearInputValue('minPricePerStock')" *ngIf="minPricePerStockInput.value" aria-label="Clear"
                    i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>
      </div>
    </div>

    <!-- Max. avg. max. drop (%) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="maxAverageMaxDrop">Max. avg. max. drop (%)</mat-label>
        <input #maxAverageMaxDropInput type="number" matInput name="maxAverageMaxDrop"
               formControlName="maxAverageMaxDrop" placeholder="15"
               id="maxAverageMaxDrop" i18n-matTooltip [min]="0" [max]="100"
               matTooltip="The maximum average maximum drop of a stock symbol in percent. The maximum drop is the relative amount by which a stock drops in value between buy day and sell day. The higher the value, the higher the risk.">
        <mat-icon (click)="clearInputValue('maxAverageMaxDrop')" *ngIf="maxAverageMaxDropInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Min. avg. volume (k$) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="minAverageVolumeBaseCurrency">Min. avg. volume (k$)</mat-label>
        <input #minAverageVolumeBaseCurrencyInput type="number" matInput
               name="minAverageVolumeBaseCurrency"
               formControlName="minAverageVolumeBaseCurrency" placeholder="100"
               id="minAverageVolumeBaseCurrency" [min]="0"
               i18n-matTooltip matTooltip="The minimum average daily trading volume of a stock symbol in thousands of USD">
        <mat-icon (click)="clearInputValue('minAverageVolumeBaseCurrency')"
                  *ngIf="minAverageVolumeBaseCurrencyInput.value" aria-label="Clear"
                  matSuffix i18n-matTooltip matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Min. market cap (M$) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="minMarketCap">Min. market cap (M$)</mat-label>
        <input #minMarketCapInput type="number" matInput name="minMarketCap" formControlName="minMarketCap"
               placeholder="1000" id="minMarketCap" [min]="0"
               i18n-matTooltip matTooltip="The minimum market cap of a stock symbol in millions of USD">
        <mat-icon (click)="clearInputValue('minMarketCap')" *ngIf="minMarketCapInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Min. data years -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="minDataYears">Min. data years</mat-label>
        <input #minDataYearsInput type="number" matInput name="minDataYears" formControlName="minDataYears"
               placeholder="15" id="minDataYears" [min]="10" [max]="30"
               i18n-matTooltip matTooltip="The minimum number of data years for the stock symbol">
        <mat-icon (click)="clearInputValue('minDataYears')" *ngIf="minDataYearsInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Max. duration (days) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="maxDuration">Max. duration (days)</mat-label>
        <input #maxDurationInput type="number" matInput name="maxDuration" formControlName="maxDuration"
               placeholder="20" id="maxDuration" [min]="0" [max]="30" i18n-matTooltip
               matTooltip="The maximum stock holding duration in days. A lower value means that more trades are possible.">
        <mat-icon (click)="clearInputValue('maxDuration')" *ngIf="maxDurationInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-2" *ngIf="planType === 'automatic'">
    <div class="col">
      <h4 class="paragraph-headline-4" i18n>Minimum average annual profit in percent:</h4>
    </div>
  </div>
  <div class="row" *ngIf="planType === 'automatic'">
    <!-- All time -->
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="minAverageRelativeProfit">All time</mat-label>
        <input #minAverageRelativeProfitInput type="number" matInput name="minAverageRelativeProfit"
               formControlName="minAverageRelativeProfit" placeholder="5" id="minAverageRelativeProfit"
               [min]="0" [max]="10">
        <mat-icon (click)="clearInputValue('minAverageRelativeProfit')" *ngIf="minAverageRelativeProfitInput.value"
                  aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Last 5 years -->
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="minAverageRelativeProfitLast5Years">Last 5 years</mat-label>
        <input #minAverageRelativeProfitLast5YearsInput type="number" matInput name="minAverageRelativeProfitLast5Years"
               formControlName="minAverageRelativeProfitLast5Years" placeholder="5" [min]="0" [max]="10"
               id="minAverageRelativeProfitLast5Years">
        <mat-icon (click)="clearInputValue('minAverageRelativeProfitLast5Years')"
                  *ngIf="minAverageRelativeProfitLast5YearsInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Last 10 years -->
    <div class="col-auto">
      <mat-form-field class="input-sm">
        <mat-label i18n="minAverageRelativeProfitLast10Years">Last 10 years</mat-label>
        <input #minAverageRelativeProfitLast10YearsInput type="number" matInput [min]="0" [max]="10"
               name="minAverageRelativeProfitLast10Years" formControlName="minAverageRelativeProfitLast10Years"
               placeholder="5" id="minAverageRelativeProfitLast10Years">
        <mat-icon (click)="clearInputValue('minAverageRelativeProfitLast10Years')"
                  *ngIf="minAverageRelativeProfitLast10YearsInput.value" aria-label="Clear" i18n-matTooltip
                  matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

  </div>


  <div class="row">
    <div class="col">
      <h3 class="paragraph-headline-3" i18n>Order fees and taxes</h3>
      <app-info-card i18n>If you want order fees and taxes to be included in this plan, enter them below. This can be
        significant because fees and taxes can reduce cash growth.
      </app-info-card>
    </div>
  </div>

  <div class="row">
    <!-- Order fee (fixed) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="orderFeeFixed">Order fee (fixed)</mat-label>
        <input #orderFeeFixedInput type="number" matInput name="orderFeeFixed" formControlName="orderFeeFixed"
               placeholder="2.50" id="orderFeeFixed" i18n-matTooltip [min]="0"
               matTooltip="The fixed part of your order fee in the base currency of you plan">
        <mat-icon (click)="clearInputValue('orderFeeFixed')" *ngIf="orderFeeFixedInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Order fee (%) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="orderFeeRelative">Order fee (%)</mat-label>
        <input #orderFeeRelativeInput type="number" matInput name="orderFeeRelative"
               formControlName="orderFeeRelative" placeholder="0.5" id="orderFeeRelative" i18n-matTooltip [min]="0"
               [max]="100" matTooltip="The relative part of your order fee in the base currency of you plan">
        <mat-icon (click)="clearInputValue('orderFeeRelative')" *ngIf="orderFeeRelativeInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Tax rate (%) -->
    <div class="col-auto">
      <mat-form-field class="input-md">
        <mat-label i18n="taxRate">Tax rate (%)</mat-label>
        <input #taxRateInput type="number" matInput name="taxRate"
               formControlName="taxRate" placeholder="26.375" id="taxRate" i18n-matTooltip [min]="0"
               [max]="100"
               matTooltip="The tax rate on profits, which should be subtracted from every profit. Losses are not taken into account.">
        <mat-icon (click)="clearInputValue('taxRate')" *ngIf="taxRateInput.value" aria-label="Clear"
                  i18n-matTooltip matSuffix matTooltip="Clear" type="button">close
        </mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="planType === 'automatic'">
    <div class="col pb-2">
      <h3 class="paragraph-headline-3" i18n>Exchanges</h3>
      <app-info-card i18n>Select the exchanges, on which you want to do statistical trades. If you don't select any, statistical trades will be selected on all exchanges.</app-info-card>
    </div>
  </div>

  <div class="row" *ngIf="planType === 'automatic'">
    <div class="col pb-3">
      <app-symbols-exchange-selector [selectedElements]="selectedExchangeShortNames" elementType="exchanges" [showQuickSelectExchangeButtons]="true"
                                     (onSelectionChanged)="exchangeSelectionChanged($event)"></app-symbols-exchange-selector>
    </div>
  </div>

  <div class="row" *ngIf="planType === 'automatic'">
    <div class="col pb-2">
      <h3 class="paragraph-headline-3" i18n>Excluded stock symbols</h3>
      <app-info-card i18n>Select stock symbols you don't want to buy.</app-info-card>
    </div>
  </div>

  <div class="row" *ngIf="planType === 'automatic'">
    <div class="col pb-2">
      <app-symbols-exchange-selector [selectedElements]="excludedSymbolCodes" elementType="symbols"
                                     (onSelectionChanged)="excludedSymbolSelectionChanged($event)"></app-symbols-exchange-selector>
    </div>
  </div>

  <!--    Eingeschlossene Trades (Trades, die auf jeden Fall enthalten sein sollen)-->
  <!--    Ausgeschlossene Trades (Trades, die nicht enthalten sein sollen)-->
</form>
