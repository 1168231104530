import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {Subject} from 'rxjs';
import {BackendService} from './backend.service';
import {ExchangeService} from './exchange.service';
import {StatisticalTradingService} from '../../statistical-trading/statistical-trading.service';
import {resetFetchUserState, resetUpdateUserState} from '../../auth/store/auth.actions';


@Injectable({
  providedIn: 'root',
})
export class InitializationService {

  destroy$: Subject<null> = new Subject();

  constructor(private store: Store<AppState>,
              private backendService: BackendService,
              private exchangeService: ExchangeService,
              private statisticalTradingService: StatisticalTradingService) {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {
    this.exchangeService.fetchAndCacheExchanges();
    this.statisticalTradingService.resetSearch();
    this.store.dispatch(resetUpdateUserState());
    this.store.dispatch(resetFetchUserState());
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }


}
