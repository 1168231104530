import {Injectable} from '@angular/core';
import {Symbol} from '../../shared/models/symbol.interface';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {Wrapper} from '../../shared/models/wrapper.model';
import {Mutex} from 'async-mutex';
import {Store} from '@ngrx/store';
import {DailyPrice} from '../../shared/models/dailyPriceData.interface';
import {UtilService} from '../../shared/util.service';
import {convertToSchedule} from '../../shared/converters/modelConverters';
import {symbolConverter} from '../../shared/services/stock.service';
import {Subject} from 'rxjs';
import {Schedule} from '../../shared/models/schedule.interface';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import firebase from 'firebase/app';
import {AppState} from '../../store/app.state';
import {environment} from '../../../environments/environment';
import {firestore} from '../../app.module';
import {PayloadResultJo} from '../../shared/models/results/payloadResultJo.interface';
import {BackendService} from '../../shared/services/backend.service';
import {HttpClient} from '@angular/common/http';
import {StatRetDeletionResult} from '../../shared/models/results/statRetDeletionResultJo.interface';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  symbolMutex = new Mutex();
  symbolsById: Map<string, Symbol> = new Map<string, Symbol>();

  scheduleMutex = new Mutex();
  schedulesById: Map<string, Schedule> = new Map<string, Schedule>();
  onScheduleSaved$ = new Subject<Schedule>();
  onScheduleDeleted$ = new Subject<string>();
  onScheduleSelected$ = new Subject<string | undefined>();

  constructor(
      private store: Store<AppState>,
      private utilService: UtilService,
      private backendService: BackendService,
      private httpClient: HttpClient) {
  }


  /**
   * Writes the given symbol and the given statistical returns for it to the database.
   * @param symbol symbols to be written
   * @param statisticalReturns statistical returns for the given symbol to be written
   */
  writeStatisticalReturns(symbol: Symbol, statisticalReturns: StatisticalReturn[]) {
    this.updateSymbol(symbol, symbol, false, async () => {
          // On successful symbol update:
          // Delete all statistical returns for symbol
          await this.deleteStatisticalReturns(symbol.code);
          // Write given statistical returns
          statisticalReturns.forEach(statisticalReturn => {
            this.insertStatisticalReturn(statisticalReturn, symbol.code, statisticalReturn => {
                  // console.log(`Successfully inserted statisticalReturn ${statisticalReturn.uid} into database.`, statisticalReturn);
                },
                error => console.error(error));
          });
        },
        error => console.error(`Error updating symbol: ${error}`),
    );
  }

  /**
   * Deletes all statistical returns for the given symbol
   * @param symbol symbol, whose statistical returns should be deleted
   */
  async deleteStatisticalReturns(symbol: string): Promise<void> {
    const batch = firebase.firestore().batch();

    const querySnapshot = await firestore.collection(environment.firestoreCollectionSymbols).doc(symbol).collection(environment.firestoreCollectionStatisticalReturns).get();
    querySnapshot.forEach(docSnapshot => {
      batch.delete(docSnapshot.ref);
    });

    batch.commit();
  }

  async fetchSymbol(uid: string, maxAgeInSec: number = environment.defaultSymbolCacheAgeInSec): Promise<Wrapper<Symbol>> {
    return this.symbolMutex.runExclusive(async () => {
      const symbolFromCache = this.symbolsById.get(uid);
      if (symbolFromCache !== undefined && this.isSymbolUpToDate(symbolFromCache, maxAgeInSec))
        return new Wrapper<Symbol>(symbolFromCache);

      try {
        const symbolDocSnapshot = await firestore.collection(environment.firestoreCollectionSymbols).doc(uid).withConverter(symbolConverter).get();
        const symbol = symbolDocSnapshot.data();
        if (symbol) {
          const symbolWithIdAndCacheDate: Symbol = {...symbol, code: symbolDocSnapshot.id, cacheDate: new Date()};
          return new Wrapper<Symbol>(symbolWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Symbol>(undefined, e.message);
      }

      // If no symbol was found
      return new Wrapper<Symbol>(undefined);
    });
  }


  /**
   * Sends the given symbol to the backend server. If it has an ID, an existing symbol is updated, otherwise a new one is added.   *
   * @param symbol symbol to be sent. Need to be provided, even if a fullSymbol is given
   * @param fullSymbol symbol to be written to the cache. This symbol is not sent to the firestore
   * @param merge if true, only the fields given in the symbol object will be updated. Otherwise, the whole symbol object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateSymbol(symbol: Symbol, fullSymbol: Symbol | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionSymbols).doc(symbol.code).set(symbol, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The symbol could not be updated\: ${error}`),
    );
    if (merge && !fullSymbol) {
      console.error('updateSymbol called with a merge job without providing a fullSymbol.');
      return;
    }

  }

  /**
   * Inserts a new statistical return into the backend database.
   */
  insertStatisticalReturn(statisticalReturn: StatisticalReturn, symbol: string, onSuccessCallback: (statisticalReturn: StatisticalReturn) => void, onErrorCallback: (error: string) => void): void {
    const uid = `${statisticalReturn.buyMonth}-${statisticalReturn.buyDay}_${statisticalReturn.sellMonth}-${statisticalReturn.sellDay}`;
    statisticalReturn = {...statisticalReturn, symbolCode: symbol};
    firestore.collection(environment.firestoreCollectionSymbols).doc(symbol).collection(environment.firestoreCollectionStatisticalReturns).doc(uid).set(statisticalReturn).then(docRef => {
          statisticalReturn.uid = uid;
          onSuccessCallback(statisticalReturn);
        },
        (error) => onErrorCallback($localize`The statisticalReturn could not be created\: ${error}`),
    );
  }

  /**
   * Checks, if the given symbol is newer then the given max age.
   * @param symbolPublic symbol or symbolPublic to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isSymbolUpToDate(symbol: Symbol, maxAgeInSec: any): boolean {
    if (!symbol.cacheDate)
      return false;
    const cacheTime = symbol.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }


  /**
   * Creates a map of prices by date only containing prices for dates newer than the date of the given filterDatePrice. If no filterDatePrice is given, the whole pricesByDate map is returned unfiltered.
   * @param pricesByDate whole map to be filtered
   * @param filterDatePrice latest daily price, which determines the filter date. Can be null or undefined. If so, no filtering takes place
   * @return filtered map (or original map, if no filter is given)
   */
  private filterByDate(pricesByDate: Map<string, DailyPrice>, filterDatePrice: DailyPrice | undefined): Map<string, DailyPrice> {
    if (!filterDatePrice?.date)
      return pricesByDate;

    const filteredMap = new Map<string, DailyPrice>();

    [...pricesByDate?.entries()].filter(entry => entry[0] && this.utilService.parseDateDefault(entry[0]).getTime() > filterDatePrice.date!.toDate().getTime())
        .forEach(entry => filteredMap.set(entry[0], entry[1]));

    return filteredMap;
  }

  // Schedules

  async fetchSchedules(limit: number = environment.defaultLoadSchedulesCount, startAfter?: DocumentSnapshot): Promise<Wrapper<Schedule[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionSchedules)
          .limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const scheduleQuerySnapshot = await query.get();
      const schedules: Schedule[] = [];
      scheduleQuerySnapshot.forEach(scheduleDocSnapshot => {
        const schedule: Schedule = convertToSchedule(scheduleDocSnapshot.data());
        if (schedule) {
          const scheduleWithId = {...schedule, uid: scheduleDocSnapshot.id};
          schedules.push(scheduleWithId);
        }
      });

      this.addSchedulesToCache(schedules);
      const lastVisible = scheduleQuerySnapshot.docs[scheduleQuerySnapshot.docs.length - 1];

      return new Wrapper<Schedule[]>(schedules, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Schedule[]>(undefined, $localize`You are not allowed to view schedules.`);
      return new Wrapper<Schedule[]>(undefined, e.message);
    }
  }

  deleteSchedule(scheduleUid: string): Promise<void> {
    return firestore.collection(environment.firestoreCollectionSchedules).doc(scheduleUid).delete();
  }

  deleteAllSchedules(): Promise<void> {
    return this.fetchSchedules().then(wrapper => {
      return new Promise<void>((resolve, reject) => {
        if (wrapper.errorMessage) {
          reject(wrapper.errorMessage);
        }
        const schedules = wrapper.data;
        if (schedules) {
          if (schedules.length === 0)
            resolve();

          resolve(this.deleteAllSchedulesRecursively(schedules, 0));

        }
      });
    });
  }

  /**
   * Sends the given schedule to the firestore. If it has an ID, an existing schedule is updated, otherwise a new one is added.
   * @param scheduleId ID of the schedule to be sent
   * @param schedule schedule to be sent. Need to be provided, even if a fullSchedule is given
   * @param fullSchedule schedule to be written to the cache. This schedule is not sent to the firestore
   * @param merge if true, only the fields given in the schedule object will be updated. Otherwise, the whole schedule object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateSchedule(scheduleId: string, schedule: Schedule, fullSchedule: Schedule | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionSchedules).doc(scheduleId).set(schedule, {merge}).then(
        () =>
            onSuccessCallback(),
        (error) =>
            onErrorCallback($localize`The schedule could not be updated\: ${error}`),
    );
    if (merge && !fullSchedule) {
      console.error('updateSchedule called with a merge job without providing a fullSchedule.');
      return;
    }
    this.addScheduleToCache(merge && fullSchedule ? fullSchedule : schedule);
  }

  /**
   * Creates a new schedule in the backend database.
   */
  insertSchedule(schedule: Schedule, onSuccessCallback: (schedule: Schedule) => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionSchedules).add(schedule).then(docRef => {
          schedule.uid = docRef.id;
          onSuccessCallback(schedule);
          this.addScheduleToCache(schedule);
        },
        (error) => onErrorCallback($localize`The schedule could not be created\: ${error}`),
    );
  }

  /**
   * Fetches the schedule with the given scheduleId from the firestore database.
   */
  async fetchSchedule(uid: string, maxAgeInSec: number = environment.defaultScheduleCacheAgeInSec): Promise<Wrapper<Schedule>> {

    return this.scheduleMutex.runExclusive(async () => {
      const scheduleFromCache = this.schedulesById.get(uid);
      if (scheduleFromCache !== undefined && this.isScheduleUpToDate(scheduleFromCache, maxAgeInSec))
        return new Wrapper<Schedule>(scheduleFromCache);

      try {
        const scheduleDocSnapshot = await firestore.collection(environment.firestoreCollectionSchedules).doc(uid).withConverter(scheduleConverter).get();
        const schedule = scheduleDocSnapshot.data();
        if (schedule) {
          const scheduleWithIdAndCacheDate: Schedule = {...schedule, uid: scheduleDocSnapshot.id, cacheDate: new Date()};
          this.addScheduleToCache(scheduleWithIdAndCacheDate);
          return new Wrapper<Schedule>(scheduleWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<Schedule>(undefined, e.message);
      }

      // If no schedule was found
      return new Wrapper<Schedule>(undefined);
    });
  }

  resetScheduleCache() {
    this.schedulesById.clear();
  }

  /**
   * Checks, if the given schedule is newer then the given max age.
   * @param schedule schedule  to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isScheduleUpToDate(schedule: Schedule, maxAgeInSec: any): boolean {
    if (!schedule.cacheDate)
      return false;
    const cacheTime = schedule.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addScheduleToCache(schedule: Schedule) {
    if (schedule.uid)
      this.schedulesById.set(schedule.uid, schedule);
  }

  private addSchedulesToCache(schedules: Schedule[]) {
    this.schedulesById.clear();
    schedules.forEach(schedule => this.addScheduleToCache(schedule));
  }


  /**
   * Deletes all the given schedules starting with the given index. Does it by calling the function over and over again until they're all gone.
   * @param schedules list of schedules
   * @param index starting index for the schedules array
   */
  private deleteAllSchedulesRecursively(schedules: Schedule[], index: number): Promise<void> {
    const scheduleUid = schedules[index].uid;
    if (!scheduleUid)
      throw new Error(`schedule with index ${index} doesn't have a uid.`);
    return this.deleteSchedule(scheduleUid).then(() => {
          console.log(`Successfully deleted schedule with UID ${scheduleUid}.`);
          if (index < schedules.length - 1)
            return this.deleteAllSchedulesRecursively(schedules, ++index);
          // We're done
          return new Promise<void>(resolve => resolve());
        },
        reason => {
          return new Promise<void>((resolve, reject) => reject(reason));
        });
  }

  async deleteOldStatisticalReturns(): Promise<Wrapper<StatRetDeletionResult>> {
    let httpParams = this.backendService.getApiSecretKeyParam();
    if (!httpParams)
      throw Error('Backend-Api-Secret-Key is missing.');
    try {
      const payloadResult: PayloadResultJo<StatRetDeletionResult> = await this.httpClient.post<PayloadResultJo<StatRetDeletionResult>>(environment.backend_api_path + environment.backend_api_request_delete_old_statistical_returns,
          null, {params: httpParams}).toPromise();
      if (!payloadResult.isSuccess)
        return new Wrapper<StatRetDeletionResult>(undefined, payloadResult.message);
      return new Wrapper<StatRetDeletionResult>(payloadResult.payload);
    } catch (e: any) {
      return new Wrapper<StatRetDeletionResult>(undefined, e.message);
    }
  }
}

// Firestore data converter
export const scheduleConverter = {
  toFirestore(schedule: Schedule): Schedule {
    return schedule;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Schedule {
    return convertToSchedule(snapshot.data(options));
  },
};

