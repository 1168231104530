import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {AuthModule} from '../auth/auth.module';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {NotificationsButtonComponent} from './header/notifications-button/notifications-button.component';
import {ImprintComponent} from './imprint/imprint.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {AccountSetupWarningComponent} from './header/account-setup-warning/account-setup-warning.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {StartComponent} from './start/start.component';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {ResponsibleContactDetailsComponent} from './imprint/responsible-contact-details/responsible-contact-details.component';
import {SupportComponent} from './support/support.component';
import {MatCardModule} from '@angular/material/card';
import {FaqsComponent} from './support/faqs/faqs.component';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {LangSelectorComponent} from './header/lang-selector/lang-selector.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {NotFoundComponent} from './not-found/not-found.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LayoutRoutingModule} from './layout-routing.module';


@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    NotificationsButtonComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    AccountSetupWarningComponent,
    StartComponent,
    ResponsibleContactDetailsComponent,
    SupportComponent,
    FaqsComponent,
    LangSelectorComponent,
    NotFoundComponent,
  ],
  imports: [
    CommonModule,
    AuthModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    NgbCarouselModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    LayoutRoutingModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotificationsButtonComponent,
    FaqsComponent,
    LangSelectorComponent,
  ],
})
export class LayoutModule {
}
