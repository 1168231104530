<ng-container *ngIf="date && utilService.getDate(date) as convertedDate; else dateUnknown">
  <ng-container *ngIf="mode==='compact-date-only'">
    <span [matTooltip]="convertedDate | moment:'LLLL'">{{convertedDate | moment:'L'}}</span>
  </ng-container>
  <ng-container *ngIf="mode==='full-date-and-time'">
    <span>{{convertedDate | moment:'LLLL'}}</span>
  </ng-container>
  <ng-container *ngIf="mode==='compact-date-and-time'">
    <span>{{convertedDate | moment:'LLL'}}</span>
  </ng-container>
  <ng-container *ngIf="mode==='relative-only'">
    <span [matTooltip]="convertedDate | moment:'LLLL'">{{utilService.createFromNowString(convertedDate)}}</span>
  </ng-container>
  <ng-container *ngIf="showRelativeDate && mode!=='relative-only'">
    <span class="small"> ({{utilService.createFromNowString(convertedDate)}})</span>
  </ng-container>
</ng-container>
<ng-template #dateUnknown>
  <span i18n>Unknown</span>
</ng-template>
