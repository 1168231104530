<h1 i18n class="paragraph-headline">Simulations</h1>

<p i18n>On this page you can find simulation results for statistical trading. Statistical trading simulations are a way to determine the potential profitability of statistical trading.
  The simulation period is always set for one calendar year, during which statistical returns data from StockForecast is used to buy and sell as many stock symbols as possible on predetermined dates.</p>

<h2 i18n class="paragraph-headline-2">Browse and search simulation results</h2>

<app-simulation-search-panel></app-simulation-search-panel>

<app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="(simulationResults$ | async) as simulationResults">
  <app-simulation-results-table [simulationResults]="simulationResults" (exchangesClicked)="onExchangesClicked($event)"></app-simulation-results-table>
  <p class="mx-0 my-3" i18n="message shown best statisticalReturns count">Showing {{simulationResults.length}} simulation results.</p>
</div>

<app-mat-icon-button *ngIf="thereIsMore$ | async" #showMoreButton [callback]="onClickShowMore.bind(this)" [params]="[]" color="primary" cssClass="mb-3"
                     i18n-label="show more" icon="expand_more" id="show-more-button"
                     label="Show more"></app-mat-icon-button>

<p i18n>The simulation can be configured with different parameters, such as the maximum holding duration, the minimum annual average profit of the statistical trade, the minimum number of years of statistical data,
  the maximum capital per trade, and the initial seed capital.</p>
<p i18n>The result of the simulation is the final capital and a transaction list that shows which stock symbols were bought and sold and how each of them performed.
  At the end of the simulation period, all stocks are sold, regardless of whether they resulted in a profit or a loss.</p>
<p i18n>The simulation uses average returns from StockForecast to select the stocks, but the actual prices of the year are used for the simulation, making losses possible for individual trades.
  Despite losses, each trade is always sold on the predetermined sell date.</p>
