import {Component, Input, OnInit} from '@angular/core';
import {TableColumn} from '../../../shared/models/columnSettings.interface';

@Component({
  selector: 'app-collective-table-header',
  templateUrl: './collective-table-header.component.html',
  styleUrls: ['./collective-table-header.component.scss'],
})
export class CollectiveTableHeaderComponent implements OnInit {

  @Input() column?: TableColumn;
  @Input() years: string[] = [];
  @Input() periods: string[] = [];

  @Input() showDevelopmentSinceBuyDay = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  getReverseYears(): string[] {
    return [...this.years].reverse();
  }

  getReversePeriods(): string[] {
    return [...this.periods].reverse();
  }

  getXToYYears(x: number, y: number, reversed: boolean = false) {
    let yearsSplice = [...this.years].reverse().slice(x, y + 1);
    if (reversed)
      return yearsSplice;
    return yearsSplice.reverse();
  }
}
