<h1 class="paragraph-headline" i18n="export">Trading prices</h1>
<table class="table table-sm table-striped" *ngIf="statRet && year">
  <tbody>
  <tr i18n-ngbTooltip ngbTooltip="The buy date of the symbol">
    <th i18n>Buy date</th>
    <td>
      <ng-container *ngIf="statRet.buyDateByYearMap !== undefined && statRet.buyDateByYearMap[year] !== undefined; else none">
        {{ utilService.getDate(statRet.buyDateByYearMap[year]) | moment:'LL'}}
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="The sell date of the symbol">
    <th i18n>Sell date</th>
    <td>
      <ng-container *ngIf="statRet.sellDateByYearMap !== undefined && statRet.sellDateByYearMap[year] !== undefined; else none">
        {{ utilService.getDate(statRet.sellDateByYearMap[year]) | moment:'LL'}}
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="The buying price of the symbol">
    <th i18n>Buy price</th>
    <td>
      <ng-container
              *ngIf="statRet.buyPriceYearMap; else none">
        <app-money [value]="statRet.buyPriceYearMap[year]" [currencyId]="statRet.currency"></app-money>
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="The selling price of the symbol">
    <th i18n>Sell price</th>
    <td>
      <ng-container
              *ngIf="statRet.sellPriceYearMap; else none">
        <app-money [value]="statRet.sellPriceYearMap[year]" [currencyId]="statRet.currency"></app-money>
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip
      ngbTooltip="The relative profit made when the symbol was bought on the buy date and sold on the sell date.">
    <th i18n>Relative profit</th>
    <td>
      <ng-container
              *ngIf="statRet.profitByYearMap; else none">
        <app-percentage-view [value]="statRet.profitByYearMap[year]"></app-percentage-view>
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="For the average profit calculation, the relative profit is truncated at +/- 10 %. We do this to filter out years
        with extreme price gains or losses.">
    <th i18n>Truncated relative profit</th>
    <td>
      <ng-container
              *ngIf="statRet.profitTruncatedByYearMap; else none">
        <app-percentage-view [value]="statRet.profitTruncatedByYearMap[year]"></app-percentage-view>
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="The payments received from the symbol's dividends when the symbol was bought on the buy date and sold on
        the sell date. Note: The payment dates may have been after the sell date.">
    <th i18n>Dividend payments</th>
    <td>
      <ng-container
              *ngIf="statRet.dividendPaymentsYearMap; else none">
        <app-money [value]="statRet.dividendPaymentsYearMap[year]" [currencyId]="statRet.currency"></app-money>
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="The max. rise within the buy and sell period relative to the buy price over all years">
    <th i18n>Max. Rise</th>
    <td>
      <ng-container
              *ngIf="statRet.maxRiseByYearMap; else none">
        <app-percentage-view [value]="statRet.maxRiseByYearMap[year]"></app-percentage-view>
      </ng-container>
    </td>
  </tr>
  <tr i18n-ngbTooltip ngbTooltip="The max. rise within the buy and sell period relative to the buy price over all years">
    <th i18n>Max. drop</th>
    <td>
      <ng-container
              *ngIf="statRet.maxDropByYearMap; else none">
        <app-percentage-view [value]="statRet.maxDropByYearMap[year]"></app-percentage-view>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>

<ng-template #none>
  -
</ng-template>
