import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {addFaqsToCache, addFaqToCache, clearFaqsCache, setFaqsLastFetch} from './layout.actions';
import {Faq} from '../../shared/models/faq.interface';


export interface LayoutState {
  announcementsLastFetch?: Date;
  faqs: Faq[];
  faqsLastFetch?: Date;
}

export const initialState: LayoutState = {
  faqs: [],
};

function logDebugMessages(actionName: string, state: LayoutState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<LayoutState, Action> = createReducer(
    initialState,

    on(addFaqToCache, (state, {faq}) => {
      const newFaqs: Faq[] = [];
      const nowUtc = new Date().getTime();
      for (const faqFromState of state.faqs) {
        // Skip the faq with the ID of the given faq, because we will add the given faq object later
        if (faq.uid === faqFromState.uid)
          continue;
        // Also skip, if the cached faq is too old (or has no cache date)
        if (!faqFromState.cacheDate || (nowUtc - faqFromState.cacheDate.getTime() > environment.defaultFaqCacheAgeInSec * 1000))
          continue;
        const faqCopy: Faq = {...faqFromState};
        newFaqs.push(faqCopy);
      }
      newFaqs.push(faq);
      const newState = {...state, faqs: newFaqs};
      logDebugMessages('addFaqToCache', state, newState);
      return newState;
    }),
    on(addFaqsToCache, (state, {faqs}) => {
      const faqsLastFetch = new Date();
      const newState = {...state, faqs, faqsLastFetch};
      logDebugMessages('addFaqsToCache', state, newState);
      return newState;
    }),
    on(setFaqsLastFetch, (state, {faqsLastFetch}) => {
      const newState = {...state, faqsLastFetch};
      logDebugMessages('setFaqsLastFetch', state, newState);
      return newState;
    }),
    on(clearFaqsCache, (state) => {
      const newState = {...state, faqs: [], faqsLastFetch: undefined};
      logDebugMessages('clearFaqsCache', state, newState);
      return newState;
    }),
);

export function layoutReducer(state: LayoutState | undefined, action: Action): LayoutState {
  return reducer(state, action);
}

