<div class="parent-component">
  <app-watch-list-star [statisticalSimulationUid]="simulationUid" [floatTopRight]="true"></app-watch-list-star>

  <ng-container *ngIf="alerts && alerts.length > 0">
    <div class="mb-3">
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
    </div>
  </ng-container>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <div *ngIf="!simulationResult">
    <h1 [ngClass]="user ? 'paragraph-headline leave-room-for-star' : 'paragraph-headline'" i18n>
      Statistical simulation result {{simulationUid}}
    </h1>
    <p i18n="unknown symbol paragraph">No data is available for this simulation ID.</p>

    <app-mat-icon-button [routerLink]="['/statistical-trading','simulations']" color="primary" cssClass="mb-3 mr-2" i18n-label icon="search"
                         label="Search simulation results"></app-mat-icon-button>

  </div>

  <div *ngIf="simulationResult as simRes">
    <h1 [ngClass]="user ? 'paragraph-headline leave-room-for-star' : 'paragraph-headline'" i18n>
      Statistical simulation result for the year {{simRes.year}}
    </h1>
    <p><a [routerLink]="['/statistical-trading','simulations']" i18n>Back to search</a></p>


    <div class="row my-3 quick-info-view">
      <!-- First column -->
      <div class="col vertical-cards-column">
        <!-- Year -->
        <div class="col single-card-holder" *ngIf="simRes.year">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n-matTooltip matTooltip="Calendar year of the simulation" i18n>Year</h5>
              <p class="card-text">{{simRes.year}}</p>
            </div>
          </div>
        </div>
        <!-- ROI, Seed and final capital -->
        <div class="col single-card-holder">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="Return on investment (ROI) in stock trading refers to the percentage of profit or loss made relative to the seed capital." i18n-matTooltip>Return on investment</h5>
              <div *ngIf="simRes.roi">
                <p class="card-text paragraph-headline">
                  <app-percentage-view [value]="simRes.roi"></app-percentage-view>
                </p>
              </div>
              <div class="mt-2" *ngIf="simRes.seedCapital">
                <h6 i18n matTooltip="The amount of capital at the beginning of the simulation" i18n-matTooltip>Seed Capital</h6>
                <p class="card-text">
                  <app-money [value]="simRes.seedCapital"></app-money>
                </p>
              </div>
              <div class="mt-2" *ngIf="simRes.finalCapital">
                <h6 i18n matTooltip="The amount of capital at the end of the simulation" i18n-matTooltip>Final Capital</h6>
                <p class="card-text">
                  <app-money [value]="simRes.finalCapital"></app-money>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Second column -->
      <div class="col vertical-cards-column">
        <!-- Exchanges -->
        <div class="col single-card-holder" *ngIf="simRes.exchangeShortNames">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" matTooltip="The stock exchanges on which the stock is traded." i18n-matTooltip i18n>Exchanges</h5>
              <p class="card-text">
                <app-exchanges-view [exchangeShortNames]="simRes.exchangeShortNames"></app-exchanges-view>
              </p>
            </div>
          </div>
        </div>
        <!-- Max. capital per trade -->
        <div class="col single-card-holder" *ngIf="simRes.maxCapitalPerTrade !== undefined">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="The maximum capital used for a single trade relative to the depot value" i18n-matTooltip>Max. capital per trade</h5>
              <p class="card-text">
                <app-percentage-view [value]="simRes.maxCapitalPerTrade"></app-percentage-view>
              </p>
            </div>
          </div>
        </div>
        <!-- Min. price per stock -->
        <div class="col single-card-holder">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="The minimum buying price per stock necessary to buy the stock symbol in the simulation" i18n-matTooltip>Min. price per stock</h5>
              <p class="card-text">
                <app-money [value]="simRes.minPricePerStock" *ngIf="simRes.minPricePerStock; else noLimit"></app-money>
              </p>
            </div>
          </div>
        </div>
        <!-- Min. avg. profit -->
        <div class="col single-card-holder" *ngIf="simRes.minAverageRelativeProfit !== undefined">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="The minimum average relative annual profit per trade necessary to buy a stock in the simulation." i18n-matTooltip>Min. average profit</h5>
              <p class="card-text">
                <app-percentage-view [value]="simRes.minAverageRelativeProfit"></app-percentage-view>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Third column -->
      <div class="col vertical-cards-column">
        <!-- Min. number of data years -->
        <div class="col single-card-holder" *ngIf="simRes.minDataYears !== undefined">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="The minimum number of historical data years necessary to buy a stock in the simulation" i18n-matTooltip>Min. data years</h5>
              <p class="card-text">{{simRes.minDataYears}}</p>
            </div>
          </div>
        </div>
        <!-- Max. duration -->
        <div class="col single-card-holder" *ngIf="simRes.maxHoldingDuration !== undefined">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="The maximum duration in days between buy and sell date." i18n-matTooltip>Max. holding duration</h5>
              <p class="card-text">{{simRes.maxHoldingDuration}}</p>
            </div>
          </div>
        </div>
        <!-- Creation Date -->
        <div class="col single-card-holder" *ngIf="simRes.creationDate !== undefined">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n matTooltip="The date, when this statistical return was calculated" i18n-matTooltip>Calculation date</h5>
              <p class="card-text">{{utilService.getDate(simRes.creationDate) | moment:'LL'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noLimit>
      <span i18n>No limit</span>
    </ng-template>

    <div *ngIf="simRes.transactionList as transactions">

      <h2 class="paragraph-headline-2" i18n>Transactions in this simulation</h2>

      <p i18n>The following {{transactions.length}} transactions were made in this simulation:</p>

      <table class="table table-striped table-sm table-responsive-lg table-sticky-header">
        <thead>
        <tr>
          <th><span i18n-matTooltip matTooltip="The buy date of the symbol" i18n>Buy date</span></th>
          <th><span i18n-matTooltip matTooltip="The sell date of the symbol" i18n>Sell date</span></th>
          <th><span i18n-matTooltip matTooltip="The duration between buy and sell date" i18n>Duration</span></th>
          <th><span i18n-matTooltip matTooltip="The stock symbol, which was bought and sold" i18n>Symbol</span></th>
          <th><span i18n-matTooltip matTooltip="The exchange, at which the symbol was bought" i18n>Exchange</span></th>
          <th><span i18n-matTooltip matTooltip="The price at which the symbol was bought" i18n>Buy price</span></th>
          <th><span i18n-matTooltip matTooltip="The price at which the symbol was sold" i18n>Sell price</span></th>
          <th><span i18n-matTooltip matTooltip="The absolute profit made with this trade" i18n>Profit</span></th>
          <th><span i18n-matTooltip [matTooltip]="roiTruncatedTooltip" i18n>ROI truncated </span></th>
          <th><span i18n-matTooltip matTooltip="The return on investment of this trade" i18n>ROI</span></th>
          <th><span i18n-matTooltip matTooltip="The value of the deposit on the buy date. Includes cash and all stock symbols held." i18n>Depot value</span></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let transaction of transactions">
          <td>
            <a [routerLink]="['/statistical-trading', 'symbols', transaction.symbolCode, transaction.statisticalReturnUid]">
              <app-date-view [date]="transaction.buyDate" mode="compact-date-only"></app-date-view>
            </a>
          </td>
          <td>
            <app-date-view [date]="transaction.sellDate" mode="compact-date-only"></app-date-view>
          </td>
          <td>{{getDuration(transaction)}}</td>
          <td>
            <a [routerLink]="['/statistical-trading','symbols',transaction.symbolCode]">
              <app-symbol-descriptor [symbolCode]="transaction.symbolCode" [nameOnly]="true"></app-symbol-descriptor>
            </a>
          </td>
          <td>
            <app-exchange-descriptor [exchangeShortName]="transaction.exchangeShortName"></app-exchange-descriptor>
          </td>
          <td>
            <app-money [value]="transaction.buyPrice"></app-money>
          </td>
          <td>
            <app-money [value]="transaction.sellPrice"></app-money>
          </td>
          <td *ngIf="transaction.sellPrice !== undefined && transaction.buyPrice !== undefined; else none">
            <app-money [value]="(transaction.sellPrice-transaction.buyPrice)"></app-money>
          </td>
          <td>
            <app-percentage-view [value]="transaction.roiTruncated"></app-percentage-view>
          </td>
          <td>
            <app-percentage-view [value]="transaction.roi"></app-percentage-view>
          </td>
          <td>
            <app-money [value]="transaction.depotValueBuyDay"></app-money>
          </td>
        </tr>
        </tbody>
      </table>

      <ng-template #none>
        -
      </ng-template>

    </div>

  </div>

  <ng-container *ngIf="(simulationSearchSimulationResults$ | async) as simulationResults">
    <h2 class="paragraph-headline-2" i18n>Other simulation results</h2>
    <app-simulation-results-table [simulationResults]="simulationResults"></app-simulation-results-table>
  </ng-container>

</div>
