<h1 class="paragraph-headline" i18n="No symbol selected">Stock Symbols</h1>

<p i18n>You can use the <a [routerLink]="['..', 'stock-search']">stock search</a> to search for stock symbols. Alternatively, you can browse the <a [routerLink]="['..', 'best-trades']">best trading opportunities for all time</a> or the <a [routerLink]="['..', 'upcoming-trades']">best upcoming trading opportunities</a>.</p>

<h2 class="paragraph-headline-2" i18n>Example symbol selection</h2>
<ul>
  <li><a [routerLink]="['..','symbols','BABA']">Alibaba</a></li>
  <li><a [routerLink]="['..','symbols','AAPL']">Apple</a></li>
  <li><a [routerLink]="['..','symbols','ATVI']">Activision Blizzard</a></li>
  <li><a [routerLink]="['..','symbols','AMZN']">Amazon</a></li>
  <li><a [routerLink]="['..','symbols','GOOGL']">Alphabet (formerly known as Google)</a></li>
  <li><a [routerLink]="['..','symbols','META']">Meta (formerly known as Facebook)</a></li>
  <li><a [routerLink]="['..','symbols','MSFT']">Microsoft Corporation</a></li>
  <li><a [routerLink]="['..','symbols','NVDA']">NVIDIA</a></li>
  <li><a [routerLink]="['..','symbols','005930.KS']">Samsung</a></li>
  <li><a [routerLink]="['..','symbols','TSLA']">Tesla</a></li>
</ul>

<ng-container *ngIf="cachedSymbols && cachedSymbols.length > 0">
  <h2 class="paragraph-headline-2" i18n>Previously seen symbols</h2>
  <ul>
    <li *ngFor="let symbol of cachedSymbols">
      <a [routerLink]="['..','symbols',symbol.code]">
        <app-symbol-descriptor [symbol]="symbol"></app-symbol-descriptor>
      </a>
    </li>
  </ul>
</ng-container>


