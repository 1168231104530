<td><a [routerLink]="['/statistical-trading', 'symbols', symbolCode]">
  <ng-container *ngIf="symbol">
    <!-- Name without shortening-->
    <span *ngIf="nameMaxLength == undefined || nameMaxLength >= symbol.name.length"
          matTooltip="Code: {{symbol.code}}&#13;Exchange: {{symbol.exchangeShortName}}" i18n-matTooltip>{{symbol.name}}</span>
    <!-- Name with shortening-->
    <span *ngIf="nameMaxLength && nameMaxLength < symbol.name.length"
          matTooltip="{{symbol.name}}&#13;Code: {{symbol.code}}&#13;Exchange: {{symbol.exchangeShortName}}" i18n-matTooltip>{{symbol.name | shorten:nameMaxLength:true}}</span>
  </ng-container>
  <!--Symbol code -->
  <span *ngIf="!symbol && symbolCode" matTooltip="Code: {{symbolCode}}" i18n-matTooltip>{{symbolCode}}</span>
</a></td>
<td><a [routerLink]="['/statistical-trading', 'symbols', symbolCode]">{{symbolCode}}</a></td>
<td *ngIf="showExchange">
  <ng-container *ngIf="symbol && symbol.exchangeShortName">
    <a (click)="onExchangeClick(symbol.exchangeShortName)" class="hover-pointer">
      <app-exchange-descriptor mode="codeOnly" [exchangeShortName]="symbol?.exchangeShortName"></app-exchange-descriptor>
    </a>
  </ng-container>
  <span *ngIf="!symbol?.exchangeShortName" i18n>Loading exchange...</span>
</td>

