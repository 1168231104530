import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {CurrencyService} from '../../services/currency.service';
import {Currency} from '../../models/currency.interface';
import Locale from '../../services/locale';
import {MatSelectChange} from '@angular/material/select';
import {ErrorStateMatcher} from '@angular/material/core';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CurrencySelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CurrencySelectorComponent,
    },
  ],
})
export class CurrencySelectorComponent implements OnInit, ControlValueAccessor, Validator {

  @ViewChild('currencyId') currencyIdRef?: AbstractControl;

  currencyControl?: FormControl;

  currencies: Currency[] = [];
  defaultCurrency?: Currency;
  /**
   * Map of currencies by ID. Initialized with all currencies available in the database
   */
  currenciesByIdCache = new Map<string, Currency>();
  @Input() selectedCurrencyId?: string;

  /**
   * If true, the default currency of the locale will be preselected.
   */
  @Input() preselectDefaultCurrency = false;

  onChange = (selectedCurrencyId?: string) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  constructor(
      public currencyService: CurrencyService) {
  }

  ngOnInit(): void {
    this.currencyControl = new FormControl();

    this.currencyService.getCurrencies().then(wrapper => {
      if (wrapper.data) {
        this.currencies = wrapper.data;

        if (this.preselectDefaultCurrency) {
          this.currencies.forEach(currency => this.currenciesByIdCache.set(currency.id, currency));
          this.defaultCurrency = this.currenciesByIdCache.get(Locale.defaultCurrencyId());

          if (!this.selectedCurrencyId)
            this.writeValue(this.defaultCurrency?.id);
        }

      }
    });
  }

  writeValue(selectedCurrencyId?: string) {
    this.selectedCurrencyId = selectedCurrencyId;
    this.currencyControl?.setValue(selectedCurrencyId);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
    if (this.currencyControl) {
      this.currencyControl.valueChanges.subscribe(() => {
        this.onChange(this.currencyControl?.value);
        this.markAsTouched();
      });
    }
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  currencyChanged(change: MatSelectChange) {
    this.onChange(change.value);
    this.selectedCurrencyId = change.value;
    this.markAsTouched();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const selectedCurrencyId = control.value;
    if (!selectedCurrencyId || selectedCurrencyId === '') {
      return {required: true};
    }
    return null;
  }

  readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (ctrl: FormControl) => (ctrl && ctrl.invalid),
  };

}
