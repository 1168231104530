<app-breadcrumb i18n-currentPageTitle currentPageTitle="My plans"
                [pages]="breadcrumbPages"></app-breadcrumb>

<h1 i18n class="paragraph-headline">My plans</h1>

<app-not-logged-in *ngIf="!firebaseUser" i18n-message="not-logged-in message account management"
                   message="You need to login, before viewing your trading plans."></app-not-logged-in>
<div *ngIf="firebaseUser">

  <app-mat-icon-button *ngIf="user" [routerLink]="['create']" color="primary" cssClass="mb-3" i18n-label icon="add"
                       label="Create new plan"></app-mat-icon-button>

  <app-statistical-trading-plans-list></app-statistical-trading-plans-list>
</div>
