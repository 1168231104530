import {createAction, props} from '@ngrx/store';
import {StatisticalSimulationSearchParams, StatisticalSimulationSortOrder} from '../shared/models/statisticalSimulationSearchParams.interface';
import {StatisticalSimulationSearchResult} from '../shared/models/statisticalSimulationSearchResult.interface';
import {StatisticalSimulationResult} from '../shared/models/statisticalSimulationResult.interface';

// Caches

export const addSimulationResultToCache = createAction('[Stock] Adding simulation result to cache', props<{ simulationResult: StatisticalSimulationResult }>());
export const clearSimulationResultCache = createAction('[Stock] Clearing simulation result cache');


// Simulations search

export const updateSimulationSearchYear = createAction('[Stock] Updating simulation search year', props<{ year?: number }>());
export const updateSimulationSearchMinRoi = createAction('[Stock] Updating simulation search minRoi', props<{ minRoi?: number }>());
export const updateSimulationSearchMinAverageRelativeProfitMin = createAction('[Stock] Updating simulation search minAverageRelativeProfitMin', props<{
  minAverageRelativeProfitMin?: number
}>());
export const updateSimulationSearchMinAverageRelativeProfitMax = createAction('[Stock] Updating simulation search minAverageRelativeProfitMax', props<{
  minAverageRelativeProfitMax?: number
}>());
export const updateSimulationSearchMaxHoldingDurationMin = createAction('[Stock] Updating simulation search maxHoldingDurationMin', props<{
  maxHoldingDurationMin?: number
}>());
export const updateSimulationSearchMaxHoldingDurationMax = createAction('[Stock] Updating simulation search maxHoldingDurationMax', props<{
  maxHoldingDurationMax?: number
}>());
export const updateSimulationSearchMinDataYears = createAction('[Stock] Updating simulation search minDataYears', props<{
  minDataYears?: number
}>());
export const updateSimulationSearchMaxCapitalPerTradeMin = createAction('[Stock] Updating simulation search maxCapitalPerTradeMin', props<{
  maxCapitalPerTradeMin?: number
}>());
export const updateSimulationSearchMaxCapitalPerTradeMax = createAction('[Stock] Updating simulation search maxCapitalPerTradeMax', props<{
  maxCapitalPerTradeMax?: number
}>());
export const updateSimulationSearchSeedCapitalMin = createAction('[Stock] Updating simulation search SeedCapitalMin', props<{
  seedCapitalMin?: number
}>());
export const updateSimulationSearchSeedCapitalMax = createAction('[Stock] Updating simulation search SeedCapitalMax', props<{
  seedCapitalMax?: number
}>());
export const addSimulationSearchExchangeShortName = createAction('[Stock] Adding simulation search exchangeShortName', props<{
  exchangeShortName: string
}>());
export const removeSimulationSearchExchangeShortName = createAction('[Stock] Removing simulation search exchangeShortName', props<{
  exchangeShortName: string
}>());
export const setSimulationSearchExchangeShortNames = createAction('[Stock] Setting simulation search exchangeShortNames', props<{
  exchangeShortNames?: string[]
}>());
export const setSimulationSearchSortOrder = createAction('[Stock] Updating simulation search sort order', props<{
  sortOrder?: StatisticalSimulationSortOrder
}>());
export const resetSimulationSearch = createAction('[Stock] Resetting simulation search results');
export const setSimulationSearchParams = createAction('[Stock] Setting simulation search params', props<{ searchParams: StatisticalSimulationSearchParams }>());
export const fetchSimulationSearchResults = createAction('[Stock] Fetching simulation search results');
export const fetchMoreSimulationSearchResults = createAction('[Stock] Fetching more simulation search results');
export const setSimulationSearchResults = createAction('[Stock] Setting simulation search results', props<{
  searchResult: StatisticalSimulationSearchResult
}>());
export const simulationSearchFailed = createAction('[Stock] Simulation search failed', props<{ errorMessage: string, code?: number }>());







