<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<h1 class="paragraph-headline" i18n>Stock search</h1>
<p i18n>On this page you can search for stock symbols. Click on any symbol in the results table to see its best statistical trading opportunities.</p>

<app-stock-search-panel [navigateToSearch]="true"></app-stock-search-panel>


<div class="my-3" *ngIf="alerts">
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
</div>

<div *ngIf="!(searchParams$ | async)?.searchTerm && !(symbols$ | async)?.length">
  <p i18n>Please enter a search term to start searching.</p>
</div>

<div *ngIf="(searchParams$ | async)?.searchTerm && !(symbols$ | async)?.length && !showLoadingSpinner">
  <div class="alert alert-info" i18n="no symbol serach results found">No results were found for your search criteria.</div>
</div>

<app-symbol-search-table [routerLinkPath]="['..','symbols']"></app-symbol-search-table>


