import {Component, Input, OnInit} from '@angular/core';
import {StatisticalTrade} from '../../../../shared/models/statisticalTrade.interface';
import {PlannerService} from '../../planner.service';

@Component({
  selector: 'app-plan-profit',
  templateUrl: './plan-profit.component.html',
  styleUrls: ['./plan-profit.component.scss'],
})
export class PlanProfitComponent implements OnInit {

  @Input() trades?: StatisticalTrade[];
  @Input() mode?: 'absolute' | 'relative';
  @Input() phase?: 'current' | 'end';
  @Input() seedCapital?: number;
  @Input() currencyId?: string;
  value?: number;

  constructor(private plannerService: PlannerService) {
  }

  ngOnInit(): void {
    this.calculateValue();
  }

  private calculateValue() {
    this.trades = this.trades ? this.trades : [];
    let result = 0;
    const now = new Date();
    for (let trade of this.trades) {
      if (this.phase === 'current') {
        let profitAbsoluteNet = this.getRelevantAbsoluteNetProfitUntilDate(trade, now);
        result += profitAbsoluteNet;
      }

      if (this.phase === 'end') {
        let profitAbsoluteNet = this.getRelevantAbsoluteNetProfit(trade);
        result += profitAbsoluteNet;
      }
    }
    switch (this.mode) {
      case 'absolute':
        this.value = result;
        return;
      case 'relative':
        if (this.seedCapital)
          this.value = result / this.seedCapital;
        else
          this.value = 0.0;
    }
  }

  private getRelevantAbsoluteNetProfit(trade: StatisticalTrade): number {
    if (trade.actual?.profitAbsoluteNet)
      return trade.actual.profitAbsoluteNet;
    else
      return trade.target.profitAbsoluteNet!;
  }

  private getRelevantAbsoluteNetProfitUntilDate(trade: StatisticalTrade, now: Date) {
    const {buyDate, sellDate} = this.plannerService.getBuyDateAndSellDate(trade);

    // If trade is completely in the past
    if (now.getTime() >= buyDate.getTime() && now.getTime() >= sellDate.getTime()) {
      return this.getRelevantAbsoluteNetProfit(trade);
    }
    // If trade is completely in the future
    if (now.getTime() < buyDate.getTime() && now.getTime() < sellDate.getTime()) {
      return 0;
    }
    // Trade starts in the past and ends in the future
    const profitRelativeNet = trade.target.profitAbsoluteNet! / (trade.actual?.buyPrice ? trade.actual.buyPrice : trade.target.buyPrice!);
    const progressedMillis = now.getTime() - buyDate.getTime();
    const durationMillis = sellDate.getTime() - buyDate.getTime();
    const progressedPercentage = progressedMillis / durationMillis;
    return progressedPercentage * profitRelativeNet;
  }

}
