import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mat-fab-search-button',
  templateUrl: './mat-fab-search-button.component.html',
  styleUrls: ['./mat-fab-search-button.component.scss'],
})
export class MatFabSearchButtonComponent implements OnInit {

  @Output() click = new EventEmitter<any>();
  @Input() type = 'button';
  @Input() color = 'primary';

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick() {
    this.click.emit();
  }
}
