import {AppState} from './app.state';
import {createSelector} from '@ngrx/store';

export const selectSimulation = (state: AppState) => state.simulation;


// Cached simulation results

export const selectCachedStatisticalSimulationResults = createSelector(
    selectSimulation,
    (simulationState) => simulationState.simulationResults,
);
// Symbol search

export const selectSimulationSearch = createSelector(
    selectSimulation,
    (simulationState) => simulationState.simulationSearch,
);
export const selectSimulationSearchSearching = createSelector(
    selectSimulationSearch,
    (symbolSearch) => symbolSearch.searching,
);
export const selectSimulationSearchParams = createSelector(
    selectSimulationSearch,
    (symbolSearch) => symbolSearch.searchParams,
);
export const selectSimulationSearchYear = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.year,
);
export const selectSimulationSearchMinRoi = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.minRoi,
);
export const selectSimulationSearchMaxCapitalPerTradeMin = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.maxCapitalPerTradeMin,
);
export const selectSimulationSearchMaxCapitalPerTradeMax = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.maxCapitalPerTradeMax,
);
export const selectSimulationSearchSeedCapitalMin = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.seedCapitalMin,
);
export const selectSimulationSearchSeedCapitalMax = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.seedCapitalMax,
);
export const selectSimulationSearchMaxHoldingDurationMin = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.maxHoldingDurationMin,
);
export const selectSimulationSearchMaxHoldingDurationMax = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.maxHoldingDurationMax,
);
export const selectSimulationSearchMinAverageRelativeProfitMin = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.minAverageRelativeProfitMin,
);
export const selectSimulationSearchMinAverageRelativeProfitMax = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.minAverageRelativeProfitMax,
);
export const selectSimulationSearchExchangeShortNames = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.exchangeShortNames,
);
export const selectSimulationSearchSortOrder = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.sortOrder,
);
export const selectSimulationSearchMinDataYears = createSelector(
    selectSimulationSearchParams,
    (symbolSearchParams) => symbolSearchParams.minDataYears,
);
export const selectSimulationSearchResult = createSelector(
    selectSimulationSearch,
    (symbolSearch) => symbolSearch.searchResult,
);
export const selectSimulationSearchSimulationResults = createSelector(
    selectSimulationSearchResult,
    (symbolsSearchResult) => symbolsSearchResult.simulationResults,
);
export const selectSimulationSearchErrorMessage = createSelector(
    selectSimulationSearchResult,
    (symbolsSearchResult) => symbolsSearchResult.errorMessage,
);
export const selectSimulationSearchThereIsMore = createSelector(
    selectSimulationSearchResult,
    (symbolsSearchResult) => symbolsSearchResult.thereIsMore,
);
