import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistical-trading-header',
  templateUrl: './statistical-trading-header.component.html',
  styleUrls: ['./statistical-trading-header.component.scss']
})
export class StatisticalTradingHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
