import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {selectCachedSymbols} from '../../../store/stock.selectors';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Symbol} from '../../../shared/models/symbol.interface';
import {TitleService} from '../../../shared/services/title.service';

@Component({
  selector: 'app-no-symbol-selected',
  templateUrl: './no-symbol-selected.component.html',
  styleUrls: ['./no-symbol-selected.component.scss'],
})
export class NoSymbolSelectedComponent extends BaseComponent implements OnInit {

  cachedSymbols$ = this.store.select(selectCachedSymbols);
  cachedSymbols: Symbol[] = [];

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Stock Symbols`);
    this.cachedSymbols$.pipe(takeUntil(this.destroy$)).subscribe(symbols => {
      this.cachedSymbols = [...symbols];
      this.cachedSymbols.sort((a: Symbol, b: Symbol) => {
        if (!a.cacheDate && !b.cacheDate)
          return 0;
        if (!a.cacheDate)
          return 1;
        if (!b.cacheDate)
          return -1;
        return b.cacheDate!.getTime() - a.cacheDate!.getTime();
      });
      let maxCount = 20;
      if (this.cachedSymbols.length > maxCount)
        this.cachedSymbols = this.cachedSymbols.slice(0, maxCount);
    });
  }

}
