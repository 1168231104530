<ng-container *ngIf="!bigMoney">
  <ng-container *ngIf="currency && currency.symbol">
    {{value | currency:currency?.symbol:'symbol': undefined: locale}}
  </ng-container>
  <ng-container *ngIf="currencyId && (!currency || !currency.symbol)">
    {{value | currency:currencyId:'code': undefined: locale}}
  </ng-container>
  <ng-container *ngIf="!currencyId && (!currency || !currency.symbol)">
    {{value | number:'1.2-2':locale}}
  </ng-container>
</ng-container>

<ng-container *ngIf="bigMoney">
  <span *ngIf="currency && currency.symbol" matTooltip="{{value | currency:currency?.symbol:'symbol': undefined: locale}}">
    {{valueBigMoney | currency:bigMoneyFormat + currency?.symbol:'symbol': '1.0-0': locale}}
  </span>
  <span *ngIf="currencyId && (!currency || !currency.symbol)" matTooltip="{{value | currency:currencyId:'code': undefined: locale}}">
    {{valueBigMoney | currency:bigMoneyFormat + currencyId:'code': '1.0-0': locale}}
  </span>
  <span *ngIf="!currencyId && (!currency || !currency.symbol)" matTooltip="{{value | number:'1.2-2':locale}}">
    {{valueBigMoney | number:'1.0-0':locale}}{{bigMoneyFormat}}
  </span>
</ng-container>
