<h2 class="paragraph-headline-2" mat-dialog-title *ngIf="dialogTitle">{{ dialogTitle }}</h2>

<ng-container *ngIf="templateRef">
  <ng-container *ngTemplateOutlet='templateRef'></ng-container>
</ng-container>

<ng-container *ngIf="showCloseButton">
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <button color="primary" i18n="Close" mat-raised-button (click)="close()">Close</button>
  </mat-dialog-actions>
</ng-container>
