import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {
  selectBestStatisticalReturnSearchAllFilteredOut,
  selectUpcomingStatisticalReturnBuyDayNumber,
  selectUpcomingStatisticalReturnSearchErrorMessage,
  selectUpcomingStatisticalReturnSearchResult,
  selectUpcomingStatisticalReturnSearchResultList,
  selectUpcomingStatisticalReturnSearchSearching,
  selectUpcomingStatisticalReturnSearchThereIsMore,
} from '../../store/stock.selectors';
import Locale from '../../shared/services/locale';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {StatisticalTradingService} from '../statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {take, takeUntil} from 'rxjs/operators';
import Util from '../../shared/util';
import {environment} from '../../../environments/environment';
import {StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {TitleService} from '../../shared/services/title.service';
import {combineLatest} from 'rxjs';
import {resetUpcomingStatisticalReturnsSearch} from '../../store/stock.actions';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-upcoming-trades',
  templateUrl: './upcoming-trades.component.html',
  styleUrls: ['./upcoming-trades.component.scss'],
})
export class UpcomingTradesComponent extends BaseComponent implements OnInit {
  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  statisticalReturns$ = this.store.select(selectUpcomingStatisticalReturnSearchResultList);
  buyDayNumber$ = this.store.select(selectUpcomingStatisticalReturnBuyDayNumber);
  numberFormatLocale = Locale.numberFormatLocale();
  searchErrorMessage$ = this.store.select(selectUpcomingStatisticalReturnSearchErrorMessage);
  thereIsMore$ = this.store.select(selectUpcomingStatisticalReturnSearchThereIsMore);
  allFilteredOut$ = this.store.select(selectBestStatisticalReturnSearchAllFilteredOut);
  searching$ = this.store.select(selectUpcomingStatisticalReturnSearchSearching);
  searchResult$ = this.store.select(selectUpcomingStatisticalReturnSearchResult);
  private readonly spinnerKeySearchingStatisticalReturns = 'searchingStatisticalReturns';


  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private formBuilder: FormBuilder,
      private statisticalTradingService: StatisticalTradingService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Upcoming statistical trades`);

    this.searching$.pipe(takeUntil(this.destroy$)).subscribe(searching => {
      if (searching)
        this.addLoadingSpinnerMessage(this.spinnerKeySearchingStatisticalReturns, $localize`Searching upcoming statistical returns...`);
      else
        this.removeLoadingSpinnerMessage(this.spinnerKeySearchingStatisticalReturns);
    });
    this.searchErrorMessage$.pipe(takeUntil(this.destroy$)).subscribe(errorMessage => {
          this.clearAlerts();
          if (errorMessage)
            this.addError(errorMessage);
        },
    );

    combineLatest([
      this.statisticalReturns$.pipe(take(1)),
      this.buyDayNumber$.pipe(take(1)),
    ]).subscribe(([statisticalReturns, buyDayNumber]) => {
      if (buyDayNumber === undefined)
        this.store.dispatch(resetUpcomingStatisticalReturnsSearch());
      statisticalReturns = this.filterOutdated(statisticalReturns);
      if (statisticalReturns?.length === 0)
        this.statisticalTradingService.fetchUpcomingStatisticalReturns();
    });

    this.searchResult$.pipe(takeUntil(this.destroy$)).subscribe(searchResult => {
      if (searchResult.allFilteredOut && searchResult.thereIsMore)
        this.statisticalTradingService.fetchMoreUpcomingStatisticalReturns();
    });
  }


  filterOutdated(statisticalReturns: StatisticalReturn[]): StatisticalReturn[] {
    const now = new Date();
    return statisticalReturns.filter(it => it.cacheDate && (now.getTime() - it.cacheDate.getTime() < environment.defaultStatisticalReturnCacheAgeInSec));
  }

  /**
   * Called, when the Show more button is clicked. Loads more trades.
   */
  onClickShowMore(): void {
    this.statisticalTradingService.fetchMoreUpcomingStatisticalReturns();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

  onExchangesSelectionChanged(selectedExchanges: string[]) {
    this.statisticalTradingService.setUpcomingStatisticalReturnsExchangesFilter(selectedExchanges);
    this.statisticalTradingService.fetchUpcomingStatisticalReturns();
  }


}
