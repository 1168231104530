import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-exchanges-view',
  templateUrl: './exchanges-view.component.html',
  styleUrls: ['./exchanges-view.component.scss'],
})
export class ExchangesViewComponent implements OnInit {

  constructor() {
  }

  _exchangeShortNames?: string[] | null = [];

  get exchangeShortNames(): string[] | undefined | null {
    return this._exchangeShortNames;
  }

  @Input() set exchangeShortNames(exchangeShortNames: string[] | undefined | null) {
    this._exchangeShortNames = exchangeShortNames;
  }

  ngOnInit(): void {
  }

}
