import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {DEFAULT_DATE_FORMAT} from '../constants/strings';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {

  constructor(
    private metaTagService: Meta,
    private router: Router) {
  }

  /**
   * Updates the given tags, if they are in fact given. If an argument is undefined/null, it's skipped. See https://ogp.me.
   * @param pageTitle The title of your object as it should appear within the graph, e.g., "The Rock".
   * @param description
   * @param keywords
   * @param imageUrls An array of image URL which should represent your object within the graph.
   * @param url The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "https://www.imdb.com/title/tt0117500/".
   * @param autoUrl If true (or omitted), the current URL will be determined automatically using the current route, but only, if no url is given
   * @param moment The date of the item, article, website etc.
   */
  updateTags(pageTitle?: string, description?: string, keywords?: string, imageUrls?: string[], url?: string, autoUrl = true, type?: string, moment?: moment.Moment): void {
    if (pageTitle) {
      this.metaTagService.updateTag({property: 'og:title', content: pageTitle});
      this.metaTagService.updateTag({name: 'twitter:title', content: pageTitle});
    } else
      this.setDefaultPageTitle();

    if (description) {
      this.metaTagService.updateTag({name: 'description', content: description});
      this.metaTagService.updateTag({property: 'og:description', content: description});
      this.metaTagService.updateTag({name: 'twitter:description', content: description});
    } else
      this.setDefaultDescription();

    if (keywords)
      this.metaTagService.updateTag({name: 'keywords', content: keywords});
    else
      this.setDefaultKeywords();

    if (imageUrls) {
      for (let i = 0; i < imageUrls.length; i++) {
        let imageUrl = imageUrls[i];
        // The first image is updated, all remaining images are added
        if (i == 0) {
          this.metaTagService.updateTag({property: 'og:image', content: imageUrl});
          this.metaTagService.updateTag({name: 'twitter:image', content: imageUrl});
        } else {
          this.metaTagService.addTag({property: 'og:image', content: imageUrl});
          this.metaTagService.addTag({name: 'twitter:image', content: imageUrl});
        }
      }
    } else
      this.setDefaultImage();

    if (url)
      this.metaTagService.updateTag({property: 'og:url', content: url});
    if (!url && autoUrl)
      this.metaTagService.updateTag({property: 'og:url', content: environment.applicationRootUrl + this.router.url});
    if (!url && !autoUrl)
      this.setDefaultUrl();

    if (type)
      this.metaTagService.updateTag({property: 'og:type', content: type});
    else
      this.setDefaultType();

    if (moment) {
      this.metaTagService.updateTag({name: 'date', content: moment.format(DEFAULT_DATE_FORMAT), scheme: 'YYYY-MM-DD'});
      this.metaTagService.updateTag({name: 'article:published_time', content: moment.format(DEFAULT_DATE_FORMAT), scheme: 'YYYY-MM-DD'});
    }
  }

  setDefaultTags() {
    this.setDefaultDescription();
    this.setDefaultKeywords();
    this.setDefaultPageTitle();
    this.setDefaultUrl();
    this.setDefaultImage();
    this.setDefaultType();
    this.metaTagService.updateTag({property: 'og:site_name', content: environment.defaultTitle});
    this.metaTagService.updateTag({property: 'og:locale', content: 'de_DE'});
    this.metaTagService.updateTag({property: 'fb:app_id', content: '419177759140058'});
    this.metaTagService.updateTag({name: 'robots', content: 'index, follow'});
    this.metaTagService.updateTag({name: 'author', content: 'Alexander Schwartz'});
    this.metaTagService.updateTag({name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no'});
    this.metaTagService.updateTag({charset: 'UTF-8'});
  }

  private setDefaultType() {
    this.metaTagService.updateTag({property: 'og:type', content: 'website'});
  }

  private setDefaultPageTitle() {
    this.metaTagService.updateTag({property: 'og:title', content: environment.defaultTitle});
    this.metaTagService.updateTag({name: 'twitter:title', content: environment.defaultTitle});
  }

  private setDefaultKeywords() {
    this.metaTagService.updateTag({
      name: 'keywords',
      content: $localize`Stock Forecast, sharing community, private rentals, borrow almost everything, rent out all the things`,
    });
  }

  private setDefaultDescription() {
    this.metaTagService.updateTag({
      name: 'description',
      content: $localize`Borrow all the things you ever wanted. Rent out, what you have, but rarely use. Stock Forecast is a sharing marketplace, on which anybody can offer items for rent.`,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: $localize`Borrow all the things you ever wanted. Rent out, what you have, but rarely use. Stock Forecast is a sharing marketplace, on which anybody can offer items for rent.`,
    });
    this.metaTagService.updateTag({
      name: 'twitter:description',
      content: $localize`Borrow all the things you ever wanted. Rent out, what you have, but rarely use. Stock Forecast is a sharing marketplace, on which anybody can offer items for rent.`,
    });
  }

  private setDefaultUrl() {
    this.metaTagService.updateTag({property: 'og:url', content: environment.applicationRootUrl});
  }

  private setDefaultImage() {
    for (let i = 0; i < environment.maxImagesCount; i++) {
      this.metaTagService.removeTag('name=\'og:image\'');
      this.metaTagService.removeTag('name=\'twitter:image\'');
    }
    this.metaTagService.updateTag({property: 'og:image', content: environment.publicImgPath + '/logo-canoe-square-w500.jpg'});
    this.metaTagService.updateTag({name: 'twitter:image', content: environment.publicImgPath + '/logo-canoe-square-w500.jpg'});
  }
}
