<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the minimum number of data years to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n-matTooltip matTooltip="Min. number of data years" i18n>Min. data years</mat-label>
          <input cdkFocusInitial #minDataYearsInput type="number" matInput name="minDataYears" formControlName="minDataYears" placeholder="20" id="minDataYears"
                 [min]="10" [max]="30" (change)="saveMinDataYears(minDataYearsInput.value)">
          <mat-icon (click)="clearMinDataYears()" *ngIf="minDataYearsInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>
