import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StatisticalTradingService} from '../../../statistical-trading.service';
import {
  selectSimulationSearchExchangeShortNames,
  selectSimulationSearchMaxCapitalPerTradeMax,
  selectSimulationSearchMaxCapitalPerTradeMin,
  selectSimulationSearchMaxHoldingDurationMax,
  selectSimulationSearchMaxHoldingDurationMin,
  selectSimulationSearchMinAverageRelativeProfitMax,
  selectSimulationSearchMinAverageRelativeProfitMin,
  selectSimulationSearchMinRoi,
  selectSimulationSearchSeedCapitalMax,
  selectSimulationSearchSeedCapitalMin,
  selectSimulationSearchYear,
} from '../../../../store/simulation.selectors';
import {
  addSimulationSearchExchangeShortName,
  setSimulationSearchExchangeShortNames,
  setSimulationSearchParams,
  updateSimulationSearchMaxCapitalPerTradeMax,
  updateSimulationSearchMaxCapitalPerTradeMin,
  updateSimulationSearchMaxHoldingDurationMax,
  updateSimulationSearchMaxHoldingDurationMin,
  updateSimulationSearchMinAverageRelativeProfitMax,
  updateSimulationSearchMinAverageRelativeProfitMin,
  updateSimulationSearchMinRoi,
  updateSimulationSearchSeedCapitalMax,
  updateSimulationSearchSeedCapitalMin,
  updateSimulationSearchYear,
} from '../../../../store/simulation.actions';
import {FormBuilder, FormGroup} from '@angular/forms';
import {StatisticalSimulationSearchParams} from '../../../../shared/models/statisticalSimulationSearchParams.interface';
import {take, takeUntil} from 'rxjs/operators';
import Util from '../../../../shared/util';

@Component({
  selector: 'app-simulation-search-panel',
  templateUrl: './simulation-search-panel.component.html',
  styleUrls: ['./simulation-search-panel.component.scss'],
})
export class SimulationSearchPanelComponent extends BaseComponent implements OnInit {
  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  searchYear$ = this.store.select(selectSimulationSearchYear);
  searchMinRoi$ = this.store.select(selectSimulationSearchMinRoi);
  searchMinAverageRelativeProfitMin$ = this.store.select(selectSimulationSearchMinAverageRelativeProfitMin);
  searchMinAverageRelativeProfitMax$ = this.store.select(selectSimulationSearchMinAverageRelativeProfitMax);
  searchMaxHoldingDurationMin$ = this.store.select(selectSimulationSearchMaxHoldingDurationMin);
  searchMaxHoldingDurationMax$ = this.store.select(selectSimulationSearchMaxHoldingDurationMax);
  searchSeedCapitalMin$ = this.store.select(selectSimulationSearchSeedCapitalMin);
  searchSeedCapitalMax$ = this.store.select(selectSimulationSearchSeedCapitalMax);
  searchMaxCapitalPerTradeMin$ = this.store.select(selectSimulationSearchMaxCapitalPerTradeMin);
  searchMaxCapitalPerTradeMax$ = this.store.select(selectSimulationSearchMaxCapitalPerTradeMax);
  selectedExchangeShortNames$ = this.store.select(selectSimulationSearchExchangeShortNames);

  exchangeShortNames: string[] = [];

  form: FormGroup = this.createForm();

  @Input() layout: 'full' | 'toolbar' = 'full';

  @Input() navigateToSearch = false;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private formBuilder: FormBuilder,
      private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setFormDefaultValues();
    this.selectedExchangeShortNames$.pipe(takeUntil(this.destroy$)).subscribe(exchangeShortNames => {
      this.exchangeShortNames = exchangeShortNames ? [...exchangeShortNames] : [];
    });
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      year: [''],
      minRoi: [''],
      minAverageRelativeProfitMin: [''],
      minAverageRelativeProfitMax: [''],
      maxHoldingDurationMin: [''],
      maxHoldingDurationMax: [''],
      seedCapitalMin: [''],
      seedCapitalMax: [''],
      maxCapitalPerTradeMin: [''],
      maxCapitalPerTradeMax: [''],
    });
  }

  private setFormDefaultValues() {
    this.searchYear$.pipe(take(1)).subscribe(year => this.form.patchValue({year: year}));
    this.searchMinRoi$.pipe(take(1)).subscribe(minRoi => this.form.patchValue({minRoi: minRoi}));
    this.searchMinAverageRelativeProfitMin$.pipe(take(1)).subscribe(minAverageRelativeProfitMin => this.form.patchValue({minAverageRelativeProfitMin: minAverageRelativeProfitMin}));
    this.searchMinAverageRelativeProfitMax$.pipe(take(1)).subscribe(minAverageRelativeProfitMax => this.form.patchValue({minAverageRelativeProfitMax: minAverageRelativeProfitMax}));
    this.searchMaxHoldingDurationMin$.pipe(take(1)).subscribe(maxHoldingDurationMin => this.form.patchValue({maxHoldingDurationMin: maxHoldingDurationMin}));
    this.searchMaxHoldingDurationMax$.pipe(take(1)).subscribe(maxHoldingDurationMax => this.form.patchValue({maxHoldingDurationMax: maxHoldingDurationMax}));
    this.searchSeedCapitalMin$.pipe(take(1)).subscribe(seedCapitalMin => this.form.patchValue({seedCapitalMin: seedCapitalMin}));
    this.searchSeedCapitalMax$.pipe(take(1)).subscribe(seedCapitalMax => this.form.patchValue({seedCapitalMax: seedCapitalMax}));
    this.searchMaxCapitalPerTradeMin$.pipe(take(1)).subscribe(maxCapitalPerTradeMin => this.form.patchValue({maxCapitalPerTradeMin: maxCapitalPerTradeMin}));
    this.searchMaxCapitalPerTradeMax$.pipe(take(1)).subscribe(maxCapitalPerTradeMax => this.form.patchValue({maxCapitalPerTradeMax: maxCapitalPerTradeMax}));
  }

  search() {
    const searchParams: StatisticalSimulationSearchParams = {
      year: this.form.value.year, minRoi: this.form.value.minRoi,
      minAverageRelativeProfitMin: this.form.value.minAverageRelativeProfitMin,
      minAverageRelativeProfitMax: this.form.value.minAverageRelativeProfitMax,
      maxHoldingDurationMin: this.form.value.maxHoldingDurationMin,
      maxHoldingDurationMax: this.form.value.maxHoldingDurationMax,
      seedCapitalMin: this.form.value.seedCapitalMin,
      seedCapitalMax: this.form.value.seedCapitalMax,
      maxCapitalPerTradeMin: this.form.value.maxCapitalPerTradeMin,
      maxCapitalPerTradeMax: this.form.value.maxCapitalPerTradeMax,
      exchangeShortNames: this.exchangeShortNames,
    };
    this.store.dispatch(setSimulationSearchParams({searchParams}));
    this.statisticalTradingService.fetchStatisticalSimulationResults();
    if (this.navigateToSearch)
      this.statisticalTradingService.navigateToStatisticalSimulationSearch();
  }

  clearYear() {
    this.store.dispatch(updateSimulationSearchYear({year: undefined}));
    this.form.patchValue({year: undefined});
  }

  clearMinRoi() {
    this.store.dispatch(updateSimulationSearchMinRoi({minRoi: undefined}));
    this.form.patchValue({minRoi: undefined});
  }

  clearMinAverageRelativeProfitMin() {
    this.store.dispatch(updateSimulationSearchMinAverageRelativeProfitMin({minAverageRelativeProfitMin: undefined}));
    this.form.patchValue({minAverageRelativeProfitMin: undefined});
  }

  clearMinAverageRelativeProfitMax() {
    this.store.dispatch(updateSimulationSearchMinAverageRelativeProfitMax({minAverageRelativeProfitMax: undefined}));
    this.form.patchValue({minAverageRelativeProfitMax: undefined});
  }

  clearMaxHoldingDurationMin() {
    this.store.dispatch(updateSimulationSearchMaxHoldingDurationMin({maxHoldingDurationMin: undefined}));
    this.form.patchValue({maxHoldingDurationMin: undefined});
  }

  clearMaxHoldingDurationMax() {
    this.store.dispatch(updateSimulationSearchMaxHoldingDurationMax({maxHoldingDurationMax: undefined}));
    this.form.patchValue({maxHoldingDurationMax: undefined});
  }

  clearSeedCapitalMin() {
    this.store.dispatch(updateSimulationSearchSeedCapitalMin({seedCapitalMin: undefined}));
    this.form.patchValue({seedCapitalMin: undefined});
  }

  clearSeedCapitalMax() {
    this.store.dispatch(updateSimulationSearchSeedCapitalMax({seedCapitalMax: undefined}));
    this.form.patchValue({seedCapitalMax: undefined});
  }

  clearMaxCapitalPerTradeMin() {
    this.store.dispatch(updateSimulationSearchMaxCapitalPerTradeMin({maxCapitalPerTradeMin: undefined}));
    this.form.patchValue({maxCapitalPerTradeMin: undefined});
  }

  clearMaxCapitalPerTradeMax() {
    this.store.dispatch(updateSimulationSearchMaxCapitalPerTradeMax({maxCapitalPerTradeMax: undefined}));
    this.form.patchValue({maxCapitalPerTradeMax: undefined});
  }

  saveYear(year: string) {
    const yearNumber = parseInt(year);
    if (isNaN(yearNumber))
      return;
    this.store.dispatch(updateSimulationSearchYear({year: yearNumber}));
  }

  saveMinRoi(minRoi: string) {
    const minRoiNumber = parseInt(minRoi);
    if (isNaN(minRoiNumber))
      return;
    this.store.dispatch(updateSimulationSearchMinRoi({minRoi: minRoiNumber}));
  }

  saveMinAverageRelativeProfitMin(minAverageRelativeProfitMin: string) {
    const minAverageRelativeProfitMinNumber = parseFloat(minAverageRelativeProfitMin);
    if (isNaN(minAverageRelativeProfitMinNumber))
      return;
    this.store.dispatch(updateSimulationSearchMinAverageRelativeProfitMin({minAverageRelativeProfitMin: minAverageRelativeProfitMinNumber}));
  }

  saveMinAverageRelativeProfitMax(minAverageRelativeProfitMax: string) {
    const minAverageRelativeProfitMaxNumber = parseFloat(minAverageRelativeProfitMax);
    if (isNaN(minAverageRelativeProfitMaxNumber))
      return;
    this.store.dispatch(updateSimulationSearchMinAverageRelativeProfitMax({minAverageRelativeProfitMax: minAverageRelativeProfitMaxNumber}));
  }

  saveMaxHoldingDurationMin(maxHoldingDurationMin: string) {
    const maxHoldingDurationMinNumber = parseFloat(maxHoldingDurationMin);
    if (isNaN(maxHoldingDurationMinNumber))
      return;
    this.store.dispatch(updateSimulationSearchMaxHoldingDurationMin({maxHoldingDurationMin: maxHoldingDurationMinNumber}));
  }

  saveMaxHoldingDurationMax(maxHoldingDurationMax: string) {
    const maxHoldingDurationMaxNumber = parseFloat(maxHoldingDurationMax);
    if (isNaN(maxHoldingDurationMaxNumber))
      return;
    this.store.dispatch(updateSimulationSearchMaxHoldingDurationMax({maxHoldingDurationMax: maxHoldingDurationMaxNumber}));
  }

  saveSeedCapitalMin(seedCapitalMin: string) {
    const seedCapitalMinNumber = parseFloat(seedCapitalMin);
    if (isNaN(seedCapitalMinNumber))
      return;
    this.store.dispatch(updateSimulationSearchSeedCapitalMin({seedCapitalMin: seedCapitalMinNumber}));
  }

  saveSeedCapitalMax(seedCapitalMax: string) {
    const seedCapitalMaxNumber = parseFloat(seedCapitalMax);
    if (isNaN(seedCapitalMaxNumber))
      return;
    this.store.dispatch(updateSimulationSearchSeedCapitalMax({seedCapitalMax: seedCapitalMaxNumber}));
  }

  saveMaxCapitalPerTradeMin(maxCapitalPerTradeMin: string) {
    const maxCapitalPerTradeMinNumber = parseFloat(maxCapitalPerTradeMin);
    if (isNaN(maxCapitalPerTradeMinNumber))
      return;
    this.store.dispatch(updateSimulationSearchMaxCapitalPerTradeMin({maxCapitalPerTradeMin: maxCapitalPerTradeMinNumber}));
  }

  saveMaxCapitalPerTradeMax(maxCapitalPerTradeMax: string) {
    const maxCapitalPerTradeMaxNumber = parseFloat(maxCapitalPerTradeMax);
    if (isNaN(maxCapitalPerTradeMaxNumber))
      return;
    this.store.dispatch(updateSimulationSearchMaxCapitalPerTradeMax({maxCapitalPerTradeMax: maxCapitalPerTradeMaxNumber}));
  }

  selectExchange(exchangeShortName?: string) {
    if (exchangeShortName)
      this.store.dispatch(addSimulationSearchExchangeShortName({exchangeShortName}));
  }

  onExchangesSelectionChanged(exchangeShortNames: string[]) {
    this.store.dispatch(setSimulationSearchExchangeShortNames({exchangeShortNames}));
  }

  clearAllFilters() {
    const searchParams: StatisticalSimulationSearchParams = {};
    this.store.dispatch(setSimulationSearchParams({searchParams}));
  }

  getCurrentYear(): number {
    return Util.getCurrentYear();
  }
}
