import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {ProfitByPeriodMap, StatisticalReturn} from '../../shared/models/statisticalReturn.interface';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {UtilService} from '../../shared/util.service';
import Util from '../../shared/util';
import {StatisticalTradingService} from '../statistical-trading.service';

@Component({
  selector: 'app-statistical-return-periods-table',
  templateUrl: './statistical-return-periods-table.component.html',
  styleUrls: ['./statistical-return-periods-table.component.scss'],
})
export class StatisticalReturnPeriodsTableComponent extends BaseComponent implements OnInit {

  @Input() statRet?: StatisticalReturn;

  relativeProfitTruncatePercentage = environment.relativeProfitTruncateThreshold * 100;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    public utilService: UtilService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getKeys(profitByPeriodMap: ProfitByPeriodMap) {
    return Object.keys(profitByPeriodMap);
  }

  getAvgMaxRiseForPeriod(statRet: StatisticalReturn, period: string) {
    const years = StatisticalTradingService.getYearsForPeriod(statRet, period);
    const relevantMaxRises = this.getMaxRises(statRet, years);
    return Util.calcAverage(relevantMaxRises);
  }

  getAvgMaxDropForPeriod(statRet: StatisticalReturn, period: string) {
    const years = StatisticalTradingService.getYearsForPeriod(statRet, period);
    const relevantMaxDrops = this.getMaxDrops(statRet, years);
    return Util.calcAverage(relevantMaxDrops);
  }

  getHighestRiseForPeriod(statRet: StatisticalReturn, period: string) {
    const years = StatisticalTradingService.getYearsForPeriod(statRet, period);
    const relevantMaxRises = this.getMaxRises(statRet, years);
    if (relevantMaxRises.length === 0)
      return null;
    return relevantMaxRises.sort()[relevantMaxRises.length - 1];
  }

  getHighestDropForPeriod(statRet: StatisticalReturn, period: string) {
    const years = StatisticalTradingService.getYearsForPeriod(statRet, period);
    const relevantMaxDrops = this.getMaxDrops(statRet, years);
    if (relevantMaxDrops.length === 0)
      return null;
    return relevantMaxDrops.sort()[relevantMaxDrops.length - 1];
  }

  private getMaxRises(statRet: StatisticalReturn, years: string[]) {
    const maxRises: number[] = [];
    if (!statRet.maxRiseByYearMap)
      return maxRises;
    years.forEach(it => {
      if (statRet.maxRiseByYearMap && statRet.maxRiseByYearMap[it])
        maxRises.push(statRet.maxRiseByYearMap!![it]);
    });
    return maxRises;
  }

  private getMaxDrops(statRet: StatisticalReturn, years: string[]) {
    const maxDrops: number[] = [];
    if (!statRet.maxDropByYearMap)
      return maxDrops;
    years.forEach(it => {
      if (statRet.maxDropByYearMap && statRet.maxDropByYearMap[it])
        maxDrops.push(statRet.maxDropByYearMap!![it]);
    });
    return maxDrops;
  }

  getWinRatio(statRet: StatisticalReturn, period: string) {
    return StatisticalTradingService.getWinRatio(statRet, period);
  }
}
