import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {addCurrenciesToCache, addCurrencyToCache, addUserPublicToCache, clearCurrenciesCache, setCurrencies} from './shared.actions';
import {environment} from '../../../environments/environment';
import {Currency} from '../models/currency.interface';
import {UserPublic} from '../models/userPublic.interface';


export interface SharedState {
  currencies: Currency[];
  currenciesFetchDate?: Date;
  usersPublic: UserPublic[];
}

export const initialState: SharedState = {
  usersPublic: [],
  currencies: [],
};

function logDebugMessages(actionName: string, state: SharedState, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<SharedState, Action> = createReducer(
    initialState,

    on(addCurrencyToCache, (state, {currency}) => {
      const newCurrencies: Currency[] = [];
      const nowUtc = new Date().getTime();
      for (const currencyFromState of state.currencies) {
        // Skip the currency with the ID of the given currency, because we will add the given currency object later
        if (currency.id === currencyFromState.id)
          continue;
        // Also skip, if the cached currency is too old (or has no cache date)
        if (!currencyFromState.cacheDate || (nowUtc - currencyFromState.cacheDate.getTime() > environment.defaultCurrencyCacheAgeInSec * 1000))
          continue;
        const currencyCopy: Currency = {...currencyFromState};
        newCurrencies.push(currencyCopy);
      }
      newCurrencies.push(currency);
      const newState = {...state, currencies: newCurrencies};
      logDebugMessages('addCurrencyToCache', state, newState);
      return newState;
    }),
    on(addCurrenciesToCache, (state, {currencies}) => {
      const currenciesLastFetch = new Date();
      const newState = {...state, currencies, currenciesLastFetch};
      logDebugMessages('addCurrenciesToCache', state, newState);
      return newState;
    }),
    on(clearCurrenciesCache, (state) => {
      const newState = {...state, currencies: [], currenciesFetchDate: undefined};
      logDebugMessages('clearCurrenciesCache', state, newState);
      return newState;
    }),
    on(setCurrencies, (state, {currencies, currenciesFetchDate}) => {
      const newState = {...state, currencies, currenciesFetchDate};
      logDebugMessages('setCurrencies', state, newState);
      return newState;
    }),
    on(addUserPublicToCache, (state, {userPublic}) => {
      const newUsersPublic: UserPublic[] = [];
      const nowUtc = new Date().getTime();
      for (const userPublicFromState of state.usersPublic) {
        // Skip the user with the ID of the given user, because we will add the given userPublic object later
        if (userPublic.uid === userPublicFromState.uid)
          continue;
        // Also skip, if the cached userPublic is too old (or has no cache date)
        if (!userPublicFromState.cacheDate || (nowUtc - userPublicFromState.cacheDate.getTime() > environment.defaultUserPublicCacheAgeInSec * 1000))
          continue;
        const userPublicCopy: UserPublic = {...userPublicFromState};
        newUsersPublic.push(userPublicCopy);
      }
      newUsersPublic.push(userPublic);
      const newState = {...state, usersPublic: newUsersPublic};

      logDebugMessages('addUserPublicToCache', state, newState);
      return newState;
    }),
);

export function sharedReducer(state: SharedState | undefined, action: Action): SharedState {
  return reducer(state, action);
}
