import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {Currency} from '../../shared/models/currency.interface';
import Util from '../../shared/util';
import {takeUntil} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImportExportModalContentComponent} from '../../shared/components/import-export-modal-content/import-export-modal-content.component';
import {CurrencyService} from '../../shared/services/currency.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-currencies-editor',
  templateUrl: './currencies-editor.component.html',
  styleUrls: ['./currencies-editor.component.scss'],
})
export class CurrenciesEditorComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {
  selectedCurrencyId?: string;
  currencies: Currency[] = [];

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private cdRef: ChangeDetectorRef,
      private modalService: NgbModal,
      private currencyService: CurrencyService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Currencies editor`);
    this.currencyService.fetchCurrencies().then(wrapper => {
      if (wrapper.data)
        this.currencies = wrapper.data;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });

    this.currencyService.onCurrencyDeleted$.pipe(takeUntil(this.destroy$)).subscribe(id => {
      this.removeFromCurrenciesList(id);
    });
    this.currencyService.onCurrencySaved$.pipe(takeUntil(this.destroy$)).subscribe(currency => {
      this.reflectUpdateInCurrenciesList(currency);
    });
    this.currencyService.onCurrencySelected$.pipe(takeUntil(this.destroy$)).subscribe(id => {
      this.selectCurrency(id);
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  onImportExport(): void {
    this.clearAlerts();
    const modalRef = this.modalService.open(ImportExportModalContentComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.exportJson = JSON.stringify(this.currencies);
    modalRef.componentInstance.filename = 'stockforecast_currencies';

    modalRef.componentInstance.importCallback = (currenciesJson: string) => {
      if (!currenciesJson) {
        this.addError($localize`Nothing was imported. Aborted.`);
        return;
      }
      const importedCurrencies: Currency[] = JSON.parse(currenciesJson);
      if (!importedCurrencies) {
        this.addError($localize`The import did not contain any Currencies. Aborted.`);
        return;
      }
      this.currencies = importedCurrencies;
      this.currencyService.deleteAllCurrencies().then(() => {
            this.addSuccess($localize`Successfully deleted all existing Currencies.`);

            importedCurrencies.forEach(currency => {
              if (!currency?.id) {
                this.addError($localize`Error importing currency <i>"${currency.id}"</i>\: Currency is undefined or has no id`);
                return;
              }
              this.currencyService.updateCurrency(currency.id, currency, currency, false, () => {
                    this.addSuccess($localize`Successfully imported currency  <i>"${currency.id}"</i>.`);
                  },
                  (error) => {
                    this.addError($localize`Error importing currency  <i>"${currency.id}"</i>\: ${error}`);
                  });
            });
          },
          reason => {
            this.addError($localize`Error deleting all existing Currencies\: ${reason}`);
          });
      console.log(importedCurrencies);
    };
  }

  /**
   * Removes the currency with the given ID from the Currencies list
   * @param id ID of the removed currency
   */
  private removeFromCurrenciesList(id: string) {
    const removedCurrency = this.currencies.find((currency: Currency) => currency.id === id);
    if (!removedCurrency)
      return;
    this.currencies = Util.removeFromArray([...this.currencies], removedCurrency);
    this.currencies = [...this.currencies];
  }

  /**
   * Updates the list with the newly saved currency. This works both for insertions or updates.
   * @param currency saved currency
   */
  private reflectUpdateInCurrenciesList(currency: Currency) {
    if (!this.currencies)
      this.currencies = [];
    this.currencies = [...this.currencies];
    const updatedCurrencyIndex = this.currencies.findIndex(element => element.id === currency.id);
    if (updatedCurrencyIndex > -1)
      this.currencies[updatedCurrencyIndex] = currency;
    else
        // This was an insert
      this.currencies.push(currency);

    this.currencies = [...this.currencies];
    this.selectedCurrencyId = currency.id;
  }

  private selectCurrency(id?: string) {
    this.selectedCurrencyId = id;
  }

}
