<app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"
                     [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<div *ngIf="statisticalReturns && statisticalReturns.length > 0">
  <table class="table table-striped table-sm table-responsive">
    <thead>
    <tr>
      <th>&nbsp;</th>
      <th *ngIf="user">&nbsp;</th>
      <th *ngIf="customIcon">&nbsp;</th>
      <ng-container *ngFor="let column of visibleColumns">
        <th *ngIf="!column.areCollectiveColumns" [style]="column.minWidth ? 'min-width: '+column.minWidth : ''">
          <ng-container *ngIf="column.sortOrderAsc !== undefined && column.sortOrderDesc !== undefined; else notSortableStandardColumn">
            <app-sort-header [sortable]="sortable" [currentSortOrder]="sortOrder$ | async" [orderAsc]="column.sortOrderAsc!!" [orderDesc]="column.sortOrderDesc!!" (onSortClicked)="sort($event)">
              <span [matTooltip]="column.tooltip">{{column.label}}</span>
            </app-sort-header>
          </ng-container>
          <ng-template #notSortableStandardColumn>
            <span [matTooltip]="column.tooltip">{{column.label}}</span>
          </ng-template>
        </th>
        <ng-container *ngIf="column.areCollectiveColumns">
          <app-collective-table-header class="collective-columns" [column]="column" [years]="years" [periods]="periods"
                                       [showDevelopmentSinceBuyDay]="showDevelopmentSinceBuyDay"></app-collective-table-header>
        </ng-container>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let statRet of statisticalReturns" class="no-row-break">
      <td>
        <a [routerLink]="['/statistical-trading', 'symbols', statRet.symbolCode, statRet.uid]">
          <mat-icon>double_arrow</mat-icon>
        </a>
      <td *ngIf="user">
        <app-watch-list-star [symbolCode]="statRet.symbolCode" [statisticalReturnUid]="statRet.uid" [floatTopRight]="false"></app-watch-list-star>
      </td>
      <td *ngIf="customIcon">
        <mat-icon class="hover-pointer" (click)="customIconClicked( statRet.symbolCode, statRet.uid)">{{customIcon}}</mat-icon>
      </td>

      <ng-container *ngFor="let column of visibleColumns">
        <td *ngIf="!column.areCollectiveColumns">
          <app-statistical-return-data-view *ngIf="statRet && statRet?.symbolCode && statRet.uid" [column]="column" [statRet]="statRet"
                                            [showExchange]="showExchange" [nameMaxLength]="40" (onExchangeClicked)="onExchangeClick($event)" [symbol]="symbolsById.get(statRet.symbolCode)"></app-statistical-return-data-view>
        </td>
        <ng-container *ngIf="column.areCollectiveColumns">
          <app-statistical-return-collective-data-view class="collective-columns" [column]="column" [years]="years" [showDevelopmentSinceBuyDay]="showDevelopmentSinceBuyDay"
                                                       [symbol]="symbolsById.get(statRet.symbolCode)" [symbolPricesOnBuyDay]="symbolPricesOnBuyDayBySymbolCode.get(statRet.symbolCode)"
                                                       [periods]="periods" [statRet]="statRet"></app-statistical-return-collective-data-view>
        </ng-container>
      </ng-container>

    </tr>
    </tbody>
  </table>
</div>
<a [routerLink]="['/account','table-columns','statistical-returns']" i18n class="small">Customize columns</a>

<ng-template #none>
  -
</ng-template>
