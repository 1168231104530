import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.state';

export const selectShared = (state: AppState) => state.shared;

export const selectUsersPublic = createSelector(
    selectShared,
    (sharedState) => sharedState.usersPublic,
);

export const selectCurrencies = createSelector(
    selectShared,
    (sharedState) => sharedState.currencies,
);

export const selectCurrenciesFetchDate = createSelector(
    selectShared,
    (sharedState) => sharedState.currenciesFetchDate,
);

