import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'valueClassWinRatioPipe',
})
export class ValueClassWinRatioPipe implements PipeTransform {

  transform(value: number | null | undefined): string {
    if (value === undefined || value === null)
      return '';

    if (value >= 0.9) {
      return 'good-value';
    } else if (value >= 0.8) {
      return 'neutral-value';
    } else {
      return 'bad-value';
    }
  }

}
