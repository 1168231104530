<a (click)="openEditDialog($event)" class="hover-pointer" [ngClass]="{'filter-active': isFilterActive}">
  <span i18n>Min win ratio</span>
  <ng-container *ngIf="minWinRatio$ | async as minWinRatio">
    <span>:&nbsp;</span>
    <app-percentage-view [value]="minWinRatio/100"></app-percentage-view>
  </ng-container>

  <ng-container *ngIf="minPeriodWinRatios$ | async as minPeriodWinRatios">
    <ng-container *ngIf="minPeriodWinRatios !== undefined && minPeriodWinRatios.length > 0">
      <ul *ngIf="isFilterActive">
        <ng-container *ngFor="let minPeriodWinRatio of minPeriodWinRatios">
          <li *ngIf="minPeriodWinRatio.minWinRatio">
            <app-period-win-ratio-view [periodWinRatio]="minPeriodWinRatio"></app-period-win-ratio-view>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </ng-container>

</a>
