<h1 i18n class="paragraph-headline">What is a Statistical Trade?</h1>
<p i18n>A statistical trade is a type of trade that is based on statistical analysis and mathematical models. It generally
  involves the use of historical data and statistical techniques such as statistical arbitrage, quantitative analysis,
  and machine learning to make predictions about future market trends and identify profitable trades.</p>
<p i18n>Statistical trading strategies can be used in various financial markets, such as stocks, bonds, currencies, and
  commodities. They are often used by hedge funds, investment banks, and other institutional investors to identify
  opportunities for profit. These strategies can also be used by individual traders, but it can be more difficult for
  them to implement them as they typically require significant computational power and sophisticated software.</p>
<ul>
  <li i18n>Algorithmic trading: using algorithms to identify and execute trades based on market data and trends</li>
  <li i18n>High-frequency trading: using high-speed computers and algorithms to execute trades at a high rate</li>
  <li i18n>Mean reversion: using statistical techniques to identify when an asset's price has deviated from its historical
    average and will likely return to it.
  </li>
</ul>
<p i18n>It's important to note that while statistical trading can be a powerful tool for identifying profitable trades, it is
  not a guarantee of success and it has also some risks involved.</p>
