<div class="modal-body">
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>

    <h1 class="paragraph-headline" i18n="export">Export</h1>
    <mat-form-field class="mat-form-textarea-w-100">
        <mat-label i18n="export data hint">Exported data as JSON string</mat-label>
        <textarea #exportRef [disabled]="true"
                  [value]="exportJson" cdkAutosizeMaxRows="15" cdkAutosizeMinRows="2"
                  cdkTextareaAutosize i18n-placeholder="export hint" matInput
                  placeholder="Exported data as JSON string"></textarea>
        <span class="character-counter">{{exportRef.value.length}}</span>
    </mat-form-field>
    <app-mat-icon-button *ngIf="exportJson" [callback]="downloadJson.bind(this)"
                         color="primary" cssClass="mb-3" i18n-label="Button label download export" icon="download"
                         label="Download JSON Export"></app-mat-icon-button>

    <h1 class="paragraph-headline" i18n="import">Import</h1>
    <mat-form-field class="mat-form-textarea-w-100">
        <mat-label i18n="json import string label">JSON import string</mat-label>
        <textarea #importRef
                  cdkAutosizeMaxRows="15" cdkAutosizeMinRows="2"
                  cdkTextareaAutosize i18n-placeholder="import hint" matInput
                  placeholder="Paste JSON string to import"></textarea>
        <span class="character-counter">{{importRef.value.length}}</span>
    </mat-form-field>
    <br>
    <app-mat-icon-button *ngIf="importCallback" [callback]="import.bind(this)" [params]="[importRef.value]"
                         color="primary" cssClass="mb-3" i18n-label="Button label import" icon="input"
                         label="Import"></app-mat-icon-button>
</div>
