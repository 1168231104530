import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {environment} from '../../environments/environment';
import {from, Observable, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {FacebookService} from '../shared/services/facebook.service';
import {AnalyticsService} from '../shared/services/analytics.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {StatisticalTradingService} from '../statistical-trading/statistical-trading.service';
import {BackendService} from '../shared/services/backend.service';
import {Store} from '@ngrx/store';
import {firestore} from '../app.module';
import firebase from 'firebase';
import Util from '../shared/util';
import {SimulationState} from './simulation.state';
import {fetchMoreSimulationSearchResults, fetchSimulationSearchResults, setSimulationSearchResults, simulationSearchFailed} from './simulation.actions';
import {selectSimulation} from './simulation.selectors';
import {StatisticalSimulationSearchParams} from '../shared/models/statisticalSimulationSearchParams.interface';
import {simulationResultConverter} from '../shared/services/stock.service';
import {StatisticalSimulationResult} from '../shared/models/statisticalSimulationResult.interface';
import {StatisticalSimulationSearchResult} from '../shared/models/statisticalSimulationSearchResult.interface';
import {AppState} from './app.state';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import QuerySnapshot = firebase.firestore.QuerySnapshot;

@Injectable()
export class SimulationEffects {

  constructor(private actions: Actions,
              private httpClient: HttpClient,
              private router: Router,
              private facebookService: FacebookService,
              private analyticsService: AnalyticsService,
              private statisticalTradingService: StatisticalTradingService,
              private backendService: BackendService,
              private store: Store<AppState>) {
  }

  fetchSimulationResultSearchResultsFromFirestore = createEffect(() => this.actions.pipe(
      ofType(fetchSimulationSearchResults, fetchMoreSimulationSearchResults),
      withLatestFrom(this.store.select(selectSimulation)),
      switchMap(action => this.handleSimulationResultsSearch(action[1])),
  ));

  /**
   * Handles the firestore search request for statistical simulation results and maps the response to handleSimulationResultsSearchResponse.
   * @param simulationState current stock state
   * @return Observable of search request
   */
  private handleSimulationResultsSearch(simulationState: SimulationState): Observable<any> {

    const searchParams: StatisticalSimulationSearchParams = simulationState.simulationSearch.searchParams;
    const limit = searchParams.limit ? searchParams.limit : environment.defaultSimulationSearchCount;

    let query = firestore.collectionGroup(environment.firestoreCollectionStatisticalSimulationResults)
        .withConverter(simulationResultConverter)
        .limit(limit);
    query = this.addWhereAndOrderByToSimulationResultsSearchQuery(query, searchParams);
    if (searchParams.startAfter)
      query = query.startAfter(searchParams.startAfter);

    try {
      return from(query.get()).pipe(
          map(response => this.handleSimulationResultsSearchResponse(response, searchParams, limit)),
          catchError((error) => this.handleSimulationResultsSearchError(error)),
      );
    } catch (error) {
      return this.handleSimulationResultsSearchError(error);
    }
  }

  private addWhereAndOrderByToSimulationResultsSearchQuery(query: firebase.firestore.Query<StatisticalSimulationResult>, searchParams: StatisticalSimulationSearchParams) {
    if (searchParams.year !== undefined)
      query = query.where('year', '==', searchParams.year);
    if (searchParams.exchangeShortNames !== undefined && searchParams.exchangeShortNames.length > 0)
      query = query.where('exchangeShortNames', 'array-contains-any', searchParams.exchangeShortNames);

    let inequalityFilterSet = false;

    if (searchParams.minRoi !== undefined) {
      query = query.where('roi', '>=', searchParams.minRoi / 100);
      query = query.orderBy('roi', 'desc');
      inequalityFilterSet = true;
    }

    if (!inequalityFilterSet && (searchParams.minAverageRelativeProfitMin !== undefined || searchParams.minAverageRelativeProfitMax !== undefined)) {
      if (searchParams.minAverageRelativeProfitMin !== undefined) {
        query = query.where('minAverageRelativeProfit', '>=', searchParams.minAverageRelativeProfitMin / 100);
      }
      if (searchParams.minAverageRelativeProfitMax !== undefined) {
        query = query.where('minAverageRelativeProfit', '<=', searchParams.minAverageRelativeProfitMax / 100);
      }
      query = query.orderBy('minAverageRelativeProfit', 'asc');
      inequalityFilterSet = true;
    }

    if (!inequalityFilterSet && (searchParams.maxHoldingDurationMin !== undefined || searchParams.maxHoldingDurationMax !== undefined)) {
      if (searchParams.maxHoldingDurationMin !== undefined) {
        query = query.where('maxHoldingDuration', '>=', searchParams.maxHoldingDurationMin);
      }
      if (searchParams.maxHoldingDurationMax !== undefined) {
        query = query.where('maxHoldingDuration', '<=', searchParams.maxHoldingDurationMax);
      }
      query = query.orderBy('maxHoldingDuration', 'asc');
      inequalityFilterSet = true;
    }

    if (!inequalityFilterSet && (searchParams.seedCapitalMin !== undefined || searchParams.seedCapitalMax !== undefined)) {
      if (searchParams.seedCapitalMin !== undefined) {
        query = query.where('seedCapital', '>=', searchParams.seedCapitalMin);
      }
      if (searchParams.seedCapitalMax !== undefined) {
        query = query.where('seedCapital', '<=', searchParams.seedCapitalMax);
      }
      query = query.orderBy('seedCapital', 'asc');
      inequalityFilterSet = true;
    }

    if (!inequalityFilterSet && (searchParams.maxCapitalPerTradeMin !== undefined || searchParams.maxCapitalPerTradeMax !== undefined)) {
      if (searchParams.maxCapitalPerTradeMin !== undefined) {
        query = query.where('maxCapitalPerTrade', '>=', searchParams.maxCapitalPerTradeMin);
      }
      if (searchParams.maxCapitalPerTradeMax !== undefined) {
        query = query.where('maxCapitalPerTrade', '<=', searchParams.maxCapitalPerTradeMax);
      }
      query = query.orderBy('maxCapitalPerTrade', 'asc');
      inequalityFilterSet = true;
    }

    if (!inequalityFilterSet)
      query = query.orderBy('roi', 'desc');

    return query;
  }

  handleSimulationResultsSearchResponse = (simulationResultQuerySnapshot: QuerySnapshot<StatisticalSimulationResult>, searchParams: StatisticalSimulationSearchParams, limit: number) => {
    const simulationResults: StatisticalSimulationResult[] = [];
    let now = new Date();
    simulationResultQuerySnapshot.forEach((simulationResultDocSnapshot: DocumentSnapshot<StatisticalSimulationResult>) => {
      const simulationResult: StatisticalSimulationResult | undefined = simulationResultDocSnapshot.data();
      if (simulationResult) {
        const simulationResultWithIdAndDate = {
          ...simulationResult,
          uid: simulationResultDocSnapshot.id,
          cacheDate: now,
        };
        simulationResults.push(simulationResultWithIdAndDate);
      }
    });

    const lastVisible = Object.freeze(simulationResultQuerySnapshot.docs[simulationResultQuerySnapshot.docs.length - 1]);

    const thereIsMore = simulationResultQuerySnapshot.size !== 0 && (limit !== undefined && simulationResultQuerySnapshot.size >= limit);
    const searchResult: StatisticalSimulationSearchResult = {
      simulationResults,
      lastVisible,
      thereIsMore,
    };
    return setSimulationSearchResults({searchResult});
  };

  private handleSimulationResultsSearchError(error: any): Observable<any> {
    return of(simulationSearchFailed({errorMessage: Util.createErrorString(error, $localize`Fetching simulation results failed.`)}));
  }

}

