export enum SortOrder {
  LAST_ADDED_TO_WATCHLIST = 'LAST_ADDED_TO_WATCHLIST',
  SYMBOL_CODE = 'SYMBOL_CODE',
  BUY_DATE = 'BUY_DATE',
  SELL_DATE = 'SELL_DATE',
  EXCHANGE_SHORT_NAME = 'EXCHANGE_SHORT_NAME',
  DURATION = 'DURATION',
  WORST_YEAR = 'WORST_YEAR',
  AVERAGE_PROFIT = 'AVERAGE_PROFIT',
  MAX_DURATION = 'MAX_DURATION',
  MIN_AVERAGE_PROFIT = 'MIN_AVERAGE_PROFIT',
  MIN_DATA_YEARS = 'MIN_DATA_YEARS',
  ROI = 'ROI',
  SEED_CAPITAL = 'SEED_CAPITAL',
  FINAL_CAPITAL = 'FINAL_CAPITAL',
  YEAR = 'YEAR',
  NUMBER_OF_TRANSACTIONS = 'NUMBER_OF_TRANSACTIONS',
  MAX_CAPITAL_PER_TRADE = 'MAX_CAPITAL_PER_TRADE'
}
