import {Injectable} from '@angular/core';
import {Notification} from '../shared/models/notification.interface';
import {environment} from '../../environments/environment';
import firebase from 'firebase/app';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {Wrapper} from '../shared/models/wrapper.model';
import {convertToNotification} from '../shared/converters/modelConverters';
import {firestore} from '../app.module';
import FirestoreError = firebase.firestore.FirestoreError;
import Timestamp = firebase.firestore.Timestamp;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor() {
  }


  /**
   * Creates a listener for notifications for the given user. Whenever the latest notifications change, the success callbacks is called with
   * the current notifications.
   * @param userUid ID of the user, whose notifications should be loaded
   * @param limit number of notifications per listener. default: 10 (or whatever is set in environment.defaultLoadNotificationsCount)
   */
  streamNotifications(userUid: string,
                      onSuccessCallback: (notifications: Notification[]) => void,
                      onErrorCallback: ((error: string) => void),
                      limit: number = environment.defaultLoadNotificationsCount): () => void {
    return firestore.collection(environment.firestoreCollectionNotifications)
      .where('userUid', '==', userUid)
      .orderBy('creationDate', 'desc')
      .limit(limit)
      .withConverter(notificationConverter)
      .onSnapshot((querySnapshot) => {
        const notifications: Notification[] = [];
        querySnapshot.forEach((doc) => {
          const notification: Notification | undefined = doc.data();
          if (notification) {
            const notificationWithId = {...notification, uid: doc.id};
            notifications.push(notificationWithId);
          }
        });
        if (notifications.length > 0) {
          onSuccessCallback(notifications);
        }

      }, (error: FirestoreError) => {
        onErrorCallback($localize`Error loading notifications\: ${error.message}`);
      });
  }

  markNotificationAsRead(notification: Notification, onSuccessCallback: () => void, onErrorCallback: (error: string) => void) {
    notification.read = true;
    firestore.collection(environment.firestoreCollectionNotifications).doc(notification.uid).set(notification).then(() => onSuccessCallback(), error => onErrorCallback(error));
  }

  async fetchNotifications(userUid: string, oldestNotificationTimestamp: Timestamp, startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadNotificationsCount): Promise<Wrapper<Notification[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionNotifications)
        .where('userUid', '==', userUid)
        .where('creationDate', '<', oldestNotificationTimestamp)
        .orderBy('creationDate', 'desc')
        .withConverter(notificationConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const notificationQuerySnapshot = await query.get();
      const notifications: Notification[] = [];
      notificationQuerySnapshot.forEach(notificationDocSnapshot => {
        const notification: Notification | undefined = notificationDocSnapshot.data();
        if (notification) {
          const notificationWithId = {...notification, uid: notificationDocSnapshot.id};
          notifications.push(notificationWithId);
        }
      });
      const lastVisible = notificationQuerySnapshot.docs[notificationQuerySnapshot.docs.length - 1];

      return new Wrapper<Notification[]>(notifications, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Notification[]>(undefined, $localize`You can only view your own notifications.`);
      return new Wrapper<Notification[]>(undefined, e.message);
    }

    // If no notifications were found
    return new Wrapper<Notification[]>(undefined);
  }

}

// Firestore data converter
export const notificationConverter = {
  toFirestore(notification: Notification): Notification {
    return notification;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Notification {
    return convertToNotification(snapshot.data(options), snapshot.data(options).uid);
  },
};
