import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {StatisticalReturnViewType} from '../../../shared/types/statisticalReturnViewType.type';
import {Observable} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {
  selectBestStatisticalReturnSearchMinAverageVolumeBaseCurrency,
  selectUpcomingStatisticalReturnSearchMinAverageVolumeBaseCurrency,
} from '../../../store/stock.selectors';
import {
  MinAverageVolumeBaseCurrencyEditDialogComponent,
} from './min-average-volume-base-currency-edit-dialog/min-average-volume-base-currency-edit-dialog.component';
import {environment} from '../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-min-average-volume-base-currency-filter',
  templateUrl: './min-average-volume-base-currency-filter.component.html',
  styleUrls: ['./min-average-volume-base-currency-filter.component.scss'],
})
export class MinAverageVolumeBaseCurrencyFilterComponent extends BaseComponent implements OnInit {

  @Input() viewType?: StatisticalReturnViewType;

  isFilterActive = false;

  baseCurrency = environment.baseCurrency;
  minAverageVolumeBaseCurrency$?: Observable<number | undefined>;

  private editDialog?: MatDialogRef<any, any>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.viewType === 'upcoming') {
      this.minAverageVolumeBaseCurrency$ = this.store.select(selectUpcomingStatisticalReturnSearchMinAverageVolumeBaseCurrency);
    }
    if (this.viewType === 'best') {
      this.minAverageVolumeBaseCurrency$ = this.store.select(selectBestStatisticalReturnSearchMinAverageVolumeBaseCurrency);
    }

    this.minAverageVolumeBaseCurrency$?.pipe(takeUntil(this.destroy$)).subscribe(minAverageVolumeBaseCurrency => {
      if (minAverageVolumeBaseCurrency)
        this.isFilterActive = true;
      else
        this.isFilterActive = false;
    });
  }

  openEditDialog(mouseEvent: MouseEvent): void {
    this.editDialog = this.statisticalTradingService.openEditFilterDialog(MinAverageVolumeBaseCurrencyEditDialogComponent, $localize`Min. volume (${this.baseCurrency})`, this.viewType, undefined, 350);
  }

}
