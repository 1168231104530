import {User} from '../models/user.interface';
import {Message} from '../models/message.interface';
import {Notification} from '../models/notification.interface';
import {UserPublic} from '../models/userPublic.interface';
import firebase from 'firebase/app';
import {Faq} from '../models/faq.interface';
import {Currency} from '../models/currency.interface';
import {Symbol} from '../models/symbol.interface';
import {StatisticalReturn} from '../models/statisticalReturn.interface';
import {Exchange} from '../models/exchange.interface';
import {Schedule} from '../models/schedule.interface';
import {StatisticalSimulationResult} from '../models/statisticalSimulationResult.interface';
import {StatisticalTradingPlan} from '../models/statisticalTradingPlan.interface';
import DocumentData = firebase.firestore.DocumentData;


export function convertToUser(data: DocumentData): User {
  if (!data?.uid || !data.displayName)
    throw new Error(`Invalid user. Data is missing: data?.uid=${data?.uid}, data.displayName=${data.displayName}`);

  const user: User = {
    creationDate: data.creationDate,
    uid: data.uid,
    displayName: data.displayName,
    ...data,
  };
  if (data.address)
    user.address = data.address;
  if (data.settings)
    user.settings = data.settings;
  if (data.firstName)
    user.firstName = data.firstName;
  if (data.lastName)
    user.lastName = data.lastName;
  if (data.telephoneNumber)
    user.telephoneNumber = data.telephoneNumber;
  if (data.admin)
    user.admin = data.admin;
  if (data.email)
    user.email = data.email;
  if (data.emailVerified)
    user.emailVerified = data.emailVerified;
  if (data.lastEditDate)
    user.lastEditDate = data.lastEditDate;
  if (data.photoURL)
    user.photoURL = data.photoURL;
  if (data.watchedSymbols)
    user.watchedSymbols = data.watchedSymbols;
  if (data.watchedStatisticalReturns)
    user.watchedStatisticalReturns = data.watchedStatisticalReturns;
  if (data.watchedStatisticalSimulation)
    user.watchedStatisticalSimulation = data.watchedStatisticalSimulation;
  return user;
}

export function convertToUserPublic(data: DocumentData): UserPublic {
  if (!data?.uid || !data.displayName)
    throw new Error(`Invalid user. Data is missing: data?.uid=${data?.uid}, data.displayName=${data.displayName}`);

  const userPublic: UserPublic = {
    creationDate: data.creationDate,
    uid: data.uid,
    displayName: data.displayName,
    ...data,
  };
  if (data.imgUrl)
    userPublic.imgUrl = data.imgUrl;
  if (data.imgUrlThumb)
    userPublic.imgUrlThumb = data.imgUrlThumb;
  return userPublic;
}

export function convertToMessage(data: DocumentData) {
  if (!data?.message || !data?.senderUid || !data?.receiverUid || !data?.date)
    throw new Error(`Invalid message. Data is missing: data?.message=${data?.message}, data?.senderUid=${data?.senderUid},
    data?.receiverUid=${data?.receiverUid}, data?.date=${data?.date}`);

  const message: Message = {
    message: data.message,
    senderUid: data.senderUid,
    receiverUid: data.receiverUid,
    date: data.date,
    ...data,
  };
  if (data.listingUid)
    message.listingUid = data.listingUid;
  return message;
}


export function convertToCurrency(data: DocumentData): Currency {
  if (!data?.id || !data?.identifier || !data?.symbol || !data?.strings)
    throw new Error(`Invalid currency. Data is missing: data?.id=${data?.id}, data?.strings=${data?.strings}, data?.symbol=${data?.symbol}, data?.identifier=${data?.identifier}`);

  const currency: Currency = {
    id: data.id,
    identifier: data.identifier,
    strings: data.strings,
    symbol: data.symbol,
    ...data,
  };
  return currency;
}

export function convertToNotification(data: DocumentData, uid: string): Notification {
  if (!data?.userUid || !data?.creationDate || !data?.notificationType || data?.read === undefined)
    throw new Error(`Invalid notification. Data is missing: data?.userUid=${data?.userUid}, data?.creationDate=${data?.creationDate},
     data?.notificationType=${data?.notificationType}, data?.read=${data?.read}`);

  const notification: Notification = {
    uid,
    userUid: data.userUid,
    read: data.read,
    creationDate: data.creationDate,
    notificationType: data.notificationType,
    actionUrl: data.actionUrl,
    ...data,
  };
  if (data.otherUser)
    notification.otherUser = data.otherUser;
  return notification;
}

export function convertToFaq(data: DocumentData): Faq {
  if (!data.creationDate)
    throw new Error(`Invalid faq. Data is missing. data?.creationDate=${data?.creationDate}`);

  const faq: Faq = {
    strings: data.strings,
    priority: data.priority,
    creationDate: data.creationDate,
    ...data,
  };
  return faq;
}

export function convertToSchedule(data: DocumentData): Schedule {

  const schedule: Schedule = {
    uid: data.uid,
    type: data.type,
    analysisIntervalInDays: data.analysisIntervalInDays,
    lastComprehensiveSymbolCheck: data.lastComprehensiveSymbolCheck,
    name: data.name,
    description: data.description,
    symbolCodes: data.symbolCodes,
    creationDate: data.creationDate,
    lastEditDate: data.lastEditDate,
    ...data,
  };
  return schedule;
}

export function convertToSymbol(data: DocumentData): Symbol {
  if (!data?.name || !data?.code)
    throw new Error(`Invalid symbol. Data is missing: data?.name=${data?.name}, data?.code=${data?.code}`);

  const symbol: Symbol = {
    name: data.name,
    code: data.code,
    ...data,
  };

  if (data.exchangeShortName)
    symbol.exchangeShortName = data.exchangeShortName;
  if (data.cacheDate)
    symbol.cacheDate = data.cacheDate;
  if (data.cik)
    symbol.cik = data.cik;
  if (data.isin)
    symbol.isin = data.isin;
  if (data.cusip)
    symbol.cusip = data.cusip;
  if (data.priceDate)
    symbol.priceDate = data.priceDate;
  if (data.price)
    symbol.price = data.price;
  if (data.range)
    symbol.range = data.range;
  if (data.changes)
    symbol.changes = data.changes;
  if (data.type)
    symbol.type = data.type;
  if (data.volAvg)
    symbol.volAvg = data.volAvg;
  if (data.mktCapUsd)
    symbol.mktCapUsd = data.mktCapUsd;
  if (data.currency)
    symbol.currency = data.currency;
  if (data.industry)
    symbol.industry = data.industry;
  if (data.website)
    symbol.website = data.website;
  if (data.description)
    symbol.description = data.description;
  if (data.ceo)
    symbol.ceo = data.ceo;
  if (data.sector)
    symbol.sector = data.sector;
  if (data.country)
    symbol.country = data.country;
  if (data.fullTimeEmployees)
    symbol.fullTimeEmployees = data.fullTimeEmployees;
  if (data.phone)
    symbol.phone = data.phone;
  if (data.address)
    symbol.address = data.address;
  if (data.city)
    symbol.city = data.city;
  if (data.state)
    symbol.state = data.state;
  if (data.zip)
    symbol.zip = data.zip;
  if (data.dcfDiff)
    symbol.dcfDiff = data.dcfDiff;
  if (data.dcf)
    symbol.dcf = data.dcf;
  if (data.image)
    symbol.image = data.image;
  if (data.ipoDate)
    symbol.ipoDate = data.ipoDate;
  if (data.defaultImage)
    symbol.defaultImage = data.defaultImage;
  if (data.activelyTrading)
    symbol.activelyTrading = data.activelyTrading;
  if (data.beta)
    symbol.beta = data.beta;
  if (data.statisticalReturnsAnalysisDate)
    symbol.statisticalReturnsAnalysisDate = data.statisticalReturnsAnalysisDate;
  if (data.statisticalReturnsAnalysisVersion)
    symbol.statisticalReturnsAnalysisVersion = data.statisticalReturnsAnalysisVersion;

  return symbol;
}

export function convertToStatisticalReturn(data: DocumentData): StatisticalReturn {
  if (!data?.symbolCode || !data?.buyDay || !data?.buyMonth || !data?.sellDay || !data?.sellMonth || !data?.duration)
    throw new Error(`Invalid statisticalReturn. Data is missing: data?.symbolCode=${data?.symbolCode}, data?.buyDay=${data?.buyDay}, data?.buyMonth=${data?.buyMonth}, data?.sellDay=${data?.sellDay}, data?.sellMonth=${data?.sellMonth}, data?.duration=${data?.duration}`);

  const statisticalReturn: StatisticalReturn = {
    symbolCode: data.symbolCode,
    buyDay: data.buyDay,
    buyMonth: data.buyMonth,
    sellMonth: data.sellMonth,
    sellDay: data.sellDay,
    duration: data.duration,
    creationDate: data.creationDate,
  };

  if (data.exchangeShortName)
    statisticalReturn.exchangeShortName = data.exchangeShortName;
  if (data.currency)
    statisticalReturn.currency = data.currency;
  if (data.buyDayNumber)
    statisticalReturn.buyDayNumber = data.buyDayNumber;
  if (data.averageRelativeProfit !== undefined)
    statisticalReturn.averageRelativeProfit = data.averageRelativeProfit;
  if (data.lowestYearlyProfit !== undefined)
    statisticalReturn.lowestYearlyProfit = data.lowestYearlyProfit;
  if (data.profitByYearMap)
    statisticalReturn.profitByYearMap = data.profitByYearMap;
  if (data.profitTruncatedByYearMap)
    statisticalReturn.profitTruncatedByYearMap = data.profitTruncatedByYearMap;
  if (data.profitAbsoluteYearMap)
    statisticalReturn.profitAbsoluteYearMap = data.profitAbsoluteYearMap;
  if (data.profitByPeriodMap)
    statisticalReturn.profitByPeriodMap = data.profitByPeriodMap;
  if (data.buyDateByYearMap)
    statisticalReturn.buyDateByYearMap = data.buyDateByYearMap;
  if (data.sellDateByYearMap)
    statisticalReturn.sellDateByYearMap = data.sellDateByYearMap;
  if (data.maxRiseByYearMap)
    statisticalReturn.maxRiseByYearMap = data.maxRiseByYearMap;
  if (data.maxDropByYearMap)
    statisticalReturn.maxDropByYearMap = data.maxDropByYearMap;
  if (data.buyPriceYearMap)
    statisticalReturn.buyPriceYearMap = data.buyPriceYearMap;
  if (data.sellPriceYearMap)
    statisticalReturn.sellPriceYearMap = data.sellPriceYearMap;
  if (data.dividendPaymentsYearMap)
    statisticalReturn.dividendPaymentsYearMap = data.dividendPaymentsYearMap;
  if (data.winRatio)
    statisticalReturn.winRatio = data.winRatio;
  if (data.averageMaxRise)
    statisticalReturn.averageMaxRise = data.averageMaxRise;
  if (data.averageMaxDrop)
    statisticalReturn.averageMaxDrop = data.averageMaxDrop;
  if (data.highestMaxRise)
    statisticalReturn.highestMaxRise = data.highestMaxRise;
  if (data.highestMaxDrop)
    statisticalReturn.highestMaxDrop = data.highestMaxDrop;

  return statisticalReturn;
}


export function convertToStatisticalSimulationResult(data: DocumentData): StatisticalSimulationResult {
  if (!data?.transactionList || !data?.exchangeShortNames)
    throw new Error(`Invalid simulationResult. Data is missing: data?.transactionList=${data?.transactionList}, data?.exchangeShortNames=${data?.exchangeShortNames}`);

  const simulationResult: StatisticalSimulationResult = {
    transactionList: data.transactionList,
    exchangeShortNames: data.exchangeShortNames,
  };

  if (data.year !== undefined)
    simulationResult.year = data.year;
  if (data.seedCapital !== undefined)
    simulationResult.seedCapital = data.seedCapital;
  if (data.finalCapital !== undefined)
    simulationResult.finalCapital = data.finalCapital;
  if (data.roi !== undefined)
    simulationResult.roi = data.roi;
  if (data.minAverageRelativeProfit !== undefined)
    simulationResult.minAverageRelativeProfit = data.minAverageRelativeProfit;
  if (data.maxHoldingDuration !== undefined)
    simulationResult.maxHoldingDuration = data.maxHoldingDuration;
  if (data.minDataYears !== undefined)
    simulationResult.minDataYears = data.minDataYears;
  if (data.maxCapitalPerTrade !== undefined)
    simulationResult.maxCapitalPerTrade = data.maxCapitalPerTrade;
  if (data.minPricePerStock !== undefined)
    simulationResult.minPricePerStock = data.minPricePerStock;
  if (data.creationDate)
    simulationResult.creationDate = data.creationDate;

  return simulationResult;
}

export function convertToExchange(data: DocumentData): Exchange {
  if (!data?.name || !data?.shortName)
    throw new Error(`Invalid exchange. Data is missing: data?.name=${data?.name}, data?.code=${data?.shortName}`);

  const exchange: Exchange = {
    name: data.name,
    shortName: data.shortName,
    ...data,
  };

  if (data.symbolCountsByNameMap)
    exchange.symbolCountsByNameMap = data.symbolCountsByNameMap;

  return exchange;
}


export function convertToStatisticalTradingPlan(data: DocumentData, planUid: string): StatisticalTradingPlan {
  if (!data?.name || !data?.typeValue || data?.seedCapital === undefined || data?.maxCapitalPerTrade === undefined || !data?.currency)
    throw new Error(`Invalid StatisticalTradingPlan. Data is missing: data?.name=${data?.name}, data?.typeValue=${data?.typeValue}, data?.seedCapital=${data?.seedCapital}, data?.maxCapitalPerTrade=${data?.maxCapitalPerTrade}, data?.currency=${data?.currency}`);

  const statisticalTradingPlan: StatisticalTradingPlan = {
    uid: planUid,
    name: data.name,
    typeValue: data.typeValue,
    seedCapital: data.seedCapital,
    maxCapitalPerTrade: data.maxCapitalPerTrade,
    currency: data.currency,
    ...data,
  };
  return statisticalTradingPlan;
}


