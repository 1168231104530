import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {selectCachedExchanges, selectSymbolSearchExchangeShortName, selectSymbolSearchSearchTerm} from '../../../store/stock.selectors';
import {Subject, timer} from 'rxjs';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {StatisticalTradingService} from '../../../statistical-trading/statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {debounce} from 'rxjs/operators';

@Component({
  selector: 'app-stock-search-panel',
  templateUrl: './stock-search-panel.component.html',
  styleUrls: ['./stock-search-panel.component.scss'],
})
export class StockSearchPanelComponent extends BaseComponent implements OnInit {
  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  searchTerm$ = this.store.select(selectSymbolSearchSearchTerm);
  selectedExchange$ = this.store.select(selectSymbolSearchExchangeShortName);
  exchanges$ = this.store.select(selectCachedExchanges);

  searchInput = new Subject<string>();

  @Input() layout: 'full' | 'toolbar' = 'full';

  @Input() navigateToSearch = false;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.searchInput.pipe(
        debounce(() => timer(500)))
        .subscribe((searchTerm) => this.setSearchTermAndSearch(searchTerm));
  }

  selectExchangeAndSearch(exchangeShortName?: string) {
    this.statisticalTradingService.updateExchangeShortName(exchangeShortName);
    this.search();
  }

  search() {
    if (this.navigateToSearch)
      this.statisticalTradingService.navigateToSymbolSearch();
    this.statisticalTradingService.fetchSearchResults();
  }

  setSearchTermAndSearch(searchTerm: string) {
    this.statisticalTradingService.updateSearchTerm(searchTerm);
    this.search();
  }
}
