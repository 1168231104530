<div class="parent-component">
  <app-watch-list-star [symbolCode]="symbolCode" [statisticalReturnUid]="statisticalReturnUid" [floatTopRight]="true"></app-watch-list-star>

  <ng-container *ngIf="alerts && alerts.length > 0">
    <div class="mb-3">
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
    </div>
  </ng-container>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>


  <div *ngIf="!symbol">
    <h1 [ngClass]="user ? 'paragraph-headline leave-room-for-star' : 'paragraph-headline'">
      <a [routerLink]="['..']">
        <app-symbol-descriptor [nameOnly]="true" [symbolCode]="symbolCode"></app-symbol-descriptor>
      </a>
    </h1>
    <p i18n="unknown symbol paragraph">No data is available for this symbol.</p>
  </div>

  <div *ngIf="symbol">
    <h1 [ngClass]="user ? 'paragraph-headline leave-room-for-star' : 'paragraph-headline'">
      <a [routerLink]="['..']">
        <app-symbol-descriptor [nameOnly]="true" [symbol]="symbol"></app-symbol-descriptor>
      </a>
    </h1>


    <ng-container *ngIf="statisticalReturn as statRet; else noStatisticalReturn">

      <h2 class="paragraph-headline-2" i18n>Statistical return for {{getFormattedBuyDate()}} - {{getFormattedSellDate()}}</h2>

      <div *ngIf="user">
        <button [matMenuTriggerFor]="actionsMenu" mat-raised-button color="primary">
          <mat-icon aria-hidden="true" class="mr-1">event</mat-icon>
          <span i18n>Add to trading plan</span>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item [routerLink]="['/statistical-trading','planner','plans','create']">
            <mat-icon>add</mat-icon>
            <span i18n>Create new plan</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="addToPlan(plan)" *ngFor="let plan of plans" [disabled]="planContainsStatisticalReturn(plan)">
            <mat-icon>event</mat-icon>
            <ng-container *ngIf="planContainsStatisticalReturn(plan)">
              [<span i18n>Already in plan</span>]
            </ng-container>
            <span>{{plan.name}}</span>
          </button>
        </mat-menu>
      </div>

      <div class="row my-3 quick-info-view">
        <!-- First column -->
        <div class="col vertical-cards-column">
          <!-- Period -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Period</h5>
                <div *ngIf="statRet.buyDay && statRet.buyMonth">
                  <h6 i18n matTooltip="The day and month of the year, when the stock has to be bought for this statistical return" i18n-matTooltip>Buy date</h6>
                  <p class="card-text">{{getFormattedBuyDate()}}</p>
                </div>
                <div class="mt-2" *ngIf="statRet.sellDay && statRet.sellMonth">
                  <h6 i18n matTooltip="The day and month of the year, when the stock has to be sold for this statistical return" i18n-matTooltip>Sell date</h6>
                  <p class="card-text">{{getFormattedSellDate()}}</p>
                </div>
                <div class="mt-2" *ngIf="statRet.duration != undefined">
                  <h6 i18n matTooltip="The amount of days between buy date and sell date" i18n-matTooltip>Holding period</h6>
                  <p class="card-text">{statRet.duration, plural, =0 {0 days} =1 {1 day} other {{{statRet.duration}} days}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Number of data years -->
          <div class="col single-card-holder" *ngIf="statRet.profitByYearMap !== undefined">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n matTooltip="The number of years for which we have data" i18n-matTooltip>Data years</h5>
                <p class="card-text">{{getNumberOfDataYears(statRet)}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Second column -->
        <div class="col vertical-cards-column">
          <!-- Average profit -->
          <div class="col single-card-holder" *ngIf="statRet.averageRelativeProfit !== undefined">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n matTooltip="The average relative annual profit if the symbol is bought each year on the buy date and sold on the sell date" i18n-matTooltip>Average profit</h5>
                <p class="card-text">
                  <app-percentage-view [value]="statRet.averageRelativeProfit"></app-percentage-view>
                  <span [matTooltip]="getTruncationTooltip()">
                  <span> (</span>
                  <span i18n>without annual truncation</span>
                  <span>: <app-percentage-view [value]="nonTruncatedProfit"></app-percentage-view></span>
                  <span>)</span>
                 </span>
                </p>
              </div>
            </div>
          </div>
          <!-- Win ratio -->
          <div class="col single-card-holder" *ngIf="statRet.winRatio !== undefined">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n matTooltip="The win ratio (number of profitable years / total years)" i18n-matTooltip>Win ratio</h5>
                <p class="card-text">
                  <app-percentage-view [value]="statRet.winRatio"></app-percentage-view>
                </p>
              </div>
            </div>
          </div>
          <!-- Worst year -->
          <div class="col single-card-holder" *ngIf="statRet.lowestYearlyProfit !== undefined">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n matTooltip="The worst annual profit in the review period" i18n-matTooltip>Worst year</h5>
                <p class="card-text">
                  <app-percentage-view [value]="statRet.lowestYearlyProfit"></app-percentage-view>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Third column -->
        <div class="col vertical-cards-column">
          <!-- Max. rises and max. drops -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Max. rises and max. drops</h5>
                <div *ngIf="statRet.averageMaxRise">
                  <h6 i18n-matTooltip matTooltip="The average max. rise within the buy and sell period relative to the buy price over all years" i18n>Avg. max. rise</h6>
                  <p class="card-text">
                    <app-percentage-view [value]="statRet.averageMaxRise"></app-percentage-view>
                  </p>
                </div>
                <div class="mt-2" *ngIf="statRet.averageMaxDrop">
                  <h6 i18n-matTooltip matTooltip="The average max. drop within the buy and sell period relative to the buy price over all years" i18n>Avg. max. drop</h6>
                  <p class="card-text">
                    <app-percentage-view [value]="statRet.averageMaxDrop"></app-percentage-view>
                  </p>
                </div>
                <div class="mt-2" *ngIf="statRet.highestMaxRise">
                  <h6 i18n-matTooltip matTooltip="The highest rise within the buy and sell period relative to the buy price of all years" i18n>Highest rise</h6>
                  <p class="card-text">
                    <app-percentage-view [value]="statRet.highestMaxRise"></app-percentage-view>
                  </p>
                </div>
                <div class="mt-2" *ngIf="statRet.highestMaxDrop">
                  <h6 i18n-matTooltip matTooltip="The highest drop within the buy and sell period relative to the buy price of all years" i18n>Highest drop</h6>
                  <p class="card-text">
                    <app-percentage-view [value]="statRet.highestMaxDrop"></app-percentage-view>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fourth column -->
        <div class="col vertical-cards-column">
          <!-- Exchange -->
          <div class="col single-card-holder" *ngIf="statRet.exchangeShortName">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" matTooltip="The stock exchange on which the stock is traded." i18n-matTooltip i18n>Exchange</h5>
                <p class="card-text">
                  <app-exchange-descriptor [exchangeShortName]="statRet.exchangeShortName" mode="nameAndCode"></app-exchange-descriptor>
                </p>
              </div>
            </div>
          </div>
          <!-- Currency -->
          <div class="col single-card-holder" *ngIf="statRet.currency">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" matTooltip="The currency in which the stock is traded" i18n-matTooltip i18n>Currency</h5>
                <p class="card-text">
                  {{statRet.currency}}
                </p>
              </div>
            </div>
          </div>
          <!-- Creation Date -->
          <div class="col single-card-holder" *ngIf="statRet.creationDate !== undefined">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n matTooltip="The date, when this statistical return was calculated" i18n-matTooltip>Calculation date</h5>
                <p class="card-text">{{utilService.getDate(statRet.creationDate) | moment:'LL'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <app-company-profile-view-accordion [symbolCode]="symbolCode" [symbol]="symbol" [companyProfileIsShown]="false" (symbolRefreshed)="onSymbolRefreshed($event)"></app-company-profile-view-accordion>
      </div>

      <div class="row">
        <div class="col"><h3 class="paragraph-headline-3" i18n>Annual profit by year</h3>

          <app-statistical-return-detail-table [statRet]="statRet"></app-statistical-return-detail-table>
        </div>
        <div class="col"><h3 class="paragraph-headline-3" i18n>Annual profit by period</h3>

          <app-statistical-return-periods-table [statRet]="statRet"></app-statistical-return-periods-table>
        </div>
      </div>


      <div class="annual-profit-by-year-container">
      </div>
    </ng-container>

    <ng-template #noStatisticalReturn>
      <ng-container *ngIf="!searching">
        <div class="alert alert-info" i18n>We could not find a statistical return for this URL.</div>
      </ng-container>
    </ng-template>

    <h3 class="paragraph-headline-3" i18n>All statistical returns for this symbol</h3>
    <app-statistical-returns-table-loader [symbolCode]="symbolCode" [symbol]="symbol"></app-statistical-returns-table-loader>


  </div>
</div>

