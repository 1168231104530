import {Component, Input, OnInit} from '@angular/core';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';

@Component({
  selector: 'app-period-data',
  templateUrl: './period-data.component.html',
  styleUrls: ['./period-data.component.scss'],
})
export class PeriodDataComponent implements OnInit {

  @Input() period?: string;
  @Input() statRet?: StatisticalReturn;

  constructor() {
  }

  ngOnInit(): void {
  }

}
