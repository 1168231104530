import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {TableColumn} from '../../../shared/models/columnSettings.interface';
import Util from '../../../shared/util';
import {TableColumnService} from '../../table-column.service';

@Component({
  selector: 'app-drag-drop-columns-list',
  templateUrl: './drag-drop-columns-list.component.html',
  styleUrls: ['./drag-drop-columns-list.component.scss'],
})
export class DragDropColumnsListComponent implements OnInit {

  @Input() visible: TableColumn[] = [];
  @Input() hidden: TableColumn[] = [];

  @Input() all: TableColumn[] = [];

  @Output() save = new EventEmitter<TableColumn[]>();

  constructor(
      private tableColumnService: TableColumnService) {
  }

  ngOnInit(): void {
    this.determineHiddenColumns();
  }

  public onResetToDefault() {
    this.visible = this.tableColumnService.filterDefaultVisible(this.all);
    this.determineHiddenColumns();
  }

  public onSave() {
    this.save.emit(this.visible);
  }


  private determineHiddenColumns() {
    this.hidden = [...this.all.filter(it => !Util.arrayContains(this.visible, it))];
  }

  drop(event: CdkDragDrop<TableColumn[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
      );
    }
  }

}
