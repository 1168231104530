import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './admin.component';
import {AdminOverviewComponent} from './admin-overview/admin-overview.component';
import {FaqsEditorComponent} from './faqs-editor/faqs-editor.component';
import {FaqsEditorFormComponent} from './faqs-editor/faqs-editor-form/faqs-editor-form.component';
import {NoFaqShownComponentComponent} from './faqs-editor/no-faq-shown-component/no-faq-shown-component.component';
import {SchedulesEditorComponent} from './schedules-editor/schedules-editor.component';
import {SchedulesEditorFormComponent} from './schedules-editor/schedules-editor-form/schedules-editor-form.component';
import {NoScheduleShownComponent} from './schedules-editor/no-schedule-shown/no-schedule-shown-component';
import {CurrenciesEditorComponent} from './currencies-editor/currencies-editor.component';
import {NoCurrencyShownComponent} from './currencies-editor/no-currency-shown/no-currency-shown.component';
import {CurrenciesEditorFormComponent} from './currencies-editor/currencies-editor-form/currencies-editor-form.component';
import {ToolsComponent} from './tools/tools.component';

const adminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
      {path: 'overview', component: AdminOverviewComponent},
      {
        path: 'schedules', component: SchedulesEditorComponent, children: [
          {path: '', component: NoScheduleShownComponent},
          {path: 'new', component: SchedulesEditorFormComponent},
          {path: ':uid', component: SchedulesEditorFormComponent},
        ],
      },
      {
        path: 'faqs', component: FaqsEditorComponent, children: [
          {path: '', component: NoFaqShownComponentComponent},
          {path: 'new', component: FaqsEditorFormComponent},
          {path: ':uid', component: FaqsEditorFormComponent},
        ],
      },
      {
        path: 'currencies', component: CurrenciesEditorComponent, children: [
          {path: '', component: NoCurrencyShownComponent},
          {path: 'new', component: CurrenciesEditorFormComponent},
          {path: ':uid', component: CurrenciesEditorFormComponent},
        ],
      },
      {path: 'tools', component: ToolsComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
