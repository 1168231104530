import {Component, Input, OnInit} from '@angular/core';
import {Exchange} from '../../models/exchange.interface';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {ExchangeService} from '../../services/exchange.service';

@Component({
  selector: 'app-exchange-descriptor',
  templateUrl: './exchange-descriptor.component.html',
  styleUrls: ['./exchange-descriptor.component.scss'],
})
export class ExchangeDescriptorComponent extends BaseComponent implements OnInit {

  @Input() mode: 'codeOnly' | 'nameOnly' | 'nameAndCode' = 'nameOnly';

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private exchangeService: ExchangeService) {
    super(authService, userService, store);
  }

  _exchange?: Exchange = undefined;

  get exchange(): Exchange | undefined {
    return this._exchange;
  }

  @Input() set exchange(exchange: Exchange | undefined) {
    this._exchange = exchange;
  }

  _exchangeShortName?: string = undefined;

  get exchangeShortName(): string | undefined {
    return this._exchangeShortName;
  }

  @Input() set exchangeShortName(exchangeShortName: string | undefined) {
    this._exchangeShortName = exchangeShortName;
    if (exchangeShortName)
      this.fetchExchange(exchangeShortName);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private fetchExchange(exchangeShortName: string) {
    this.exchangeService.getExchange(exchangeShortName).then(exchange => {
      this.exchange = exchange;
    });
  }

}
