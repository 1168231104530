<ng-container *ngIf="value !== undefined; else noValue">
  <span *ngIf="value >= 0">+ </span>
  <span *ngIf="value < 0">- </span>
  <ng-container *ngIf="mode=='absolute'">
    <app-money [value]="value" [currencyId]="currencyId"></app-money>
  </ng-container>
  <ng-container *ngIf="mode=='relative'">
    <app-percentage-view [value]="value!!"></app-percentage-view>
  </ng-container>
</ng-container>

<ng-template #noValue>
  <span i18n>Unknown</span>
</ng-template>
