<app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<div *ngIf="symbol && !symbol.activelyTrading" class="alert alert-primary mt-3" i18n>This stock does not get traded actively.</div>

<div class="row my-3 company-profile-view" *ngIf="symbol">
  <!-- First column -->
  <div class="col vertical-cards-column">
    <!-- Company name, description and image -->
    <div class="col single-card-holder" *ngIf="symbol.name || symbol.description">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" *ngIf="symbol.name">{{symbol.name}}</h5>
          <p class="card-text" *ngIf="symbol.website">
            <a [href]="symbol.website" class="icon-with-text" target="_blank">
              {{symbol.website}}
              <mat-icon class="ml-1">open_in_new</mat-icon>
            </a>
          </p>
          <p class="card-text justify" *ngIf="symbol.description">
            {{symbol.description|shorten:200:true}}
            <span *ngIf="symbol.description.length>200"><a (click)="showCompanyDescription();$event.preventDefault()" i18n href="#">Show more</a></span>
          </p>
          <img *ngIf="symbol.image && !symbol.defaultImage" [src]="symbol.image" class="card-img-bottom company-img" alt="Company image" i18n-alt (error)="hideImage()" #companyImage>
        </div>
      </div>
    </div>
    <!-- Stock type -->
    <div class="col single-card-holder">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The type of the stock (Regular stock, ETF, Fund or ADR (American Depository Receipt))" i18n-matTooltip>Stock type</h5>
          <p class="card-text">
            <app-stock-type [type]="symbol.type"></app-stock-type>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Second column -->
  <div class="col vertical-cards-column">
    <!-- ISIN, Symbol, CIK, CUSIP -->
    <div class="col single-card-holder" *ngIf="symbol.isin || symbol.code || symbol.cik || symbol.cusip">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Codes</h5>
          <div *ngIf="symbol.isin">
            <h6 i18n matTooltip="The International Securities Identification Number (ISIN) of the stock" i18n-matTooltip>ISIN</h6>
            <p class="card-text icon-with-text">{{symbol.isin}}
              <a href="#" target="_blank" (click)="copyToClipboard(symbol.isin);$event.preventDefault()">
                <mat-icon class="ml-1" matTooltip="Click to copy to clipboard" i18n-matTooltip>content_copy</mat-icon>
              </a>
            </p>
          </div>
          <div class="mt-2" *ngIf="symbol.code">
            <h6 i18n matTooltip="The stock symbol" i18n-matTooltip>Symbol</h6>
            <p class="card-text icon-with-text">{{symbol.code}}
              <a href="#" target="_blank" (click)="copyToClipboard(symbol.code);$event.preventDefault()">
                <mat-icon class="ml-1" matTooltip="Click to copy to clipboard" i18n-matTooltip>content_copy</mat-icon>
              </a>
            </p>
          </div>
          <div class="mt-2" *ngIf="symbol.cik">
            <h6 i18n matTooltip="The Central Index Key (CIK) of the company" i18n-matTooltip>CIK</h6>
            <p class="card-text icon-with-text">{{symbol.cik}}
              <a href="#" target="_blank" (click)="copyToClipboard(symbol.cik);$event.preventDefault()">
                <mat-icon class="ml-1" matTooltip="Click to copy to clipboard" i18n-matTooltip>content_copy</mat-icon>
              </a>
            </p>
          </div>
          <div class="mt-2" *ngIf="symbol.cusip">
            <h6 i18n matTooltip="The Committee on Uniform Security Identification Procedures (CUSIP) number of the stock" i18n-matTooltip>CUSIP</h6>
            <p class="card-text icon-with-text">{{symbol.cusip}}
              <a href="#" target="_blank" (click)="copyToClipboard(symbol.cusip);$event.preventDefault()">
                <mat-icon class="ml-1" matTooltip="Click to copy to clipboard" i18n-matTooltip>content_copy</mat-icon>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Current price, change and range -->
    <div class="col single-card-holder" *ngIf="symbol.price || symbol.changes || symbol.range">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n>Price</h5>
          <div class="card-text" *ngIf="symbol.price">
            <h6 i18n matTooltip="The current stock price. We only show new values every ten minutes." i18n-matTooltip>Current price</h6>
            <p class="card-text">
              <app-money [value]="symbol.price" [currencyId]="symbol.currency"></app-money>
            </p>
          </div>
          <div class="mt-2" *ngIf="symbol.changes">
            <h6 i18n matTooltip="The change in stock price from the previous day's closing price" i18n-matTooltip>Change since yesterday</h6>
            <p class="card-text">
              <app-money [value]="symbol.changes" [currencyId]="symbol.currency"></app-money>
            </p>
          </div>
          <div class="mt-2" *ngIf="symbol.range">
            <h6 i18n>52-Week Range </h6>
            <p class="card-text">
              <app-money [value]="getRangeLowerEnd(symbol.range)" [currencyId]="symbol.currency"></app-money>
              -
              <app-money [value]="getRangeUpperEnd(symbol.range)" [currencyId]="symbol.currency"></app-money>
            </p>
          </div>

        </div>
      </div>
    </div>
    <!-- Exchange -->
    <div class="col single-card-holder" *ngIf="symbol.exchangeShortName">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" matTooltip="The stock exchange on which the stock is traded." i18n-matTooltip i18n>Exchange</h5>
          <p class="card-text">
            <app-exchange-descriptor [exchangeShortName]="symbol.exchangeShortName"></app-exchange-descriptor>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Third column -->
  <div class="col vertical-cards-column">
    <!-- Market Cap -->
    <div class="col single-card-holder" *ngIf="symbol.mktCapUsd">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The market capitalization" i18n-matTooltip>Market Cap</h5>
          <p class="card-text">
            <app-money [value]="symbol.mktCapUsd" currencyId="USD" [bigMoney]="true"></app-money>
          </p>
        </div>
      </div>
    </div>
    <!-- Average trading volume -->
    <div class="col single-card-holder" *ngIf="symbol.volAvg">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The average daily trading volume of the stock" i18n-matTooltip>Average trading volume</h5>
          <p class="card-text">
            <span i18n>{{symbol.volAvg | number:'1.0-0':locale}} Units</span>
            -
            <app-money *ngIf="symbol && symbol.volAvg && symbol.price && symbol.currency" [value]="symbol.volAvg * symbol.price" [currencyId]="symbol.currency" [bigMoney]="true"></app-money>
            <ng-container *ngIf="symbol.currency !== baseCurrency">-
              <app-money *ngIf="symbol && symbol.volAvgBaseCurrency" [value]="symbol.volAvgBaseCurrency" [currencyId]="baseCurrency" [bigMoney]="true"></app-money>
            </ng-container>
          </p>
        </div>
      </div>
    </div>
    <!-- Beta -->
    <div class="col single-card-holder" *ngIf="symbol.beta">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="Beta is a measure of a stock's volatility in relation to the overall market. A beta of 1 indicates that the stock's price will move with the market, while a beta greater than 1 implies greater volatility and a beta less than 1 suggests lower volatility than the overall market." i18n-matTooltip>Beta</h5>
          <p class="card-text">
            {{symbol.beta | number:'1.0-3':locale}}
          </p>
        </div>
      </div>
    </div>
    <!-- Full time employees -->
    <div class="col single-card-holder" *ngIf="symbol.fullTimeEmployees">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The number of full-time employees at the company" i18n-matTooltip>Full time employees</h5>
          <p class="card-text">
            {{symbol.fullTimeEmployees | number:'1.0-0':locale}}</p>
        </div>
      </div>
    </div>
    <!-- Sector -->
    <div class="col single-card-holder" *ngIf="symbol.sector">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The sector in which the company operates" i18n-matTooltip>Sector</h5>
          <p class="card-text">{{symbol.sector}}</p>
        </div>
      </div>
    </div>
    <!-- Industry -->
    <div class="col single-card-holder" *ngIf="symbol.industry">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The industry in which the company operates" i18n-matTooltip>Industry</h5>
          <p class="card-text">{{symbol.industry}}</p>
        </div>
      </div>
    </div>
    <!-- IPO date -->
    <div class="col single-card-holder" *ngIf="symbol.ipoDate">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" matTooltip="The date the company went public" i18n-matTooltip i18n>IPO date</h5>
          <p class="card-text">{{utilService.getDate(symbol.ipoDate) | moment:'LL'}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Fourth column -->
  <div class="col vertical-cards-column">
    <!-- DCF, DCF diff -->
    <div class="col single-card-holder" *ngIf="symbol.dcf || symbol.dcfDiff">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n>Discounted Cash Flow
          </h5>
          <div *ngIf="symbol.dcf">
            <h6 i18n matTooltip="DCF stands for Discounted Cash Flow and refers to a valuation method used to estimate the value of an investment based on its expected future cash flows. This method involves estimating future cash flows, discounting them to their present value using a discount rate, and then summing them up to determine the net present value (NPV) of the investment." i18n-matTooltip>DCF</h6>
            <p class="card-text">
              <app-money [value]="symbol.dcf" [currencyId]="symbol.currency"></app-money>
            </p>
          </div>
          <div class="mt-2" *ngIf="symbol.dcfDiff">
            <h6 i18n matTooltip="The difference between the current stock price and the discounted cash flow (DCF) value" i18n-matTooltip>DCF difference</h6>
            <p class="card-text">
              <app-money [value]="symbol.dcfDiff" [currencyId]="symbol.currency"></app-money>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- CEO -->
    <div class="col single-card-holder" *ngIf="symbol.ceo">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n matTooltip="The name of the company's CEO" i18n-matTooltip>CEO</h5>
          <p class="card-text">{{symbol.ceo}}</p>
        </div>
      </div>
    </div>
    <!-- Contact info -->
    <div class="col single-card-holder" *ngIf="symbol.address || symbol.city || symbol.zip || symbol.state || symbol.country || symbol.phone">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n>Contact information</h5>
          <p class="card-text">
            <app-address-table [address]="createAddress(symbol)" [phone]="symbol.phone"></app-address-table>
          </p>
        </div>
      </div>
    </div>
    <!-- Price date -->
    <div class="col single-card-holder">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" matTooltip="The date when the price and market data of this symbol were retrieved" i18n-matTooltip i18n>Price date</h5>
          <p class="card-text" *ngIf="symbol.priceDate">
            <app-date-view [date]="utilService.getDate(symbol.priceDate)"></app-date-view>
          </p>
          <p class="card-text small text-align-end" *ngIf="isRefreshCooldownExpired()">
            <a (click)="refresh();$event.preventDefault()" href="#" target="_blank" i18n>Refresh</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

