import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UtilService} from '../../../../shared/util.service';
import {TitleService} from '../../../../shared/services/title.service';
import {StatisticalTradingPlan} from '../../../../shared/models/statisticalTradingPlan.interface';
import {PlannerService} from '../../planner.service';
import {PlanOptimizationStartData} from '../../helper-components/statistical-trading-plan-actions-menu/statistical-trading-plan-actions-menu.component';

@Component({
  selector: 'app-statistical-trading-plans-list',
  templateUrl: './statistical-trading-plans-list.component.html',
  styleUrls: ['./statistical-trading-plans-list.component.scss'],
})
export class StatisticalTradingPlansListComponent extends BaseComponent implements OnInit {
  private readonly spinnerKeyFetchPlans = 'fetchPlans';
  private readonly spinnerKeyOptimizePlan = 'optimizePlan';

  plans: StatisticalTradingPlan[] = [];

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private utilService: UtilService,
    private titleService: TitleService,
    private plannerService: PlannerService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadUserPlans();
  }

  private loadUserPlans() {
    if (this.user?.uid) {
      this.addLoadingSpinnerMessage(this.spinnerKeyFetchPlans, $localize`Fetching your plans...`);
      this.plannerService.fetchUserPlans(this.user.uid).then(wrapper => {
        this.removeLoadingSpinnerMessage(this.spinnerKeyFetchPlans);
        if (wrapper.success)
          this.plans = wrapper.data ? wrapper.data : [];
        if (wrapper.errorMessage)
          this.addError(wrapper.errorMessage);
      });
    }
  }

  planDeleted(planUid: string) {
    this.plans = this.plans.filter(it => it.uid != planUid);
  }

  planDeletionFailed(errorMessage: string) {
    this.addError(errorMessage);
  }

  optimizationStarted(startData: PlanOptimizationStartData) {
    this.clearAlerts();
    this.addLoadingSpinnerMessage(this.spinnerKeyOptimizePlan, $localize`Optimizing your plan...`);
    startData.optimizedPlanPromise.then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyOptimizePlan);
      if (wrapper.success && wrapper.data) {
        this.plannerService.showOptimizationFinishedSnackbar(startData.planName, startData.planUid);
        this.plans = [...this.plans];
        let oldPlan = this.plans.find(plan => plan.uid === startData.planUid);
        if (oldPlan) {
          let oldPlanIndex = this.plans.indexOf(oldPlan);
          this.plans[oldPlanIndex] = wrapper.data;
        } else {
          this.plans.push(wrapper.data);
        }
      }
      if (wrapper.errorMessage && !wrapper.success)
        this.addError($localize`Optimizing plan '${startData.planName}' failed: ` + wrapper.errorMessage);
    });
  }
}
