import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StatisticalReturn} from '../../../shared/models/statisticalReturn.interface';
import {TableColumn} from '../../../shared/models/columnSettings.interface';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {StockService} from '../../../shared/services/stock.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StatisticalTradingService} from '../../statistical-trading.service';
import Util from '../../../shared/util';
import Locale from '../../../shared/services/locale';
import {Symbol} from '../../../shared/models/symbol.interface';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-statistical-return-data-view',
  templateUrl: './statistical-return-data-view.component.html',
  styleUrls: ['./statistical-return-data-view.component.scss'],
})
export class StatisticalReturnDataViewComponent {

  public baseCurrency = environment.baseCurrency;

  locale = Locale.numberFormatLocale();

  @Input() column?: TableColumn;
  @Input() statRet?: StatisticalReturn;
  @Input() symbol?: Symbol | undefined;

  @Input() showExchange = false;
  @Input() nameMaxLength: number | undefined = undefined;
  @Output() onExchangeClicked = new EventEmitter<string>();

  constructor(
      authService: AuthService,
      userService: UserService,
      stockService: StockService,
      store: Store<AppState>) {
  }

  ngOnInit(): void {
  }


  getFormattedBuyDate(statisticalReturn: StatisticalReturn): string {
    return StatisticalTradingService.getFormattedDate(statisticalReturn?.buyDay, statisticalReturn?.buyMonth);
  }

  getFormattedSellDate(statisticalReturn: StatisticalReturn): string {
    return StatisticalTradingService.getFormattedDate(statisticalReturn?.sellDay, statisticalReturn?.sellMonth);
  }

  onExchangeClick(exchangeShortName: string) {
    this.onExchangeClicked.emit(exchangeShortName);
  }


  getNumberOfDataYears(statRet: StatisticalReturn): string {
    return Util.getNumberOfDataYearsString(statRet);
  }
}
