import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../shared/components/base-dialog/base-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  selectBestStatisticalReturnSearchMinAverageRelativeProfit,
  selectUpcomingStatisticalReturnSearchMinAverageRelativeProfit,
} from '../../../../store/stock.selectors';
import {takeUntil} from 'rxjs/operators';
import {StatisticalReturnsSearchParams} from '../../../../shared/models/statisticalReturnsSearchParams.interface';
import {mergeUpdateBestStatisticalReturnSearchParams, mergeUpdateUpcomingStatisticalReturnSearchParams} from '../../../../store/stock.actions';

@Component({
  selector: 'app-min-average-relative-profit-edit-dialog',
  templateUrl: './min-average-relative-profit-edit-dialog.component.html',
  styleUrls: ['./min-average-relative-profit-edit-dialog.component.scss'],
})
export class MinAverageRelativeProfitEditDialogComponent extends BaseDialogComponent implements OnInit {

  form: FormGroup = this.createForm();

  minAverageRelativeProfit$?: Observable<number | undefined>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    dialogRef: MatDialogRef<MinAverageRelativeProfitEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) {
    super(authService, userService, store, dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.viewType === 'upcoming') {
      this.minAverageRelativeProfit$ = this.store.select(selectUpcomingStatisticalReturnSearchMinAverageRelativeProfit);
    }
    if (this.data.viewType === 'best') {
      this.minAverageRelativeProfit$ = this.store.select(selectBestStatisticalReturnSearchMinAverageRelativeProfit);
    }

    this.minAverageRelativeProfit$?.pipe(takeUntil(this.destroy$)).subscribe(minAverageRelativeProfit => {
      this.form.patchValue({minAverageRelativeProfit});
    });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      minAverageRelativeProfit: [''],
    });
  }

  saveMinAverageRelativeProfit(minAverageRelativeProfitString: string) {
    if (!minAverageRelativeProfitString)
      return this.clearMinAverageRelativeProfit();
    const minAverageRelativeProfit = parseInt(minAverageRelativeProfitString);
    if (isNaN(minAverageRelativeProfit))
      return;
    let searchParams: StatisticalReturnsSearchParams = {minAverageRelativeProfit};
    this.mergeUpdateSearchParams(searchParams);
  }

  clearMinAverageRelativeProfit() {
    let searchParams: StatisticalReturnsSearchParams = {minAverageRelativeProfit: undefined};
    this.mergeUpdateSearchParams(searchParams);
  }

  private mergeUpdateSearchParams(searchParams: StatisticalReturnsSearchParams) {
    if (this.data.viewType === 'upcoming')
      this.store.dispatch(mergeUpdateUpcomingStatisticalReturnSearchParams({searchParams}));
    if (this.data.viewType === 'best')
      this.store.dispatch(mergeUpdateBestStatisticalReturnSearchParams({searchParams}));
  }
}
