import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StatisticalTradingPlan} from '../../../../shared/models/statisticalTradingPlan.interface';
import {StatisticalTradeStep} from '../../../../shared/models/statisticalTradeStep.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StatisticalTrade, StatisticalTradeProperties} from '../../../../shared/models/statisticalTrade.interface';
import Util from '../../../../shared/util';
import {PlannerService} from '../../planner.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-statistical-trading-plan-trade-edit',
  templateUrl: './statistical-trading-plan-trade-edit.component.html',
  styleUrls: ['./statistical-trading-plan-trade-edit.component.scss'],
})
export class StatisticalTradingPlanTradeEditComponent extends BaseComponent implements OnInit {

  @Input() plan?: StatisticalTradingPlan;
  @Input() step?: StatisticalTradeStep;

  @Output() onSuccessfulEdit = new EventEmitter<StatisticalTradingPlan>();
  @Output() onEditFailed = new EventEmitter<string>();

  targetForm ?: FormGroup;
  actualForm ?: FormGroup;
  actual?: StatisticalTradeProperties;
  target?: StatisticalTradeProperties;
  trade?: StatisticalTrade;
  calculateDerivedValuesTrigger = new Subject<void>();
  bought = false;
  sold = false;

  constructor(
    authService: AuthService,
    userService: UserService,
    private formBuilder: FormBuilder,
    private plannerService: PlannerService,
    store: Store<AppState>) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.extractTargetAndActualProperties();

    this.createTargetPropertiesForm();
    this.createActualPropertiesForm();
  }

  private createTargetPropertiesForm() {
    if (!this.target) {
      this.addError($localize`Target trade properties are missing.`);
      return;
    }
    this.targetForm = this.createPropertiesForm(this.target, true);
  }

  private createActualPropertiesForm() {
    if (!this.actual) {
      this.addError($localize`Actual trade properties are missing.`);
      return;
    }
    this.actualForm = this.createPropertiesForm(this.actual);
  }

  private extractTargetAndActualProperties() {
    if (!this.plan || !this.step) {
      this.addError($localize`Invalid input data. Plan or step is undefined.`);
      return;
    }
    this.trade = this.plan.trades?.find(it =>
      this.step?.statisticalReturnUid === it.statisticalReturnUid && this.step?.symbolCode === it.symbolCode,
    );
    if (!this.trade) {
      this.addError($localize`Invalid input data. Trade could not be found in input data.`);
      return;
    }

    this.actual = this.trade.actual;
    this.target = this.trade.target;

    this.bought = this.trade.bought;
    this.sold = this.trade.sold;
  }


  private createPropertiesForm(properties: StatisticalTradeProperties, requiredValues: boolean = false): FormGroup {

    let validators = requiredValues ? [Validators.required] : [];

    return this.formBuilder.group({
      buyDate: [properties.buyDate ? Util.getDate(properties.buyDate) : '', validators],
      sellDate: [properties.sellDate ? Util.getDate(properties.sellDate) : '', validators],
      buyPrice: [properties.buyPrice ? properties.buyPrice : '', validators],
      sellPrice: [properties.sellPrice ? properties.sellPrice : '', validators],
      orderFeeAbsolute: [properties.orderFeeAbsolute ? properties.orderFeeAbsolute : 0],
      taxAbsolute: [properties.taxAbsolute ? properties.taxAbsolute : 0],
      profitRelativeGross: [{value: properties.profitRelativeGross ? properties.profitRelativeGross : '', disabled: true}, validators],
      profitRelativeNet: [{value: properties.profitRelativeNet ? properties.profitRelativeNet : '', disabled: true}, validators],
      profitAbsoluteGross: [{value: properties.profitAbsoluteGross ? properties.profitAbsoluteGross : '', disabled: true}, validators],
      profitAbsoluteNet: [{value: properties.profitAbsoluteNet ? properties.profitAbsoluteNet : '', disabled: true}, validators],
    });
  }

  save(): void {
    this.clearAlerts();

    if (!this.user?.uid) {
      this.addError($localize`You are not logged in.`);
      return;
    }
    if (!this.trade || !this.plan?.trades) {
      this.addError($localize`Invalid input data. Plan or step is undefined.`);
      return;
    }
    this.trade = {
      ...this.trade,
      actual: this.createProperties(this.actualForm?.getRawValue()),
      target: this.createProperties(this.targetForm?.getRawValue()),
      bought: this.bought,
      sold: this.sold,
    };
    if (this.datesAreSetAndBuyIsAfterSell(this.trade))
      return;
    const oldTrade = this.plan.trades?.find(it =>
      this.step?.statisticalReturnUid === it.statisticalReturnUid && this.step?.symbolCode === it.symbolCode,
    );
    if (!oldTrade) {
      this.addError($localize`The trade that you are trying to save can no longer be found in the plan.`);
      return;
    }
    let trades = Util.removeFromArray([...this.plan.trades], oldTrade);
    trades.push(this.trade);
    trades = trades.sort(PlannerService.tradesSortByDateComparator());
    this.plan = {...this.plan, trades};
    this.plannerService.savePlan(this.plan, this.user.uid, this.plan.uid).then(wrapper => {
      if (wrapper.success && wrapper.data) {
        this.onSuccessfulEdit.emit(wrapper.data);
      }
      if (wrapper.errorMessage)
        this.addError($localize`Saving failed. ` + wrapper.errorMessage);
    });
  }

  private createProperties(formValues: any) {
    let properties: StatisticalTradeProperties = {};
    if (!formValues)
      return properties;
    if (formValues.buyDate)
      properties = {...properties, buyDate: Util.getTimestamp(formValues.buyDate)};
    if (formValues.sellDate)
      properties = {...properties, sellDate: Util.getTimestamp(formValues.sellDate)};
    if (formValues.buyPrice)
      properties = {...properties, buyPrice: formValues.buyPrice};
    if (formValues.sellPrice)
      properties = {...properties, sellPrice: formValues.sellPrice};
    if (formValues.profitRelativeGross)
      properties = {...properties, profitRelativeGross: Util.fromPercentage(formValues.profitRelativeGross)};
    if (formValues.profitAbsoluteGross)
      properties = {...properties, profitAbsoluteGross: formValues.profitAbsoluteGross};
    if (formValues.profitRelativeNet)
      properties = {...properties, profitRelativeNet: Util.fromPercentage(formValues.profitRelativeNet)};
    if (formValues.profitAbsoluteNet)
      properties = {...properties, profitAbsoluteNet: formValues.profitAbsoluteNet};
    properties = {...properties, orderFeeAbsolute: formValues.orderFeeAbsolute ? formValues.orderFeeAbsolute : 0};
    properties = {...properties, taxAbsolute: formValues.taxAbsolute ? formValues.taxAbsolute : 0};
    return properties;
  }

  public copyBuyDate() {
    let sourceValue = this.targetForm?.value.buyDate;
    this.actualForm?.patchValue({buyDate: sourceValue});
    this.calculateDerivedValuesTrigger.next();
  }

  public copySellDate() {
    let sourceValue = this.targetForm?.value.sellDate;
    this.actualForm?.patchValue({sellDate: sourceValue});
    this.calculateDerivedValuesTrigger.next();
  }

  public copyBuyPrice() {
    let sourceValue = this.targetForm?.value.buyPrice;
    this.actualForm?.patchValue({buyPrice: sourceValue});
    this.calculateDerivedValuesTrigger.next();
  }

  public copySellPrice() {
    let sourceValue = this.targetForm?.value.sellPrice;
    this.actualForm?.patchValue({sellPrice: sourceValue});
    this.calculateDerivedValuesTrigger.next();
  }

  public copyOrderFee() {
    let sourceValue = this.targetForm?.value.orderFeeAbsolute;
    this.actualForm?.patchValue({orderFeeAbsolute: sourceValue});
    this.calculateDerivedValuesTrigger.next();
  }

  public copyTax() {
    let sourceValue = this.targetForm?.value.taxAbsolute;
    this.actualForm?.patchValue({taxAbsolute: sourceValue});
    this.calculateDerivedValuesTrigger.next();
  }

  public copyAllValues() {
    this.copyTax();
    this.copyBuyDate();
    this.copySellDate();
    this.copyOrderFee();
    this.copyBuyPrice();
    this.copySellPrice();
  }

  onBought(bought: boolean) {
    this.bought = bought;
  }

  onSold(sold: boolean) {
    this.sold = sold;
  }

  deleteActualValues(): void {
    this.actualForm?.reset();
    this.bought = false;
    this.sold = false;
  }

  deleteTargetValues(): void {
    this.targetForm?.reset();
  }

  private datesAreSetAndBuyIsAfterSell(trade: StatisticalTrade) {
    if (trade.actual?.buyDate && trade.actual?.sellDate) {
      const buyDate = Util.getDate(trade.actual.buyDate).getTime();
      const sellDate = Util.getDate(trade.actual.sellDate).getTime();
      if (buyDate > sellDate) {
        this.addError($localize`The actual buy date is after the sell date.`);
        return true;
      }
    }
    if (trade.target?.buyDate && trade.target?.sellDate) {
      const buyDate = Util.getDate(trade.target.buyDate).getTime();
      const sellDate = Util.getDate(trade.target.sellDate).getTime();
      if (buyDate > sellDate) {
        this.addError($localize`The target buy date is after the sell date.`);
        return true;
      }
    }
    return false;
  }
}
