<ng-container
  *ngIf="period !== undefined && statRet !== undefined && periodWinRatio; else none">
  <span [class]="periodWinRatio | valueClassWinRatioPipe">
    <app-percentage-view [value]="periodWinRatio"></app-percentage-view>
  </span>
</ng-container>

<ng-template #none>
  -
</ng-template>
