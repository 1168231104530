import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() centered = false;
  @Input() message ?: string;
  @Input() float = true;
  @Input() messagesByKey ?: Map<string, string>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
