import {layoutReducer} from '../layout/store/layout.reducer';
import {sharedReducer} from '../shared/store/shared.reducer';
import {authReducer} from '../auth/store/auth.reducer';
import {stockReducer} from './stock.reducer';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {simulationReducer} from './simulation.reducer';
import {plannerReducer} from './planner.reducer';
import {AppState} from './app.state';

export const appReducer: ActionReducerMap<AppState> = {
  layout: layoutReducer,
  shared: sharedReducer,
  stock: stockReducer,
  simulation: simulationReducer,
  auth: authReducer,
  planner: plannerReducer,
};

/**
 * Writes the specified store data into the local session storage and retrieves it on app start. Therefore, the state survives a browser refresh.
 * @see https://github.com/btroncone/ngrx-store-localstorage
 * @param reducer reducer
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const keys = ['shared', 'layout', 'stock', 'simulation', 'auth', 'planner'];
  return localStorageSync({keys, rehydrate: true, storage: localStorage})(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

