import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseDialogComponent} from '../../../../shared/components/base-dialog/base-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PeriodWinRatio, StatisticalReturnsSearchParams} from '../../../../shared/models/statisticalReturnsSearchParams.interface';
import {mergeUpdateBestStatisticalReturnSearchParams, mergeUpdateUpcomingStatisticalReturnSearchParams} from '../../../../store/stock.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
  selectBestStatisticalReturnSearchMinPeriodWinRatios,
  selectBestStatisticalReturnSearchMinWinRatio,
  selectUpcomingStatisticalReturnSearchMinPeriodWinRatios,
  selectUpcomingStatisticalReturnSearchMinWinRatio,
} from '../../../../store/stock.selectors';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';

@Component({
  selector: 'app-min-win-ratios-edit-dialog',
  templateUrl: './min-win-ratios-edit-dialog.component.html',
  styleUrls: ['./min-win-ratios-edit-dialog.component.scss'],
})
export class MinWinRatiosEditDialogComponent extends BaseDialogComponent implements OnInit {

  form: FormGroup = this.createForm();

  minWinRatio$?: Observable<number | undefined>;
  minPeriodWinRatios$?: Observable<PeriodWinRatio[] | undefined>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    dialogRef: MatDialogRef<MinWinRatiosEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) {
    super(authService, userService, store, dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.viewType === 'upcoming') {
      this.minWinRatio$ = this.store.select(selectUpcomingStatisticalReturnSearchMinWinRatio);
      this.minPeriodWinRatios$ = this.store.select(selectUpcomingStatisticalReturnSearchMinPeriodWinRatios);
    }
    if (this.data.viewType === 'best') {
      this.minWinRatio$ = this.store.select(selectBestStatisticalReturnSearchMinWinRatio);
      this.minPeriodWinRatios$ = this.store.select(selectBestStatisticalReturnSearchMinPeriodWinRatios);
    }

    this.minWinRatio$?.pipe(takeUntil(this.destroy$)).subscribe(minWinRatio => {
      this.form.patchValue({minWinRatio});
    });

    this.minPeriodWinRatios$?.pipe(takeUntil(this.destroy$)).subscribe(minPeriodWinRatios => {
      minPeriodWinRatios?.forEach(({period, minWinRatio}) => {
        this.form.patchValue({['minWinRatio' + period]: minWinRatio});
      });
    });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      minWinRatio: [''],
      minWinRatio5: [''],
      minWinRatio10: [''],
      minWinRatio15: [''],
      minWinRatio20: [''],
      minWinRatio25: [''],
      minWinRatio30: [''],
    });
  }

  saveMinWinRatio(minWinRatioString: string) {
    if (!minWinRatioString)
      return this.clearMinWinRatio();
    const minWinRatio = parseInt(minWinRatioString);
    if (isNaN(minWinRatio))
      return;
    let searchParams: StatisticalReturnsSearchParams = {minWinRatio};
    this.mergeUpdateSearchParams(searchParams);
  }

  clearMinWinRatio() {
    let searchParams: StatisticalReturnsSearchParams = {minWinRatio: undefined};
    this.mergeUpdateSearchParams(searchParams);
  }

  saveMinWinRatioForPeriod(minWinRatioString: string, period: string) {
    if (!minWinRatioString)
      return this.clearMinWinRatioForPeriod(period);
    const minWinRatio = parseInt(minWinRatioString);
    if (isNaN(minWinRatio))
      return;
    const periodWinRatio: PeriodWinRatio = {minWinRatio, period};
    let searchParams: StatisticalReturnsSearchParams = {minPeriodWinRatios: [periodWinRatio]};
    this.mergeUpdateSearchParams(searchParams);
  }

  clearMinWinRatioForPeriod(period: string) {
    const periodWinRatio: PeriodWinRatio = {minWinRatio: undefined, period};
    let searchParams: StatisticalReturnsSearchParams = {minPeriodWinRatios: [periodWinRatio]};
    this.mergeUpdateSearchParams(searchParams);
  }

  private mergeUpdateSearchParams(searchParams: StatisticalReturnsSearchParams) {
    if (this.data.viewType === 'upcoming')
      this.store.dispatch(mergeUpdateUpcomingStatisticalReturnSearchParams({searchParams}));
    if (this.data.viewType === 'best')
      this.store.dispatch(mergeUpdateBestStatisticalReturnSearchParams({searchParams}));
  }
}
