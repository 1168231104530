import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatisticalSimulationResult} from '../../../shared/models/statisticalSimulationResult.interface';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StockService} from '../../../shared/services/stock.service';
import {StatisticalReturn} from '../../../shared/models/statisticalReturn.interface';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {StatisticalSimulationSortOrder} from '../../../shared/models/statisticalSimulationSearchParams.interface';
import {selectSimulationSearchSortOrder} from '../../../store/simulation.selectors';
import {setSimulationSearchSortOrder} from '../../../store/simulation.actions';

@Component({
  selector: 'app-simulation-results-table',
  templateUrl: './simulation-results-table.component.html',
  styleUrls: ['./simulation-results-table.component.scss'],
})
export class SimulationResultsTableComponent extends BaseComponent implements OnInit {

  currentSortOrder$ = this.store.select(selectSimulationSearchSortOrder);

  @Output() exchangesClicked = new EventEmitter<string[]>();

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private modalService: NgbModal,
      private stockService: StockService) {
    super(authService, userService, store);
  }

  public get sortOrder(): typeof StatisticalSimulationSortOrder {
    return StatisticalSimulationSortOrder;
  }

  _simulationResults?: StatisticalSimulationResult[] | null = [];

  get simulationResults(): StatisticalSimulationResult[] | undefined | null {
    return this._simulationResults;
  }

  @Input() set simulationResults(simulationResults: StatisticalSimulationResult[] | undefined | null) {
    this._simulationResults = simulationResults;
  }

  @Input() sortable = true;

  ngOnInit(): void {
    super.ngOnInit();
  }


  getFormattedBuyDate(statisticalReturn: StatisticalReturn): string {
    return StatisticalTradingService.getFormattedDate(statisticalReturn?.buyDay, statisticalReturn?.buyMonth);
  }

  getFormattedSellDate(statisticalReturn: StatisticalReturn): string {
    return StatisticalTradingService.getFormattedDate(statisticalReturn?.sellDay, statisticalReturn?.sellMonth);
  }

  sort(sortOrderString: string) {
    const sortOrder = this.getStatisticalSimulationSortOrderFromSortOrder(sortOrderString);
    this.store.dispatch(setSimulationSearchSortOrder({sortOrder}));
  }

  getStatisticalSimulationSortOrderFromSortOrder(sortOrderString: string): StatisticalSimulationSortOrder | undefined {
    return Object.values(StatisticalSimulationSortOrder).includes(sortOrderString as StatisticalSimulationSortOrder)
        ? (sortOrderString as StatisticalSimulationSortOrder)
        : undefined;
  }

  onExchangesClicked(exchangeShortNames: string[]) {
    this.exchangesClicked.emit(exchangeShortNames);
  }
}

