<app-redirect></app-redirect>
<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="main p-0">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>

