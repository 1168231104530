<div class="jumbotron mb-3">
  <h1 class="jumbotron-headline" i18n="My Stock Forecast headline|My Stock Forecast headline">My Stock Forecast</h1>
  <p class="jumbotron-lead" i18n="My Stock Forecast lead text">Manage your account, view your listings, your transactions, your messages, change your settings and
    more.</p>
</div>


<div class="card-deck">

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['/statistical-trading','planner','plans']" class="stretched-link">
          <span class="material-icons card-icon text-primary">event</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['/statistical-trading','planner','plans']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="Statistical trading planner headline">Statistical trading planner</h2>
            <p class="card-text" i18n="Statistical trading planner lead text">Plan your personal upcoming statistical trading strategy</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','watch-list']" class="stretched-link">
          <span class="material-icons card-icon text-primary">star</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','watch-list']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="watch list headline">My watch list</h2>
            <p class="card-text" i18n="watch list lead text">See what stock symbols and statistical trades you're watching.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','personal-info']" class="stretched-link">
          <span class="material-icons card-icon text-primary">manage_accounts</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','personal-info']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="personal info headline">My personal information</h2>
            <p class="card-text" i18n="personal info lead text">Edit your first and last name, birthday, gender, nationality, phone number, home address,
              user name, email address and user image.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','settings']" class="stretched-link">
          <span class="material-icons card-icon text-primary">settings</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','settings']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="settings headline">Settings</h2>
            <p class="card-text" i18n="settings lead text">Change Stock Forecast settings.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="card info-card mb-3">
    <div class="row no-gutters">
      <div>
        <a [routerLink]="['..','table-columns']" class="stretched-link">
          <span class="material-icons card-icon text-primary">view_column</span>
        </a>
      </div>
      <div class="col">
        <div class="card-body">
          <a [routerLink]="['..','table-columns']" class="stretched-link">
            <h2 class="paragraph-headline-2" i18n="Table columns headline">Table columns</h2>
            <p class="card-text" i18n="settings lead text">Change what columns should be visible in Stock Forecast tables.</p>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
