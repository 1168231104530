<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the minimum profit in the worst year to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n-matTooltip matTooltip="Min. profit in the worst year" i18n>Min. worst profit (%)</mat-label>
          <input cdkFocusInitial #minLowestYearlyProfitInput type="number" matInput name="minLowestYearlyProfit" formControlName="minLowestYearlyProfit" placeholder="-5" id="minLowestYearlyProfit"
                 [min]="-100" [max]="10" (change)="saveMinLowestYearlyProfit(minLowestYearlyProfitInput.value)">
          <mat-icon (click)="clearMinLowestYearlyProfit()" *ngIf="minLowestYearlyProfitInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>



