<div class="my-3">
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
</div>

<app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

<table class="table table-striped table-sm table-responsive-lg table-sticky-header">
  <thead>
  <tr>
    <th i18n matTooltip="The name the plan" i18n-matTooltip>Name</th>
    <th i18n matTooltip="The type of the plan - automatic or manual" i18n-matTooltip>Type</th>
    <th i18n matTooltip="The date of the first trade in this plan" i18n-matTooltip>Start date</th>
    <th i18n matTooltip="The date of the last trade in this plan" i18n-matTooltip>End date</th>
    <th i18n matTooltip="The number of trades, that have already been realized (bought and sold)" i18n-matTooltip>Realized trades</th>
    <th i18n matTooltip="The outstanding number of trades to be realized (bought and sold) in the future" i18n-matTooltip>Future trades</th>
    <th i18n matTooltip="The capital at the start of the plan" i18n-matTooltip>Seed capital</th>
    <th i18n matTooltip="The current estimated depot value, if the plan was followed correctly" i18n-matTooltip>Current value</th>
    <th i18n matTooltip="The estimated capital at the end of the plan" i18n-matTooltip>Final capital</th>
    <th i18n matTooltip="The exchanges, on which you want to do statistical trades" i18n-matTooltip>Exchanges</th>
    <th i18n matTooltip="The creation date of the plan" i18n-matTooltip>Created</th>
    <th i18n matTooltip="The last edit date of the plan" i18n-matTooltip>Last edit</th>
    <th i18n matTooltip="Edit or delete the plan" i18n-matTooltip>&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let plan of plans">
    <td><a [routerLink]="[plan.uid]">{{plan.name}}</a></td>
    <td>
      <app-plan-type-view [type]="plan.typeValue"></app-plan-type-view>
    </td>
    <td>
      <app-plan-start-or-end-date [trades]="plan.trades" phase="start"></app-plan-start-or-end-date>
    </td>
    <td>
      <app-plan-start-or-end-date [trades]="plan.trades" phase="end"></app-plan-start-or-end-date>
    </td>
    <td>
      <app-realized-trades-count [trades]="plan.trades"></app-realized-trades-count>
    </td>
    <td>
      <app-future-trades-count [trades]="plan.trades"></app-future-trades-count>
    </td>
    <td>
      <app-money [value]="plan.seedCapital" [currencyId]="plan.currency"></app-money>
    </td>
    <td>
      <app-plan-value [trades]="plan.trades" phase="current" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-value>
    </td>
    <td>
      <app-plan-value [trades]="plan.trades" phase="end" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-value>
    </td>
    <td>
      <app-exchanges-view *ngIf="plan.exchangeShortNames && plan.exchangeShortNames.length>0; else all" [exchangeShortNames]="plan.exchangeShortNames"></app-exchanges-view>
      <ng-template #all i18n>All</ng-template>
    </td>
    <td>
      <app-date-view [date]="plan.creationDate" [showRelativeDate]="false" mode="compact-date-only"></app-date-view>
    </td>
    <td>
      <app-date-view *ngIf="plan.lastEditDate; else unknown" [date]="plan.lastEditDate" [showRelativeDate]="false" mode="compact-date-only"></app-date-view>
      <ng-template #unknown i18n>Never</ng-template>
    </td>
    <td>
      <app-statistical-trading-plan-actions-menu [planUid]="plan.uid" [planName]="plan.name" (onSuccessfulDeletion)="planDeleted($event)" [automaticPlan]="plan.typeValue"
                                                 (onDeletionFailed)="planDeletionFailed($event)" (onOptimizationStarted)="optimizationStarted($event)"></app-statistical-trading-plan-actions-menu>
    </td>
  </tr>
  </tbody>
</table>
