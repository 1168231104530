<ng-container *ngIf="part === 'all' || part === 'introduction'">
  <ng-container *ngIf="page === 'best-statistical-trades'">
    <p i18n>The following table shows the best statistical trading opportunities for all symbols:</p>
  </ng-container>
  <ng-container *ngIf="page === 'symbol-view'">
    <p i18n>The following table shows the best statistical trading opportunities for this symbol:</p>
  </ng-container>
  <ng-container *ngIf="page === 'upcoming-statistical-trades'">
    <p i18n>The following table shows upcoming statistical trading opportunities for all symbols.</p>
  </ng-container>
</ng-container>

<ng-container *ngIf="part === 'all' || part === 'description'">
  <p i18n>Each row represents a buy and sell date and shows the average profit you would make each year if you bought and sold the symbol on those days.</p>
  <p i18n>The columns further to the right will then show the profit for each individual year. You can click on these figures to see details of the prices on the buy and sell dates. The remaining columns show you the average annual profit you would have made over the last 5 years, 10 years, 15 years ... up to 30 years (if there is enough data for this symbol).</p>
  <p i18n>We truncate the annual profit at +/- {{relativeProfitTruncatePercentage}} %. This means an annual profit of over {{relativeProfitTruncatePercentage}} % is treated as {{relativeProfitTruncatePercentage}} % for the average profit calculation, while an annual loss of more than {{relativeProfitTruncatePercentage}} % is treated as {{relativeProfitTruncatePercentage}} %. We do this to filter out years with extreme price gains or losses. Otherwise a single year with a very high profit could pull up the average, even though the stock performed poorly in all other years.</p>
  <p i18n>We only show you trading opportunities that have an average annual profit of at least {{minRelativeReturnAnyPeriodPercentage}} % in any of the shown periods and at least {{minRelativeReturnAllTimePercentage}} % over all time.</p>
</ng-container>
