import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {sha512} from 'js-sha512';
import {HttpParams} from '@angular/common/http';

import {SharedService} from '../shared/services/shared.service';
import {Router} from '@angular/router';
import {UserService} from '../shared/services/user.service';
import {AnalyticsEventName, AnalyticsService} from '../shared/services/analytics.service';
import {clearFirebaseUser, setFirebaseUser} from './store/auth.actions';

import firebase from 'firebase/app';
import {FirebaseUser} from '../shared/models/firebaseUser.interface';
import {AppState} from '../store/app.state';
import FirebaseAuthUser = firebase.User;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  openLoginDropdownSubject = new Subject<boolean>();
  private isFirebaseLoggedIn = false;

  constructor(private store: Store<AppState>,
              private router: Router,
              private userService: UserService,
              private sharedService: SharedService,
              private analyticsService: AnalyticsService) {
  }


  /**
   * Generates a nonce and returns it.
   * @return nonce consisting of the current timestamp
   */
  generateNonce(): number {
    return new Date().getTime();
  }

  /**
   * <p>
   * Generates a signature used for authentication.
   * <p/>
   *
   * @param tokenId     the ID of the token used for authentication
   * @param username    the name of the user to be authenticated
   * @param requestPath the request path of the request
   * @param nonce       a number, which always has to be bigger than the last used one. Usually a timestamp
   * @param tokenValue  the token value
   * @return signature
   * @throws AuthenticationException If encoding the given data (consisting of tokenId, userId, requestPath, nonce and paramsString) as hmecSHA256 fails
   * @author Alexander Schwartz yamma Apps Created 17.08.2018
   */
  generateSignature(tokenId: number, username: string, requestPath: string, nonce: number, tokenValue: string): string {
    const preHash = tokenId + '-' + username + '-' + requestPath + '-' + nonce;
    const signature = sha512.hmac(tokenValue, preHash);
    return signature;
  }

  /**
   * Generates a nonce and token and returns them both.
   * @param tokenId     the ID of the token used for authentication
   * @param username    the name of the user to be authenticated
   * @param requestPath the request path of the request
   * @param tokenValue  the token value
   * @return nonce and signature in a Java script object
   */
  generateNonceAndSignature(tokenId: number, username: string, requestPath: string, tokenValue: string): { nonce: number; signature: string } {
    const nonce = this.generateNonce();
    const signature = this.generateSignature(tokenId, username, requestPath, nonce, tokenValue);
    return {nonce, signature};
  }

  /**
   * Generates HttpParams consisting of a current, generated nonce (current timestamp) and a generated signature used for authentication.
   * @param tokenId     the ID of the token used for authentication
   * @param username    the name of the user to be authenticated
   * @param requestPath the request path of the request
   * @param tokenValue  the token value
   * @return nonce and signature inside a HttpParams object.
   */
  createNonceAndSignatureHttpParams(tokenId: number, username: string, requestPath: string, tokenValue: string): HttpParams {
    const nonceAndSignature = this.generateNonceAndSignature(tokenId, username, requestPath, tokenValue);
    let body = new HttpParams();
    body = body.set('tokenId', tokenId + '');
    body = body.set('name', username);
    body = body.set('nonce', nonceAndSignature.nonce + '');
    body = body.set('signature', nonceAndSignature.signature);
    return body;
  }

  /**
   * Emits an event on the openLoginDropdownSubject, if available, and returns true. Otherwise, returns false.
   * @return true, if successful, false otherwise
   */
  openLoginDropdown(): boolean {
    if (this.openLoginDropdownSubject) {
      this.openLoginDropdownSubject.next(true);
      return true;
    }
    return false;
  }

  /**
   * Called on successful firebase login.
   * @param firebaseAuthUser firebase auth user
   */
  onFirebaseSignIn(firebaseAuthUser: FirebaseAuthUser): void {
    this.addFirebaseUserToStore(firebaseAuthUser);
    this.isFirebaseLoggedIn = true;
    this.analyticsService.logEvent(AnalyticsEventName.SIGN_IN);
  }

  addFirebaseUserToStore(firebaseAuthUser: firebase.User) {
    const firebaseUser: FirebaseUser = {
      uid: firebaseAuthUser.uid,
      email: firebaseAuthUser.email,
      emailVerified: firebaseAuthUser.emailVerified,
      displayName: firebaseAuthUser.displayName,
      telephoneNumber: firebaseAuthUser.phoneNumber,
      photoURL: firebaseAuthUser.photoURL,
    };
    this.store.dispatch(setFirebaseUser({firebaseUser}));
  }

  /**
   * Called on firebase logout.
   */
  onFirebaseLogout(): void {
    this.store.dispatch(clearFirebaseUser());
    console.log('Logging out from firebase.');
    this.analyticsService.logEvent(AnalyticsEventName.SIGN_OUT);
    if (this.isFirebaseLoggedIn) {
      this.router.navigate(['loggedOut']);
      this.isFirebaseLoggedIn = false;
    }
  }
}
