import {Component, Input, OnInit} from '@angular/core';
import {StatisticalTrade} from '../../../../shared/models/statisticalTrade.interface';

@Component({
  selector: 'app-realized-trades-count',
  templateUrl: './realized-trades-count.component.html',
  styleUrls: ['./realized-trades-count.component.scss'],
})
export class RealizedTradesCountComponent implements OnInit {

  @Input() trades?: StatisticalTrade[];

  constructor() {
  }

  ngOnInit(): void {
  }

  getRealizedTradesCount(trades: StatisticalTrade[]) {
    let realizedTrades = trades.filter(it => it.bought && it.sold);
    return realizedTrades.length;
  }
}
