import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {FacebookService} from '../shared/services/facebook.service';
import {AnalyticsService} from '../shared/services/analytics.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {StatisticalTradingService} from '../statistical-trading/statistical-trading.service';
import {BackendService} from '../shared/services/backend.service';
import {Store} from '@ngrx/store';
import {AppState} from './app.state';

@Injectable()
export class PlannerEffects {


  constructor(private actions: Actions,
              private httpClient: HttpClient,
              private router: Router,
              private facebookService: FacebookService,
              private analyticsService: AnalyticsService,
              private statisticalTradingService: StatisticalTradingService,
              private backendService: BackendService,
              private store: Store<AppState>) {
  }

}

