import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {environment} from '../../../../environments/environment';
import Util from '../../util';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UtilService} from '../../util.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent extends BaseComponent implements OnInit {

  @Input() remainingUploads: number = environment.maxImagesCount;
  @Input() minSize?: number;
  @Input() maxSize?: number;
  @Input() uploadEnabled?: boolean = true;

  @Output() onUploadClicked = new EventEmitter<File[]>();

  // Uploader
  isHovering: boolean = false;
  limitReached = false;

  files: File[] = [];
  @Input() fileTypes?: string[];

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public utilService: UtilService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(fileList: FileList) {
    this.clearAlerts();

    const onLimitReached = () => {
      this.limitReached = true;
    };

    for (let i = 0; i < fileList.length; i++) {
      if (fileList.item(i) !== null) {
        const file: File = fileList!.item(i) as File;

        if (this.fileAlreadySelected(file)) {
          this.addError(file.name + ': ' + $localize`Duplicate file.`);
          continue;
        }

        if (this.minSize && file.size < this.minSize) {
          this.addError(file.name + ': ' + $localize`The file is too small. Minimum file size\: ` + Util.humanFileSize(this.minSize, true));
          continue;
        }

        if (this.maxSize && file.size > this.maxSize) {
          this.addError(file.name + ': ' + $localize`The file is too large. Maximum file size\: ` + Util.humanFileSize(this.maxSize, true));
          continue;
        }

        if (this.files.length >= this.remainingUploads) {
          onLimitReached.call(this);
          return;
        }
        if (Util.validateFileType(file, this.fileTypes)) {
          if (this.files.length >= this.remainingUploads) {
            onLimitReached.call(this);
            return;
          }
          this.files.push(file);
        } else
          this.addError($localize`Invalid filetype. Allowed filetypes are\:` + ' ' + Util.prettyPrintArray(this.fileTypes!));
      }
    }
  }

  fileChangeEvent(event: any): void {
    const fileList = event.target.files;
    this.onDrop(fileList);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
  }

  /**
   * Start uploading all files
   */
  uploadAll() {
    this.clearAlerts();

    if (this.files && this.files.length > 0)
      this.onUploadClicked.emit(this.files);
  }

  onDelete(file: File) {
    this.clearAlerts();
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.limitReached = this.files.length >= this.remainingUploads;
  }

  /**
   * Checks, if the given file file is already selected (contained in this.files).
   * @param newFile File to be checked
   * @return true, if already selected, false otherwise
   */
  private fileAlreadySelected(newFile: File) {
    for (const file of this.files) {
      if (file.name === newFile.name && file.size === newFile.size)
        return true;
    }

    return false;
  }
}
