import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {StatisticalTradingService} from '../../../statistical-trading/statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {WatchListItem} from '../../models/watchListItem.interface';
import {Router} from '@angular/router';
import {resetUpdateUserState} from '../../../auth/store/auth.actions';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-watch-list-star',
  templateUrl: './watch-list-star.component.html',
  styleUrls: ['./watch-list-star.component.scss'],
})
export class WatchListStarComponent extends BaseComponent implements OnInit {

  @Input() floatTopRight = false;

  alreadyWatched: boolean = false;

  _symbolCode?: string = undefined;

  get symbolCode(): string | undefined {
    return this._symbolCode;
  }

  @Input() set symbolCode(symbolCode: string | undefined) {
    this._symbolCode = symbolCode;
  }

  _statisticalSimulationUid?: string = undefined;

  get statisticalSimulationUid(): string | undefined {
    return this._statisticalSimulationUid;
  }

  @Input() set statisticalSimulationUid(simulationUid: string | undefined) {
    this._statisticalSimulationUid = simulationUid;
  }

  _statisticalReturnUid?: string = undefined;

  get statisticalReturnUid(): string | undefined {
    return this._statisticalReturnUid;
  }

  @Input() set statisticalReturnUid(statisticalReturnUid: string | undefined) {
    this._statisticalReturnUid = statisticalReturnUid;
  }

  @Output() onRemoval = new EventEmitter<WatchListItem>();

  undoSnackBarRef ?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private statisticalTradingService: StatisticalTradingService,
      private matSnackBar: MatSnackBar,
      private router: Router) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkIfAlreadyWatched();
  }

  private checkIfAlreadyWatched() {
    this.user$.subscribe(user => {
      if (user?.uid !== undefined)
        this.alreadyWatched = this.statisticalTradingService.doesUserWatchItem(user, this.symbolCode, this.statisticalReturnUid, this.statisticalSimulationUid);
    });
  }

  removeFromWatchlist() {
    if (!this.user)
      return;
    this.alreadyWatched = this.statisticalTradingService.removeFromWatchList(this.user!, this.symbolCode, this.statisticalReturnUid, this.statisticalSimulationUid);
    this.listenForResult('remove');
  }

  addToWatchlist() {
    if (!this.user)
      return;
    this.alreadyWatched = this.statisticalTradingService.addToWatchList(this.user!, this.symbolCode, this.statisticalReturnUid, this.statisticalSimulationUid);
    this.listenForResult('add');
  }

  showUndoSnackbar(removedItem: WatchListItem) {
    let messageRemovedItem = '';
    if (removedItem?.symbolCode && !removedItem.statisticalReturnUid)
      messageRemovedItem = removedItem.symbolCode;
    if (removedItem?.symbolCode && removedItem.statisticalReturnUid)
      messageRemovedItem = `${removedItem.symbolCode}: ${removedItem.statisticalReturnUid}`;
    if (removedItem?.statisticalSimulationUid)
      messageRemovedItem = removedItem.statisticalSimulationUid;
    this.undoSnackBarRef = this.matSnackBar.open($localize`Removed from watch list: ` + messageRemovedItem, $localize`Undo`, {
      duration: 10000,
    });
    this.undoSnackBarRef.onAction().subscribe(() => this.addToWatchlist());
  }

  private showGoToWatchListSnackbar(addedItem: WatchListItem) {
    let messageAddedItem = '';
    let route: string[] = [];
    if (addedItem?.symbolCode && !addedItem.statisticalReturnUid) {
      messageAddedItem = addedItem.symbolCode;
      route = ['/account', 'watch-list', 'symbols'];
    }
    if (addedItem?.symbolCode && addedItem.statisticalReturnUid) {
      messageAddedItem = `${addedItem.symbolCode}: ${addedItem.statisticalReturnUid}`;
      route = ['/account', 'watch-list', 'statistical-returns'];
    }
    if (addedItem?.statisticalSimulationUid) {
      messageAddedItem = addedItem.statisticalSimulationUid;
      route = ['/account', 'watch-list', 'statistical-simulations'];
    }
    this.undoSnackBarRef = this.matSnackBar.open($localize`Added to watch list: ` + messageAddedItem, $localize`Go to watch list`, {
      duration: 10000,
    });
    this.undoSnackBarRef.onAction().subscribe(() => {
      this.router.navigate(route);
    });
  }

  private showUpdateErrorSnackbar(errorMessage?: string) {
    this.undoSnackBarRef = this.matSnackBar.open($localize`Saving failed: ` + errorMessage, undefined, {duration: 10000});
  }

  private listenForResult(type: 'add' | 'remove') {
    const stopListening$: Subject<null> = new Subject();
    this.updateUserActionResult$.pipe(takeUntil(stopListening$)).subscribe(result => {
      if (result.errorMessage) {
        this.showUpdateErrorSnackbar(result.errorMessage);
      }
      if (result.success) {
        this.stopListening(stopListening$);
        if (type === 'add') {
          this.undoSnackBarRef?.dismiss();
          let addedItem: WatchListItem = {
            symbolCode: this.symbolCode,
            statisticalReturnUid: this.statisticalReturnUid,
            statisticalSimulationUid: this.statisticalSimulationUid,
          };
          this.showGoToWatchListSnackbar(addedItem);
        }
        if (type === 'remove') {
          let removedItem: WatchListItem = {
            symbolCode: this.symbolCode,
            statisticalReturnUid: this.statisticalReturnUid,
            statisticalSimulationUid: this.statisticalSimulationUid,
          };
          this.onRemoval.emit(removedItem);
          this.showUndoSnackbar(removedItem);
        }
      }
    });
  }

  private stopListening(stopListening$: Subject<null>) {
    stopListening$.next();
    this.store.dispatch(resetUpdateUserState());
  }
}
