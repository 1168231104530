import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {StockService} from '../../services/stock.service';
import {StatisticalTradingService} from '../../../statistical-trading/statistical-trading.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {selectSymbolSearchResultList, selectSymbolSearchThereIsMore, selectSymbolSearchTotalCount} from '../../../store/stock.selectors';
import Util from '../../util';
import {Symbol} from '../../models/symbol.interface';

@Component({
  selector: 'app-symbol-search-table',
  templateUrl: './symbol-search-table.component.html',
  styleUrls: ['./symbol-search-table.component.scss'],
})
export class SymbolSearchTableComponent extends BaseComponent implements OnInit {

  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private stockService: StockService,
      private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  @Input() routerLinkPath?: string[];
  @Input() isClickable = false;
  @Output() onSymbolClicked = new EventEmitter<Symbol>();

  symbols$ = this.store.select(selectSymbolSearchResultList);
  thereIsMore$ = this.store.select(selectSymbolSearchThereIsMore);
  searchTotalCount$ = this.store.select(selectSymbolSearchTotalCount);

  ngOnInit(): void {
    super.ngOnInit();
  }

  selectExchange(exchangeShortName: string) {
    this.statisticalTradingService.updateExchangeShortName(exchangeShortName);
    this.statisticalTradingService.fetchSearchResults();
  }

  onSymbolClick(symbol: Symbol) {
    this.onSymbolClicked.emit(symbol);
  }

  /**
   * Called, when the Show more button is clicked. Loads more trades.
   */
  onClickShowMore(): void {
    this.statisticalTradingService.fetchMoreSearchResults();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

  appendSymbolCodeToRouterLinkPath(symbolCode: string): string[] {
    if (!this.routerLinkPath)
      return [symbolCode];
    return [...this.routerLinkPath, symbolCode];

  }

}
