<ng-container *ngIf="user">
  <div [ngClass]="floatTopRight ? 'float-top-right' : ''">
    <ng-container *ngIf="alreadyWatched">
      <a (click)="removeFromWatchlist();$event.preventDefault()" href="#" target="_blank" matTooltip="Remove from watch list" i18n-matTooltip>
        <mat-icon color="accent">star</mat-icon>
      </a>
    </ng-container>
    <ng-container *ngIf="!alreadyWatched">
      <a (click)="addToWatchlist();$event.preventDefault()" href="#" target="_blank" matTooltip="Add to watch list" i18n-matTooltip>
        <mat-icon color="accent">star_border</mat-icon>
      </a>
    </ng-container>
  </div>
</ng-container>
