import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {StatisticalReturn} from '../../../shared/models/statisticalReturn.interface';
import {StockService} from '../../../shared/services/stock.service';
import {Symbol} from '../../../shared/models/symbol.interface';
import {StatisticalTradingService} from '../../statistical-trading.service';
import Util from '../../../shared/util';
import {UtilService} from '../../../shared/util.service';
import {environment} from '../../../../environments/environment';
import {TitleService} from '../../../shared/services/title.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {BreadcrumbPage} from '../../../shared/components/breadcrumb/breadcrumb.component';
import {StatisticalTradingPlan} from '../../../shared/models/statisticalTradingPlan.interface';
import {PlannerService} from '../../planner/planner.service';
import {MatSnackBar, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-statistical-return-view',
  templateUrl: './statistical-return-view.component.html',
  styleUrls: ['./statistical-return-view.component.scss'],
})
export class StatisticalReturnViewComponent extends BaseComponent implements OnInit {
  @ViewChildren('stepRef') stepRefs?: QueryList<ElementRef>;

  breadcrumbPages: BreadcrumbPage[] = [
    {routerLink: '../..', title: $localize`Statistical trading planner`},
    {routerLink: '..', title: $localize`My plans`},
  ];

  symbolCode?: string;
  symbol?: Symbol;
  statisticalReturnUid?: string;
  statisticalReturn?: StatisticalReturn;
  years: string[] = [];
  periods: string[] = [];
  nonTruncatedProfit?: number;
  relativeProfitTruncatePercentage = environment.relativeProfitTruncateThreshold * 100;

  plans: StatisticalTradingPlan[] = [];

  searching: boolean = false;
  private readonly spinnerKeyFetchingSymbol = 'fetchSymbol';
  private readonly spinnerKeyStatisticalReturn = 'fetchStatisticalReturn';
  private readonly spinnerKeyFetchUserPlans = 'fetchUserPlans';
  private readonly spinnerKeyAddToPlan = 'addToPlan';

  planAddedSnackbar ?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public utilService: UtilService,
      private activatedRoute: ActivatedRoute,
      private stockService: StockService,
      private statisticalTradingService: StatisticalTradingService,
      private router: Router,
      private matSnackBar: MatSnackBar,
      private plannerService: PlannerService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Statistical return`);

    // Retrieve uid from route params
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(
        (params) => {
          this.clearAlerts();
          if (this.symbolCode !== params.symbolCode || this.statisticalReturnUid !== params.statisticalReturnUid)
            this.resetComponent();
          this.symbolCode = params.symbolCode;
          this.statisticalReturnUid = params.statisticalReturnUid;
          if (this.symbolCode) {
            this.titleService.setTitle(this.symbolCode);
            this.fetchSymbol(this.symbolCode);
            if (this.statisticalReturnUid)
              this.fetchStatisticalReturn();
          }
        });

    this.fetchTradingPlans();
  }

  public fetchStatisticalReturn() {
    if (!this.symbolCode) {
      this.addError($localize`Please select a symbol first.`);
    }
    if (!this.statisticalReturnUid) {
      this.addError($localize`Please select a statistical return first.`);
    }
    if (!this.symbolCode || !this.statisticalReturnUid)
      return;

    this.searching = true;
    this.addLoadingSpinnerMessage(this.spinnerKeyStatisticalReturn, $localize`Fetching statistical return`);
    this.statisticalTradingService.fetchStatisticalReturn(this.symbolCode!, this.statisticalReturnUid!).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyStatisticalReturn);
      this.searching = false;
      if (wrapper.data) {
        this.statisticalReturn = wrapper.data;
        this.years = StatisticalTradingService.determineYears([this.statisticalReturn], true);
        this.nonTruncatedProfit = StatisticalTradingService.calculateNonTruncatedProfit(this.statisticalReturn, this.years);
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });

  }


  private fetchSymbol(symbolCode: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingSymbol, $localize`Fetching symbol ${this.symbolCode}`);
    this.stockService.fetchSymbolFromBackend(symbolCode).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingSymbol);
      if (wrapper.data) {
        this.symbol = wrapper.data;
        this.titleService.setTitle(this.symbol.name);
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }

  private resetComponent() {
    this.symbol = undefined;
    this.statisticalReturn = undefined;
  }

  getFormattedBuyDate(): string {
    return StatisticalTradingService.getFormattedDate(this.statisticalReturn?.buyDay, this.statisticalReturn?.buyMonth);
  }

  getFormattedSellDate(): string {
    return StatisticalTradingService.getFormattedDate(this.statisticalReturn?.sellDay, this.statisticalReturn?.sellMonth);
  }


  getNumberOfDataYears(statRet: StatisticalReturn): string {
    return Util.getNumberOfDataYearsString(statRet);
  }

  public getTruncationTooltip() {
    return $localize`We truncate the annual profit at +/- ${this.relativeProfitTruncatePercentage} %. This means an annual profit of over ${this.relativeProfitTruncatePercentage} % is treated as ${this.relativeProfitTruncatePercentage} % for the average profit calculation, while an annual loss of more than ${this.relativeProfitTruncatePercentage} % is treated as ${this.relativeProfitTruncatePercentage} %. We do this to filter out years with extreme price gains or losses. Otherwise a single year with a very high profit could pull up the average, even though the stock performed poorly in all other years.`;
  }

  onSymbolRefreshed(symbol: Symbol) {
    this.symbol = symbol;
  }

  addToPlan(plan: StatisticalTradingPlan) {
    if (!this.user?.uid || !this.statisticalReturn || !plan.uid)
      return;
    this.addLoadingSpinnerMessage(this.spinnerKeyAddToPlan, $localize`Adding this trade to your statistical trading plan '${plan.name}'...`);
    this.plannerService.addStatisticalReturnToPlan(this.statisticalReturn, plan.uid, this.user.uid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyAddToPlan);
      if (wrapper.success && wrapper.data) {
        this.showGoToWatchListSnackbar(wrapper.data);
        this.replaceLocalPlan(wrapper.data);
      }
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }


  private showGoToWatchListSnackbar(plan: StatisticalTradingPlan) {
    if (!plan.uid || !plan.name)
      return;
    this.planAddedSnackbar = this.matSnackBar.open($localize`This trade was successfully added to your plan ${plan.name}.`, $localize`Go to plan`, {
      duration: 10000,
    });
    let route: string[] = ['/statistical-trading', 'planner', 'plans', plan.uid];
    this.planAddedSnackbar.onAction().subscribe(() => {
      this.router.navigate(route);
    });
  }

  private fetchTradingPlans() {
    if (!this.user?.uid)
      return;
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchUserPlans, $localize`Loading your statistical trading plans...`);
    this.plannerService.fetchUserPlans(this.user?.uid).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchUserPlans);
      if (wrapper.success && wrapper.data)
        this.plans = wrapper.data;
      if (wrapper.errorMessage)
        this.addError($localize`Could not fetch your statistical trading plans: ${wrapper.errorMessage}`);
    });
  }

  planContainsStatisticalReturn(plan: StatisticalTradingPlan) {
    if (!plan.trades || plan.trades.length === 0)
      return false;
    return plan.trades.find(it => it.statisticalReturnUid === this.statisticalReturnUid) !== undefined;
  }

  private replaceLocalPlan(resultingPlan: StatisticalTradingPlan) {
    let planFromLocalCache = this.plans.find(it => it.uid === resultingPlan.uid);
    if (!planFromLocalCache)
      return;
    const plans = Util.removeFromArray([...this.plans], planFromLocalCache);
    this.plans = [...plans, resultingPlan];
  }
}
