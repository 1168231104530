<app-alerts [alerts]="alerts" [float]="true" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

<div *ngIf="symbols && symbols.length>0">
  <h2 class="paragraph-headline-2">Selected stocks</h2>
  <mat-form-field appearance="fill" class="stock-search-bar">
    <mat-label i18n>Select symbols</mat-label>
    <mat-chip-list #selectedSymbolsList aria-label="Symbol selection">
      <mat-chip
        (removed)="remove(selectedSymbol)"
        *ngFor="let selectedSymbol of selectedSymbols">
        {{selectedSymbol.name}} ({{selectedSymbol.code}})
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #symbolInput
        (keyup)="filter(symbolInput.value)"
        (matChipInputTokenEnd)="add($event)"
        [formControl]="selectSymbolInput"
        [matAutocomplete]="auto"
        [matChipInputFor]="selectedSymbolsList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        placeholder="New symbol...">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event?.option?.value)">
      <mat-option *ngFor="let symbol of filteredSymbols" [value]="symbol">
        {{symbol.name}} ({{symbol.code}})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>


  <h1 class="paragraph-headline">List by exchange</h1>
  <mat-chip-list *ngIf="exchanges && exchanges.length > 0" aria-label="Exchange selection" i18n-aria-label>
    <ng-container *ngFor="let exchange of exchanges">
      <mat-chip (click)="onExchangeClick(exchange)" *ngIf="selectedExchange != exchange"
                class="hover-pointer">{{exchange}} ({{symbolsByExchange.get(exchange)?.length}})
      </mat-chip>
      <mat-chip *ngIf="selectedExchange == exchange" color="primary" selected>{{exchange}}
        ({{symbolsByExchange.get(exchange)?.length}})
        <mat-icon (click)="onExchangeClick(exchange)" class="hover-pointer ml-2">cancel</mat-icon>
      </mat-chip>
    </ng-container>

  </mat-chip-list>


  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <div *ngIf="selectedExchangeSymbols && selectedExchangeSymbols.length > 0 && selectedExchange" class="col-8 pl-0">
    <div class="symbols-container">
      <h2 class="paragraph-headline-2">{{selectedExchange}}</h2>
      <mat-selection-list [multiple]="true" class="symbols-list">
        <mat-list-option *ngFor="let symbol of selectedExchangeSymbols" [value]="symbol.code" class="symbol-box">
          <p><b>{{symbol.code}}</b> {{symbol.name}}<br>
            <small>{{symbol.exchangeShortName}}</small>
          </p>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>


</div>
