<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the minimum average volume in thousands {{baseCurrency}} to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n-matTooltip matTooltip="Min. average volume in thousands {{baseCurrency}}" i18n>Min. volume (k {{baseCurrency}})</mat-label>
          <input cdkFocusInitial #minAverageVolumeBaseCurrencyInput type="number" matInput name="minAverageVolumeBaseCurrency" formControlName="minAverageVolumeBaseCurrency" placeholder="1000000" id="minAverageVolumeBaseCurrency"
                 [min]="0" [max]="999999999999" (change)="saveMinAverageVolumeBaseCurrency(minAverageVolumeBaseCurrencyInput.value)">
          <mat-icon (click)="clearMinAverageVolumeBaseCurrency()" *ngIf="minAverageVolumeBaseCurrencyInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>
