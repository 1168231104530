import {Injectable} from '@angular/core';
import {ColumnList, TableColumn, tableColumnsStatisticalReturn, TableId} from '../shared/models/columnSettings.interface';
import {User as FirestoreUser} from '../shared/models/user.interface';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableColumnService {

  destroy$: Subject<null> = new Subject();

  constructor() {
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {

  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  getTableColumns(user: FirestoreUser | undefined, tableId: TableId): TableColumn[] {
    if (user?.settings?.columnSettings === undefined)
      return this.getDefaultColumns(tableId);
    const columnList: ColumnList = user.settings.columnSettings[tableId];
    if (columnList === undefined)
      return this.getDefaultColumns(tableId);
    const visibleColumnIds = columnList.visibleColumnIds;
    if (visibleColumnIds === undefined)
      return this.getDefaultColumns(tableId);
    const allTableColumns = this.getAllTableColumns(tableId);
    return this.convertToTableColumn(visibleColumnIds, allTableColumns);
  }

  getDefaultColumns(tableId: TableId): TableColumn[] {
    let all = this.getAllTableColumns(tableId);
    return this.filterDefaultVisible(all);
  }

  getAllTableColumns(tableId: TableId): TableColumn[] {
    switch (tableId) {
      case 'statistical-returns':
        return tableColumnsStatisticalReturn;
      case 'simulation-results':
        return [];
      default:
        return [];
    }
  }

  convertToTableColumn(columnIds: string[], allTableColumns: TableColumn[]): TableColumn[] {
    const tableColumns: TableColumn[] = [];
    columnIds.forEach(columnId => {
      let item = allTableColumns.find(it => it.id === columnId);
      if (item !== undefined) {
        const tableColumn: TableColumn = {...item, id: item.id, defaultVisible: item.defaultVisible, label: item.label};
        tableColumns.push(item);
      }
    });
    return tableColumns;
  }

  /**
   * Filters out all fields, that are not visible by default, only leaving in the by default visible fields.
   * @param all all columns
   * @return by default visible columns
   */
  filterDefaultVisible(all: TableColumn[]): TableColumn[] {
    return [...all.filter(it => it.defaultVisible)];
  }
}
