<h1 class="paragraph-headline"><span i18n>Edit step</span><span>&nbsp;</span>
  <a [routerLink]="['/statistical-trading/','symbols',step?.symbolCode, step?.statisticalReturnUid]" target="_blank">
    <app-symbol-descriptor [symbolCode]="step?.symbolCode" [nameOnly]="true"></app-symbol-descriptor>
  </a>
</h1>

<div class="mb-2" *ngIf="alerts && alerts.length">
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
</div>

<div class="row">
  <div class="col" *ngIf="targetForm && plan">
    <h2 class="paragraph-headline-2" i18n>Target properties</h2>
    <app-statistical-trade-properties-form [formGroup]="targetForm" [currencyId]="plan.currency" (onSave)="save()"></app-statistical-trade-properties-form>
  </div>

  <div class="col" *ngIf="actualForm && plan">
    <h2 class="paragraph-headline-2" i18n>Actual properties</h2>
    <app-statistical-trade-properties-form [formGroup]="actualForm" [currencyId]="plan.currency" (onSave)="save()" [calculateDerivedValuesTrigger]="calculateDerivedValuesTrigger" (onBought)="onBought($event)" (onSold)="onSold($event)"></app-statistical-trade-properties-form>
  </div>
</div>

<div class="row">
  <div class="col">
    <mat-checkbox [checked]="bought" [disabled]="true" i18n i18n-matTooltip matTooltip="A stock symbol is considered bought when a buy date and price are set.">Bought</mat-checkbox>
  </div>
  <div class="col">
    <app-mat-icon-button icon="delete" color="warn" label="Delete target values" i18n-label [callback]="deleteTargetValues.bind(this)"></app-mat-icon-button>
  </div>
  <div class="col">
    <mat-checkbox [checked]="sold" [disabled]="true" i18n i18n-matTooltip matTooltip="A stock symbol is considered sold when a sell date and price are set.">Sold</mat-checkbox>
  </div>
  <div class="col">
    <app-mat-icon-button icon="delete" color="warn" label="Delete actual values" i18n-label [callback]="deleteActualValues.bind(this)"></app-mat-icon-button>
  </div>
</div>
<div *ngIf="targetForm && actualForm && plan">
  <div class="row">
    <div class="col">
      <h3 class="paragraph-headline-3" i18n>Copy values:</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-mat-icon-button label="Buy date" i18n-label [callback]="copyBuyDate.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>
    </div>
    <div class="col">
      <app-mat-icon-button label="Buy price" i18n-label [callback]="copyBuyPrice.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>
    </div>
    <div class="col">
      <app-mat-icon-button label="Sell date" i18n-label [callback]="copySellDate.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>
    </div>
    <div class="col">
      <app-mat-icon-button label="Sell price" i18n-label [callback]="copySellPrice.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>
    </div>
    <div class="col">
      <app-mat-icon-button label="Order fee" i18n-label [callback]="copyOrderFee.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>
    </div>
    <div class="col">
      <app-mat-icon-button label="Tax" i18n-label [callback]="copyTax.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>
    </div>
    <div class="col">
      <app-mat-icon-button label="All" i18n-label [callback]="copyAllValues.bind(this)" icon="chevron_right" cssClass="mr-1 mb-2"></app-mat-icon-button>

    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <app-mat-icon-button [disabled]="!targetForm?.valid || !actualForm?.valid" label="Save" i18n-label cssClass="mt-3" icon="save"
                         type="button" [callback]="save.bind(this)"></app-mat-icon-button>

  </div>
</div>
