import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {UserService} from '../shared/services/user.service';
import {BaseComponent} from '../shared/components/base/base.component';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent extends BaseComponent implements OnInit {

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


}
