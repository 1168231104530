<ng-container *ngIf="column !== undefined; else noColumn">

  <ng-container [ngSwitch]="column?.id">

    <ng-container *ngSwitchCase="'PERIODS_ALL_ASCENDING'">
      <th *ngFor="let period of periods">
        <app-period-header [period]="period"></app-period-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'PERIODS_ALL_DESCENDING'">
      <th *ngFor="let period of getReversePeriods()">
        <app-period-header [period]="period"></app-period-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'PERIODS_WIN_RATIOS_ALL_ASCENDING'">
      <th *ngFor="let period of periods">
        <app-period-win-ratio-header [period]="period"></app-period-win-ratio-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'PERIODS_WIN_RATIOS_ALL_DESCENDING'">
      <th *ngFor="let period of getReversePeriods()">
        <app-period-win-ratio-header [period]="period"></app-period-win-ratio-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_ALL_ASCENDING'">
      <th *ngFor="let year of years">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_ALL_DESCENDING'">
      <th *ngFor="let year of getReverseYears()">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_0_TO_5_ASCENDING'">
      <th *ngFor="let year of getXToYYears(0,5,false)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_0_TO_5_DESCENDING'">
      <th *ngFor="let year of getXToYYears(0,5,true)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_6_TO_10_ASCENDING'">
      <th *ngFor="let year of getXToYYears(6,10,false)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_6_TO_10_DESCENDING'">
      <th *ngFor="let year of getXToYYears(6,10,true)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_11_TO_15_ASCENDING'">
      <th *ngFor="let year of getXToYYears(11,15,false)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_11_TO_15_DESCENDING'">
      <th *ngFor="let year of getXToYYears(11,15,true)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_16_TO_20_ASCENDING'">
      <th *ngFor="let year of getXToYYears(16,20,false)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_16_TO_20_DESCENDING'">
      <th *ngFor="let year of getXToYYears(16,20,true)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_21_TO_25_ASCENDING'">
      <th *ngFor="let year of getXToYYears(21,25,false)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_21_TO_25_DESCENDING'">
      <th *ngFor="let year of getXToYYears(21,25,true)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'YEARS_26_TO_30_ASCENDING'">
      <th *ngFor="let year of getXToYYears(26,30,false)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>
    <ng-container *ngSwitchCase="'YEARS_26_TO_30_DESCENDING'">
      <th *ngFor="let year of getXToYYears(26,30,true)">
        <app-year-header [year]="year"></app-year-header>
      </th>
    </ng-container>

    <ng-container *ngSwitchCase="'DEVELOPMENT_SINCE_BUY_DAY'">
      <ng-container *ngIf="showDevelopmentSinceBuyDay">
        <th>
          <span i18n-matTooltip matTooltip="The close price on the buy day" i18n>Buy day price</span>
        </th>
        <th>
          <span i18n-matTooltip matTooltip="The current price of the symbol" i18n>Current price</span>
        </th>
        <th>
          <span i18n-matTooltip matTooltip="Price change since buy day" i18n>Change</span>
        </th>
        <th>
          <span i18n-matTooltip matTooltip="The statistically expected price on the sell day" i18n>Target sell price</span>
        </th>
        <th>
          <span i18n-matTooltip matTooltip="The profit, if bought today and sold on sell day" i18n>Expected profit</span>
        </th>
      </ng-container>
    </ng-container>

  </ng-container>

</ng-container>

<ng-template #noColumn>
  <th>&nbsp;</th>
</ng-template>
