<app-breadcrumb i18n-currentPageTitle currentPageTitle="{{plan?.name ? plan?.name : 'Trading plan'}}"
                [pages]="breadcrumbPages"></app-breadcrumb>
<div class="parent-component">

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <app-not-logged-in *ngIf="!firebaseUser" i18n-message="not-logged-in message account management"
                     message="You need to login, before viewing your trading plans."></app-not-logged-in>

  <div *ngIf="firebaseUser">

    <ng-container *ngIf="plan">

      <h1 class="paragraph-headline leave-room-for-star">{{plan?.name}}</h1>

      <app-statistical-trading-plan-actions-menu [planUid]="plan.uid" [planName]="plan.name" (onSuccessfulDeletion)="planDeleted($event)" (onDeletionFailed)="planDeletionFailed($event)"
                                                 [floatTopRight]="true" (onOptimizationStarted)="optimizationStarted($event)" [automaticPlan]="plan.typeValue"></app-statistical-trading-plan-actions-menu>

      <h2 class="paragraph-headline-2">
        <app-plan-type-view [type]="plan.typeValue"></app-plan-type-view>
      </h2>
      <div class="my-3">
        <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
      </div>
      <div class="row my-3 plan-view">
        <!-- First column -->
        <div class="col vertical-cards-column">
          <!-- Capital -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Capital</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n matTooltip="The capital at the start of the plan" i18n-matTooltip>Seed capital</th>
                    <td>
                      <app-money [value]="plan.seedCapital" [currencyId]="plan.currency"></app-money>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="The current estimated depot value, if the plan was followed correctly" i18n-matTooltip>Current value</th>
                    <td>
                      <app-plan-value [trades]="plan.trades" phase="current" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-value>
                      <p class="small mb-0">
                        <app-plan-profit [trades]="plan.trades" phase="current" mode="absolute" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-profit>
                        <br>
                        <app-plan-profit [trades]="plan.trades" phase="current" mode="relative" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-profit>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="The estimated capital at the end of the plan" i18n-matTooltip>Final capital</th>
                    <td>
                      <app-plan-value [trades]="plan.trades" phase="end" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-value>
                      <p class="small mb-0">
                        <app-plan-profit [trades]="plan.trades" phase="end" mode="absolute" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-profit>
                        <br>
                        <app-plan-profit [trades]="plan.trades" phase="end" mode="relative" [seedCapital]="plan.seedCapital" [currencyId]="plan.currency"></app-plan-profit>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th matTooltip="The maximum capital spent on a single trade (relative to current depot value)" i18n-matTooltip i18n="maxCapitalPerTrade">Max. capital per trade</th>
                    <td>
                      <app-percentage-view [value]="plan.maxCapitalPerTrade"></app-percentage-view>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- Minimum profits -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Minimum avg. annual profits</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n="minAverageRelativeProfit">All time</th>
                    <td *ngIf="plan.minAverageRelativeProfit; else none">
                      <app-percentage-view [value]="plan.minAverageRelativeProfit"></app-percentage-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="minAverageRelativeProfitLast5Years">Last 5 years</th>
                    <td *ngIf="plan.minAverageRelativeProfitLast5Years; else none">
                      <app-percentage-view [value]="plan.minAverageRelativeProfitLast5Years"></app-percentage-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="minAverageRelativeProfitLast10Years">Last 10 years</th>
                    <td *ngIf="plan.minAverageRelativeProfitLast10Years; else none">
                      <app-percentage-view [value]="plan.minAverageRelativeProfitLast10Years"></app-percentage-view>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Second column -->
        <div class="col vertical-cards-column">
          <!-- Plan period -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Plan period</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n matTooltip="The date of the first trade in this plan" i18n-matTooltip>Start date</th>
                    <td>
                      <app-plan-start-or-end-date [trades]="plan.trades" phase="start"></app-plan-start-or-end-date>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="The date of the last trade in this plan" i18n-matTooltip>End date</th>
                    <td>
                      <app-plan-start-or-end-date [trades]="plan.trades" phase="end"></app-plan-start-or-end-date>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--  Criteria for statistical trades -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Criteria for statistical trades</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n="minWinRatio">Min. win ratio (%)</th>
                    <td *ngIf="plan.minWinRatio; else none">
                      <app-percentage-view [value]="plan.minWinRatio"></app-percentage-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="minPricePerStock">Min. price per stock</th>
                    <td *ngIf="plan.minPricePerStock; else none">
                      <app-money [value]="plan.minPricePerStock" [currencyId]="plan.currency"></app-money>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="maxAverageMaxDrop">Max. avg. max. drop (%)</th>
                    <td *ngIf="plan.maxAverageMaxDrop; else none">
                      <app-percentage-view [value]="plan.maxAverageMaxDrop"></app-percentage-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="minAverageVolumeBaseCurrency">Min. avg. volume</th>
                    <td *ngIf="plan.minAverageVolumeBaseCurrency; else none">
                      <app-money [value]="plan.minAverageVolumeBaseCurrency" [currencyId]="baseCurrency" [bigMoney]="true"></app-money>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="minMarketCap">Min. market cap</th>
                    <td *ngIf="plan.minMarketCap; else none">
                      <app-money [value]="plan.minMarketCap" [currencyId]="baseCurrency" [bigMoney]="true"></app-money>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="minDataYears">Min. data</th>
                    <td *ngIf="plan.minDataYears; else none" i18n>{plan.minDataYears, plural, =0 {0 years} =1 {1 year} other {{{plan.minDataYears}} years}}</td>
                  </tr>
                  <tr>
                    <th i18n="maxDuration">Max. duration</th>
                    <td *ngIf="plan.maxDuration; else none" i18n>{plan.maxDuration, plural, =0 {0 days} =1 {1 day} other {{{plan.maxDuration}} days}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Third column -->
        <div class="col vertical-cards-column">
          <!-- Trades counts -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Trades</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n matTooltip="The number of trades, that have already been realized (bought and sold)" i18n-matTooltip>Realized trades</th>
                    <td>
                      <app-realized-trades-count [trades]="plan.trades"></app-realized-trades-count>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="The outstanding number of trades to be realized (bought and sold) in the future" i18n-matTooltip>Future trades</th>
                    <td>
                      <app-future-trades-count [trades]="plan.trades"></app-future-trades-count>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--  Order fees and taxes -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Order fees and taxes</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n="orderFeeFixed">Order fee (fixed)</th>
                    <td *ngIf="plan.orderFeeFixed; else none">
                      <app-money [value]="plan.orderFeeFixed" [currencyId]="plan.currency"></app-money>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="orderFeeRelative">Order fee (%)</th>
                    <td *ngIf="plan.orderFeeRelative; else none">
                      <app-percentage-view [value]="plan.orderFeeRelative"></app-percentage-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n="taxRate">Tax rate (%)</th>
                    <td *ngIf="plan.taxRate; else none">
                      <app-percentage-view [value]="plan.taxRate"></app-percentage-view>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Fourth column -->
        <div class="col vertical-cards-column">
          <!-- Exchanges -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Exchanges</h5>
                <p class="card-text">
                  <app-exchanges-view *ngIf="plan.exchangeShortNames && plan.exchangeShortNames.length>0; else all" [exchangeShortNames]="plan.exchangeShortNames"></app-exchanges-view>
                  <ng-template #all i18n>All</ng-template>
                </p>
              </div>
            </div>
          </div>
          <!-- Included and excluded -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Included and excluded</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n matTooltip="Statistical trades that should be included in the plan. All already realized trades are automatically included." i18n-matTooltip>Included trades</th>
                    <td *ngIf="plan.includedTrades && plan.includedTrades.length > 0; else none">
                      <a (click)="showDataInModal('includedTrades');$event.preventDefault()" href="#" target="_blank" class="hover-pointer">{{plan.includedTrades.length}}</a>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="Statistical trades that should be excluded from the plan. Already realized trades are never excluded." i18n-matTooltip>Excluded trades</th>
                    <td *ngIf="plan.excludedTrades && plan.excludedTrades.length > 0; else none">
                      <a (click)="showDataInModal('excludedTrades');$event.preventDefault()" href="#" target="_blank" class="hover-pointer">{{plan.excludedTrades.length}}</a>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="Stock symbols that should be excluded from the plan. Already realized trades are never excluded." i18n-matTooltip>Excluded stocks</th>
                    <td *ngIf="plan.excludedSymbolCodes && plan.excludedSymbolCodes.length > 0; else none">
                      <a (click)="showDataInModal('excludedSymbolCodes');$event.preventDefault()" href="#" target="_blank" class="hover-pointer">{{plan.excludedSymbolCodes.length}}</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- Creation and edit dates -->
          <div class="col single-card-holder">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title" i18n>Creation and edit dates</h5>
                <table class="table table-sm table-striped">
                  <tbody>
                  <tr>
                    <th i18n matTooltip="The creation date of the plan" i18n-matTooltip>Created</th>
                    <td *ngIf="plan.creationDate; else none">
                      <app-date-view [date]="plan.creationDate" [showRelativeDate]="false" mode="compact-date-and-time"></app-date-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="The last edit date of the plan" i18n-matTooltip>Last edit</th>
                    <td *ngIf="plan.lastEditDate; else none">
                      <app-date-view [date]="plan.lastEditDate" [showRelativeDate]="false" mode="compact-date-and-time"></app-date-view>
                    </td>
                  </tr>
                  <tr>
                    <th i18n matTooltip="The last optimization date of the plan" i18n-matTooltip>Last optimization</th>
                    <td *ngIf="plan.lastOptimizationDate; else none">
                      <app-date-view [date]="plan.lastOptimizationDate" [showRelativeDate]="false" mode="compact-date-and-time"></app-date-view>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #none>
        <td>-</td>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="plan && plan.trades; else noTrades">
      <app-statistical-trading-plan-trades-view [plan]="plan" (planEdited)="onPlanEdited($event)"></app-statistical-trading-plan-trades-view>
    </ng-container>

    <ng-template #noTrades>
      <p i18n>This plan does not contain any trades yet.</p>
    </ng-template>

  </div>
</div>


