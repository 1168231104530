import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Symbol} from '../../models/symbol.interface';
import {BaseComponent} from '../base/base.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import Util from '../../util';
import {MAX_SYMBOLS_IN_AUTOCOMPLETE} from '../../constants/numbers';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
  selector: 'app-symbol-selector',
  templateUrl: './symbol-selector.component.html',
  styleUrls: ['./symbol-selector.component.scss'],
})
export class SymbolSelectorComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {

  exchanges?: string[];
  selectedExchangeSymbols?: Symbol[];
  symbolsByExchange = new Map<string, Symbol[]>();
  selectedExchange?: string;
  @Output() onSelectionChange = new EventEmitter<Symbol[]>();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectSymbolInput = new FormControl();
  filteredSymbols: Symbol[] = [];
  selectedSymbols: Symbol[] = [];
  @ViewChild('symbolInput') symbolInput?: ElementRef<HTMLInputElement>;
  private readonly spinnerKeySelectingExchange = 'selectExchange';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private cdRef: ChangeDetectorRef) {
    super(authService, userService, store);
    // this.selectSymbolInput.valueChanges.pipe(
    //   startWith(null),
    //   map((filterTerm?: string) => {
    //     this.filteredSymbols = (filterTerm ? this._filter(filterTerm) : []);
    //   }),
    // );
  }


  _symbols?: Symbol[];

  get symbols(): Symbol[] | undefined {
    return this._symbols;
  }

  @Input() set symbols(symbols: Symbol[] | undefined) {
    this._symbols = symbols;
    if (symbols)
      this.createExchangesListAndMap(symbols);
  }

  ngOnInit(): void {
  }

  onExchangeClick(exchange: string) {

    // If the exchange was already selected, unselect it.
    if (this.selectedExchange === exchange) {
      this.selectedExchange = undefined;
      this.selectedExchangeSymbols = [];
      return;
    }

    this.addLoadingSpinnerMessage(this.spinnerKeySelectingExchange, $localize`Selecting exchange ${exchange}`);
    this.selectedExchange = exchange;
    this.selectedExchangeSymbols = this.symbolsByExchange.get(exchange);
    this.removeLoadingSpinnerMessage(this.spinnerKeySelectingExchange);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  getColor(exchange: any) {
    return 'primary';
  }

  /**
   * Called, when a symbol code is entered into the filter input field and enter is pressed.
   * @param event
   */
  add(event: MatChipInputEvent): void {
    this.clearAlerts();

    if (!this.symbols || !event?.value || event.value.length === 0)
      return;

    const inputValue = (event.value);

    const foundSymbol = this.symbols.find(symbol => symbol.code.toLowerCase() === inputValue.toLowerCase());
    if (foundSymbol)
      this.selected(foundSymbol);
    else
      this.addWarning($localize`Symbol "${inputValue}" was not found.`);

    // Clear the input inputValue
    event.chipInput!.clear();
  }

  /**
   * Called, when a symbol is deleted from the list of selected symbols by clicking on a chip.
   * @param symbol symbol to be removed
   */
  remove(symbol: Symbol): void {
    this.clearAlerts();
    if (!this.symbols)
      return;

    // Remove from selected symbols
    const index = this.selectedSymbols.indexOf(symbol);

    if (index >= 0) {
      this.selectedSymbols.splice(index, 1);
    }

    // Add to all symbols
    this.symbols.push(symbol);
    this.symbols.sort((a, b) => Util.compare(a.name, b.name, true));

    this.emitSelectedSymbols();
  }

  /**
   * Called, when a symbol was selected from the dropdown.
   * @param selectedSymbol symbol, that has been selected
   */
  selected(selectedSymbol?: Symbol): void {
    this.clearAlerts();
    if (!this.symbols || !selectedSymbol)
      return;

    // Add to selected symbols
    this.selectedSymbols.push(selectedSymbol);

    // Remove from all symbols
    this.symbols = this.symbols.filter(symbol => symbol.code !== selectedSymbol.code);
    // Remove from filtered symbols
    this.filteredSymbols = this.filteredSymbols.filter(symbol => symbol.code !== selectedSymbol.code);

    // Clear input field
    if (this.symbolInput)
      this.symbolInput.nativeElement.value = '';
    this.selectSymbolInput.setValue(null);

    this.emitSelectedSymbols();
  }

  /**
   * Applies the given filter term to only show the wanted symbols in the dropdown of the search input field.
   * @param filterTerm symbol code or name
   */
  public filter(filterTerm: string): void {
    const filterValue = filterTerm.toLowerCase();
    if (!this.symbols) {
      this.filteredSymbols = [];
      return;
    }

    this.filteredSymbols = this.symbols.filter((symbol: Symbol) => symbol.code.toLowerCase().includes(filterValue) || symbol.name.toLowerCase().includes(filterValue)).splice(0, MAX_SYMBOLS_IN_AUTOCOMPLETE).sort((a, b) => Util.compare(a.name, b.name, true));
  }

  /**
   * Emits the current list of selected symbols.
   */
  private emitSelectedSymbols() {
    this.onSelectionChange.emit(this.selectedSymbols);
  }

  /**
   * Creates a list of all exchanges and a map of symbols per exchange for the given list of symbols.
   * @param symbols source for the list and map
   */
  private createExchangesListAndMap(symbols: Symbol[]) {
    this.exchanges = [];
    this.symbolsByExchange.clear();
    symbols?.forEach(symbol => {
      if (symbol.exchangeShortName) {
        if (!this.exchanges?.includes(symbol.exchangeShortName))
          this.exchanges?.push(symbol.exchangeShortName);
        const symbolsForExchange: Symbol[] = this.symbolsByExchange?.get(symbol.exchangeShortName) !== undefined ? this.symbolsByExchange.get(symbol.exchangeShortName)! : [];
        symbolsForExchange.push(symbol);
        this.symbolsByExchange.set(symbol.exchangeShortName, symbolsForExchange);
      }
    });
    this.exchanges.sort();
  }
}
