<ng-container *ngIf="symbols$ | async as symbols">

  <ng-container *ngIf="symbols.length > 0">

    <h2 class="paragraph-headline-2" i18n>Search Results</h2>
    <table class="table table-striped table-responsive-sm table-sm table-sticky-header">
      <thead>
      <tr>
        <th *ngIf="user">&nbsp;</th>
        <th i18n="symbol name">Name</th>
        <th i18n="exchange">Exchange</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let symbol of symbols">
        <tr>
          <td *ngIf="user">
            <app-watch-list-star [symbolCode]="symbol.code" [floatTopRight]="false"></app-watch-list-star>
          </td>
          <td>
            <a *ngIf="symbol.code && routerLinkPath" (click)="isClickable ? onSymbolClick(symbol) : null" [routerLink]="appendSymbolCodeToRouterLinkPath(symbol.code)">{{symbol.name}}</a>
            <a *ngIf="symbol.code && !routerLinkPath" (click)="isClickable ? onSymbolClick(symbol) : null">{{symbol.name}}</a>
            <a *ngIf="!symbol.code && !routerLinkPath" (click)="isClickable ? onSymbolClick(symbol) : null" class="hover-pointer">{{symbol.name}}</a>
          </td>
          <td><a (click)="selectExchange(symbol.exchangeShortName)" *ngIf="symbol.exchangeShortName" class="hover-pointer">
            <app-exchange-descriptor [exchangeShortName]="symbol.exchangeShortName" mode="nameAndCode"></app-exchange-descriptor>
          </a></td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <p *ngIf="symbols" class="mx-0 mt-3" i18n="message shown symbols count and total symbols count">Showing {{symbols.length}} of {{searchTotalCount$ | async}} symbols matching your search criteria.</p>

    <div *ngIf="thereIsMore$ | async">
      <button #showMoreButton (click)="onClickShowMore()" class="btn btn-primary mt-0 mb-3" i18n="show more" id="show-more-button" type="button">Show more</button>
    </div>
  </ng-container>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

</ng-container>
