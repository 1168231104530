<div class="parent-component">
  <app-watch-list-star [symbolCode]="symbolCode" [floatTopRight]="true"></app-watch-list-star>

  <ng-container *ngIf="alerts && alerts.length > 0">
    <div class="mb-3">
      <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
    </div>
  </ng-container>

  <app-please-wait-loading [active]="showLoadingSpinner" [messagesByKey]="loadingSpinnerMessagesByKey"
                           [messagesByKey]="loadingSpinnerMessagesByKey"></app-please-wait-loading>

  <div *ngIf="!showLoadingSpinner">

    <div *ngIf="!symbol">
      <h1 [ngClass]="user ? 'paragraph-headline leave-room-for-star' : 'paragraph-headline'">
        <app-symbol-descriptor [nameOnly]="true" [symbolCode]="symbolCode"></app-symbol-descriptor>
      </h1>
      <p i18n="unknown symbol paragraph">No data is available for this symbol.</p>
    </div>

    <div *ngIf="symbol">
      <h1 [ngClass]="user ? 'paragraph-headline leave-room-for-star' : 'paragraph-headline'">
        <app-symbol-descriptor [nameOnly]="true" [symbol]="symbol"></app-symbol-descriptor>
      </h1>
      <div class="mb-3">
        <app-company-profile-view [symbol]="symbol" (symbolRefreshed)="onSymbolRefreshed($event)"></app-company-profile-view>
      </div>

      <h2 class="paragraph-headline-2" i18n="Best statistical returns headline">Best statistical returns</h2>

      <app-statistical-returns-table-loader [symbolCode]="symbolCode" [symbol]="symbol"></app-statistical-returns-table-loader>

    </div>
  </div>

</div>
