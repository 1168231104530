import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SymbolDescriptorComponent} from '../../../shared/components/symbol-descriptor/symbol-descriptor.component';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {StockService} from '../../../shared/services/stock.service';
import {AppState} from '../../../store/app.state';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-symbol-exchange-columns',
  templateUrl: './symbol-exchange-columns.component.html',
  styleUrls: ['./symbol-exchange-columns.component.scss'],
})
export class SymbolExchangeColumnsComponent extends SymbolDescriptorComponent {

  @Input() showExchange = false;
  @Input() statisticalReturnUid?: string;
  @Input() nameMaxLength: number | undefined = undefined;
  @Output() onExchangeClicked = new EventEmitter<string>();

  constructor(
      authService: AuthService,
      userService: UserService,
      stockService: StockService,
      store: Store<AppState>) {
    super(authService, userService, store, stockService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onExchangeClick(exchangeShortName: string) {
    this.onExchangeClicked.emit(exchangeShortName);
  }
}
