import {AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';
import {StatisticalTradingService} from '../../../statistical-trading.service';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UtilService} from '../../../../shared/util.service';
import {SymbolAndStatisticalReturnUid} from '../../../../shared/models/symbolAndStatisticalReturnUid.interface';

export type DataType = 'excludedSymbolCodes' | 'excludedTrades' | 'includedTrades'

@Component({
  selector: 'app-included-excluded-modal',
  templateUrl: './included-excluded-modal.component.html',
  styleUrls: ['./included-excluded-modal.component.scss'],
})
export class IncludedExcludedModalComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentChecked {


  _dataType?: DataType;

  get dataType(): DataType | undefined {
    return this._dataType;
  }

  @Input() set dataType(dataType: DataType | undefined) {
    this._dataType = dataType;
    this.init();
  }

  _itemUids?: string[];

  get itemUids(): string[] | undefined {
    return this._itemUids;
  }

  @Input() set itemUids(itemUids: string[] | undefined) {
    this._itemUids = itemUids;
    this.init();
  }

  private init() {
    if (!this.dataType || !this.itemUids)
      return;

    switch (this.dataType) {
      case 'includedTrades':
      case 'excludedTrades':
        this.loadStatisticalReturns();
        break;
    }
  }

  @Output() onDeleteStatisticalReturnClicked = new EventEmitter<SymbolAndStatisticalReturnUid>();
  @Output() onDeleteSymbolClicked = new EventEmitter<string>();

  statisticalReturns: StatisticalReturn[] = [];
  private readonly spinnerKeyStatisticalReturns = 'fetchStatisticalReturns';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      public utilService: UtilService,
      public activeModal: NgbActiveModal,
      private cdRef: ChangeDetectorRef,
      private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ESC') {
      this.activeModal.dismiss('ESC press');
    }
  }


  private loadStatisticalReturns() {
    const watchedStatisticalReturns: SymbolAndStatisticalReturnUid[] = this.createSymbolAndStatisticalReturnUids();
    if (!watchedStatisticalReturns)
      return;
    this.statisticalReturns = [];
    watchedStatisticalReturns.forEach(it => {
          this.addLoadingSpinnerMessage(this.spinnerKeyStatisticalReturns, $localize`Fetching statistical returns`);

          this.statisticalTradingService.fetchStatisticalReturn(it.symbolCode, it.statisticalReturnUid).then(wrapper => {
            this.removeLoadingSpinnerMessage(this.spinnerKeyStatisticalReturns);
            if (wrapper.data) {
              this.statisticalReturns.push(wrapper.data);
            }
            if (wrapper.errorMessage)
              this.addError($localize`Error loading statistical trade ${it.symbolCode}\: ${it.statisticalReturnUid}\: ${wrapper.errorMessage}`);
          });
        },
    );
  }

  private createSymbolAndStatisticalReturnUids() {
    if (!this.itemUids || this.itemUids.length === 0)
      return [];
    const symbolAndStatisticalReturnUids: SymbolAndStatisticalReturnUid[] = [];
    this.itemUids.forEach(it => {
      let symbolAndStatisticalReturnUid = StatisticalTradingService.determineSymbolAndStatisticalReturnUid(it);
      if (symbolAndStatisticalReturnUid)
        symbolAndStatisticalReturnUids.push(symbolAndStatisticalReturnUid);
    });
    return symbolAndStatisticalReturnUids;
  }

  deleteTrade(symbolAndStatisticalReturnUid: SymbolAndStatisticalReturnUid) {
    if (symbolAndStatisticalReturnUid)
      this.onDeleteStatisticalReturnClicked.emit(symbolAndStatisticalReturnUid);
  }

  deleteSymbol(symbolCode: string) {
    if (symbolCode)
      this.onDeleteSymbolClicked.emit(symbolCode);
  }
}
