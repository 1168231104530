<h1 class="paragraph-headline" i18n>Upcoming statistical trades</h1>

<app-info-text page="upcoming-statistical-trades" part="introduction"></app-info-text>


<div class="my-3">
  <app-alerts [alerts]="alerts" [float]="false" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>
</div>

<ng-container *ngIf="statisticalReturns$ | async as statisticalReturns">

  <app-statistical-return-search-panel viewType="upcoming"></app-statistical-return-search-panel>

  <ng-container *ngIf="statisticalReturns.length > 0; else noStatisticalReturns">

    <app-statistical-returns-table [showName]="true" [statisticalReturns]="statisticalReturns" [sortable]="true" viewType="upcoming"
                                   [showExchange]="true" (onExchangeClicked)="onExchangesSelectionChanged([$event])"></app-statistical-returns-table>

    <p *ngIf="statisticalReturns" class="mx-0 my-3" i18n="message shown best upcoming statisticalReturns count">Showing
      the best {{statisticalReturns.length}} upcoming statistical returns of all stock symbols</p>

    <app-mat-icon-button *ngIf="thereIsMore$ | async" #showMoreButton [callback]="onClickShowMore.bind(this)" [params]="[]" color="primary" cssClass="mb-3"
                         i18n-label="show more" icon="expand_more" id="show-more-button"
                         label="Show more"></app-mat-icon-button>

  </ng-container>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [messagesByKey]="loadingSpinnerMessagesByKey"
                       [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <ng-template #noStatisticalReturns>
    <ng-container *ngIf="!(searching$ | async)">
      <div class="alert alert-info" i18n>We do not know of any upcoming statistical trading opportunities on the selected day.</div>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="statisticalReturns.length > 0">
    <app-info-text page="upcoming-statistical-trades" part="description"></app-info-text>
  </ng-container>

</ng-container>
