import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountComponent} from './account.component';
import {AccountOverviewComponent} from './account-overview/account-overview.component';
import {SettingsComponent} from './settings/settings.component';
import {PersonalInfoComponent} from './personal-info/personal-info.component';
import {WatchListComponent} from './watch-list/watch-list.component';
import {TableColumnsComponent} from './table-columns/table-columns.component';

const accountRoutes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
      {path: 'overview', component: AccountOverviewComponent, data: {reuse: false}},
      {path: 'settings', component: SettingsComponent, data: {reuse: false}},
      {path: 'personal-info', component: PersonalInfoComponent},
      {path: 'watch-list', component: WatchListComponent},
      {path: 'watch-list/:type', component: WatchListComponent},
      {path: 'table-columns', component: TableColumnsComponent},
      {path: 'table-columns/:type', component: TableColumnsComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(accountRoutes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {
}
