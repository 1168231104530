<div [ngClass]="floatTopRight ? 'float-top-right' : ''">
  <button mat-icon-button [matMenuTriggerFor]="actionsMenu" aria-label="Actions" i18n-aria-label>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #actionsMenu="matMenu">
    <button mat-menu-item (click)="editTradeClicked()">
      <mat-icon>edit</mat-icon>
      <span i18n="Edit button|Edit button">Edit</span>
    </button>
    <button mat-menu-item (click)="deleteTradeClicked()">
      <mat-icon>delete</mat-icon>
      <span i18n>Delete</span>
    </button>
  </mat-menu>
</div>
