import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  constructor() {
  }

  @Input() pages?: BreadcrumbPage[];
  @Input() currentPageTitle?: string;

  ngOnInit(): void {
  }

}

export interface BreadcrumbPage {
  routerLink: any[] | string | null | undefined;
  title: string;
}
