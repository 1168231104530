import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'valueClassPipe',
})
export class ValueClassPipe implements PipeTransform {

  transform(value: number | null | undefined): string {
    if (value === undefined || value === null)
      return '';

    if (value > 0) {
      return 'good-value';
    } else if (value === 0) {
      return 'neutral-value';
    } else {
      return 'bad-value';
    }
  }

}
