import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {StatisticalReturnViewType} from '../../../shared/types/statisticalReturnViewType.type';
import {Observable} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {selectBestStatisticalReturnSearchMinDataYears, selectUpcomingStatisticalReturnSearchMinDataYears} from '../../../store/stock.selectors';
import {MinDataYearsEditDialogComponent} from './min-data-years-edit-dialog/min-data-years-edit-dialog.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-min-data-years-filter',
  templateUrl: './min-data-years-filter.component.html',
  styleUrls: ['./min-data-years-filter.component.scss'],
})
export class MinDataYearsFilterComponent extends BaseComponent implements OnInit {

  @Input() viewType?: StatisticalReturnViewType;

  isFilterActive = false;

  minDataYears$?: Observable<number | undefined>;

  private editDialog?: MatDialogRef<any, any>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    private statisticalTradingService: StatisticalTradingService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.viewType === 'upcoming') {
      this.minDataYears$ = this.store.select(selectUpcomingStatisticalReturnSearchMinDataYears);
    }
    if (this.viewType === 'best') {
      this.minDataYears$ = this.store.select(selectBestStatisticalReturnSearchMinDataYears);
    }

    this.minDataYears$?.pipe(takeUntil(this.destroy$)).subscribe(minDataYears => {
      if (minDataYears)
        this.isFilterActive = true;
      else
        this.isFilterActive = false;
    });
  }

  openEditDialog(mouseEvent: MouseEvent): void {
    this.editDialog = this.statisticalTradingService.openEditFilterDialog(MinDataYearsEditDialogComponent, $localize`Min. data years`, this.viewType, undefined, 350);
  }

}
