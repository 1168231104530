<app-info-card i18n>You can modify the column visibility and order by using the drag-and-drop functionality. Simply drag the desired columns from the "Visible" section to the "Hidden" section or vice versa to achieve the desired configuration.</app-info-card>
<div class="row mt-3">
  <div class="col">
    <h3 class="paragraph-headline-3" i18n>Visible columns</h3>

    <div
            cdkDropList
            #visibleList="cdkDropList"
            [cdkDropListData]="visible"
            [cdkDropListConnectedTo]="[hiddenList]"
            class="list"
            (cdkDropListDropped)="drop($event)">
      <div class="column-box" *ngFor="let item of visible" cdkDrag>{{item.label}}</div>
    </div>
  </div>
  <div class="col">
    <h3 class="paragraph-headline-3" i18n>Hidden columns</h3>
    <div
            cdkDropList
            #hiddenList="cdkDropList"
            [cdkDropListData]="hidden"
            [cdkDropListConnectedTo]="[visibleList]"
            class="list"
            (cdkDropListDropped)="drop($event)">
      <div class="column-box" *ngFor="let item of hidden" cdkDrag>{{item.label}}</div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col">
    <app-mat-icon-button label="Save" i18n-label [callback]="onSave.bind(this)" icon="save" cssClass="mr-2"></app-mat-icon-button>
    <app-mat-icon-button color="warn" label="Reset to defaults" i18n-label [callback]="onResetToDefault.bind(this)" icon="restart_alt"></app-mat-icon-button>
  </div>
</div>
