import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from '../../../../shared/components/base-dialog/base-dialog.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  selectBestStatisticalReturnSearchMinLowestYearlyProfit,
  selectUpcomingStatisticalReturnSearchMinLowestYearlyProfit,
} from '../../../../store/stock.selectors';
import {takeUntil} from 'rxjs/operators';
import {StatisticalReturnsSearchParams} from '../../../../shared/models/statisticalReturnsSearchParams.interface';
import {mergeUpdateBestStatisticalReturnSearchParams, mergeUpdateUpcomingStatisticalReturnSearchParams} from '../../../../store/stock.actions';

@Component({
  selector: 'app-min-lowest-yearly-profit-edit-dialog',
  templateUrl: './min-lowest-yearly-profit-edit-dialog.component.html',
  styleUrls: ['./min-lowest-yearly-profit-edit-dialog.component.scss'],
})
export class MinLowestYearlyProfitEditDialogComponent extends BaseDialogComponent implements OnInit {

  form: FormGroup = this.createForm();

  minLowestYearlyProfit$?: Observable<number | undefined>;

  constructor(
    authService: AuthService,
    userService: UserService,
    store: Store<AppState>,
    dialogRef: MatDialogRef<MinLowestYearlyProfitEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) {
    super(authService, userService, store, dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.data.viewType === 'upcoming') {
      this.minLowestYearlyProfit$ = this.store.select(selectUpcomingStatisticalReturnSearchMinLowestYearlyProfit);
    }
    if (this.data.viewType === 'best') {
      this.minLowestYearlyProfit$ = this.store.select(selectBestStatisticalReturnSearchMinLowestYearlyProfit);
    }

    this.minLowestYearlyProfit$?.pipe(takeUntil(this.destroy$)).subscribe(minLowestYearlyProfit => {
      this.form.patchValue({minLowestYearlyProfit});
    });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      minLowestYearlyProfit: [''],
    });
  }

  saveMinLowestYearlyProfit(minLowestYearlyProfitString: string) {
    if (!minLowestYearlyProfitString)
      return this.clearMinLowestYearlyProfit();
    const minLowestYearlyProfit = parseInt(minLowestYearlyProfitString);
    if (isNaN(minLowestYearlyProfit))
      return;
    let searchParams: StatisticalReturnsSearchParams = {minLowestYearlyProfit};
    this.mergeUpdateSearchParams(searchParams);
  }

  clearMinLowestYearlyProfit() {
    let searchParams: StatisticalReturnsSearchParams = {minLowestYearlyProfit: undefined};
    this.mergeUpdateSearchParams(searchParams);
  }

  private mergeUpdateSearchParams(searchParams: StatisticalReturnsSearchParams) {
    if (this.data.viewType === 'upcoming')
      this.store.dispatch(mergeUpdateUpcomingStatisticalReturnSearchParams({searchParams}));
    if (this.data.viewType === 'best')
      this.store.dispatch(mergeUpdateBestStatisticalReturnSearchParams({searchParams}));
  }
}
