import {StatisticalTradingPlan} from '../shared/models/statisticalTradingPlan.interface';

export interface PlannerState {
  statisticalTradingPlans: StatisticalTradingPlan[];
}


export const initialState: PlannerState = {
  statisticalTradingPlans: [],
};
