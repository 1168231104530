import firebase from 'firebase';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export interface StatisticalReturnsSearchParams {
  symbolCode?: string;
  exchangeShortNames?: string[];
  limit?: number;
  startAfter?: DocumentSnapshot;
  buyDayNumber?: number;
  minAverageRelativeProfit?: number;
  minAverageVolume?: number;
  minAverageVolumeBaseCurrency?: number;
  minDataYears?: number;
  maxDuration?: number;
  minLowestYearlyProfit?: number;
  minWinRatio?: number;
  minAverageMaxRise?: number;
  maxAverageMaxDrop?: number;
  minHighestRise?: number;
  maxHighestDrop?: number;
  minMarketCap?: number;
  sortOrder?: StatisticalReturnsSortOrder;
  minPeriodWinRatios?: PeriodWinRatio[];
}

export enum StatisticalReturnsSortOrder {
  SymbolNameAsc = 'symbolNameAsc',
  SymbolCodeAsc = 'symbolCodeAsc',
  ExchangeNameAsc = 'exchangeNameAsc',
  ExchangeShortNameAsc = 'exchangeShortNameAsc',
  CurrencyAsc = 'currencyAsc',
  BuyDateAsc = 'buyDateAsc',
  SellDateAsc = 'sellDateAsc',
  PriceAsc = 'priceAsc',
  DurationAsc = 'durationAsc',
  AverageRelativeProfitAsc = 'avgRelativeProfitAsc',
  WorstYearAsc = 'worstYearAsc',
  DataYearsAsc = 'dataYearsAsc',
  WinRatioAsc = 'winRatioAsc',
  AvgMaxRiseAsc = 'avgMaxRiseAsc',
  AvgMaxDropAsc = 'avgMaxDropAsc',
  MarketCapAsc = 'marketCapAsc',
  AvgVolumeAsc = 'avgVolumeAsc',
  AvgVolumePriceAsc = 'avgVolumePriceAsc',
  AvgVolumeBaseCurrencyeAsc = 'avgVolumeBaseCurrencyeAsc',
  HighestMaxRiseAsc = 'highestMaxRiseAsc',
  HighestMaxDropAsc = 'highestMaxDropAsc',
  SymbolNameDesc = 'symbolNameDesc',
  SymbolCodeDesc = 'symbolCodeDesc',
  ExchangeNameDesc = 'exchangeNameDesc',
  ExchangeShortNameDesc = 'exchangeShortNameDesc',
  CurrencyDesc = 'currencyDesc',
  BuyDateDesc = 'buyDateDesc',
  SellDateDesc = 'sellDateDesc',
  PriceDesc = 'priceDesc',
  DurationDesc = 'durationDesc',
  AverageRelativeProfitDesc = 'avgRelativeProfitDesc',
  WorstYearDesc = 'worstYearDesc',
  DataYearsDesc = 'dataYearsDesc',
  WinRatioDesc = 'winRatioDesc',
  AvgMaxRiseDesc = 'avgMaxRiseDesc',
  AvgMaxDropDesc = 'avgMaxDropDesc',
  MarketCapDesc = 'marketCapDesc',
  AvgVolumeDesc = 'avgVolumeDesc',
  AvgVolumePriceDesc = 'avgVolumePriceDesc',
  AvgVolumeBaseCurrencyDesc = 'avgVolumeBaseCurrencyDesc',
  HighestMaxRiseDesc = 'highestMaxRiseDesc',
  HighestMaxDropDesc = 'highestMaxDropDesc',
}

export interface PeriodWinRatio {
  period: string;
  minWinRatio?: number;
}
