import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.state';

export const selectLayout = (state: AppState) => state.layout;

export const selectFaqs = createSelector(
    selectLayout,
    (layoutState) => layoutState.faqs,
);

export const selectFaqsLastFetch = createSelector(
    selectLayout,
    (layoutState) => layoutState.faqsLastFetch,
);

export const selectAnnouncementsLastFetch = createSelector(
    selectLayout,
    (layoutState) => layoutState.announcementsLastFetch,
);

