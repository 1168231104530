import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {AuthService} from '../../../../auth/auth.service';
import {UserService} from '../../../../shared/services/user.service';
import {Symbol} from '../../../../shared/models/symbol.interface';
import {StatisticalTradingService} from '../../../statistical-trading.service';
import {StatisticalReturn} from '../../../../shared/models/statisticalReturn.interface';
import {UtilService} from '../../../../shared/util.service';
import {StockService} from '../../../../shared/services/stock.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';

@Component({
  selector: 'app-admin-options',
  templateUrl: './admin-options.component.html',
  styleUrls: ['./admin-options.component.scss'],
})
export class AdminOptionsComponent extends BaseComponent implements OnInit {

  @Input() symbol?: Symbol;
  analysing = false;
  analysisFinished = false;
  @Output() onStatisticalReturnsLoaded = new EventEmitter<StatisticalReturn[]>();

  private readonly analyzeStatisticalReturns = 'analyzeStatisticalReturns';
  private readonly spinnerKeyFetchingSymbol = 'fetchSymbol';

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private statisticalTradingService: StatisticalTradingService,
      private stockService: StockService,
      public utilService: UtilService,
  ) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public analyze() {
    if (!this.symbol)
      return;

    this.analysing = true;
    this.addLoadingSpinnerMessage(this.analyzeStatisticalReturns, $localize`Analyzing statistical returns`);
    this.clearAlerts();
    this.statisticalTradingService.analyzeStatisticalReturns(this.symbol.code).then(wrapper => {
      this.analysing = false;
      this.removeLoadingSpinnerMessage(this.analyzeStatisticalReturns);
      if (wrapper.errorMessage && !wrapper.success) {
        this.addError(wrapper.errorMessage);
      } else {
        this.addSuccess($localize`Successfully analyzed symbol ${this.symbol?.name}.`);
        if (wrapper.data) {
          this.analysisFinished = true;
          this.onStatisticalReturnsLoaded.next(wrapper.data);
        }
      }
    });
  }


  fetchSymbol(symbolCode: string) {
    this.addLoadingSpinnerMessage(this.spinnerKeyFetchingSymbol, $localize`Fetching symbol ${symbolCode}`);
    this.stockService.fetchSymbolFromFirestore(symbolCode, 0).then(wrapper => {
      this.removeLoadingSpinnerMessage(this.spinnerKeyFetchingSymbol);
      if (wrapper.data)
        this.symbol = wrapper.data;
      if (wrapper.errorMessage)
        this.addError(wrapper.errorMessage);
    });
  }
}
