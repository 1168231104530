<div class="container bg-container p-0">
  <ngb-carousel #imageCarousel *ngIf="imgNames.length>0" [animation]="false" [interval]="10000" [pauseOnHover]="false" [showNavigationArrows]="false"
                [showNavigationIndicators]="false" class="animatedCarousel my-1">
    <ng-template (slid)="titleImageActiveIndex = i" *ngFor="let imgName of imgNames; index as i" ngbSlide>
      <div class="wrapper">
        <picture *ngIf="isImageActive(i)">
          <source *ngFor="let width of imgWidths" [srcset]="imgPath+imgName+'-w'+width+imgExtension" media="(max-width: {{width}}px) and (max-height: {{width/3*2}}px)">
          <img [src]="imgPath+imgName+'-w1132'+imgExtension" alt="Stock Forecast start page background image {{imgName}}"
               class="carousel" i18n-alt="Alt text of Stock Forecast start page background image">
        </picture>
        <div class="carousel-caption center">
          <div class="container main-slogan-container m-0 p-0">
            <h1 class="carousel-headline text-shadow-primary" i18n="Start page headline borrow">Stock Forecast</h1>
            <p class="carousel-paragraph text-shadow-primary" i18n="start page paragraph rent out">Stock analysis and predictions</p>
          </div>
        </div>

      </div>
    </ng-template>
  </ngb-carousel>
</div>

<div class="container bg-container p-2">

  <h1 class="headline center" i18n="start page our goal headline">Our goal</h1>
  <p i18n="start page our goal statistical trading paragraph">
    Our mission is to provide you with the most accurate and reliable stock predictions. Our team of experts has spent countless hours analyzing historic stock data to
    identify patterns and trends that can be used to predict future movements in the market.
    We use a <a [routerLink]="['statistical-trading']">statistical trading</a> approach to identify opportunities for profitable investments, such as stocks that tend to rise
    in value before major events like Black Friday. By using our data-driven insights, you can make informed investment decisions that have the potential to
    generate good profits from holding a stock for as little as a few weeks.
    With our cutting-edge analysis and reliable predictions, you can confidently navigate the complex world of stock trading and achieve your financial goals.
  <p i18n="start page our goal dividends paragraph">
    In addition to our data-driven analysis of stock prices, we also provide comprehensive information about upcoming dividend payments.
    Our team analyzes the dividend schedules of all stock symbols to calculate the dividend yields, and we present the most promising dividend opportunities to our users.
    By leveraging our expertise in <a [routerLink]="['dividends']">dividend analysis</a>, you can make informed investment decisions that have the potential
    to earn you steady, reliable income over the long term.
    Whether you're a seasoned investor or just starting out, our dividend analysis tools and insights can help you achieve your financial goals.
  </p>
  <div class="card-deck">

    <div class="card info-card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['statistical-trading']" class="stretched-link">
            <span class="material-icons card-icon text-primary">trending_up</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['statistical-trading']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="personal info headline">Statistical trading</h2>
              <p class="card-text" i18n="statistical trading lead text">Make money with statistical regularities - Some stocks have tendencies to make huge increases in value during certain time periods each year. We show you which ones these are.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card info-card mb-3">
      <div class="row no-gutters">
        <div>
          <a [routerLink]="['dividends']" class="stretched-link">
            <span class="material-icons card-icon text-primary">payments</span>
          </a>
        </div>
        <div class="col">
          <div class="card-body">
            <a [routerLink]="['dividends']" class="stretched-link">
              <h2 class="paragraph-headline-2" i18n="settings headline">Dividends</h2>
              <p class="card-text" i18n="settings lead text">Find out which stocks have the best dividend yields and when to buy them.</p>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

