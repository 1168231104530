import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  selectSimulationSearchErrorMessage,
  selectSimulationSearchSearching,
  selectSimulationSearchSimulationResults,
  selectSimulationSearchThereIsMore,
} from '../../../store/simulation.selectors';
import {AuthService} from '../../../auth/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../shared/services/title.service';
import Util from '../../../shared/util';
import {StatisticalTradingService} from '../../statistical-trading.service';
import {setSimulationSearchExchangeShortNames} from '../../../store/simulation.actions';

@Component({
  selector: 'app-simulation-search',
  templateUrl: './simulation-search.component.html',
  styleUrls: ['./simulation-search.component.scss'],
})
export class SimulationSearchComponent extends BaseComponent implements OnInit {
  @ViewChild('showMoreButton') public showMoreButtonElementRef?: ElementRef;

  simulationResults$ = this.store.select(selectSimulationSearchSimulationResults);
  searchErrorMessage$ = this.store.select(selectSimulationSearchErrorMessage);
  thereIsMore$ = this.store.select(selectSimulationSearchThereIsMore);
  searching$ = this.store.select(selectSimulationSearchSearching);

  constructor(
      authService: AuthService,
      userService: UserService,
      private titleService: TitleService,
      private statisticalTradingService: StatisticalTradingService,
      store: Store<AppState>) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Simulations`);
    this.searchErrorMessage$.pipe(takeUntil(this.destroy$)).subscribe(errorMessage => {
      if (errorMessage)
        this.addError(errorMessage);
      else
        this.clearErrors();
    });
  }

  /**
   * Called, when the Show more button is clicked. Loads more trades.
   */
  onClickShowMore(): void {
    this.statisticalTradingService.fetchMoreStatisticalSimulationResults();
    Util.scrollToElementRef(this.showMoreButtonElementRef);
  }

  onExchangesClicked(exchangeShortNames: string[]) {
    this.store.dispatch(setSimulationSearchExchangeShortNames({exchangeShortNames}));
  }
}
