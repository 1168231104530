<app-base-dialog [templateRef]='childTemplate'></app-base-dialog>
<ng-template #childTemplate>

  <p i18n>Please enter the maximum duration in days to filter by.</p>
  <form [formGroup]="form">

    <div class="row mx-0 mb-1">

      <div class="col-auto px-1">

        <mat-form-field class="input-md">
          <mat-label i18n-matTooltip matTooltip="Max duration in days" i18n>Max. duration</mat-label>
          <input cdkFocusInitial #maxDurationInput type="number" matInput name="maxDuration" formControlName="maxDuration" placeholder="15" id="maxDuration"
                 [min]="0" [max]="30" (change)="saveMaxDuration(maxDurationInput.value)">
          <mat-icon (click)="clearMaxDuration()" *ngIf="maxDurationInput.value" aria-label="Clear" i18n-matTooltip
                    matSuffix matTooltip="Clear" type="button">close
          </mat-icon>
        </mat-form-field>

      </div>
    </div>
  </form>

</ng-template>
