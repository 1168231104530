<div class="container bg-container px-1">

  <!-- Breadcrumb-->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <a class="breadcrumb-item" i18n="Admin" routerLink="..">Admin</a>
      <li class="breadcrumb-item">
        <span i18n="tools headline">Tools</span>
      </li>
    </ol>
  </nav>

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="tools headline">Tools</h1>
    <p class="jumbotron-lead" i18n="tools lead text">Miscellaneous admin tools. Use with cautions</p>
  </div>

  <app-alerts [alerts]="alerts" [scrollToSubject]="scrollToAlertsSubject"></app-alerts>

  <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

  <h1 class="paragraph-headline" i18n>Delete old statistical returns </h1>
  <p i18n>Delete all statistical returns with a previous analysis version.</p>
  <app-mat-icon-button [callback]="onDeleteOldStatisticalReturnsClicked.bind(this)" color="primary" cssClass="mr-2 mb-3" i18n-label icon="delete"
                       label="Delete old statistical returns"></app-mat-icon-button>

</div>
