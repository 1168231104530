import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from './admin.component';
import {AdminOverviewComponent} from './admin-overview/admin-overview.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AdminRoutingModule} from './admin-routing.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FaqsEditorComponent} from './faqs-editor/faqs-editor.component';
import {FaqsEditorFormComponent} from './faqs-editor/faqs-editor-form/faqs-editor-form.component';
import {LayoutModule} from '../layout/layout.module';
import {NoFaqShownComponentComponent} from './faqs-editor/no-faq-shown-component/no-faq-shown-component.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxEditorModule} from 'ngx-editor';
import {SchedulesEditorComponent} from './schedules-editor/schedules-editor.component';
import {SchedulesEditorFormComponent} from './schedules-editor/schedules-editor-form/schedules-editor-form.component';
import {NoScheduleShownComponent} from './schedules-editor/no-schedule-shown/no-schedule-shown-component';
import {SchedulesComponent} from './schedules-editor/schedules/schedules.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {StatisticalTradingModule} from '../statistical-trading/statistical-trading.module';
import {CurrenciesEditorComponent} from './currencies-editor/currencies-editor.component';
import {CurrenciesEditorFormComponent} from './currencies-editor/currencies-editor-form/currencies-editor-form.component';
import {NoCurrencyShownComponent} from './currencies-editor/no-currency-shown/no-currency-shown.component';
import { CurrenciesComponent } from './currencies-editor/currencies/currencies.component';
import { ToolsComponent } from './tools/tools.component';


@NgModule({
  declarations: [
    AdminComponent,
    AdminOverviewComponent,
    FaqsEditorComponent,
    FaqsEditorFormComponent,
    NoFaqShownComponentComponent,
    SchedulesEditorComponent,
    SchedulesEditorFormComponent,
    NoScheduleShownComponent,
    SchedulesComponent,
    CurrenciesEditorComponent,
    NoCurrencyShownComponent,
    CurrenciesEditorFormComponent,
    CurrenciesComponent,
    ToolsComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    AdminRoutingModule,
    DragDropModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    LayoutModule,
    MatSliderModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxEditorModule,
    MatExpansionModule,
    MatChipsModule,
    StatisticalTradingModule,
  ],
})
export class AdminModule {
}
