import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-year-header',
  templateUrl: './year-header.component.html',
  styleUrls: ['./year-header.component.scss'],
})
export class YearHeaderComponent implements OnInit {

  @Input() year?: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
