<ng-container *ngIf="symbol">
  <table class="table table-striped table-sm table-responsive">
    <thead>
    <tr>
      <th i18n>Symbol code</th>
      <th i18n>Exchange</th>
    </thead>
    <tbody>
    <tr>
      <td>{{symbol?.code}}</td>
      <td>
        <app-exchange-descriptor [exchangeShortName]="symbol.exchangeShortName" mode="nameAndCode"></app-exchange-descriptor>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>
