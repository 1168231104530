import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {MetadataService} from '../../shared/services/metadata.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent extends BaseComponent implements OnInit {

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private metadataService: MetadataService,
      private titleService: TitleService) {
    super(authService, userService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Privacy policy`);
    this.metadataService.updateTags($localize`Privacy policy`,
        $localize`Read all about our privacy policy.`,
        $localize`privacy policy, data protection, GDPR, DSGVO`,
    );
  }

}
