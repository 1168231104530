import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {Subject} from 'rxjs';
import {Wrapper} from '../models/wrapper.model';
import {Mutex} from 'async-mutex';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FunctionsService} from './functions.service';
import {AuthService} from '../../auth/auth.service';
import {BaseComponent} from '../components/base/base.component';
import {UserService} from './user.service';


@Injectable({
  providedIn: 'root',
})
export class BackendService extends BaseComponent {

  destroy$: Subject<null> = new Subject();
  stockMutex = new Mutex();
  apiPublicKey?: string;
  apiSecretKey?: string;
  apiSecretKeyParam ?: HttpParams;
  apiPublicKeyParam ?: HttpParams;
  public apiSecretKeyReadySubject = new Subject<Boolean>();

  constructor(
      authService: AuthService,
      userService: UserService,
      store: Store<AppState>,
      private httpClient: HttpClient,
      private functionsService: FunctionsService) {
    super(authService, userService, store);
    this.init();
    super.ngOnInit();
  }

  init(): void {
    this.initializeApiKeys();
  }

  private initializeApiKeys() {
    this.apiPublicKey = environment.backend_api_public_key;
    this.apiPublicKeyParam = new HttpParams().set('apikey', this.apiPublicKey);

    this.user$.subscribe(user => {
      if (user?.admin)
        this.initializeApiSecretKey();
      else {
        this.apiSecretKey = undefined;
        this.apiSecretKeyParam = undefined;
      }
    });
  }

  private initializeApiSecretKey() {
    if (this.apiSecretKeyParam)
      return;
    this.loadApiSecretKey().then(wrapper => {
      if (wrapper.data) {
        this.apiSecretKey = wrapper.data;
        this.apiSecretKeyParam = new HttpParams().set('apikey', this.apiSecretKey);
        this.apiSecretKeyReadySubject.next(true);
      }
      if (wrapper.errorMessage)
        console.error(`Error loading Stock Forecast Backend's secret API key: ${wrapper.errorMessage}`);
      this.apiSecretKeyReadySubject.next(false);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  getApiSecretKeyParam(): HttpParams | undefined {
    return this.apiSecretKeyParam;
  }

  getApiPublicKeyParam(): HttpParams | undefined {
    return this.apiPublicKeyParam;
  }

  getApiSecretKey(): string | undefined {
    return this.apiSecretKey;
  }

  getApiPublicKey(): string | undefined {
    return this.apiPublicKey;
  }

  /**
   * Loads the Stock Forecast backend's API's secret key from the Google secret manager. If successful, it is stored in this.apiKey.
   */
  private async loadApiSecretKey(): Promise<Wrapper<string>> {
    if (this.apiSecretKey)
      return new Wrapper(this.apiSecretKey);

    try {
      const response = await this.functionsService.getSecretPayloadAsync(environment.backend_api_secret_key_secret_name);
      if (response.data)
        return new Wrapper(response.data, undefined);
    } catch
        (e: any) {
      return new Wrapper<string>(undefined, e.message);
    }

    // If no secret was found
    return new Wrapper<string>(undefined);
  }


}
